import { Component, OnInit } from '@angular/core';
import { LaboursService } from '../labours.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LabourProfileImageComponent } from '../create-sub-labour/labour-profile-image/labour-profile-image.component';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.css']
})
export class UploadPhotoComponent implements OnInit {

  showModal: boolean = false;
  public activeModal: NgbModalRef;

  loginUserId: string | null = localStorage.getItem('UserID');
  selectedLabour: any;

  leftFinger: string = '';
  rightFinger: string = '';
  fingers: string[] = ['Thumb', 'Index', 'Middle', 'Ring', 'Pinky'];

  leftHandData: any = {
    Thumb: null,
    Index: null,
    Middle: null,
    Ring: null,
    Pinky: null,
  };

  rightHandData: any = {
    Thumb: null,
    Index: null,
    Middle: null,
    Ring: null,
    Pinky: null,
  };

  photoFile: File | null = null;
  leftFingerFile: File | null = null;
  rightFingerFile: File | null = null;

  leftFingerImage: string = '';
  rightFingerImage: string = '';
  savedCroppedImage: any = "../assets/Image/Icon/default.png";

  constructor(
    private labourService: LaboursService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.labourService.currentData.subscribe((result) => {
      if (result.function == "onUploadPhoto" && result.data != '' && result.type == 'selectedLabour') {
        this.getSingleLabour(result.data)
      }
    });
  }

  getSingleLabour(labourData: any) {
    const reqObj: any = {
      Labour_Code: labourData.Labour_Code,
      RequireType: 0,
      ActionType: 0,
      UserId: this.loginUserId
    }
    this.labourService.getLabourDetails(reqObj).subscribe((response) => {
      if (response) {
        this.selectedLabour = response[0];
        this.savedCroppedImage = this.selectedLabour.Photo || "../assets/Image/Icon/default.png";
        this.leftHandData = {
          Thumb: this.selectedLabour.LeftThumb || null,
          Index: this.selectedLabour.LeftIndex || null,
          Middle: this.selectedLabour.LeftMiddle || null,
          Ring: this.selectedLabour.LeftRing || null,
          Pinky: this.selectedLabour.LeftPinky || null,
        };
        this.rightHandData = {
          Thumb: this.selectedLabour.RightThumb || null,
          Index: this.selectedLabour.RightIndex || null,
          Middle: this.selectedLabour.RightMiddle || null,
          Ring: this.selectedLabour.RightRing || null,
          Pinky: this.selectedLabour.RightPinky || null,
        };
      }
    });

    this.showModal = true;
  }

  captureImage(type: string): void {
    this.activeModal = this.modalService.open(LabourProfileImageComponent, {
      size: "lg",
      backdrop: "static",
    });
  
    if (type === 'photo') {
      this.activeModal.componentInstance.image = this.savedCroppedImage;
    } else if (type === 'left') {
      const selectedImage = this.leftFinger ? this.leftHandData[this.leftFinger] : null;
      this.activeModal.componentInstance.image = selectedImage;
    } else if (type === 'right') {
      const selectedImage = this.rightFinger ? this.rightHandData[this.rightFinger] : null;
      this.activeModal.componentInstance.image = selectedImage;
    }
  
    this.activeModal.componentInstance.croppedImage.subscribe((arg: any) => {
      if (type === 'photo') {
        this.savedCroppedImage = arg;
        this.selectedLabour.Photo = arg;
      } else if (type === 'left') {
        this.leftFingerImage = arg;
        if (this.leftFinger) {
          this.leftHandData[this.leftFinger] = arg;
          this.selectedLabour[`Left${this.leftFinger}`] = arg;
        }
      } else if (type === 'right') {
        this.rightFingerImage = arg;
        if (this.rightFinger) {
          this.rightHandData[this.rightFinger] = arg;
          this.selectedLabour[`Right${this.rightFinger}`] = arg;
        }
      }
      this.activeModal.close();
    });
  
    this.activeModal.componentInstance.closeModal.subscribe(() => {
      this.activeModal.close();
      this.clearImageValues();
    });
  }
  
  
  
  

  clearImageValues() {
    this.savedCroppedImage = "";
    this.leftFingerImage = '';
    this.rightFingerImage = '';
  }

  onFingerSelect(hand: string, selectedFinger: string): void {
    if (hand === 'left') {
      if (this.leftFinger && this.leftHandData[this.leftFinger]) {
        this.leftHandData[this.leftFinger] = null;
      }
      this.leftFinger = selectedFinger;
    }
    
    if (hand === 'right') {
      if (this.rightFinger && this.rightHandData[this.rightFinger]) {
        this.rightHandData[this.rightFinger] = null;
      }
      this.rightFinger = selectedFinger;
    }
  }
  

  onFileChange(event: Event, type: 'photo' | 'left' | 'right') {
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files[0]) {
      const file = input.files[0];

      this.convertToBase64(file, (base64Image: string) => {
        if (type === 'photo') {
          this.savedCroppedImage = base64Image;
          console.log("Updated Labour Photo: ", this.savedCroppedImage);
        } else if (type === 'left') {
          this.leftFingerImage = base64Image;
          console.log("Updated Left Finger Image: ", this.leftFingerImage);
          this.updateSelectedFingerData('Left', base64Image);
        } else if (type === 'right') {
          this.rightFingerImage = base64Image;
          console.log("Updated Right Finger Image: ", this.rightFingerImage);
          this.updateSelectedFingerData('Right', base64Image);
        }
      });
    }
  }

  convertToBase64(file: File, callback: (base64Image: string) => void): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result as string);
    };
  }

  updateSelectedFingerData(hand: 'Left' | 'Right', base64Image: string) {
    const selectedFinger = this[`${hand.toLowerCase()}Finger`];

    if (selectedFinger) {
      const fingerKey = `${hand}${selectedFinger}`;
      console.log(`Updating ${hand} ${selectedFinger}: `, base64Image);

      if (hand === 'Left') {
        this.leftHandData[fingerKey] = base64Image;
        console.log('Left Hand Data:', this.leftHandData);
      } else if (hand === 'Right') {
        this.rightHandData[fingerKey] = base64Image;
        console.log('Right Hand Data:', this.rightHandData);
      }
    }
  }



  updateDetails() {
    const payload: any = {
      [this.selectedLabour.Labour_Code]: {
        Labour_Code: {
          OldValue: this.selectedLabour.Labour_Code,
          NewValue: this.selectedLabour.Labour_Code,
        },
        Photo: {
          OldValue: this.selectedLabour.Photo,
          NewValue: this.savedCroppedImage,
        },
      },
    };
  
    const addFingerData = (hand: string, labourKey: string, handData: any) => {
      const fingers = ["Thumb", "Index", "Middle", "Ring", "Pinky"];
      fingers.forEach((finger) => {
        const oldValue = this.selectedLabour[`${labourKey}${finger}`];
        const newValue = handData[finger];
  
        if (oldValue !== null || newValue !== null) {
          if (oldValue !== newValue) {
            payload[this.selectedLabour.Labour_Code][`${hand}${finger}`] = {
              OldValue: oldValue,
              NewValue: newValue,
            };
          }
        }
      });
    };
  
    addFingerData("Left", "Left", this.leftHandData);
    addFingerData("Right", "Right", this.rightHandData);
    this.labourService.updateProfilePhotoAndFingers(payload).subscribe((response) => { });
    this.closeModal();
  }
  
  
  
  closeModal() {
    this.showModal = false;
    this.clearForm();
  }

  clearForm() {
    this.leftFinger = '';
    this.rightFinger = '';
    this.photoFile = null;
    this.leftFingerFile = null;
    this.rightFingerFile = null;
  }
}
