import { NgSelectComponent } from '@ng-select/ng-select';
import * as XLSX from 'xlsx';

/** close the ngSelect  */
export function closeNgSelect(item: NgSelectComponent, event: any): void {
  if (item && item.isOpen && event.target.className) {
    // const isScrollingInScrollHost: boolean = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
    const isScrollingInScrollHost: boolean = (event.target.className as string).indexOf('ng-dropdown-panel') > -1;
    if (isScrollingInScrollHost) { return; }
    item.close();
  }
}

export function getListFromExcelFile(fileReader: FileReader) {

  const arrayBuffer: ArrayBufferLike = fileReader.result as ArrayBufferLike;
  const data = new Uint8Array(arrayBuffer);
  const arr = new Array();
  for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
  const bstr = arr.join("");
  const workbook = XLSX.read(bstr, { type: "binary" });
  const first_sheet_name = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[first_sheet_name];
  return XLSX.utils.sheet_to_json(worksheet, { raw: true });
}


export function checkValidFileSize(fileSize: number, maxSizeAllowed: number): boolean {
  return fileSize / 1024 < maxSizeAllowed;
}

export function getAlertMessageByShortCode(alertMessages: any[], code: string): string {
  const messageObj = alertMessages ? alertMessages.find(item => item.Short_Code === code) : null;
  return messageObj ? messageObj.MSG : null;
}