import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarRoutingModule } from './calendar-routing.module';
import { CalenderService } from './calender.service';
import { CalendarComponent } from './calendar.component';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { ComplianceTasksComponent } from './compliance-tasks/compliance-tasks.component';

@NgModule({
  declarations: [
    CalendarComponent,
    ComplianceTasksComponent,
  ],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    CoreModule.forRoot(),
    AngularDateTimePickerModule
  ],
})

export class CalendarModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CalendarModule,
      providers: [
        CalenderService,
      ]
    };
  }
}
