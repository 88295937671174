import { Injectable } from '@angular/core';

declare var dc: any;

interface Config {
  crossfilter: any;
  crossfilter2?: any;
  filterGroup: string;
  chartId: string;
  groupByProperty: string;
  groupByProperty2?: string;

  promptText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuSelectChartService {

  constructor() { }

  getMenuSelect(config: Config) {
    const dimension = config.crossfilter.dimension((d) => {
      return d[config.groupByProperty];
    });

    let finalDimension;

    if (config.groupByProperty2) {
      const dimension2 = config.crossfilter2.dimension((d) => {
        return d[config.groupByProperty2];
      });
      finalDimension = mirror_dimension([dimension, dimension2]);
    } else {
      finalDimension = dimension;
    }

    const group = dimension.group().reduceSum((d) => {
      return 1;
    });

    const chart = dc.selectMenu(config.chartId, config.filterGroup);
    chart.dimension(finalDimension)
      .title((d) => {
        return d.key;
      })
      .group(group)
      .render();

    if (config.promptText) {
      chart.promptText(config.promptText);
    }

    chart.render();
    return chart;
  }
}

function mirror_dimension(dims) {
  function mirror(fname) {
    return function (v) {
      dims.forEach((dim) => {
        dim[fname](v);
      });
    };
  }
  return {
    filter: mirror('filter'),
    filterExact: mirror('filterExact'),
    filterRange: mirror('filterRange'),
    filterFunction: mirror('filterFunction')
  };
}
