import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { WizardStep, AgActionCheckboxRendererComponent } from 'src/app/core/components';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AttendanceService } from '../../attendance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { Contractor, FilterTypeEnum } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import * as moment from 'moment';
import { LabourWeekOFfPrsenter } from '../../labour-weekoff-changes/labourweekoffchange-presenter/labourweekoffchange.presenter';
import { formatDate } from '@angular/common';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getMinDateFromCode } from 'src/app/common/utils';
declare var $;
const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-single-weekoff-change',
  templateUrl: './single-weekoff-change.component.html',
  styleUrls: ['./single-weekoff-change.component.css'],
  viewProviders: [LabourWeekOFfPrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ]
})
export class SingleWeekoffChangeComponent implements OnInit {

  public get minDateRegularSpecificTimeWeekOfChangeFrom$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG355_V1_FV_1', 'MOD115PG355DV_V1_2')));
  }
  public get minDateRegularPermanentWeekOfChangeFrom$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG355_V2_FV_1', 'MOD115PG355DV_V2_2')));
  }
  public get minDateSpecificDayWeeklyOff$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG355_V3_FV_1', 'MOD115PG355DV_V3_2')));
  }
  public get minDateSpecificDayWeeklyOffShift$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG355_V4_FV_1', 'MOD115PG355DV_V4_2')));
  }

  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  @ViewChild('bulkWeekoffForm') bulkWeekoffForm: NgForm;
  @Output() public save1: EventEmitter<void>;
  public formGroup: FormGroup;
  // tslint:disable-next-line: variable-name
  WeekOffdays = [{ Name: 'SUN' }, { Name: 'MON' }, { Name: 'TUE' }, { Name: 'WED' }, { Name: 'THU' }, { Name: 'FIR' }, { Name: 'SAT' }];
  pWeekOffdays = [{ Name1: 'SUN' }, { Name1: 'MON' },
  { Name1: 'TUE' }, { Name1: 'WED' }, { Name1: 'THU' },
  { Name1: 'FIR' }, { Name1: 'SAT' }];
  constructor(public sharedService: SharedService,
    public router: Router,
    public attendanceService: AttendanceService,
    public activeModal: NgbActiveModal,
    private departmenttrainingPrsenter: LabourWeekOFfPrsenter,
    private fb: FormBuilder,
    private logHelperService: LogHelperService
  ) {
    this.formGroup = this.departmenttrainingPrsenter.buildForm();
    this.save1 = new EventEmitter();
  }
  wizardSteps: WizardStep[] = [];
  public checkList: any = [];
  rowData = [];
  formData: any;
  weekOffdata: any;
  show = false;
  show1 = false;
  periodicchange = false;
  permanent = false;
  upcomingweekoffchange = false;
  previousweekoffchange = false;
  employees: any[];
  public activeFilter: string;
  public selectedTabId: any;
  public companyList: any[] = [];
  public plantList: any[] = [];
  public filterTabList: any[] = [];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  private selectedFilterData: any = { JSONText: {} };
  public filterNameFormGroup: FormGroup;
  private filterType: FilterTypeEnum;
  columnDefs = [
    {
      headerName: 'Select', field: 'selected', width: 50,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCheckboxRendererComponent
    },
    {
      headerName: 'GatePassNo', field: 'GatePassNo', width: 70, filterParams:
        { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Full Name', field: 'FullName', width: 200, filterParams:
        { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Company', field: 'Company',
      width: 150, headerComponentParams: { enableSorting: false }, suppressMenu: true,
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 100, filterParams:
        { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Valid Upto Date', field: 'ValidUptoDate', width: 100, filterParams:
        { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Department Name', field: 'DepartmentName', width: 100, filterParams:
        { applyButton: true, clearButton: true }
    }
  ];


  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getCompany();
    this.getLabourName();
    const userId = localStorage.getItem('UserID');
    const roleCode = GLOBAL.USER.ROLE_CODE;
  }

  public getLabourName() {
    this.attendanceService.getLabourName().subscribe((response: any) => {
      this.employees = response;
      this.employees[0].IsWO = 1;
    });
  }


  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }
  getCompany() {
    this.attendanceService.getCompanies().subscribe((response: any[]) => {

      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }
  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  getPlants(companyCode: any): void {
    this.attendanceService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
    });
  }
  // tslint:disable-next-line: function-name
  RegularWeekOff($event) {

    if ($event.target.checked === true) {
      this.show = true;
      this.show1 = false;
      this.upcomingweekoffchange = false;
      this.previousweekoffchange = false;
    } else {
      this.show = false;
    }
  }

  // tslint:disable-next-line: function-name
  Specificweekoffs($event) {
    if ($event.target.checked === true) {
      this.show1 = true;
      this.show = false;
      this.periodicchange = false;
      this.permanent = false;
    } else {
      this.show1 = false;
    }
  }

  // tslint:disable-next-line: function-name
  PeriodicWOChange($event) {

    if ($event.target.checked === true) {
      this.periodicchange = true;
      this.permanent = false;
      this.show1 = false;

    } else {
      this.periodicchange = false;
    }
  }

  // tslint:disable-next-line: function-name
  PermanentWOChange($event) {

    if ($event.target.checked === true) {
      this.permanent = true;
      this.periodicchange = false;
      this.show1 = false;

    } else {
      this.permanent = false;
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
  }
  onCloseAction() {
    this.save1.emit();
    this.activeModal.close();
  }
  displayrange(event) {

    const formValue = this.formGroup.getRawValue();
    console.log('xyz', this.formGroup.getRawValue());
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.AplicableDate = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.ENDDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    }

    // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // const d = new Date(formValue.JSONText.AplicableDate);
    // const a = formatDate(formValue.JSONText.AplicableDate, 'dd-MM-yyyy', 'en-US');
    // const e = formatDate(formValue.JSONText.ENDDate, 'dd-MM-yyyy', 'en-US');
    // const dayName = days[d.getDay()];
    // const alter = `All ${dayName} from ${a} to ${e} will be marked as Weekly Off`;
    // alert(alter);

  }

  private checkWeekOfValidation(data: any): boolean {
    let isPreventCheckbox: boolean;
    switch (true) {
      case data.IsWO === 1:
        this.showAlert('warning', `You can't shift on week off day`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsLeave === 1:
        this.showAlert('warning',
          `You can't shift week of on leave applied day.\n Are you sure shift week off on leave applied date?`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsHoliday === 1:
        this.showAlert('warning', `You can't shift week of on Holiday`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsSuspended === 1:
        this.showAlert('warning', `You can't shift week of on Suspended date`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsNotApplicable === 1:
        this.showAlert('warning', `You can't shift week off on Not Applicable date.`, 'Warning');
        isPreventCheckbox = true;
        break;
      default:
        isPreventCheckbox = false;
    }
    return isPreventCheckbox;
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();
    // if (formValue.JSONText.Labour_Code1) {
    //   const selectedLabour = this.employees.find((item: any) => item.Labour_Code === formValue.JSONText.Labour_Code1);
    //   if (selectedLabour) {
    //     if (this.checkWeekOfValidation(selectedLabour)) {
    //       return;
    //     }
    //   }
    // }
    console.log('xyz', this.formGroup.getRawValue());
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.FromDate = moment(formValue.JSONText.FromDate).format(this.sharedService.datePickerSettings.requestFormat);
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.AplicableDate = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.ENDDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    }
    formValue.JSONText.FromDate = formValue.JSONText.weekOffDate ?
      moment(formValue.JSONText.weekOffDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    // formValue.JSONText.AplicableDate = formValue.JSONText.weekOffShiftDate ?
    //   moment(formValue.JSONText.weekOffShiftDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    // formValue.JSONText.ENDDate = formValue.JSONText.weekOffDate ?
    //   moment(formValue.JSONText.weekOffDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    formValue.JSONText.ToDate = formValue.JSONText.weekOffShiftDate ?
      moment(formValue.JSONText.weekOffShiftDate).format(this.sharedService.datePickerSettings.requestFormat) : null;

    delete formValue.JSONText.weekOffDate;
    delete formValue.JSONText.weekOffShiftDate;
    formValue.JSONText.LabourCodes = [];
    formValue.JSONText.LabourCodes.push(formValue.JSONText.Labour_Code1);
    formValue.JSONText.AplicableDate = moment(formValue.JSONText.AplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    console.log('formValue', formValue);
    if (formValue.JSONText.weekofftype != null) {
      if (formValue.JSONText.LabourCodes.length > 0) {
        this.attendanceService.submitrequestWeekOFFData(formValue.JSONText).subscribe((response) => {
          if (response.Status) {
            this.formGroup.reset();
            this.periodicchange = false;
            this.permanent = false;
            this.show1 = false;
            this.show = false;
            this.logHelperService.logSuccess({
              message: 'Accociate week Off change request Approved successfully'
            });
          } else {
            this.logHelperService.logError({
              message: 'Accociate week Off change request Not Submitted '
            });
          }
        });
      } else {
        this.logHelperService.logError({
          message: 'Please Select Alteast Record'
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select Week Off Type'
      });
    }
  }
  getLabourFilterdata() {

    const formValue = this.formGroup.getRawValue();
    this.attendanceService.getActiveLabourDetails(formValue.JSONText).subscribe((res: any) => {
      console.log(res.Data.Table);
      this.rowData = res.Data.Table;
      for (let index = 0; index < this.rowData.length; index++) {
        const element = this.rowData[index];
        element['selected'] = false;
      }
    });
  }
}
