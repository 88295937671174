import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { ManPowerService } from '../man-power.service';

@Component({
  selector: 'app-reporting-date-time-grid-cell-renderer',
  templateUrl: './reporting-date-time-grid-cell-renderer.component.html',
  styleUrls: ['./reporting-date-time-grid-cell-renderer.component.css']
})
export class ReportingDateTimeGridCellRendererComponent implements OnInit, AgRendererComponent {
  public refresh: any;
  public subject: Subject<any>;
  public showMenu: boolean;
  public params: any = {
    value: 'none'
  };

  constructor(private manPowerServices: ManPowerService) {
    this.showMenu = false;
    this.subject = new Subject();
  }

  ngOnInit() {
  }

  public agInit(params: any): void {
    this.params = params;
    console.dir(params);
  }

  public buttonClick(params: any): void {
        //  event.preventDefault();  // prevent page reloading.
    this.manPowerServices.subject.next(params);
  }


  downArrowClick(event) {
    event.preventDefault();  // prevent page reloading.
    const target = event.target;
    this.showMenu = !this.showMenu;

    console.log(`Button clicked  this.showMenu =${this.showMenu}`);
  }

  mouseleave(event) {
    event.preventDefault();  // prevent page reloading.
    const target = event.target;
    this.showMenu = false;
    console.log(`mouseleave this.showMenu =${this.showMenu}`);
  }
}
