import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from 'src/app/app.globals';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
declare var $: any;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GatepassRequestProcessService {

  constructor(
    private httpClient: HttpClient,
  ) { }


  public getContractors(requestObj: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.WORK_ORDER.SP_Listing_Contractor_Filter_V1}`, requestObj);
  }

  public getWorkOrders(requestObj: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_GetContracotWiseWorkOrderList}`, requestObj)
  }

  public getPlantList(requestObj: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_GetWorkOrderWisePlantList}`, requestObj)
  }

  public getLicenseByContractor(dataObj: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_GetContracotWiseLicenseList}`, dataObj);
  }

  public getWCPolicy(workorderCode: number): Observable<any> {
    return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT3}GetWCPolicy?workoderordercode=${workorderCode}`);
  }

  public getWCPolicyNewList(workorderCode: number, data): Observable<any>{
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_COMMON_LIST}CLPMS_SP_GetContracotWiseECPolicyList`, data);
  }

  public getGatepassList(data: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_GatePassList_4_Request}`, data);
  }

  public insertGatePassRequest(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}InsertGatePassRequest`, data);
  }

  public insertGatePassRequestRenew(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}InsertGatePassRenewRequest`, data);
  }
  public insertGatePassRequestCancel(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}InsertGatePassCancelRequest`, data);
  }

  public insertGatePassRequestBulkCancel(data: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_GatePassCloseDateUpdate}`, data);
  }

  public checkContractorStatus(data: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.ContractorStatusCheck_N_Update}`, data);
  }

}