import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AttendanceService } from '../../attendance.service';
import * as moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { SharedService } from 'src/app/core/services';
import { Console } from '@angular/core/src/console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GLOBAL } from 'src/app/app.globals';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import {
  ExpandRowComponent,
  AgPunchInCellRendererComponent,
  AgDateCellRendererComponent,
  AgDropDownCellRendererComponent,
  AgSaveCellRendererComponent
} from '../../ag-helper';
// tslint:disable-next-line:max-line-length
import { ReportViewListGroupRowComponent } from '../../report-view-list-group-row/report-view-list-group-row.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellPreviousDayRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-previousday-renderer/ag-action-cell-previousday-renderer.component';
// tslint:disable-next-line:max-line-length
import { RptViewListPreviousdayrecordsComponent } from '../../attendance-manual-punch-change/rpt-view-list-privious-day-records/rpt-view-list-privious-day-records.component';
import { LogHelperService } from 'src/app/core/services/log-helper.service';

@Component({
  selector: 'app-attendance-single-punch-management',
  templateUrl: './attendance-single-punch-management.component.html',
  styleUrls: ['./attendance-single-punch-management.component.css']
})
export class AttendanceSinglePunchManagementComponent implements OnInit {

  [x: string]: any;
  // @ViewChild('agGrid') gridApi: AgGridAngular;
  selectedCount = 0;
  private defaultColDef;
  private editType;
  selectedRows: any[] = [];
  rowClassRules;
  requiredFlag = false;
  public closeModal: EventEmitter<any>;
  private isRowSelectable;
  getRowNodeId;
  gridColumnApi;
  private gridApi;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  columnDefs;
  rowData = [];
  rowDataAll = [];
  attResultCode1 = [];
  attResultCode: any;
  attResultCodeName: any;
  url: any;
  groupingMeta = ['ContractorName'];
  private reportValueData: any[];
  documentTypes = [];
  data = [];
  id: any;
  rowSelection;
  roleCode;
  sectionhead: any;
  contractor: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  savedCroppedImage: any = '';
  showCropper = false;
  forContractorLogin = false;
  public alertOption: SweetAlertOptions = {};

  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  @ViewChild('uploadDocumentTemplateRef') uploadDocumentTemplateRef: TemplateRef<any>;
  @ViewChild('uploadDocumentTemplateRefSingle') uploadDocumentTemplateRefSingle: TemplateRef<any>;
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  constructor(public decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private attendanceService: AttendanceService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService
  ) {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: false,
      headerHeight: 40,
      frameworkComponents: { fullWidthCellRenderer: AgPunchInCellRendererComponent },
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      fullWidthCellRenderer: 'fullWidthCellRenderer',
      fullWidthCellRendererParams: {
        isDisable: (params) => {
          if (params.data.rowData) {
            return params.data.rowData.FShift_Code === -2 || params.data.rowData.FShift_Code === -1;
          }
          return false;
        },
        punchDetailsUpdated: (punchData) => {
          this.punchData = punchData;
        }
      }
    };
    this.closeModal = new EventEmitter();
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {
      //
      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: false,
      headerHeight: 40,
      frameworkComponents: { fullWidthCellRenderer: AgPunchInCellRendererComponent },
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      fullWidthCellRenderer: 'fullWidthCellRenderer',
      fullWidthCellRendererParams: {
        isDisable: (params) => {
        },

        punchDetailsUpdated: (punchData) => {

          this.punchData = punchData;

        }
      }
    };

  }


  @Input()
  public set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): Date {
    return this.selectedReportInfo;
  }

  @Input()
  public set reportData(value: any[]) {
    if (value) {
      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
      // this.rowData = value;
    }
  }

  public get reportData(): any[] {
    return this.reportValueData;
  }

  ngOnInit() {
    //  this.getWrongAttendanceList();
    // this.reportData(this.reportValueData);
    console.log('a', this.reportValueData);
  }
  public onCloseAction(): void {
    this.closeModal.next();
  }

  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs = [
      {
        headerName: '#', field: 'sortCode', width: 80,
        sortable: false, suppressMenu: true,
        cellRendererFramework: ExpandRowComponent
      },
      {
        headerName: 'Code', field: 'GatePassNo', width: 80,
        sortable: false, filter: true
      },
      {
        headerName: 'IVR Code', field: 'ULC', hide: 'true',
        sortable: false, filter: true
      },
      {
        headerName: 'Date', field: 'AttDate', width: 80,
        sortable: false, suppressMenu: true
      },
      {
        headerName: 'Name', field: 'FullName', width: 120,
        sortable: false, suppressMenu: true
      },
      {
        headerName: '1st Shift', field: 'FShift_Code', sortable: false, suppressMenu: true, width: 130,
        cellRendererFramework: AgDropDownCellRendererComponent,
        cellRendererParams: {
          shifts: GLOBAL.SHIFT_LIST,
          forceSelection: (params) => {
            return (params === -2 || params === -1) ? {
              field: 'SShift_Code',
              value: -2
            } : false;
          },
        }
      },
      {
        headerName: 'In Date Time', field: 'FSInPunch', sortable: false, suppressMenu: true, width: 250,
        // valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
          },
        }
      },
      {
        headerName: 'Out Date Time', field: 'FSOutPunch', sortable: false, suppressMenu: true, width: 250,
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
          },
        }
      },
      {
        headerName: '2nd Shift', field: 'SShift_Code', sortable: false, suppressMenu: true, width: 130,
        cellRendererFramework: AgDropDownCellRendererComponent, hide: 'params.data.IsAllowSecondShiftVisible === 0',
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
          },
          shifts: GLOBAL.SHIFT_LIST.filter(shift => shift.Shift_Code !== -1)
        }
      },
      // {
      //   headerName: '2nd Shift1', field: 'SShift_Code', sortable: false, suppressMenu: true, width: 130,
      //   cellRendererFramework: AgDropDownCellRendererComponent, show: 'params.data.IsAllowSecondShiftVisible === 1',
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
      //     },
      //     shifts: GLOBAL.SHIFT_LIST.filter(shift => shift.Shift_Code !== -1)
      //   }
      // },
      {
        headerName: 'In Date Time', field: 'SSInPunch1', sortable: false, suppressMenu: true, width: 250,
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent, hide: 'params.data.IsAllowSecondShiftVisible === 0',
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
              || params.data.SShift_Code === -2;
          },
        }
      },
      // {
      //   headerName: 'In Date Time1', field: 'SSInPunch', sortable: false, suppressMenu: true, width: 250,
      //   valueFormatter: this.timeValueFormatter,
      //   cellRendererFramework: AgDateCellRendererComponent, show: 'params.data.IsAllowSecondShiftVisible === 1',
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
      //         || params.data.SShift_Code === -2;
      //     },
      //   }
      // },
      {
        headerName: 'Out Date Time', field: 'SSOutPunch1',
        sortable: false, suppressMenu: true, width: 250, hide: 'params.data.IsAllowSecondShiftVisible === 0',
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
              || params.data.SShift_Code === -2;
          },
        }
      },
      // {
      //   headerName: 'Out Date Time1', field: 'SSOutPunch',
      //   sortable: false, suppressMenu: true, width: 250, show: 'params.data.IsAllowSecondShiftVisible === 1',
      //   valueFormatter: this.timeValueFormatter,
      //   cellRendererFramework: AgDateCellRendererComponent,
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
      //         || params.data.SShift_Code === -2;
      //     },
      //   }
      // },
      {
        headerName: 'WH', field: 'WorkingHours', sortable: false, suppressMenu: true, width: 80,
      },
      {
        headerName: 'OT', field: 'OTHours', width: 80,
        sortable: false, suppressMenu: true
      },
      {
        headerName: '', field: '', width: 40,
        sortable: false, suppressMenu: true,
        cellRendererFramework: AgSaveCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            // return ((params.data.FShift_Code !== -2 || params.data.FShift_Code !== -1)
            //   && (!moment(params.data.FSInPunch).isValid() || !moment(params.data.FSOutPunch).isValid()))
            // || (params.data.SShift_Code !== -2
            //   && (!moment(params.data.SSInPunch).isValid() || !moment(params.data.SSOutPunch).isValid()));
          }
        }
      }
    ];
  }

  onApprove() {


    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length === 0) {
      alert('Please select atleast one row');
      // return;
    } else {
      console.log('this.selectedRows', this.selectedRows);
      let attResultCode2 = '';
      this.selectedRows.forEach((data) => {
        this.attResultCode1.push(data.AttPunch_Code);
        console.log('this.attResultCode', attResultCode2);
      });
      attResultCode2 = this.attResultCode1.join(',');
      console.log('this.attResultCode', this.attResultCode2);

      this.attendanceService.updateWrongPunch({ attResultCode: attResultCode2 }).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Data Save Succesfully.'
        });
        this.closeModal.next();
        /// this.getWrongAttendanceList();
      });
    }
  }

  onDelete() {

    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length === 0) {
      alert('Please select atleast one row');
      // return;
    } else {
      console.log('this.selectedRows', this.selectedRows);
      let attResultCode2 = '';
      this.selectedRows.forEach((data) => {
        this.attResultCode1.push(data.AttPunch_Code);
        console.log('this.attResultCode', attResultCode2);
      });
      attResultCode2 = this.attResultCode1.join(',');
      console.log('this.attResultCode', this.attResultCode2);

      this.attendanceService.deleteWrongPunch({ attResultCode: attResultCode2 }).subscribe((dt: any[]) => {
        console.log('after delete:', dt);
        this.logHelperService.logSuccess({
          message: 'Data  Succesfully Deleted.'
        });
        this.closeModal.next();
        /// this.getWrongAttendanceList();
      });
    }
  }
  onSelectionChanged(event) {

    let data = event.api.getSelectedRows();
    data = data.filter(e => !e.groupKey);
    this.selectedCount = data.length;
    // this.selectedCount = 1;
    console.log('selectedCount', this.selectedCount);
  }

  onRowSelected(event) {
    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    } else {
      if (event.node.selected) {
        // event.node.columnApi.getColumn('Comment').getColDef().cellStyle = { 'border': '1px solid green' };
        console.log('oevent.node.selected1', event.node.selected);
      } else {
        // this.gridOptions.columnApi.getColumn('Comment').getColDef().cellStyle = {'border': 'none'}
        console.log('oevent.node.selected2', event.node.selected);

      }
    }
  }

  modelOpen(item) {

    const data = {
      AttPunch_Code: item.AttPunch_Code,
      Status: item.Status,
      AttendancePunch: item.AttendancePunch
    };
    this.attendanceService.getpreviousdayrecords(data).subscribe((response: any[]) => {
      this.openReportViewModal(response);
    });
  }
  openReportViewModal(response: any[]): void {
    const modalRef = this.modalService.open(RptViewListPreviousdayrecordsComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  // saveRecord(params) {
  //   const tempParams = {
  //     // attendance: params,

  //     Punch: (params.IsInOut === 1 ? params.OutPunch  :  params.InPunch),
  //     IsInOut: (params.IsInOut === 1 ? 2 : 1),
  //     ULC: params.ULC,
  //     UnitCode: params.Unit_Code,
  //     UserID: '142',
  //   };
  // this.attendanceService.savePunchdDetails(tempParams).subscribe((res) => {
  //     console.log(res);
  //   });
  // }

  saveRecord(params) {
    const tempParams = {
      // attendance: params,

      Punch: (params.IsInOut === 1 ? params.OutPunch : params.InPunch),
      IsInOut: (params.IsInOut === 1 ? 2 : 1),
      ULC: params.ULC,
      UnitCode: params.Unit_Code,
      UserID: '142',
    };
    // const tempParams = {
    //   attendance: params,
    //   punchData: this.punchData
    // };
    this.attendanceService.savePunchdDetails(tempParams).subscribe((res) => {
      console.log(res);
    });
  }

  timeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('HH:mm');
    }

    return '';
  }
  getPunchInfo(rowData, rowIndex) {

    console.log('rowData', rowData);
    console.log('rowIndex', rowIndex);
    if (this.currentPunchRowIndex === rowIndex) {
      // delete row
      console.log('delete row', this.currentPunchRowIndex);
      const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex);
      expandedRow.data.expanded = false;
      this.gridAPI.updateRowData({ update: [expandedRow.data] });
      const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex + 1);
      this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      this.currentPunchRowIndex = undefined;
    } else {
      // First close previously opend row index
      if (this.currentPunchRowIndex !== undefined) {
        console.log('undefined', this.currentPunchRowIndex);
        const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex);
        expandedRow.data.expanded = false;
        this.gridAPI.updateRowData({ update: [expandedRow.data] });
        const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex + 1);
        this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      }
      this.rowData = rowData;
      // add row
      this.currentPunchRowIndex = rowIndex;
      this.gridAPI.updateRowData({
        add: [{
          rowData,
          fullWidth: true
        }],
        addIndex: rowIndex + 1
      });
    }
  }

}
