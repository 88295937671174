import { Component, OnInit } from '@angular/core';
import {
  YearlyBudgetModel, monthList, plantList, siteList, glList,
  costCenterList, yearTypeList, manPowerTypeList
} from './yearly-budget.modal';
import * as _ from 'lodash';

@Component({
  selector: 'app-yearly-budget',
  templateUrl: './yearly-budget.component.html',
  styleUrls: ['./yearly-budget.component.css']
})
export class YearlyBudgetComponent implements OnInit {
  siteList = siteList;
  plantList = plantList;
  glList = glList;
  manPowerTypeList = manPowerTypeList;

  costCenterList = costCenterList;
  selectedPlant: 0;
  yearTypeList = yearTypeList;

  yearList = [
    '2019-2020',
    '2018-2019',
    '2017-2018',
  ];

  unitList = [];

  monthList = monthList;
  monthObjectList = [];
  yearlyBudgetObject: YearlyBudgetModel = new YearlyBudgetModel();

  showUnitWiseBlock: boolean;
  constructor() { }

  ngOnInit() {
  }

  onPlantChange() {
    this.yearlyBudgetObject.plant = this.plantList[this.selectedPlant];
  }

  createUnitWiseList() {
    if (this.yearlyBudgetObject.year &&
      this.yearlyBudgetObject.budgetAmount > 0 &&
      this.yearlyBudgetObject.plant.UnitList.length > 0) {
      const yearObjectArray = this.yearlyBudgetObject.year.split('-');
      const monthObjectList = [];
      const totalBudgetAmount = (this.yearlyBudgetObject.budgetAmount
        / this.yearlyBudgetObject.plant.UnitList.length).toFixed(2);

      this.monthList.map((value, index) => {
        const valueObject = {
          Period: '',
          Amount: (Number(totalBudgetAmount) / 12).toFixed(2)
        };

        if (index < 9) {
          valueObject.Period = `${value}-${yearObjectArray[0]}`;
        } else {
          valueObject.Period = `${value}-${yearObjectArray[1]}`;
        }
        monthObjectList.push(valueObject);
      });

      this.yearlyBudgetObject.plant.UnitList.map((value) => {
        value.BudgetAmount = totalBudgetAmount;
        value['MonthObjectList'] = _.cloneDeep(monthObjectList);
      });
      this.showUnitWiseBlock = true;
    } else {
      this.showUnitWiseBlock = false;
    }

  }

  addUnitWiseEntry() {
    // this.yearlyBudgetObject.plant.UnitList.forEach((value, index) => {
    //   this.onYearChange(index);
    // });
  }
  updateUnitBudgetAmt($event, unitWiseDataIndex) {
    if (this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount > Number($event.currentTarget.value)) {
      const remainingToalAmt = this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount - Number($event.currentTarget.value);
      const remainingAmt = Number(remainingToalAmt / (this.yearlyBudgetObject.plant.UnitList.length - 1)).toFixed(2);

      this.yearlyBudgetObject.plant.UnitList.map((value, index) => {
        if (index !== unitWiseDataIndex) {
          value.BudgetAmount = Number(value.BudgetAmount) + Number(remainingAmt);
          this.yearlyBudgetObject.plant.UnitList[index]['MonthObjectList'].map((element, index) => {
            element.Amount = (Number(value.BudgetAmount) / 12).toFixed(2);
            return element;
          });
        }
      });
      this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount = Number($event.currentTarget.value);
      this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
        element.Amount = (Number(this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount) / 12).toFixed(2);
        return element;
      });

    } else {
      $event.currentTarget.value = this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount;
    }
  }

  updateUnitWiseAmount(amountIndex, unitWiseDataIndex) {
    let remainingTotalAmt = 0;
    this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index <= amountIndex) {
        remainingTotalAmt += Number(element.Amount);
      }
    });

    const remainingIndex = 12 - (amountIndex + 1);
    const remainingAmt = Number((this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount - remainingTotalAmt)
      / remainingIndex).toFixed(2);
    this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index > amountIndex) {
        element.Amount = remainingAmt;
      }
      return element;
    });
  }
}

