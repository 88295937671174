import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { GLOBAL } from "src/app/app.globals";
import { checkValidFileSize } from "src/app/core/utility/utility";

@Component({
  selector: 'app-task-document-upload',
  templateUrl: './task-document-upload.component.html',
  styleUrls: ['./task-document-upload.component.css']
})
export class WOTaskDocumentUploadComponent {

  @Output() public closeModal: EventEmitter<void>;
  @Output() public handleHRCRequestAction: EventEmitter<any>;

  public fileError: string;
  public selectedFiles: File[];
  public maxSizeAllowed: number;

  public get maxFileSize(): number {
    return GLOBAL.ALLOWED_FILE_SIZE;
  }
  
  constructor() {
    this.closeModal = new EventEmitter();
    this.handleHRCRequestAction = new EventEmitter();
    this.maxSizeAllowed = GLOBAL.ALLOWED_FILE_SIZE * 1024;
  }

  public onCloseAction(): void {
    this.closeModal.emit();
  }

  public onFileChange(event: any): void {
    if (!event.target.files) return;

    const files = event.target ? event.target.files : [];
    if (!checkValidFileSize(files[0].size, this.maxSizeAllowed)) {
      this.fileError = `Max file size allowed is ${GLOBAL.ALLOWED_FILE_SIZE}MB.`;
      this.selectedFiles = [];
      event.target.value = null;
      return;
    }

    this.selectedFiles = event.target ? event.target.files : [];
  }

  public onHRCRequest(): void {
    this.handleHRCRequestAction.emit(Array.from(this.selectedFiles));
  }
  

}