import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { GLOBAL } from 'src/app/app.globals';
import { AgFilterCellRenderComponent } from
  'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../model/man-power.model';
import { GridOptions, GridApi } from 'ag-grid-community';
import { SelectStepsComponent } from 'src/app/core/components';
import { RoleCode, ManpowerRequestStatus } from '../../man-power.modal';
import { ManPowerService } from '../../man-power.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ag-man-power-cell-renderer',
  templateUrl: './ag-man-power-cell-renderer.component.html',
  styleUrls: ['./ag-man-power-cell-renderer.component.css'],
  providers: [ManPowerService]
})
export class AgManPowerCellRendererComponent implements OnInit, ICellRendererAngularComp {

  @ViewChild('requestSelectStep') public requestSelectStep: SelectStepsComponent;
  public closeModal: EventEmitter<any>;

  public isApprovedProcessAndHRUser: boolean;
  public rowData: any;
  public sectionFullText = '';
  params;
  showInOut = GLOBAL.SHOW_IN_OUT;
  punchList;
  selectedInOut = [];
  punchData;
  datePipe = new DatePipe('en-US');
  // todayDate = moment(new Date()).format('DD-MMM-YYYY')
  todayDate = moment().format();
  removedList: any[] = [];

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };

  public contractorConfig: any;
  public gridAPI: GridApi;
  public stepsData: any[];

  public requistionGridOptions: GridOptions = <GridOptions>{
    suppressHorizontalScroll: false,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    onGridReady: (params) => {
      this.gridAPI = params.api;
    },
    onGridSizeChanged: (params) => {
      // params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: 10,
    headerHeight: 40,
  };
  selectedType: any;
  selectedWorkmanCategory: any;
  selectedDate: any;
  selectedShiftSection: any;
  selectedShift: any;
  selectedDateObj: any;
  showSaveButton = false;
  isNewEntry = true;
  constructor(private manPowerService: ManPowerService) {
    this.stepsData = [];
    this.closeModal = new EventEmitter();
  }

  ngOnInit() {
    // this.todayDate = this.datePipe.transform(new Date(), 'dd-MMM-YYYY')
    this.showSaveButton = this.manPowerService.isEditAllow();
    // console.log("this. show save button value:", this.showSaveButton)
    // this.punchList = GLOBAL.TYPE_OF_PUNCHES;
    // const params = this.params.data.rowData;
    // this.attendanceService.getPunchDetails(params).subscribe((punchData: any[]) => {
    //   punchData.forEach((punch) => {
    //     punch['momentAttendancePunch'] = moment(punch.AttendancePunch).format('YYYY-MM-DD');
    //   });
    //   this.punchData = _.groupBy(punchData, 'momentAttendancePunch');
    //   this.punchData = _.values(this.punchData);
    // });
  }

  agInit(params: any): void {
    this.createConfigForContractor();

    this.params = params;
    this.rowData = this.params.data;
    this.rowData.workManCategoryList.forEach((workman: any) => {
      workman.Dates.forEach((dt: any) => {
        dt.Shifts.forEach((shift: any) => {
          if (shift.AssignedContractors.find(cont => cont.ContractorVendor_Code !== null && cont.ContractorVendor_Code !== '')) {
            this.isNewEntry = false;
          }
        });
      });
    });

    this.sectionFullText = `${this.rowData.Section1}  >>  ${this.rowData.Section2}  >>  ${this.rowData.Section3}`;
    // this.rowData.Dates.forEach((item: any) => {
    //   // debugger
    //   item.displayDate = this.dateConvertor(new Date(item.Date));
    //   item.rowspan = item.rowspan ? item.rowspan + item.Shifts.length : item.Shifts.length;

    //   item.Shifts.forEach(((shiftItem: any) => {

    //     if (shiftItem.AssignedContractors.length === 0) {
    //       shiftItem.AssignedContractors.push({
    //         Assign: shiftItem.Requirement,
    //         ContractorVendor_Code: null,
    //         ContractorName: 'Nitesh'
    //       });

    //       shiftItem.AssignedContractors.push({
    //         Assign: shiftItem.Requirement,
    //         ContractorVendor_Code: null,
    //         ContractorName: 'Nitesh2'
    //       });
    //     }

    //     item.rowspan = item.rowspan + shiftItem.AssignedContractors.length;

    //     shiftItem.rowspan = shiftItem.rowspan ? shiftItem.rowspan + shiftItem.AssignedContractors.length
    // : shiftItem.AssignedContractors.length;

    //   }))
    // });
    console.log('params', this.rowData);
    this.checkValidProcess();
  }

  public checkValidProcess() {
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    const requiredRoles = [RoleCode.HRHEAD, RoleCode.HRBP, RoleCode.SECURITY, RoleCode.ERTEAM];
    // tslint:disable-next-line: max-line-length
    const hrHead = userRoles.find((item: any) => requiredRoles.includes(item.Role_Code));
    if (hrHead && this.rowData.Status === ManpowerRequestStatus.APPROVED) {
      this.isApprovedProcessAndHRUser = true;
    }
  }

  public onValueInput(assignedContractor: any): void {
    console.log('onValueInput', assignedContractor);
    if (assignedContractor.Assign === 0) {
      assignedContractor.Assign = '';
    }

  }

  refresh(): boolean {
    return true;
  }


  public openModalForRequest(type: number, workManCategory: any): void {
    if (this.isApprovedProcessAndHRUser) {
      this.selectedType = type;
      this.selectedWorkmanCategory = workManCategory;
      this.showModal();
    }
  }

  public openModalForDateWise(type: number, dateChildSections: any[]): void {
    if (this.isApprovedProcessAndHRUser) {
      this.selectedType = type;
      this.selectedDate = dateChildSections;
      this.showModal();
    }
  }

  public openModalForShiftWise(type: number, data: any, shift, dateObject): void {
    if (this.isApprovedProcessAndHRUser) {
      this.selectedType = type;
      this.selectedShiftSection = data;
      this.selectedShift = shift;
      this.selectedDateObj = dateObject;
      this.showModal();
    }
  }

  public replicateSameContractorsAllShifts(type, workManCategory, dateIndex, dateObject) {
    if (this.isApprovedProcessAndHRUser) {

      let hasError = false;

      for (let i = (dateIndex + 1); i < workManCategory.Dates.length; i++) {
        if (hasError) {
          break;
        }
        const nextDate = workManCategory.Dates[i];
        nextDate.Shifts.forEach((shiftItem: any) => {
          const existShift = dateObject.Shifts.find((item) => {
            return item.ShiftName === shiftItem.ShiftName && item.Requirement === shiftItem.Requirement;
          });
          if (!existShift || !existShift.AssignedContractors
            || existShift.AssignedContractors.filter(item => (item.error || !item.ContractorVendor_Code || !item.Assign)).length > 0) {
            hasError = true;
            return;
          }
        });
      }

      if (hasError) {
        alert('Please select Contractor or Assign value or Assign value not matched with requirement');
        return;
      }

      if (confirm('Are you sure you want to replicate same to below dates?')) {
        // If there is no mor dates to replicate
        if (workManCategory.Dates.lenght <= (dateIndex + 1)) {
          return;
        }

        for (let i = (dateIndex + 1); i < workManCategory.Dates.length; i++) {
          const nextDate = workManCategory.Dates[i];
          nextDate.Shifts.forEach((shiftItem: any) => {
            const existShift = dateObject.Shifts.find((item) => {
              return item.ShiftName === shiftItem.ShiftName && item.Requirement === shiftItem.Requirement;
            });
            if (existShift && existShift.AssignedContractors) {
              shiftItem.AssignedContractors = JSON.parse(JSON.stringify(existShift.AssignedContractors));
              this.calculateRowspan(nextDate);
            }
          });
        }
      }
    }
  }

  public replicateSameContractorsSingleShift(type, workManCategory, dateIndex, dateObject, existShift) {
    if (this.isApprovedProcessAndHRUser) {

      if (!existShift || !existShift.AssignedContractors
        || existShift.AssignedContractors.filter(item => (item.error || !item.ContractorVendor_Code || !item.Assign)).length > 0) {
        alert('Please select Contractor or Assign value or Assign value not matched with requirement');
        return;
      }

      if (confirm('Are you sure you want to replicate same to below dates?')) {
        // If there is no mor dates to replicate
        if (workManCategory.Dates.lenght <= (dateIndex + 1)) {
          return;
        }

        for (let i = (dateIndex + 1); i < workManCategory.Dates.length; i++) {
          const nextDate = workManCategory.Dates[i];
          nextDate.Shifts.forEach((shiftItem: any) => {
            if (existShift && existShift.AssignedContractors
              && existShift.ShiftName === shiftItem.ShiftName
              && existShift.Requirement === shiftItem.Requirement
            ) {
              shiftItem.AssignedContractors = JSON.parse(JSON.stringify(existShift.AssignedContractors));
              this.calculateRowspan(nextDate);
            }
          });
        }
      }
    }
  }

  public calculateRowspan(dateObject) {
    let rowspan = 0;
    dateObject.Shifts.forEach((shiftItem: any) => {
      const assigneeCount = shiftItem.AssignedContractors.length;
      rowspan += (assigneeCount > 0 ? assigneeCount : 1);
    });

    dateObject.rowspan = rowspan;
  }

  public calculateShortageRowspan(shiftObject) {
    let rowspan = 0;
    shiftObject.forEach((shiftItem: any) => {
      const MRShiftId = shiftItem.AssignedContractors[0].MRShiftId;
      const assigneeCount = shiftItem.AssignedContractors.find(e => e.AssignId === MRShiftId);
      rowspan += (assigneeCount > 0 ? assigneeCount : 1);
    });

    shiftObject.rowspan = rowspan;
  }

  public onSubmitAction(selectedData: any): void {
    console.log('onSubmitAction selectedData', selectedData);
  }

  public onCloseActionOfGrid(selectedData: any): void {
    console.log('onCloseActionOfGrid selectedData', selectedData);
    this.selectedType = null;
  }

  public getSelectedRowData(selectedData: any): void {
    console.log('getSelectedRowData selectedData', selectedData);
    this.setTableData(selectedData.data);
    this.requestSelectStep.closeModel();
  }

  public onEmitData(selectedData: any): void {
    console.log('onEmitData selectedData', selectedData);
    this.requestSelectStep.closeModel();
  }

  public checkShortageValidForData(assignedContractor: any, shift: any) {

    const findParent = shift.AssignedContractors.find((x: any) => x.AssignId === assignedContractor.RefAssignId);
    if (findParent) {
      const findAllByParent = shift.AssignedContractors.filter((x: any) => x.RefAssignId === assignedContractor.RefAssignId);



      // const findParent = shift.AssignedContractors.find((x: any) => x.MRShiftId === assignedContractor.AssignId);
      // if (findParent) {
      //   const findAllByParent = shift.AssignedContractors.filter((x: any) => x.RefAssignId === assignedContractor.RefAssignId);
      if (findAllByParent) {
        const totalValue = findAllByParent.reduce((sum, item) => sum + parseInt(item.Assign, 10), 0);
        if (totalValue > 0 && totalValue !== findParent.Shortage) {
          findAllByParent.map((e: any) => e.error = true);
        } else {
          findAllByParent.map((e: any) => e.error = false);
        }
        findAllByParent.filter((e: any) => parseInt(e.Assign, 10) === 0 || !e.Assign).map((x: any) => x.error = true);
        // const findAllContractor = shift.AssignedContractors.map((e: any) => e.ContractorVendor_Code);
        const findAllContractor = [...findAllByParent, findParent].map((e: any) => e.ContractorVendor_Code);
        const duplicateIds = findAllContractor.map((e, i, final) => final.indexOf(e) !== i && i)
          .filter(obj => findAllContractor[obj])
          .map(e => findAllContractor[e]);
        // console.log("find duplicate:", duplicateIds)
        if (duplicateIds.length > 0) {
          findAllByParent.map((e: any) => e.errorContractor = true);
        } else {
          findAllByParent.map((e: any) => e.errorContractor = false);
        }
        shift.AssignedContractors.filter((e: any) => !e.ContractorVendor_Code).map((x: any) => x.errorContractor = true);
      }
    }
    console.log('shift', shift);
  }

  private checkValidForData(workManCategoryList: any): boolean {
    for (let i = 0; i < workManCategoryList.Dates.length; i++) {
      const shifts = workManCategoryList.Dates[i].Shifts;
      // let dt = this.datePipe.transform(workManCategoryList.Dates[i].Date, 'YYYY-MM-dd');
      if (moment(workManCategoryList.Dates[i].Date).format('YYYY-MM-DD') > (moment().format('YYYY-MM-DD')) || this.isNewEntry) {
        //   console.log("in less or equal")
        // } else {
        for (let j = 0; j < shifts.length; j++) {
          const shiftItem = shifts[j];
          const totalValue = shiftItem.AssignedContractors.reduce((sum, item) => sum + parseInt(item.Assign, 10), 0);
          if (shiftItem.Requirement !== totalValue) {
            shiftItem.AssignedContractors.map((e: any) => e.error = true);
          } else {
            shiftItem.AssignedContractors.map((e: any) => e.error = false);
          }
        }
      }

    }
    return false;
  }

  public onSave(): void {
    const errorClass = document.querySelectorAll('.error-border');
    if (errorClass.length === 0) {
      //   console.log("in error class")
      // } else {
      //   console.log("NO ERROR")
      this.params.context.componentParent.saveData(this.rowData, this.removedList);
    }
  }

  public addIndividualAssignedContractor(innerIndex, dateObject: any, shift: any, workManCategory: any): void {
    // console.log('innerIndex:', innerIndex);
    // console.log('dateObject', dateObject);
    // console.log('shift:', shift);
    // console.log('workManCategory:', workManCategory);
    if (shift.AssignedContractors[innerIndex].Shortage > 0) {
      const assignedContractorsLength = shift.AssignedContractors.length;
      const assignedContractor = assignedContractorsLength > 0 ? shift.AssignedContractors[innerIndex] : null;
      const obj = {
        Assign: '',
        AssignId: shift.AssignedContractors[innerIndex].MRShiftId,
        RefAssignId: shift.AssignedContractors[innerIndex].AssignId,
        ContractorVendor_Code: '',
        ContractorName: '',
        SubContractorVendor_Code: '',
        SubContractorName: ''
      };
      // shift.AssignedContractors.splice(0 + 1, 0, obj);
      shift.AssignedContractors.splice(innerIndex + 1, 0, obj);
      shift.rowspan = shift.rowspan + 1;
      dateObject.rowspan = dateObject.rowspan + 1;
    }
    this.checkShortageValidForData(shift.AssignedContractors[innerIndex + 1], shift);
    // this.checkValidForData(workManCategory);
  }

  public removeIndividualAssignedContractor(innerIndex, dateObject: any, shift: any): void {
    // console.log("innerIndex:", innerIndex)
    // console.log("dateObject", dateObject)
    // console.log("shift:", shift)
    // console.log("workManCategory:", workManCategory)
    // shift.AssignedContractors.splice(innerIndex, 1);
    const findAllByParent = [] = shift.AssignedContractors
      .filter((x: any) => x.RefAssignId === shift.AssignedContractors[innerIndex].AssignId);
    if (findAllByParent.length > 0) {
      const removedObj = shift.AssignedContractors.splice(findAllByParent.length + innerIndex, 1)[0];
      // const removedObj = findAllByParent.splice(findAllByParent.length -1 , 1)[0]
      if (removedObj.$id) {
        this.removedAssignedContractorList(removedObj);
      }
      shift.rowspan = shift.rowspan - 1;
      dateObject.rowspan = dateObject.rowspan - 1;
      // }
      // this.checkValidForData(workManCategory);
      this.checkShortageValidForData(shift.AssignedContractors[innerIndex + 1], shift);
    }
  }

  private removedAssignedContractorList(obj) {
    this.removedList.push(obj);
  }

  public addAssignedContractor(dateObject: any, shift: any, workManCategory: any): void {
    if (shift.Requirement > shift.AssignedContractors.length) {
      const assignedContractorsLength = shift.AssignedContractors.length;
      const assignedContractor = assignedContractorsLength > 0 ? shift.AssignedContractors[assignedContractorsLength - 1] : null;
      shift.AssignedContractors.push(
        {
          Assign: '',
          ContractorVendor_Code: '',
          ContractorName: '',
          CategoryWorkmenMIL_Code: assignedContractor ? assignedContractor.CategoryWorkmenMIL_Code : '',
          SubContractorVendor_Code: '',
          SubContractorName: '',
          RefAssignId: shift.AssignedContractors[0].AssignId,
          MRShiftId: shift.AssignedContractors[0].MRShiftId
        }
      );
      dateObject.rowspan = dateObject.rowspan + 1;
    }
    this.checkValidForData(workManCategory);
  }

  public minusAssignedContractor(dateObject: any, shift: any, workManCategory: any): void {
    const assignedContractorsLength = shift.AssignedContractors.length;
    if (assignedContractorsLength > 1) {
      const removedObj = shift.AssignedContractors.pop();
      if (removedObj.$id) {
        this.removedAssignedContractorList(removedObj);
      }
      dateObject.rowspan = dateObject.rowspan - 1;
    }
    this.checkValidForData(workManCategory);
  }

  private showModal(): void {
    this.setStepArray(this.rowData.contractorList);
    setTimeout(() => {
      console.log('stepsData', this.stepsData);
      this.requestSelectStep.openModel();
    });
  }

  private setTableData(selectedData: any): void {
    if (this.selectedType === 1) {
      // this.rowData.workManCategoryList.forEach((workManCategory: any) => {
      //   workManCategory.Dates.forEach((dateItem: any) => {
      //     dateItem.Shifts.forEach((shiftItem: any) => {
      //       shiftItem.AssignedContractors.forEach((assignedContractor: any) => {
      //         assignedContractor.ContractorVendor_Code = selectedData.ContractorVendor_Code;
      //         assignedContractor.ContractorName = selectedData.Contractor;
      //         assignedContractor.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
      //         assignedContractor.SubContractorName = selectedData.SubContractor;
      //       });
      //     });
      //   });
      // });
      this.selectedWorkmanCategory.Dates.forEach((dateItem: any) => {
        dateItem.Shifts.forEach((shiftItem: any) => {
          shiftItem.AssignedContractors.forEach((assignedContractor: any) => {
            assignedContractor.ContractorVendor_Code = selectedData.ContractorVendor_Code;
            assignedContractor.ContractorName = selectedData.Contractor;
            assignedContractor.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
            assignedContractor.SubContractorName = selectedData.SubContractor;
          });
        });
      });
    } else if (this.selectedType === 2) {
      this.selectedDate.forEach((shiftItem: any) => {
        shiftItem.AssignedContractors.forEach((assignedContractor: any) => {
          assignedContractor.ContractorVendor_Code = selectedData.ContractorVendor_Code;
          assignedContractor.ContractorName = selectedData.Contractor;
          assignedContractor.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
          assignedContractor.SubContractorName = selectedData.SubContractor;
        });
      });
    } else if (this.selectedType === 3) {
      this.selectedShiftSection.ContractorVendor_Code = selectedData.ContractorVendor_Code;
      this.selectedShiftSection.ContractorName = selectedData.Contractor;
      this.selectedShiftSection.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
      this.selectedShiftSection.SubContractorName = selectedData.SubContractor;
      // this.selectedDateObj <= this.todayDate ? this.checkShortageValidForData(this.selectedShiftSection, this.selectedShift) : '';
      (this.selectedDateObj > this.todayDate) || this.isNewEntry ? ''
        : this.checkShortageValidForData(this.selectedShiftSection, this.selectedShift);
    }
  }

  filterInoutSelection(selected) {
    this.selectedInOut = [];
    this.punchData.forEach((punches) => {
      punches.forEach((punch) => {
        if (Number(punch.drpInOutMidPunch) !== -1
          && Number(punch.drpInOutMidPunch) !== 0
          && Number(punch.drpInOutMidPunch) !== NaN) {
          this.selectedInOut.push(Number(punch.drpInOutMidPunch));
        }
      });
    });
    this.punchList.forEach((punch) => {
      if (this.selectedInOut.indexOf(punch.Code) === -1) {
        punch['isDisable'] = false;
      } else {
        punch['isDisable'] = true;
      }
    });
    this.punchDetailsUpdated();
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }

  punchDetailsUpdated() {
    if (this.params.punchDetailsUpdated) {
      this.params.punchDetailsUpdated(this.punchData);
    }
  }

  dateConvertor(date: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'];
    return `${date.getDate()}-${months[date.getMonth()].substr(0, 3)}-${date.getFullYear()}`;
  }

  // config for select filter component
  private createConfigForContractor(): any {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor Code', field: 'ContractorVendor_Code',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Contractor', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRenderer: (params) => {
          let val = '';
          if (params.data.SubContractor) {
            val = `${params.data.SubContractor} / ${params.data.Contractor}`;
          } else {
            val = params.data.Contractor;
          }
          return val;
        }
      },
      {
        headerName: 'Contractor Code', field: 'ContractorVendor_Code',
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRenderer: (params) => {
          let val = '';
          if (params.data.SubContractorVendor_Code) {
            val = `${params.data.SubContractorVendor_Code} / ${params.data.ContractorVendor_Code}`;
          } else {
            val = params.data.ContractorVendor_Code;
          }
          return val;
        }
      },
      {
        headerName: 'Email Id', field: 'EmailID',
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    return contractorColumnDefs;
  }

  private setStepArray(data: any[]): void {
    const firstSteps = {
      id: 1,
      type: 'grid',
      title: 'Contractor List',
      button: { skip: false, cancel: false, submit: true, prev: false, next: false, close: true },
      header: true,
      footer: false,
      isAboveElementRef: false,
      isBelowElementRef: false,
      columnDef: this.createConfigForContractor(),
      rowData: data,
      gridOptions: this.requistionGridOptions
    };
    this.stepsData = [firstSteps];
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }
}
