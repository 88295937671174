import { Component, Input } from "@angular/core";


@Component({
  selector: 'app-wo-task-list-header',
  templateUrl: './wo-task-list-header.component.html',
  styleUrls: ['./wo-task-list-header.component.css'],
  // styles: [`
  //     :host {
  //       'style': 'display: flex;'
  //     }`
  // ]
  host: {
    class: 'd-flex'
  },
})
export class WOTaskListHeaderComponent {

  @Input() public taskDetail: any;

  constructor(
  ) {
  }

}