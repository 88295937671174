import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-checkbox-renderer',
  templateUrl: './ag-action-checkbox-renderer.component.html',
  styleUrls: ['./ag-action-checkbox-renderer.component.css']
})
export class AgActionCheckboxRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    // if (!params.data.parent) {
    this.params = params;
    // }
  }

  refresh(): boolean {
    return true;
  }

}
