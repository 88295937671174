import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ag-select-action-cell-renderer',
  templateUrl: './ag-select-action-cell-renderer.component.html',
  styleUrls: ['./ag-select-action-cell-renderer.component.css']
})
export class AgSelectActionCellRendererComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
