import { Component, OnInit } from '@angular/core';
import { AgNumberCountComponent, AgActionCellRendererComponent } from '../core/components';
import { Router } from '@angular/router';
import { GrievanceService } from './grievance.service';
@Component({
  selector: 'app-grievance',
  templateUrl: './grievance.component.html',
  styleUrls: ['./grievance.component.css']
})
export class GrievanceComponent implements OnInit {

  constructor(
    private router: Router,
    private grievanceService: GrievanceService
  ) { }


  contractorType = 1;

  filter = 0;
  userEmail = 'rajnish.mehat@bolr.co.in';
  userId = 653;
  userRoleCode = 101;

  rowData = [];
  columnDefs = [
    {
      headerName: '#', field: 'sortCode', width: 50, cellRendererFramework: AgNumberCountComponent,
      headerComponentParams: { enableSorting: false }, suppressMenu: true, headerHeight: 150,
      lockPinned: true,
    },
    {
      headerName: 'Grievanc eNo', field: 'Grievance_Code', width: 80, hide: 'true',
      filterParams: { applyButton: true, clearButton: true },
      lockPinned: true,
    },
    {
      headerName: 'Request No', field: 'RequestNbr', width: 150,
      filterParams: { applyButton: true, clearButton: true },
      lockPinned: true,
    },
    {
      headerName: 'Grievance Subject ', field: 'GrievanceSubject', width: 300,
      filterParams: { applyButton: true, clearButton: true }, cellStyle: { 'white-space': 'normal' },
      lockPinned: true,
    },
    {
      headerName: 'Contractor Name', field: 'ContractorName', width: 200,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'WorkOrder No', field: 'WorkOrderNo', width: 100,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Grivance Category', field: 'GrivanceCategory', width: 300,
      filterParams: { applyButton: true, clearButton: true }, cellStyle: { 'white-space': 'normal' }
    },
    {
      headerName: 'Priority', field: 'Priority', width: 70,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Impact', field: 'Impact', width: 70,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Addressal Date', field: 'DisGrievancDate', width: 101,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Entry Date', field: 'DisDateofEntry', width: 100,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Expected Date', field: 'DisExpectedDate', width: 100,
      filterParams: { applyButton: true, clearButton: true }
    },
    { headerName: 'Status', field: 'FStatus', width: 100, filterParams: { applyButton: true, clearButton: true } },
    {
      headerName: '', field: '', width: 100, headerComponentParams: { enableSorting: false },
      suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
    }
  ];

  ngOnInit() {
    this.getGrivanceData(this.filter, this.userEmail, this.userId, this.userRoleCode);
  }

  getGrivanceData(filter, userEmail, userId, userRoleCode) {
    this.grievanceService.getGrivanceData(filter, userEmail, userId, userRoleCode).subscribe((res: []) => {
      this.rowData = res;
    });
  }

  onTabChange(event) {
    // this.getContractorList(event.nextId);
  }

  editContractor(rowData) {
    this.router.navigate([`/edit-create-grievance/${rowData.Grievance_Code}`]);
    console.log('edit', rowData);
  }

  deleteContractor(index) {
    this.rowData.splice(index, 1);
  }
  addButton() {
    this.router.navigate(['/create-grievance']);
  }
}
