import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GridApi, GridOptions } from "ag-grid-community";
import { forkJoin } from "rxjs";
import { GLOBAL } from "src/app/app.globals";
import { AgNumberCountComponent, AgSelectActionCellRendererComponent } from "src/app/core/components";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { ContractorType, GatePassAccociateInformation, GatepassRequestProcess } from "../gatepass-request-process.model";
import { GatepassRequestProcessService } from "../gatepass-request-process.service";
import { HttpClient } from "@angular/common/http";
import { SharedService } from "src/app/core/services";
import { GatePassRequestsService } from "../../gate-pass-requests.service";

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-contractor-selection',
  templateUrl: './contractor-selection.component.html',
  styleUrls: ['./contractor-selection.component.css']
})
export class ContractorSelectionComponent implements OnInit {

  @Input() public stepData: GatepassRequestProcess;
  @Input() public globalSettings: any[];
  @Input() public alertMessages: any[];
  @Input() public contractorList: any[] = [];
  @Input() public isContractor: boolean;
  @Input() public isDisableContractorPrevBtn: boolean;

  @Output() public handleSave: EventEmitter<GatePassAccociateInformation>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<number>;

  public currentStep: number = 1;
  public allStepDataObj: any;
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public gridAPI: GridApi;
  public isCheckApprovalStatus: boolean = true;
  public isHidePOInput: boolean;

  public get headerTitle(): string {
    return this.stepData ? this.stepData.isSubContractorFlowStarted ? 'Sub Contractor Selection' : 'Contractor Selection' : '';
  }

  private contractorVerifiedMessage: string;
  private contractorVerifiedMessageT: string;

  constructor(
    private httpClient: HttpClient,
    private gatepassRequestProcessService: GatepassRequestProcessService,
    private logHelperService: LogHelperService,
    public sharedService: SharedService,
    public gatepassService: GatePassRequestsService
  ) {
    this.handleSave = new EventEmitter<GatePassAccociateInformation>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<number>();

    this.createColumnDef();
    this.createGridOptions();
  }

  public ngOnInit(): void {
    this.gatepassService.getRequestTypeText(this.stepData);
    this.isCheckApprovalStatus = this.globalSettings.find(setting => setting.Code === browserWindowEnv.CONTRACTOR_APPROVAL_STATUS).Value;
    //this.isHidePOInput = this.globalSettings.find(setting => setting.Code === browserWindowEnv.CONTRACTOR_REQ_CONSTATUS).Value;
    const verifiedMessageObj = this.alertMessages.find(item => item.Short_Code === 'MSG195_6');
    const verifiedMessageObjT = this.alertMessages.find(item => item.Short_Code === 'MSG195_5');
    if (verifiedMessageObj) {
      this.contractorVerifiedMessage = verifiedMessageObj.MSG;
    }
    if (verifiedMessageObjT) {
      this.contractorVerifiedMessageT = verifiedMessageObjT.MSG;
      console.log(verifiedMessageObjT);
    }
  }

  public onNextAction(): void {
        this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      this.sharedService.setGlobalSettings(res);
      if (res) {
        this.globalSettings = res.Data.Table;
        const contactorSetting = this.globalSettings.find(item => item.Code === 'MOD52PG195REQCONSTATUS');
        if (contactorSetting && contactorSetting.Value === '1') {
          this.isHidePOInput = false;
        }
        else if (contactorSetting && contactorSetting.Value === '0') {
          this.isHidePOInput = true;
        }
      }
    });
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  public onPrevAction(): void {
    this.handlePrevAction.emit(this.stepData.contractorType === 'SUB_CONTRACTORS_MAN' ? 6 : 1);
  }

  public onRowClicked(item: any): void {
    const data = this.globalSettings.find(setting => setting.Code === 'MOD152PG195CONAPVV01');
    if (data && data.Value === '1') {
      const requestObj = {
        UserId: GLOBAL.USER_ID,
        RequireType: 0,
        //ContractorVendor_Code: item.data.ContractorVendor_Code,
        ContractorVendor_Code: 103,
        ActionType: 1,
        ApproverUSers: null
      };
      console.log(item.data.Status);
      if(item.data.Status === 'Active'){
        console.log('inside')
        //const verifiedMessageObjT = this.alertMessages.find(item => item.Short_Code === 'MSG195_5');
        if (this.isCheckApprovalStatus) {
          this.gatepassRequestProcessService.checkContractorStatus(requestObj).subscribe((checkContractorStatus: any[]) => {
            if (checkContractorStatus && checkContractorStatus.length && checkContractorStatus[0].IsApproved) {
            // if (checkContractorStatus && checkContractorStatus.length) {
              this.handleSave.emit({ ...item.data }); 
            } else {
              this.logHelperService.logError({
                message: this.contractorVerifiedMessageT
              })
            }
          });
        } else {
          this.handleSave.emit({ ...item.data });
        }
      } else {
        console.log('else')

        this.logHelperService.logError({
          message: this.contractorVerifiedMessage
        })
      }
      return;
    }

    this.handleSave.emit({ ...item.data });
  }

  private createColumnDef() {
    this.columnDefs = [
      {
        headerName: '#', field: '',
        width: 10, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: 'CCN', field: 'ContractorVendor_Code',
        width: 50, filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' }
      },
      {
        headerName: 'Vendor Code', field: 'ShortCode',
        width: 100, filterParams: { applyButton: true, clearButton: true }, filter: true,cellStyle: { textAlign: 'center' }
      },
      {
        headerName: 'Contractor', field: 'Company', width: 150, filterParams:
        { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true,
        cellStyle : { 'text-align' : 'left !important'}
      },
      {
        headerName: 'Email ID', field: 'EmailID', width: 170, filterParams:
          { applyButton: true, clearButton: true }, sortable: true, filter: false
      },
      {
        headerName: 'PAN', field: 'PANNo', width: 100, filterParams:
          { applyButton: true, clearButton: true }, sortable: true, filter: false
      },
      {
        headerName: 'Status', field: 'Status', width: 80, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: false, cellStyle: { textAlign: 'center' },
        //hide : true,
        hide: !this.isHidePOInput,
        cellRenderer: ({ data }) => {
          if (!data.Status) {
            return null;
          }
          if (data.Status === 'Deactivate') {
            return `<span class="badge badge-danger">${data.Status}</span>`;
          }

          return `<span class="badge badge-success">${data.Status}</span>`;
        }
      },
      {
        headerName: 'Action', field: '',width : 50,suppressSizeToFit: true, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: true, cellRendererFramework: AgSelectActionCellRendererComponent,
        type : 'centerAligned', cellStyle : { 'text-align' : 'center !important'}
      }
    ];
  }

  private createGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        return 37;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 40,
    };
  }

}