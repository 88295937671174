import { Directive, ElementRef, HostListener, Input, ModuleWithProviders } from '@angular/core';
/**
 * Directive which can allow only number
 */
@Directive({ selector: '[appOnlyNumber]' })
export class OnlyNumbeDirective {
  public regexStr = '^[0-9]*$';
  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) public onKeyDown(event: Event): void {
    const e: KeyboardEvent = <KeyboardEvent>event;
    // if (this.OnlyNumber) {
    // tslint:disable-next-line:deprecation
    if (e.keyCode === 13) {
      return;
    }
    // tslint:disable-next-line:deprecation
    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 && !e.shiftKey || (e.keyCode >= 96 && e.keyCode <= 105)
    // tslint:disable-next-line:deprecation
      || (e.keyCode >= 35 && e.keyCode <= 39)) {
    // tslint:disable-next-line:deprecation
      if (e.keyCode === 38) {
        e.preventDefault();
        return;
      }
      return;
    }
    // tslint:disable-next-line:deprecation
    if (e.keyCode === 229 || e.keyCode === 38) {
      e.preventDefault();
      return;
    }
    // tslint:disable-next-line:deprecation
    const ch: string = String.fromCharCode(e.keyCode);
    const regEx: RegExp = new RegExp(this.regexStr);
    if (regEx.test(ch)) {
      if (!/^[a-z A-Z ]*$/.test(ch) && !e.shiftKey) {
        return;
      }
    }
    e.preventDefault();
    // }
  }
}
