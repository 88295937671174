import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef,
  Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import * as faceApi from '../../../assets/Scripts/face-api.min.js';



@Component({
  selector: 'app-face-cam',
  templateUrl: './face-train-cam.component.html',
  styleUrls: ['./face-train-cam.component.css']
})

export class FaceTrainCameraComponent implements OnInit, OnDestroy, AfterViewInit {



  //
  @ViewChild('canvas')
  public canvas: ElementRef;
  @ViewChild('video')
  public video: ElementRef;


  private labels: string;
  private systems: string;

  isShowPlay = true;
  @Input() set label(label: string) {
    if (label) {
      this.labels = label;
    }
  }
  get label(): string {
    return this.labels;
  }


  @Input() set system(system: string) {
    if (system) {
      this.systems = system;
    }
  }
  get system(): string {
    return this.systems;
  }


  @Output() getTrainData = new EventEmitter();

  isModuleLoaded: boolean;


  constructor(private cdr: ChangeDetectorRef) {

    navigator.getUserMedia = (navigator.getUserMedia);
  }

  async ngOnInit() {

    try {
      this.isModuleLoaded = false;
      await this.loadModule();
      this.isModuleLoaded = true;
    } catch (error) {
      alert('fail to load model');
    }



  }
  ngAfterViewInit() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
      });
    }
  }

  startVideo() {
    this.video.nativeElement.play();
    this.isShowPlay = false;
  }
  emitTrainData(value) {
    this.getTrainData.emit(value);
  }

  public async capture() {
    this.video.nativeElement.pause();
    const detections = await faceApi.detectSingleFace(this.video.nativeElement,
      new faceApi.TinyFaceDetectorOptions({ minConfidence: 0.8, inputSize: 192 })).withFaceLandmarks().withFaceDescriptor();
    if (detections) {
      const labeledFaceDescriptorsObj = new faceApi.LabeledFaceDescriptors(this.labels, [detections.descriptor]);
      this.emitTrainData({ ...labeledFaceDescriptorsObj, system: this.systems });
    } else {
      alert('Photo is not clear so plz try again!!');
    }
    this.isShowPlay = true;
  }

  ngOnDestroy() {
  }
  async loadModule() {
    await faceApi.nets.faceRecognitionNet.loadFromUri('assets/models');
    await faceApi.nets.faceLandmark68Net.loadFromUri('assets/models');
    await faceApi.nets.ssdMobilenetv1.loadFromUri('assets/models');
    await faceApi.nets.tinyFaceDetector.loadFromUri('assets/models');
  }

}
