import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-wo-task-detail-grid-wrapper',
  templateUrl: './reports-grid-wrapper.component.html',
  styleUrls: ['./reports-grid-wrapper.component.css']
})
export class WOTaskDetailGridWrapperComponent implements OnInit {

  rowClassRules;
  // @Input() gridOptions;
  @Input() columnDefs = [];
  @Input() rowData = [];
  @Input() selectedData: any;
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() rightArrowClick: EventEmitter<any> = new EventEmitter();

  numberOfRowsPerPage = 10;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  gridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    rowSelection: 'multiple',
    isExternalFilterPresent: () => true,
    // doesExternalFilterPass: node => node.data.isVisible,
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
      this.gridAPI.forEachLeafNode((node) => {
        if (this.selectedData) {
          if (this.selectedData.IsApproved) {
            if (this.selectedData.IsApproved === 1 || this.selectedData.IsApproved === true) {
              // node.setSelected(node.data.isSelected);
              node.data.isSelected = true;
            } else {
              node.data.isSelected = this.selectedData;
            }
          }
        }
      });
    },
    onGridSizeChanged: (params) => {
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
    },
    onColumnResized: (params) => {
      params.api.resetRowHeights();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    headerHeight: 50,
  };

  @Input('autoRowHeight')
  set autoRowHeight(value: boolean) {
    if (value) {
      this.gridOptions.getRowHeight = null;
    }
  }

  constructor() { }

  ngOnInit() {
    console.log('ngOnInit ====== 1');
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };
  }

  onRowSelected(event) {
    this.rowData[event.rowIndex].isSelected = event.node.selected;
  }

  onRightArrowClick(params) {
    this.rightArrowClick.emit(params);
  }
}
