import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQrCodeComponent } from './app-qr-code.component';

@Injectable({
  providedIn: 'root'
})
export class AppQrCodeService {

  constructor(
    private modalService: NgbModal
  ) { }

  generateQRCode(data, width?): void {
    const modalRef = this.modalService.open(AppQrCodeComponent,
      { backdrop: 'static', windowClass: 'qr-code-modal-panel' });

    const appQrCodeComponent: AppQrCodeComponent = modalRef.componentInstance;
    appQrCodeComponent.setData(data);
    appQrCodeComponent.setWidth(width);
    appQrCodeComponent.generate();
  }

}
