import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GLOBAL } from "src/app/app.globals";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { slideInLeft, slideInRight } from "./animations/common";
import { WOTaskListService } from "./wo-task-list.service";
import { animate, style, transition, trigger } from '@angular/animations';
import { WOGeneralUndertakingFormComponent } from "./general-undertaking-form/general-undertaking-form.component";
import { WOHRCContractorDetail, WOHRCContractorTotalDetail, WOHRCDetail, WOHRCRequestFlowDetail } from "./wo-task-model";
import * as moment from "moment";
import { SharedService } from "src/app/core/services";
import { WOTaskActionModalComponent } from "./wo-task-action-modal/wo-task-action-modal.component";

@Component({
  selector: 'app-wo-task-list',
  templateUrl: './wo-task-list.component.html',
  styleUrls: ['./wo-task-list.component.css'],
  host: {
    class: 'h-100 overflow-hidden d-flex flex-column'
  },
  // animations: [slideInLeft, slideInRight]
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(500)]),
      transition(':leave', [animate(500, style({ height: 0 }))]),
    ]),
  ],
})
export class WOTaskListComponent implements OnInit {

  @Input() public statusList: any[];
  @Input() public documentTypes: any[];
  @Input() public taskList: any[];
  @Input() public alertMessages: any[];
  @Input() public hrcDetailsRoleWise: any[];
  @Input() public hrcRequestFlowDetails: WOHRCRequestFlowDetail;
  @Input() public hrcDetails: WOHRCDetail;
  @Input() public hrcContractorDetail: WOHRCContractorDetail;
  @Input() public hrcContractorTotalData: WOHRCContractorTotalDetail;

  @Output() public closeModal: EventEmitter<any>;
  public isShowTaskDetailPanel: boolean;
  // public taskList$: Observable<any[]> = this.wOTaskListService.taskList$;
  public taskDetail$: Observable<any[]> = this.wOTaskListService.taskDetail$.pipe(
    tap(res => res ? this.isShowTaskDetailPanel = true : this.isShowTaskDetailPanel = false));
  public generalUndertakingTaskList$: Observable<any[]> = this.wOTaskListService.generalUndertakingTaskList$;

  public taskDocuments: any[];
  public currentTaskDetailObj: any;
  public isDisableReSubmit: any;
  public removeDocument: number;
  // public isContractor: any= 119;
  //  role = 119, show general undertaking , re submit
  // Action button hide to 119 and show to all user
  // ok

  constructor(
    private wOTaskListService: WOTaskListService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
    public sharedService: SharedService
  ) {
    this.closeModal = new EventEmitter();
  }

  public ngOnInit(): void {
    console.log('hrcRequestFlowDetails', this.hrcRequestFlowDetails);
    
    this.wOTaskListService.taskDocuments$.subscribe(res => {

      this.taskDocuments = res.map(item => (
        {
          ...item,
          fileFullPath: `${GLOBAL.APIS.BASE_API_URL}${item.ParentRootFolder}${item.UploadFileName}`
        }
      ));
    });
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public getTaskDetail(task: any): void {
    this.currentTaskDetailObj = task;
    this.taskDocuments = [];
    this.wOTaskListService.setTaskDetailAction(task.Task_Code);
  }

  public handleTaskInputSave(data: any): void {
    this.saveHRCTaskInput(data);
  }
  public handleBack(): void {
    this.currentTaskDetailObj = null;
    this.wOTaskListService.setTaskDetailAction(null);
  }

  public ReSubmitRequestHRC(): void {
    this.saveHRCRequest({ RequestStatus_Code: 845, IsReSubmit: true });
  }

  public handleActionClick(): void {
    const [isValid, message] = this.checkValidationOnAction();
    if (!isValid) {
      this.logHelperService.logError({ message });
      return;
    }

    const modalRef = this.modalService.open(WOTaskActionModalComponent, {
      size: 'sm', backdrop: 'static',
      windowClass: 'wo-task-upload-modal overflow-hidden'
    });
    modalRef.componentInstance.hrcDetails = this.hrcDetails;
    modalRef.componentInstance.statusList = this.statusList;
    modalRef.componentInstance.closeModal.subscribe((value: string) => modalRef.close(value));
    modalRef.componentInstance.handleSaveAction.subscribe((value: any) => this.saveHRCRequest(value, modalRef));
  }

  public handleGenerateUnderTaking(): void {
    const requestObj = {
      SiteMIL_Code: null,
      Plant_Code: null,
      WorkOrder_Code: this.hrcDetails.WorkOrderCode,
      Month_Code: this.hrcDetails.PayrollMonth_Code,
      RequireType: 1,
      ActionType: 1,
      UserId: GLOBAL.USER_ID
    };

    this.wOTaskListService.getGeneralUndertakingTaskList(requestObj).subscribe(res => {
      const modalRef = this.modalService.open(WOGeneralUndertakingFormComponent, {
        size: 'lg', backdrop: 'static',
        windowClass: 'modal-panel modal-scroll overflow-hidden'
      });
      modalRef.componentInstance.hrcDetails = this.hrcDetails;
      modalRef.componentInstance.taskList = this.taskList;
      modalRef.componentInstance.complianceTasks = res;
      // modalRef.componentInstance.handleSaveHRCRequest.subscribe((data: any) => this.saveHRCRequest(data, modalRef));
      modalRef.componentInstance.closeModal.subscribe((value: string) => modalRef.close(value));
    })
  }

  public saveHRCTaskInput(data: any): void {
    const requestObj = this.getHRCTaskInputRequest(data);
    if (data.files && data.files.length) {
      this.wOTaskListService.saveHRCTaskInput(requestObj).subscribe(res => {
        if (!res) {
          this.logHelperService.logError({
            message: 'Failed to save data.'
          })
          return;
        }

        this.wOTaskListService.hrcUploadDoc(this.getDocuments(data), data.files).subscribe(res => {
          if (!res) {
            this.logHelperService.logError({
              message: 'Failed to save data.'
            })
            return;
          }

          this.logHelperService.logSuccess({
            message: 'Data saved successfully.'
          });
        });
      });
    } else {
      this.wOTaskListService.saveHRCTaskInput(requestObj).subscribe(res => {
        if (!res) {
          this.logHelperService.logError({
            message: 'Failed to save data.'
          })
          return;
        }

        this.logHelperService.logSuccess({
          message: 'Data saved successfully.'
        });
      });
    }
  }

  private getHRCTaskInputRequest({ Task_Code, Remark, Amount, NoOfSubscription, Status, ActualDate, UnskillTotal, HighSkillTotal, SkillTotal, SemiSkillTotal, StaffTotal }: any): any {
    return {
      Workorder_Code: this.hrcDetails.WorkOrderCode,
      HRC_Code: this.hrcDetails.HRCCode,
      Task_Code,
      IsCoplied: 1,
      Remark,
      Amount,
      NoOfSubscription,
      Status,
      RoleCode: null,
      ActualDate: ActualDate ? moment(ActualDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
      UnskillTotal,
      HighSkillTotal,
      SkillTotal,
      SemiSkillTotal,
      StaffTotal,
      RequireType: 0,
      ActionType: 0,
      UserID: GLOBAL.USER_ID
    };
  }

  private getDocuments(data: any): any {
    return {
      DShort_Code: 'DOCP22206HRCT',
      Image_Type: data.documentTypes,  // HRC Undertaking
      RefTypeMasterList_Code: 1057,// HRC Module
      Referance_Code: data.Task_Code  // data
    };
  }

  public saveHRCRequest(data: any, modalRef?: any): void {
    const { PayrollMonth_Code: Month_Code, HRCCode: Reference_Code, WorkOrderCode, Request_Code, Plant_Code } = this.hrcDetails;
    const requestObj = {
      RequestType_Code: 106,
      LastRequestcode: null,
      Month_Code,
      Request_Code,
      RequestStatus_Code: data.RequestStatus_Code || null,
      Reference_Code,
      IsReSubmit: data.IsReSubmit || null,
      IsSubmitAllow: null,
      RequireType: 0,
      HoldAmount: data.HoldAmount || null,
      Plant_Code,
      ActionType: 0,
      IsComplied: data.IsComplied,
      Reason: data.Reason || null,
      UserId: GLOBAL.USER_ID,
      WorkOrder_Code: WorkOrderCode
    };

    this.wOTaskListService.saveHRCRequest(requestObj).subscribe(res => {
      if (!res) {
        this.logHelperService.logError({
          message: 'Failed to save data.'
        })
        return;
      }

      this.logHelperService.logSuccess({
        message: 'Data saved successfully.'
      });
      modalRef && modalRef.close('');
    })
  }

  private checkValidationOnAction(): [boolean, string] {
    if (this.taskList.filter(item => item.StatusMasterList_Code === 264).length) {
      this.logHelperService.logError({
        message: 'Still, the Contractor is not submitted HRC for the month. '
      });

      return [false, 'Still, the Contractor is not submitted HRC for the month. '];
    }

    if(this.hrcRequestFlowDetails && this.hrcRequestFlowDetails.MSG){
      return [false, this.hrcRequestFlowDetails.MSG]
    }

    return [true, null];
  }
}