import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-save-cell-renderer',
  templateUrl: './ag-save-cell-renderer.component.html',
  styleUrls: ['./ag-save-cell-renderer.component.css']
})
export class AgSaveCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
  }

  refresh() {
    return true;
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }

  save() {
    this.params.context.componentParent.saveRecord(this.params.data);
  }


}
