import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, ElementRef, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AgActionCellRendererComponent } from 'src/app/core/components';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';
import { Subject } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
// tslint:disable-next-line: max-line-length
import * as _ from 'lodash';
import { ReportViewListGroupRowComponent } from '../../../report-view-list-group-row/report-view-list-group-row.component';
import { formatDate } from '@angular/common';
import { AttendanceService } from '../../../attendance.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
@Component({
  selector: 'app-labour-attendance-sycreprocess',
  templateUrl: './labour-attendance-sycreprocess.component.html',
  styleUrls: ['./labour-attendance-sycreprocess.component.css']
})
export class LabourAttendanceSycreprocessComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public tableRowData1: any[] = [];
  public myMap: any[] = [];
  public myMap1: any[] = [];
  selectedRows: any[] = [];
  public reportDate: string;
  public totalData: number;
  private defaultColDef;
  private defaultColDef1;
  private isRowSelectable;
  private editType;
  getRowNodeId;
  gridColumnApi;
  private gridApi;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  private selectedReportInfo: any;
  cValue = new Date();

  myDate = formatDate(this.cValue, 'dd-MM-yyyy', 'en-US');
  @Output() public save: EventEmitter<void>;
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;

  public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  columnDefs: any[] = [];
  rowData = [];
  groupingMeta = ['Company'];
  private reportValueData: any[];

  constructor(private sharedService: SharedService, private router: Router,
    private attendanceService: AttendanceService, private logHelperService: LogHelperService) {
    this.closeModal = new EventEmitter();
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {
      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true; // !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        // this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: true,
      headerHeight: 40,
      // isFullWidthCell: (rowNode) => {
      //     return rowNode.data.fullWidth;
      // },
    };
    this.getRowNodeId = function (data) {
      return data.group ? data.group : `${data.Labour_Code}`;
    };
  }

  ngOnInit() {
    this.totalData = this.reportValueData.length;
    console.log('a', this.totalData);
    this.reportDate = this.dateConvertor(this.selectedReportInfo);
    console.log('b', this.reportDate);
    this.setColumnDef();
  }
  // private createColumnDef(): void {
  //   this.columnDefs = [
  //     {
  //       headerName: '', field: '', width: 30,
  //       pinned: 'left',
  //       sortable: false, suppressMenu: true,
  //       cellRenderer: this.rowIndexCellRenderer,
  //       filter: false,
  //       checkboxSelection: true,
  //       headerCheckboxSelection: true,

  //     },
  //     {
  //       headerName: 'GatePassNo', field: 'GatePassNo', width: 70, filterParams:
  //         { applyButton: true, clearButton: true }, filter: true
  //     },
  //     {
  //       headerName: 'Full Name', field: 'FullName', width: 200, filterParams:
  //         { applyButton: true, clearButton: true }, filter: true
  //     },
  //     {
  //       headerName: 'Company', field: 'Company',
  //       width: 150, headerComponentParams: { enableSorting: false }, suppressMenu: true,
  //       filter: true
  //     },
  //     {
  //       headerName: 'Issue Date', field: 'IssueDate', width: 100, filterParams:
  //         { applyButton: true, clearButton: true }, filter: true
  //     },
  //     {
  //       headerName: 'Valid Upto Date', field: 'ValidUptoDate', width: 100, filterParams:
  //         { applyButton: true, clearButton: true }, filter: true
  //     },
  //     {
  //       headerName: 'Department Name', field: 'DepartmentName', width: 100, filterParams:
  //         { applyButton: true, clearButton: true }, filter: true
  //     }
  //   ];
  // }
  @Input()
  public set selectedReportData(value: any) {
    if (value) {

      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): any {
    return this.selectedReportInfo;
  }


  @Input()
  public set reportData(value: any[]) {
    if (value) {
      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
      // this.rowData = value;
    }
  }
  public get reportData(): any[] {
    return this.reportValueData;
  }
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs = [
      {
        headerName: '', field: '', width: 30,
        pinned: 'left',
        sortable: false, suppressMenu: true,
        cellRenderer: this.rowIndexCellRenderer,
        filter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,

      },
      {
        headerName: '#',
        width: 50,
        pinned: 'left',
        suppressMenu: true,
        valueGetter(params) {
          return params.data.group ? '' : params.node.rowIndex;
        },
        colSpan: (params) => {
          return params.data.group ? 8 : 1;
        },
      },
      // {
      //   headerName: 'Company',
      //   field: 'Company',
      //   hide: true, visible: false,
      // //   // headerName: 'Company', field: 'ContractorName', width: 100, sortable: true, filter: true,
      // //   // filterParams: { applyButton: true, clearButton: true },
      // },
      {
        headerName: 'GatePass No', width: 100, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true },
        valueGetter(params) {
          return params.data.group ? params.data.group : params.data.GatePassNo;
        },
        colSpan: (params) => {
          return params.data.group ? 5 : 1;
        },
      },
      // {
      //   headerName: 'GatePassNo', field: 'GatePassNo', width: 70, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      {
        headerName: 'Full Name', field: 'FullName', width: 200, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Company', field: 'Company',
        width: 150, headerComponentParams: { enableSorting: false }, suppressMenu: true,
        filter: true
      },
      {
        headerName: 'Issue Date', field: 'IssueDate', width: 60, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Valid Upto Date', field: 'ValidUptoDate', width: 60, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      // {

      //   headerName: 'Issue Date', field: 'Issue Date', width: 70, filterParams:
      //   { applyButton: true, clearButton: true }, filter: true
      //   // headerName: 'Issue Date', field: 'IssueDate', width: 100, filterParams:
      //   //   { applyButton: true, clearButton: true }, filter: true,
      //   // cellRenderer: (data) => {
      //   //   return moment(data.IssueDate).format('DD/MM/YYYY');
      //   // }
      // },
      // {

      //   headerName: 'ValidUptoDate', field: 'ValidUptoDate', width: 70, filterParams:
      //   { applyButton: true, clearButton: true }, filter: true
      //   // headerName: 'Valid Upto Date', field: 'ValidUptoDate', width: 100, filterParams:
      //   //   { applyButton: true, clearButton: true }, filter: true,
      //   // cellRenderer: (data) => {
      //   //   return moment(data.ValidUptoDate).format('DD/MM/YYYY');
      //   //}
      // },
      {
        headerName: 'Department Name', field: 'DepartmentName', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      }
    ];
  }

  onRowSelected(event) {
    if (event.data.parent) {
      // const targetIdx = event.data.map(item => item.IssueDate).indexOf(this.selectedRows.Date);
      // this.createnode[targetIdx] = this.updateWith;
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.Company === event.data.group) {

          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    }
  }
  private onRowEditingStarted(event) {
    if (!this.gridApi.getSelectedRows().find(e => e.rowIndex === event.rowIndex)) {
      this.gridApi.stopEditing();
    }
  }
  onApprove() {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    // this.selectedRows.push(this.selectedReportInfo);
    // this.selectedRows.push[this.reportDate];
    if (this.selectedRows.length > 0) {
      const finalData = this.selectedRows.map(item => {
        return {
          ...item,
          Date: this.selectedReportData.Date,
          EDate: this.selectedReportData.EDate,
          Contractor_Code: item.ContractorVendor_Code
        }
      })
      // tslint:disable-next-line: ter-indent
      this.attendanceService.attendancereprocess(finalData).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Attendance Reprocess Sucessfully'
        });
      });
      this.closeModal.next();
      this.save.emit();
    } else {
      this.logHelperService.logError({
        message: 'Please Select Alteast One Record'
      });
    }

  }

  public onCloseAction(): void {
    this.closeModal.next();
  }


  onEdit(params) {
    // this.editClick.emit(params);
  }

  onDelete(params) {
    // this.deleteClick.emit(params);
  }

  nestGroupBy = (seq, keys) => {
    // tslint:disable-next-line: curly
    if (!keys.length)
      return seq;
    // tslint:disable-next-line: no-var-keyword
    // tslint:disable-next-line: prefer-const
    let first = keys[0];
    const rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return this.nestGroupBy(value, rest);
    });
  }

  getGroupRowTableData1(reportData: any[]): any[] {
    // tslint:disable-next-line: max-line-length
    const data = this.nestGroupBy(reportData, ['ContractorName']);
    console.log('data', data);
    const tableData1 = [];
    return data;
  }
  getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    let index = 1;
    // reportData =  reportData.filter((item,index)=>index <= 10)
    const groupData = new Set(reportData.map(item => item.ContractorName));
    groupData.forEach((groupItem: string) => {
      const items = reportData.filter(item => item.ContractorName === groupItem);
      items.forEach((item: any) => {
        item.index = index;
        index++;
      });
      const rowItems = {
        items,
        ContractorName: groupItem
      };
      tableData.push(rowItems);
    });
    return tableData;
  }
  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    // tslint:disable-next-line: max-line-length
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} To ${date1.getDate()}-${months[date1.getMonth()]}-${date1.getFullYear()}`;
  }
}
