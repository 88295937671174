import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReportBuilderAPI, GLOBAL, BASE_URL } from 'src/app/app.globals';
import { map } from 'rxjs/operators';
declare var $: any;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ReportBuilderService {
  httpClient: any;

  constructor(
    private http: HttpClient
  ) { }

  getDataForAPI(api: ReportBuilderAPI) {
    const params = {};
    if (api.Params) {
      for (let index = 0; index < api.Params.length; index++) {
        const param = api.Params[index];
        if (param.ValueData) {
          params[param.Name.trim()] = param.ValueData;
        } else {
          params[param.Name.trim()] = null;
        }
      }
    }

    if (api.Method === 'POST') {
      const body = JSON.stringify(params);
      httpOptions['body'] = body;
      return this.http.request(
        api.Method,
        BASE_URL + api.Url,
        httpOptions
      );
    }
    return this.http.request(
      api.Method || 'GET',
      `${BASE_URL}..${api.Url}?${$.param(params)}`,
      httpOptions
    );
  }


  getAPIList() {
    const model = {
    };
    return this.http.post(`${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_ALL_RB_API}`, model);
  }

  // return this.http.request<any>(
  // GLOBAL.HTTP_GET,
  // GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_ALL_RB_API,
  // httpOptions
  // ).pipe(map(res => res.Data));
  // }

}
