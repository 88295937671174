export enum MenuItemType {
  LEFTBAR = 1,
  TOPBAR = 2,
  TOPDROPDOWN = 3,
  COMMONMASTER = 4,
  CONFIGURATION = 5,
  REPORT = 6,
  SETTING = 7,
  APPLICATIONGROUP = 8
}
