import { Injectable } from '@angular/core';

declare var d3: any;
declare var dc: any;

interface Config {
  crossfilter: any;
  crossfilter2?: any;
  filterGroup: string;
  chartId: string;
  groupByProperty: string;
  reduceProperty: string;
  width: number;
  height: number;

  // Optional
  gap?: number;
  outerPadding?: number;
  yAxisLabel?: string;
  xAxisLabel?: string;
  elasticX?: boolean;
  elasticY?: boolean;
  margins?: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  };
  legend?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RowChartService {

  constructor() { }

  generateRowChart(config: Config, dimension, group) {
    const chart = dc.rowChart(config.chartId, config.filterGroup);
    chart
      .width(config.width)
      .height(config.height)
      .ordinalColors(["#5B4DB7","#42ADC7","#81D152","#F5F263","#FF9D4F","#FF5347"])
      .dimension(dimension)
      .group(group)
      .ordering(
        // tslint:disable-next-line: ter-prefer-arrow-callback
        function (d: { value: number; }) { return -d.value; }
      )
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .label((d) => {
        const totalValue = d3.sum(group.all(), d => d.value);
        return `${d.key}: ${d.value} (${Math.round((d.value / totalValue) * 100)}%)`;
      })
      .on('postRender', (chart) => {
        this.addYLabel(chart, config.yAxisLabel);
        this.addXLabel(chart, config.xAxisLabel);
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(50).y(10).itemWidth(70).horizontal(true)
        .legendText((d) => {
          return d.name;
        }));
    }

    chart.render();
    return chart;
  }

  getRowChartContractorWiseChart(config: Config) {
    const contractorDimension = config.crossfilter.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorULCGroup = contractorDimension.group().reduceCount((d: any) => {
      let cnt = 0;
      if (d.IsTenDays === true) {
        cnt++;
      }
      return cnt;
    });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup.all().filter((d) => {
            return d.value !== 0;
          }).slice(0, 5);
        }
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    return this.generateRowChart(config, contractorDimension, fakeGroup);
  }


  getRowChart(config: Config) {
    const dimension = config.crossfilter.dimension((d) => {
      return d[config.groupByProperty];
    });
    const group = dimension.group().reduceCount((d) => {
      return d[config.reduceProperty];
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(group);

    return this.generateRowChart(config, dimension, nonEmptyData);
  }


  // Functions to add x-label & y-label to Row Charts (Unsupported by dc.js)
  addXLabel = (chartToUpdate, displayText) => {
    const textSelection = chartToUpdate.svg()
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', chartToUpdate.width() / 2)
      .attr('y', chartToUpdate.height())
      .text(displayText);
    const textDims = textSelection.node().getBBox();
    const chartMargins = chartToUpdate.margins();

    // Dynamically adjust positioning after reading text dimension from DOM
    textSelection
      .attr('x', chartMargins.left + (chartToUpdate.width()
        - chartMargins.left - chartMargins.right) / 2)
      .attr('y', chartToUpdate.height());// - Math.ceil(textDims.height) / 2);
  }

  addYLabel = (chartToUpdate, displayText) => {
    const textSelection = chartToUpdate.svg()
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('x', -chartToUpdate.height() / 2)
      .attr('y', 10)
      .text(displayText);
    const textDims = textSelection.node().getBBox();
    const chartMargins = chartToUpdate.margins();

    // Dynamically adjust positioning after reading text dimension from DOM
    textSelection
      .attr('x', -chartMargins.top - (chartToUpdate.height()
        - chartMargins.top - chartMargins.bottom) / 2)
      .attr('y', Math.max(Math.ceil(textDims.height), chartMargins.left
        - Math.ceil(textDims.height) - 5));
  }

  getRowContractorWisePresentChart(config: Config) {
    const contractorDimension = config.crossfilter.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorDimension2 = config.crossfilter2.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorULCGroup = contractorDimension.group().reduceSum((d: any) => {
      return d.ActualDeployed;
    });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup.all().filter((d) => {
            return d.value !== 0;
          }).slice(0, 20);
        }
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    return this.generateRowChart(config, mirror_dimension([contractorDimension, contractorDimension2]), fakeGroup);
  }

  getRowCategoryWisePresentChart(config: Config) {
    const contractorDimension = config.crossfilter.dimension((d: any) => {
      return d.WorkmanCategory;
    });

    const contractorDimension2 = config.crossfilter2.dimension((d: any) => {
      return d.CategoryName;
    });

    const contractorULCGroup = contractorDimension.group().reduceCount((d: any) => {
      return d.ULC;
    });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup.all().filter((d) => {
            return d.value !== 0;
          }).slice(0, 5);
        }
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    return this.generateRowChart(config, mirror_dimension([contractorDimension, contractorDimension2]), fakeGroup);
  }

  getRowChartContractorWiseCWChart(config: Config) {
    const contractorDimension = config.crossfilter.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorDimension2 = config.crossfilter2.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorULCGroup = contractorDimension.group().reduceSum((d: any) => {
      return d[config.reduceProperty];
    });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup.all().filter((d) => {
            return d.value !== 0;
          }).slice(0, 15);
        }
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    return this.generateRowChart(config, mirror_dimension([contractorDimension, contractorDimension2]), fakeGroup);
  }
}

function mirror_dimension(dims) {
  function mirror(fname) {
    return function (v) {
      dims.forEach((dim) => {
        dim[fname](v);
      });
    };
  }
  return {
    filter: mirror('filter'),
    filterExact: mirror('filterExact'),
    filterRange: mirror('filterRange'),
    filterFunction: mirror('filterFunction')
  };
}
