import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ReportExportService {

  constructor() { }

  public exportExcelFileFromElement(element: HTMLElement, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${fileName}`);

    /* save to file */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  public exportAsExcelFile(json: any[], excelFileName: string, date: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName, date);
  }

  private saveAsExcelFile(buffer: any, fileName: string, date: any): void {

    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, `${fileName}_export_${Date}${EXCEL_EXTENSION}`);
    // FileSaver.saveAs(data, fileName + '_' + date + EXCEL_EXTENSION);
    const content = `${fileName}_${date}${EXCEL_EXTENSION}`;
    FileSaver.saveAs(data, content);
  }
}
