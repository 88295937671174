import { Component, OnInit } from '@angular/core';
import { SharedService } from '../core/services';
import { AgActionCellRendererComponent, AgNumberCountComponent } from '../core/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditGlobalSettingComponent } from './edit-global-setting/edit-global-setting.component';
import { LogHelperService } from '../core/services/log-helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-setting',
  templateUrl: './global-setting.component.html',
  styleUrls: ['./global-setting.component.css']
})
export class GlobalSettingComponent implements OnInit {
  
  agGridData: Array<any> = [];
  tableColDefs: any[] = [
    {
      headerName: "#",
      field: '',
      width: 5,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: "Group Code",
      field: "GroupCode",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Code",
      field: "Code",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Apps",
      field: "Apps",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Module",
      field: "Module",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Page",
      field: "Page",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Role",
      field: "Role",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "SettingName",
      field: "SettingName",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "ValueType",
      field: "ValueType",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Company Code",
      field: "Company_Code",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Value",
      field: "Value",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: 'Action', field: '', width: 10,
      sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
    }
  ];

  constructor(private router: Router, private sharedService: SharedService, private modalService: NgbModal, private logHelperService: LogHelperService,) { }

  ngOnInit() {
    this.getGridData();
  }
getGridData(){
  this.sharedService.getGlobalSettingData().subscribe((res:any)=> {
      this.agGridData = res;
  });
}
editGlobalSetting(rowData) {
  const modalRef = this.modalService.open(EditGlobalSettingComponent, {
    backdrop: 'static'
  });
  modalRef.componentInstance.settingData = rowData;
  modalRef.componentInstance.handleClose.subscribe((res: any) => {
    if (res) {
      this.sharedService.updateGlobalSetting(res).subscribe((response: any) => {
        if(response){
          this.logHelperService.logSuccess({
            message: 'Global Setting Update successfully'
          });
          this.getGridData();
        }else {
          this.logHelperService.logError({
            message: 'Global Setting not updated'
          });
        }
       
      });
    }
    modalRef.close('')
  });
}
}
