import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GridApi, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { AgNumberCountComponent, AgSelectActionCellRendererComponent } from "src/app/core/components";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { GatepassRequestProcess, GatePassRequestType } from "../gatepass-request-process.model";
import { GatePassRequestsService } from "../../gate-pass-requests.service";

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-license-selection',
  templateUrl: './license-selection.component.html',
  styleUrls: ['./license-selection.component.css']
})
export class LicenseSelectionComponent implements OnInit {

  @Input() public licenseList: any[] = [];
  @Input() public stepData: GatepassRequestProcess;
  @Input() public globalSettings: any[];
  @Input() public alertMessages: any[];
  @Input() public selectedPlantDetail: any;
  @Input() public selectedWorkOrderDetail: any;

  @Output() public handleSave: EventEmitter<any>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<void>;

  public currentStep: number = 1;
  public allStepDataObj: any;
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public gridAPI: GridApi;
  public stateCapacityExceedStatus: any;
  public showStatus: any;
  public verifyLicense: any;

  private parentLicenseList: any[] = [];

  private parentLicenseCode: number;
  private isCheckValidation: boolean;
  private isHideRemaining: boolean;
  isHideSkip: boolean = true;
  licenceExpireMessage: any;
  selectActiveMsg: any;
  noremainingMsg: any;
  constructor(
    private logHelperService: LogHelperService,
    public gatepassService: GatePassRequestsService
  ) {
    this.handleSave = new EventEmitter<any>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<void>();
    this.isCheckValidation = false;
    this.createColumnDef();
    this.createGridOptions();
  }

  public ngOnInit(): void {
    this.gatepassService.getRequestTypeText(this.stepData);
    // this.showStatus = this.globalSettings.find(item => item.Code === browserWindowEnv.LICENSE_SELECTION_STATUS_SHOW).Value;
    // this.verifyLicense = this.globalSettings.find(item => item.Code === browserWindowEnv.VERIFY_LICENSE).Value;
    // const workOrderSetting = this.globalSettings.find(item => item.Code === browserWindowEnv.RemainingPerson.licenseSelectionCode);
    // if (workOrderSetting && workOrderSetting.Value === '1') {
    //   this.isCheckValidation = true;
    // }
    // console.log("selectedWorkOrderDetail  -->",this.selectedWorkOrderDetail)
    // console.log("selectedPlantDetail  -->",this.selectedPlantDetail)
    // console.log("alertMessages  -->",this.alertMessages)
    console.log('licenseList', this.licenseList)
    this.licenceExpireMessage = this.alertMessages.find((i: any) => i.Short_Code == "MSG195_8")
    this.noremainingMsg = this.alertMessages.find((i: any) => i.Short_Code == "MSG195_1")
    this.selectActiveMsg = this.alertMessages.find((i: any) => i.Short_Code == "MSG195_3")

    this.setGlobalSetting()

  }

  setGlobalSetting() {
    this.showStatus = this.globalSettings.find(item => item.Code === "MOD52PG195REQLICSTATUS").Value;
    this.verifyLicense = this.globalSettings.find(item => item.Code === "MOD152PG195LICAPVV01").Value;
    const showSkipButton = this.globalSettings.find(item => item.Code === "MOD52PG195REQLICUMB");

    const workOrderSetting = this.globalSettings.find(item => item.Code === "MOD52PG195REQLICCAP");

    console.log("workOrderSetting --->", workOrderSetting)
    if (workOrderSetting && workOrderSetting.Value === '1') {
      this.isCheckValidation = true;
    }

    if (workOrderSetting && workOrderSetting.Value === '0') {
      this.isHideRemaining = true
    }
    else {
      this.isHideRemaining = false
    }

    let allLicenseExpired: boolean = false;
    let licenseExpiredCount: number = 0;
    if (this.licenseList.length !== 0) {
      this.licenseList.forEach((license: any) => {
        if (license.Status !== 'Active') {
          licenseExpiredCount++;
        }
      })
      if (this.licenseList.length === licenseExpiredCount) {
        allLicenseExpired = true;
      }
    }

    if (this.stepData.contractorType === 'SUB_CONTRACTORS_MAN' && showSkipButton && showSkipButton.Value === '0') {
      this.isHideSkip = false;
    } else if (this.stepData.contractorType === 'SUB_CONTRACTORS_MAN' && showSkipButton && showSkipButton.Value === '1') {
      if ((this.licenseList.length === 0 || allLicenseExpired) &&
        this.selectedWorkOrderDetail.ActivePerson < this.selectedPlantDetail.LicenseMaxValue) {
        this.isHideSkip = true;
      } else {
        this.isHideSkip = false;
      }
    }

    if (this.licenseList.length !== 0) {
      this.licenseList.forEach((license: any) => {
        // console.log("license --->",license)

        let validityDate = new Date(license.ValidtyDate)
        // console.log("validityDate -->",validityDate)
        let currentDate = new Date()
        // console.log("currentDate -->",currentDate)


        if (validityDate <= currentDate) {
          this.isHideSkip = false
        }
      })
    } else {
      this.isHideSkip = false  // You want to add true here
    }

    this.createColumnDef();
  }

  public onNextAction(): void {
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  public onPrevAction(): void {
    if (this.parentLicenseCode) {
      this.licenseList = this.parentLicenseList;
      this.parentLicenseCode = null;
      return;
    }
    this.handlePrevAction.emit();
  }

  public onSkipAction(): void {
    if (this.stepData.contractorType === 'SUB_CONTRACTORS_MAN' && this.stepData.requestType == 103) {
      const showSkipButton = this.globalSettings.find(item => item.Code === "MOD52PG195REQLICUMB");
      if (showSkipButton.Value == '0') {
        this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
      } else {
        this.checkConditionWithSkip();
      }
    } else {
      this.checkConditionWithSkip();
    }
  }

  checkConditionWithSkip() {
    let checkCondition = this.globalSettings.find(item => item.Code === 'MOD52PG195REQLICCAP').Value;
    let msg = this.alertMessages.find(item => item.Short_Code === 'MSG195_8').MSG;
    msg = msg.replaceAll('{State}', this.selectedPlantDetail.State);
    msg = msg.replaceAll('{Value}', this.selectedPlantDetail.LicenseMaxValue);
    if (this.selectedWorkOrderDetail.ActivePerson < this.selectedPlantDetail.LicenseMaxValue) {
      if (checkCondition == "1" && this.selectedWorkOrderDetail.ActivePerson >= this.selectedPlantDetail.LicenseMaxValue) {
        this.logHelperService.logError({
          message: msg,
          html: true
        })
      } else if (checkCondition == "2" && this.selectedWorkOrderDetail.ActivePerson >= this.selectedPlantDetail.LicenseMaxValue) {
        this.logHelperService.logError({
          title: 'Warning!',
          message: msg,
          html: true
        }, 'warning', 'Go to Next', true).then((res) => {
          if (res && res.value) {
            this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
          }
        })
      } else if (checkCondition == "0") {
        this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
      }else if(checkCondition == "1" && this.selectedWorkOrderDetail.ActivePerson < this.selectedPlantDetail.LicenseMaxValue){
        this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
      }else if(checkCondition == "2" && this.selectedWorkOrderDetail.ActivePerson < this.selectedPlantDetail.LicenseMaxValue){
        this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
      }
    } else if (this.selectedWorkOrderDetail.ActivePerson >= this.selectedPlantDetail.LicenseMaxValue) {
      if (checkCondition == "1") {
        this.logHelperService.logError({
          message: msg,
          html: true
        })
      } else if (checkCondition == "2") {
        this.logHelperService.logError({
          title: 'Warning!',
          message: msg,
          html: true
        }, 'warning', 'Go to Next', true).then((res) => {
          if (res && res.value) {
            this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
          }
        })
      } else if (checkCondition == "0"){
        this.handleSave.emit({ License_Code: null, NewLicense_Code: null, isSkipLicense: true });
      }
    }
  }

  public onRowClicked({ data: selectedData }: any): void {
    this.isCheckValidation = true;
    // console.log("GatePassRequestType type -->",GatePassRequestType.New)
    // if (this.stepData.requestType === GatePassRequestType.New && this.isCheckValidation && selectedData.Remaining <= 0) {
    //   this.logHelperService.logError({
    //     message: 'You cannot request more than license capacity also you are exceed as per CLRA without license capacity.'
    //   });

    //   return;
    // }

    if (this.parentLicenseCode) {
      this.handleSave.emit({ License_Code: this.parentLicenseCode, NewLicense_Code: selectedData.License_Code });
      return;
    }

    if (this.stepData.requestType == 104 && selectedData.Status == 'Expired') {
      let msg = this.alertMessages.find(item => item.Short_Code === 'MSG195_15').MSG;
      this.logHelperService.logError({
        title: 'Warning!',
        message: msg,
        html: true
      }, 'warning', 'Go to Next', true).then((res) => {
        if (res && res.value) {
          if (this.stepData.requestType === GatePassRequestType.Renew) {
            this.parentLicenseCode = selectedData.License_Code;
            this.parentLicenseList = this.licenseList;
            this.licenseList = this.licenseList.filter(item => item.License_Code !== selectedData.License_Code && item.Plant_Code === selectedData.Plant_Code);
          }
        }
      })
    } else {

      if (this.verifyLicense == 1) {
        if (selectedData.Status !== 'Expired') {
          this.handleSave.emit({ ...selectedData });
        } else {
          this.logHelperService.logError({
            title: 'OOPS!!!',
            message: 'License is already expired! Please extend validity date for selected License. Or select other active License.'
          });
        }
      }
      // } else if (this.verifyLicense == 0) {
      //   this.handleSave.emit({ ...selectedData });
      // }

      

      // milan 

      if (this.isHideRemaining) {
        if (selectedData.Status == "Expired") {
          if (!this.isHideSkip) {
            this.logHelperService.logError({
              title: 'OOPS!!!',
              // message: this.licenceExpireMessage.MSG
              message: "Selected license is expired. Please select active license or Skip the License selection option"
            });
          }
        }
      }

      if (!this.isHideRemaining) {
        if (selectedData.Status == "Active") {
          if (selectedData.Remaining === 0 && this.stepData.requestType === GatePassRequestType.New) {
            this.logHelperService.logError({
              title: 'OOPS!!!',
              // message: this.licenceExpireMessage.MSG
              message: this.noremainingMsg.MSG
            });
          } else {
            let remainingZeroMessage = this.alertMessages.find(item => item.Short_Code === 'MSG195_1');
            let checkCondition = this.globalSettings.find(item => item.Code === 'MOD52PG195REQLICCAP').Value;
            if (checkCondition === '1' && selectedData.Remaining < 1) {
              this.logHelperService.logError({
                message: remainingZeroMessage ? remainingZeroMessage.MSG : ""
              })
            } else if (checkCondition === '2' && selectedData.Remaining < 1) {
              this.logHelperService.logError({
                title: 'Warning!',
                message: remainingZeroMessage ? remainingZeroMessage.MSG : "",
              }, 'warning', 'Go to Next', true).then((res) => {
                if (res && res.value) {
                  this.handleSave.emit({ ...selectedData });
                }
              })
            } else if (selectedData.Remaining > 0) {
              this.handleSave.emit({ ...selectedData });
            }
            // this.handleSave.emit({ ...selectedData });
          }
        } else if (selectedData.Status == "Expired" && !this.isHideSkip) {
          if (this.stepData.requestType === GatePassRequestType.New) {
            this.logHelperService.logError({
              title: 'OOPS!!!',
              // message: this.licenceExpireMessage.MSG
              message: this.selectActiveMsg.MSG
            });
          }
        } else if (selectedData.Status == "Expired" && this.isHideSkip) {
          if (this.stepData.requestType === GatePassRequestType.New) {
            if (this.selectedPlantDetail.LicenseMaxValue < this.selectedWorkOrderDetail.ActivePerson) {
              this.logHelperService.logError({
                title: 'OOPS!!!',
                message: "Selected license is expired also as per CLRA without license capacity also exceeded."
              });
            } else {
              this.handleSave.emit({ ...selectedData });
            }
          }
        }
      } else {
        if (selectedData.Status != "Expired") {
          this.handleSave.emit({ ...selectedData });
        }
      }
    }
  }

  private createColumnDef() {
    this.columnDefs = [
      {
        headerName: '#', field: '',
        width: 10, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: 'Licence', field: 'LicenseNo', wrapText: true, autoHeight: true,
        Width: 70, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
      },
      {
        headerName: 'Plant', field: 'PlantName',
        width: 50, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: false, textAlign: 'center'
      },
      {
        headerName: 'Person to be engaged', field: 'Person', width: 80, wrapHeaderText: true,
        autoHeaderHeight: true, filterParams:
          { applyButton: true, clearButton: true }, filter: false, cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Active', field: 'ActivePerson', width: 40, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        hide: !this.isHideRemaining
      },
      {
        headerName: 'Remaining', field: 'Remaining', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: false, cellStyle: { textAlign: 'center' },
        hide: this.isHideRemaining
      },
      {
        headerName: 'State', field: 'State', width: 40, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: false
      },
      {
        headerName: 'Issue Dt.', field: 'IssueDate', width: 50, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: false, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          return moment(data.IssueDate).format('DD/MM/YYYY');
        }
      },
      {
        headerName: 'Validity Dt.', field: 'ValidtyDate', width: 50, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: false, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          return moment(data.ValidtyDate).format('DD/MM/YYYY');
        }
      },
      {
        headerName: 'Status', field: 'Status', width: 60, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: false, cellStyle: { textAlign: 'center' },
        hide: this.showStatus == '0' ? true : false,
        cellRenderer: ({ data }) => {
          if (data.Status === 'Expired') {
            return `<span class="badge badge-danger">${data.Status}</span>`;
          }

          return `<span class="badge badge-success">${data.Status}</span>`;
        }
      },
      {
        headerName: 'Action', field: '', width: 30, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
      }
    ];
  }

  private createGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        return 37;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 40,
    };
  }

}