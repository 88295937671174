import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShiftgroupComponent } from './shiftgroup/shiftgroup.component';
import { CreateShiftGroupComponent } from './shiftgroup/create-shift-group/create-shift-group.component';

const routes: Routes = [
  {
    path: 'shift-group',
    component: ShiftgroupComponent
  },
  {
    path: 'create-shift-group',
    component: CreateShiftGroupComponent
  },
  {
    path: 'edit-shift-group/:id',
    component: CreateShiftGroupComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShiftRoutingModule { }
