import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ElementRef
}

  from '@angular/core';

export class AdvanceFilters {
  name: string;
  code: string;
}

@Component({
  selector: 'app-advance-filter',
  templateUrl: './advance-filter.component.html',
  styleUrls: ['./advance-filter.component.css']
})
export class AdvanceFilterComponent implements OnInit {
  private privateOpen = false;
  showAll = false;
  @Input() filterSelected = false;
  @Input() selectedFilters: AdvanceFilters[];
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() filterRemoved: EventEmitter<any> = new EventEmitter();

  @Input() public set open(val: boolean) {
    this.privateOpen = val;
    this.openChange.emit(val);
  }

  public get open(): boolean {
    return this.privateOpen;
  }

  @Output() clear: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      // this.text = "clicked outside";
      this.filterClosed(false);
    }
  }

  constructor(
    private eRef: ElementRef
  ) { }

  ngOnInit() { }

  filterClosed(event) {
    this.open = event;
  }

  clearFilter() {
    this.clear.emit(true);
  }

  removeFilter(filter: AdvanceFilters) {
    this.filterRemoved.emit(filter.code);
  }

  showHideAll() {
    this.showAll = !this.showAll;
  }

}
