import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: ' app-ag-input-edit-cell-renderer',
  templateUrl: './ag-input-edit-cell-renderer.component.html',
  styleUrls: ['./ag-input-edit-cell-renderer.component.css']
})
export class AgInputEditCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  isValid = true;
  invalidMsg = '';
  inputClass = 'cellInput';
  width: 90;

  public showVerifyBtn: boolean;

  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
    this.width = this.params.width ? this.params.width : 90;
    this.showVerifyBtn = this.params.isShowVerifyBtn;
    // console.log('agInit', this.params);
    
    this.validateColumn();
  }

  refresh() {
    return true;
  }

  onValueChange($event) {
    this.params.data[this.params.column.colId] = $event.target.value;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection($event.target.value);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }

    this.params.onInputTextChange(this.params.rowIndex, this.params.column.colId);
  }

  validateColumn() {
    const obj = this.params.isColValid(this.params.rowIndex, this.params.column.colId);
    this.isValid = obj.isValid;
    this.inputClass = 'cellInput';
    this.invalidMsg = '';
    if (obj.requireValidation) {
      this.inputClass += ' require-validation-cell-input';
      this.invalidMsg = obj.msg;
    } else if (!obj.isValid) {
      this.inputClass += ' invalid-cell-input';
      this.invalidMsg = obj.msg;
    }
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }

  onVerify(): void {
    this.params.context.componentParent.openAdharCardVerifyModal(this.params.data, this.params.rowIndex);

  }
}
