import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, ElementRef, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AgActionCellRendererComponent, FileDoc } from 'src/app/core/components';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';
import { forkJoin, Subject } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ReportExportService } from 'src/app/reports/contractor-master/report-export.service';
import { DepartmenttrainingService } from '../department-training.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
// tslint:disable-next-line: max-line-length
@Component({
  selector: 'app-bulk-upload-with-individual-doc',
  templateUrl: './bulk-upload-with-individual-doc.component.html',
  styleUrls: ['./bulk-upload-with-individual-doc.component.css']
})
export class BulkUploadWithIndividualDocComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public tableRowData1: any[] = [];
  public myMap: any[] = [];
  public myMap1: any[] = [];
  public reportDate: string;
  public totalData: number;
  private selectedReportInfo: Date;
  uploadedFiles: FileDoc[] = [];
  imageChangedEvent: any = '';
  invalidFileType: boolean;
  invalidFileTypeMessage = '';
  rowDataAll = [];
  datePickerPickerMode: any;
  datePickerPlaceHolder: any;
  FromDate: any;
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  @Output() public save: EventEmitter<void>;
  @Input()
  public set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): Date {
    return this.selectedReportInfo;
  }


  @Input()
  public set reportData(value: any[]) {
    if (value) {

      this.reportValueData = value;
      // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = value;
      // this.rowData = value;
      this.createFileFormArray(this.rowData);
    }
  }
  public get reportData(): any[] {
    return this.reportValueData;
  }
  public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  public fileFormArray: FormArray;
  public isFormSubmitted: boolean;
  public isFileRequired: boolean;
  public allowedFileTypes: string;

  columnDefs: any[] = [];
  rowData = [];
  groupingMeta = ['ContractorName'];
  private reportValueData: any[];
  private onDestroy: Subject<void>;

  constructor(private router: Router,
    private departmenttrainingService: DepartmenttrainingService,
    private logHelperService: LogHelperService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.closeModal = new EventEmitter();
    this.save = new EventEmitter();
    this.fileFormArray = this.fb.array([]);
    this.onDestroy = new Subject();
  }

  ngOnInit() {
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.totalData = this.reportValueData.length;
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };

    this.sharedService.getGlobalSettings().pipe(takeUntil(this.onDestroy))
      .subscribe((settings: any) => {
        const fileRequiredObj = settings.Data.Table.find(item => item.Code === 'EMAIL119');
        const fileTypeObj = settings.Data.Table.find(item => item.Code === 'EMAILSMTP100');

        if (fileRequiredObj && fileRequiredObj.Value === '1') {
          this.fileFormArray.controls = this.fileFormArray.controls.map(control => {
            control.setValidators([Validators.required]);
            control.updateValueAndValidity();
            return control;
          })
        }
        this.allowedFileTypes = fileTypeObj ? fileTypeObj.Value : '';
      })

    // const fileRequired = this.departmenttrainingService.globalMasterSettingData('EMAIL119');
    // const fileTypes = this.departmenttrainingService.globalMasterSettingData('EMAILSMTP101');
    // forkJoin(fileRequired, fileTypes)
    //   .pipe(takeUntil(this.onDestroy))
    //   .subscribe(([fileRequiredObj, fileTypeObj]: [any[], any[]]) => {
    //     // debugger
    //     if (fileRequiredObj && fileRequiredObj.length && fileRequiredObj[0].Value === '1') {
    //       this.fileFormArray.controls = this.fileFormArray.controls.map(control => {
    //         control.setValidators([Validators.required]);
    //         control.updateValueAndValidity();
    //         return control;
    //       })
    //     }
    //     this.allowedFileTypes = fileTypeObj && fileTypeObj.length ? fileTypeObj[0].Value : '';
    //   })
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  createFileFormArray(list: any[]): void {
    list.forEach((item: any) => {
      let control: FormControl;
      if (this.isFileRequired) {
        control = this.fb.control('', [Validators.required]);
      } else {
        control = this.fb.control('');
      }
      this.fileFormArray.push(control);
    });
  }

  fileUploadedFromInput(event, data) {
    this.fileFormArray.controls[data].setValue(event.target.files);
    // this.invalidFileType = false;
    // const newFiles = event.target.files;
    // this.uploadedFiles = newFiles;
    // // tslint:disable-next-line: no-parameter-reassignment
    // data.doc = newFiles;
  }
  public onCloseAction(): void {
    this.closeModal.next();
    this.rowData = [];
  }
  onFilesChanged(newFiles, rowData) {

    this.uploadedFiles = newFiles;
    rowData.Doc = newFiles;
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    // console.log(this.imageChangedEvent);
  }
  onEdit(params) {
    // this.editClick.emit(params);
  }
  onDelete(params) {
    // this.deleteClick.emit(params);
  }

  public onSave(rowData): void {
    this.isFormSubmitted = true;
    // if (this.fileFormArray.valid) {
    //   console.log(this.fileFormArray.value);

    console.log(rowData);
    this.departmenttrainingService.SaveLabourTrainingWithIndividiauldocDetail(rowData,
      this.fileFormArray.value || [], '').subscribe((dt: any[]) => {
        console.log(dt);
        if (this.rowData.length > 0) {
          this.logHelperService.logSuccess({
            message: 'Training Records Updated Successfully'
          });

          // } else {
          //   this.logHelperService.logError({
          //     message: 'Please Fill Mandotary Fields'
          //   });
        }
        this.onCloseAction();
        this.save.emit();
      });
    // }
  }

  onStartDateChange(event) {
    this.rowData.forEach((element) => {
      element.StartDate = this.datePipe.transform(event.value._d, 'yyyy-MM-dd');
    });
  }
}
