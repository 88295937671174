import { Injectable } from '@angular/core';

declare var d3: any;

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  public groupByData(data: any[], keys: string[]) {
    const nest = d3.nest();
    keys.forEach((key) => {
      nest.key((d) => { return d[key]; });
    });
    return nest.entries(data);
  }

}
