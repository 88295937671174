import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { GLOBAL } from '../app.globals';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  constructor(
    private httpClient: HttpClient,
    private dp: DatePipe
  ) { }

  getAllAppliedLeaves(type: number) {
    // const body = JSON.stringify(labourParams);
    const model = {
      Search: '',
      UserID: localStorage.getItem('UserID'),
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      Type: type
    };
    // return this.httpClient.request(
    //   GLOBAL.HTTP_POST,
    //   `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_APPLIEDLEAVE}?${queryString}`,
    //   httpOptions
    // );

    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_APPLIEDLEAVE}`, model);
  }

  getLeaveType() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveType}`, model);
  }
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveType}?Search=&CountryCode=`,
  // httpOptions
  // );
  // }

  getAppliedLeaveType() {
    const model = {
      Search: '',
      UserID: localStorage.getItem('UserID'),
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_AppliedLeaveType}`, model);
  }

  // getAssignedLeaveTypes() {
  //   const queryString = $.param({
  //     Search: '',
  //     UserID: localStorage.getItem('UserID'),
  //     RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ASSIGNED_LEAVETYPE}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // getLeaveBalance() {
  //   const queryString = $.param({
  //     Search: '',
  //     UserID: localStorage.getItem('UserID'),
  //     RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LEAVEBALANCE}?${queryString}`,
  //     httpOptions
  //   );
  // }

  public getLeaveBalance(search, userID, roleCode, gatepass): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LEAVEBALANCE}`;
    return this.httpClient.post<any[]>(url, { search, userID, roleCode, gatepass });
    // return of([{CurrentBalance: 20, LeaveType: 'COFF', LeaveType_Code: 120}])
  }
  public gateCOFFLeaves(date, gatepassno): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_COFF_DATEWISE_ACTIVITY}`;
    return this.httpClient.post<any[]>(url, { gatepassno, Date: date });
  }

  public getLastPlantPresent(date: any, gatepass, userID): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LAST_PLANT_PRESENT_STATUS}`;
    return this.httpClient.post<any[]>(url, { Date: date, GatepassNo: gatepass, UserID: userID }).pipe(
      map((response: any) => response && response[0])
    );
  }

  public getLeavesCriteriaByDates(startDate: string, endDate: string, gatepass: number, userID: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LEAVE_CRITERIA_BY_DATES}`;
    return this.httpClient.post<any[]>(url, { FromDate: startDate, ToDate: endDate, GatepassNo: gatepass });
  }

  // public getJoiningDate(date: any, gatepass, userID): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LAST_PLANT_PRESENT_STATUS}`;
  //   return this.httpClient.post<any[]>(url, { Date: date, GatepassNo: gatepass, UserID: userID }).pipe(
  //     map((response: any) => response && response[0])
  //   );
  // }

  public getsetionhead() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTIONHEAD}`, model).
      pipe(
        map((response: any) => response));
  }
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTIONHEAD}?Search=`,
  // httpOptions
  //  );
  // }

  getLabourName() {
    const model = {
      Search: '',
      UserID: localStorage.getItem('UserID'),
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOURNAME}`, model).
      pipe(
        map((response: any) => response));
  }

  //    return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOURNAME}?${queryString}`,
  // httpOptions
  // );
  // }

  applyLeave(leavedata): Observable<Object> {
    const body = JSON.stringify(leavedata);
    httpOptions['body'] = body;
    // const body = this.setDataForApplyLeave(leavedata);
    // return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LABOUR_DATA_FOR_LEAVE}`, body).
    //   pipe(
    //     map((response: any) => response));
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LABOUR_DATA_FOR_LEAVE}`,
      httpOptions
    );

  }

  public cancelationrequest(data: any): Observable<any> {

    // const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.APPLY_CANCELATION_REQUEST}`;
    const url = '';
    return this.httpClient.post(url, data).
      pipe(
        map((response: any) => response));
  }

  // public getLeaveBalance(ulc): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ULC}`;
  //   return this.httpClient.post(url, { ulc}).
  //     pipe(
  //       map((response: any) => response));
  // }
  // tslint:disable-next-line: function-name
  GetdataofAppliedLeave(leaveappcode) {
    const model = {
      leaveappcode
    };
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_APPLIEDLEAVE_BY_CODE}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  // tslint:disable-next-line: function-name
  UpdateapplyLeave(leavedata): Observable<Object> {

    const body = JSON.stringify(leavedata);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_UPDATE_LABOUR_DATA_FOR_LEAVE}`,
      httpOptions
    );
  }

  deleteLeave(leavedata): Observable<Object> {
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_LeaveDelete}`, leavedata);
  }
  // deleteLeave(leavedata): Observable<Object> {
  //   const body = JSON.stringify(leavedata);
  //   httpOptions['body'] = body;
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_POST,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.DELETE_APPLIEDLEAVE_BY_CODE}`,
  //     httpOptions
  //   );
  // }

  public getLeaveCriteria(leavetypecode): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LEAVE_CRITERIA}`;
    return this.httpClient.post(url, { leavetypecode }).
      pipe(
        map((response: any) => response));
  }

  public getPlantMasterData(): Observable<any> {

    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA}`, model).
      pipe(
        map((response: any) => response));
  }


  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA
  //   + '?search=&CompanyCode=&StateCode=&CityCode=&CountryCode=&UnitType=&Ownership=&LocatedAt=&ISESICApplicable=');
  // }

  public getLabourPerformanceReportData(data: any): Observable<any> {
    const dataObj = {
      DateOrMonthly: null,
      Labour_Code: data.Labour_Code,
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      isfinancial: null,
      pFinacialYear: null,
      startmonth: null,
      endmonth: null,
      lFinacialYear: null,
      Contractor: null,
      workOrder_Code: null,
      subContractors: null,
      subWorkOrder_Code: null,
      plant_Code: null,
      unit_Code: null,
      Leavetype_Code: null,
      Page: 'Time-CardReport'
    };

    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_ATTENDANCE}`;
    return this.httpClient.post<any>(url, dataObj);

    // map(() => {
    //   return [
    //     // {
    //     //   Date: new Date(2021, 2, 2),
    //     //   AttendanceStatus: 'P'
    //     // },
    //     // {
    //     //   Date: new Date(2021, 2, 6),
    //     //   AttendanceStatus: 'P'
    //     // }
    //   ]
    // })
    return of(
      [
        {
          Date: new Date(2021, 2, 2),
          AttendanceStatus: 'P'
        },
        {
          Date: new Date(2021, 2, 6),
          AttendanceStatus: 'P',
          IsPresentHalf: true,
          FHalfPresent: false
        }
      ]);
  }

  private setDataForApplyLeave(leaves: any[]): any[] {
    return leaves.map((item: any) => {
      return { ...item, startDate: item.startDate.toDateString(), endDate: item.endDate.toDateString() };
    });
  }

  public approveCancelationRequest(data: any[]): Observable<any[]> {
    // const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.APPROVED_CANCELATION_REQUEST}`;
    const url = '';
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response.Data));
  }

  public checkLeaveAvailibity(data): Observable<any> {
    const model = {
      Labour_Code: data.Labour_Code,
      LeaveType_Code: data.LeaveType_Code,
      LeaveDate: this.dp.transform(data.date, 'yyyy-MM-dd'),
      LeaveCriteria: data.leavecriteria,
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CheckLeaveavAilability}`, model).
      pipe(
        map((response: any) => response));
  }
}
