import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { AttendanceService } from "../attendance.service";
import * as moment from "moment";
import { DecimalPipe } from "@angular/common";
import { SharedService } from "src/app/core/services";
import { Console } from "@angular/core/src/console";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";
import { GridApi, GridOptions } from "ag-grid-community";
import { GLOBAL } from "src/app/app.globals";
import { AttendanceApprove } from "../attendance-modal";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { SweetAlertOptions } from "sweetalert2";
// tslint:disable-next-line: max-line-length
import { AgActionAttendanceCheckboxRenderComponent } from "src/app/core/components/grid-wrapper/helper/ag-action-attendance-checkbox-render/ag-action-attendance-checkbox-render.component";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-attendance-approve-bysec",
  templateUrl: "./attendance-approve-bysec.component.html",
  styleUrls: ["./attendance-approve-bysec.component.css"],
})
export class AttendanceApproveBysecComponent implements OnInit {
  // tslint:disable-next-line: member-ordering

  constructor(
    public decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private attendanceService: AttendanceService,
    private modalService: NgbModal
  ) {}

  @Input()
  public set reportData(value: any[]) {
    if (value) {
      this.reportValueData1 = value;
      value.map((x, i) => {
        x.rowIndex = i + 1;
        x.isVisible = true;
      });
      this.rowData = value;
    }
  }
  public closeModal: EventEmitter<any>;
  @ViewChild("templateRef") templateRef: TemplateRef<any>;
  reportValueData = [];
  reportValueData1 = [];
  reportValueData2 = [];
  rowData: any[] = [];
  groupingMeta = [""];
  modalRef: any;
  conSel: boolean;
  columnDefs: any[] = [];
  finalArray = [];
  finalArray2 = [];
  associateform = new FormGroup({});
  rowSelection: string;
  selectedData: any;
  selectedData2: any;
  isApproved: any;
  total: any;
  selectedShift: any;
  sum = 0;
  value: any;
  selectedWorkmanCategory: any;
  date = new Date();
  targetDate = moment(this.date).subtract(15, "day").toDate();
  reportDateRange = [moment(this.targetDate), moment()];
  @ViewChild("dialogerror") private dialogerror: SwalComponent;
  alertOption: { title: string; html: string; type: string };
  // tslint:disable-next-line: member-ordering
  selectedRows: any;
  // tslint:disable-next-line: member-ordering
  // gridApi: GridApi;
  // tslint:disable-next-line: member-ordering
  requiredFlag: any;
  // tslint:disable-next-line: member-ordering
  selectedDate: any;
  allFlag: boolean;
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return "<div></div>";
  };
  ngOnInit() {
    // this.totalData = this.reportValueData.length;
    // console.log('a', this.totalData);

    this.getApproveAttendanceList();
    // this.add(this.reportValueData);

    const sumOfAges = this.reportValueData.reduce((sum, currentValue) => {
      return sum + currentValue.Contractors;
    }, 0);
  }

  add(data) {
    console.log("av", data);
  }

  public checkAllSubData(groupData, checked: boolean) {
    groupData.isCheckedAll = groupData.isCheckedAll ? false : true;
    groupData.Values.forEach((value) => {
      value.Values.forEach((value1) => {
        value1.Values.forEach((data) => {
          // this.total = groupData.length;
          // let approved = 0;
          // let count;
          // for (count = 0; count < groupData.length; count++) {
          //   approved += groupData[count].IsApproved;
          // }
          if (checked) {
            this.total = data.length;
            let approved = 0;
            let count;
            for (count = 0; count < data.length; count++) {
              approved += data[count].IsApproved;
            }
            this.isApproved = approved;
            data.Values.forEach((data1) => (data1.IsApproved = 1));
            this.selectedDate = groupData.Date;
            this.selectedData = data;
            this.selectedWorkmanCategory = value.CategoryWorkmenMIL_Code;
            this.selectedShift = value1.Shift_Code;
            this.reportData = data.Values;
            this.rowData.forEach((item) => (item.isSelected = true));
            this.onCloseModel(checked);
          } else {
            this.total = data.length;
            let approved = 0;
            let count;
            for (count = 0; count < data.length; count++) {
              approved += data[count].IsApproved;
            }
            this.isApproved = approved;
            data.Values.forEach((data1) => (data1.IsApproved = 0));
            this.selectedDate = groupData.Date;
            this.selectedData = data;
            this.selectedWorkmanCategory = value.CategoryWorkmenMIL_Code;
            this.selectedShift = value1.Shift_Code;
            this.reportData = data.Values;
            this.rowData.forEach((item) => (item.isSelected = false));
            this.onCloseModel(checked);
          }

          // this.isApproved = approved;
          // this.selectedDate = selectedDate;
          // this.selectedData = selectedData;
          // this.selectedWorkmanCategory = category.CategoryWorkmenMIL_Code;
          // this.selectedShift = shift.Shift_Code;
          // this.reportData = manPowerItem;
        });
      });
    });
  }

  onDateRangeChange($event) {
    // $event[0].format()
    // $event[1].format()
    this.getApproveAttendanceList();
  }

  getApproveAttendanceList() {
    this.finalArray = [];
    const model = {
      UserID: localStorage.getItem("UserID"),
    };

    this.attendanceService
      .getListForApproval({
        Filter: {
          UserId: localStorage.getItem("UserID"),
          StartDate: this.reportDateRange[0].format(),
          EndDate: this.reportDateRange[1].format(),
        },
        Fields: [
          {
            KeyName: "Date",
            DisplayName: "",
          },
          {
            KeyName: "CategoryWorkmenMIL_Code",
            DisplayName: "Category",
          },
          {
            KeyName: "Shift_Code",
            DisplayName: "Shift",
            ExtraFields: ["tr1", "ttp1", "st1", "ap1", "pa1", "pahr1"],
          },
          {
            KeyName: "Contractor_Code",
            DisplayName: "Contractor",
            ExtraFields: [
              "Requirement",
              "TotalPresent",
              "Shortage",
              "Approved",
              "PendingApproved",
              "PendingApprovedwithhr",
              "IsApproved",
              "IsApprovedCheck",
            ],
          },
          {
            KeyName: "ULC",
            DisplayName: "Name",
            ExtraFields: [
              "LabourMovement_Code",
              "UnitIn",
              "GatePassNo",
              "UnitOut",
              "Shift",
              "ShiftTime",
              "WHR",
              "OT",
              "Plant",
              "Unit",
              "Department",
              "Section",
              "IsApproved",
              "IsApprovedCheck",
              "TrainingStatus",
            ],
          },
        ],
      })
      .subscribe((response: any[]) => {
        this.reportValueData = response;
        this.reportValueData.forEach((element) => {
          element.Values.forEach((element1) => {
            element1.Values.forEach((element2) => {
              let tr1: number = element2.Values.map(
                (a) => a.Requirement
              ).reduce(function (a, b) {
                return a + b;
              });
              element2.tr1 = tr1;

              let ttp1: number = element2.Values.map(
                (a) => a.TotalPresent
              ).reduce(function (a, b) {
                return a + b;
              });
              element2.ttp1 = ttp1;

              let st1: number = element2.Values.map((a) => a.Shortage).reduce(
                function (a, b) {
                  return a + b;
                }
              );
              element2.st1 = st1;

              let ap1: number = element2.Values.map((a) => a.Approved).reduce(
                function (a, b) {
                  return a + b;
                }
              );
              element2.ap1 = ap1;

              let pa1: number = element2.Values.map(
                (a) => a.PendingApproved
              ).reduce(function (a, b) {
                return a + b;
              });
              element2.pa1 = pa1;

              let pahr1: number = element2.Values.map(
                (a) => a.PendingApprovedwithhr
              ).reduce(function (a, b) {
                return a + b;
              });
              element2.pahr1 = pahr1;
            });
          });
        });

        // this.reportValueData.Values.forEach((value) => {
        //   value.Values.forEach((value1) => {
        //     value1.Values.forEach((data) => {
        //     })
        //   })
        // })

        this.add(response);
        //       if (response.length > 0) {
        //         response.Values.forEach((value) => {
        //           value.Values.forEach((value1) => {
        //             value1.Values.forEach((data) => {
        //               let count;
        //               for (count = 0; count < data.length; count++) {
        //                 this.sum += data[count].TotalPresent;
        //               }
        //             });
        //           });
        //         });
        //       }
      });
  }
  /*** Grid Configuration */
  dateValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format("MM/DD/YYYY");
    }
    return "";
  };
  timeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format("HH:mm");
    }
    return "";
  };
  decimalValueFormatter = (params) => {
    if (params.value) {
      return this.decimalPipe.transform(params.value, "2.2-2");
    }
    return "";
  };

  onApprove(selectedDate, category, shift, allFlag) {
    let sendData: any;
    if (allFlag) {
      sendData = this.finalArray.filter(
        (x: any) => x.selectedDate === selectedDate
      );
    } else {
      sendData = this.finalArray.filter(
        (x: any) =>
          x.selectedDate === selectedDate &&
          x.workmanContractorCode === category.CategoryWorkmenMIL_Code &&
          x.shiftCode === shift.Shift_Code
      );
    }
    const attendenceAproveArray: AttendanceApprove[] = [];
    const labourMovementCodes = [];
    sendData.forEach((labourMovementData: any) => {
      const attendenceAprove: AttendanceApprove = new AttendanceApprove();
      labourMovementCodes.push(labourMovementData.LabourMovement_Code);
      attendenceAprove.ArrayULC = labourMovementData.ulc;
      attendenceAprove.UserId = localStorage.getItem("UserID");
      attendenceAprove.RoleCode = JSON.parse(
        localStorage.getItem("Role")
      ).Role_Code;
      attendenceAprove.Date = labourMovementData.selectedDate;
      // attendenceAprove.contractorCode = labourMovementData.Co;
      // attendenceAprove.workmanCategoryCode = localStorage.getItem('UserID');
      attendenceAproveArray.push(attendenceAprove);
    });
    if (attendenceAproveArray.length === 0) {
      alert("Please select atleast one row");
      // this.alertOption = {
      //   title: 'error!!!',
      //   html: `<span class='Please select atleast one row.</span>`,
      //   type: 'error'
      // };
      return;
    }

    const requestObj = {
      UserID: localStorage.getItem("UserID"),
      RequirType: 0,
      ActionType: 2,
      IsLabour: 1,
      LabourMovement_Code: labourMovementCodes.join(),
    };

    const approveAPI$ = this.attendanceService.approveAttendanceBySec(
      attendenceAproveArray
    );
    const attendanceStatusUpdateAPI$ =
      this.attendanceService.rejectAttendanceBySec(requestObj);
    forkJoin(approveAPI$, attendanceStatusUpdateAPI$).subscribe(
      ([response, leaveBalanceList]: [any, any]) => {
        let countx: any;
        countx = response;
        this.alertOption = {
          title: "Saved!!!",
          html: `<span class='customClass'>Data Saved Successfully. ${countx} Persons Department Traning are Pending</span>`,
          type: "success",
        };
        setTimeout(() => {
          const alertRef = this.dialogerror.show();
          alertRef.then((e) => {
            this.onCloseAction();
          });
        }, 10);
        this.getApproveAttendanceList();
      }
    );
    return;
  }

  onReject(selectedDate, category, shift, allFlag) {
    let sendData: any;
    if (allFlag) {
      sendData = this.finalArray.filter(
        (x: any) => x.selectedDate === selectedDate
      );
    } else {
      sendData = this.finalArray.filter(
        (x: any) =>
          x.selectedDate === selectedDate &&
          x.workmanContractorCode === category.CategoryWorkmenMIL_Code &&
          x.shiftCode === shift.Shift_Code
      );
    }
    const attendenceAproveArray: AttendanceApprove[] = [];
    const labourMovementCodes = [];
    sendData.forEach((labourMovementData: any) => {
      labourMovementCodes.push(labourMovementData.LabourMovement_Code);
      const attendenceAprove: AttendanceApprove = new AttendanceApprove();
      attendenceAprove.ArrayULC = labourMovementData.ulc;
      attendenceAprove.UserId = localStorage.getItem("UserID");
      attendenceAprove.RoleCode = JSON.parse(
        localStorage.getItem("Role")
      ).Role_Code;
      attendenceAprove.Date = labourMovementData.selectedDate;
      // attendenceAprove.contractorCode = labourMovementData.Co;
      // attendenceAprove.workmanCategoryCode = localStorage.getItem('UserID');
      attendenceAproveArray.push(attendenceAprove);
    });

    if (attendenceAproveArray.length === 0) {
      alert("Please select atleast one row");
      // this.alertOption = {
      //   title: 'error!!!',
      //   html: `<span class='Please select atleast one row.</span>`,
      //   type: 'error'
      // };
      return;
    }
    const requestObj = {
      UserID: localStorage.getItem("UserID"),
      RequirType: 0,
      ActionType: 1,
      IsLabour: 1,
      LabourMovement_Code: labourMovementCodes.join(),
    };

    this.attendanceService
      .rejectAttendanceBySec(requestObj)
      .subscribe((response: any) => {
        let countx: any;
        countx = response;
        this.alertOption = {
          title: "Saved!!!",
          html: `<span class='customClass'>Data Saved Successfully.</span>`,
          type: "success",
        };
        setTimeout(() => {
          const alertRef = this.dialogerror.show();
          alertRef.then((e) => {
            this.onCloseAction();
          });
        }, 10);
        this.getApproveAttendanceList();
      });
  }

  onCloseAction() {}
  onCloseModelAction() {
    if (this.modalRef) {
      this.modalRef.close("click");
      this.rowData.forEach((item) => {
        item.IsApproved = item.tmpIsApproved;
      });
    }
  }
  public openStateSelectionPopup(
    manPowerItem: any,
    selectedData: any,
    selectedDate,
    category,
    shift
  ): void {
    this.total = manPowerItem.length;
    // tslint:disable-next-line: max-line-length
    this.selectedDate = selectedDate;
    this.selectedData = selectedData;
    this.selectedWorkmanCategory = category.CategoryWorkmenMIL_Code;
    this.selectedShift = shift.Shift_Code;
    this.reportData = manPowerItem;
    this.modalRef = this.modalService.open(this.templateRef, {
      size: "lg",
      backdrop: "static",
      windowClass: "modal-panel",
    });
    this.setColumnDef();
  }

  public checkboxClick(
    manPowerItem: any,
    selectedData: any,
    selectedDate,
    category,
    shift,
    checkedValue: boolean
  ) {
    this.total = manPowerItem.length;
    let approved = 0;
    let count;
    for (count = 0; count < manPowerItem.length; count++) {
      approved += manPowerItem[count].IsApproved;
    }
    this.isApproved = approved;
    // if (checkedValue) { selectedData.Values.forEach(data1 => data1.IsApproved = 1); }
    // this.selectedDate = selectedDate;
    // this.selectedData = selectedData;
    // this.selectedWorkmanCategory = category.CategoryWorkmenMIL_Code;
    // this.selectedShift = shift.Shift_Code;
    // this.reportData = manPowerItem;
    // this.modalRef = this.modalService.open(this.templateRef, { size: 'lg', backdrop: 'static' });

    // this.setColumnDef();

    if (checkedValue) {
      selectedData.checkedStatus = 1;
      this.selectedDate = selectedDate;
      this.selectedData = selectedData;
      this.selectedWorkmanCategory = category.CategoryWorkmenMIL_Code;
      this.selectedShift = shift.Shift_Code;
      this.reportData = manPowerItem;
      selectedData.Values.forEach((data1) => (data1.IsApproved = true));
      this.onCloseModel(checkedValue);
    } else {
      selectedData.Values.forEach((data1) => {
        if (data1.IsApprovedCheck) {
          data1.IsApproved = true;
        } else {
          data1.IsApproved = false;
        }
      });
      // selectedData.Values.forEach(data1 => data1.IsApproved = false);
      selectedData.checkedStatus = 0;

      const currentSelectedList = this.finalArray.filter(
        (x: any) =>
          x.selectedDate === selectedDate &&
          x.workmanContractorCode === category.CategoryWorkmenMIL_Code &&
          x.shiftCode === shift.Shift_Code &&
          x.contractorCode === selectedData.Contractor_Code
      );
      if (currentSelectedList.length) {
        currentSelectedList.forEach((item) => {
          const index = this.finalArray.indexOf(item);
          if (index > -1) {
            this.finalArray.splice(index, 1);
          }
        });
      }
    }
  }

  public checkboxViewClick(
    manPowerItem: any,
    selectedData: any,
    selectedDate,
    category,
    shift,
    checkedStatus
  ) {
    // selectedData.IsApproved = 1;
    this.total = manPowerItem.length;
    let approved = 0;
    let count;
    for (count = 0; count < manPowerItem.length; count++) {
      approved += manPowerItem[count].IsApproved;
    }
    // if(checkedStatus === 1)
    // {
    // selectedData.Values.forEach((data1) => data1.IsApproved = 1);
    // )
    manPowerItem.forEach((item) => {
      item.tmpIsApproved = item.IsApproved;
    });
    this.selectedDate = selectedDate;
    this.selectedData = selectedData;
    this.selectedData2 = selectedData;
    this.selectedWorkmanCategory = category.CategoryWorkmenMIL_Code;
    this.selectedShift = shift.Shift_Code;
    this.reportData = manPowerItem;

    this.modalRef = this.modalService.open(this.templateRef, {
      size: "lg",
      backdrop: "static",
      windowClass: "modal-panel modal-body-scroll",
    });
    this.setColumnDef();
  }

  public onCloseModel(checked: boolean): void {
    if (this.finalArray.length > 0) {
      const index = this.finalArray.findIndex(
        (x: any) =>
          x.selectedDate === this.selectedDate &&
          x.workmanContractorCode === this.selectedWorkmanCategory &&
          x.contractorCode === this.selectedData.Contractor_Code &&
          x.shiftCode === this.selectedShift
      );
      if (index > -1) {
        this.finalArray.splice(index, 1);
      }
    }
    const ulcData = this.rowData
      .filter((x: any) => x.IsApproved === true || x.IsApproved === 1)
      .map((y: any) => y.ULC);
    const labourMovementData = this.rowData
      .filter(function (x) {
        return x.IsApproved === true || x.IsApproved === 1;
      })
      .map(function (y) {
        return y.LabourMovement_Code;
      });
    if (ulcData.length > 0) {
      this.finalArray.push({
        selectedDate: this.selectedDate,
        contractorCode: this.selectedData.Contractor_Code,
        shiftCode: this.selectedShift,
        workmanContractorCode: this.selectedWorkmanCategory,
        ulc: ulcData,
        labourMovement: labourMovementData,
      });
    }
    const rowCount = this.rowData.length;
    const selectedCount = ulcData.length;

    if (checked) {
      this.selectedData.checkedStatus = 1;
    } else {
      this.selectedData.checkedStatus = 0;
    }
    // else {
    //   if (rowCount === selectedCount) {
    //     this.selectedData.checkedStatus = 1;
    //   } else if (selectedCount > 0) {
    //     this.selectedData.checkedStatus = 2;
    //   } else {
    //     this.selectedData.checkedStatus = 0;
    //   }
    // }

    // if (this.selectedData.checked = true) {
    //   this.selectedData.checkedStatus = 1;
    // }
    // this.reportValueData.forEach((level1: any) => {
    //   if (level1.Date === this.selectedDate) {
    //     level1.Values.forEach((level2: any) => {
    //       if (level2.CategoryWorkmenMIL_Code === this.selectedWorkmanCategory) {
    //         level2.Values.forEach((level3: any) => {
    //           if (level3.Shift_Code === this.selectedShift) {
    //             level3.Values.forEach((level4: any) => {
    //               if (level4.Contractor_Code === this.selectedData.Contractor_Code) {
    //                 const coutApproved = ulcData.length;
    //                 const coutPendingApproved = level4.Values.length - ulcData.length;

    //                 // level4.Approved = ulcData.length;
    //                 // level4.PendingApproved = level4.Values.length - ulcData.length;
    //               }
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
    // });
    // console.log('final array', this.finalArray);
  }

  public onSaveModel(): void {
    if (this.finalArray.length > 0) {
      const index = this.finalArray.findIndex(
        (x: any) =>
          x.selectedDate === this.selectedDate &&
          x.workmanContractorCode === this.selectedWorkmanCategory &&
          x.contractorCode === this.selectedData.Contractor_Code &&
          x.shiftCode === this.selectedShift
      );
      if (index > -1) {
        this.finalArray.splice(index, 1);
      }
    }

    const ulcData = this.rowData
      .filter((x: any) => x.IsApproved === true)
      .map((y: any) => y.ULC);
    const labourMovementCodes = this.rowData
      .filter((x: any) => x.IsApproved === true)
      .map((y: any) => y.LabourMovement_Code);

    console.log("save ulc ", ulcData);
    if (ulcData.length > 0) {
      this.finalArray.push({
        selectedDate: this.selectedDate,
        contractorCode: this.selectedData.Contractor_Code,
        shiftCode: this.selectedShift,
        workmanContractorCode: this.selectedWorkmanCategory,
        ulc: ulcData,
        LabourMovement_Code: labourMovementCodes,
      });
    }
    console.log("save ulc ", this.finalArray);
    const rowCount = this.rowData.length;
    const selectedCount = ulcData.length;

    if (rowCount === selectedCount) {
      this.selectedData.checkedStatus = 1;
    } else if (selectedCount > 0) {
      this.selectedData.checkedStatus = 2;
    } else {
      this.selectedData.checkedStatus = 0;
    }
    console.log("final array", this.finalArray2);
    if (this.modalRef) {
      this.modalRef.close("click");
    }
  }

  setColumnDef() {
    // this.rowClassRules = {
    //   'group-row': (params) => {
    //     return params.data.groupLevel === 1;
    //   },
    // };
    this.columnDefs = [
      {
        headerName: "",
        field: "IsApproved",
        width: 30,
        pinned: "left",
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: AgActionAttendanceCheckboxRenderComponent,
        // headerCellRenderer: this.selectAllRenderer
        filter: false,
        // checkboxSelection: true,
        //  headerCheckboxSelection: true,
        // headerCheckboxSelection: function (params) {
        //   console.log('paramsss', params);

        //   // const displayedColumns = params.columnApi.getAllDisplayedColumns();
        //   // return displayedColumns[0] === params.column;
        //   return false;
        // },
        // checkboxSelection (params) {
        //   // add your cancheck-logic here
        //   if (params.data.IsApproved) {
        //     return true;
        //   }
        //   return false;
        // }
        // cellRenderer: (params) => {
        //   if (params.data.IsApproved === 1) {
        //     return `<input type="checkbox" checked disabled />`;
        //   }
        //   {// tslint:disable-next-line: ter-indent
        //     return `<input type="checkbox"/>`;
        //   }
        // }
      },
      //  {
      //     headerName: 'Select', field: 'selected', width: 100,
      //     sortable: false, suppressMenu: true, cellRendererFramework: AgActionCheckboxRendererComponent
      //   },
      {
        headerName: "Training",
        field: "TrainingStatus",
        width: 100,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "Code",
        field: "GatePassNo",
        width: 80,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "ULC",
        field: "ULC",
        width: 80,
        sortable: false,
        pinned: "left",
        hide: "true",
        isVisible: "False",
      },
      {
        headerName: "Associate",
        field: "Name",
        minwidth: 110,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "Location",
        sortable: false,
        children: [
          {
            headerName: "Plant",
            field: "Plant",
            width: 50,
            sortable: false,
          },
          {
            headerName: "Unit",
            field: "Unit",
            width: 50,
            sortable: false,
          },
          {
            headerName: "Department",
            field: "Department",
            width: 110,
            sortable: false,
          },
          {
            headerName: "Section",
            field: "Section",
            width: 200,
            sortable: false,
          },
        ],
      },
      {
        headerName: "Shift Time",
        children: [
          {
            headerName: "Shift",
            field: "Shift",
            width: 50,
            sortable: false,
          },
          {
            headerName: "Time",
            field: "ShiftTime",
            width: 80,
            sortable: false,
          },
        ],
      },
      {
        headerName: "IN-OUT",
        children: [
          {
            headerName: "IN",
            field: "UnitIn",
            width: 50,
            valueFormatter: this.timeValueFormatter,
            sortable: false,
          },
          {
            headerName: "OUT",
            field: "UnitOut",
            width: 50,
            valueFormatter: this.timeValueFormatter,
            sortable: false,
          },
        ],
      },
      {
        headerName: "Attendance Details",
        children: [
          {
            headerName: "WHR",
            field: "WHR",
            width: 50,
            valueFormatter: this.decimalValueFormatter,
            sortable: false,
          },
          {
            headerName: "OT",
            field: "OT",
            width: 50,
            valueFormatter: this.decimalValueFormatter,
            sortable: false,
          },
        ],
      },
    ];
    this.rowSelection = "multiple";
  }
}
