import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-row-list-group-timecard-plantwise',
  templateUrl: './row-list-group-timecard-plantwise.component.html',
  styleUrls: ['./row-list-group-timecard-plantwise.component.css']
})
export class RowListGroupTimecardPlantwiseComponent implements ICellRendererAngularComp   {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  toggleRow(rowData) {
    const rowDataMaster = this.params.context.componentParent.rowData;
    const rowIndex = this.params.rowIndex + 1;
    let index = rowIndex;
    while (rowDataMaster[index] && rowData.groupLevel !== rowDataMaster[index].groupLevel) {
      const element = rowDataMaster[index];
      element.isVisible = !element.isVisible;
      index++;
    }
    this.params.context.componentParent.rowData = rowDataMaster;
    this.params.api.onFilterChanged();
  }

}
