// import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { ListofWeekoffPresendDataComponent } from './listof-weekoff-presend-data/listof-weekoff-presend-data.component';
import { CoffApprovalProcessRoutingModule } from './coff-approval-process-routing.module';
import { CoreModule } from '../core/core.module';
import { EntryfordayService } from '../reports/daily-attendance/entryfortheday/entryforday.service';
import { EntryfordayAdapter } from '../reports/daily-attendance/adapter/entryforday.adapter';
import { DailyAttendanceService } from '../reports/daily-attendance/daily-attendance.service';
import { RptPunchFromReaderService } from '../reports/daily-attendance/rpt-punch-from-reader/rpt-punch-from-reader.service';
import { ReportExportService } from '../reports/contractor-master/report-export.service';

@NgModule({
  declarations: [ListofWeekoffPresendDataComponent],
  imports: [
    CommonModule,
    CoffApprovalProcessRoutingModule,
    CoreModule.forRoot(),
  ],
  providers: [EntryfordayService, EntryfordayAdapter,
    DailyAttendanceService, RptPunchFromReaderService, ReportExportService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class CoffApprovalProcessModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoffApprovalProcessModule,
    };
  }
}
