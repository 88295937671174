import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labour-punch-editing',
  templateUrl: './labour-punch-editing.component.html',
  styleUrls: ['./labour-punch-editing.component.css']
})
export class LabourPunchEditingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
