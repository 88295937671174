import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private url = 'https://ciplaapi.spikyarc.com/api/Common/MultiTableList/CLPMS_SP_RPT_TableHeader';

  constructor(private _http : HttpClient) { }

  public getTableData(pageCode : number){
    let data = {
      "Filter": {
        "Page_Code": pageCode,
        "RequireType": 0,
        "ActionType": 0,
        "UserId": 100
      }
    };
    return this._http.post(this.url, data);
  }
}
