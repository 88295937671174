import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-date-range-filter',
  templateUrl: './custom-date-range-filter.component.html',
  styleUrls: ['./custom-date-range-filter.component.css']
})
export class CustomDateRangeFilterComponent implements OnInit {

  yearTypeFilterToggle = false;
  dateFilterList = [];
  selectedYearTypeText = 'Select';
  selectedDateFilter = null;
  selectedFilterType = null;
  selectedYearType = 0;
  selectedYear = null;
  selectedMonth = null;
  monthDays = [31, 28, 31, 30, 31, 31, 30, 31, 30, 31, 30, 31];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  yearList = [new Date().getFullYear(), (new Date().getFullYear() - 1)];
  monthList = [
    { id: 0, label: 'Jan' },
    { id: 1, label: 'Feb' },
    { id: 2, label: 'Mar' },
    { id: 3, label: 'Apr' },
    { id: 4, label: 'May' },
    { id: 5, label: 'Jun' },
    { id: 6, label: 'Jul' },
    { id: 7, label: 'Aug' },
    { id: 8, label: 'Sep' },
    { id: 9, label: 'Oct' },
    { id: 10, label: 'Nov' },
    { id: 11, label: 'Dec' },
  ];

  calendateDateFilterTypes = [
    { id: 1, label: 'Yearly', showYear: true, showMonth: false },
    { id: 2, label: 'Half Yearly', showYear: true, showMonth: false },
    { id: 3, label: 'Quarterly', showYear: true, showMonth: false },
    { id: 4, label: 'Monthly', showYear: true, showMonth: false },
    { id: 5, label: 'Forthnightly', showYear: true, showMonth: true },
    { id: 6, label: 'Weekly', showYear: true, showMonth: true },
    { id: 7, label: 'Today', showYear: false, showMonth: false },
  ];

  financialDateFilterTypes = [
    { id: 11, label: 'Yearly', showYear: true, showMonth: false },
    { id: 12, label: 'Half Yearly', showYear: true, showMonth: false },
    { id: 13, label: 'Quarterly', showYear: true, showMonth: false },
    { id: 14, label: 'Monthly', showYear: true, showMonth: false },
    { id: 15, label: 'Forthnightly', showYear: true, showMonth: true },
    { id: 16, label: 'Weekly', showYear: true, showMonth: true },
    { id: 17, label: 'Today', showYear: false, showMonth: false },
  ];

  @Output() dateChange = new EventEmitter<any>();

  @Input()
  set years(years: number[]) {
    this.yearList = years;
  }

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.setDefault();
  }

  setDefault() {
    const today = new Date();
    this.selectedYearType = 7;
    this.selectedFilterType = this.calendateDateFilterTypes.find(item => item.id === this.selectedYearType);
    this.selectedYearTypeText = this.selectedFilterType.label;
    this.selectedYear = today.getFullYear();
    this.selectedMonth = today.getMonth();
    this.dateFilterList = this.generateDateFilterList();
    setTimeout(() => {
      this.selectedDateFilter = this.dateFilterList[0];
    }, 100);
  }

  onYearTypeChange(dateType) {
    this.yearTypeFilterToggle = false;
    this.selectedDateFilter = null;
    this.selectedFilterType = dateType;
    this.selectedYearType = dateType.id;
    this.selectedYearTypeText = dateType.label;
    this.dateFilterList = this.generateDateFilterList();
  }

  onYearChange($event) {
    this.selectedYear = parseInt($event.target.value, 10);
    this.selectedMonth = null;
    this.selectedDateFilter = null;
    this.dateFilterList = this.generateDateFilterList();
  }

  onMonthChange($event) {
    this.selectedDateFilter = null;
    this.selectedMonth = parseInt($event.target.value, 10);
    this.dateFilterList = this.generateDateFilterList();
  }

  onChangeDateFilter(dateFilter) {
    console.log('dateFilter', dateFilter);
    this.selectedDateFilter = dateFilter;
    this.dateChange.next(JSON.parse(JSON.stringify(dateFilter)));
  }

  generateDateFilterList() {
    if (!this.selectedYearType || !this.selectedYear) {
      return [];
    }

    switch (this.selectedYearType) {
      case 1:
        return this.generateCalendarYearly(this.selectedYear);
      case 11:
        return this.generateFinancialYearly(this.selectedYear);
      case 2:
        return this.generateCalendarHalfYearly(this.selectedYear);
      case 12:
        return this.generateFinancialHalfYearly(this.selectedYear);
      case 3:
        return this.generateCalendarQuarterly(this.selectedYear);
      case 13:
        return this.generateFinancialQuarterly(this.selectedYear);
      case 4:
        return this.generateCalendarMonthly(this.selectedYear);
      case 14:
        return this.generateFinancialMonthly(this.selectedYear);
      case 5:
      case 15:
        if (this.selectedMonth === null) {
          return [];
        }
        return this.generateCalendarForthnightly(this.selectedYear, this.selectedMonth);
      case 6:
      case 16:
        if (this.selectedMonth === null) {
          return [];
        }
        return this.generateCalendarWeekly(this.selectedYear, this.selectedMonth);
      case 7:
      case 17:
        return this.generateToday();
    }
  }

  generateCalendarYearly(selectedYear) {
    return [
      this.generateDateFilter(`Year ${selectedYear}`, new Date(selectedYear, 0, 1), new Date(selectedYear, 11, 31))
    ];
  }

  generateFinancialYearly(selectedYear) {
    return [
      // tslint:disable-next-line:max-line-length
      this.generateDateFilter(`Mar ${selectedYear} - Apr ${selectedYear + 1}`, new Date(selectedYear, 3, 1), new Date(selectedYear + 1, 2, 31))
    ];
  }

  generateCalendarHalfYearly(selectedYear) {
    return [
      // `Year ${selectedYear} (Jan-Jun)`,
      this.generateDateFilter(`Jan ${selectedYear} - Jun ${selectedYear}`, new Date(selectedYear, 0, 1), new Date(selectedYear, 5, 30)),
      // `Year ${selectedYear} (Jul-Dec)`
      this.generateDateFilter(`Jul ${selectedYear} - Dec ${selectedYear}`, new Date(selectedYear, 6, 1), new Date(selectedYear, 11, 31))
    ];
  }

  generateFinancialHalfYearly(selectedYear) {
    return [
      // tslint:disable-next-line:max-line-length
      this.generateDateFilter(`Apr ${selectedYear} - Sep ${selectedYear}`, new Date(selectedYear, 3, 1), new Date(selectedYear, 8, 30)),
      // tslint:disable-next-line:max-line-length
      this.generateDateFilter(`Oct ${selectedYear} - Mar ${selectedYear + 1}`, new Date(selectedYear, 9, 1), new Date(selectedYear + 1, 2, 31))
    ];
  }

  generateCalendarQuarterly(selectedYear) {
    return [
      this.generateDateFilter(`Q1 Jan-Mar`, new Date(selectedYear, 0, 1), new Date(selectedYear, 2, 31)),
      this.generateDateFilter(`Q2 Apr-Jun`, new Date(selectedYear, 3, 1), new Date(selectedYear, 5, 30)),
      this.generateDateFilter(`Q3 Jul-Sep`, new Date(selectedYear, 6, 1), new Date(selectedYear, 8, 30)),
      this.generateDateFilter(`Q4 Oct-Dec`, new Date(selectedYear, 9, 1), new Date(selectedYear, 11, 31))
    ];
  }

  generateFinancialQuarterly(selectedYear) {
    return [
      this.generateDateFilter(`Q1 Apr-Jun`, new Date(selectedYear, 0, 1), new Date(selectedYear, 0, 31)),
      this.generateDateFilter(`Q1 Apr-Jun`, new Date(selectedYear, 3, 1), new Date(selectedYear, 5, 30)),
      this.generateDateFilter(`Q2 Jul-Sep`, new Date(selectedYear, 6, 1), new Date(selectedYear, 8, 30)),
      this.generateDateFilter(`Q3 Oct-Dec`, new Date(selectedYear, 9, 1), new Date(selectedYear, 11, 31)),
      this.generateDateFilter(`Q4 Jan-Mar`, new Date(selectedYear + 1, 0, 1), new Date(selectedYear + 1, 2, 31)),
    ];
  }

  generateCalendarMonthly(selectedYear) {
    return [
      this.generateDateFilter(`${selectedYear}-Jan`, new Date(selectedYear, 0, 1), new Date(selectedYear, 0, 31)),
      // tslint:disable-next-line:max-line-length
      this.generateDateFilter(`${selectedYear}-Feb`, new Date(selectedYear, 1, 1), new Date(selectedYear, 1, this.getFebDays(selectedYear))),
      this.generateDateFilter(`${selectedYear}-Mar`, new Date(selectedYear, 2, 1), new Date(selectedYear, 2, 31)),
      this.generateDateFilter(`${selectedYear}-Apr`, new Date(selectedYear, 3, 1), new Date(selectedYear, 3, 30)),
      this.generateDateFilter(`${selectedYear}-May`, new Date(selectedYear, 4, 1), new Date(selectedYear, 4, 31)),
      this.generateDateFilter(`${selectedYear}-Jun`, new Date(selectedYear, 5, 1), new Date(selectedYear, 5, 30)),
      this.generateDateFilter(`${selectedYear}-Jul`, new Date(selectedYear, 6, 1), new Date(selectedYear, 6, 31)),
      this.generateDateFilter(`${selectedYear}-Aug`, new Date(selectedYear, 7, 1), new Date(selectedYear, 7, 31)),
      this.generateDateFilter(`${selectedYear}-Sep`, new Date(selectedYear, 8, 1), new Date(selectedYear, 8, 30)),
      this.generateDateFilter(`${selectedYear}-Oct`, new Date(selectedYear, 9, 1), new Date(selectedYear, 9, 31)),
      this.generateDateFilter(`${selectedYear}-Nov`, new Date(selectedYear, 10, 1), new Date(selectedYear, 10, 30)),
      this.generateDateFilter(`${selectedYear}-Dec`, new Date(selectedYear, 11, 1), new Date(selectedYear, 11, 31)),
    ];
  }

  generateFinancialMonthly(selectedYear) {
    const nextYear = selectedYear + 1;
    return [
      this.generateDateFilter(`${selectedYear}-Apr`, new Date(selectedYear, 3, 1), new Date(selectedYear, 3, 30)),
      this.generateDateFilter(`${selectedYear}-May`, new Date(selectedYear, 4, 1), new Date(selectedYear, 4, 31)),
      this.generateDateFilter(`${selectedYear}-Jun`, new Date(selectedYear, 5, 1), new Date(selectedYear, 5, 30)),
      this.generateDateFilter(`${selectedYear}-Jul`, new Date(selectedYear, 6, 1), new Date(selectedYear, 6, 31)),
      this.generateDateFilter(`${selectedYear}-Aug`, new Date(selectedYear, 7, 1), new Date(selectedYear, 7, 31)),
      this.generateDateFilter(`${selectedYear}-Sep`, new Date(selectedYear, 8, 1), new Date(selectedYear, 8, 30)),
      this.generateDateFilter(`${selectedYear}-Oct`, new Date(selectedYear, 9, 1), new Date(selectedYear, 9, 31)),
      this.generateDateFilter(`${selectedYear}-Nov`, new Date(selectedYear, 10, 1), new Date(selectedYear, 10, 30)),
      this.generateDateFilter(`${selectedYear}-Dec`, new Date(selectedYear, 11, 1), new Date(selectedYear, 11, 31)),
      this.generateDateFilter(`${selectedYear}-Jan`, new Date(selectedYear, 0, 1), new Date(selectedYear, 0, 31)),
      // tslint:disable-next-line:max-line-length
      this.generateDateFilter(`${nextYear}-Feb`, new Date(nextYear, 1, 1), new Date(nextYear, 1, this.getFebDays(nextYear))),
      this.generateDateFilter(`${nextYear}-Mar`, new Date(nextYear, 2, 1), new Date(nextYear, 2, 31))
    ];
  }

  generateCalendarForthnightly(selectedYear, selectedMonth) {
    const lastDate = selectedMonth === 1 ? this.getFebDays(selectedYear) : this.monthDays[selectedMonth];
    return [
      this.generateDateFilter(
        `${this.months[selectedMonth]}-1 to ${this.months[selectedMonth]}-14`,
        new Date(selectedYear, selectedMonth, 1), new Date(selectedYear, selectedMonth, 14)),
      this.generateDateFilter(
        `${this.months[selectedMonth]}-15 to ${this.months[selectedMonth]}-${lastDate}`,
        new Date(selectedYear, selectedMonth, 15), new Date(selectedYear, selectedMonth, lastDate)),
    ];
  }

  generateCalendarWeekly(selectedYear, selectedMonth) {
    const lastDate = selectedMonth === 1 ? this.getFebDays(selectedYear) : this.monthDays[selectedMonth];
    return [
      this.generateDateFilter(`Week-1`, new Date(selectedYear, selectedMonth, 1), new Date(selectedYear, selectedMonth, 7)),
      this.generateDateFilter(`Week-2`, new Date(selectedYear, selectedMonth, 8), new Date(selectedYear, selectedMonth, 14)),
      this.generateDateFilter(`Week-3`, new Date(selectedYear, selectedMonth, 15), new Date(selectedYear, selectedMonth, 21)),
      this.generateDateFilter(`Week-4`, new Date(selectedYear, selectedMonth, 22), new Date(selectedYear, selectedMonth, 28)),
      this.generateDateFilter(`Week-5`, new Date(selectedYear, selectedMonth, 29), new Date(selectedYear, selectedMonth, lastDate)),
    ];
  }

  generateToday() {
    return [
      this.generateDateFilter(`Today`, new Date(), new Date()),
    ];
  }

  getFebDays(selectedYear) {
    return selectedYear % 4 === 0 ? 29 : 28;
  }

  generateDateFilter(label, startDate, endDate) {
    return { label, startDate, endDate };
  }

  public onCloseAction(): void {
    this.activeModal.dismiss('Cross click');
  }
}
