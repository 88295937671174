import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NotifyService {

  // Observable string sources
  private showSessionExpiry = new Subject<boolean>();
  showSessionExpiry$ = this.showSessionExpiry.asObservable();
  constructor() { }

  setShowSessionExpiry(fetch: boolean) {
    this.showSessionExpiry.next(fetch);
  }
}
