import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../../core/components/select-steps/select-steps-model/select-steps.model';
import { CompanyCommonsMasterService } from '../../../company-commons-master/company-commons-master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { SelectStepsComponent, AgActionCellRendererComponent, AgNumberCountComponent } from '../../../core/components';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'app-latecoming-policy',
  templateUrl: './latecoming-policy.component.html',
  styleUrls: ['./latecoming-policy.component.css']
})
export class LatecomingPolicyComponent implements OnInit {
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('agGrid') agGrid: any;
  rowData: any;
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public modalRef: any;
  public gridAPI: GridApi;
  public numberOfRowsPerPage = 10;
  private selectedFilterData: any = { JSONText: {} };
  /// public shiftMasterForm: FormGroup;
  lateInPolicyForm = new FormGroup({
    // LateInPolicy_Code: new FormControl(''),
    // Sys_Code: new FormControl(''),
    Company_Code: new FormControl(''),
    Plant_Code: new FormControl(''),
    Name: new FormControl(''),
    // ShortCode: new FormControl(''),
    // Version: new FormControl(''),
    // IsLabour: new FormControl(''),
    // ApplicableDate: new FormControl(''),
    // ValiduptoDate: new FormControl(''),
    IsDefault: new FormControl(''),
    // IsCompany: new FormControl(''),
    MaxLateOut: new FormControl(''),
    // IsApplicableLateOut: new FormControl(''),
    MaxLateIn: new FormControl(''),
    MaxLateInAllow: new FormControl(''),
    MaxLateOutAllow: new FormControl(''),
    // CreatedDate: new FormControl(''),
    // CreateBy: new FormControl(''),
    // ModifiedDate: new FormControl(''),
    // ModifiedBy: new FormControl(''),
    // IsActive: new FormControl(''),
    // IsDelete: new FormControl(''),
    // IsSync: new FormControl(''),
  });
  masterData = [];
  statementType: any;
  public companyData: any[] = [];
  public plantData: any[] = [];
  editLateInPolicyCode1: string;
  show = false;
  editMode = false;
  lateinpolicycode: any;
  syscode: any;
  plantcode: any;
  version: any;
  save = false;
  update = false;
  // columnDefs = [
  //   {
  //     headerName: '#', field: '', width: 50,
  //     sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
  //   },
  //   { headerName: 'LateInPolicy Code', field: 'LateInPolicy_Code', sortable: true, width: 70, filter: true },
  //   { headerName: 'System Code', field: 'Sys_Code', sortable: true, width: 40, filter: true },
  //   { headerName: 'Company Code', field: 'Company_Code', sortable: true, width: 70, filter: true },
  //   { headerName: 'ShortCode', field: 'ShortCode', sortable: true, width: 70, filter: true },
  //   { headerName: 'Name', field: 'Name', sortable: true, width: 70, filter: true },
  //   { headerName: 'Plant Code', field: 'Plant_Code', sortable: true, width: 70, filter: true },
  //   { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
  //   { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
  //   { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
  //   { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
  //   { headerName: 'IsDefault', field: 'IsDefault', sortable: true, width: 70, filter: true },
  //   { headerName: 'IsCompany', field: 'IsCompany', sortable: true, width: 70, filter: true },
  //   { headerName: 'MaxLateOut', field: 'MaxLateOut', sortable: true, width: 70, filter: true },
  //   { headerName: 'IsApplicableLateOut', field: 'IsApplicableLateOut', sortable: true, width: 70, filter: true },
  //   { headerName: 'MaxLateIn', field: 'MaxLateIn', sortable: true, width: 70, filter: true },
  //   { headerName: 'MaxLateInAllow', field: 'MaxLateInAllow', sortable: true, width: 70, filter: true },
  //   { headerName: 'MaxLateOutAllow', field: 'MaxLateOutAllow', sortable: true, width: 70, filter: true },
  //   // { headerName: 'CreatedDate', field: 'CreatedDate', sortable: true, width: 270, filter: true },
  //   // { headerName: 'CreateBy', field: 'CreateBy', sortable: true, width: 270, filter: true },
  //   // { headerName: 'ModifiedDate', field: 'ModifiedDate', sortable: true, width: 270, filter: true },
  //   // { headerName: 'ModifiedBy', field: 'ModifiedBy', sortable: true, width: 270, filter: true },
  //   // { headerName: 'IsActive', field: 'IsActive', sortable: true, width: 270, filter: true },
  //   // { headerName: 'IsDelete', field: 'IsDelete', sortable: true, width: 270, filter: true },
  //   // { headerName: 'IsSync', field: 'IsSync', sortable: true, width: 270, filter: true },
  //   // { headerName: 'IsPlantDefault', field: 'IsPlantDefault', sortable: true, width: 270, filter: true },
  //   // { headerName: 'User id', field: 'UserID', sortable: true, width: 270, filter: true },
  //   { headerName: 'StatementType', field: 'StatementType', sortable: true, width: 50, filter: true },
  //   {
  //     headerName: 'Action', field: '', width: 50,
  //     sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
  //   }
  // ];


  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };

  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  constructor(private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private logHelperService: LogHelperService,
    private activatedRoute: ActivatedRoute,
    public sharedDataService: SharedDataService,
    private modalService: NgbModal, private service: CompanyCommonsMasterService) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

  }
  // tslint:disable-next-line: function-name
  IfCheck($event) {
    if ($event.target.checked === true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }
  ngOnInit() {
    // this.plantMaster();
    this.getLateInPolicy();
    this.getPlantData();
    this.getCompanyData();
    // this.getCountry();
    // this.sharedDataService.getMasterData().subscribe((masterList: any) => {
    //
    //   console.log('master data', masterList);
    //   this.masterData = masterList;

    //   const unit = this.masterData.filter(list => list.Parent_Code === 150);
    //   const owner = this.masterData.filter(list => list.Parent_Code === 162);
    //   const location = this.masterData.filter(list => list.Parent_Code === 165);
    //   const status = this.masterData.filter(list => list.Parent_Code === 949);
    //   this.siteMasterData = this.bindDataforMasterItem(status);
    //   this.unitetypeList = this.bindDataforMasterItem(unit);
    //   this.ownershiplist = this.bindDataforMasterItem(owner);
    //   this.locationlist = this.bindDataforMasterItem(location);
    //   if (masterList.length) {
    //     this.plantMasterForm.reset();
    //   }
    // });
  }

  bindDataforMasterItem(filteredData) {
    const convertedData = [];
    filteredData.forEach((element: any) => {
      const subData = [];
      const subDataFilter = this.masterData.filter(list => list.Parent_Code === element.MasterItem_Code);
      if (subDataFilter.length > 0) {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name, children: this.bindDataforMasterItem(subDataFilter) });
      } else {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name });
      }
    });
    return convertedData;
  }


  getCompanyData() {
    return this.service.getCompanyMasterData().subscribe((res: any) => {
      this.companyData = res.Data.Table;
      // if (this.companyData.length === 1) {
      //   // this.labourForm.control.value.Company;
      //   this.earlyGoingPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
      // } else {
      //   this.companyData = res.Data.Table;
      // }
    });
  }

  getPlantData() {
    return this.service.getPlantMasterData().subscribe((res: any) => {
      // this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
      this.plantData = res.Data.Table;
      // if (this.plantData.length === 1) {
      //   // this.labourForm.control.value.Company;
      //   this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
      // } else {
      //   this.plantData = res.Data.Table;
      // }
    });
  }

  // plantMaster() {
  //   return this.service.getPlant().subscribe((value: any) => {
  //     this.rowData = value;
  //   });
  // }

  getLateInPolicy() {
    const model = {
      UserID: localStorage.getItem('UserID'),
      LateInPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MaxLateOut: '',
      IsApplicableLateOut: '',
      MaxLateIn: '',
      MaxLateInAllow: '',
      MaxLateOutAllow: '',
      StatementType: 'Select'
    };

    this.service.getLateInPolicy(model).subscribe((response: any[]) => {


      // return this.service.getUnitwiseDetails().subscribe((response: any) => {
      this.rowData = response;



      console.log('xyz', (response));

    });

  }
  // getCountry() {
  //   return this.service.getCountry().subscribe((res: any) => {
  //     this.countries = res.Data.Table;
  //   });
  // }
  // getStatesByCountryId() {
  //   return this.service.getStatesByCountryId(this.plantMasterForm.controls['Country_Code'].value || 100)
  //     .subscribe((res: any) => {
  //       this.states = res.Data.Table;
  //     });
  // }
  // getDistrictsByState() {
  //   this.service.getDistrictsByState(this.plantMasterForm.controls['State_Code'].value || 100).subscribe((res: any) => {
  //     this.districts = res.Data.Table;
  //   });
  // }
  // getCitiesByDistrict() {
  //   this.service.getCitiesByDistrict(this.plantMasterForm.controls['District_Code'].value || 100).subscribe((res: any) => {
  //     this.cities = res.Data.Table;
  //   });
  // }
  // getPostOfficeByCity() {
  //   this.service.getPOsByCity(this.earlyGoingPolicyForm.controls['Company_Code'].value || 100).subscribe((res: any) => {
  //     this.pPos = res.Data.Table;
  //   });
  //  }

  public openModal(): void {
    this.clearData();
    this.modalRef = this.modalService.open(this.tempRef,
      { size: 'lg', backdrop: 'static' });
    if (this.editLateInPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;
    }

  }
  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
    this.save = false;
    this.update = false;
  }
  private clearData() {
    this.lateInPolicyForm.reset();
  }
  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
    this.save = false;
    this.update = false;
  }


  public saveAndNew(): void {

    console.log('form data open modal', this.lateInPolicyForm.getRawValue());
    const value = this.lateInPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editLateInPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (value.Name != null && value.ShortCode != null) {
      const data = {
        // Short_Code: this.editplantCode,
        LateInPolicy_Code: this.editLateInPolicyCode1,
        Name: value.Name,
        Sys_Code: value.Sys_Code,
        Company_Code: value.Company_Code,
        ShortCode: value.ShortCode,
        Plant_Code: value.Plant_Code,
        Version: value.Version,
        IsLabour: value.IsLabour,
        ApplicableDate: value.ApplicableDate,
        ValiduptoDate: value.ValiduptoDate,
        IsDefault: value.IsDefault,
        IsCompany: value.IsCompany,
        MaxLateOut: value.MaxLateOut,
        IsApplicableLateOut: value.IsApplicableLateOut,
        MaxLateIn: value.MaxLateIn,
        MaxLateInAllow: value.MaxLateInAllow,
        MaxLateOutAllow: value.MaxLateOutAllow,
        // CreatedDate: value.CreatedDate,
        // CreateBy: value.CreateBy,
        // ModifiedDate: value.ModifiedDate,
        // ModifiedBy: value.ModifiedBy,
        // IsActive: value.IsActive,
        // IsDelete: value.IsDelete,
        // IsSync: value.IsSync,
        // IsPlantDefault: value.IsPlantDefault,
        StatementType: this.statementType,
        UserID: localStorage.getItem('UserID'),
      };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateLateInPolicy(data).subscribe((response: any) => {
          if (response.Status) {
            this.logHelperService.logSuccess({
              message: 'LateInPolicy Updated successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getLateInPolicy();
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddLateInPolicy(data).subscribe((response: any) => {
          if (response.Status) {
            this.logHelperService.logSuccess({
              message: 'LateInPolicy added successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getLateInPolicy();
          }
        });
      }
    }
    // else {
    // //   this.logHelperService.logError({
    // //     message: 'Please Fill Mandotary Fields'
    // //   });
    // // }
  }
  // unitMaster() {
  //   throw new Error('Method not implemented.');
  // }

  public editlateInPolicy_Code($event): void {
    this.openModal();
    this.editLateInPolicyCode1 = $event.LateInPolicy_Code;
    if (this.editLateInPolicyCode1) {

      this.openModal();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getLateInPolicyProfile(this.editLateInPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }
        // this.editshiftCode1 = shift;
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        this.lateInPolicyForm.controls['LateInPolicy_Code'].setValue(shift[0].LateInPolicy_Code);
        this.lateInPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.lateInPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.lateInPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.lateInPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.lateInPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.lateInPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.lateInPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.lateInPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.lateInPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.lateInPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.lateInPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.lateInPolicyForm.controls['MaxLateOut'].setValue(shift[0].MaxLateOut);
        this.lateInPolicyForm.controls['IsApplicableLateOut'].setValue(shift[0].IsApplicableLateOut);
        this.lateInPolicyForm.controls['MaxLateIn'].setValue(shift[0].MaxLateIn);
        this.lateInPolicyForm.controls['MaxLateInAllow'].setValue(shift[0].MaxLateInAllow);
        this.lateInPolicyForm.controls['MaxLateOutAllow'].setValue(shift[0].MaxLateOutAllow);
        // this.lateInPolicyForm.controls['CreatedDate'].setValue(shift[0].CreatedDate);
        // this.lateInPolicyForm.controls['CreateBy'].setValue(shift[0].CreateBy);
        // this.lateInPolicyForm.controls['ModifiedDate'].setValue(shift[0].ModifiedDate);
        // this.lateInPolicyForm.controls['ModifiedBy'].setValue(shift[0].ModifiedBy);
        // this.lateInPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        // this.lateInPolicyForm.controls['IsDelete'].setValue(shift[0].IsDelete);
        // this.lateInPolicyForm.controls['IsSync'].setValue(shift[0].IsSync);
        // this.lateInPolicyForm.controls['IsPlantDefault'].setValue(shift[0].IsPlantDefault);
        // this.lateInPolicyForm.controls['StatementType'].setValue(shift[0].StatementType);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  // tslint:disable-next-line: function-name
  CheckShortCode() {
    // tslint:disable-next-line: ter-indent
    this.service.checkLatePolicy(this.lateInPolicyForm.controls.Plant_Code.value, this.editLateInPolicyCode1).subscribe((res: any) => {
      this.plantcode = res.Data;
      if (this.plantcode > 0)
      // tslint:disable-next-line: brace-style
      {
        this.logHelperService.logError({
          message: 'This field requires a unique entry. Please enter different ShortCode.'
        });
        this.lateInPolicyForm.controls['Plant_Code'].reset();
        // this.lateInPolicyForm.controls['Name'].reset();
      }
    });
    return true;
  }
}
