import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-section-head-approval-list-modal.component',
  templateUrl: './section-head-approval-list-modal.component.html',
  styleUrls: ['./section-head-approval-list-modal.component.css'],
  host: {
    class: 'h-100 overflow-hidden d-flex flex-column'
  }
})
export class SectionHeadOTApprovalListModalComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public reportDate: string;
  public totalData: number;
  private selectedReportInfo: any;
  public totalShifColspan = 0;
  public dates: any[] = [];

  myDate: any;
  @Input()
  // tslint:disable-next-line: indent
  public set selectedReportData(value: any) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): any {
    return this.selectedReportInfo;
  }

  @Input()
  public set reportData(value: any) {
    if (value) {
      this.reportValueData = value;
      //  const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      //  this.rowData = tempRowData;
      console.log(value);

    }
  }
  public get reportData(): any {
    return this.reportValueData;
  }
  // public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  public showAlert: EventEmitter<any>;
  public selectedItems: EventEmitter<any>;
  // columnDefs: any[] = [];
  // rowData = [];
  // groupingMeta = ['ContractorName'];
  private reportValueData: any[];
  public groupedData: any;
  public finalReportData: any[] = [];
  public isCheckAll: boolean;

  constructor() {
    this.closeModal = new EventEmitter();
    this.showAlert = new EventEmitter();
    this.selectedItems = new EventEmitter();
  }

  ngOnInit() {
    // this.reportDate = this.dateConvertor(this.selectedReportData);
    this.totalData = this.reportData;
    // this.finalReportData = this.getGroupRowTableData(this.reportData);
    console.log('reportData', this.reportData);

  }

  public checkAllForDate(dateObj: any) {
    dateObj.isCheck = !dateObj.isCheck;
    if (dateObj.contractors) {
      dateObj.contractors = dateObj.contractors.map(item => {
        item.isCheck = !dateObj.isCheck;
        return this.checkContractor(item);
      });
    }
  }

  public checkContractor(contractorObj: any) {
    contractorObj.isCheck = !contractorObj.isCheck;
    if (contractorObj.shifts) {
      contractorObj.shifts = contractorObj.shifts.map(item => {
        item.isCheck = !contractorObj.isCheck;
        return this.checkShift(item);
      });
    }
    return contractorObj;
  }

  public checkShift(shiftObj: any) {
    shiftObj.isCheck = !shiftObj.isCheck;
    if (shiftObj.assoicates) {
      shiftObj.assoicates = shiftObj.assoicates.map(item => {
        return { ...item, isCheck: shiftObj.isCheck }
      });
    }

    return shiftObj;
  }

  public checkAssociates(assoicateObj: any) {
    assoicateObj.isCheck = !assoicateObj.isCheck;
  }

  public onApprove(): void {
    // let finalDataList: any[] = [];
    // this.reportData.forEach(dateObj => {
    //   if (dateObj.isCheck) {
    //     dateObj.contractors.forEach(contractor => {
    //       contractor.shifts.forEach(shift => {
    //         finalDataList = finalDataList.concat(shift.assoicates);
    //       });
    //     });
    //   } else {
    //     dateObj.contractors.forEach(contractor => {
    //       if (contractor.isCheck) {
    //         contractor.shifts.forEach(shift => {
    //           finalDataList = finalDataList.concat(shift.assoicates);
    //         });
    //       } else {
    //         contractor.shifts.forEach(shift => {
    //           if (shift.isCheck) {
    //             finalDataList = finalDataList.concat(shift.assoicates);
    //           }
    //         });
    //       }
    //     });
    //   }
    // });

    // if (!finalDataList.length) {
    //   this.showAlert.emit('Please select at least on item.');
    //   return;
    // }
    this.selectedItems.emit({ selectedItems: this.reportData, isAccept: true });
  }

  public onReject(): void {
    this.selectedItems.emit({ selectedItems: this.reportData, isAccept: false });
    // this.closeModal.emit();
  }

  private getWeekDaysName(index: number): string {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekDays[index];
  }

  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    // tslint:disable-next-line: max-line-length
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} to ${date1.getDate()}-${months[date1.getMonth()]}-${date1.getFullYear()}`;
  }

  private getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    const dateWiseData = new Set(reportData.map(item => item.date));
    dateWiseData.forEach((dateWiseItem: string) => {
      const items = reportData.filter(item => item.date === dateWiseItem);
      const dateObj = {
        date: dateWiseItem,
        sections: []
      };
      const sectionWiseData = new Set(items.map(item => item.Section));
      sectionWiseData.forEach(sectionItem => {
        const sectionItems = items.filter(item => item.Section === sectionItem);
        const sectionObj = {
          name: sectionItem,
          shifts: sectionItems
        };

        dateObj.sections.push(sectionObj);
      });

      tableData.push(dateObj);
    });
    return tableData;
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }
}
