import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-payroll-excel-file-sample',
  templateUrl: './payroll-excel-file-sample.component.html',
  styleUrls: ['./payroll-excel-file-sample.component.css']
})
export class PayrollExcelFileSampleComponent {

  @Input() public sites: any[];
  @Output() public closeModal: EventEmitter<any>;

  public fileError: string;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
    this.closeModal = new EventEmitter();
    this.formGroup = this.buildForm();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      site: [null, [Validators.required]]
    })
  }
}