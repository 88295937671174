import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-attendance-regularization-cell-renderer',
  templateUrl: './attendance-regularization-cell-renderer.component.html'
})
export class AttendanceRegularizationCellRendererComponent implements OnInit, ICellRendererAngularComp {

  params;
  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
  }

  refresh() {
    return true;
  }

  public onClick() {
    this.params.clicked(this.params.data, this.params.rowIndex);
  }

}
