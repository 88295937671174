import { Directive, TemplateRef, Input } from '@angular/core';
import { TemplateType } from './get-template.model';

@Directive({
  selector: '[appGetTemplate]'
})
export class GetTemplateDirective<T> {
  /**
    * Will give a template input pass by the user.
    */
  // tslint:disable-next-line: no-input-rename
  @Input('appGetTemplate') public templateInput: T;
  constructor(private templateRef: TemplateRef<any>) {
    this.getTemplate();
  }

  /**
   * `@description A method that will return a template reference of the modal.
   */
  public getTemplate(): TemplateType<T> {
    return {
      templateRef: this.templateRef,
      templateInput: this.templateInput
    };
  }
}
