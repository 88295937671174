import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ag-table',
  templateUrl: './ag-table.component.html',
  styleUrls: ['./ag-table.component.css']
})
export class AgTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
