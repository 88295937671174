import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaveModuleComponent } from './leave-module.component';
import { LeaveApplyFromComponent } from './leave-apply-from/leave-apply-from.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {
    path: 'leave-module',
    component: LeaveModuleComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'leave-apply-from',
    component: LeaveApplyFromComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeaveRoutingModule { }
