import { Component, Input } from "@angular/core";


@Component({
  selector: 'app-wo-task-list-footer',
  templateUrl: './wo-task-list-footer.component.html',
  styleUrls: ['./wo-task-list-footer.component.css'],
  host: {
    class: 'd-flex'
  }
})
export class WOTaskListFooterComponent {

  @Input() public taskDetail: any;

  constructor(
  ) {
  }

}