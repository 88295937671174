import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from 'src/app/app.globals';

@Injectable()
export class PolicyService {

  constructor(private httpClient: HttpClient) {

  }

  public getPolicyNumber(requestObj: any): Observable<any> {
    return this.httpClient.post(GLOBAL.APIS.POLICY_MANAGEMENT.GET_POLICY_NUMBER, requestObj);
  }

  public getPolicyHistory(requestObj: any): Observable<any> {
    return this.httpClient.post(GLOBAL.APIS.POLICY_MANAGEMENT.GET_POLICY_HISTORY, requestObj);
  }
}
