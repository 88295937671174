import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-cell-lock-renderer',
  templateUrl: './ag-action-cell-lock-renderer.component.html',
  styleUrls: ['./ag-action-cell-lock-renderer.component.css']
})
export class AgActionCellLockRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  showActiveUser = false;
  showDeactiveUser = false;
  showlockacitve = false;
  showunlockdeactive = false;
  constructor() { }

  ngOnInit() {
    if (this.params.data.IsLocked === false && this.params.data.IsActive === true) {
      this.showActiveUser = true;
      this.showDeactiveUser = false;
      this.showlockacitve = false;
      this.showunlockdeactive = false;
    } else if (this.params.data.IsLocked === true && this.params.data.IsActive === false) {
      this.showActiveUser = false;
      this.showDeactiveUser = true;
      this.showlockacitve = false;
      this.showunlockdeactive = false;
    } else if (this.params.data.IsLocked === true && this.params.data.IsActive === true) {
      this.showActiveUser = false;
      this.showDeactiveUser = false;
      this.showlockacitve = true;
      this.showunlockdeactive = false;
    } else if (this.params.data.IsLocked === false && this.params.data.IsActive === false) {
      this.showActiveUser = false;
      this.showDeactiveUser = false;
      this.showlockacitve = false;
      this.showunlockdeactive = true;
    }
  }

  agInit(params: any): void {
    if (!params.data.parent) {
      this.params = params;
    }
  }

  refresh(): boolean {
    return false;
  }

  isLockUser() {
    this.params.context.componentParent.isLockUser(this.params.data);
  }

  isActiveUser() {
    this.params.context.componentParent.isActiveUser(this.params.data);
  }

}
