import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ComponentFactory } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FilterTypeEnum } from '../model/man-power.model';

@Injectable()
export class ManPowerPrsenter {
  public reportData$: Observable<FormGroup>;
  private reportData: Subject<FormGroup>;
  public startDate$: Observable<string>;
  private startDate: Subject<string>;
  public contractor$: Observable<number>;
  private contractor: Subject<number>;
  public workOrder$: Observable<any>;
  private workOrder: Subject<any>;
  constructor(private fb: FormBuilder) {
    this.reportData = new Subject();
    this.reportData$ = this.reportData.asObservable();

    this.startDate = new Subject();
    this.startDate$ = this.startDate.asObservable();

    this.contractor = new Subject();
    this.contractor$ = this.contractor.asObservable();

    this.workOrder = new Subject();
    this.workOrder$ = this.workOrder.asObservable();
  }

  public buildForm(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        Date: [null, Validators.required],
        shiftGroup: [null],
        groupBy: [null],
        punchingWiseFilter: [null],
        labourCode: [null],
        dataOrderBy: [null],
        CurrentStatus: [null],
        Company_Code: [null, Validators.required],
        Plant_Code: [null, Validators.required],
        Unit_Code: [null],
        Department_Code: [null],
        Section_Code: [null],
        ShiftGroupType_Code: [null],
        Shift_Code: [null],
        StartDate: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        Gender: [null],
        Skill_Code: [null],
        Trade_Code: [null],
        QualificationMasterList_Code: [null],
        TechQualificationMasterList_Code: [null],
        QualificationStreamMasterList_Code: [null],
        Religion_Code: [null],
        Cast_Code: [null],
        IsDomicial: [null],
        FirstAIDTraning: [null],
        FireFighter: [null],
        SAge: [null],
        EAge: [null],
        JobType: [null],
        NatureOfWork_Code: [null],
        WageCriteria: [null],
        ContactortMenType: [null],
        ExpiredMedicalPeriod: [null],
        ExpiredFireTraining: [null],
        PoliceVerifed: [null],
        LabourDemandType: [null],
        ShiftTypeGroup_Code:[null]
      })
    });
  }

  public buildFormForUnassign(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        Date: [null, Validators.required],
        Sdate: [null],
        Edate: [null],
        shiftGroup: [null],
        groupBy: [null],
        punchingWiseFilter: [null],
        labourCode: [null],
        dataOrderBy: [null],
        CurrentStatus: [null],
        Company_Code: [null, Validators.required],
        Plant_Code: [null, Validators.required],
        Unit_Code: [null],
        Department_Code: [null],
        Section_Code: [null],
        ShiftGroupType_Code: [null],
        ShiftTypeGroup_Code: [null],
        Shift_Code: [null],
        StartDate: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        Gender: [null],
        Skill_Code: [null],
        Trade_Code: [null],
        QualificationMasterList_Code: [null],
        TechQualificationMasterList_Code: [null],
        QualificationStreamMasterList_Code: [null],
        Religion_Code: [null],
        Cast_Code: [null],
        IsDomicial: [null],
        FirstAIDTraning: [null],
        FireFighter: [null],
        SAge: [null],
        EAge: [null],
        JobType: [null],
        NatureOfWork_Code: [null],
        WageCriteria: [null],
        ContactortMenType: [null],
        ExpiredMedicalPeriod: [null],
        ExpiredFireTraining: [null],
        PoliceVerifed: [null],
        LabourDemandType: [null],
      })
    });
  }

  public bindForm(formGroup: FormGroup, data: any) {
    formGroup.patchValue(data);
  }

  public saveContractorFilterData(contractorFormGroup: FormGroup) {
    if (contractorFormGroup.get('startDate').value !== '' || contractorFormGroup.get('contractorCode').value !== null
      || contractorFormGroup.get('workOrderNo').value !== null) {
      const data: any = contractorFormGroup.value;
      data.filterType = FilterTypeEnum.ContractorFilter;
      this.reportData.next(data);
    }
  }

  public onStartDateChange(startDate: string): void {
    if (startDate !== '') {
      this.startDate.next(startDate);
    }
  }

  public onContractorChange(contractorCode: string): void {
    if (contractorCode !== null) {
      this.contractor.next(parseInt(contractorCode, 10));
    } else {
      this.contractor.next(null);
    }
  }

  public onWorkOrderChange(contractorCode: string, workOrderNo: string): void {
    if (contractorCode !== null && workOrderNo !== null) {
      this.workOrder.next({
        contractorCode,
        workOrderNo
      });
    }
  }
}
