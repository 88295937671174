import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { GLOBAL } from "../app.globals";
import { User } from "./login-model";
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const httpOptionsForm = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  }),
};

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private authSource: BehaviorSubject<any> = new BehaviorSubject(0);
  public auth$: Observable<any> = this.authSource.asObservable();
  constructor(private httpClient: HttpClient) {}

  /**
   * @description Triggers auth change event
   * @author Amit Mahida
   * @param {any} login
   * @memberof LoginService
   */
  updateOnAuthChange(login: any) {
    this.authSource.next(login);
  }

  login(params: User) {
    const body = new HttpParams()
      .set("username", params.username)
      .set("password", params.password)
      .set("grant_type", "password");
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.LOGIN}`,
      body,
      httpOptionsForm
    );
  }

  forgotpassword(userEmail, urL) {
    const queryString = $.param({
      Email: userEmail,
      url: urL,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      decodeURIComponent(
        `${GLOBAL.APIS.USER_MANAGEMENT.FORGOT_USER_PASSWORD}?${queryString}`
      ),
      httpOptions
    );
  }

  extendTimeOut(password) {
    const body = new HttpParams()
      .set("userEmail", localStorage.getItem("UserEmailId"))
      .set("userPassword", password);
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.CHECK_LOGIN}`,
      body,
      httpOptionsForm
    );
  }

  // public getglobaldata1(): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LEAVEBALANCE}`;
  //   return this.httpClient.post(url).
  //     pipe(
  //       map((response: any) => response));
  // }

  public getglobaldata() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GLOBALDATA}`,
      httpOptions
    );
  }
}
