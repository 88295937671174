import { Component, OnInit, ViewContainerRef, ComponentRef, TemplateRef, ViewChild, Input, ComponentFactoryResolver } from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';
import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import { GLOBAL } from 'src/app/app.globals';
import { EntryForThePrsenter } from 'src/app/reports/daily-attendance/entryfortheday/entryfortheday-presenter/entryfortheday.presenter';
import { EntryfordayService } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
// tslint:disable-next-line: max-line-length
import * as _ from 'lodash';
import { Contractor, MasterItemCode } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
// tslint:disable-next-line: max-line-length
import { ReportViewListTimecardPlantwiseComponent } from 'src/app/reports/daily-attendance/rpt_timecard-plantwise-report/report-view-list-timecard-plantwise/report-view-list-timecard-plantwise.component';
declare var $;
const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-listof-weekoff-presend-data',
  templateUrl: './listof-weekoff-presend-data.component.html',
  styleUrls: ['./listof-weekoff-presend-data.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class ListofWeekoffPresendDataComponent implements OnInit {

  public activeFilter: string;
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  public savedFilters: any[] = [];
  public groupByList: any[];
  public companyList: any[];
  public plantList: any[];
  public unitList: any[];
  public plantLit: any[];
  public departmentList: any[];
  public sectionList: any[];
  public companyNatureofWorkList: any[];
  public shiftGroupTypeList: any[];
  public shiftTypeList: any[];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public empList: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  public genderList: any[] = [];
  public skillList: any[] = [];
  public designationList: any[] = [];
  public qualificationList: any[] = [];
  public technicalQualificationList: any[] = [];
  public qualificationStreamList: any[] = [];
  public religionList: any[] = [];
  public castList: any[] = [];
  public jobTypeList: any[] = [];
  public labourNatureOfWorkList: any[] = [];
  public workMenTypeList: any[] = [];


  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;

  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  public pagecode: number;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;

  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;

  private filterType: FilterTypeEnum;

  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;

  private contractorCode: number;
  private workOrderNo: number;
  public userId = localStorage.getItem('UserID');
  public roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private entryfordayService: EntryfordayService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private entryForThePrsenter: EntryForThePrsenter,
    public sharedService: SharedService) {
    this.formGroup = this.entryForThePrsenter.buildForm();
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.pagecode = 342;
    this.getALLSavedFilterDescripationUserWise(this.pagecode);
    this.createFilterTab();
    this.setDefultDate();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();
    const userId = localStorage.getItem('UserID');
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (userId && roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  setDefultDate() {
    const now = new Date();
    const edate = new Date(now.getFullYear(), now.getMonth(), 0);
    const sdate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
    this.formGroup.get('JSONText').get('dateRange').setValue([sdate, edate]);

  }

  public onPlantChange(): void {
    this.unitList = [];
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (plantCode) {
      this.getUnits(plantCode);
    }
  }

  private getAllData() {
    this.setGroupByList();
    this.getCompany();
    this.getNatureOfWorks();
    this.getSections();
    this.getContractors();
    this.getGender();
    this.getSkill();
    this.getQualifications();
    this.getTechnicalQualifications();
    this.getQualificationStreams();
    this.getReligions();
    this.getCasts();
    this.getWorkManNatureOfWorks();
  }

  getCompany() {
    this.entryfordayService.getCompanies().subscribe((response: any[]) => {
      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }

  getContractors() {
    this.entryfordayService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.Plant_Code);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);

      }
    });
  }

  getContractorsSelectedByDefault(userId: any, roleCode: any) {
    this.entryfordayService.getContractorsbydefault(userId, roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].ContractorVendor_Code);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getLicensesByContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.contractorLicenseList = response;
      this.formGroup.get('JSONText').get('License_Code').setValue(this.selectedFilterData.JSONText.License_Code);
    });
  }

  getWCPolicyByContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.contractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('WCPolicy').setValue(this.selectedFilterData.JSONText.WCPolicy);
    });
  }

  getPlants(companyCode: any): void {
    this.entryfordayService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
      if (this.selectedFilterData.JSONText.Plant_Code) {
        this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
      }
    });
  }

  getUnits(plantCode: any): void {
    this.entryfordayService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get('Unit_Code').setValue(this.selectedFilterData.JSONText.Unit_Code);
    });
  }

  // getEmployee(Contractor: any, plantcode: any, unitCode: any, SubContractors: any, UserId: any): void {
  //   this.entryfordayService.getLabourDetails(Contractor, plantcode, unitCode, SubContractors, UserId).subscribe((response: any[]) => {
  //     this.empList = response;
  //     this.formGroup.get('JSONText').get('Labour_Code').setValue(this.selectedFilterData.JSONText.Labour_Code);
  //   });
  // }

  getEmployee() {

    //
    const formValue = this.formGroup.getRawValue();
    const sDate = formValue.JSONText.Date;
    const eDate = formValue.JSONText.EDate;
    let contCode = '';
    let subcontCode = '';
    let ptCode = '';
    let utCode = '';

    if (formValue.JSONText.Contractors) {
      contCode = formValue.JSONText.Contractors.join(',');
    } else {
      contCode = null;
    }

    if (formValue.JSONText.Plant_Code) {
      ptCode = formValue.JSONText.Plant_Code;
    } else {
      ptCode = null;
    }
    if (formValue.JSONText.Unit_Code) {
      utCode = formValue.JSONText.Unit_Code;
    } else {
      utCode = null;
    }


    if (formValue.JSONText.SubContractors) {
      subcontCode = formValue.JSONText.SubContractors;
    } else {
      subcontCode = null;
    }


    this.entryfordayService.getLabourDetails({
      UserId: localStorage.getItem('UserID'),
      plant_Code: ptCode,
      unit_Code: utCode,
      contractors: contCode,
      subContractors: subcontCode,
    }).subscribe((response: any[]) => {

      if (response && response.length > 0) {
        this.empList = response;
      } else {
        // message for data not found
      }
      // this.reportValueData = response;
      // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, this.reportValueData);
      // this.rowData = tempRowData;
      // console.log('Output', tempRowData);
    });

  }

  getDepartments(unitCode: any): void {
    this.entryfordayService.getDepartments(unitCode).subscribe((response: any[]) => {
      this.departmentList = response;
      this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
    });
  }

  getDesignations(companyCode: string): void {
    this.entryfordayService.getDesignations(companyCode).subscribe((response: any[]) => {
      this.designationList = response;
      this.formGroup.get('JSONText').get('Trade_Code').setValue(this.selectedFilterData.JSONText.Trade_Code);
    });
  }

  getGender(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.GENDER).subscribe((response: any[]) => {
      this.genderList = response;
      this.formGroup.get('JSONText').get('Gender').setValue(this.selectedFilterData.JSONText.Gender);
    });
  }

  getSkill(): void {
    this.entryfordayService.getSkills().subscribe((response: any[]) => {
      this.skillList = response;
      this.formGroup.get('JSONText').get('Skill_Code').setValue(this.selectedFilterData.JSONText.Skill_Code);
    });
  }

  getQualifications(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.QUALIFICATION).subscribe((response: any[]) => {
      this.qualificationList = response;
      this.formGroup.get('JSONText').get('QualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationMasterList_Code);
    });
  }

  getTechnicalQualifications(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.TECHNICALQUALIFICATION).subscribe((response: any[]) => {
      this.technicalQualificationList = response;
      this.formGroup.get('JSONText').get('TechQualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.TechQualificationMasterList_Code);
    });
  }

  getQualificationStreams(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.QUALIFICATIONSTREAM).subscribe((response: any[]) => {
      this.qualificationStreamList = response;
      this.formGroup.get('JSONText').get('QualificationStreamMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationStreamMasterList_Code);
    });
  }

  getReligions(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.RELIGION).subscribe((response: any[]) => {
      this.religionList = response;
      this.formGroup.get('JSONText').get('Religion_Code').setValue(this.selectedFilterData.JSONText.Religion_Code);
    });
  }

  getCasts(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.CASTS).subscribe((response: any[]) => {
      this.castList = response;
      this.formGroup.get('JSONText').get('Cast_Code').setValue(this.selectedFilterData.JSONText.Cast_Code);
    });
  }

  getSections(): void {
    this.entryfordayService.getSections().subscribe((response: any[]) => {
      this.sectionList = response;
      this.formGroup.get('JSONText').get('Section_Code').setValue(this.selectedFilterData.JSONText.Section_Code);
    });
  }

  getShiftTypes(): void {
    this.entryfordayService.getShiftTypes().subscribe((response: any[]) => {
      this.shiftGroupTypeList = response;
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(this.selectedFilterData.JSONText.ShiftGroupType_Code);
    });
  }

  getNatureOfWorks(): void {
    this.entryfordayService.getNatureOfWorks().subscribe((response: any[]) => {
      this.companyNatureofWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  getWorkManNatureOfWorks(): void {
    this.entryfordayService.getNatureOfWorks().subscribe((response: any[]) => {
      this.labourNatureOfWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
      // {
      //   value: '1',
      //   label: 'Contractor PO Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor and Department Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor, PO and Department Wise'
      // }
    ];
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    // this.formGroup.get('JSONText').get('subContractorCode').setValue(null);
    // this.formGroup.get('JSONText').get('subContractorWorkOrderNo').setValue(null);
    // // this.workOrders = [];
    // this.subContractors = [];
    // this.subContractorWorkOrders = [];
    if (companyCode) {
      this.getPlants(companyCode);
      this.getDesignations(companyCode);
    }
  }

  onContractorChange() {
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('License_Code').setValue(null);
    this.formGroup.get('JSONText').get('WCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('ULC').setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.Labour_Code = null;

    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];
    this.empList = [];

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    // const Contractor = this.formGroup.get('JSONText').get('Contractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    // const plant_Code = this.formGroup.get('JSONText').get('Plant_Code').value;
    // const unitCode = this.formGroup.get('JSONText').get('Unit_Code').value;
    // const SubContractors = this.formGroup.get('JSONText').get('SubContractors').value;
    // const UserId = this.formGroup.get('JSONText').get('UserID').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('ULC').disabled) {
        this.formGroup.get('JSONText').get('ULC').enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      this.getLicensesByContractorCode(contractorCode);
      this.getWCPolicyByContractorCode(contractorCode);
      this.getEmployee();
    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
      this.formGroup.get('JSONText').get('ULC').disable();
      this.getEmployee();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get('JSONText').get('WorkOrder_Code').value;
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
      this.getEmployee();
    }
    this.getEmployee();
  }

  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  public onContractorSaveEmitter(data: any) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubContractorChange() {
    const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.subContractorWorkOrders = [];
    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantcode);
      this.getWCPolicyBySubContractorCode(subContractorCode);
      this.getLicensesBySubContractorCode(subContractorCode);
      this.getEmployee();
    }
  }

  public onSubContractorSaveEmitter(data: any) {
    console.log('saved data', data);
    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  getSubWorkOrderListEmitter(contractorCode: number, plantcode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
    }
  }

  public onSaveFilter(): void {
    console.log('form data', this.formGroup.getRawValue());
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    console.log('saved data', this.reportFilterData);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();
    this.setDefultDate();
    this.openModal();
  }
  public onSubmit(): void {

    const formValue = this.formGroup.getRawValue();
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      if (formValue.JSONText.Plant_Code > 0) {
        if (formValue.JSONText.Contractors > 0) {
          formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
          formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
          console.log('StartDate', formValue.JSONText.Date);
          console.log('EndDate', formValue.JSONText.EDate);
          console.log('xyz', this.formGroup.getRawValue());

          let contCode = '';
          let ptCode = '';
          let utCode = '';
          let workorderCode = '';
          let sworkorderCode = '';
          let subconcode = '';
          let labourCode = '';
          // let Sdate = '';
          // let Edate = '';
          let isFinancial = '';
          let pfinancial = '';
          let ifinancial = '';
          let smonth = '';
          let emonth = '';
          // let catecode = '';
          // let natureofwork = '';
          // let shifttypegroupcode = '';
          // let shiftcode = '';


          if (formValue.JSONText.Contractors) {
            contCode = formValue.JSONText.Contractors.join(',');
          } else {
            contCode = null;
          }
          if (formValue.JSONText.SubContractors) {
            subconcode = formValue.JSONText.SubContractors;
          } else {
            subconcode = null;
          }
          if (formValue.JSONText.Plant_Code) {
            ptCode = formValue.JSONText.Plant_Code;
          } else {
            ptCode = null;
          }
          if (formValue.JSONText.Unit_Code) {
            utCode = formValue.JSONText.Unit_Code;
          } else {
            utCode = null;
          }
          if (formValue.JSONText.WorkOrder_Code) {
            workorderCode = formValue.JSONText.WorkOrder_Code;
          } else {
            workorderCode = null;
          }
          if (formValue.JSONText.SubWorkOrder_Code) {
            sworkorderCode = formValue.JSONText.SubWorkOrder_Code;
          } else {
            sworkorderCode = null;
          }
          if (formValue.JSONText.ULC) {
            labourCode = formValue.JSONText.ULC;
          } else {
            labourCode = null;
          }
          // if (formValue.JSONText.Date) {
          //   Sdate = formValue.JSONText.Date;
          // } else {
          //   Sdate = null;
          // }
          // if (formValue.JSONText.EDate) {
          //   Edate = formValue.JSONText.EDate;
          // } else {
          //   Edate = null;
          // }
          if (formValue.JSONText.isfinancial) {
            isFinancial = formValue.JSONText.isfinancial;
          } else {
            isFinancial = null;
          }
          if (formValue.JSONText.pFinacialYear) {
            pfinancial = formValue.JSONText.pFinacialYear;
          } else {
            pfinancial = null;
          }
          if (formValue.JSONText.lFinacialYear) {
            ifinancial = formValue.JSONText.lFinacialYear;
          } else {
            ifinancial = null;
          }
          if (formValue.JSONText.startmonth) {
            smonth = formValue.JSONText.startmonth;
          } else {
            smonth = null;
          }
          if (formValue.JSONText.endmonth) {
            emonth = formValue.JSONText.endmonth;
          } else {
            emonth = null;
          }
          // if (formValue.JSONText.NatureOfWork_Code) {
          //   natureofwork = formValue.JSONText.NatureOfWork_Code.join(',');
          // } else {
          //   natureofwork = null;
          // }
          // if (formValue.JSONText.ShiftGroupType_Code) {
          //   shifttypegroupcode = formValue.JSONText.ShiftGroupType_Code.join(',');
          // } else {
          //   shifttypegroupcode = null;
          // }
          // if (formValue.JSONText.Shift_Code) {
          //   shiftcode = formValue.JSONText.Shift_Code.join(',');
          // } else {
          //   shiftcode = null;
          // }

          // if (formValue.JSONText.CategoryWorkmenMIL_Code) {
          //   catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
          // } else {
          //   catecode = null;
          // }
          // const uid = localStorage.getItem('UserID');
          formValue.filterText = {
            DateOrMonthly: 0,
            plant_Code: ptCode,
            unit_Code: utCode,
            workOrder_Code: workorderCode,
            subWorkOrder_Code: sworkorderCode,
            Labour_Code: labourCode,
            StartDate: formValue.JSONText.Date,
            EndDate: formValue.JSONText.EDate,
            isfinancial: isFinancial,
            pFinacialYear: pfinancial,
            lFinacialYear: ifinancial,
            startmonth: smonth,
            endmonth: emonth,
            // section_Code: secCode,
            // company_Code: null,
            // UserId: uid,
            // workMenCategory_Code: null,
            // natureOfWork_Code: null,
            // shiftGroupType_Code: null,
            // shift_Code: null,
            // includingSubContractor: null,
            Contractor: contCode,
            // workOrder_Code: null,
            // license_Code: null,
            // WCPolicy: null,
            // ESIC: null,
            subContractors: subconcode,
            // subWorkOrder_Code: null,
            // subLicense_Code: null,
            // subWCPolicy: null,
            // subESIC: null,
            // gender: null,
            // skill_Code: null,
            // trade_Code: null,
            // Qulification_Code: null,
            // techQualification_Code: null,
            // streamQualification_Code: null,
            // religion_Code: null,
            // cast_Code: null,
            // JobType: null,
            // FirstAIDTraning: null,
            // IsDomicial: null,
            // FireFighter: null,
            // CurrentStatus: null,
            // SAge: null,
            // EAge: null,
            // Wage: null,
            // ContactortMenType: null,
            // ExpiredMedicalPeriod: null,
            // ExpiredFireTraining: null,
            // PoliceVerifed: null,
            Page: 'Time-CardReportSummary',
          };
          console.log('filter', formValue.filterText);
          this.entryfordayService.getPlantwiseTimeCardReportData(formValue.filterText).subscribe((response: any[]) => {
            console.log(response);

            // this.entryfordayService.getPlantwiseTimeCardReportData(formValue.JSONText).subscribe((response: any[]) => {
            this.openReportViewModal(response);
          });
        } else {
          this.logHelperService.logError({
            message: 'Please Select Contractor'
          });
        }
      }
      // tslint:disable-next-line: brace-style
      else {
        this.logHelperService.logError({
          message: 'Please Select plant'
        });
      }
    }
    // tslint:disable-next-line: brace-style
    else {
      this.logHelperService.logError({
        message: 'Please Select Date'
      });
    }

  }
  openReportViewModal(response: any[]): void {
    const formValue = this.formGroup.getRawValue();
    // tslint:disable-next-line: indent
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    }
    const modalRef = this.modalService.open(ReportViewListTimecardPlantwiseComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    // response.forEach((item: any) => {
    //   if (item.SubContractor) {
    //     item.ContractorNameWithSubContractor = `${item.ContractorName}[${item.SubContractor}]`;
    //   } else {
    //     item.ContractorNameWithSubContractor = item.ContractorName;
    //   }
    // });
    modalRef.componentInstance.selectedReportData = formValue.JSONText;
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  // public onFilterTab(filterObj: any): void {
  //   console.log('filterObj', filterObj);
  //   this.activeFilter = filterObj.name;
  //   this.selectedTabId = filterObj.id;
  //   this.manageVisibilityOfSteps();
  // }

  public onFilterTab(filterObj: any): void {

    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
        .get('Contractors').value.length > 1)) {
      this.logHelperService.logError({
        message: 'Please first fullfill data in contractor.'
      });
      return;
    }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  public onAddfilter(): void {
    console.log('form data open modal', this.formGroup.getRawValue());
    const value = this.formGroup.getRawValue();
    const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
    const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
      && item.Report_Code !== this.selectedFilterData.Report_Code);
    if (!filterTypeObj) {
      selectedFilterDataObj.id = this.selectedTabId;
      const data = {
        Report_Code: this.selectedFilterData.Report_Code || '',
        Page_Code: 342,
        FilterName: value.FilterName,
        JSONTextDetails: JSON.stringify(selectedFilterDataObj)
      };
      if (this.selectedFilterData.Report_Code) {
        this.entryfordayService.updateFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      } else {
        this.entryfordayService.addFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
            this.setDefultDate();
          }
        });
      }
    } else {
      alert('This filter is already exist.');
    }
  }

  public deleteFilterItem(filterObj: any): void {
    this.entryfordayService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(this.pagecode);
    });
  }

  private getALLSavedFilterDescripationUserWise(pagecode: number) {
    this.entryfordayService.getALLSavedFilterDescripationUserWise(pagecode).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();
    // this.reportFilterData = null;
    // this.selectedFilterData = null;

    // this.contractorCode = null;
    // this.workOrderNo = null;
    // this.subContractors = [];
    // this.workOrders = [];
    // // this.contractors=[];
    // setTimeout(() => {
    //   this.contractors = [...this.contractors];
    // }, 100);

    // this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: any) {

    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.entryForThePrsenter.bindForm(this.formGroup, filterObj);
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  private getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
          this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
        }
      }
    });
  }

  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
      }
    });
  }

  getWCPolicyBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.subContractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('SubWCPolicy').setValue(this.selectedFilterData.JSONText.SubWCPolicy);
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subContractorLicenseList = response;
      this.formGroup.get('JSONText').get('SubLicense_Code').setValue(this.selectedFilterData.JSONText.SubLicense_Code);
    });
  }

  private getSubContractors(workOrderNo: any = ''): void {
    // this.entryfordayService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
    //   this.subContractors = response;
    // });
    this.entryfordayService.getContractors('', workOrderNo).subscribe((response: any[]) => {
      this.subContractors = response;
      this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
        this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
          this.selectedFilterData.JSONText.Plant_Code);
        this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);

      }
    });
  }

  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      {
        id: 2,
        name: 'Company',
        active: false
      },
      {
        id: 3,
        name: 'Contractor',
        active: false
      },
      {
        id: 4,
        name: 'Sub-Contractor',
        active: false
      },
      {
        id: 5,
        name: 'Associate',
        active: false
      }

    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }

}
