import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/core/services';
import { Checklist, WizardStep, FileDoc } from 'src/app/core/components';
import { AgFilterCellRenderComponent } from
  'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { EcPoliciesService } from '../ec-policies.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { MasterItemCode } from '../../contractor.enum';
import { GLOBAL } from 'src/app/app.globals';
import * as moment from 'moment';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-ec-policies-form',
  templateUrl: './ec-policies-form.component.html',
  styleUrls: ['./ec-policies-form.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class EcPoliciesFormComponent implements OnInit, OnDestroy {

  @ViewChild('ecPolicyForm') public ecPolicyForm: NgForm;
  @ViewChild('statusSelectBox') statusSelectBox: NgSelectComponent;

  public checkList: Checklist[] = [];
  public wizardSteps: WizardStep[] = [];
  public contractorWorkOrderList: any[] = [];
  public contractorList: any[] = [];
  public subContractorList: any[] = [];
  public subContractorWorkOrderList: any[] = [];
  public plantList: any[] = [];
  public statuses: any[] = [];
  public natureOfWorkList: any[] = [];
  // datepicker control config
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  // selector component config
  public contractorConfig: any;
  public subContractorConfig: any;
  public workOrderConfig: any;
  public subContractorWOConfig: any;
  public editMode = false;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  public isSubContractor = false;
  public documentTypes = [];
  public uploadedFiles: FileDoc[] = [];
  public isValidStartDate = false;

  public minDate = new Date();
  public minEndDate: Date;
  public allowedStartDate:any='';
  private onDestroy: Subject<any>;
  private ecPolicy: any = {};
  private isContractorTouched = false;
  private isContractorWOTouched = false;
  private isSubContractorTouched = false;
  private isSubContractorWOTouched = false;
  public allowedFileTypes: any = '';
  public maxSizeAllowed: any = '';
  globalSettings:any;
  isContractor:any =false;
  headerTitleName:string='';
  roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  userId = localStorage.getItem('UserID');
  isVerifyDate:boolean = true
  isStatus:boolean = true


  constructor(
    private sharedService: SharedService,
    private ecPolicyService: EcPoliciesService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.onDestroy = new Subject();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

    this.createConfigForContractor();
    this.createConfigForWorkOrder();
    this.createConfigForSubContractor();
    this.createConfigForSubContractorWO();
  }

  ngOnInit() { 
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      if (res) {
        this.globalSettings = res.Data.Table;
        this.maxSizeAllowed = Number(this.globalSettings.find(item => item.Code === 'DOCSIZEEC1').Value)* 1024;
        this.allowedFileTypes = this.globalSettings.find(item => item.Code === 'DOCFORMATEC1').Value;
        this.allowedStartDate = this.globalSettings.find(item => item.Code === 'MOD119PPG193ECCDSVSTD').Value;
      }
    });
    const roles = JSON.parse(localStorage.getItem('Role'));
    this.isContractor = roles.some(item => item.Role_Code ===119);
    if (this.checkContractorType()) {
      this.bindWizardSteps();
      setTimeout(() => {
        this.getEcPolicyDetails();
        this.getDocumentTypes();
        this.bindCheckList();

        window.addEventListener('scroll', this.onScroll, true);
      }, 10);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  public onContractorChange(data): void {
    if (!data) return;
    if(this.ecPolicy.ParentContractorVendor_Code){
      this.ecPolicy.ParentContractorVendor_Code = data.ContractorVendor_Code;
    }
    this.isContractorTouched = true;
    this.contractorWorkOrderList = [];
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];

    this.ecPolicyForm.controls['ParentWorkOrderCode'].setValue(null);
    this.ecPolicy.ParentWorkOrderCode = null;
    if (this.isSubContractor) {
      this.ecPolicyForm.controls['ContractorVendor_Code'].setValue(null);
      this.ecPolicy.ContractorVendor_Code = null;
      this.ecPolicyForm.controls['ChildWorkOrderCode'].setValue(null);
      this.ecPolicy.ChildWorkOrderCode = null;
    }
    const contractorCode = this.ecPolicyForm.controls['ParentContractorVendor_Code'].value;
    if (contractorCode) {
      this.getContractorWorkOrder(contractorCode);
    }
  }

  public onContractorWOChange(data: any): void {
    if (!data) return;
    if(this.ecPolicy.ParentWorkOrderCode){
      this.ecPolicy.ParentWorkOrderCode = data.WorkOrder_Code;
    }
    if (this.isSubContractor) {
      this.ecPolicyForm.controls['ContractorVendor_Code'].setValue(null);
      this.ecPolicyForm.controls['ChildWorkOrderCode'].setValue(null);
      this.ecPolicy.ContractorVendor_Code = null;
      this.ecPolicy.ChildWorkOrderCode = null;
    }
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];
    const parentWorkOrderCode = this.ecPolicyForm.controls['ParentWorkOrderCode'].value;
    const contractorCode = this.ecPolicyForm.controls['ParentContractorVendor_Code'].value;
  
    if (parentWorkOrderCode && contractorCode && this.isSubContractor) {
      this.getSubContractorsByExcludeCode(contractorCode, parentWorkOrderCode);
    }
  }

  public onSubContractorChange(data: any): void {
    if (!data) return;
    this.isContractorTouched = true;
    if(this.ecPolicy.ContractorVendor_Code){
      this.ecPolicy.ContractorVendor_Code = data.ContractorVendor_Code;
    }
    this.subContractorWorkOrderList = [];
    this.ecPolicyForm.controls['ChildWorkOrderCode'].setValue(null);
    this.ecPolicy.ChildWorkOrderCode = null;
    const ContractorCode = this.ecPolicyForm.controls['ParentContractorVendor_Code'].value;
    const workorderCode =  this.ecPolicyForm.controls['ParentWorkOrderCode'].value;
    const subContractorCode = this.ecPolicyForm.controls['ContractorVendor_Code'].value;
    if (subContractorCode) {
      this.getSubContractorWorkOrder(ContractorCode, subContractorCode, workorderCode);
    }
  }

  public onSubContractorWOChange(data: any): void {
    if (!data) return;
    if(this.ecPolicy.ChildWorkOrderCode){
      this.ecPolicy.ChildWorkOrderCode = data.WorkOrder_Code;
    }
  }

  public onStartDateChange(): void {
    if (this.ecPolicyForm.controls['StartDate'].valid) {
      //this.ecPolicyForm.controls['OrderStartDate'].patchValue(new Date((new Date(this.ecPolicyForm.controls['OrderStartDate'].value)).getTime() + (60 * 60 * 24 * 1000)));
      const startDate = this.ecPolicyForm.controls['StartDate'].value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        // this.ecPolicyForm.controls['StartDate'].setValue('');
        if(this.allowedStartDate == 1){
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>Please enter current date or greater than current date.</span>`,
          type: 'warning'
        };
        this.openAlert();
      }
        // return;
      }
      const endDate = this.ecPolicyForm.controls['EndDate'].value;
      this.isValidStartDate = true;
      this.minEndDate = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      //this.minEndDate = startDate;
      if (startDate > endDate) {
        if (this.ecPolicyForm.controls['EndDate'].valid) {
          this.ecPolicyForm.controls['EndDate'].setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      this.isValidStartDate = false;
      this.ecPolicyForm.controls['EndDate'].setValue('');
    }
  }

  public onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  public saveAndNew(): void {
    this.onSubmit(this.ecPolicyForm.value, true);
  }

  public updateAndNew(): void {
    this.onSubmit(this.ecPolicyForm.value, true);
  }

  public clearForm(): void {
    this.ecPolicyForm.reset();
  }

  public onCancel() {
    this.router.navigate(['/contractor/ec-policies']);
  }

  public onSubmit(formValue: any, saveAndNew: boolean): void {
    if (this.uploadedFiles.length === 0) {
      this.showAlert('error', 'Please add ec policy documents.', 'Error!!!');
      return;
    }
    let ecPolicyObj: any;
    ecPolicyObj = { ...this.ecPolicy, ...formValue };
    ecPolicyObj.CreateBy = localStorage.getItem('UserID');
    ecPolicyObj.StartDate = new Date((new Date(ecPolicyObj.StartDate)).getTime() + (60 * 60 * 24 * 1000));
    const ecPolicyNo = ecPolicyObj.ECPolicyNo;
    const ecPolicyCode = ecPolicyObj.ECPolicy_Code || '';
    this.ecPolicyService.checkECPolicyNoAvailability(ecPolicyNo, ecPolicyCode).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any) => {
        if (response.Data === 0) {
          ecPolicyObj.IsDirect = true;
          if (this.isSubContractor) {
            ecPolicyObj.IsDirect = false;
          } else {
            ecPolicyObj.ContractorVendor_Code = ecPolicyObj.ParentContractorVendor_Code;
            ecPolicyObj.ParentContractorVendor_Code = null;
          }

          ecPolicyObj.DocumentImageMasterViews = [];
          if (ecPolicyObj.ECPolicy_Code) {
            this.ecPolicyService.updateECPolicy(ecPolicyObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                if (response) {

                  this.saveDocuments(response, saveAndNew, false);
                } else {
                  this.alertOption = {
                    title: 'Error!!!',
                    html: `<span class='customClass'>Some error has occured! Please try again.</span>`,
                    type: 'error'
                  };
                  this.openAlert();

                }
              }
            );
          } else {
            this.ecPolicyService.addECPolicy(ecPolicyObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                if (response.Code) {

                  this.saveDocuments(response.Code, saveAndNew, true);
                } else {
                  this.alertOption = {
                    title: 'Error!!!',
                    html: `<span class='customClass'>Some Error has occured! Please try again.</span>`,
                    type: 'error'
                  };
                  this.openAlert();
                }
              }
            );
          }
        } else {
          this.alertOption = {
            title: 'Error!!!',
            html: `<span class='customClass'>EC Policy No. is already exist.</span>`,
            type: 'error'
          };
          this.openAlert();
        }
      });
  }

  private saveDocuments(response: any, saveAndNew: boolean, isAdd: boolean) {

    const uploadParams = {
      ECPolicy_Code: response
    };
    const filesToUpload = this.getNotUploadedItems(this.uploadedFiles);
    if (uploadParams.ECPolicy_Code && filesToUpload.length > 0) {
      this.ecPolicyService.uploadDocuments(filesToUpload, uploadParams)
        .pipe(takeUntil(this.onDestroy)).subscribe((res) => {
          if (saveAndNew) {
            this.showECPolicyInfo(isAdd, false);
            this.resetFormAfterSave();
          } else {
            this.showECPolicyInfo(isAdd, true);
          }
        });
    } else if (saveAndNew) {
      this.showECPolicyInfo(isAdd, false);
      this.resetFormAfterSave();
    } else {
      this.showECPolicyInfo(isAdd, true);
    }
  }

  private showECPolicyInfo(isAdd: boolean, redirect: boolean) {
    const message = isAdd ? 'EC Policy is successfully added.' : 'EC Policy is successfully updated.';
    this.alertOption = {
      title: 'Success!!!',
      html: `<span class='customClass'>${message}</span>`,
      type: 'success',
      timer: 2000
    };
    this.openAlert();

    if (redirect) {
      setTimeout(() => {
        this.router.navigate(['/contractor/ec-policies']);
      }, 3000);
    }
  }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }

  private checkContractorType(): boolean {
    let isValidType = false;
    const id: string = this.activatedRoute.snapshot.params.id;
    if (id) {
      isValidType = true;
      this.editMode = true;
    } else {
      const type: string = this.activatedRoute.snapshot.queryParamMap.get('type');
      if (type === '1' || type === '0') {
        this.isSubContractor = type === '0';
        isValidType = true;
      }
    }
    return isValidType;
  }

  private bindWizardSteps() {
    this.wizardSteps = [
      {
        id: 'ecPolicyDetails',
        name: 'E.C. Policy',
        isValid: () => {
          // return true;
          return this.wizard1Validation();
        },
      },
      {
        id: 'documentDetails',
        name: 'Documents',
        isValid: () => { return true; }
      }
    ];
  }
  private wizard1Validation(): boolean {
    let flag = false;
    if (this.ecPolicyForm.controls.Plants) {
      flag = this.ecPolicyForm.controls.Plants.valid
        && this.ecPolicyForm.controls.ParentContractorVendor_Code.valid
        && this.ecPolicyForm.controls.ParentWorkOrderCode.valid
        && this.ecPolicyForm.controls.StartDate.valid
        && this.ecPolicyForm.controls.EndDate.valid
        && this.ecPolicyForm.controls.Person.valid
        && this.ecPolicyForm.controls.NatureOfWorkList.valid
        && this.ecPolicyForm.controls.SatusMasterList_Code.valid;

      if (flag) {
        flag = this.checkValidEndDate();
      }

      if (flag && this.isSubContractor) {
        flag = this.ecPolicyForm.controls.ContractorVendor_Code.valid
          && this.ecPolicyForm.controls.ChildWorkOrderCode.valid;
      }
    }
    return flag;
  }
  private checkValidEndDate() {
    if (this.ecPolicyForm.controls.StartDate.value !== '' && this.ecPolicyForm.controls.EndDate.value !== '') {
      const startDate: Date = new Date(this.ecPolicyForm.controls.StartDate.value);
      const endDate: Date = new Date(this.ecPolicyForm.controls.EndDate.value);
      if (startDate > endDate) {
        return false;
      }
    }
    return true;
  }
  private getEcPolicyDetails() {
    const id = this.activatedRoute.snapshot.params.id;

    if (id) {

      this.ecPolicyService.getEcPolicyByPolicyNo(id).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
        if (response.length > 0) {
          this.ecPolicy = response[0];
          if (this.ecPolicy.ParentContractorVendor_Code) {
            this.isSubContractor = true;
          } else {
            this.ecPolicy.ParentContractorVendor_Code = this.ecPolicy.ContractorVendor_Code;
            this.ecPolicy.ContractorVendor_Code = null;
          }
          this.getAllData();
          this.setControlValue(true);
        }
      });
    } else {
      this.setDefaultECPolicyObj();
      this.setControlValue(false);
      this.getAllData();
    }
  }

  private setDefaultECPolicyObj() {
    this.ecPolicy = {
      ParentContractorVendor_Code: null,
      ContractorVendor_Code: null,
      ParentWorkOrderCode: null,
      ChildWorkOrderCode: null,
      Plants: null,
      NatureOfWorkList: null,
      SatusMasterList_Code: null,
      DocumentImageMasterViews: []
    };
  }

  private setControlValue(isEdit: boolean): void {
    this.ecPolicyForm.controls['ParentContractorVendor_Code'].setValue(this.ecPolicy.ParentContractorVendor_Code);
    this.ecPolicyForm.controls['ParentWorkOrderCode'].setValue(this.ecPolicy.ParentWorkOrderCode);
    if (this.ecPolicyForm.controls['ContractorVendor_Code']) {
      this.ecPolicyForm.controls['ContractorVendor_Code'].setValue(this.ecPolicy.ContractorVendor_Code);
    }
    if (this.ecPolicyForm.controls['ChildWorkOrderCode']) {
      this.ecPolicyForm.controls['ChildWorkOrderCode'].setValue(this.ecPolicy.ChildWorkOrderCode);
    }
    this.ecPolicyForm.controls['Person'].setValue(this.ecPolicy.Person);
    this.ecPolicyForm.controls['Plants'].setValue(this.ecPolicy.Plants);
    this.ecPolicyForm.controls['NatureOfWorkList'].setValue(this.ecPolicy.NatureOfWorkList);
    this.ecPolicyForm.controls['StartDate'].setValue(this.ecPolicy.StartDate);
    this.ecPolicyForm.controls['EndDate'].setValue(this.ecPolicy.EndDate);
    this.ecPolicyForm.controls['Comment'].setValue(this.ecPolicy.Comment);
    this.ecPolicyForm.controls['SatusMasterList_Code'].setValue(this.ecPolicy.SatusMasterList_Code);
    this.ecPolicyForm.controls['IsAllCountryCovered'].setValue(this.ecPolicy.IsAllCountryCovered);
    this.ecPolicyForm.controls['VerifyDate'].setValue(this.ecPolicy.VerifyDate);
    this.ecPolicyForm.controls['ECPolicyNo'].setValue(this.ecPolicy.ECPolicyNo);
    this.headerTitleName = this.ecPolicy.ECPolicyNo;
    this.uploadedFiles = this.ecPolicy.DocumentImageMasterViews;
    if (isEdit) {
      this.isValidStartDate = true;
      this.minEndDate = new Date((new Date(this.ecPolicy.StartDate)).getTime() + (60 * 60 * 24 * 1000));
    }

    this.uploadedFiles = [];
    if (this.ecPolicy.DocumentImageMasterViews) {
      this.ecPolicy.DocumentImageMasterViews.forEach((file) => {
        this.uploadedFiles.push({
          ID: file.ID,
          file: {
            lastModified: file.ModifiedDate,
            name: file.UploadFileName
          },
          documentTypeCode: file.DocumentTypeMasterList_Code,
          isUploaded: true,
          url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder
        });
      });
    }
  }

  private checkECPolicyNoAvailability(ecPolicyNo: string, ecPolicyCode?: number): void {

  }

  private getAllData() {
    this.getStatus();

    if (this.roleCode === 119) {
      this.getContractorsSelectedByDefault(this.userId, this.roleCode);
    } else {
      this.getContractors();
    }

    this.ecPolicyService.getNatureOfWorks().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.natureOfWorkList = response;
      if(this.ecPolicy.NatureOfWorkList){
        this.ecPolicyForm.controls['NatureOfWorkList'].setValue(this.ecPolicy.NatureOfWorkList);
      }
      // else {
      //   if (this.natureOfWorkList.length === 1)
      //     this.ecPolicyForm.controls['NatureOfWorkList'].patchValue([this.natureOfWorkList[0].NatureOfWork_Code]);
      // }
    });

    this.getPlants();
  }


  getContractors() {
    this.ecPolicyService.getContractors().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorList = response;

      if(this.ecPolicy.ParentContractorVendor_Code){
        this.ecPolicyForm.controls['ParentContractorVendor_Code'].setValue(this.ecPolicy.ParentContractorVendor_Code);
        this.getContractorWorkOrder(this.ecPolicy.ParentContractorVendor_Code);
      }
      else{
        if (this.contractorList.length === 1) {
          this.ecPolicyForm.controls['ParentContractorVendor_Code'].patchValue(this.contractorList[0].ContractorVendor_Code);
          this.getContractorWorkOrder(this.contractorList[0].ContractorVendor_Code);
        }
      }
    });
  }

  commonContractorWorkOrderBindValue() {
    this.ecPolicy.ParentContractorVendor_Code = this.contractorList[0].ContractorVendor_Code;
    this.ecPolicyForm.controls['ParentContractorVendor_Code'].patchValue(this.contractorList[0].ContractorVendor_Code);
    this.getContractorWorkOrder(this.ecPolicy.ParentContractorVendor_Code);
  }

  getContractorsSelectedByDefault(userId: any, roleCode: any) {
    this.ecPolicyService.getContractorsbydefault(userId, roleCode).subscribe((response: any[]) => {
      this.contractorList = response;
      this.ecPolicyForm.controls['ParentContractorVendor_Code'].setValue(this.ecPolicy.ParentContractorVendor_Code);
      if (this.ecPolicy.ParentWorkOrderCode && this.isSubContractor) {
        this.getContractorWorkOrder(this.ecPolicy.ParentContractorVendor_Code);
      } else if (this.contractorList.length === 1) {
        this.commonContractorWorkOrderBindValue();
      }
    });
  }

  private getStatus(): void {
    this.ecPolicyService.getMasterItemListByCode(MasterItemCode.STATUS_CODE).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any[]) => {
        this.statuses = response[0].Values;
        this.ecPolicyForm.controls['SatusMasterList_Code'].setValue(this.ecPolicy.SatusMasterList_Code);
      });
  }

  private getSubContractorsByExcludeCode(contractorCode, WorkorderCode): void {
    this.ecPolicyService.getContractors(true, contractorCode, WorkorderCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.subContractorList = response;


      if (this.ecPolicy.ContractorVendor_Code) {
        this.ecPolicyForm.controls['ContractorVendor_Code'].setValue(this.ecPolicy.ContractorVendor_Code);
        if (this.isSubContractor) {
          this.getSubContractorWorkOrder(this.ecPolicy.ParentContractorVendor_Code, this.ecPolicy.ContractorVendor_Code, this.ecPolicy.ParentWorkOrderCode);
        }
      }
      else {
        if (this.subContractorList.length === 1) {
          this.ecPolicyForm.controls['ContractorVendor_Code'].patchValue(this.subContractorList[0].ContractorVendor_Code);
          const contractorCode = this.ecPolicyForm.controls['ParentContractorVendor_Code'].value;
          const subContractorCode = this.subContractorList[0].ContractorVendor_Code;
          const workorderCode = this.ecPolicyForm.controls['ParentWorkOrderCode'].value;
          this.getSubContractorWorkOrder(contractorCode, subContractorCode, workorderCode);
        }
      }

    });
  }

  private getPlants(companyCode: any = ''): void {
    this.ecPolicyService.getPlants(companyCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.plantList = response;
      if (this.ecPolicy.PlantCodes) {
        this.ecPolicyForm.controls['Plants'].setValue(this.ecPolicy.PlantCodes);
      }
      else {
        if (this.plantList.length === 1) {
          this.ecPolicyForm.controls['Plants'].patchValue([this.plantList[0].Plant_Code]);
        }
      }

    });
  }

  getContractorWorkOrder(contractorCode: number): void {
    // .pipe(takeUntil(this.onDestroy))
    this.ecPolicyService.getWorkOrderFilterData(contractorCode).subscribe((response: any[]) => {
      this.contractorWorkOrderList = response;
      if (this.ecPolicy.ParentWorkOrderCode) {
        this.ecPolicyForm.controls['ParentWorkOrderCode'].setValue(this.ecPolicy.ParentWorkOrderCode);
        if (this.isSubContractor) {
          this.getSubContractorsByExcludeCode(contractorCode, this.ecPolicy.ParentWorkOrderCode);
        }
      }
      else {
        if (this.contractorWorkOrderList.length === 1){
          this.ecPolicyForm.controls['ParentWorkOrderCode'].patchValue(this.contractorWorkOrderList[0].WorkOrder_Code);
          if(this.isSubContractor){
            const contractorCode = this.ecPolicyForm.controls['ParentContractorVendor_Code'].value;
          this.getSubContractorsByExcludeCode(contractorCode, this.contractorWorkOrderList[0].WorkOrder_Code);
          }
        }
      }

    });
  }

  getSubContractorWorkOrder(contractorCode, subContractorCode, workorderCode): void {
    this.ecPolicyService.getWorkOrderFilterDataForSubWorkorder(contractorCode, subContractorCode, workorderCode).subscribe((response: any[]) => {
      this.subContractorWorkOrderList = response;

      if (this.ecPolicy.ChildWorkOrderCode) {
        this.ecPolicyForm.controls['ChildWorkOrderCode'].setValue(this.ecPolicy.ChildWorkOrderCode);
      }
      else {
        if (this.subContractorWorkOrderList.length === 1)
          this.ecPolicyForm.controls['ChildWorkOrderCode'].patchValue(this.subContractorWorkOrderList[0].WorkOrder_Code);
      }
    });
  }

  private bindCheckList() {
    this.checkList.push(
      {
        id: 1,
        text: 'EC Policy Details',
        open: true,
        childrens: [
          {
            text: 'Company',
            title: 'Company',
            desc: 'Plant is required.',
            isValid: () => {
              return (this.ecPolicyForm.controls.Plants.valid);
            },
            hasError: () => {
              return ((this.ecPolicyForm.controls.Plants.dirty
                || this.ecPolicyForm.controls.Plants.touched)
                && this.ecPolicyForm.controls.Plants.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Contractor',
            title: 'Contractor',
            desc: 'Contractor and Work Order is required.',
            isValid: () => {
              return (this.ecPolicyForm.controls.ParentContractorVendor_Code.valid
                && this.ecPolicyForm.controls.ParentWorkOrderCode.valid);
            },
            hasError: () => {
              return (((this.ecPolicyForm.controls.ParentContractorVendor_Code.dirty
                || this.ecPolicyForm.controls.ParentContractorVendor_Code.touched)
                && this.ecPolicyForm.controls.ParentContractorVendor_Code.invalid)
                || ((this.ecPolicyForm.controls.ParentWorkOrderCode.dirty
                  || this.ecPolicyForm.controls.ParentWorkOrderCode.touched)
                  && this.ecPolicyForm.controls.ParentWorkOrderCode.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'EC Policy',
            title: 'EC Policy',
            desc: 'EC Policy No, Start Date and End Date is required.',
            isValid: () => {
              return (this.ecPolicyForm.controls.ECPolicyNo.valid
                && this.ecPolicyForm.controls.StartDate.valid
                && this.ecPolicyForm.controls.EndDate.valid);
            },
            hasError: () => {
              return (((this.ecPolicyForm.controls.ECPolicyNo.dirty || this.ecPolicyForm.controls.ECPolicyNo.touched)
                && this.ecPolicyForm.controls.ECPolicyNo.invalid)
                || ((this.ecPolicyForm.controls.StartDate.dirty || this.ecPolicyForm.controls.StartDate.touched)
                  && this.ecPolicyForm.controls.StartDate.invalid)
                || ((this.ecPolicyForm.controls.EndDate.dirty || this.ecPolicyForm.controls.EndDate.touched)
                  && this.ecPolicyForm.controls.EndDate.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Insure Person',
            title: 'Insure Person',
            desc: 'Insure Person is required.',
            isValid: () => {
              return (this.ecPolicyForm.controls.Person.valid);
            },
            hasError: () => {
              return ((this.ecPolicyForm.controls.Person.dirty
                || this.ecPolicyForm.controls.Person.touched)
                && this.ecPolicyForm.controls.Person.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          }
        ]
      },
      {
        id: 2,
        text: 'Documents',
        open: true,
        childrens: [
          {
            text: 'EC Policy Documents',
            title: 'EC Policy Documents',
            desc: 'EC Policy Document is required.',
            isValid: () => {
              return (this.uploadedFiles.length > 0);
            },
            hasError: () => {
              return (this.uploadedFiles.length === 0);
            },
            isMandatory: true,
            isVisible: true,
            step: '2',
          }
        ]
      }
    );
    if (this.isSubContractor) {
      const subContractorhekListObj = {
        text: 'Sub Contractor',
        title: 'Sub Contractor',
        desc: 'Sub Contractor and Sub-Work Order is required.',
        isValid: () => {
          return (this.ecPolicyForm.controls.ContractorVendor_Code.valid
            && this.ecPolicyForm.controls.ChildWorkOrderCode.valid);
        },
        hasError: () => {
          return (((this.ecPolicyForm.controls.ContractorVendor_Code.dirty
            || this.ecPolicyForm.controls.ContractorVendor_Code.touched)
            && this.ecPolicyForm.controls.ContractorVendor_Code.invalid)
            || ((this.ecPolicyForm.controls.ChildWorkOrderCode.dirty
              || this.ecPolicyForm.controls.ChildWorkOrderCode.touched)
              && this.ecPolicyForm.controls.ChildWorkOrderCode.invalid));
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(2, 0, subContractorhekListObj);
    }
  }

  // config for select filter component
  private createConfigForContractor(): void {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor', field: 'ShortCode',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Company', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.contractorConfig = {
      contractor: { contractorColumnDefs, labelText: 'Contractor', placeholderText: 'Select Contractor' }
    };
  }

  // config for select filter component
  private createConfigForSubContractor(): void {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor', field: 'ShortCode',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Company', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.subContractorConfig = {
      contractor: { contractorColumnDefs, labelText: 'Sub-Contractor', placeholderText: 'Select Sub-Contractor' }
    };
  }

  private createConfigForWorkOrder(): void {
    const workOrderColumnDefs = [
      {
        headerName: 'Work Order Number', field: 'WorkOrderNO',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Allow Person', field: 'Owner', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Available Associates', field: 'RemainingPerson', width: 90, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'EIC User', field: 'EICUser', width: 100, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Issue Date', field: 'IssueDate', width: 50, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Validity', field: 'ValidityDate', width: 50, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Status', field: 'Status', width: 70, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.workOrderConfig = {
      workOrder: { workOrderColumnDefs, labelText: 'Select WO/PO', placeholderText: 'Select WO/PO' }
    };
  }

  private createConfigForSubContractorWO(): void {
    const workOrderColumnDefs = [
      {
        headerName: 'Work Order Number', field: 'WorkOrderNo',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Allow Person', field: 'Person', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Owner', field: 'Owner', width: 70, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.subContractorWOConfig = {
      workOrder: { workOrderColumnDefs, labelText: 'Sub-Work Order', placeholderText: 'Select Sub-Work Order' }
    };
  }

  private getDocumentTypes() {
    const res = {
      "Page_Code": 193,
      "RequireType": 1,
      "ActionType": 0,
      "SelectedField": "Name,DocumentType_Code,page_code",
      "Search": null,
      "UserId": GLOBAL.USER_ID
    }

    this.ecPolicyService.getDocumentTypesPageModuleWise(res).pipe(takeUntil(this.onDestroy)).subscribe((docTypes: []) => {
      this.documentTypes = docTypes;
    });
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  private resetFormAfterSave() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      setTimeout(() => {
        if (!this.isSubContractor) {
          this.router.navigate(['/contractor/add-ecpolicy/'], { queryParams: { type: '1' } });
        } else {
          this.router.navigate(['/contractor/add-ecpolicy/'], { queryParams: { type: '0' } });
        }
      }, 500);
    }

    this.ecPolicyForm.reset();
    this.setDefaultECPolicyObj();

    this.setControlValue(false);

  }

  private onScroll = (event: any) => {
    if (this.statusSelectBox && this.statusSelectBox.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.statusSelectBox.close();
    }
  }
}
