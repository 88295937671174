import { TemplateRef } from '@angular/core';

/**
 * `@name TemplateType
 * create for directive getTemplate()
 */
// tslint:disable-next-line:no-any
export class TemplateType<T = any> {
  public templateRef: TemplateRef<T>;
  public templateInput: T;
}
