import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ComponentFactory } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FilterTypeEnum } from '../entryforday.model';

@Injectable()
export class EntryForThePrsenter {

  public reportData$: Observable<FormGroup>;
  private reportData: Subject<FormGroup>;
  public startDate$: Observable<string>;
  private startDate: Subject<string>;
  public contractor$: Observable<number>;
  private contractor: Subject<number>;
  public workOrder$: Observable<any>;
  private workOrder: Subject<any>;
  constructor(private fb: FormBuilder) {
    this.reportData = new Subject();
    this.reportData$ = this.reportData.asObservable();

    this.startDate = new Subject();
    this.startDate$ = this.startDate.asObservable();

    this.contractor = new Subject();
    this.contractor$ = this.contractor.asObservable();

    this.workOrder = new Subject();
    this.workOrder$ = this.workOrder.asObservable();
  }

  public buildForm(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        Date: [null],
        groupBy: [null],
        punchingWiseFilter: [null],
        labourCode: [null],
        dataOrderBy: [null],
        CurrentStatus: '',
        Company_Code: [null],
        Plant_Code: [null],
        Unit_Code: [null],
        ULC: [null],
        GatePassNo: [null],
        Department_Code: [null],
        CategoryWorkmenMIL_Code: [null],
        WorkmenCategoty_Code: [null],
        Section_Code: [null],
        sectionList: [null],
        workmanCategoryList: [null],
        ShiftGroupType_Code: [null],
        Shift_Code: [null],
        StartDate: [null],
        EDate: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        Gender: [null],
        Skill_Code: [null],
        Trade_Code: [null],
        QualificationMasterList_Code: [null],
        TechQualificationMasterList_Code: [null],
        QualificationStreamMasterList_Code: [null],
        Religion_Code: [null],
        Cast_Code: [null],
        IsDomicial: [null],
        FirstAIDTraning: [null],
        FireFighter: [null],
        SAge: [null],
        EAge: [null],
        JobType: [null],
        NatureOfWork_Code: [null],
        WageCriteria: [null],
        ContactortMenType: [null],
        ExpiredMedicalPeriod: [null],
        ExpiredFireTraining: [null],
        PoliceVerifed: [null],
        LabourDemandType: [null],
        dateRange: [null],
        Page: [null],
        contAndSubCont: [null],
        earlyOutAndLateIn: [null],
        formControlName: [null],
        isAttendanceStatus: [null],
        isApprove: [null],
        UserID: [],
        JoiningOrResigning: [null],
        allmasterorspecific: [null],
        MTestOrITORPV: [null],
        ExcludeContractor: [null],
        SubContractorName: [null],
        Labour_Code: [null],
        IncludingSubContractor: [null],
        ShiftTypeGroup_Code: [null],
        SRN: [null],
        ExceptionalAttendance: [null],
        Reason: [null],
        IsCompanyDefault: [null],
        ExceptionalAttendance_Code: [null],
        ifIndivudualCommon: [null],
        ChangeStatus: [null],
        IsContractorShiftLabour: [null],
        State_Code:[null],
        ifStatusChange: [{ value: null, disabled: true }],
        employeeData: this.fb.array([]),
        processType:['individual'],
        criteriaCompany: [null, Validators.required],
        criteriaPlant: [null, Validators.required],
        criteriaAssociate: [null, Validators.required],
        criteriaPayrollMonth: [null, Validators.required],
        criteriaPayrollYear: [null, Validators.required],
        criteriaStartMonth: [null, Validators.required],
        criteriaStartYear: [null, Validators.required],
        criteriaEndMonth: [null, Validators.required],
        criteriaEndYear: [null, Validators.required],
        calculateAnnualBonus: [false],
        payrollContractor: [null, Validators.required],
        payrollWorkOrder: [null, Validators.required],
        payrollSubContractor: [null, Validators.required],
        payrollSubWorkOrder: [null, Validators.required],
      }),
    });
  }

  // public buildFormForMedical(): FormGroup {
  //   return this.fb.group({
  //     FilterName: [null],
  //     JSONText: this.fb.group({
  //       Date: [null],
  //       dataOrderBy: [null],
  //       CurrentStatus: '',
  //       Company_Code: [null],
  //       Plant_Code: [null],
  //       Unit_Code: [null],
  //       Department_Code: [null],
  //       CategoryWorkmenMIL_Code: [null],
  //       WorkmenCategoty_Code: [null],
  //       Section_Code: [null],
  //       sectionList: [null],
  //       workmanCategoryList: [null],
  //       ShiftGroupType_Code: [null],
  //       Shift_Code: [null],
  //       StartDate: [null],
  //       EDate: [null],
  //       Contractors: [null],
  //       WorkOrder_Code: [null],
  //       License_Code: [null],
  //       ESIC: [null],
  //       WCPolicy: [null],
  //       SubContractors: [null],
  //       SubWorkOrder_Code: [null],
  //       id: [null],
  //       SubLicense_Code: [null],
  //       SubESIC: [null],
  //       SubWCPolicy: [null],
  //       WorkSiteCategory: [null],
  //       Gender: [null],
  //       Skill_Code: [null],
  //       Trade_Code: [null],
  //       QualificationMasterList_Code: [null],
  //       TechQualificationMasterList_Code: [null],
  //       QualificationStreamMasterList_Code: [null],
  //       Religion_Code: [null],
  //       Cast_Code: [null],
  //       IsDomicial: [null],
  //       NatureOfWork_Code: [null],
  //       ContactortMenType: [null],
  //       dateRange: [null],
  //       formControlName: [null],
  //       UserID: '',
  //       JoiningOrResigning: [null],
  //     })
  //   });
  // }
  public bindForm(formGroup: FormGroup, data: any) {
    formGroup.patchValue(data);
  }

  public saveContractorFilterData(contractorFormGroup: FormGroup) {
    if (contractorFormGroup.get('startDate').value !== '' || contractorFormGroup.get('contractorCode').value !== null
      || contractorFormGroup.get('workOrderNo').value !== null) {
      const data: any = contractorFormGroup.value;
      data.filterType = FilterTypeEnum.ContractorFilter;
      this.reportData.next(data);
    }
  }

  public onStartDateChange(startDate: string): void {
    if (startDate !== '') {
      this.startDate.next(startDate);
    }
  }

  public onContractorChange(contractorCode: string): void {
    if (contractorCode !== null) {
      this.contractor.next(parseInt(contractorCode, 10));
    } else {
      this.contractor.next(null);
    }
  }

  public onWorkOrderChange(contractorCode: string, workOrderNo: string): void {
    if (contractorCode !== null && workOrderNo !== null) {
      this.workOrder.next({
        contractorCode,
        workOrderNo
      });
    }
  }
}
