import {
    AgFilterManPowerAssignAllocationComponent
} from '../daily-man-power-location-assign/ag-filter-man-power-assign-allocation/ag-filter-man-power-assign-allocation.component';
import {
    AgFilterCellRenderComponent
} from 'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';

export const contractorColumnDefsItem = [
  {
    headerName: '#', field: '',
    width: 350, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
    cellRendererFramework: AgFilterManPowerAssignAllocationComponent
  },
  {
    headerName: 'Vendor Code', field: 'ShortCode',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Contractor', field: 'Company',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Contact Name', field: '',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'PAN', field: 'PANNo',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'PF', field: '',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'ESIC', field: 'PANNo',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Email Id', field: 'EmailID', width: 450,
    filterParams: { applyButton: true, clearButton: true }, sortable: true,
    filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    }
  },
  {
    headerName: 'Registration State', field: '', width: 450,
    filterParams: { applyButton: true, clearButton: true }, sortable: true,
    filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    }
  }
];

export const workOrderColumnDefsitem = [
  {
    headerName: '#', field: '',
    width: 350, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
    cellRendererFramework: AgFilterCellRenderComponent
  },
  {
    headerName: 'Vendor Code', field: 'ShortCode',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Contractor', field: 'Company',
    width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Work Order No', field: 'WorkOrderNo', width: 350, filterParams:
            { applyButton: true, clearButton: true }, filter: true, cellStyle: {
              'align-items': 'center', display: 'flex'
            },
  },
  {
    headerName: 'Person', field: 'Person', width: 250, filterParams:
            { applyButton: true, clearButton: true }, filter: true, cellStyle: {
              'align-items': 'center', display: 'flex'
            },
  },
  {
    headerName: 'Issue Date', field: 'OrderStartDate', width: 400, filterParams: { applyButton: true, clearButton: true },
    suppressMenu: false, sortable: true, filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Validity Date', field: 'OrderEndDate', width: 400, filterParams: { applyButton: true, clearButton: true },
    sortable: false, suppressMenu: false, filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Owner', field: 'Owner', width: 250, filterParams: { applyButton: true, clearButton: true },
    suppressMenu: false, sortable: true, filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'HOD', field: 'HOD', width: 250, filterParams: { applyButton: true, clearButton: true },
    suppressMenu: false, sortable: true, filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
  {
    headerName: 'Status', field: 'Status', width: 250, filterParams: { applyButton: true, clearButton: true },
    suppressMenu: false, sortable: true, filter: true, cellStyle: {
      'align-items': 'center', display: 'flex'
    },
  },
];
