export enum ManpowerRequestStatus {
  APPROVED = 'Approved',
  HOLD = 'On Hold',
  REJECT = 'Rejected',
  PENDING = 'Pending',
  SUBMITED = 'Submitted'

}

export enum ManpowerType {
  BUDGETED = 856,
  NONBUDGETED = 857
}

export enum RoleCode {
  SITEHEAD = 102,
  SITEHRHEAD = 103,
  PLANTHEAD = 104,
  UNITHEAD = 105,
  DEPTHEAD = 106,
  SECTIONHEAD = 107,
  SECURITYHEAD = 112,
  SECURITY = 111,
  HRHEAD = 118,
  CONTRACTOR = 119,
  HRBP = 121,
  ERTEAM = 120
}

export class YearlyBudgetModel {
  site: number;
  plant: any;
  gL: number;
  costCenter: number;
  budgetedManpower = 108000;
  yearType: number;
  year: String = '2019-2020';
  startDate: Date;
  endDate: Date;
}

export const monthList = [
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
  'JAN',
  'FEB',
  'MAR',
];

export const plantList = [
  {
    Id: 1,
    Name: 'plant1',
    UnitList: [
      { Id: 1, Name: 'P1-Unit -1', BudgetAmount: 0 },
      { Id: 2, Name: 'P1-Unit -2', BudgetAmount: 0 },
      { Id: 3, Name: 'P1-Unit -3', BudgetAmount: 0 },
    ]
  },
  {
    Id: 2,
    Name: 'plant2',
    UnitList: [
      { Id: 1, Name: 'P2-Unit -1', BudgetAmount: 0 },
      { Id: 2, Name: 'P2-Unit -2', BudgetAmount: 0 },
      { Id: 3, Name: 'P2-Unit -3', BudgetAmount: 0 },
    ]
  },
  {
    Id: 3,
    Name: 'plant3',
    UnitList: [
      { Id: 1, Name: 'P3-Unit -1', BudgetAmount: 0 },
      { Id: 2, Name: 'P3-Unit -2', BudgetAmount: 0 },
      { Id: 3, Name: 'P3-Unit -3', BudgetAmount: 0 },
    ]
  },
];

export const siteList = [
  { Id: 1, Name: 'site1' },
  { Id: 2, Name: 'site2' },
  { Id: 3, Name: 'site3' },
  { Id: 4, Name: 'site4' },
];
export const glList = [
  { Id: 1, Name: 'GL1' },
  { Id: 2, Name: 'GL2' },
  { Id: 3, Name: 'GL3' },
  { Id: 4, Name: 'GL4' },
];

export const costCenterList = [
  { Id: 1, Name: 'Cost Center - 1' },
  { Id: 2, Name: 'Cost Center - 2' },
  { Id: 3, Name: 'Cost Center - 3' },
  { Id: 4, Name: 'Cost Center - 4' },
];

export const yearTypeList = [
  { Id: 1, Name: 'Financial' },
  { Id: 2, Name: 'Mide-Year' }
];

// export const manPowerTypeList = [
//   { Id: 1, Name: 'non-budgeted' },
//   { Id: 2, Name: 'budgeted' }
// ];



export const yearList = [
  '2019-2020',
  '2018-2019',
  '2017-2018',
];

export const departmentList = [
  {
    Id: 1,
    Name: 'department1'
  },
  {
    Id: 2,
    Name: 'department2',
  },
  {
    Id: 3,
    Name: 'department3'
  }
];
export const sectionList = [
  {
    Id: 1,
    Name: 'Section1',
    shiftList: ['shiftA', 'shiftB', 'shiftC'],
    Date: [],
    shiftA: [],
    shiftB: [],
    shiftC: []
  },
  {
    Id: 2,
    Name: 'Section2',
    shiftList: ['shiftAA', 'shiftBB', 'shiftCC'],
    Date: [],
    shiftA: [],
    shiftB: [],
    shiftC: []
  },
  {
    Id: 3,
    Name: 'Section3',
    shiftList: ['shiftAAA', 'shiftBBB', 'shiftCCC'],
    Date: [],
    shiftA: [],
    shiftB: [],
    shiftC: []
  }

];

export interface SectionList {
  search: string;
  parentSectionCode: string;
  departmentCode: number;
  unitCode: number;
  plantCode: number;
  companyCode: string;
}

export interface ShiftList {
  search: string;
  sDate: string;
  eDate: string;
  plantCode: number;
  shiftCode: number;
  shiftTypeGroupCode: number;
  shiftGroupCode: number;
}

export interface PlantMaster {
  search: string;
  company: string;
  stateCode: string;
  citycode: string;
  countryCode: string;
  unitType: string;
  ownership: string;
  locatedAt: string;
  iSESICApplicable: string;
}

export interface GetRequestionList {
  search: string;
  companyCodes: string;
  stateCodes: string;
  plantCodes: string;
  unitCodes: string;
  departmentCodes: string;
  sectionCodes: string;
  contractorCodes: String;
  sDate: string;
  eDate: string;
  userId: string;
  shiftCode?: string;
  shiftTypeGroupCodes?: string;
  shiftGroupCodes?: string;
}

export class SaveLabourMovement {
  // tslint:disable-next-line: variable-name
  ManpowerRequisiton_Code: string;
  // tslint:disable-next-line: variable-name
  EntryDateTime: string;
  // tslint:disable-next-line: variable-name
  ContractorVendor_Code: string;
  // tslint:disable-next-line: variable-name
  WorkOrder_Code: string;
  // tslint:disable-next-line: variable-name
  NewWorkOrder_Code: string;
  // tslint:disable-next-line: variable-name
  License_Code: string;
  // tslint:disable-next-line: variable-name
  ECPolicy_Code: string;
  // tslint:disable-next-line: variable-name
  GatePassRequest_Code: string;
  // tslint:disable-next-line: variable-name
  ULC: string;
  // tslint:disable-next-line: variable-name
  GatePassNo: string;
  // tslint:disable-next-line: variable-name
  Plant_Code: string;
  // tslint:disable-next-line: variable-name
  Unit_Code: string;
  // tslint:disable-next-line: variable-name
  Department_Code: string;
  // tslint:disable-next-line: variable-name
  section_Code: string;
  // tslint:disable-next-line: variable-name
  InPunchDateTime: string;
  // tslint:disable-next-line: variable-name
  OutPunchDateTime: string;
  // tslint:disable-next-line: variable-name
  IsSubmited: string;
}
// export const PLANT_UNIT_DEP_REQUEST = {


// };
export class AttendanceApprove {
  // tslint:disable-next-line: variable-name
  ManpowerRequisiton_Code: string;
  // tslint:disable-next-line: variable-name
  ManpowerRequisiton_No: string;
  // tslint:disable-next-line: variable-name
  EntryDateTime: string;
  // tslint:disable-next-line: variable-name
  ContractorVendor_Code: string;
  // tslint:disable-next-line: variable-name
  WorkOrder_Code: string;
  // tslint:disable-next-line: variable-name
  NewWorkOrder_Code: string;
  // tslint:disable-next-line: variable-name
  License_Code: string;
  // tslint:disable-next-line: variable-name
  ECPolicy_Code: string;
  // tslint:disable-next-line: variable-name
  GatePassRequest_Code: string;
  // tslint:disable-next-line: variable-name
  ULC: string;
  // tslint:disable-next-line: variable-name
  GatePassNo: string;
  // tslint:disable-next-line: variable-name
  Plant_Code: string;
  // tslint:disable-next-line: variable-name
  Unit_Code: string;
  // tslint:disable-next-line: variable-name
  Department_Code: string;
  // tslint:disable-next-line: variable-name
  section_Code: string;
  // tslint:disable-next-line: variable-name
  InPunchDateTime: string;
  // tslint:disable-next-line: variable-name
  OutPunchDateTime: string;
  // tslint:disable-next-line: variable-name
  IsSubmited: string;
  // tslint:disable-next-line: variable-name
  LabourMovement_Code: string;
  // tslint:disable-next-line: variable-name
  UserId: string;
  // tslint:disable-next-line: variable-name
  RoleCode: string;
  // tslint:disable-next-line: variable-name
  Action: string;
  // tslint:disable-next-line: variable-name
  Comment: string;
  // tslint:disable-next-line: variable-name
  ApprovedOT: string;
  // tslint:disable-next-line: variable-name
  ApprovedWH: string;
  // tslint:disable-next-line: variable-name
  Status: string;  // kh 16-05-2020
  // tslint:disable-next-line: variable-name
  NatureOfWorkCode: string;  // kh 02-07-2020
  // tslint:disable-next-line: variable-name
  SkillCode: string;  // kh 02-07-2020
  // tslint:disable-next-line: variable-name
  ShiftCode: string;  // kh 02-07-2020
  // tslint:disable-next-line: variable-name
  CategoryMIL_Code: String; // hp 19-07-2020
  // tslint:disable-next-line: variable-name
  OldUnit_Code: String; // hp 15-10-2020
}
