import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-app-qr-code',
  templateUrl: './app-qr-code.component.html',
  styleUrls: ['./app-qr-code.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppQrCodeComponent implements OnInit {
  @Input() qrCodeData = '';
  data = '';
  width = 320;
  showQRCode = false;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  setData(data) {
    this.data = data;
  }

  setWidth(width) {
    this.width =  width ? width : 320;
  }

  generate() {
    this.showQRCode = true;
  }

  close() {
    this.activeModal.close();
  }

}
