import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-cell-edit-renderer',
  templateUrl: './ag-action-cell-edit-renderer.component.html',
  styleUrls: ['./ag-action-cell-edit-renderer.component.css']
})
export class AgActionCellEditRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    if (!params.data.parent) {
      this.params = params;
    }
  }

  refresh(): boolean {
    return false;
  }

  onEdit() {
    this.params.context.componentParent.onEdit(this.params.data);
  }

  onDelete() {
    this.params.context.componentParent.onDelete(this.params.data);
  }
}
