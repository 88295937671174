import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HrClearanceRoutingModule } from './hr-clearance-routing.module';
import { HrClearanceComponent } from './hr-clearance.component';
import { HrClearanceService } from './hr-clearance.service';
import { CoreModule } from '../core/core.module';
import { GroupRowComponent } from './group-row/group-row.component';
import { HrcStatusComponent } from './hrc-status/hrc-status.component';
import { WOTaskListComponent } from './wo-task-list/wo-task-list.component';
import { WOTaskListViewComponent } from './wo-task-list/task-list-view/task-list-view.component';
import { WOTaskDetailViewComponent } from './wo-task-list/task-detail-view/task-detail-view.component';
import { WOTaskListHeaderComponent } from './wo-task-list/wo-task-list-header/wo-task-list-header.component';
import { WOTaskListFooterComponent } from './wo-task-list/wo-task-list-footer/wo-task-list-footer.component';
import { WOTaskDetailGridWrapperComponent } from './wo-task-list/wo-task-detail-grid-wrapper/reports-grid-wrapper.component';
import { WOGeneralUndertakingFormComponent } from './wo-task-list/general-undertaking-form/general-undertaking-form.component';
import { WOTaskDocumentUploadComponent } from './wo-task-list/task-document-upload/task-document-upload.component';
import { WOTaskListService } from './wo-task-list/wo-task-list.service';
import { WOLabourComplianceDeclarationFormPrintComponent } from './wo-task-list/labour-compliance-declaration-form-print/labour-compliance-declaration-form-print.component';
import { WOTaskActionModalComponent } from './wo-task-list/wo-task-action-modal/wo-task-action-modal.component';

@NgModule({
  declarations: [
    HrClearanceComponent,
    GroupRowComponent,
    HrcStatusComponent,
    WOTaskListComponent,
    WOTaskListViewComponent,
    WOTaskDetailViewComponent,
    WOTaskListHeaderComponent,
    WOTaskListFooterComponent,
    WOTaskDetailGridWrapperComponent,
    WOGeneralUndertakingFormComponent,
    WOTaskDocumentUploadComponent,
    WOLabourComplianceDeclarationFormPrintComponent,
    WOTaskActionModalComponent
  ],
  entryComponents: [
    GroupRowComponent,
    HrcStatusComponent,
    WOTaskListComponent,
    WOTaskDetailViewComponent,
    WOGeneralUndertakingFormComponent,
    WOTaskDocumentUploadComponent,
    WOTaskActionModalComponent
  ],
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    HrClearanceRoutingModule
  ],
  providers: [
    HrClearanceService,
    WOTaskListService
  ]
})

export class HrClearanceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: HrClearanceModule,
      providers: [
        HrClearanceService,
      ]
    };
  }
}
