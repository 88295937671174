import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { ModulewisePageComponent } from './modulewise-page/modulewise-page.component';
import { UserManagementService } from './user-management.service';
import { CoreModule } from '../core/core.module';
import { CheckboxCellComponent } from './checkbox-cell/checkbox-cell.component';
import { PagewiseActionComponent } from './pagewise-action/pagewise-action.component';
import { ModuleRoleManagementComponent } from './module-role-management/module-role-management.component';
import { CreateUserInfoComponent } from './create-user-info/create-user-info.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { UserListComponent } from './user-list/user-list.component';
import { UserPagewiseActionComponent } from './user-pagewise-action/user-pagewise-action.component';
import { UserDepartmentSectionComponent } from './user-department-section/user-department-section.component';
import { AccessCheckboxCellComponent } from './access-checkbox-cell/checkbox-cell.component';

@NgModule({
  declarations: [
    ModulewisePageComponent,
    CheckboxCellComponent,
    AccessCheckboxCellComponent,
    PagewiseActionComponent,
    ModuleRoleManagementComponent,
    CreateUserInfoComponent,
    UserPagewiseActionComponent,
    UserDepartmentSectionComponent,
    UserListComponent
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    CoreModule.forRoot(),
    PasswordStrengthMeterModule
  ],
  providers: [
    UserManagementService
  ],
  entryComponents: [
    CheckboxCellComponent,
    AccessCheckboxCellComponent,
  ]
})
export class UserManagementModule { }
