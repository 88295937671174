import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaySlipComponent } from './pay-slip.component';
import { PaySlipRoutingModule } from './pay-slip-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { PaySlipPrintComponent } from './pay-slip-print/pay-slip-print.component';

@NgModule({
  declarations: [PaySlipComponent, PaySlipPrintComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgGridModule.withComponents([]),
    NgbModule,
    PaySlipRoutingModule
  ],
  entryComponents: [
    PaySlipPrintComponent,
  ],
})
export class PaySlipModule {}