import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';
import { convertToHourAndMinutes, findTotalMinutes } from 'src/app/common/utils';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { ManPowerService } from '../../man-power.service';

@Component({
  selector: 'app-approval-list-modal',
  templateUrl: './approval-list-modal.component.html',
  styleUrls: ['./approval-list-modal.component.css'],
  host: {
    class: 'h-100 overflow-hidden d-flex flex-column'
  }
})
export class OTApprovalListModalComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public reportDate: string;
  public totalData: number;
  private selectedReportInfo: any;
  public totalShifColspan = 0;
  public dates: any[] = [];
  public shortCodeList: any[] = [];

  myDate: any;
  @Input()
  // tslint:disable-next-line: indent
  public set selectedReportData(value: any) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): any {
    return this.selectedReportInfo;
  }

  @Input()
  public set reportData(value: any) {
    if (value) {
      this.reportValueData = value;
      //  const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      //  this.rowData = tempRowData;
    }
  }
  public get reportData(): any {
    return this.reportValueData;
  }

  public requestLevels: any[] = [];
  public closeModal: EventEmitter<any>;
  public showAlert: EventEmitter<any>;
  public selectedItems: EventEmitter<any[]>;
  // columnDefs: any[] = [];
  // rowData = [];
  // groupingMeta = ['ContractorName'];
  private reportValueData: any[];
  public groupedData: any;
  public finalReportData: any[] = [];
  public isCheckAll: boolean;

  constructor(private manPowerService: ManPowerService, private loggerService: LogHelperService) {
    this.closeModal = new EventEmitter();
    this.showAlert = new EventEmitter();
    this.selectedItems = new EventEmitter();
  }

  ngOnInit() {
    // this.reportDate = this.dateConvertor(this.selectedReportData);
    this.totalData = this.reportData;
    // this.finalReportData = this.getGroupRowTableData(this.reportData);
    console.log('reportData', this.reportData);
    console.log('shortCodeList', this.shortCodeList);
    this.finalReportData = this.getGroupRowTableData(this.reportData);
    console.log('finalReportData', this.finalReportData);

  }

  public checkAllForDate(dateObj: any) {
    dateObj.isCheck = !dateObj.isCheck;
    if (dateObj.shifts) {
      dateObj.shifts = dateObj.shifts.map(item => {
        item.isCheck = !dateObj.isCheck;
        return this.checkShift(item);
      });
    }
  }

  public checkShift(shiftObj: any) {
    shiftObj.isCheck = !shiftObj.isCheck;
    if (shiftObj.contractors) {
      shiftObj.contractors = shiftObj.contractors.map(item => {
        if (item.isDisabled) {
          return { ...item, isCheck: false }
        }
        return { ...item, isCheck: shiftObj.isCheck }
      });
    }
    return shiftObj;
  }

  public checkContractor(event: any, contractorObj: any) {
    if (!contractorObj.isCheck && !contractorObj.SectionPlant_ID) {
      this.loggerService.logError({
        message: 'Please select short code.'
      });
      contractorObj.isCheck = false;
      event.preventDefault();
      return;
    }

    contractorObj.isCheck = !contractorObj.isCheck;
  }

  public onSubmit(): void {
    let finalDataList: any[] = [];
    this.finalReportData.forEach(dateObj => {
      if (dateObj.isCheck) {
        dateObj.shifts.forEach(shift => {
          finalDataList = finalDataList.concat(shift.contractors);
        });
      } else {
        dateObj.shifts.forEach(shift => {
          if (shift.isCheck) {
            finalDataList = finalDataList.concat(shift.contractors);
          } else {
            finalDataList = finalDataList.concat(shift.contractors.filter(item => item.isCheck));
          }
        });
      }
    });

    if (!finalDataList.length) {
      this.showAlert.emit('Please select at least on item.');
      return;
    }
    if (finalDataList.filter(item => !item.SectionPlant_ID).length > 0) {
      this.showAlert.emit('Please select short code for selected items.');
      return;
    }

    this.selectedItems.emit(finalDataList);
  }

  public onClose(): void {
    this.closeModal.emit();
  }

  public onShortCodeChanges(value: any, contractor: any) {
    contractor.RequestType_Code = null;
    const shiftCodeObj = this.shortCodeList.find(item => item.LevelID2 === value.LevelID2);
    if (shiftCodeObj) {
      contractor.Plant = shiftCodeObj.Plant0;
      contractor.Plant_Code = shiftCodeObj.Plantcode0;
      contractor.Unit = shiftCodeObj.Unit0;
      contractor.Unit_Code = shiftCodeObj.Unitcode0;
      contractor.Department = shiftCodeObj.Department0;
      contractor.Department_Code = shiftCodeObj.Departmentcode0;
      contractor.Section = `${shiftCodeObj.SectionName0} | ${shiftCodeObj.SectionName1} | ${shiftCodeObj.SectionName2}`;
      contractor.SectionPlant_ID = shiftCodeObj.LevelID2;

      const requestLevel = this.requestLevels.find(item => item.Plant_Code === contractor.Plant_Code);
      if (requestLevel) {
        contractor.RequestLevel_Code = requestLevel.RequestLevel_Code;
      }
    }
  }

  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    // tslint:disable-next-line: max-line-length
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} to ${date1.getDate()}-${months[date1.getMonth()]}-${date1.getFullYear()}`;
  }

  private getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    const dateWiseData = new Set(reportData.map(item => item.Date));
    dateWiseData.forEach((dateWiseItem: string) => {
      let totalDateObjMinutes = 0;

      const items = reportData.filter(item => item.Date === dateWiseItem);
      const dateObj = {
        date: dateWiseItem,
        shifts: [],
        totalEWS: '0',
        totalWHR: 0,
      };
      const shiftWiseData = new Set(items.map(item => item.Shift));
      shiftWiseData.forEach(shiftItem => {
        let totalShiftMinutes = 0;

        const shiftItems = items.filter(item => item.Shift === shiftItem);
        shiftItems.forEach(shiftItemObj => {
          const requestLevel = this.requestLevels.find(item => item.Plant_Code === shiftItemObj.Plant_Code);
          if (requestLevel) {
            shiftItemObj.RequestLevel_Code = requestLevel.RequestLevel_Code;
          }
          totalShiftMinutes += findTotalMinutes(shiftItemObj.OT);
        });

        const shiftObj = {
          shift: shiftItem,
          contractors: shiftItems,
          // total: shiftItems.length > 0 ? shiftItems.map(res => res.OT || 0).reduce((a, b) => a + b, 0) : 0,
          totalEWS: convertToHourAndMinutes(totalShiftMinutes),
          totalWHR: shiftItems.length
        };
        dateObj.totalEWS += shiftObj.totalEWS;
        dateObj.totalWHR += shiftObj.totalWHR;

        dateObj.shifts.push(shiftObj);

        totalDateObjMinutes += totalShiftMinutes;
      });

      dateObj.totalEWS = convertToHourAndMinutes(totalDateObjMinutes),
        tableData.push(dateObj);
    });
    return tableData;
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }
}
