import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { AttendanceService } from 'src/app/attendance/attendance.service';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css']
})
export class FilterPopupComponent implements OnInit {
  public tabListFilter = [
    { id: 1, name: 'COMPANY', active: true },
    { id: 2, name: 'GATE PASS', active: false },
    { id: 3, name: 'CONTRACTOR', active: false },
    { id: 4, name: 'SUB-CONTRACTOR', active: false }
  ];

  @Input() public filter;
  isSelectedTabId: number = 1;
  roles = GLOBAL.ROLES;

  savedFilters: any[] = [];
  contractors: any[] = [];
  workOrders: any[] = [];
  ecPoliciesByWo: any[] = [];
  subContractors: any[] = [];
  plants: any[] = [];
  departments: any[] = [];
  workmanCategory: any[] = [];
  skills: any[] = [];
  months: any[] = [];
  leavetypes: any[] = [];
  statelist: any[] = [];
  licensesByWo: any[] = [];
  subLicenses: any[] = []; 
  subEcPoliciesByWo: any[] = [];
  selectedContractors: any;
  selectedWorkOrder: string = '';
  selectedWorkOrderCode: number | null = null;
  selectedSubContractor: any;
  isActiveFilter: string = '';

  selectedPlant: any; 
  selectedDepartment: any; 
  selectedSkill: any; 
  selectedWorkmanCategory: any;

  divRolecode = true; 


  constructor(
    public activeModal: NgbActiveModal,
    private gatePassRequestsService: GatePassRequestsService,
    public sharedService: SharedService,
    public attendanceService: AttendanceService,
  ) {}

  async ngOnInit() {
    this.getContractors();
    this.getStates();
    this.getLeaveType();
    this.getMonth();
    this.getAllDepartments();
    this.getAllPlants();
    this.getAllSkills();
    this.getAllWorkSiteCategories();
    this.isActiveFilter = this.tabListFilter[0].name;
  }

  public onTabFilter(tab: any): void {
    this.isSelectedTabId = tab.id;
    this.isActiveFilter = tab.name;
    this.tabListFilter.forEach((item) => item.active = item.id === tab.id);
  }

  getContractors() {
    this.attendanceService.getContractors().subscribe((response: any[]) => {
      this.contractors = response || [];
    });
  }

  getWorkOrders(contractorCode: any): void {
    this.workOrders = [];
    this.attendanceService.getWorkOrdersByContractorCode(contractorCode, undefined).subscribe((response: any[]) => {
      this.workOrders = response;
    });
  }

  getStates(): void {
    this.gatePassRequestsService.getAllState().subscribe((response: any) => {
      this.statelist = response;
    });
  }

  getLeaveType(): void {
    this.gatePassRequestsService.getLeaveTypeData().subscribe((response: any) => {
      this.leavetypes = response;
    });
  }

  getMonth(): void {
    this.gatePassRequestsService.getMonth().subscribe((response: any) => {
      this.months = response;
    });
  }

  getAllDepartments() {
    this.gatePassRequestsService.getALLDepartments().subscribe((response: any) => {
      this.departments = response.Data || response || [];
    });
  }

  getAllWorkSiteCategories() {
    this.gatePassRequestsService.getALLWorkSiteCategories().subscribe((response: any[]) => {
      this.workmanCategory = response.filter((list) => list.Parent_Code === 858);
    });
  }

  getAllPlants() {
    this.gatePassRequestsService.getALLPlants().subscribe((response: any) => {
      this.plants = response.Data || response || [];
    });
  }

  getAllSkills() {
    this.gatePassRequestsService.getALLSkills().subscribe((response: any) => {
      this.skills = response.Data || response || [];
    });
  }

  onContractorChange(selectedContractor: any) {
    this.selectedContractors = selectedContractor;

    if (selectedContractor.ContractorVendor_Code) {
      this.getWorkOrders(selectedContractor.ContractorVendor_Code);
    } else {
      this.workOrders = [];
    }
  }

  onWorkOrderChange(selectedWorkOrder: any) {
    this.selectedWorkOrder = selectedWorkOrder.WorkOrderNo;
    this.selectedWorkOrderCode = selectedWorkOrder.WorkOrder_Code;
    this.getWorkOrderBySubContractors();
  }

  getWorkOrderBySubContractors(): void {
    this.attendanceService.getSubContractors(this.selectedContractors.ContractorVendor_Code, this.selectedWorkOrderCode)
      .subscribe((response: any[]) => {
        this.subContractors = response;
      });
  }
  

  onSubmitAdvanceFilter() {
    const filterObject = {
      plants: this.selectedPlant,
      departments: this.selectedDepartment,
      skills: this.selectedSkill,
      workmanCategory: this.selectedWorkmanCategory,
      search: this.filter.search, 
      ulc: this.filter.ulc, 
      statusCode: this.filter.statusCode, 
      roleCodeForApproval: this.filter.roleCodeForApproval,
      roleActionCode: this.filter.roleActionCode,
      labourDemandType: this.filter.labourDemandType,
      gatePassStatus: this.filter.gatePassStatus,
      filterStartDate: this.filter.filterStartDate,
      filterEndDate: this.filter.filterEndDate,
      contractors: this.selectedContractors,
      workOrder: this.selectedWorkOrder,
      subContractors: this.selectedSubContractor,
      subWorkOrder: this.filter.subWorkOrder
    };
  
    this.activeModal.close(filterObject);
    this.resetFilter();
  }

  resetFilter() {
    this.selectedPlant = null;
    this.selectedDepartment = null;
    this.selectedSkill = null;
    this.selectedWorkmanCategory = null;
    this.filter.search = '';
    this.filter.ulc = '';
    this.filter.statusCode = null;
    this.filter.roleCodeForApproval = null;
    this.filter.roleActionCode = null;
    this.filter.labourDemandType = null;
    this.filter.gatePassStatus = false;
    this.filter.filterStartDate = null;
    this.filter.filterEndDate = null;
    this.selectedContractors = null;
    this.selectedWorkOrder = null;
    this.selectedSubContractor = null;
    this.filter.subWorkOrder = null;
  }

  onCloseAction(): void {
    this.resetFilter();
    this.activeModal.close();
  }
}
