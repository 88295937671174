import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//import { ContractorsService } from '../contractors.service';
import { DatePipe } from '@angular/common';
import { AgNumberCountComponent } from 'src/app/core/components';

@Component({
  selector: 'app-edit-global-setting',
  templateUrl: './edit-global-setting.component.html',
  styleUrls: ['./edit-global-setting.component.css']
})
export class EditGlobalSettingComponent implements OnInit {
  date = new DatePipe('en-US');
  @Input() data: any;
  @Input() settingData: any;
  @Output() public handleClose: EventEmitter<void> = new EventEmitter();
  value: any = '';
  code: any='';
  groupCode: any='';

  constructor() { }

  ngOnInit() {
    // this.contractorsService.getContractorByID(this.data.ContractorVendor_Code, JSON.parse(localStorage.getItem('Role'))[0].Role_Code).subscribe((res: any) => {
    //   this.contractors = res.data;
    // });
    this.value = this.settingData.Value;
    this.code = this.settingData.Code;
    this.groupCode = this.settingData.GroupCode;
  }
  public onClose(data?: any): void {
    this.handleClose.emit(data);
  }

  updateData(data) {
    data.control.markAsUntouched();
    if (data.valid) {
      const req = {
      Code: this.settingData.Code,
      GroupCode: this.settingData.GroupCode,
      value: data.value,
      SiteMIL_Code:null,
      Plant_Code:null ,
      Company_Code:null ,
      RequireType:0 , // 0: Code wise Only Value Update  | 1: Site wise Value Update | 2: Plant wise vlaue update
      ActionType:1 , // 0: Insert Record | 1: Update Record
      AppName:null ,
      Module:null ,
      Page:null ,
      SettingName:null,
      ValueType:null ,
      Role:null ,
      ValueInfo:null ,
      UserId: localStorage.getItem('UserID')
      }
      this.onClose(req);      
    }
  
  }
}
