import { Component, OnInit, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { BaseFilterData } from './base-filter.model';

@Component({
  selector: 'app-base-filter',
  templateUrl: './base-filter.component.html',
  styleUrls: ['./base-filter.component.css']
})
export class BaseFilterComponent implements OnInit {

  // public variables

  // it will content the list of filters pass by the developer
  @Input() public filters: BaseFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: BaseFilterData[];

  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;

  // private variables
  private selectedFilterData: BaseFilterData;

  constructor() { }

  ngOnInit() {
  }

  onFilterTypeClick(filterObj: BaseFilterData): void {
    this.selectedFilterData = { ...filterObj };
    if (this.contentRef) {
      this.contentRef.clear();
      this.contentRef.createEmbeddedView(filterObj.templateRef);
    }
  }

  onSaveFilter(): void {
    console.log('save filter data', this.selectedFilterData);

  }

  onSubmit(): void {

  }

}
