import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { WOHRCDetail } from "../wo-task-model";

@Component({
  selector: 'app-labour-compliance-declaration-form-print',
  templateUrl: './labour-compliance-declaration-form-print.component.html',
  styleUrls: ['./labour-compliance-declaration-form-print.component.css']
})
export class WOLabourComplianceDeclarationFormPrintComponent {

  @Input() public hrcTasks: any[] = [];
  @Input() public complianceTasks: any[] = [];
  @Input() public hrcDetails: WOHRCDetail;

  public currentDate: Date = new Date();

  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;

  private complianceStatusObj = {
    1: 'Yes',
    2: 'No',
    3: 'Not Applicable'
  }

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.btnPrint.nativeElement.click();
    });
  }

  private dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
  }

  public getComplianceStatus(value: number): string {
    return this.complianceStatusObj[value];
  }
}
