import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GLOBAL } from '../../app.globals';
import { UserManagementService } from '../user-management.service';
import { CheckboxCellComponent } from '../checkbox-cell/checkbox-cell.component';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import * as _ from 'lodash';

@Component({
  selector: 'app-modulewise-page',
  templateUrl: './modulewise-page.component.html',
  styleUrls: ['./modulewise-page.component.css']
})
export class ModulewisePageComponent implements OnInit {

  gridAPI: GridApi;
  gridOptions: GridOptions;
  dataList = [];
  columnDefs = [];
  rowData: any;
  moduleTable = [];
  pageTable = [];
  orignalData = [];
  pinnedTopRowData = [];

  onCheckChange = (rowIndex, field) => {
  }

  onCheckAllChange = (params, rowIndex, field, event) => {
    const isChecked = event.currentTarget.checked;
    if (confirm(`Are you sure to ${isChecked ? 'select all' : 'un-select all'} all?`)) {
      // Select all manually
      // this.dataList.forEach(item => {
      //   item[params.colDef.field].value = isChecked;
      // });

      // Select all from service side
      const response = {
        selectedPageIds: '110, 112, 114, 116, 142',
        moduleId: 100,
        moduleName: 'Access & Rights '
      };

      // tslint:disable-next-line:prefer-const
      const selectedPageIds = response.selectedPageIds ? response.selectedPageIds.split(',').map(Number) : [];
      this.dataList.forEach((item) => {
        const obj = item[response.moduleName];
        obj.value = selectedPageIds.indexOf(obj.pageId) >= 0 ? true : false;
      });
    } else {
      console.log('on Calcel');
      event.currentTarget.checked = !isChecked;
    }
  }

  constructor(
    private userManagementService: UserManagementService, private loadingService: Ng4LoadingSpinnerService
  ) {
  }

  ngOnInit() {
    this.returndata();
  }

  returndata() {
    this.userManagementService.getModulePageList().subscribe((res: any) => {
      if (res.Status) {
        this.moduleTable = this.copy(res.Data.Table);
        this.pageTable = this.copy(res.Data.Table1);
        this.orignalData = res.Data.Table;
        this.updateAllRows(this.copy(this.moduleTable), this.copy(this.pageTable));
      }
    }, (error) => {
      this.loadingService.hide();
    });
  }

  updateAllRows(moduleData, pageData) {
    const columnDefs = [];
    const rowData = [];

    if (moduleData.length > 0) {
      moduleData.forEach((data) => {
        columnDefs.push({
          headerName: data.moduleName, field: data.moduleName,
          cellRendererFramework: CheckboxCellComponent, headerClass: 'text-center',
          width: 100, colId: data.moduleId,
          cellRendererParams: {
            onCheckChange: this.onCheckChange,
            onCheckAllChange: this.onCheckAllChange
          }
        });
      });
    }

    if (pageData.length > 0) {
      const firstRow = {
        Page: 'Selected Pages',
        isCountCell: true,
      };
      const checkAllRow = {
        Page: 'Select all Pages',
        isCheckAllCell: true,
      };
      moduleData.forEach((modules) => {
        // tslint:disable-next-line:prefer-const
        let selectedPageIds = modules.selectedPageIds ? modules.selectedPageIds.split(',').map(Number) : [];
        firstRow[modules.moduleName] = selectedPageIds.length;
        checkAllRow[modules.moduleName] = {
          moduleId: modules.moduleId,
          value: false
        };
      });
      this.pinnedTopRowData.push(firstRow);
      this.pinnedTopRowData.push(checkAllRow);
      pageData.forEach((data) => {
        const obj = { Page: data.pageName };
        moduleData.forEach((modules) => {
          // tslint:disable-next-line:prefer-const
          let selectedPageIds = modules.selectedPageIds ? modules.selectedPageIds.split(',').map(Number) : [];
          obj[modules.moduleName] = {
            moduleId: modules.moduleId,
            pageId: data.pageId,
            value: selectedPageIds.indexOf(data.pageId) >= 0 ? true : false
          };
        });
        rowData.push(obj);
      });
    }
    setTimeout(() => {
      this.dataList = rowData;
      const dataCol = [
        {
          headerName: 'Page', field: 'Page',
          sortable: false, filter: false, width: 200, pinned: true,
          headerClass: 'text-center'
        }, {
          headerName: 'Module', field: 'Module', headerClass: 'text-center',
          children: []
        }
      ];
      dataCol[1].children = columnDefs;
      this.columnDefs = dataCol;
      this.setGridOption();
    }, 500);
  }

  setGridOption() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowData: this.dataList,
      pinnedTopRowData: this.pinnedTopRowData,
      rowHeight: 32,
      headerHeight: 34,
      defaultColDef: {
        width: 100,
        resizable: true
      }, context: {
        componentParent: this
      }
    };
  }

  onSave() {
    const data = [];
    this.gridOptions.api.forEachNode((rowNode) => {
      if (rowNode.data.Page !== 'Selected Pages') {
        const selectedId = [];
        let selectedpage = '';
        Object.entries(rowNode.data).forEach(
          (valuePair: any) => {
            if (typeof (valuePair[1]) === 'object') {
              if (selectedpage === '') {
                selectedpage = valuePair[1].pageId;
              }
              if (valuePair[1].value) {
                selectedId.push(valuePair[1].moduleId);
              }
            }
          }
        );
        const objPageWise = {
          pageId: selectedpage,
          selectedModuleId: selectedId
        };
        data.push(objPageWise);
      }
    });
    setTimeout(() => {
      this.userManagementService.setModuleWisePageReqParameter(this.orignalData, this.columnDefs, data);
    }, 500);
  }

  countSelectedPages(cell) {
    this.gridOptions.api.forEachNode((rowNode) => {
      if (rowNode.data.Page === 'Selected Pages') {
        if (cell.checked) {
          rowNode.data[cell.Col] += 1;
        } else {
          rowNode.data[cell.Col] -= 1;
        }
      }
    });
    this.gridOptions.api.refreshCells();
  }

  copy(data) {
    return JSON.parse(JSON.stringify(data));
  }
}
