import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
@Injectable({
  providedIn: "root",
})
export class CommonHttpClientService {
  constructor(public http: HttpClient) {}

  private prepareHeader(headers: HttpHeaders | null): object {
    headers = headers || new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/json");
    return {
      headers: headers,
    };
  }

  private prepareHeaderFormData(headers: HttpHeaders | null): object {
    headers = headers || new HttpHeaders();
    return {
      headers: headers,
    };
  }

  get<T>(url: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get<T>(url, expandedHeaders);
  }

  post(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.post(url, body, expandedHeaders);
  }

  postFormData(
    url: string,
    body: any,
    headers?: HttpHeaders | null
  ): Observable<any> {
    const expandedHeaders = this.prepareHeaderFormData(headers);
    return this.http.post(url, body, expandedHeaders);
  }

  put(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.put(url, body, expandedHeaders);
  }

  patch(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.patch(url, body, expandedHeaders);
  }

  delete<T>(url: string, headers?: HttpHeaders | null): Observable<T> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.delete<T>(url, expandedHeaders);
  }
}
