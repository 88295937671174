import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { WizardStep, Checklist } from 'src/app/core/components';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/core/services';
import { WorkOrdersService } from '../work-orders.service';
declare let _;
@Component({
  selector: 'app-create-sub-work-order',
  templateUrl: './create-sub-work-order.component.html',
  styleUrls: ['./create-sub-work-order.component.css']
})
export class CreateSubWorkOrderComponent implements OnInit, AfterViewInit, OnDestroy {
  formChangesSubscription: Subscription;
  @ViewChild('contractorForm') contractorForm: NgForm;
  checkList: Checklist[] = [];
  wizardSteps: WizardStep[] = [];
  contractorTypes: any[] = [];
  entityTypes = [];
  contractorCode: number;
  ecPolicies = [];
  masterItemList = [];
  statusOfIndustries = [];
  addressTypes = [];
  contactPerTypes = [];
  bankNames = [];
  accountTypes = [];
  countries = [];
  states = [];
  districts = [];
  // cities = [];
  // postOfficies = [];
  statesLocal = [];
  districtsLocal = [];
  // citiesLocal = [];
  // postOfficiesLocal = [];

  complienceInfoCode: number;
  contractorContactPersons = [];
  hideAddContactPerson = false;
  disableAddContactPerson = true;
  hideUpdateContactPerson = true;
  editContractorContactPersonIndex: number;

  drivingLicenceTypes = [];
  constructor(
    private workOrdersService: WorkOrdersService,
    private route: ActivatedRoute,
    public sharedService: SharedService
  ) {

  }

  /**
   * @description
   * @author Sonal Prajapati
   * @memberof CreateSubWorkOrderComponent
   */
  async ngOnInit() {

    this.formChangesSubscription = this.contractorForm.form.valueChanges.subscribe((form) => {
      if (form['company']
        && form['plant']
        && form['contractor']
        && form['search']
        && form['natureOfWork']
        && form['shortcode']
        && form['sideArea']
        && form['department']
        && form['orderType']
        && form['hodUSer']
        && form['workOrderNo']
        && form['startDate']
        && form['LIORef']
        && form['endDate']
        && form['complain']
        && form['noOfPerson']
        && form['comment']
        && form['hrc']
        && form['workType']

      ) {
        this.disableAddContactPerson = false;
      } else {
        this.disableAddContactPerson = true;
      }
    });
    const contractorId = this.route.snapshot.paramMap.get('id');
    if (contractorId) {

    } else {

    }

    this.checkList = [
      {
        id: 1,
        text: 'Contractor Vendor',
        open: true,
        childrens: [
          {
            text: 'CompanyName and Code is required',
            title: 'NameandCode',
            desc: 'CompanyName and Code is required.',
            isValid: () => {
              return (this.contractorForm.controls.company.valid && this.contractorForm.controls.shortcode.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.company.dirty || this.contractorForm.controls.company.touched)
                && this.contractorForm.controls.company.invalid)
                || ((this.contractorForm.controls.shortcode.dirty || this.contractorForm.controls.shortcode.touched)
                  && this.contractorForm.controls.shortcode.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Plant required',
            title: 'plant',
            desc: 'Plant required.',
            isValid: () => {
              return (this.contractorForm.controls.plant.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.plant.dirty || this.contractorForm.controls.plant.touched)
                && this.contractorForm.controls.plant.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Contractor required',
            title: 'Contractor',
            desc: 'Contractor required.',
            isValid: () => {
              return (this.contractorForm.controls.contractor.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.contractor.dirty || this.contractorForm.controls.contractor.touched)
                && this.contractorForm.controls.contractor.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Nature of Work required',
            title: 'Nature of work ',
            desc: 'Nature of Work Required.',
            isValid: () => {
              return (this.contractorForm.controls.natureOfWork.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.natureOfWork.dirty || this.contractorForm.controls.natureOfWork.touched)
                && this.contractorForm.controls.natureOfWork.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Work Side Area required',
            title: 'Work Side Area ',
            desc: 'Work Side Area Required.',
            isValid: () => {
              return (this.contractorForm.controls.sideArea.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.sideArea.dirty || this.contractorForm.controls.sideArea.touched)
                && this.contractorForm.controls.sideArea.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Executing Department required',
            title: 'Executing Department ',
            desc: 'Executing Department Required.',
            isValid: () => {
              return (this.contractorForm.controls.department.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.department.dirty || this.contractorForm.controls.department.touched)
                && this.contractorForm.controls.department.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Order Type required',
            title: 'Order Type ',
            desc: 'Order Type Required.',
            isValid: () => {
              return (this.contractorForm.controls.orderType.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.orderType.dirty || this.contractorForm.controls.orderType.touched)
                && this.contractorForm.controls.orderType.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'HOD User required',
            title: 'HOD User ',
            desc: 'HOD User Required.',
            isValid: () => {
              return (this.contractorForm.controls.hodUSer.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.hodUSer.dirty || this.contractorForm.controls.hodUSer.touched)
                && this.contractorForm.controls.hodUSer.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Work Order Type required',
            title: 'Work Order Type ',
            desc: 'Work Order Type Required.',
            isValid: () => {
              return (this.contractorForm.controls.workOrderNo.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.workOrderNo.dirty || this.contractorForm.controls.workOrderNo.touched)
                && this.contractorForm.controls.workOrderNo.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Start Date required',
            title: 'Start Date ',
            desc: 'Start Date Required.',
            isValid: () => {
              return (this.contractorForm.controls.startDate.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.startDate.dirty || this.contractorForm.controls.startDate.touched)
                && this.contractorForm.controls.startDate.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'LIO Reference required',
            title: 'LIO Reference ',
            desc: 'LIO Reference Required.',
            isValid: () => {
              return (this.contractorForm.controls.LIORef.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.LIORef.dirty || this.contractorForm.controls.LIORef.touched)
                && this.contractorForm.controls.LIORef.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'End Date required',
            title: 'End Date ',
            desc: 'End Date Required.',
            isValid: () => {
              return (this.contractorForm.controls.endDate.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.endDate.dirty || this.contractorForm.controls.endDate.touched)
                && this.contractorForm.controls.endDate.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'complain Date required',
            title: 'complain Date ',
            desc: 'complain Date Required.',
            isValid: () => {
              return (this.contractorForm.controls.complain.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.complain.dirty || this.contractorForm.controls.complain.touched)
                && this.contractorForm.controls.complain.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Number Of Person required',
            title: 'Number Of Person ',
            desc: 'Number Of Person Required.',
            isValid: () => {
              return (this.contractorForm.controls.noOfPerson.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.noOfPerson.dirty || this.contractorForm.controls.noOfPerson.touched)
                && this.contractorForm.controls.noOfPerson.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Comment required',
            title: 'Comment ',
            desc: 'Comment Required.',
            isValid: () => {
              return (this.contractorForm.controls.comment.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.comment.dirty || this.contractorForm.controls.comment.touched)
                && this.contractorForm.controls.comment.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'HRC Application required',
            title: 'HRC Application ',
            desc: 'HRC Application Required.',
            isValid: () => {
              return (this.contractorForm.controls.hrc.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.hrc.dirty || this.contractorForm.controls.hrc.touched)
                && this.contractorForm.controls.hrc.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Work Type required',
            title: 'Work Type ',
            desc: 'Work Type Required.',
            isValid: () => {
              return (this.contractorForm.controls.workType.valid);
            },
            hasError: () => {
              return ((this.contractorForm.controls.workType.dirty || this.contractorForm.controls.workType.touched)
                && this.contractorForm.controls.workType.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
        ],
      },
      //   {
      //     id: 2,
      //     text: 'Complience Registration Checklist',
      //     open: true,
      //     childrens: [
      //       {
      //         text: 'PF Detail',
      //         title: 'Address Proof',
      //         desc: 'Provide EPF Code, EPC Regional Office And Date.',
      //         step: '4',
      //         isVisible: true,
      //         isValid: () => { return true; },
      //         hasError: () => {
      //           return false;
      //         },
      //       },
      //       {
      //         text: 'ESI Detail',
      //         title: 'Address Proof',
      //         desc: 'Provide ESIC Code, ESIC Local Office And Date.',
      //         step: '4',
      //         isVisible: true,
      //         isValid: () => { return true; },
      //         hasError: () => {
      //           return false;
      //         },
      //       }
      //     ],
      //   }
    ];

    this.wizardSteps = [
      {
        id: 'contractor',
        name: 'WorkOrder Detail',
        isValid: () => {
          return true;
        },
      },
      {
        id: 'Order Owner User',
        name: 'Contact Person',
        isValid: () => { return true; },
      },
      {
        id: 'documentDetail',
        name: 'Document Detail',
        isValid: () => { return true; }
      },

    ];


    await this.workOrdersService.getContractorCode().subscribe((contractorCode: any) => {
      this.contractorCode = contractorCode;
    });
    await this.workOrdersService.getECPolicies().subscribe((ecPolicies: any) => this.ecPolicies = ecPolicies);
    await this.workOrdersService.getMasterList().subscribe((masterList: any) => {
      this.masterItemList = masterList;
      this.entityTypes = this.masterItemList.filter(list => list.Parent_Code === 100);
      this.statusOfIndustries = this.masterItemList.filter(list => list.Parent_Code === 104);
      this.addressTypes = this.masterItemList.filter(list => list.Parent_Code === 138);
      this.contactPerTypes = this.masterItemList.filter(list => list.Parent_Code === 212);
      this.bankNames = this.masterItemList.filter(list => list.Parent_Code === 145);
      this.accountTypes = this.masterItemList.filter(list => list.Parent_Code === 213);
      this.drivingLicenceTypes = this.masterItemList.filter(list => list.Parent_Code === 194);
      this.contractorTypes = this.masterItemList.filter(list => list.Parent_Code === 215);
    });

    await this.workOrdersService.getCountries().subscribe((countries: []) => {
      this.countries = countries;
    });

    // this.workOrdersService.getComplienceInfoCode().subscribe((complienceInfoCode: number) => {
    //   this.complienceInfoCode = complienceInfoCode;
    // });
    this.getStatesByCountryId();

  }

  // bindData(filteredData): any[] {
  //   const convertedData: any[] = [];
  //   filteredData.forEach((val) => {
  //     convertedData.push({
  //       itemName: val.Name,
  //       id: val.MasterItem_Code
  //     });
  //   });
  //   return convertedData;
  // }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

  }

  getStatesByCountryId() {
    this.workOrdersService.getStatesByCountryId(this.contractorForm.control.value['permentcountrycode'] || 100).subscribe((states: []) => {
      this.states = states;
    });
  }

  getDistrictsByState() {
    this.workOrdersService.getDistrictsByState(this.contractorForm.control.value['permentstatecode']).subscribe((districts: []) => {
      this.districts = districts;
    });
  }

  getCitiesByDistrict() {
    this.workOrdersService.getCitiesByDistrict(this.contractorForm.control.value['permentdistrictcode']).subscribe((cities: []) => {
      // this.cities = cities;
    });
  }

  getPOsByCity() {
    this.workOrdersService.getPOsByCity(this.contractorForm.control.value['permentcitycode']).subscribe((postOfficies: []) => {
    // this.postOfficies = postOfficies;
    });
  }

  getStatesByCountryIdLocal() {
    this.workOrdersService.getStatesByCountryId(this.contractorForm.control.value['localcountrycode']).subscribe((states: []) => {
      this.statesLocal = states;
    });
  }

  getDistrictsByStateLocal() {
    this.workOrdersService.getDistrictsByState(this.contractorForm.control.value['localstatecode']).subscribe((districts: []) => {
      this.districtsLocal = districts;
    });
  }

  getCitiesByDistrictLocal() {
    this.workOrdersService.getCitiesByDistrict(this.contractorForm.control.value['localdistrictcode']).subscribe((cities: []) => {
      // this.citiesLocal = cities;
    });
  }

  getPOsByCityLocal() {
    this.workOrdersService.getPOsByCity(this.contractorForm.control.value['localcitycode']).subscribe((postOfficies: []) => {
      // this.postOfficiesLocal = postOfficies;
    });
  }

  // addContractorContactPerson(index?: number) {
  //   const contractorContactPerson = {
  //     AltEmailID: this.contractorForm.control.value['altPersonemail11'],
  //     AltMobileNo: this.contractorForm.control.value['ALtcontactPermobileno'],
  //     ContractorVendor_Code: this.complienceInfoCode,
  //     Designation: this.contractorForm.control.value['Persondesignation'],
  //     EmailID: this.contractorForm.control.value['personemail'],
  //     FullName: this.contractorForm.control.value['fullname'],
  //     MobileNo: this.contractorForm.control.value['contactPermobileno'],
  //     PersonTypeMasterList_Code: this.contractorForm.control.value['contactPertype'],
  //     PhoneNo: this.contractorForm.control.value['contactPerphoneno']
  //   };

  //   this.contractorForm.controls['altPersonemail11'].reset();
  //   this.contractorForm.controls['ALtcontactPermobileno'].reset();
  //   this.contractorForm.controls['Persondesignation'].reset();
  //   this.contractorForm.controls['personemail'].reset();
  //   this.contractorForm.controls['fullname'].reset();
  //   this.contractorForm.controls['contactPermobileno'].reset();
  //   this.contractorForm.controls['contactPertype'].reset('');
  //   this.contractorForm.controls['contactPerphoneno'].reset();
  //   if (typeof index !== 'undefined') {
  //     this.contractorContactPersons[index] = contractorContactPerson;
  //   } else {
  //     this.contractorContactPersons.push(contractorContactPerson);
  //   }
  // }

  // updateContractorContactPerson() {
  //   this.addContractorContactPerson(this.editContractorContactPersonIndex);
  //   this.hideUpdateContactPerson = true;
  // }

  editContractorContactPerson(person, index) {

    this.editContractorContactPersonIndex = index;
    this.contractorForm.controls['altPersonemail11'].setValue(person.AltEmailID);
    this.contractorForm.controls['ALtcontactPermobileno'].setValue(person.AltMobileNo);
    this.contractorForm.controls['Persondesignation'].setValue(person.Designation);
    this.contractorForm.controls['personemail'].setValue(person.EmailID);
    this.contractorForm.controls['fullname'].setValue(person.FullName);
    this.contractorForm.controls['contactPermobileno'].setValue(person.MobileNo);
    this.contractorForm.controls['contactPertype'].setValue(person.PersonTypeMasterList_Code);
    this.contractorForm.controls['contactPerphoneno'].setValue(person.PhoneNo);
    this.hideUpdateContactPerson = false;
  }

  deleteContractorContactPerson(index: number) {
    this.contractorContactPersons.splice(index, 1);
  }

  onSubmit(formData) {
    const contractortype = [];
    const contractor: any = {
      // ContractorVendor_Code: formData.contractorvendorcode1,
      contractor: formData.contractor,
      // StatusofIndustryMasterList_Code: formData.statusofindustry,
      Company: formData.company,
      Plan: formData.plant,
      ShortCode: formData.shortcode,
      search: formData.search,
      natureOfWork: formData.natureOfWork,
      sideArea: formData.sideArea,
      department: formData.department,
      orderType: formData.orderType,
      hodUSer: formData.hodUSer,
      workOrderNo: formData.workOrderNo,
      startDate: formData.startDate,
      LIORef: formData.LIORef,
      endDate: formData.endDate,
      complain: formData.complain,
      noOfPerson: formData.noOfPerson,
      comment: formData.comment,
      hrc: formData.hrc,
      workType: formData.workType,
      // PermentDistrict_Code: formData.permentdistrictcode,
      // PermentPostoffice_Code: formData.permentpostofficecode,
      // LocalAddress1: formData.localaddress1,
      // LocalAddress2: formData.localaddress2,
      // LocalAddress3: formData.localaddress3,
      // LocalZipCode: formData.localzipcode,
      // LocalCountry_Code: formData.localcountrycode,
      // LocalState_Code: formData.localstatecode,
      // LocalDistrict_Code: formData.localdistrictcode,
      // LocalCity_Code: formData.localcitycode,
      // LocalPostoffice_Code: formData.localpostofficecode,
      // BankNameMasterList_Code: formData.bankname,
      // NameInBank: formData.nameinbank,
      // BranchName: formData.branchname,
      // BankAccountNumber: formData.accountnumber,
      // BranchCode: formData.branchcode,
      // AccountTypeMasterList_Code: formData.accounttype,
      // IFSCCode: formData.ifsccode,
      // MICRCode: formData.micrcode,
      // PANNumber: formData.pannumber,
      // PANName: formData.panname,
      // AadhaarNo: formData.adhaarnumber,
      // AadhaarName: formData.adhaarname,
      // DLCTypeMasterList_Code: formData.driliceType,
      // DrivingLicenseNo: formData.drilicenumber,
      // DrivingLicenseValidityDate: formData.drilicevaliditydate,
      // DrivingLicenseName: formData.drilicensename,
      // DrivingLicenseBatchNo: formData.licbatchnumber,
      // DrivingLicenseRegDate: formData.liceanceRegDate,
      // PassportNo: formData.passportno,
      // PassportName: formData.passportname,
      // PassportValidityDate: formData.passportexpiredate,
      // CreateBy: GLOBAL.USER.LOGGED_IN_USER,
      // RoleCode: GLOBAL.USER.ROLE_CODE,
      // IsContactor: false,
      // ContractorTypeMasterList_Code: [],
      // EntityComplianceInfos: []
    };

    formData.contractortypeOut.forEach((item) => {
      contractor.ContractorTypeMasterList_Code.push(item.id);
    });
    if (formData.centralgood === true) {
      const entityComplianceInfo = {
        ShortCode: 'SCLRA1001',
        ContractorComplianceInfo_Code: formData.contractorinfocode,
        RegistrationNumber: formData.gstno,
        Other1: formData.companyno
      };
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.IndianIncome === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1091';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.Other2 = formData.tanno;
      entityComplianceInfo.Other3 = formData.companypanno;
      entityComplianceInfo.RegistrationDate = formData.registrationdate;
      entityComplianceInfo.DueOn = formData.renewaldate;
      entityComplianceInfo.Other4 = formData.paocode;
      entityComplianceInfo.Other5 = formData.ddocode;
      entityComplianceInfo.Other6 = formData.paono;
      entityComplianceInfo.Other7 = formData.ddono;
      entityComplianceInfo.Other8 = formData.ainno;
      entityComplianceInfo.Other9 = formData.lwfno;
      entityComplianceInfo.Other10 = formData.tds;
      entityComplianceInfo.Other1 = formData.incometax;
      entityComplianceInfo.RegistrationNumber = formData.range;
      entityComplianceInfo.RegistrationOfficeName = formData.employer;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.valueadded === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1092';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.Other1 = formData.tin;
      entityComplianceInfo.RegistrationDate = formData.registrationdate1;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.establishments === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1093';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.RegistrationNumber = formData.establishednymber;
      entityComplianceInfo.Other1 = formData.natureofunit;
      entityComplianceInfo.Other2 = formData.registeryear;
      entityComplianceInfo.Date1 = formData.datebusiness;
      entityComplianceInfo.Other3 = formData.category;
      entityComplianceInfo.Other3 = formData.businesstype;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.centralexcise === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1094';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.RegistrationNumber = formData.registrationnumber;
      entityComplianceInfo.Date1 = formData.registrationdate2;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.contractlabour === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1095';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.RegistrationNumber = formData.registrationno1;
      entityComplianceInfo.Date1 = formData.registrationdate3;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.thecompanies === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1096';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.RegistrationNumber = formData.registrationno2;
      entityComplianceInfo.Date1 = formData.registrationdate5;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.factoriesact === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1097';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.RegistrationNumber = formData.registrationno6;
      entityComplianceInfo.Date1 = formData.registrationdate6;
      entityComplianceInfo.push(entityComplianceInfo);
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.theemployees === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'CCLRA1003';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.ContractorVendor_Code = formData.contractorvendorcode1;
      entityComplianceInfo.RegistrationNumber = formData.epfcode;
      entityComplianceInfo.Other3 = formData.epcregional;
      entityComplianceInfo.DueOn = formData.renewaldate;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.employeesstates === true) {
      formData.ContractorContactState.forEach((val) => {
        const entityComplianceInfo: any = {};
        entityComplianceInfo.ShortCode = 'CCLRA1007';
        entityComplianceInfo.ContractorComplianceInfo_Code = val.ContractorComplianceInfo_Code;
        entityComplianceInfo.State_Code = val.State_Code;
        entityComplianceInfo.RegistrationNumber = val.RegistrationNumber;
        entityComplianceInfo.RegistrationDate = val.registrationdate7;
        entityComplianceInfo.RegistrationOfficeName = val.RegistrationOfficeName;
        entityComplianceInfo.ValidityDate = val.ValidityDate;
        contractor.EntityComplianceInfos.push(entityComplianceInfo);
      });
    }

    if (formData.profession === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1100';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.Other2 = formData.pttax;
      entityComplianceInfo.Date1 = formData.pttaxdate;
      entityComplianceInfo.RegistrationNumber = formData.ptocircleno;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }

    if (formData.finanaceact === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1099';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.Other1 = formData.servicetax;
      entityComplianceInfo.Date1 = formData.servicedate;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }

    if (formData.psara === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1101';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.Other1 = formData.psara;
      entityComplianceInfo.Date1 = formData.psaradate;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.foodlicense === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'SCLRA1102';
      entityComplianceInfo.ContractorComplianceInfo_Code = formData.contractorinfocode;
      entityComplianceInfo.RegistrationNumber = formData.fssai;
      entityComplianceInfo.AppliedDate = formData.fssaidate;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }

    contractor.ContractorContactPersonViews = [];
    contractor.ContractorContactPersonViews = _.clone(formData.ContractorContactPerson);
    // this.workOrdersService.addContractor(contractor).subscribe((result) => {

    // });
  }



  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.formChangesSubscription.unsubscribe();
  }

}
