import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { LeaveRoutingModule } from './leave-routing.module';
import { LeaveModuleComponent } from './leave-module.component';
import { CoreModule } from '../core/core.module';
import { LeaveApplyFromComponent } from './leave-apply-from/leave-apply-from.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';


@NgModule({
  declarations: [
    LeaveModuleComponent,
    LeaveApplyFromComponent
  ],
  imports: [
    CommonModule,
    LeaveRoutingModule,
    CoreModule.forRoot(),
    SweetAlert2Module
  ],
  providers: [DatePipe],
  exports: [
  ]
})

export class LeaveModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LeaveModule,
    };
  }
}
