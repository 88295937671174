import {
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
  HostListener,
} from "@angular/core";
import { LoginService } from "./login/login.service";
import { Subscription, SubscriptionLike as ISubscription } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { GLOBAL } from "./app.globals";
import { NotifyService } from "./common/notify.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SharedService } from "./core/services";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from "@angular/router";
import { Ng4LoadingSpinnerService } from "./core/components";
import { IdleTimeoutService } from "./core/services/idle-timeout.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener("window:unload", ["$event"])
  // unloadHandler(event) {
  //  this.logout();
  // }
  // on close browser
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    if (!localStorage.get("token")) {
      this.logout();
    }
  }
  title = "oceansoftv1";
  opened = false;
  isLoggedIn = true;
  authSubscription: Subscription;
  cookieValue;
  private showTimeOutSub: ISubscription;
  @ViewChild("autoShownModal") autoShownModal: ModalDirective;
  isModalShown = false;
  userPassword: any = "";
  showPasswordRequired: any;
  public showToastr: boolean;
  // = `UserID=653&RoleID=95F0B7C079748D526041FAEDD0BEF91A9EBB291F
  // &RoleCode=112&UserEmail=rajnish.mehta@borl.co.in&UserName=rajnish.mehta`;
  constructor(
    private loginService: LoginService,
    private router: Router,

    private cookieService: CookieService,
    private notifyService: NotifyService,
    private sharedService: SharedService,
    // private userIdle: UserIdleService
    private loadingService: Ng4LoadingSpinnerService,
    private idleTimeoutService: IdleTimeoutService
  ) {
    this.showTimeOutSub = this.notifyService.showSessionExpiry$.subscribe(
      (flag) => {
        if (flag) {
          this.isModalShown = true;
        } else this.isModalShown = false;
      }
    );

    this.isLoggedIn = localStorage.getItem("token") ? true : false;
    if (!this.isLoggedIn) {
      this.router.navigate(["login"]);
      // return;
    }
    // this.cookieService.set('OceanSystem', this.cookieValue);
    this.authSubscription = this.loginService.auth$.subscribe((response) => {
      this.isLoggedIn = localStorage.getItem("token") ? true : false;
      if (!this.isLoggedIn) {
        this.router.navigate(["login"]);
        // return;
      }
      if (response === 1) {
        this.isLoggedIn = true;
        this.router.navigate(["labours"]);
      } else if (response === 2) {
        this.isLoggedIn = true;
        this.router.navigate(["contractors"]);
      } else if (response === 3) {
        this.isLoggedIn = true;
        this.router.navigate(["dashboard"]);
      } else if (response === -1) {
        console.error(
          "The System is not able to connect the database." +
            "Please contact your system administrator or IT department who is maintaining your system"
        );
      } else {
        // errorBox();
      }
      if (response !== -1) {
        GLOBAL.USER = {
          LOGGED_IN_USER: Number(localStorage.getItem("UserID")),
          ROLE_CODE: Number(localStorage.getItem("RoleCode")),
          EMAIL_ID: localStorage.getItem("UserEmail"),
          // LOGGED_IN_USER: Number(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserID')),
          // ROLE_CODE: Number(new URLSearchParams(this.cookieService.get('OceanSystem')).get('RoleCode')),
          // EMAIL_ID: String(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserEmail')),
        };
      }
    });
  }

  ngOnInit() {
    if (this.isLoggedIn) {
      this.sharedService.globalMasterSettingData().subscribe((res: any) => {
        this.sharedService.setGlobalSettings(res);
      });
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.loadingService.show();
      } else if (
        val instanceof NavigationEnd ||
        val instanceof NavigationError ||
        val instanceof NavigationCancel
      ) {
        this.loadingService.hide();
      }
    });

    this.idleTimeoutService.showToastr.subscribe((value) => {
      this.showToastr = value;
    });
  }

  @HostListener("document:mousemove")
  @HostListener("document:keypress")
  onUserActivity(): void {
    this.idleTimeoutService.resetTimer();
  }

  logout() {
    this.sharedService.deleteCookie("token");
    this.sharedService.deleteCookie("OceanSystem");
    localStorage.clear();
    this.router.navigate(["login"]);
    this.loginService.updateOnAuthChange(false);
  }

  showModal(): void {
    this.userPassword = "";
    this.showPasswordRequired = false;
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  cancelExtension() {
    this.autoShownModal.hide();
    this.logout();
  }

  toggleSidebar() {
    this.opened = !this.opened;
  }

  extendTimeOut() {
    this.showPasswordRequired = false;
    if (this.userPassword) {
      // call confirmation
      this.loginService
        .extendTimeOut(this.userPassword)
        .subscribe((res: any) => {
          if (res.Message !== "OK") {
            //   this.autoShownModal.hide();
            // } else {
            this.showPasswordRequired = true;
          }
        });
    } else {
      this.showPasswordRequired = true;
    }
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }
}
