import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
// import { ZXingScannerComponent } from '@zxing/ngx-scanner';
// import { Result } from '@zxing/library';

@Component({
  selector: 'app-read-qr-code',
  templateUrl: './read-qr-code.component.html',
  styleUrls: ['./read-qr-code.component.css']
})
export class ReadQrCodeComponent implements AfterViewInit {

  @ViewChild('scanner')
  // scanner: ZXingScannerComponent;

  ngAfterViewInit(): void {
    // this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
    //   // Autoselect first available camera
    //   const selectedDevice = devices[0];
    //   this.scanner.device = selectedDevice;
    // });
  }

  onCodeResult(resultString: string) {
    // Handle QR code result
    console.log('Scanned QR code result:', resultString);
    alert('Scanned QR code result: ' + resultString);
  }

}
