import { Component } from '@angular/core';
import { ManPowerService } from '../man-power.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-drop-down-cell-editor',
  templateUrl: './drop-down-cell-editor.component.html',
  styleUrls: ['./drop-down-cell-editor.component.css']
})
export class DropDownCellEditorComponent implements ICellRendererAngularComp {

  params;
  // items = [];
  selectedItem;
  constructor(
    private manPowerService: ManPowerService
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  onSearchChange(event) {
  }

  onItemSelect(event) {
    this.params.onItemSelect(this.params, event, this.params.rowIndex);
  }
}
