export class AttendanceApprove {
  // tslint:disable-next-line: variable-name
  Date: Date;
  // tslint:disable-next-line: variable-name
  contractorCode: string;
  // tslint:disable-next-line: variable-name
  UserId: string;
  // tslint:disable-next-line: variable-name
  RoleCode: string;
  // tslint:disable-next-line: variable-name
  Category_Code: string;
  // tslint:disable-next-line: variable-name
  Shift_Code: string;
  // tslint:disable-next-line: variable-name
  ArrayULC: [];
}
