import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManPowerComponent } from './man-power-yearly.component';
import { ManPowerRoutingModule } from './man-power-yearly-routing.module';
import { CoreModule } from '../core/core.module';
import { ManPowerService } from './man-power-yearly.service';

@NgModule({
  imports: [
    CommonModule,
    ManPowerRoutingModule,
    CoreModule.forRoot(),
  ],
  declarations: [ManPowerComponent],
  providers: [ManPowerService]
})
export class ManPowerYearlyModule { }
