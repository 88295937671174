import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-attendance-checkbox-render',
  templateUrl: './ag-action-attendance-checkbox-render.component.html',
  styleUrls: ['./ag-action-attendance-checkbox-render.component.css']
})
export class AgActionAttendanceCheckboxRenderComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  constructor() { }
  show = false;
  hide = false;
  disable = false;

  ngOnInit() {

    // if (this.params.data.IsApproved === 1 || this.params.data.IsApproved === true && this.params.data.checkedStatus === 1) {
    //   this.show = true;
    //   this.hide = false;
    //   this.disable = false;

    // }
    // tslint:disable-next-line:brace-style
    // else if (this.params.data.IsApproved === 0 || this.params.data.IsApproved === false) {
    //   this.show = false;
    //   this.hide = true;
    //   this.disable = true;
    // }


    // else if (this.params.data.IsApproved === 0 || this.params.data.TrainingStatus === 'Not Done'
    //     || this.params.data.TrainingStatus === 'Expired' || this.params.data.TrainingStatus === 'Refresher Training'
    //     || this.params.data.TrainingStatus === 'Expire soon' || this.params.data.TrainingStatus === 'Not attempted') {
    //       this.show = false;
    //       this.hide = true;
    //       this.disable = true;
    //     }
  }

  agInit(params: any): void {
    // if (!params.data.parent) {
    this.params = params;
    // }
  }

  refresh(): boolean {
    return true;
  }

}
