import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject, iif } from 'rxjs';
import { SubContractorFilterPrsenter } from './sub-contractor-filter.presenter';
import { FormGroup } from '@angular/forms';
import { FilterTypeEnum } from '../report-filter-test/report-filter.model';
import { ReportFilterModel, Contractor, WorkOrder } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';

@Component({
  selector: 'app-sub-contractor-filter',
  templateUrl: './sub-contractor-filter.component.html',
  styleUrls: ['./sub-contractor-filter.component.css'],
  viewProviders: [SubContractorFilterPrsenter]
})
export class SubContractorFilterComponent implements OnInit {

  @Output() subContractorChangeEmitter: EventEmitter<number> = new EventEmitter();
  @Output() subWorkOrderChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() subContractorSaveEmitter: EventEmitter<ReportFilterModel> = new EventEmitter();
  @Output() getSubContractorListEmiiter: EventEmitter<any> = new EventEmitter();


  public subContractorFormGroup: FormGroup;
  public saveReportData: Subject<ReportFilterModel>;
  public onContractorCodeChange: Subject<number>;
  public getSubContractorList: Subject<any>;

  public licenses: any[] = [];

  @Input() public set subContractors(value: Contractor[]) {
    if (value) {
      this.contractorList = [...value];
      if (this.reportFilter) {
        this.setFormGroup(this.reportFilter);
      }
    }
  }

  public get subContractors(): Contractor[] {
    return this.contractorList;
  }

  @Input() public set workOrders(value: WorkOrder[]) {
    if (value) {
      this.workOrderList = [...value];
    }
  }

  public get workOrders(): WorkOrder[] {
    return this.workOrderList;
  }

  @Input() public set reportFilterData(value: ReportFilterModel) {
    if (value) {
      this.reportFilter = { ...value };
      // this.setFormGroup(this.reportFilter);
      if (this.reportFilterData.contractorCode && this.reportFilterData.workOrderNo) {
        this.getSubContractorListEmiiter.emit({ contractorCode: value.contractorCode, workOrderNo: value.workOrderNo });
      }
    } else {
      this.subContractorFormGroup.get('subContractorCode').setValue(null);
      this.subContractorFormGroup.get('subContractorWorkOrderNo').setValue(null);
    }
  }

  public get reportFilterData(): ReportFilterModel {
    return this.reportFilter;
  }

  @Input() public set resetForm(value: boolean) {
    if (value) {
    }
  }

  public get resetForm(): boolean {
    return this.formReset;
  }

  private reportFilter: ReportFilterModel;
  private formReset: boolean;
  private contractorList: Contractor[] = [];
  private workOrderList: WorkOrder[] = [];

  constructor(private subContractorFilterPresenter: SubContractorFilterPrsenter) {
    this.saveReportData = new Subject();
    this.onContractorCodeChange = new Subject();
    this.getSubContractorList = new Subject();

    this.subContractorFormGroup = this.subContractorFilterPresenter.buildForm();
  }

  ngOnInit() {
    this.propertyObserver();
  }

  // public onContractorChange(): void {
  //   this.subContractorFilterPresenter.onContractorChange(this.subContractorFormGroup.get('subContractorCode').value);
  // }

  public onContractorChange(): void {
    this.workOrderList = [];
    this.subContractorFormGroup.get('subContractorWorkOrderNo').setValue(null);
    this.subContractorChangeEmitter.emit(this.subContractorFormGroup.get('subContractorCode').value);
    // this.contractorFilterPresenter.onContractorChange(this.contractorFormGroup.get('contractorCode').value);
  }

  public onWorkOrderChange() {
    // this.contractorFilterPresenter.onWorkOrderChange(this.contractorFormGroup.get('contractorCode').value,
    //   this.contractorFormGroup.get('workOrderNo').value);
    this.subWorkOrderChangeEmitter.next({
      contractorCode: this.subContractorFormGroup.get('subContractorCode').value,
      workOrderNo: this.subContractorFormGroup.get('subContractorWorkOrderNo').value
    });
  }

  private propertyObserver(): void {
    this.subContractorFilterPresenter.contractor$.subscribe((value: any) => {
      this.onContractorCodeChange.next(value);
    });
  }

  private setFormGroup(value: any): void {
    this.subContractorFormGroup.patchValue(value);
  }
}
