import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[permissionIf]'
})
export class CustomRoleBasedContentDirective {

  @Input() set permissionIf(allowedRoles: any[]) {
    this.checkAllowedRoles(allowedRoles)
  }
  @Input() set permissionIfNotAllowed(roles: any[]) {
    this.checkForNotAllowedRoles(roles)
  }

  private userRoles: any[];
  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef
  ) { 
    this.userRoles = localStorage.getItem('Role') ? JSON.parse(localStorage.getItem('Role')).map(item=> item.Role_Code) : [];
  }

  private checkAllowedRoles(roles: any[]): void {
    if(!roles) return;
    const show = roles.some(item=> this.userRoles.includes(item));
    show ? this.container.createEmbeddedView(this.templateRef) : this.container.clear();
  }

  private checkForNotAllowedRoles(roles: any[]): void {
    if(!roles) return;

    const show = roles.some(item=> this.userRoles.includes(item));
    !show ? this.container.createEmbeddedView(this.templateRef) : this.container.clear();
  }
}