import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ComponentFactory } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FilterTypeEnum } from '../report-filter-test/report-filter.model';
import { ReportFilterModel } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
@Injectable()
export class SubContractorFilterPrsenter {

  public reportData$: Observable<ReportFilterModel>;
  private reportData: Subject<ReportFilterModel>;

  public contractor$: Observable<number>;
  private contractor: Subject<number>;

  public workOrder$: Observable<number>;
  private workOrder: Subject<any>;

  constructor(private fb: FormBuilder) {
    this.reportData = new Subject();
    this.reportData$ = this.reportData.asObservable();

    this.contractor = new Subject();
    this.contractor$ = this.contractor.asObservable();

    this.workOrder = new Subject();
    this.workOrder$ = this.workOrder.asObservable();
  }

  public buildForm(): FormGroup {
    return this.fb.group({
      subContractorCode: [null],
      subContractorWorkOrderNo: [null],
    });
  }

  public onContractorChange(contractorCode: string): void {
    if (contractorCode !== '') {
      this.contractor.next(parseInt(contractorCode, 10));
    }
  }
}
