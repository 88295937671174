import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-verification-history',
  templateUrl: './verification-history.component.html',
  styleUrls: ['./verification-history.component.css']
})
export class VerificationHistoryComponent implements OnInit {
  private pRowData = [];
  @Input() public set rowData(value: any[]) {
    this.pRowData = value;
  }

  public get rowData(): any[] {
    return this.pRowData;
  }

  historyColumnDefs = [
    {
      headerName: 'GatePass No', field: 'GatePassNo', width: 250,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Name', field: 'NameInIdentity', width: 250,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 250,
      filterParams: { applyButton: true, clearButton: true },
      cellRenderer: (data) => {
        return data.data.ValidUptoDate ? moment(data.data.IssueDate).format('DD/MM/YYYY') : '-'
      }
    },
    {
      headerName: 'Validity Date', field: 'ValidUptoDate', width: 250,
      filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true,
      cellRenderer: (data) => {
        return data.data.ValidUptoDate ? moment(data.data.ValidUptoDate).format('DD/MM/YYYY') : '-'
      }
    },
    {
      headerName: 'Vendor Code', field: 'ContractorVendor_Code', width: 250,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Contactor Name', field: 'Contractor Name', width: 250,
      filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true
    },
    {
      headerName: 'Order Number', field: 'PO/WO Number', width: 250,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'License No', field: 'ContractorLicense', width: 250,
      filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Wage Criteria', field: 'ESICWageCriteria', width: 250,
      suppressMenu: true
    },
    {
      headerName: 'EC Policy', field: 'EC Policy', width: 250,
      suppressMenu: true,
    }
  ];

  gridOptions = {
    columnDefs: this.historyColumnDefs,
    // rowData: $scope.Historydata,
    getRowHeight: (params) => {
      return 39;
    },
    onGridReady: (params) => {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: 10,
    headerHeight: 39
  };
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

}
