import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';
import { share } from 'rxjs/operators';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
const dateFormat = SharedService.dateFormat;
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-ag-date-cell-renderer',
  templateUrl: './ag-date-cell-renderer.component.html',
  styleUrls: ['./ag-date-cell-renderer.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class AgDateCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  onDateChange(newValue) {
    this.params.data[this.params.column.colId] = moment(newValue).format('YYYY-MM-DDTHH:mm:ss');
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }
}
