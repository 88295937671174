import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-approve-status-cell-renderer',
  templateUrl: './ag-approve-status-cell-renderer.component.html',
  styleUrls: ['./ag-approve-status-cell-renderer.component.css']
})
export class AgApproveStatusCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
  }

  refresh() {
    return true;
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }
}
