import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-input-edit-cell-renderer',
  templateUrl: './ag-input-edit-cell-renderer.component.html',
  styleUrls: ['./ag-input-edit-cell-renderer.component.css']
})
export class AgInputEditCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
  }

  refresh() {
    return true;
  }

  onValueChange($event) {
    this.params.data[this.params.column.colId] = $event.target.value;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection($event.target.value);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }
}
