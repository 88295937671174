import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NgxSidebarService {
  sidebarOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  openSidebar() {
    this.sidebarOpen.next(true);
  }

  closeSidebar() {
    this.sidebarOpen.next(false);
  }

}
