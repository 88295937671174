import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular/dist/interfaces";

@Component({
  selector: "total-value-component",
  template: `
    <div class="form-group">
      <input type="number" [value]="cellValue" (chande)="valueChange()" style="width: 150px;" class="form-control" />
    </div>
  `,
})
export class inputValueRenderer implements ICellRendererAngularComp {
  public cellValue!: string;

  agInit(params): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  valueChange() {
    // debugger;
    alert(`${this.cellValue} medals won!`);
  }

  getValueToDisplay(params) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
