import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YearlyBudgetComponent } from './yearly-budget.component';
import { YearlyBudgetRoutingModule } from './yearly-budget-routing.module';
import { CoreModule } from '../core/core.module';
import { YearlyBudgetService } from './yearly-budget.service';
import { YearlyBudgetFormComponent } from './yearly-budget-form/yearly-budget-form.component';
import { YearlyBudgetListComponent } from './yearly-budget-list/yearly-budget-list.component';
import { NgxPrintModule } from 'ngx-print';
import { PivotViewAllModule } from '@syncfusion/ej2-angular-pivotview';

@NgModule({
  imports: [
    CommonModule,
    PivotViewAllModule,
    YearlyBudgetRoutingModule,
    CoreModule.forRoot(),
    NgxPrintModule
  ],
  declarations: [YearlyBudgetComponent, YearlyBudgetFormComponent, YearlyBudgetListComponent],
  providers: [YearlyBudgetService],
  entryComponents: [YearlyBudgetFormComponent]
})
export class YearlyBudgetModule { }
