import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Observable } from "rxjs";
import { GLOBAL } from "src/app/app.globals";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { WOTaskDocumentUploadComponent } from "../task-document-upload/task-document-upload.component";
import { WOTaskListService } from "../wo-task-list.service";
import { WOHRCDetail } from "../wo-task-model";


@Component({
  selector: 'app-general-undertaking-form',
  templateUrl: './general-undertaking-form.component.html',
  styleUrls: ['./general-undertaking-form.component.css'],
  host: {
    style: 'height: 100%; display: flex; flex-direction: column'
  }
})
export class WOGeneralUndertakingFormComponent implements OnInit {

  @Input() public taskList: any[];
  @Input() public complianceTasks: any[];
  @Input() public hrcDetails: WOHRCDetail;

  @Output() public closeModal: EventEmitter<void>;
  @Output() public handleSaveHRCRequest: EventEmitter<any>;
  public optionList: any[];
  public formGroup: FormGroup;
  public canUploadDocument: boolean;
  public iPrintDisabled = true;

  public get taskActions(): FormArray {
    return this.formGroup.get('taskActions') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private wOTaskListService: WOTaskListService,
    private logHelperService: LogHelperService
  ) {
    this.closeModal = new EventEmitter();
    this.handleSaveHRCRequest = new EventEmitter();

    this.optionList = this.getOptionList();
    this.formGroup = this.fb.group({
      taskActions: this.fb.array([])
    })
  }

  public ngOnInit(): void {
    this.setControlValues(this.complianceTasks || []);
    this.iPrintDisabled = !!this.complianceTasks.filter(item => !item.ID).length;
  }

  public onCloseAction(): void {
    this.closeModal.emit();
  }

  public onSave(): void {
    if (!this.formGroup.valid) {
      for (const formGroup of this.taskActions.controls) {
        formGroup.get('Reason').markAsDirty();
        formGroup.get('Reason').markAsTouched();
        formGroup.get('Reason').updateValueAndValidity();
      }
      return;
    }
    this.saveHRCUndertaking(this.taskActions.getRawValue());
  }

  public onIsCompliedChange(form: FormGroup): void {
    const formValue = form.getRawValue();
    if (formValue.IsComplied === 1) {
      form.get('Reason').disable();
      form.get('Reason').setValue(null);
      form.get('Reason').clearValidators();
      form.get('Reason').updateValueAndValidity();

      return;
    }

    form.get('Reason').enable();
    form.get('Reason').setValidators([Validators.required]);
    form.get('Reason').updateValueAndValidity();
  }

  public checkValidation(form: FormGroup, controlName: string): boolean {
    if (form.valid || form.get(controlName).valid) return false;
    if (form.get(controlName).dirty && form.get(controlName).touched) return true;
    return false;
  }


  public onPrint(): void {
    this.canUploadDocument = false;
    setTimeout(() => {
      this.canUploadDocument = true;
    }, 300);
  }

  public onUploadDocument(): void {
    const modalRef = this.modalService.open(WOTaskDocumentUploadComponent, {
      size: 'sm', backdrop: 'static',
      windowClass: 'wo-task-upload-modal overflow-hidden'
    });

    modalRef.componentInstance.closeModal.subscribe((value: string) => modalRef.close(value));
    modalRef.componentInstance.handleHRCRequestAction.subscribe(files => this.saveHRCRequest(files, modalRef));
  }

  public saveHRCRequest(files: File[], modalRef: any): void {
    const [isValid, message] = this.checkValidationOnAction();
    if (!isValid) {
      this.logHelperService.logError({ message });
      return;
    }

    const { PayrollMonth_Code: Month_Code, HRCCode: Reference_Code, DateOfSubmittion, WorkOrderCode, Request_Code, Plant_Code } = this.hrcDetails;
    const requestObj = {
      RequestType_Code: 106,
      LastRequestcode: null,
      Month_Code,
      Request_Code,
      RequestStatus_Code: !DateOfSubmittion ? 637 : null,
      Reference_Code,
      IsReSubmit: null,
      IsSubmitAllow: null,
      RequireType: 0,
      ActionType: 0,
      IsComplied: 0,
      Plant_Code,
      HoldAmount: null,
      Reason: '',
      UserId: GLOBAL.USER_ID,
      WorkOrder_Code: WorkOrderCode
    };

    const hrcSave$ = this.wOTaskListService.saveHRCRequest(requestObj);
    const hrcDocumentSave$ = this.onUpload(files);

    forkJoin(hrcSave$, hrcDocumentSave$).subscribe(() => {
      this.logHelperService.logSuccess({
        message: 'Data saved successfully.'
      });

      modalRef.close('');
    }, (() => {
      this.logHelperService.logError({
        message: 'Failed to save data.'
      })
    }))
  }

  private checkValidationOnAction(): [boolean, string] {
    if (this.taskList.filter(item => item.StatusMasterList_Code === 264).length) {
      this.logHelperService.logError({
        message: 'Still, the Contractor is not submitted HRC for the month. '
      });

      return [false, 'Still, the Contractor is not submitted HRC for the month. '];
    }

    return [true, null];
  }

  private saveHRCUndertaking(data: any[]): void {
    const finaList = data.map(({ ID, Request_Code, HRC_CheckList, Task_Code, IsComplied, Reason, RequireType }) => {
      return {
        ID: ID,
        Request_Code,
        WorkOrder_Code: this.hrcDetails.WorkOrderCode,
        HRC_CheckList,
        HRC_Code: this.hrcDetails.HRCCode || null,
        Task_Code,
        Month_Code: this.hrcDetails.PayrollMonth_Code,
        IsComplied,
        Reason,
        RequireType: RequireType || 0,
        UserID: GLOBAL.USER_ID
      }
    });

    this.wOTaskListService.saveHRCUnderTakingList(finaList).subscribe(res => {
      this.logHelperService.logSuccess({
        message: 'Data saved successfully.'
      });
      this.iPrintDisabled = false;
    })
  }

  private onUpload(files: any[]): Observable<any> {
    const data = {
      DShort_Code: 'DOCP22206HRCT',
      Image_Type: '146',  // HRC Undertaking
      RefTypeMasterList_Code: 1047,// HRC Module
      Referance_Code: this.hrcDetails.HRCCode  // HRC_Code
    };

    return this.wOTaskListService.hrcUploadDoc(data, files);
  }

  private getOptionList(): any[] {
    return [
      {
        label: 'Yes',
        value: 1
      },
      {
        label: 'No',
        value: 2
      },
      {
        label: 'Not Applicable',
        value: 3
      }
    ]
  }

  private setControlValues(taskList: any[]): void {
    const control = this.formGroup.get('taskActions') as FormArray;
    control.controls = [];
    for (const task of taskList) {
      const formGroup: any = this.buildForm(task);
      control.push(formGroup);
    }
  }

  private buildForm(task: any): FormGroup {
    const reasonDisabled = task.IsComplied === 1 || task.ID === null ? true : false
    return this.fb.group({
      HRC_CheckList: [task.HRC_CheckList || null],
      ID: [task.ID || null],
      Request_Code: [task.Request_Code || null],
      WorkOrder_Code: [task.WorkOrder_Code || null],
      Task_Code: [task.Task_Code || null],
      CheckListName: [task.CheckListName || null],
      PayrollMonth_Code: [task.PayrollMonth_Code || null],
      IsComplied: [task.ID ? task.IsComplied : 1],
      Reason: [{ value: task.Reason || null, disabled: reasonDisabled }, reasonDisabled ? [] : [Validators.required]]
    })
  }


}