import { Injectable } from '@angular/core';
import * as Swal from 'sweetalert2';

class LogMessage {
  message: string;
  title?: string;
  html?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LogHelperService {

  constructor() { }

  logSuccess(data: LogMessage) {
    Swal.default({
      title: data.title || 'Success!',
      text: data.message,
      type: 'success',
      confirmButtonText: 'OK'
    });
  }

  logApproved(data: LogMessage) {
    Swal.default({
      title: data.title || 'Rejected!',
      text: data.message,
      type: 'error',
      confirmButtonText: 'OK'
    });
  }

  logError(data: LogMessage, type: any = 'error', okButtonText: any = 'OK', showCancelButton: boolean = false,) {
    const swal = Swal.default({
      title: data.title || 'Error!',
      text: data.html ? "" : data.message,
      html: data.html ? data.message : "",
      type: type,
      confirmButtonText: okButtonText,
      cancelButtonText: 'Cancel',
      showCancelButton: showCancelButton
    });
    return swal;
  }
  confirmationBox(data: LogMessage, type: any = 'error', okButtonText: any = 'Yes', showCancelButton: boolean = false) {
    const swal = Swal.default({
      title: data.title || '',
      text: data.html ? "" : data.message,
      html: data.html ? data.message : "",
      confirmButtonText: okButtonText,
      customClass: 'swal-custom-cls',
      cancelButtonText: 'No',
      showCancelButton: true,
      padding: 50,
      width: 600,
      showCloseButton: true
    });
   return swal;
  }
}
