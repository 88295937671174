import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { NgxSidebarService } from './ngx-sidebar.service';

export class NgxSidebarOptions {
  title = '';
  width = 40;
}

@Component({
  selector: 'app-ngx-sidebar',
  templateUrl: './ngx-sidebar.component.html',
  styleUrls: ['./ngx-sidebar.component.css']
})
export class NgxSidebarComponent implements OnInit {
  sidebarOpen = false;
  renderSidebar = false;
  @Input() options: NgxSidebarOptions = new NgxSidebarOptions();
  constructor(
    private ngxSidebarService: NgxSidebarService,
    private eRef: ElementRef
  ) {
    this.ngxSidebarService.sidebarOpen.subscribe((res) => {
      this.sidebarOpen = res;
    });
    setTimeout(() => {
      this.renderSidebar = true;
    }, 1000);
  }

  ngOnInit() {
  }

  closeSidebar() {
    this.ngxSidebarService.closeSidebar();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && event.target.id !== 'open-filter') {
      this.ngxSidebarService.closeSidebar();
    }
  }

}
