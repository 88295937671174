import { Injectable } from '@angular/core';
import { ChartUtilService } from './chart-util.service';

declare var d3: any;
declare var dc: any;

interface Config {
  crossfilter: any;
  filterGroup: string;
  chartId: string;
  width: number;
  height: number;
  dispatch: any;

  // Optional
  crossfilter2?: any;
  yAxisLabel?: string;
  xAxisLabel?: string;
  legend?: boolean;
  labelValueFormatted?: boolean;
  margins?: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  };
}

@Injectable({
  providedIn: 'root'
})
export class DrilldownChartService {

  constructor(
    private chartUtilService: ChartUtilService,
  ) { }

  getDrillDownChartRequirementShortage(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['TotalReq', 'ActualDeployed', 'TotalShortage'];
    const barLabelMap = {
      TotalReq: 'Planned',
      ActualDeployed: 'Actual',
      TotalShortage: 'Shortage'
    };

    /*
    Expt means Key
    Run means Plant
    Speed  TotalReq
    */
    const dimensions = [
      config.crossfilter.dimension((d) => {
        if (d.SiteName !== null) {
          return d.SiteName;
        }
      }),
      config.crossfilter.dimension(d => d.PlantName),
      config.crossfilter.dimension(d => d.UnitName),
      config.crossfilter.dimension(d => d.DeptName),
    ];

    const dimensions2 = [
      config.crossfilter2.dimension((d) => {
        if (d.SiteName !== null) {
          return d.SiteName;
        }
      }),
      config.crossfilter2.dimension(d => d.PlantName),
      config.crossfilter2.dimension(d => d.UnitName),
      config.crossfilter2.dimension(d => d.DeptName),
    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.TotalReq !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const amountSumGroup = this.getGroupByDimension(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
      .on('filtered', (chart, filter) => {
        if (filter === null || dimensionIndex >= dimensions.length - 1) {
          return;
        }
        this.hideLabel(chart);

        dimensionIndex++;
        const dimension = dimensions[dimensionIndex];
        const amountSumGroup = this.getGroupByDimension(dimension);

        chart
          .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
          .dimension(mirror_dimension([dimension, dimensions2[dimensionIndex]]))
          .filterAll();

        for (let i = 1; i < barKeyList.length; ++i) {
          chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
        }
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(100).y(2).itemWidth(105).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }));
    }

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimension(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(mirror_dimension([dimension, dimensions2[dimensionIndex]]))
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimension(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(mirror_dimension([dimension, dimensions2[dimensionIndex]]))
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupByDimensionRequirementAndDeployed(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq += +v.ConractorAssigne;
        }

        p.ActualDeployed += +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq -= +v.ConractorAssigne;
        }

        p.ActualDeployed -= +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          ActualDeployed: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
        };
      });
  }

  getGroupByDimensionBudgetAndActual(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);

        if (p.Budget > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }

        return p;
      },
      (p: any, v: any) => {
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);

        if (p.Budget > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0,
          BudgetPec: 0,
          ActualPec: 0
        };
      });
  }

  getDrillDownPlantWiseBudgetVsActual(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['Budget', 'Actual'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
    };

    /*
    Expt means Key
    Run means Plant
    Speed  TotalReq
    */
    const dimensions = [
      config.crossfilter.dimension((d) => {
        if (d.Plant) {
          return d.Plant;
        }
      }),
      config.crossfilter.dimension((d) => {
        if (d.Unit) {
          return d.Unit;
        }
      }),
    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Budget !== 0 && d.value.Actual !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
      .on('filtered', (chart, filter) => {
        if (filter === null || dimensionIndex >= dimensions.length - 1) {
          return;
        }

        this.hideLabel(chart);
        dimensionIndex++;
        const dimension = dimensions[dimensionIndex];
        const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);

        chart
          .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
          .dimension(dimension)
          .filterAll();

        for (let i = 1; i < barKeyList.length; ++i) {
          chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
        }
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(100).y(2).itemWidth(105).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }));
    }

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getDrillDownChartRequirementDeployed(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['TotalReq', 'ActualDeployed'];
    const barLabelMap = {
      TotalReq: 'Requirements',
      ActualDeployed: 'Actual',
    };

    /*
    Expt means Key
    Run means Plant
    Speed  TotalReq
    */
    const dimensions = [
      config.crossfilter.dimension((d) => {
        if (d.Site !== null) {
          return d.Site;
        }
      }),
      config.crossfilter.dimension(d => d.PlantName),
      config.crossfilter.dimension(d => d.UnitName),
      config.crossfilter.dimension(d => d.DeptName),
    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.TotalReq !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const amountSumGroup = this.getGroupByDimensionRequirementAndDeployed(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
      .on('filtered', (chart, filter) => {
        if (filter === null || dimensionIndex >= dimensions.length - 1) {
          return;
        }

        this.hideLabel(chart);
        dimensionIndex++;
        const dimension = dimensions[dimensionIndex];
        const amountSumGroup = this.getGroupByDimensionRequirementAndDeployed(dimension);

        chart
          .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
          .dimension(dimension)
          .filterAll();

        for (let i = 1; i < barKeyList.length; ++i) {
          chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
        }
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(100).y(2).itemWidth(105).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }));
    }

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimensionRequirementAndDeployed(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimensionRequirementAndDeployed(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }




  getGroupByDimension(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq += +v.ConractorAssigne;
          p.TotalShortage += +v.TotalShortage;
        } else {
          p.TotalShortage -= +v.ActualDeployed;
        }
        p.ActualDeployed += +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq -= +v.ConractorAssigne;
          p.TotalShortage -= +v.TotalShortage;
        } else {
          p.TotalShortage += +v.ActualDeployed;
        }

        p.ActualDeployed -= +v.ActualDeployed;

        const total = p.TotalReq + p.ActualDeployed + p.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          ActualDeployed: 0,
          TotalShortage: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
          TotalShortagePec: 0
        };
      });
  }

  getGroupByDimensionForPresetn(dimension, index) {
    return dimension.group().reduce(
      (p: any, v: any) => {

        if (v.IsShortageClear === 0) {
          p.TotalReq += +v.ConractorAssigne;
        }

        p.TotalPresent += +v.ActualDeployed;
        // if (index === 3) { // DeptName wise
        //   if (v.DeptName !== 'NF' && v.AttendanceStatus === 'P') {
        //     p.TotalPresent += v.ActualDeployed;
        //   }
        //   if (v.DeptName === 'NF' && v.AttendanceStatus === 'P') {
        //     p.TotalPresent++;
        //   }
        // } else {
        //   if (v.AttendanceStatus === 'P') {
        //     p.TotalPresent++;
        //   }
        // }

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.TotalPresentPec = Math.round((p.TotalPresent * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq -= +v.ConractorAssigne;
        }

        p.TotalPresent -= +v.ActualDeployed;
        // p.TotalReq -= +v.TotalReq;
        // if (index === 3) { // DeptName wise
        //   if (v.DeptName !== 'NF' && v.AttendanceStatus === 'P') {
        //     p.TotalPresent -= v.ActualDeployed;
        //   }
        //   if (v.DeptName === 'NF' && v.AttendanceStatus === 'P') {
        //     p.TotalPresent--;
        //   }
        // } else {
        //   if (v.AttendanceStatus === 'P') {
        //     p.TotalPresent--;
        //   }
        // }

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.TotalPresentPec = Math.round((p.TotalPresent * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          TotalPresent: 0,
          TotalReqPec: 0,
          TotalPresentPec: 0,
        };
      });
  }

  hideLabel = (chart) => {
    chart.selectAll('text.barLabel')
      .attr('class', 'barLabel d-none');
  }

  rotateBarLabel = (chart, config?: Config) => {
    if (!chart.select('.bar')[0][0]) {
      return;
    }

    const y = chart.y();
    const x = chart.x();
    // tslint:disable-next-line
    const barWidth = parseInt(chart.select('.bar')[0][0].getAttribute('width'));
    const gap = chart.gap();
    const groupGap = chart.groupGap();

    // console.log('gap', gap);
    // console.log('groupGap', groupGap);
    // console.log('barWidth', barWidth);

    chart.selectAll('text.barLabel')
      .attr('class', 'barLabel d-none')
      .attr('transform', 'rotate(-90)')
      .attr('y', (d) => {
        let groupBars = 0;
        if (d.layer === 'Actual') {
          groupBars = barWidth + groupGap;
        } else if (d.layer === 'ActualDeployed' || d.layer === 'Present' || d.layer === 'TotalPresent') {
          groupBars = barWidth + gap;
        } else if (d.layer === 'TotalShortage') {
          groupBars = (barWidth * 2) + gap + groupGap;
        }

        // console.log('y', x(d.x), groupBars);

        return x(d.x) + (barWidth / 2) + gap + groupGap + groupBars;
      })
      .attr('x', (d) => {
        return -y(0) + 40 + this.getExtraPadding(d.y, config);
      })
      .attr('class', 'barLabel');
  }

  getExtraPadding(value, config: Config) {
    if (config && config.labelValueFormatted) {
      return 0;
    }

    if (value > 999999999) {
      return 18;
    }

    if (value > 9999999) {
      return 15;
    }

    return 0;
  }

  getGroupByBudgetDimension(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += +v.Budget;
        p.Actual += +v.Actual;

        if (p.Actual > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }

        return p;
      },
      (p: any, v: any) => {
        p.Budget -= +v.Budget;
        p.Actual -= +v.Actual;

        const total = p.Budget + p.Actual;

        if (p.Actual > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0,
          BudgetPec: 0,
          ActualPec: 0
        };
      });
  }
  getDrillDownChartBudgetRequirementShortage(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['Budget', 'Actual'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual'
    };

    /*
    Expt means Key
    Run means Plant
    Speed  TotalReq
    */
    const dimensions = [
      config.crossfilter.dimension((d) => {
        if (d.State !== null) {
          return d.State;
        }
      }),
      config.crossfilter.dimension(d => d.Plant),
      config.crossfilter.dimension(d => d.Unit),
    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Budget !== 0 && d.value.Actual !== 0 && d.value.Budget !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const amountSumGroup = this.getGroupByBudgetDimension(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
      .on('filtered', (chart, filter) => {
        if (filter === null || dimensionIndex >= dimensions.length - 1) {
          return;
        }
        this.hideLabel(chart);

        dimensionIndex++;
        const dimension = dimensions[dimensionIndex];
        const amountSumGroup = this.getGroupByBudgetDimension(dimension);

        chart
          .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
          .dimension(dimension)
          .filterAll();

        for (let i = 1; i < barKeyList.length; ++i) {
          chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
        }
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(100).y(2).itemWidth(105).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }));
    }

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByBudgetDimension(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByBudgetDimension(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }


  getGroupByManPBudgetDimension(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.Requirement += +v.Requirement;
        p.Present += +v.Present;

        if (p.Present > 0) {
          p.RequirementPec = 100;
          p.PresentPec = Math.round((p.Present * 100) / p.Requirement);
        }

        return p;
      },
      (p: any, v: any) => {
        p.Requirement -= +v.Requirement;
        p.Present -= +v.Present;

        const total = p.Requirement + p.Present;

        if (p.Present > 0) {
          p.RequirementPec = 100;
          p.PresentPec = Math.round((p.Present * 100) / p.Requirement);
        }
        return p;
      },
      () => {
        return {
          Requirement: 0,
          Present: 0,
          RequirementPec: 0,
          PresentPec: 0
        };
      });
  }
  getDrillDownChartManBudgetRequirementShortage(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['Requirement', 'Present'];
    const barLabelMap = {
      Requirement: 'Budgeted Manpower',
      Present: 'Actual'
    };

    /*
    Expt means Key
    Run means Plant
    Speed  TotalReq
    */
    const dimensions = [
      config.crossfilter.dimension((d) => {
        if (d.State !== null) {
          return d.State;
        }
      }),
      config.crossfilter.dimension(d => d.Plant),
      config.crossfilter.dimension(d => d.Unit),
    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Requirement !== 0 && d.value.Present !== 0 && d.value.Requirement !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const amountSumGroup = this.getGroupByManPBudgetDimension(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
      .on('filtered', (chart, filter) => {
        if (filter === null || dimensionIndex >= dimensions.length - 1) {
          return;
        }
        this.hideLabel(chart);

        dimensionIndex++;
        const dimension = dimensions[dimensionIndex];
        const amountSumGroup = this.getGroupByManPBudgetDimension(dimension);

        chart
          .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
          .dimension(dimension)
          .filterAll();

        for (let i = 1; i < barKeyList.length; ++i) {
          chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
        }
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(100).y(2).itemWidth(120).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }));
    }

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByManPBudgetDimension(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByManPBudgetDimension(dimension);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(dimension)
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getDrillDownChartPlatWisePresent(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['TotalReq', 'TotalPresent'];
    const barLabelMap = {
      TotalReq: 'Planned',
      TotalPresent: 'Present',
    };

    /*
    Expt means Key
    Run means Plant
    Speed  TotalReq
    */
    const dimensions = [
      config.crossfilter.dimension((d) => {
        if (d.SiteName !== null) {
          return d.SiteName;
        }
      }),
      config.crossfilter.dimension(d => d.PlantName),
      config.crossfilter.dimension(d => d.UnitName),
      config.crossfilter.dimension(d => d.DeptName),
    ];

    const dimensions2 = [
      config.crossfilter2.dimension((d) => {
        if (d.SiteName !== null) {
          return d.SiteName;
        }
      }),
      config.crossfilter2.dimension(d => d.PlantName),
      config.crossfilter2.dimension(d => d.UnitName),
      config.crossfilter2.dimension(d => d.DeptName),
    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.TotalReq !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const amountSumGroup = this.getGroupByDimensionForPresetn(dimension, 0);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
      .on('filtered', (chart, filter) => {
        if (filter === null || dimensionIndex >= dimensions.length - 1) {
          return;
        }
        this.hideLabel(chart);

        dimensionIndex++;
        const dimension = dimensions[dimensionIndex];
        const amountSumGroup = this.getGroupByDimensionForPresetn(dimension, dimensionIndex);

        chart
          .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
          .dimension(mirror_dimension([dimension, dimensions2[dimensionIndex]]))
          .filterAll();

        for (let i = 1; i < barKeyList.length; ++i) {
          chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
        }
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(100).y(2).itemWidth(105).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }));
    }

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimensionForPresetn(dimension, dimensionIndex);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(mirror_dimension([dimension, dimensions2[dimensionIndex]]))
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const amountSumGroup = this.getGroupByDimensionForPresetn(dimension, dimensionIndex);
      chart
        .group(removeEmptyData(amountSumGroup), barKeyList[0], sel_stack(barKeyList[0]))
        .dimension(mirror_dimension([dimension, dimensions2[dimensionIndex]]))
        .filterAll();

      for (let i = 1; i < barKeyList.length; ++i) {
        chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
      }

      chart.render();
    });

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(removeEmptyData(amountSumGroup), barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }
}

function mirror_dimension(dims) {
  function mirror(fname) {
    return function (v) {
      dims.forEach((dim) => {
        dim[fname](v);
      });
    };
  }
  return {
    filter: mirror('filter'),
    filterExact: mirror('filterExact'),
    filterRange: mirror('filterRange'),
    filterFunction: mirror('filterFunction')
  };
}
