import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ComponentFactory } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FilterTypeEnum } from '../report-filter-test/report-filter.model';

@Injectable()
export class ContractorFilterPrsenter {

  public reportData$: Observable<FormGroup>;
  private reportData: Subject<FormGroup>;
  public startDate$: Observable<string>;
  private startDate: Subject<string>;
  public contractor$: Observable<number>;
  private contractor: Subject<number>;
  public workOrder$: Observable<any>;
  private workOrder: Subject<any>;
  constructor(private fb: FormBuilder) {
    this.reportData = new Subject();
    this.reportData$ = this.reportData.asObservable();

    this.startDate = new Subject();
    this.startDate$ = this.startDate.asObservable();

    this.contractor = new Subject();
    this.contractor$ = this.contractor.asObservable();

    this.workOrder = new Subject();
    this.workOrder$ = this.workOrder.asObservable();
  }

  public buildForm(): FormGroup {
    return this.fb.group({
      startDate: [''],
      contractorCode: [null],
      workOrderNo: [null],
    });
  }

  public saveContractorFilterData(contractorFormGroup: FormGroup) {
    if (contractorFormGroup.get('startDate').value !== '' || contractorFormGroup.get('contractorCode').value !== null
      || contractorFormGroup.get('workOrderNo').value !== null) {
      const data: any = contractorFormGroup.value;
      data.filterType = FilterTypeEnum.ContractorFilter;
      this.reportData.next(data);
    }
  }

  public onStartDateChange(startDate: string): void {
    if (startDate !== '') {
      this.startDate.next(startDate);
    }
  }

  public onContractorChange(contractorCode: string): void {
    if (contractorCode !== null) {
      this.contractor.next(parseInt(contractorCode, 10));
    } else {
      this.contractor.next(null);
    }
  }

  public onWorkOrderChange(contractorCode: string, workOrderNo: string): void {
    if (contractorCode !== null && workOrderNo !== null) {
      this.workOrder.next({
        contractorCode,
        workOrderNo
      });
    }
  }
}
