import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-icon-action-cell-renderer',
  templateUrl: './ag-icon-action-cell-renderer.component.html',
  styleUrls: ['./ag-icon-action-cell-renderer.component.css']
})
export class AgIconActionCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  width = 30;
  title = '';
  icon = 'fa fa-info';
  cssClass = '';
  fontSize = 24;
  count = 0;
  showCount = false;
  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
    this.count = params.rowIndex + 1;
    this.width = this.params.width ? this.params.width : 30;
    this.icon = this.params.icon ? this.params.icon : 'fa fa-info';
    this.cssClass = this.params.cssClass ? this.params.cssClass : '';
    this.title = this.params.title ? this.params.title : '';
    this.fontSize = this.params.fontSize ? this.params.fontSize : 24;
    this.showCount = this.params.showCount ? true : false;
  }

  refresh() {
    return true;
  }

  onClick() {
    this.params.onClick(this.params);
  }

}
