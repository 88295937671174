import { NullTemplateVisitor } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-details',
  templateUrl: './date-details.component.html',
  styleUrls: ['./date-details.component.css']
})
export class DateDetailsComponent implements OnInit {
  @Input() approvalData: any;
  @Input() requestType: number;
  @Input() maxValidityDate: any;

  constructor(public activeModal: NgbActiveModal,) { }
  date = new Date();
  ngOnInit() {
    this.approvalData.POValidityDate = this.approvalData.POValidityDate ? new Date(this.approvalData.POValidityDate) : null;
    this.approvalData.LicenseValidityDate = this.approvalData.LicenseValidityDate ? new Date(this.approvalData.LicenseValidityDate) : null;
    this.approvalData.ECPolicyValidityDate = this.approvalData.ECPolicyValidityDate ? new Date(this.approvalData.ECPolicyValidityDate) : null;
    this.approvalData.XrayValidityDate = this.approvalData.XrayValidityDate ? new Date(this.approvalData.XrayValidityDate) : null;
    this.approvalData.EyeTestingValidityDate = this.approvalData.EyeTestingValidityDate ? new Date(this.approvalData.EyeTestingValidityDate) : null;
  }

  filterData() {
    this.activeModal.close();
  }
}
