import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
@Injectable()
export class ListFilterPipe implements PipeTransform {
  transform(items: any[], fields: string[], value: string): any[] {
    console.log('List value', value);
    if (!items) {
      return [];
    }
    if (!fields || !value) {
      return items;
    }

    return items.filter((singleItem) => {
      let find = false;
      fields.some((field, index, arr) => {
        if (singleItem && singleItem[field]) {
          find = singleItem[field].toString().toLowerCase().includes(value.toLowerCase());
          return find;
        }
      });
      return find;
    });
  }
}
