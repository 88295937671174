import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from 'src/app/app.globals';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SelectStepsFilterService {
  constructor(private httpClient: HttpClient) {

  }

  public getAllContractorData(): Observable<any> {
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_CONTRACTORS);
  }

  public getContractorFilterData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS
      + '?search=' + data.search + '&sDate=' + data.sDate + '&eDate='
      + data.eDate + '&selectedField=' + data.field + '&isFilter=' + data.isFilter);
  }

  /**
   * @author Ashok Yadav.
   * @method getWorkOrderFilterData
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getWorkOrderFilterData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate=' + data.sDate
    //   + '&eDate=' + data.eDate
    //   + '&search=' + data.search
    //   + '&selectedField=' + data.selectedField
    //   + '&filterType=' + data.filterType
    //   + '&condition=' + data.condition
    //   + '&isActive=' + data.isActive
    //   + '&workOrderType=' + data.workOrderType
    //   + '&workOrderOwner_Code=' + data.workOrderOwnerCode
    //   + '&workOrderHOD_Code=' + data.workOrderOwnerHOD
    //   + '&department_Code=' + data.departmentCode
    //   + '&isContractorType=' + data.isContractorType
    //   + '&Plant_Code=' + data.plantCode
    //   + '&NatureOfWork_Code=' + data.natureOfLicenseCode
    //   + '&NatureOfWork_Code=' + data.natureOfWorkCode
    //   + '&Contractor_Code=' + data.contractorCode
    //   + '&SubContractor_Code=' + data.contractorCode
    //   + '&WorkOrder_Code=' + data.workOrderCode
    //   + '&SubWorkOrder_Code=' + data.subWorkOrderCode
    //   + '&WorkSiteArea_Code=' + data.WorkSiteAreaCode
    //   + '&IsHRCApplicable=' + data.isHRCApplicable
    //   + '&Status_Code=' + data.statusCode
    //   + '&UserId=' + data.userId);

    const payload = {
      sDate: data.sDate,
      eDate: data.eDate,
      selectedField: data.selectedField,
      filterType: data.filterType,
      condition: data.condition,
      isActive: data.isActive,
      workOrderType: data.workOrderType,
      workOrderOwner_Code: data.workOrderOwnerCode,
      workOrderHOD_Code: data.workOrderOwnerHOD,
      department_Code: data.departmentCode,
      isContractorType: data.isContractorType,
      Plant_Code: data.plantCode,
      NatureOfWork_Code: data.natureOfWorkCode,
      Contractor_Code: data.contractorCode,
      SubContractor_Code: data.contractorCode,
      WorkOrder_Code: data.workOrderCode,
      SubWorkOrder_Code: data.subWorkOrderCode,
      WorkSiteArea_Code: data.WorkSiteAreaCode,
      IsHRCApplicable: data.isHRCApplicable,
      Status_Code: data.statusCode,
      UserId: data.userId
    };

    return this.httpClient.post<any[]>(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER, payload).pipe(
      map((response: any) => response));

  }

  /**
   * @author Ashok Yadav.
   * @method getLicenceFilterData
   * @description Invoke this method and it is return `getLicenceFilterData` observable.
   */
  public getLicenceFilterData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_LICENSE
      + '?search=' + data.search
      + '&sDate=' + data.sDate
      + '&eDate=' + data.eDate
      + '&State_Code=' + data.stateCode
      + '&Status_Code=' + data.statusCode
      + '&isContractorType=' + data.isContractorType
      + '&Plant_Code=' + data.plantCode
      + '&IsActive=' + data.isActive
      + '&NatureOfLicense_Code=' + data.natureOfLicenseCode
      + '&NatureOfWork_Code=' + data.natureOfWorkCode
      + '&Contractor_Code=' + data.contractorCode
      + '&SubContractor_Code=' + data.contractorCode
      + '&WorkOrder_Code=' + data.workOrderCode
      + '&SubWorkOrder_Code=' + data.subWorkOrderCode
      + '&selectedField=' + data.field);
  }

  /**
   * @author Ashok Yadav.
   * @method getEcPolicyData
   * @description Invoke this method and it is return `getEcPolicyData` observable.
   */
  public getEcPolicyFilterData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS_WCPOLICIES_DATA
    return this.httpClient.get(`${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS_COMPANYECPOLICIES_DATA}?search=${data.search}`);
  }

  /**
   * @author Ashok Yadav.
   * @method getLabourProfileData
   * @description Invoke this method and it is return `getLabourProfileData` observable.
   */
  public getLabourProfileData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_PROFILE);
  }

  /**
   * @author Ashok Yadav.
   * @method getPassListingData
   * @description Invoke this method and it is return `getPassListingData` observable.
   */
  public getPassListingData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR);
  }

  /**
  * @author Ashok Yadav.
  * @method getShiftListingData
  * @description Invoke this method and it is return `getShiftListingData` observable.
  */
  public getShiftListingData(data: any): Observable<any> {
    const model = data;
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(GLOBAL.APIS.USER_MANAGEMENT.GET_SHIFT_MASTER_DATA, data);
  }

  /**
  * @author Ashok Yadav.
  * @method getManPowerRequtionListingData
  * @description Invoke this method and it is return `getManPowerRequtionListingData` observable.
  */
  public getManPowerRequtionListingData(data: any): Observable<any> {
    const model = {
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COMPANY_BUDGET_DATA}`, model);
  }

  // tslint:disable-next-line: prefer-template
  //  return this.httpClient.get(GLOBAL.APIS.USER_MANAGEMENT.GET_COMPANY_BUDGET_DATA);
  // }

  /**
   * @author Ashok Yadav.
   * @method getUserList
   * @description Invoke this method and it is return `getManPowerRequtionListingData` observable.
   */
  public getUserList(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_LIST}?search=&Company=&Plant=&Designation=&State=&City=&Country=
    &Gender=&isLock=&isActive= &isDelete=&isSA=&Role=&TimeZone=&Language=&IsOrganizationUser=`;
    return this.httpClient.get(url);
  }

  /**
   * @author Ashok Yadav.
   * @method getWorkOrderByContractor
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getWorkOrderByContractr(data: any): Observable<any> {
    return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.
      GET_PASS_MANAGEMENT}GetWorkOrderByContractor?ID=${data.contractorVendorcode}&ReqType=${data.requestType}`);
  }

  /**
   * @author Ashok Yadav.
   * @method getLicenseByContractor
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getLicenseByContractor(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT
      + 'GetLicenseByContractor?ID=' + data.parentContractorVendorCode
      + '&ReqType=' + data.requestType + '&workordercode=' + data.parentWorkOrderCode);
  }
}
