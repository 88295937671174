import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "src/app/core/services";
import { LoanNRecoveriesRegisterFromCService } from "./loan-n-recoveries-register-from-c.service";
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeComponent } from "ng-pick-datetime";
import { MY_MOMENT_FORMATS } from "src/app/core/core.module";
import { EntryForThePrsenter } from "../../entryfortheday/entryfortheday-presenter/entryfortheday.presenter";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { forkJoin, Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoanNRecoveriesFromCComponent } from "./loan-n-recoveries-from-c/loan-n-recoveries-from-c.component";
import * as moment from "moment";

declare var $;

@Component({
  selector: 'app-loan-n-recoveries-register-from-c',
  templateUrl: './loan-n-recoveries-register-from-c.component.html',
  styleUrls: ['./loan-n-recoveries-register-from-c.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "en-IN" },
    LoanNRecoveriesRegisterFromCService
  ],
})

export class LoanNRecoveriesRegisterFromCComponent implements OnInit {

  formGroup: FormGroup;
  filterNameFormGroup: FormGroup;
  companyList: any[] = [];
  plantList: any[] = [];
  stateList: any[] = [];
  contractorsList: any[] = [];
  contractorWorkOrderList: any[] = [];

  activeFilter: string;
  selectedTabId: any;
  selectedContractor: any
  filterTabList: any[] = [];
  selectedFilterData: any = {};

  pageCode: any = 349;
  plantCode: any;

  contAndSubContList: any[] = [
    { ContAndSubCont: 0, name: "Date Of Damage" },
    { ContAndSubCont: 1, name: "Recovery Date" },
  ];

  userId = localStorage.getItem("UserID");
  @ViewChild("dialogerror") dialogerror: SwalComponent;
  @ViewChild("endDate") endDate: OwlDateTimeComponent<any>;

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    private modalService: NgbModal,
    public apiCallService: LoanNRecoveriesRegisterFromCService,
    private entryForThePrsenter: EntryForThePrsenter,
  ) { }

  ngOnInit() {
    const currentMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const currentMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    this.formGroup = this.fb.group({
      DateRange: new FormControl([currentMonthStart, currentMonthEnd]),
      ContAndSubCont: new FormControl(null, Validators.required),
      Company: new FormControl(null, Validators.required),
      Plants: new FormControl(null, Validators.required),
      State: new FormControl(null, Validators.required),
      Contractors: new FormControl(null, Validators.required),
      WorkOrder: new FormControl(null),
    });

    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.getAllData();
    this.manageVisibilityOfSteps();
  }

  getAllData() {
    this.getCompany();
    this.formGroup.patchValue({ ContAndSubCont: 1 });
    const roleCode = JSON.parse(localStorage.getItem("Role"))[0].Role_Code;
    if (this.userId && roleCode === 119) {
      this.getContractorsSelectedByDefault(this.userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  onFilterTab(filterObj: any): void {
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  getCompany() {
    this.apiCallService.getCompanies().subscribe((response) => {
      this.companyList = response;
      const companyCode = this.selectedFilterData.Company_Code;
      if (companyCode) {
        this.formGroup.patchValue({ Company: companyCode });
        this.getPlants(companyCode);
      } else if (this.companyList.length === 1) {
        const singleCompany = this.companyList[0].Company_Code;
        this.formGroup.get("Company").disable();
        this.formGroup.patchValue({ Company: singleCompany });
        this.getPlants(singleCompany);
      }
    });
  }

  getPlants(companyCode: any): void {
    this.apiCallService.getPlants(companyCode).subscribe((response) => {
      this.plantList = response;
      this.plantCode = this.selectedFilterData.Plant_Code;
      if (this.plantCode) {
        this.getState(this.plantCode);
      } else if (this.plantList.length === 1) {
        const singlePlantCode = this.plantList[0].Plant_Code;
        this.formGroup.patchValue({ Plants: singlePlantCode });
        this.getState(singlePlantCode);
      }
    });
  }

  getState(plantCode: any): void {
    this.apiCallService.getStatesList(plantCode).subscribe((response) => {
      this.stateList = response;
      if (this.stateList.length === 1) {
        const stateCode = this.stateList[0].State_Code;
        this.formGroup.patchValue({ State: stateCode });
      }
    });
  }

  getContractorsSelectedByDefault(userId: any, roleCode: any) {
    this.apiCallService.getContractorsbydefault(userId, roleCode).subscribe((response) => {
      this.contractorsList = response;
      this.selectedContractor = this.contractorsList && this.contractorsList.length ? this.contractorsList[0] : null;
      if (this.selectedFilterData && this.selectedFilterData.Contractors) {
        this.formGroup.patchValue({ Contractors: this.selectedFilterData.Contractors });
        this.getWorkOrdersByContractorCode(this.selectedFilterData.Contractors, this.selectedFilterData.plantcode);
      } else if (this.contractorsList.length === 1) {
        this.formGroup.patchValue({ Contractors: this.contractorsList[0].ContractorVendor_Code });
        this.getWorkOrdersByContractorCode(this.contractorsList[0].ContractorVendor_Code, this.selectedFilterData.plantcode);
      }
    }
    );
  }

  getContractors(): void {
    this.apiCallService.getContractors().subscribe((response) => {
      this.contractorsList = response;
      if (this.selectedFilterData.Contractors) {
        this.formGroup.patchValue({ Contractors: this.selectedFilterData.Contractors });
        this.getWorkOrdersByContractorCode(this.selectedFilterData.Contractors, this.selectedFilterData.Plant_Code);
      }
    });
  }

  getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.apiCallService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response) => {
      this.contractorWorkOrderList = response;
      if (this.selectedFilterData.WorkOrder_Code) {
        this.formGroup.patchValue({ WorkOrder: this.selectedFilterData.WorkOrder_Code });
      }
      else if (this.contractorWorkOrderList.length === 1) {
        this.formGroup.patchValue({ WorkOrder: this.contractorWorkOrderList[0].WorkOrder_Code });
      }
    });
  }

  onCompanyChange() {
    const companyCode = this.formGroup.controls['Company'].value;
    this.formGroup.patchValue({ Plants: null });
    this.selectedFilterData.Plant_Code = null;
    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  selectedPlant: any
  onPlantChange() {
    this.selectedPlant = this.formGroup.controls['Plants'].value;

    if (this.selectedPlant) {
      this.getState(this.selectedPlant);
    }
  }

  onContractorChange(selectedContractor) {
    this.selectedContractor = selectedContractor;

    const controls = this.formGroup.controls;
    this.formGroup.patchValue({
      WorkOrder: null,
    });

    this.selectedFilterData = {
      WorkOrder: null,
    };

    if (!controls['WorkOrder'].value) {
      controls['WorkOrder'].disable();
    }

    const contractorCode = controls['Contractors'].value;
    this.plantCode = controls['Plants'].value;

    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, this.plantCode);
      controls['WorkOrder'].enable();
    }
  }

  onSubmit() {
    const WorkOrder = this.formGroup.value.WorkOrder;

    const reqObj = {
      Filter: {
        Plant_Code: this.plantCode,
        Page_Code: this.pageCode,
        Refereance_Code: null,
        Contractor_Code: this.selectedContractor.ContractorVendor_Code,
        WorkOrder_Code: WorkOrder ? WorkOrder : null,
        SubWorkOrder_Code: null,
        License_Code: null,
        SubLicense_Code: null,
        RequireType: 1,
        ActionType: 1,
        UserId: this.userId
      }
    }

    const dateRange = this.formGroup.value.DateRange;
    const contAndSubCont = this.formGroup.value.ContAndSubCont;

    let startDate: string | null = null;
    let endDate: string | null = null;

    if (dateRange && Array.isArray(dateRange)) {
      startDate = moment(dateRange[0]).format('YYYY-MM-DD');
      endDate = moment(dateRange[1]).format('YYYY-MM-DD');
    }

    const damageNLossReport = {
      sDate: startDate,
      eDate: endDate,
      RequireType: contAndSubCont,
      ContractorVendor_Code: this.selectedContractor.ContractorVendor_Code,
      WorkOrder_Code: WorkOrder ? WorkOrder : null,
      ActionType: 0,
      UserId: this.userId
    }
 
    const wageReportData = this.apiCallService.damageNLossReport(damageNLossReport);
    const headerData = this.apiCallService.saveLoanNRecoveriesFormC(reqObj);
    forkJoin(wageReportData, headerData).subscribe(([wageReportData, headerData]: [any, any]) => {
      this.openReportViewModal(wageReportData, headerData);
    }
    );
  }

  openReportViewModal(response: any, headerData: any): void {
    const formValue = this.formGroup.getRawValue();

    if (Array.isArray(response)) {
      if (formValue.dateRange && formValue.dateRange.length > 0) {
        const startDate = moment(formValue.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
        const endDate = moment(formValue.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);

        formValue.Date = startDate;
        formValue.EDate = endDate;
      }
    }

    const modalRef = this.modalService.open(LoanNRecoveriesFromCComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'modal-panel'
    });

    modalRef.componentInstance.selectedReportData = formValue;
    modalRef.componentInstance.headerData = headerData;
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.stateList = this.stateList;

    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }


  getControl(controlName: string) {
    return this.formGroup.get(controlName);
  }

  clearData() {
    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {};
    this.manageVisibilityOfSteps();
  }

  manageVisibilityOfSteps() {
    const children = $("#content-container").children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css("display", "");
        item.active = true;
      } else {
        $(children[index]).css("display", "none");
        item.active = false;
      }
    });
  }

  createFilterTab(): void {
    this.filterTabList = [
      { id: 1, name: "CRITERIA", active: true },
      { id: 2, name: "COMPANY", active: false },
      { id: 3, name: "CONTRACTOR", active: false },
    ];
  }
}