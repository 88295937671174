import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { UserManagementService } from '../user-management.service';
import { AccessCheckboxCellComponent } from '../access-checkbox-cell/checkbox-cell.component';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-user-pagewise-action',
  templateUrl: './user-pagewise-action.component.html',
  styleUrls: ['./user-pagewise-action.component.css']
})
export class UserPagewiseActionComponent implements OnInit {

  gridAPI: GridApi;
  gridOptions: GridOptions;
  dataList = [];
  columnDefs = [];
  rowData: any;
  orignalData = [];
  pUserId = null;

  pageActionRes;
  selectedActions = [];

  @Input()
  set userId(userId: number) {
    this.pUserId = userId;
  }

  @Input() userFullName: string;

  @Output() closeModel = new EventEmitter<boolean>();

  constructor(
    private userManagementService: UserManagementService, private loadingService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.getServiceData();
  }

  getServiceData() {
    const getPageActionList = this.userManagementService.getPageActionList();
    const getUserPageAction = this.userManagementService.getUserPageAction(this.pUserId);

    forkJoin(getPageActionList, getUserPageAction).
      subscribe(([pageActionRes, selectedActions]:
        [any[], any[]]) => {
        this.pageActionRes = pageActionRes;
        this.selectedActions = selectedActions;
        this.populateData();
      });
  }

  onCheckChange = (data, params) => {
    console.log('data', data);
    this.userManagementService.updateUserPageAction(data).subscribe((res) => {
    }, (err) => {
      data.value = !data.value;
    });
  }

  populateData() {
    const columnDefs = [];
    const rowData = [];

    const actionData = this.pageActionRes.Data.Table1;
    const pageData = this.pageActionRes.Data.Table;
    this.orignalData = this.pageActionRes.Data.Table;
    if (actionData.length > 0) {
      actionData.filter((action) => {
        columnDefs.push({
          headerName: action.actionName, field: `Action_${action.actionId}`,
          cellRendererFramework: AccessCheckboxCellComponent, headerClass: 'text-center',
          width: 100, colId: action.actionId,
          cellRendererParams: {
            onCheckChange: this.onCheckChange,
          }
        });
      });
    }

    if (pageData.length > 0) {
      // pageData.filter((pages) => {
      //   // tslint:disable-next-line:prefer-const
      //   let selectedActionIds = pages.selectedActionIds ? pages.selectedActionIds.split(',') : [];
      //   firstRow[pages.pageName] = selectedActionIds.length;
      // });
      let tmpModuleName = '';
      pageData.filter((data) => {

        let isNewName = false;
        if (tmpModuleName !== data.moduleName) {
          isNewName = true;
          tmpModuleName = data.moduleName;
          // const obj = {
          //   'Module List': isNewName ? data.moduleName : '',
          //   'Page List': '',
          // };

          // actionData.filter((action) => {
          //   obj[`${action.moduleId}_${action.actionName}`] = {
          //     actionId: action.actionId,
          //     moduleId: data.moduleId,
          //     value: false
          //   };
          // });
          // rowData.push(obj);
        }

        const obj = {
          'Module List': isNewName ? data.moduleName : '',
          'Page List': data.pageName,
        };

        const found = this.selectedActions.find(item => item.PageId === data.pageId);
        let sellectedUserActionIds = [];
        if (found && found.selectedActionIds) {
          sellectedUserActionIds = found.selectedActionIds.split(', ').map(item => parseInt(item, 10));
        }

        // tslint:disable-next-line:prefer-const
        const selectedActionIds = data.selectedActionIds ? data.selectedActionIds.split(',').map(Number) : [];
        actionData.filter((action) => {
          if (selectedActionIds.indexOf(action.actionId) < 0) {
            obj[`Action_${action.actionId}`] = {
              actionId: action.actionId,
              pageId: data.pageId,
              moduleId: data.moduleId,
              userId: this.pUserId,
              value: false, // false,
              disabled: true,
            };
          } else {
            const selectedRole = sellectedUserActionIds.findIndex(item => item === action.actionId) > -1;
            obj[`Action_${action.actionId}`] = {
              actionId: action.actionId,
              pageId: data.pageId,
              moduleId: data.moduleId,
              userId: this.pUserId,
              value: selectedRole,
              disabled: false,
            };
          }
        });
        rowData.push(obj);
      });
    }
    setTimeout(() => {
      this.dataList = rowData;
      const dataCol = [
        {
          headerName: 'Module List', field: 'Module List',
          sortable: false, filter: false, width: 200, pinned: true,
          headerClass: 'text-center',
        },
        {
          headerName: 'Page List', field: 'Page List',
          sortable: false, filter: false, width: 200, pinned: true,
          headerClass: 'text-center',
        },
        // {
        //   headerName: '', field: 'selectAllActions',
        //   cellRendererFramework: CheckboxCellComponent, headerClass: 'text-center',
        //   width: 100, colId: 'selectAllActions'
        // },
        {
          headerName: 'Action List', field: 'Action List',
          headerClass: 'text-center',
          children: []
        }
      ];
      dataCol[2].children = columnDefs;
      this.columnDefs = dataCol;
      this.setGridOption();
    }, 500);
  }

  setGridOption() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowData: this.dataList,
      rowHeight: 32,
      headerHeight: 34,
      defaultColDef: {
        resizable: true
      }, context: {
        componentParent: this
      }
    };
  }

  onSave() {
    const data = [];
    this.gridOptions.api.forEachNode((rowNode) => {
      if (rowNode.data['Action List'] !== 'Total Selected Action') {
        const selectedId = [];
        let selectedpage = '';
        Object.entries(rowNode.data).forEach(
          (valuePair: any) => {
            if (typeof (valuePair[1]) === 'object') {
              if (selectedpage === '') {
                selectedpage = valuePair[1].actionId;
              }
              if (valuePair[1].value) {
                selectedId.push(valuePair[1].pageId);
              }
            }
          }
        );
        const objActionWise = {
          actionId: selectedpage,
          selectedPageId: selectedId
        };
        data.push(objActionWise);
      }
    });

    setTimeout(() => {
      this.userManagementService.setPagewiseActionReqParameter(this.orignalData, this.columnDefs, data);
    }, 500);
  }

  countSelectedPages(cell) {
    this.gridOptions.api.forEachNode((rowNode) => {
      if (rowNode.data['Action List'] === 'Total Selected Action') {
        if (cell.checked) {
          rowNode.data[cell.Col] += 1;
        } else {
          rowNode.data[cell.Col] -= 1;
        }
      }
    });
    this.gridOptions.api.refreshCells();
  }

  onClose(): void {
    this.closeModel.emit(false);
  }
}
