import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { User } from './forgot-password-model';
import { ForgotPasswordService } from './forgot-password.service';
import { Ng4LoadingSpinnerService } from '../core/components';
import { Router } from '@angular/router';
import { SharedService } from '../core/services';
import { GLOBAL } from '../app.globals';
import { CookieService } from 'ngx-cookie-service';
import { NotifyService } from '../common/notify.service';
import { AppQrCodeService } from '../core/components/app-qr-code/app-qr-code.service';
import { LogHelperService } from '../core/services/log-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  changepasswordform = new FormGroup({
    UserEmail: new FormControl('')
  });
  public modalRef: any;

  cookieValue;
  constructor(
    private loginService: ForgotPasswordService, private loadingService: Ng4LoadingSpinnerService,
    private router: Router, private sharedService: SharedService, private modalService: NgbModal,
    private appQrCodeService: AppQrCodeService, private logHelperService: LogHelperService,
    private cookieService: CookieService, private notifyService: NotifyService
  ) { }

  ngOnInit() {
  }

  public onClosePopup(): void {
    this.modalRef.close('click');
    // alert('Tejal');
  }
}
