import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

import * as XLSX from "xlsx";
import * as moment from "moment";
import { Ng4LoadingSpinnerService } from "src/app/core/components";
import { LaboursService } from "../labours/labours.service";
import { GridOptions } from "ag-grid-community";
import { AgInputEditCellRendererComponent } from "./ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component";
import { AgSelectEditCellRendererComponent } from "./ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component";
import { AgDatePickerEditCellRendererComponent } from "./ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component";
import { AgIconActionCellRendererComponent } from "./ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component";
import { CookieService } from "ngx-cookie-service";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import * as _ from "lodash";
import { TradeMasterParams } from "src/app/common/common-api.model";
import { CommonApiService } from "src/app/common/common-api.service";
import {forkJoin} from 'rxjs';

declare const $: any;

@Component({
  selector: "app-labour-import",
  templateUrl: "./labour-import.component.html",
  styleUrls: ["./labour-import.component.css"],
})
export class LabourImportComponent implements OnInit {
  @ViewChild("fileInput") fileInput;
  @Output() public save: EventEmitter<void>;
  @Input() isShowSingleButton: boolean;
  @Input() isShowAllButton: boolean;

  fileError = "";

  public closeModal: EventEmitter<any>;
  maindivclass = "content Overlapclass";
  userEmail = ""; // GetCokkiesValue(3);
  loginUserId = ""; // GetCokkiesValue(3);
  loading = false;
  showLabourImport = false;
  maxGridHeight = window.innerHeight - 210;
  fullHeight = `${window.innerHeight - 110}px`;
  fileObj = null;
  labourData = [];
  fileHref =
    "http://borlcmms.spikyarc.com/Angular/UserOperation/ImportLabour/Controller.js";

  // path = globalapipath.replace(globalapipath.substring(globalapipath.length - 5), '');
  // sampleexcelLink = path + '/Documents/LabourExcelFormate.xlsx';

  pincodeMap = {};
  pincodeNotFoundSet = new Set();
  isAdhaarMandatory = true;
  isBankMandatory = false;
  dateOptions = {
    showWeeks: false,
  };
  yesNoList = [
    {
      id: "Yes",
      text: "Yes",
    },
    {
      id: "No",
      text: "No",
    },
  ];

  labourForList = [
    {
      id: "General",
      text: "General",
    },
    {
      id: "Shout Down",
      text: "Shout Down",
    },
  ];

  nationalityList = [];

  wageCriteriaList = [
    {
      id: "Wage / Salary   ≥   21000.00/-   ( EC Policy Applicable)",
      text: "Wage / Salary   ≥   21000.00/-   ( EC Policy Applicable)",
    },
    {
      id: "Wage / Salary   <   21000.00/- (ESIC Applicable)",
      text: "Wage / Salary   <   21000.00/- (ESIC Applicable)",
    },
  ];
  pincodeList = [];
  genderList = [];
  maritalStatusList = [];
  qualificationList = [];
  qulificationStreamList = [];
  technicalQulificationList = [];
  bloodGroupList = [];
  skillList = [];
  tradeList = [];
  relationList = [];
  bankNameList = [];
  workSiteList = [];
  drivingLicenseType = [];
  workSiteCategoryList = [];
  castList = [];
  religionList = [];
  plantUnitDepartmentTree = [];
  validityPeriodList = [];
  workmanCategoryList = [];
  identityList = [];
  show = false;
  downloadSampleList = [
    {
      Name: "Gujarat",
      Url: "assets/sample/import/labour/Contractor_LabourMasterImportSheet_V3_Goa.xlsx",
    },
  ];
  globalSettingData = [];
  globalDataSampleDownload = [];

  userplantDetail = [];
  DownloadSampleExelShortCode = "LBRIMPEXL003";
  allPlantsUser = [];
  tradeMasterParams: TradeMasterParams;
  policeVerificationRequiredCode: any = {};
  referenceContactDetailsRequiredCode: any = {};
  emergencyContactDetailsRequiredCode: any = {};
  bankAccountDetailsRequiredCode: any = {};
  aadharCardOrPassportRequiredCode: any = {};
  drivingLicenseRequiredCode: any = {};
  driverTradeCode: any = {};
  driverTradeCodeArr = [];
  shortCodeWiseObjectMapping = {};

  onInputTextChange = (rowIndex, field, flieldVal = "") => {
    $("div[uib-tooltip-popup]").remove();
    if (field === "Qualification") {
      const val = String(this.labourData[rowIndex]["Qualification"]);
      let isQualificationIlletrate = false;
      for (let p = 0; p < this.qualificationList.length; p++) {
        if (this.qualificationList[p].id === val) {
          if (this.qualificationList[p].ShortCode === "GM332") {
            isQualificationIlletrate = true;
          }
          break;
        }
      }
      if (isQualificationIlletrate) {
        for (let p = 0; p < this.qulificationStreamList.length; p++) {
          if (this.qulificationStreamList[p].ShortCode === "GM815") {
            this.labourData[rowIndex]["QulificationStream"] = String(
              this.qulificationStreamList[p].id
            );
            break;
          }
        }

        for (let p = 0; p < this.technicalQulificationList.length; p++) {
          if (this.technicalQulificationList[p].ShortCode === "GM814") {
            this.labourData[rowIndex]["TechnicalQulification"] = String(
              this.technicalQulificationList[p].id
            );
            break;
          }
        }
      }
    }
    if (field === "Pincode") {
      this.labourData[rowIndex]["VillageCode"] = null;
      const val = this.labourData[rowIndex]["Pincode"] || "";
      if (val !== "") {
        for (let p = 0; p < this.pincodeList.length; p++) {
          if (this.pincodeList[p].text.toLowerCase() === val.toLowerCase()) {
            this.labourData[rowIndex]["VillageCode"] = this.pincodeList[p].id;
            break;
          }
        }
      }
    }

    if (field === "PermanentPincode") {
      this.labourData[rowIndex]["PermanentVillageCode"] = null;
      const val = this.labourData[rowIndex]["PermanentPincode"] || "";
      if (val !== "") {
        for (let p = 0; p < this.pincodeList.length; p++) {
          if (this.pincodeList[p].text.toLowerCase() === val.toLowerCase()) {
            this.labourData[rowIndex]["PermanentVillageCode"] =
              this.pincodeList[p].id;
            break;
          }
        }
      }
    }

    if (field === "RPincode") {
      this.labourData[rowIndex]["RVillageCode"] = null;
    }

    if (field === "EPincode") {
      this.labourData[rowIndex]["EVillageCode"] = null;
    }

    if (field === "Plant_Code") {
      const row = this.labourData[rowIndex];
      row.Unit_Code = null;
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === "Unit_Code") {
      const row = this.labourData[rowIndex];
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === "Department_Code") {
      const row = this.labourData[rowIndex];
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === "SectionPlantRelationId1") {
      const row = this.labourData[rowIndex];
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === "SectionPlantRelationId2") {
      const row = this.labourData[rowIndex];
      row.SectionPlantRelationId3 = null;
    }

    if (field === "SectionPlantRelationId3") {
      const row = this.labourData[rowIndex];
    }

    if (flieldVal !== "") {
      const row = this.labourData[rowIndex];
      if (flieldVal != "") {
        row[field] = flieldVal;
      }
    }
    const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    const rowNodes = [row]; // params needs an array
    // const params = {
    //   force: true,
    //   rowNodes: rowNodes,
    //   columns: [field]
    // };
    // this.gridOptions.api.refreshCells(params);
    // tslint:disable-next-line
    this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    // this.gridOptions.api.refreshCells({ rowNodes: rowNodes, columns: [field], force: true });
  };

  isColValid = (rowIndex, field) => {
    const returnObj = {
      isValid: true,
      requireValidation: false,
      msg: "",
    };
    let val = "";
    const requireValidationMessage = "Validation required";
    const passportRequiredInputs = ["PassportNumber","NameInPassport","PassportRegistraionDate","PassportExpireDate"].filter(item => (this.labourData[rowIndex][item] || '').trim() !== '').length;
    const adhaarCardRequiredInputs = ["AdhaarCardNumber", "NameInAdhaarCard"].filter(item => this.labourData[rowIndex][item].trim() !== '').length;
    const voterIdRequiredInputs = ["VoterIDNumber", "NameInVoterID"].filter(item => this.labourData[rowIndex][item].trim() !== '').length;
    const relationCardRequiredInputs = ["RationCardNumber", "NameInRationCard"].filter(item => this.labourData[rowIndex][item].trim() !== '').length;
    const drivingLicenseRequiredInputs = ["DrivingLicenseNumber","LicenseBatchNumber","NameInDrivingLicense","DrivingLicenseExpireDate"].filter(item => this.labourData[rowIndex][item].trim() !== '').length;
    const panCardRequiredInputs = ["PANNo","NameInPAN"].filter(item => this.labourData[rowIndex][item].trim() !== '').length;

    switch (field) {
      case "Plant_Code":
      case "Unit_Code":
      case "Department_Code":
      case "SectionPlantRelationId1":
      case "SectionPlantRelationId2":
      case "SectionPlantRelationId3":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = `Please select ${field}.`;
        }
        break;
      case "WageCriteria":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Wage Criteria.";
        }
        break;
      case "VendorCode":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Vendor Code.";
        }
        break;
      case "ContractorName":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Contractor Name.";
        }
        break;
      case "WONo":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter WO No.";
        }
        break;
      case "LabourDemandFor":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Labour Demand For.";
        }
        break;
      case "Nationality":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Nationality.";
        }
        break;
      case "WorkSite":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Work Site.";
        }
        break;
      case "WorkCategory":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Work Category.";
        }
        break;
      case "ValidityPeriod":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Validity Period.";
        }
        break;
      case "WorkmanCategory":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Associate Category.";
        }
        break;
      case "PassportNumber":
        if (this.aadharCardOrPassportRequiredCode.Value === "1") {
          val = this.labourData[rowIndex][field] || "";
          if (this.labourData[rowIndex]["Nationality"] === "569" && val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Passport Number.";
          }
        } else if (passportRequiredInputs > 0 && passportRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Passport related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      // case "NameInPassport":
      //   if (this.aadharCardOrPassportRequiredCode.Value === "1") {
      //     val = this.labourData[rowIndex][field] || "";
      //     if (this.labourData[rowIndex]["Nationality"] === "569" && val === "") {
      //       returnObj.isValid = false;
      //       returnObj.msg = "Please enter Name in Passport.";
      //     }
      //   } else if (passportRequiredInputs > 0 && passportRequiredInputs < 4) {
      //     returnObj.isValid = false;
      //     returnObj.msg = "Please enter Passport related all required fields.";
      //   } else {
      //     returnObj.isValid = true;
      //     returnObj.msg = "";
      //   }
      //   break;
      case "PassportRegistraionDate":
        if (this.aadharCardOrPassportRequiredCode.Value === "1") {
          val = this.labourData[rowIndex][field] || "";
          if (this.labourData[rowIndex]["Nationality"] === "569" && val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Passport Registraion Date.";
          }
        } else if (passportRequiredInputs > 0 && passportRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Passport related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "PassportExpireDate":
        if (this.aadharCardOrPassportRequiredCode.Value === "1") {
          val = this.labourData[rowIndex][field] || "";
          if (this.labourData[rowIndex]["Nationality"] === "569" && val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Passport Expire Date.";
          }
        } else if (passportRequiredInputs > 0 && passportRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Passport related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "FirstName":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter First Name.";
        }
        break;
      case "FatherHusbandName":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Father/Husband Name.";
        }
        break;
      case "Relation":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter relation.";
        }
        break;
      case "LastName":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Last Name.";
        }
        break;
      case "BirthDate":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Birth Date.";
        } else {
          const valArr = val.split("-");
          const forDate = `${valArr[2]}-${valArr[1]}-${valArr[0]}`;
          const years = moment().diff(forDate, "years");
          if (years < 18 || years > 65) {
            returnObj.isValid = false;
            returnObj.msg = "age should be between 18 and 65 years.";
          }
        }
        break;
      case "Gender":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter gender.";
        }
        break;
      case "maritalStatus":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Marital Status.";
        }
        break;
      case "PoliceVerificationDate":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          const passportNumber =
            this.labourData[rowIndex]["PassportNumber"] || "";
          const passportRegistraionDate =
            this.labourData[rowIndex]["PassportRegistraionDate"] || "";
          const passportExpireDate =
            this.labourData[rowIndex]["PassportExpireDate"] || "";
          if (
            passportNumber === "" ||
            (passportRegistraionDate === "" && passportExpireDate === "")
          ) {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Police Verification Date.";
          }
        }
        break;
      case "PermanentAddress":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Permanent Address.";
        }
        break;
      case "PermanentLandMark":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Permanent Land Mark.";
        }
        break;
      case "PermanentArea":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Permanent Area.";
        }
        break;
      case "PermanentPincode":
        this.labourData[rowIndex]["PermanentState"] = "";
        this.labourData[rowIndex]["PermanentDistct"] = "";
        this.labourData[rowIndex]["PermanentCity"] = "";
        this.labourData[rowIndex]["PermanentStateCode"] = "";
        this.labourData[rowIndex]["PermanentCityCode"] = "";
        this.labourData[rowIndex]["PermanentDistctCode"] = "";
        this.labourData[rowIndex]["PermanentLocationList"] = [];
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Permanent Pincode.";
          // } else if (this.labourData[rowIndex]['PermanentVillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg =
              " Pin code not found in master data. Please contract administrator or change pin code.";
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]["PermanentState"] =
            pincodeDetail.State_Name;
          this.labourData[rowIndex]["PermanentDistct"] =
            pincodeDetail.Distct_Name;
          this.labourData[rowIndex]["PermanentCity"] = pincodeDetail.City_Name;
          this.labourData[rowIndex]["PermanentStateCode"] =
            pincodeDetail.State_Code;
          this.labourData[rowIndex]["PermanentCityCode"] =
            pincodeDetail.City_Code;
          this.labourData[rowIndex]["PermanentDistctCode"] =
            pincodeDetail.Distct_Code;
          this.labourData[rowIndex]["PermanentLocationList"] =
            pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]["PermanentVillageCode"] =
              pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case "LocalAddress":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Local Address.";
        }
        break;
      case "LocalLandMark":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Local Land Mark.";
        }
        break;
      case "LocalArea":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Local Area.";
        }
        break;
      case "Pincode":
        this.labourData[rowIndex]["State"] = "";
        this.labourData[rowIndex]["Distct"] = "";
        this.labourData[rowIndex]["City"] = "";
        this.labourData[rowIndex]["StateCode"] = "";
        this.labourData[rowIndex]["CityCode"] = "";
        this.labourData[rowIndex]["DistctCode"] = "";
        this.labourData[rowIndex]["LocationList"] = [];
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Pincode.";
          // } else if (this.labourData[rowIndex]['VillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg =
              " Pin code not found in master data. Please contract administrator or change pin code.";
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]["State"] = pincodeDetail.State_Name;
          this.labourData[rowIndex]["Distct"] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]["City"] = pincodeDetail.City_Name;
          this.labourData[rowIndex]["StateCode"] = pincodeDetail.State_Code;
          this.labourData[rowIndex]["CityCode"] = pincodeDetail.City_Code;
          this.labourData[rowIndex]["DistctCode"] = pincodeDetail.Distct_Code;
          this.labourData[rowIndex]["LocationList"] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]["VillageCode"] =
              pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case "Qualification":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Qualification.";
        }
        break;
      case "QulificationStream":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Qulification Stream.";
        }
        break;
      case "TechnicalQulification":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Technical Qulification.";
        }
        break;
      case "Cast":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Cast.";
        }
        break;
      case "Religion":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Religion.";
        }
        break;
      case "AdhaarCardNumber":
        if (this.aadharCardOrPassportRequiredCode.Value === "1") {
          val = this.labourData[rowIndex][field] || "";
          if (this.labourData[rowIndex]["Nationality"] === "480" && val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Aadhaar Card Number.";
          }
        } else if (adhaarCardRequiredInputs > 0 && adhaarCardRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Aadhaar Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = '';
        }
        break;
      case "NameInAdhaarCard":
        if (this.aadharCardOrPassportRequiredCode.Value === "1") {
          val = this.labourData[rowIndex][field] || "";
          if (this.labourData[rowIndex]["Nationality"] === "480" && val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Name In Adhaar Card.";
          }
        } else if (adhaarCardRequiredInputs > 0 && adhaarCardRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Aadhaar Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "VoterIDNumber":
        if (voterIdRequiredInputs > 0 && voterIdRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Voter Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "NameInVoterID":
        if (voterIdRequiredInputs > 0 && voterIdRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Voter Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "RationCardNumber":
        if (relationCardRequiredInputs > 0 && relationCardRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Relation Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "NameInRationCard":
        if (relationCardRequiredInputs > 0 && relationCardRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Relation Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "PANNo":
        if (panCardRequiredInputs > 0 && panCardRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter PAN Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "NameInPAN":
        if (panCardRequiredInputs > 0 && panCardRequiredInputs < 2) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter PAN Card related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "BankName":
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Bank Name.";
          }
        }
        break;
      case "BankBranch":
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Bank Branch.";
          }
        }
        break;
      case "AccountNumber":
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Account Number.";
          }
        }
        break;
      case "IFSCCode":
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter IFSC Code.";
          }
        }
        break;
      case "NameAsPerBankRecord":
        if (this.isBankMandatory)  {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Name As Per Bank Record.";
          }
        }
        break;
      case "BloodGroup":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Blood Group.";
        }
        break;
      case "SkillType":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Skill Type.";
        }
        break;
      case "Trade":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Trade.";
        }
        break;
      case "DrivingLicenseNumber":
        val = this.labourData[rowIndex][field] || "";
        if ((this.drivingLicenseRequiredCode.Value === "1" && this.driverTradeCodeArr.includes(this.labourData[rowIndex]["Trade"].toString()))
          && val === ""
        ) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License Number.";
        } else if (drivingLicenseRequiredInputs > 0 && drivingLicenseRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "LicenseBatchNumber":
        val = this.labourData[rowIndex][field] || "";
        if ((this.drivingLicenseRequiredCode.Value === "1" && this.driverTradeCodeArr.includes(this.labourData[rowIndex]["Trade"].toString()))
          && val === ""
        ) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License Number.";
        } else if (drivingLicenseRequiredInputs > 0 && drivingLicenseRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "NameInDrivingLicense":
        val = this.labourData[rowIndex][field] || "";
        if ((this.drivingLicenseRequiredCode.Value === "1" && this.driverTradeCodeArr.includes(this.labourData[rowIndex]["Trade"].toString()))
          && val === ""
        ) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Name In Driving License.";
        } else if (drivingLicenseRequiredInputs > 0 && drivingLicenseRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "DrivingLicenseExpireDate":
        val = this.labourData[rowIndex][field] || "";
        if ((this.drivingLicenseRequiredCode.Value === "1" && this.driverTradeCodeArr.includes(this.labourData[rowIndex]["Trade"].toString()))
          && val === ""
        ) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License Expire Date.";
        } else if (drivingLicenseRequiredInputs > 0 && drivingLicenseRequiredInputs < 4) {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Driving License related all required fields.";
        } else {
          returnObj.isValid = true;
          returnObj.msg = "";
        }
        break;
      case "RPincode":
        this.labourData[rowIndex]["RState"] = "";
        this.labourData[rowIndex]["RDistct"] = "";
        this.labourData[rowIndex]["RCity"] = "";
        this.labourData[rowIndex]["RStateCode"] = "";
        this.labourData[rowIndex]["RCityCode"] = "";
        this.labourData[rowIndex]["RDistctCode"] = "";
        this.labourData[rowIndex]["RLocationList"] = [];
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          // returnObj.isValid = false;
          // returnObj.msg = 'Please enter Pincode.';
          // } else if (this.labourData[rowIndex]['RVillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg =
              " Pin code not found in master data. Please contract administrator or change pin code.";
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]["RState"] = pincodeDetail.State_Name;
          this.labourData[rowIndex]["RDistct"] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]["RCity"] = pincodeDetail.City_Name;
          this.labourData[rowIndex]["RStateCode"] = pincodeDetail.State_Code;
          this.labourData[rowIndex]["RCityCode"] = pincodeDetail.City_Code;
          this.labourData[rowIndex]["RDistctCode"] =  pincodeDetail.Distct_Code;
          this.labourData[rowIndex]["RLocationList"] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]["RVillageCode"] =
              pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case "EPincode":
        this.labourData[rowIndex]["EState"] = "";
        this.labourData[rowIndex]["EDistct"] = "";
        this.labourData[rowIndex]["ECity"] = "";
        this.labourData[rowIndex]["EStateCode"] = "";
        this.labourData[rowIndex]["ECityCode"] = "";
        this.labourData[rowIndex]["EDistctCode"] = "";
        this.labourData[rowIndex]["ELocationList"] = [];
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          // returnObj.isValid = false;
          // returnObj.msg = 'Please enter Pincode.';
          // } else if (this.labourData[rowIndex]['EVillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg =
              " Pin code not found in master data. Please contract administrator or change pin code.";
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]["EState"] = pincodeDetail.State_Name;
          this.labourData[rowIndex]["EDistct"] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]["ECity"] = pincodeDetail.City_Name;
          this.labourData[rowIndex]["EStateCode"] = pincodeDetail.State_Code;
          this.labourData[rowIndex]["ECityCode"] = pincodeDetail.City_Code;
          this.labourData[rowIndex]["EDistctCode"] = pincodeDetail.Distct_Code;
          this.labourData[rowIndex]["ELocationList"] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]["EVillageCode"] =
              pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case "PermanentVillageCode":
      case "VillageCode":
        val = this.labourData[rowIndex][field] || "";
        if (val === "") {
          returnObj.isValid = false;
          returnObj.msg = "Please enter Village.";
        }
        break;
      case "RVillageCode":
        val = this.labourData[rowIndex]["RPincode"] || "";
        if (val !== "") {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Village.";
          }
        }
        break;
      case "EVillageCode":
        val = this.labourData[rowIndex]["EPincode"] || "";
        if (val !== "") {
          val = this.labourData[rowIndex][field] || "";
          if (val === "") {
            returnObj.isValid = false;
            returnObj.msg = "Please enter Village.";
          }
        }
        break;
      default:
        break;
    }

    return returnObj;
  };

  isOneAdressProofAvailable = (rowIndex) => {
    const returnObj = {
      isValid: true,
      msg: "",
    };

    const panNo = this.labourData[rowIndex]["PANNo"] || "";
    const nameInPAN = this.labourData[rowIndex]["NameInPAN"] || "";
    if (panNo !== "" && nameInPAN !== "") {
      return returnObj;
    }

    const adhaarCardNumber =
      this.labourData[rowIndex]["AdhaarCardNumber"] || "";
    const nameInAdhaarCard =
      this.labourData[rowIndex]["NameInAdhaarCard"] || "";
    if (adhaarCardNumber !== "" && nameInAdhaarCard !== "") {
      return returnObj;
    }

    const voterIDNumber = this.labourData[rowIndex]["VoterIDNumber"] || "";
    const nameInVoterID = this.labourData[rowIndex]["NameInVoterID"] || "";
    if (voterIDNumber !== "" && nameInVoterID !== "") {
      return returnObj;
    }

    const rationCardNumber =
      this.labourData[rowIndex]["RationCardNumber"] || "";
    const nameInRationCard =
      this.labourData[rowIndex]["NameInRationCard"] || "";
    if (rationCardNumber !== "" && nameInRationCard !== "") {
      return returnObj;
    }

    const drivingLicenseNumber =
      this.labourData[rowIndex]["DrivingLicenseNumber"] || "";
    const nameInDrivingLicense =
      this.labourData[rowIndex]["NameInDrivingLicense"] || "";
    const drivingLicenseExpireDate =
      this.labourData[rowIndex]["DrivingLicenseExpireDate"] || "";
    if (
      drivingLicenseNumber !== "" &&
      nameInDrivingLicense !== "" &&
      drivingLicenseExpireDate !== ""
    ) {
      return returnObj;
    }

    const passportNumber = this.labourData[rowIndex]["PassportNumber"] || "";
    const passportRegistraionDate =
      this.labourData[rowIndex]["PassportRegistraionDate"] || "";
    const passportExpireDate =
      this.labourData[rowIndex]["PassportExpireDate"] || "";
    if (
      passportNumber !== "" &&
      passportRegistraionDate !== "" &&
      passportExpireDate !== ""
    ) {
      return returnObj;
    }

    returnObj.isValid = false;
    returnObj.msg = "Atleast one address proof detail is required.";
    return returnObj;
  };

  deleteRow = (rowIndex) => {
    this.labourData.splice(rowIndex, 1);
    this.gridOptions.api.setRowData(this.labourData);
  };

  getPlantList = (rowIndex, field) => {
    return this.plantUnitDepartmentTree.map((item) => {
      return { id: item.Plantcode0, text: item.Plant0 };
    });
  };

  getUnitList = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);

    return this.plantUnitDepartmentTree
      .find((item) => item.Plantcode0 === row.Plant_Code)
      .Values.map((item) => {
        return { id: item.Unitcode0, text: item.Unit0 };
      });
  };

  getDepartmentList = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);

    return this.plantUnitDepartmentTree
      .find((item) => item.Plantcode0 === row.Plant_Code)
      .Values.find((item) => item.Unitcode0 === row.Unit_Code)
      .Values.map((item) => {
        return { id: item.UnitDeptID0, text: item.Department0 };
      });
  };

  getSection1List = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);

    return this.plantUnitDepartmentTree
      .find((item) => item.Plantcode0 === row.Plant_Code)
      .Values.find((item) => item.Unitcode0 === row.Unit_Code)
      .Values.find((item) => item.UnitDeptID0 === row.Department_Code)
      .Values.map((item) => {
        return { id: item.Sectioncode0, text: item.SectionName0 };
      });
  };

  getSection2List = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (
      !row.Plant_Code ||
      !row.Unit_Code ||
      !row.Department_Code ||
      !row.SectionPlantRelationId1
    ) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);

    return this.plantUnitDepartmentTree
      .find((item) => item.Plantcode0 === row.Plant_Code)
      .Values.find((item) => item.Unitcode0 === row.Unit_Code)
      .Values.find((item) => item.UnitDeptID0 === row.Department_Code)
      .Values.find((item) => item.Sectioncode0 === row.SectionPlantRelationId1)
      .Values.map((item) => {
        return { id: item.Sectioncode1, text: item.SectionName1 };
      });
  };

  getSection3List = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (
      !row.Plant_Code ||
      !row.Unit_Code ||
      !row.Department_Code ||
      !row.SectionPlantRelationId1 ||
      !row.SectionPlantRelationId2
    ) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);
    row.SectionPlantRelationId2 = parseInt(row.SectionPlantRelationId2, 10);

    return this.plantUnitDepartmentTree
      .find((item) => item.Plantcode0 === row.Plant_Code)
      .Values.find((item) => item.Unitcode0 === row.Unit_Code)
      .Values.find((item) => item.UnitDeptID0 === row.Department_Code)
      .Values.find((item) => item.Sectioncode0 === row.SectionPlantRelationId1)
      .Values.find((item) => item.Sectioncode1 === row.SectionPlantRelationId2)
      .Values.map((item) => {
        return { id: item.LevelID2, text: item.SectionName2 };
      });
  };

  getLocationList = (rowIndex, field) => {
    const row = this.labourData[rowIndex];
    let list = [];
    switch (field) {
      case "PermanentVillageCode":
        list = row["PermanentLocationList"];
        break;
      case "VillageCode":
        list = row["LocationList"];
        break;
      case "RVillageCode":
        list = row["RLocationList"];
        break;
      case "EVillageCode":
        list = row["ELocationList"];
        break;
    }

    return list.map((item) => {
      return { id: item.Village_Code, text: item.Village_Name };
    });
  };

  setFocusInput = (rowIndex, field) => {
    // const colInput = $(`#importGrid .ag-body-container [row-index='${rowIndex}'] [col-id=''${field}'] input`);
    // const colSelect = $(`#importGrid .ag-body-container [row-index='${rowIndex}'] [col-id=''${field}'] select`);
    // const colLeftPinInput = $(`#importGrid .ag-pinned-left-cols-container [row-index='${rowIndex}'] [col-id=''${field}'] input`);
    // const colLeftPinSelect = $(`#importGrid .ag-pinned-left-cols-container [row-index='${rowIndex}'] [col-id='${field}'] select`);
    // if (colInput.length > 0) {
    //   colInput.focus();
    // } else if (colSelect.length > 0) {
    //   colSelect.focus();
    // } else if (colLeftPinInput.length > 0) {
    //   colLeftPinInput.focus();
    // } else if (colLeftPinSelect.length > 0) {
    //   colLeftPinSelect.focus();
    // }
  };
  columnDefs = [];
  gridOptions: GridOptions;
  constructor(
    private loadingService: Ng4LoadingSpinnerService,
    private cookieService: CookieService,
    private logHelperService: LogHelperService,
    private labourService: LaboursService,
    private commonApiService: CommonApiService
  ) {
    this.closeModal = new EventEmitter();
    this.save = new EventEmitter();
    // this.userEmail = String(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserEmail'));
    this.userEmail = String(localStorage.getItem("UserEmailId"));
    this.loginUserId = localStorage.getItem("UserID");

    this.tradeMasterParams = {
      UserId: localStorage.getItem("userId")
        ? parseInt(localStorage.getItem("userId"))
        : 0,
    };
  }

  ngOnInit() {
    this.getGlobalSettings();
    this.setColmuns();
    this.getUserplantDetail();
    this.loadGlobalSettings();
    this.loadSkillList();
    this.loadTradeList();
    // this.getPincodeList();
    // this.GetWorkSiteCategoryList();
    this.getShortCodeWiseMasterList();
    //this.getMasterList();
    this.getPlantUnitDepartmentTree();
    setTimeout(() => {
      // this.bindExcelData(null);
      this.gridOptions.api.sizeColumnsToFit();
    }, 5000);
  }
  setColmuns() {
    // tslint:disable-next-line
    this.columnDefs = [
      {
        headerName: "",
        field: "act",
        width: 30,
        minWidth: 30,
        cellRendererFramework: AgIconActionCellRendererComponent,
        cellRendererParams: {
          onClick: this.deleteRow,
          title: "Delete",
          width: 30,
          icon: "fa fa-times",
          cssClass: "row-delete-icon",
          fontSize: 24,
        },
        pinned: "left",
      },
      {
        headerName: "Company",
        children: [
          {
            headerName: "Plant",
            field: "Plant_Code",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getPlantList,
            },
          },
          {
            headerName: "Unit",
            field: "Unit_Code",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getUnitList,
            },
          },
          {
            headerName: "Department",
            field: "Department_Code",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getDepartmentList,
            },
          },
          {
            headerName: "Section",
            field: "SectionPlantRelationId1",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getSection1List,
            },
          },
          {
            headerName: "Sub-Section 1",
            field: "SectionPlantRelationId2",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getSection2List,
            },
          },
          {
            headerName: "Sub-Section 2",
            field: "SectionPlantRelationId3",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getSection3List,
            },
          },
        ],
      },
      {
        headerName: "Compliance Details",
        children: [
          {
            headerName: "Wage Criteria",
            field: "WageCriteria",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.wageCriteriaList,
            },
          },
          {
            headerName: "ESIC No",
            field: "ESICNo",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "PF No",
            field: "PFNo",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "UAN Number",
            field: "UANNumber",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "DGMS No",
            field: "DGMSNo",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Associate Demand For",
            field: "LabourDemandFor",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.labourForList,
            },
          },
          {
            headerName: "Nationality",
            field: "Nationality",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.nationalityList,
            },
          },
          {
            headerName: "Work Site",
            field: "WorkSite",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.workSiteList,
            },
          },
          {
            headerName: "Work Category",
            field: "WorkCategory",
            headerClass: "multi-header",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.workSiteCategoryList,
            },
          },
          {
            headerName: "Validity Period",
            field: "ValidityPeriod",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.validityPeriodList,
            },
          },
          {
            headerName: "Associate Category",
            field: "WorkmanCategory",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.workmanCategoryList,
            },
          },
        ],
      },
      {
        headerName: "Associate Information",
        children: [
          {
            headerName: "First Name",
            field: "FirstName",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Father/Husband Name",
            field: "FatherHusbandName",
            headerClass: "multi-header",
            width: 110,
            minWidth: 110,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Relation",
            field: "Relation",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.relationList,
            },
          },
          {
            headerName: "Last Name",
            field: "LastName",
            width: 100,
            minWidth: 100,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Birth Date",
            field: "BirthDate",
            width: 120,
            minWidth: 120,
            cellRendererFramework: AgDatePickerEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Gender",
            field: "Gender",
            width: 100,
            minWidth: 100,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.genderList,
            },
          },
          {
            headerName: "Marital Status",
            field: "MaritalStatus",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.maritalStatusList,
            },
          },
          {
            headerName: "Mobile",
            field: "Mobile",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Police Verification Date",
            field: "PoliceVerificationDate",
            width: 120,
            minWidth: 120,
            headerClass: "multi-header",
            cellRendererFramework: AgDatePickerEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "P-Address",
            field: "PermanentAddress",
            headerClass: "multi-header",
            width: 270,
            minWidth: 270,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "P-Land Mark",
            field: "PermanentLandMark",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "P-Area",
            field: "PermanentArea",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "P-Pincode",
            field: "PermanentPincode",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "P-Village",
            field: "PermanentVillageCode",
            width: 150,
            minWidth: 150,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getLocationList,
            },
          },
          {
            headerName: "P-State",
            field: "PermanentState",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "P-Distct",
            field: "PermanentDistct",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "P-City",
            field: "PermanentCity",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "L-Address",
            field: "LocalAddress",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "L-Land Mark",
            field: "LocalLandMark",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "L-Area",
            field: "LocalArea",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Pincode",
            field: "Pincode",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Village",
            field: "VillageCode",
            width: 150,
            minWidth: 150,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getLocationList,
            },
          },
          {
            headerName: "State",
            field: "State",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "Distct",
            field: "Distct",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "City",
            field: "City",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "Qualification",
            field: "Qualification",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.qualificationList,
            },
          },
          {
            headerName: "Q_School/College",
            field: "Q_SchoolCollege",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Q_Year of Passing",
            field: "Q_YearofPassing",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Q_Class Or Grade",
            field: "Q_ClassOrGrade",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Q_Medium of Instruction",
            field: "Q_MediumofInstruction	",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Q_Specialized Course",
            field: "Q_SpecializedCourse",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Q_Percentage",
            field: "Q_Percentage",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Qulification Stream",
            field: "QulificationStream",
            width: 270,
            minWidth: 270,
            headerClass: "multi-header",
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.qulificationStreamList,
            },
          },
          {
            headerName: "QS_School/College",
            field: "QS_SchoolCollege",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "QS_Year of Passing",
            field: "QS_YearofPassing",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "QS_Class Or Grade",
            field: "QS_ClassOrGrade",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "QS_Medium of Instruction",
            field: "QS_MediumofInstruction	",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "QS_Specialized Course",
            field: "QS_SpecializedCourse",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "QS_Percentage",
            field: "QS_Percentage",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Technical Qulification",
            field: "TechnicalQulification",
            width: 270,
            minWidth: 270,
            headerClass: "multi-header",
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.technicalQulificationList,
            },
          },
          {
            headerName: "TQ_School/College",
            field: "TQ_SchoolCollege",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "TQ_Year of Passing",
            field: "TQ_YearofPassing",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "TQ_Class Or Grade",
            field: "TQ_ClassOrGrade",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "TQ_Medium of Instruction",
            field: "TQ_MediumofInstruction	",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "TQ_Specialized Course",
            field: "TQ_SpecializedCourse",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "TQ_Percentage",
            field: "TQ_Percentage",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Cast",
            field: "Cast",
            width: 270,
            minWidth: 270,
            headerClass: "multi-header",
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.castList,
            },
          },
          {
            headerName: "Religion",
            field: "Religion",
            width: 270,
            minWidth: 270,
            headerClass: "multi-header",
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.religionList,
            },
          },
        ],
      },
      {
        headerName: "Associate Identity Details",
        children: [
          {
            headerName: "PAN No",
            field: "PANNo",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "NameIn PAN",
            field: "NameInPAN",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Adhaar Card Number",
            field: "AdhaarCardNumber",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Name In Adhaar Card",
            field: "NameInAdhaarCard",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Voter-IDNumber",
            field: "VoterIDNumber",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Name In Voter-ID",
            field: "NameInVoterID",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Ration Card Number",
            field: "RationCardNumber",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Name In Ration Card",
            field: "NameInRationCard",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "License Batch Number",
            field: "LicenseBatchNumber",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Driving License Type",
            field: "DrivingLicenseType",
            width: 270,
            minWidth: 270,
            headerClass: "multi-header",
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.drivingLicenseType,
            },
          },
          {
            headerName: "Driving License Number",
            field: "DrivingLicenseNumber",
            headerClass: "multi-header",
            width: 130,
            minWidth: 130,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Name In Driving License",
            field: "NameInDrivingLicense",
            headerClass: "multi-header",
            width: 150,
            minWidth: 150,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Driving License Expire Date",
            field: "DrivingLicenseExpireDate",
            headerClass: "multi-header",
            width: 120,
            minWidth: 120,
            cellRendererFramework: AgDatePickerEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Passport Number",
            field: "PassportNumber",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Name In Passport",
            field: "NameInPassport",
            width: 200,
            minWidth: 200,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Passport Registraion Date",
            field: "PassportRegistraionDate",
            headerClass: "multi-header",
            width: 120,
            minWidth: 120,
            cellRendererFramework: AgDatePickerEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Passport Expire Date",
            field: "PassportExpireDate",
            width: 120,
            minWidth: 120,
            cellRendererFramework: AgDatePickerEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
        ],
      },
      {
        headerName: "Associate Bank Details",
        children: [
          {
            headerName: "Bank Name",
            field: "BankName",
            width: 300,
            minWidth: 200,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.bankNameList,
            },
          },
          {
            headerName: "Bank Branch",
            field: "BankBranch",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Account Number",
            field: "AccountNumber",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "IFSC Code",
            field: "IFSCCode",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Name As Per Bank Record",
            field: "NameAsPerBankRecord",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
        ],
      },
      {
        headerName: "",
        children: [
          {
            headerName: "Blood Group",
            field: "BloodGroup",
            width: 100,
            minWidth: 100,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.bloodGroupList,
            },
          },
          {
            headerName: "Skill Type",
            field: "SkillType",
            width: 100,
            minWidth: 100,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.skillList,
            },
          },
          {
            headerName: "Trade",
            field: "Trade",
            width: 270,
            minWidth: 270,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataList: () => this.tradeList,
            },
          },
        ],
      },
      {
        headerName: "Referance Contact Details",
        children: [
          {
            headerName: "Full Name",
            field: "RFullName",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Relation",
            field: "RRelation",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Address",
            field: "RAddress",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Land Mark",
            field: "RLandMark",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Area",
            field: "RArea",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Pincode",
            field: "RPincode",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Village",
            field: "RVillageCode",
            width: 150,
            minWidth: 150,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getLocationList,
            },
          },
          {
            headerName: "State",
            field: "RState",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "Distct",
            field: "RDistct",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "City",
            field: "RCity",
            width: 100,
            minWidth: 100,
          },
        ],
      },
      {
        headerName: "Emergency Contact Details",
        children: [
          {
            headerName: "Full Name",
            field: "EFullName",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Relation",
            field: "ERelation",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Address",
            field: "EAddress",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Land Mark",
            field: "ELandMark",
            headerClass: "multi-header",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Area",
            field: "EArea",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Pincode",
            field: "EPincode",
            width: 100,
            minWidth: 100,
            cellRendererFramework: AgInputEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
            },
          },
          {
            headerName: "Village",
            field: "EVillageCode",
            width: 150,
            minWidth: 150,
            isShowAllButton: this.isShowAllButton,
            isShowSingleButton: this.isShowSingleButton,
            cellRendererFramework: AgSelectEditCellRendererComponent,
            cellRendererParams: {
              onInputTextChange: this.onInputTextChange,
              isColValid: this.isColValid,
              dataListByIndex: this.getLocationList,
            },
          },
          {
            headerName: "State",
            field: "EState",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "Distct",
            field: "EDistct",
            width: 100,
            minWidth: 100,
          },
          {
            headerName: "City",
            field: "ECity",
            width: 100,
            minWidth: 100,
          },
        ],
      },

      // { headerName: 'Vendor Code', field: 'VendorCode', width: 100, minWidth: 100,
      // cellRenderer: this.textCellRenderer, pinned: 'left' },
      // { headerName: 'Contractor Name', field: 'ContractorName', width: 100, minWidth: 100,
      // cellRenderer: this.textCellRenderer, headerClass: 'multi-header', pinned: 'left' },
      // { headerName: 'WO No', field: 'WONo', width: 100, minWidth: 100,
      // cellRenderer: this.textCellRenderer, pinned: 'left' },
      // { headerName: 'LIC No', field: 'LICNo', width: 100, minWidth: 100,
      //   cellRendererFramework: AgInputEditCellRendererComponent,
      // cellRendererParams: {
      //   onInputTextChange: this.onInputTextChange,
      //   isColValid: this.isColValid,
      // }
      // },
      // { headerName: 'ESIC Applicable', field: 'ESICApplicable', width: 100, minWidth: 100, headerClass: 'multi-header',
      // cellRenderer: this.selectCellRenderer, cellRendererParams: { dataList: this.yesNoList } },
      // { headerName: 'ESIC No', field: 'ESICNo', width: 100, minWidth: 100,
      // cellRendererFramework: AgInputEditCellRendererComponent,
      //   cellRendererParams: {
      //   onInputTextChange: this.onInputTextChange,
      //     isColValid: this.isColValid,
      //       }
      //     },
      // { headerName: 'WC No', field: 'WCNo', width: 100, minWidth: 100,
      // cellRendererFramework: AgInputEditCellRendererComponent,
      //   cellRendererParams: {
      //   onInputTextChange: this.onInputTextChange,
      //     isColValid: this.isColValid,
      //       }
      //     },
    ];

    // tslint:disable-next-line
    this.gridOptions = {
      context: {
        componentParent: this,
      },
      columnDefs: this.columnDefs,
      rowData: this.labourData,
      headerHeight: 39,
      rowHeight: 39,
      onGridReady: (params) => {
        // this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      // onGridReady: (params) => {
      //   params.api.sizeColumnsToFit();
      //   //params.api.setRowData(this.data.ContractorVendor);
      // },
      // onGridSizeChanged: (params) => {
      //   params.api.sizeColumnsToFit();
      // },
      onCellFocused: (params) => {
        const rowIndex = params.rowIndex;
        if (params.column) {
          const field = params.column.getColId();
          this.setFocusInput(rowIndex, field);
        }
      },
      getRowStyle: (params) => {
        let isValid = true;
        const keys = Object.keys(params.data);
        for (let j = 0; j < keys.length; j++) {
          const validObj = this.isColValid(params.node.rowIndex, keys[j]);
          if (!validObj.isValid) {
            isValid = false;
            break;
          }
        }
        const validObj = this.isOneAdressProofAvailable(params.node.rowIndex);
        if (!validObj.isValid) {
          isValid = false;
        }
        if (isValid) {
          this.labourData[params.node.rowIndex]["rowValid"] = true;
          return { background: "#33CC00 !important" };
        }

        this.labourData[params.node.rowIndex]["rowValid"] = false;
        return { background: "#FFFF95 !important" };
      },
      pagination: false,
      paginationPageSize: 10,
      suppressMovableColumns: true,
      // angularCompileRows: true
    };
  }
  public getPlantUnitDepartmentTree() {
    // this.labourService.getDepartmentPlantUnitSectionTree()
    //   .subscribe((res) => {
    //     const companyData = res.find(item => item.Company_Code === 101);
    //     this.plantUnitDepartmentTree = companyData ? companyData.PlantTree : companyData;
    //   });

    this.labourService
      .getDepartmentPlantUnitSectionData()
      .subscribe((response) => {
        this.plantUnitDepartmentTree = response;
      });
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  onFileChange($event) {
    this.validateFile($event.target);
  }

  onUploadFile() {
    this.getConractor();
    // this.bindExcelData(null);
    if (this.validateFile(this.fileInput.nativeElement)) {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        if (e.target) {
          /* read workbook */
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          /* grab first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* save data */
          const fileContent = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            blankrows: false,
            defval: null,
            raw: false,
          });

          if (!fileContent) {
            this.fileError = "Please select a file to upload.";
            return;
          }
          const labourData = [];
          const colgrps = fileContent.shift();
          const keys: any = fileContent.shift();
          const firstTabId = "";
          const data = fileContent.map((e) => {
            const obj = {};
            for (let i = 0; i < keys.length; i++) {
              obj[keys[i]] = e[i];
            }
            return obj;
          });

          if (data.length === 0) {
            this.loadingService.hide();
            this.fileError =
              "AtLeast one record should be availabe in file to upload a file.";
            return;
          }
          if (data.length > Number(this.globalSettingData[0].Value)) {
            this.loadingService.hide();
            this.fileError = `Maximum ${Number(
              this.globalSettingData[0].Value
            )} Records is allowed to import.`;
            return;
          }

          // this.loadingService.hide();
          localStorage.setItem("xlsx_data", JSON.stringify(data));
          this.bindExcelData(this.removeSortCharFromKeys(data));
        }
      };
      this.loadingService.show();
      setTimeout(() => {
        reader.readAsBinaryString(this.fileInput.nativeElement.files[0]);
      }, 1000);
    }
  }

  validateFile(target) {
    this.fileError = "";
    if (target.files.length === 0) {
      this.fileError = "Please select Excel file";
      target.value = "";
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = "Cannot use multiple files";
      target.value = "";
      return false;
    }

    if (
      !(
        target.files[0].name.endsWith(".xlsx") ||
        target.files[0].name.endsWith(".xls")
      )
    ) {
      this.fileError = "Invalid File format, please select Excel file only.";
      target.value = "";
      return false;
    }

    return true;
  }

  removeSortCharFromKeys(data) {
    const newData = [];
    data.forEach((obj) => {
      const newObj = Object.keys(obj).reduce(
        (result, key) => {
          const newKey = key.replace("↓", "").trim();
          result[newKey] = obj[key];
          return result;
        },
        Array.isArray(obj) ? [] : {}
      );
      newData.push(newObj);
    });

    return newData;
  }

  bindExcelData(data) {
    const data1 = [
      {
        Plant: "1111",
      },
    ];
    const data2 = [
      {
        Plant: "Goa 1",
        Unit: "Unit-1",
        Department: "Accounts",
        Section: "Packing",
        "Sub-Section 1": "FFS",
        "Sub-Section 2": "Line 2",

        // Plant: 'Goa 5',
        // Unit: 'CMN',
        // Department: 'Accounts',
        // 'Section 2': 'Accounts',
        // 'Sub-Section 1': 'Accounts',
        // 'Sub-Section 2': 'Accounts',

        "Wage Criteria": "Wage / Salary   <   21000.00/- (ESIC Applicable)",
        "Labour Demand For": "General",
        Nationality: "Indian ",
        "Work Site": "External Perimeter",
        "Work Category": "Casual Worker",
        "Validity Period": "Casual worker < 3 months",
        "Associate Category": "Contractor Casual Labour",
        "First Name": "TestSP2",
        "Father/Husband Name": "TestSP2",
        Relation: "Father",
        "Last Name": "xyz",
        "Birth Date": "1/1/92",
        Gender: "Male",
        "Marital Status": "MARRIED",
        "Police Verification Date": "1/1/20",
        "Permanent Address": "Bina",
        "Permanent Land Mark": "Bina",
        "Permanent Area": "Bina",
        "Permanent Pincode": "470113",
        "Local Address": "Bina",
        "Local Land Mark": "Bina",
        "Local Area": "Bina",
        Pincode: "470113",
        Qualification: "Other",
        "Qulification Stream": "Other",
        "Technical Qulification": "Other",
        Cast: "General",
        Religion: "Hindu",
        "PAN No": "PAN1234567",
        "NameIn PAN": "Test Pan",
        "Adhaar Card Number": "423412341231",
        "Name In Adhaar Card": "RAMKRISHAN MEENa",
        "Voter-IDNumber": "VOTER123",
        "Name In Voter-ID": "Test Voter",
        "Ration Card Number": "RATION123",
        "Name In Ration Card": "Test Ration",
        "License Batch Number": "B2020",
        "Driving License Number": "LIC123",
        "Name In Driving License": "Test Lice",
        "Driving License Expire Date": "1/1/2025",
        "Passport Number": "PASS123",
        "Passport Registraion Date": "1/1/2017",
        "Passport Expire Date": "1/1/2025",
        "Bank Name": "Axis Bank",
        "Bank Branch": "Bank Ahmedabad",
        "Account Number": "1234567890",
        "IFSC Code": "IFC1234",
        "Name As Per Bank Record": "Test Bank",
        "Blood Group": "B+",
        "Skill Type": "Semi Skilled",
        Trade: "Helper",
        "UAN Number": "UAN123",
        "DGMS No": "DG123",
        "PF No": "PF123",
        Mobile: "9876543210",
        "R-Full Name": "R name",
        "R-Relation": "Brother",
        "R-Address": "R address",
        "R-Land Mark": "R landmark",
        "R-Area": "R area",
        "R-Country": "R country",
        "R-State": "R state",
        "R-District": "R district",
        "R-City": "R city",
        "R-PinCode": "470113",
        "E-Full Name": "E name",
        "E-Relation": "Brother",
        "E-Address": "E address",
        "E-Land Mark": "E landmark",
        "E-Area": "E area",
        "E-Country": "E country",
        "E-State": "E state",
        "E-District": "E district",
        "E-City": "E city",
        "E-PinCode": "470113",
      },
    ];

    const importData = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element && Object.keys(element).length > 0) {
        let dob = (element["Birth Date"] || "").toString().trim();
        dob = moment(dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (dob === "Invalid date") {
          dob = "";
        }
        let dled = (element["Driving License Expire Date"] || "")
          .toString()
          .trim();
        dled = moment(dled, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (dled === "Invalid date") {
          dled = "";
        }

        let pvDate = (element["Police Verification Date"] || "")
          .toString()
          .trim();
        pvDate = moment(pvDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (pvDate === "Invalid date") {
          pvDate = "";
        }

        let prDate = (element["Passport Registraion Date"] || "")
          .toString()
          .trim();
        prDate = moment(prDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (prDate === "Invalid date") {
          prDate = "";
        }

        let peDate = (element["Passport Expire Date"] || "").toString().trim();
        peDate = moment(peDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        if (peDate === "Invalid date") {
          peDate = "";
        }

        let gender = (element["Gender"] || "").toString().trim();
        let genderFound = false;
        if (gender !== "") {
          for (let p = 0; p < this.genderList.length; p++) {
            if (this.genderList[p].text === gender) {
              gender = String(this.genderList[p].id);
              genderFound = true;
              break;
            }
          }
        }
        if (!genderFound) {
          gender = "";
        }

        let maritalStatus = (element["Marital Status"] || "").toString().trim();
        let maritalStatusFound = false;
        if (maritalStatus !== "") {
          for (let p = 0; p < this.maritalStatusList.length; p++) {
            if (
              this.maritalStatusList[p].text.toLowerCase() ===
              maritalStatus.toLowerCase()
            ) {
              maritalStatus = String(this.maritalStatusList[p].id);
              maritalStatusFound = true;
              break;
            }
          }
        }
        if (!maritalStatusFound) {
          maritalStatus = "";
        }

        let relation = (element["Relation"] || "").toString().trim();
        let relationFound = false;
        if (relation !== "") {
          for (let p = 0; p < this.relationList.length; p++) {
            if (
              this.relationList[p].text.toLowerCase() === relation.toLowerCase()
            ) {
              relation = String(this.relationList[p].id);
              relationFound = true;
              break;
            }
          }
        }
        if (!relationFound) {
          relation = "";
        }

        let qualification = (element["Qualification"] || "").toString().trim();
        let qualificationFound = false;
        let isQualificationIlletrate = false;
        if (qualification !== "") {
          for (let p = 0; p < this.qualificationList.length; p++) {
            if (
              this.qualificationList[p].text.toLowerCase() ===
              qualification.toLowerCase()
            ) {
              qualification = String(this.qualificationList[p].id);
              if (this.qualificationList[p].ShortCode === "GM332") {
                isQualificationIlletrate = true;
              }
              qualificationFound = true;
              break;
            }
          }
        }
        if (!qualificationFound) {
          qualification = "";
        }

        let qulificationStream = (element["Qulification Stream"] || "")
          .toString()
          .trim();
        let qulificationStreamFound = false;
        if (isQualificationIlletrate) {
          for (let p = 0; p < this.qulificationStreamList.length; p++) {
            if (this.qulificationStreamList[p].ShortCode === "GM815") {
              qulificationStream = String(this.qulificationStreamList[p].id);
              qulificationStreamFound = true;
              break;
            }
          }
        } else {
          if (qulificationStream !== "") {
            for (let p = 0; p < this.qulificationStreamList.length; p++) {
              if (
                this.qulificationStreamList[p].text.toLowerCase() ===
                qulificationStream.toLowerCase()
              ) {
                qulificationStream = String(this.qulificationStreamList[p].id);
                qulificationStreamFound = true;
                break;
              }
            }
          }
        }
        if (!qulificationStreamFound) {
          qulificationStream = "";
        }

        let technicalQulification = (element["Technical Qulification"] || "")
          .toString()
          .trim();
        let technicalQulificationFound = false;
        if (isQualificationIlletrate) {
          for (let p = 0; p < this.technicalQulificationList.length; p++) {
            if (this.technicalQulificationList[p].ShortCode === "GM814") {
              technicalQulification = String(
                this.technicalQulificationList[p].id
              );
              technicalQulificationFound = true;
              break;
            }
          }
        } else {
          if (technicalQulification !== "") {
            for (let p = 0; p < this.technicalQulificationList.length; p++) {
              if (
                this.technicalQulificationList[p].text.toLowerCase() ===
                technicalQulification.toLowerCase()
              ) {
                technicalQulification = String(
                  this.technicalQulificationList[p].id
                );
                technicalQulificationFound = true;
                break;
              }
            }
          }
        }
        if (!technicalQulificationFound) {
          technicalQulification = "";
        }

        let cast = (element["Cast"] || "").toString().trim();
        let castFound = false;
        if (cast !== "") {
          for (let p = 0; p < this.castList.length; p++) {
            if (this.castList[p].text === cast) {
              cast = String(this.castList[p].id);
              castFound = true;
              break;
            }
          }
        }
        if (!castFound) {
          cast = "";
        }

        let religion = (element["Religion"] || "").toString().trim();
        let religionFound = false;
        if (religion !== "") {
          for (let p = 0; p < this.religionList.length; p++) {
            if (this.religionList[p].text === religion) {
              religion = String(this.religionList[p].id);
              religionFound = true;
              break;
            }
          }
        }
        if (!religionFound) {
          religion = "";
        }

        let bankName = (element["Bank Name"] || "").toString().trim();
        let bankNameFound = false;
        if (bankName !== "") {
          for (let p = 0; p < this.bankNameList.length; p++) {
            if (
              this.bankNameList[p].text.toLowerCase() === bankName.toLowerCase()
            ) {
              bankName = String(this.bankNameList[p].id);
              bankNameFound = true;
              break;
            }
          }
        }
        if (!bankNameFound) {
          bankName = "";
        }

        let bloodGroup = (element["Blood Group"] || "").toString().trim();
        let bloodGroupFound = false;
        if (bloodGroup !== "") {
          for (let p = 0; p < this.bloodGroupList.length; p++) {
            if (
              this.bloodGroupList[p].text.toLowerCase() ===
              bloodGroup.toLowerCase()
            ) {
              bloodGroup = String(this.bloodGroupList[p].id);
              bloodGroupFound = true;
              break;
            }
          }
        }
        if (!bloodGroupFound) {
          bloodGroup = "";
        }

        let skill = (element["Skill Type"] || "").toString().trim();
        let skillFound = false;
        if (skill !== "") {
          for (let p = 0; p < this.skillList.length; p++) {
            if (this.skillList[p].text.toLowerCase() === skill.toLowerCase()) {
              skill = String(this.skillList[p].id);
              skillFound = true;
              break;
            }
          }
        }
        if (!skillFound) {
          skill = "";
        }

        let trade = (element["Trade"] || "").toString().trim();

        const matchedTrade = trade
          ? this.tradeList.find((item) => item.text.toLowerCase() === trade.toLowerCase())
          : null;

        trade = matchedTrade ? String(matchedTrade.id) : "";

        let workSite = (element["Work Site"] || "").toString().trim();
        let workSiteFound = false;
        if (workSite !== "") {
          for (let p = 0; p < this.workSiteList.length; p++) {
            if (
              this.workSiteList[p].text.toLowerCase() === workSite.toLowerCase()
            ) {
              workSite = String(this.workSiteList[p].id);
              workSiteFound = true;
              break;
            }
          }
        }
        if (!workSiteFound) {
          workSite = "";
        }

        let workCategory = (element["Work Category"] || "").toString().trim();
        let workCategoryFound = false;
        if (workCategory !== "") {
          for (let p = 0; p < this.workSiteCategoryList.length; p++) {
            if (
              this.workSiteCategoryList[p].text.toLowerCase() ===
              workCategory.toLowerCase()
            ) {
              workCategory = String(this.workSiteCategoryList[p].id);
              workCategoryFound = true;
              break;
            }
          }
        }
        if (!workCategoryFound) {
          workCategory = "";
        }

        let nationality = (element["Nationality"] || "").toString().trim();
        let nationalityFound = false;
        if (nationality !== "") {
          for (let p = 0; p < this.nationalityList.length; p++) {
            if (
              this.nationalityList[p].text.toLowerCase() ===
              nationality.toLowerCase()
            ) {
              nationality = String(this.nationalityList[p].id);
              nationalityFound = true;
              break;
            }
          }
        }
        if (!nationalityFound) {
          nationality = "";
        }

        let validityPeriod = (element["Validity Period"] || "")
          .toString()
          .trim();
        if (validityPeriod !== "") {
          const validityPeriodFound = this.validityPeriodList.find(
            (item) => item.text.toLowerCase() === validityPeriod.toLowerCase()
          );
          if (validityPeriodFound) {
            validityPeriod = validityPeriodFound.id;
          } else {
            validityPeriod = "";
          }
        }

        let workmanCategory = (element["Associate Category"] || "")
          .toString()
          .trim();
        if (workmanCategory !== "") {
          const workmanCategoryFound = this.workmanCategoryList.find(
            (item) => item.text.toLowerCase() === workmanCategory.toLowerCase()
          );
          if (workmanCategoryFound) {
            workmanCategory = workmanCategoryFound.id;
          } else {
            workmanCategory = "";
          }
        }

        const permanentPincode = (element["Permanent Pincode"] || "")
          .toString()
          .trim();
        let permanentVillageCode = null;
        if (permanentPincode !== "") {
          for (let p = 0; p < this.pincodeList.length; p++) {
            if (
              this.pincodeList[p].text.toLowerCase() ===
              permanentPincode.toLowerCase()
            ) {
              permanentVillageCode = this.pincodeList[p].id;
              break;
            }
          }
        }

        const pincode = (element["Pincode"] || "").toString().trim();
        let villageCode = null;
        if (pincode !== "") {
          for (let p = 0; p < this.pincodeList.length; p++) {
            if (
              this.pincodeList[p].text.toLowerCase() === pincode.toLowerCase()
            ) {
              villageCode = this.pincodeList[p].id;
              break;
            }
          }
        }

        const plant = (element["Plant"] || "").toString().trim();
        const unit = (element["Unit"] || "").toString().trim();
        const department = (element["Department"] || "").toString().trim();
        const section1 = (element["Section"] || "").toString().trim();
        const section2 = (element["Sub-Section 1"] || "").toString().trim();
        const section3 = (element["Sub-Section 2"] || "").toString().trim();
        const licenseType = (element["Driving License Type"] || "").toString().trim();
        let drivingLicenseType = '' 
        this.drivingLicenseType.forEach((_type:any)=>{
          if(licenseType === _type.text){
            drivingLicenseType = _type.id + '';
          }
        });


        let plantCode = null;
        let unitCode = null;
        let departmentCode = null;
        let sectionPlantRelationId1 = null;
        let sectionPlantRelationId2 = null;
        let sectionPlantRelationId3 = null;

        const plantFound = this.plantUnitDepartmentTree.find(
          (item) => item.Plant0.toLowerCase() === plant.toLowerCase()
        );
        if (plantFound) {
          plantCode = plantFound.Plantcode0;

          const unitFound = plantFound.Values.find(
            (item) => item.Unit0.toLowerCase() === unit.toLowerCase()
          );
          if (unitFound) {
            unitCode = unitFound.Unitcode0;

            const departmentFound = unitFound.Values.find(
              (item) =>
                item.Department0.toLowerCase() === department.toLowerCase()
            );
            if (departmentFound) {
              departmentCode = departmentFound.UnitDeptID0;

              const section1Found = departmentFound.Values.find(
                (item) =>
                  item.SectionName0.toLowerCase() === section1.toLowerCase()
              );
              if (section1Found) {
                sectionPlantRelationId1 = section1Found.Sectioncode0;

                const section2Found = section1Found.Values.find(
                  (item) =>
                    item.SectionName1.toLowerCase() === section2.toLowerCase()
                );
                if (section2Found) {
                  sectionPlantRelationId2 = section2Found.Sectioncode1;

                  const section3Found = section2Found.Values.find(
                    (item) =>
                      item.SectionName2.toLowerCase() === section3.toLowerCase()
                  );
                  if (section3Found) {
                    sectionPlantRelationId3 = section3Found.LevelID2;
                  }
                }
              }
            }
          }
        }

        importData.push({
          Login_UserId: this.loginUserId,
          Plant_Code: plantCode,
          Unit_Code: unitCode,
          Department_Code: departmentCode,
          SectionPlantRelationId1: sectionPlantRelationId1,
          SectionPlantRelationId2: sectionPlantRelationId2,
          SectionPlantRelationId3: sectionPlantRelationId3,

          WageCriteria: (element["Wage Criteria"] || "").toString().trim(),
          LabourDemandFor: (element["Labour Demand For"] || "")
            .toString()
            .trim(),
          Nationality: nationality,
          WorkSite: workSite,
          WorkCategory: workCategory,

          ValidityPeriod: validityPeriod,
          WorkmanCategory: workmanCategory,

          FirstName: (element["First Name"] || "").toString().trim(),
          FatherHusbandName: (element["Father/Husband Name"] || "")
            .toString()
            .trim(),
          // tslint:disable-next-line
          Relation: relation,
          LastName: (element["Last Name"] || "").toString().trim(),
          BirthDate: dob,
          Gender: gender,
          MaritalStatus: maritalStatus,
          PoliceVerificationDate: pvDate,
          PermanentAddress: (element["Permanent Address"] || "")
            .toString()
            .trim(),
          PermanentLandMark: (element["Permanent Land Mark"] || "")
            .toString()
            .trim(),
          PermanentArea: (element["Permanent Area"] || "").toString().trim(),
          PermanentPincode: (element["Permanent Pincode"] || "")
            .toString()
            .trim(),
          PermanentVillageCode: null,

          LocalAddress: (element["Local Address"] || "").toString().trim(),
          LocalLandMark: (element["Local Land Mark"] || "").toString().trim(),
          LocalArea: (element["Local Area"] || "").toString().trim(),
          Pincode: (element["Pincode"] || "").toString().trim(),
          VillageCode: null,

          Qualification: qualification,
          Q_SchoolCollege : (element['Q_School/College'] || '').toString().trim(),
          Q_YearofPassing : (element['Q_Year of Passing'] || '').toString().trim(),
          Q_ClassOrGrade : (element['Q_Class Or Grade'] || '').toString().trim(),
          Q_MediumofInstruction : (element['Q_Medium of Instruction'] || element['Q_Medium of Instruction '] || '').toString().trim(),
          Q_SpecializedCourse : (element['Q_Specialized Course'] || '').toString().trim(),
          Q_Percentage : (element['Q_Percentage'] || '').toString().trim(),
          QulificationStream: qulificationStream,
          QS_SchoolCollege : (element['QS_School/College'] || '').toString().trim(),
          QS_YearofPassing : (element['QS_Year of Passing'] || '').toString().trim(),
          QS_ClassOrGrade : (element['QS_Class Or Grade'] || '').toString().trim(),
          QS_MediumofInstruction : (element['QS_Medium of Instruction'] || element['QS_Medium of Instruction '] || '').toString().trim(),
          QS_SpecializedCourse : (element['QS_Specialized Course'] || '').toString().trim(),
          QS_Percentage : (element['QS_Percentage'] || '').toString().trim(),
          TechnicalQulification: technicalQulification,
          TQ_SchoolCollege : (element['TQ_School/College'] || '').toString().trim(),
          TQ_YearofPassing : (element['TQ_Year of Passing'] || '').toString().trim(),
          TQ_ClassOrGrade : (element['TQ_Class Or Grade'] || '').toString().trim(),
          TQ_MediumofInstruction : (element['TQ_Medium of Instruction'] || element['TQ_Medium of Instruction '] || '').toString().trim(),
          TQ_SpecializedCourse : (element['TQ_Specialized Course'] || '').toString().trim(),
          TQ_Percentage : (element['TQ_Percentage'] || '').toString().trim(),
          Cast: cast,
          Religion: religion,

          PANNo: (element["PAN No"] || "").toString().trim(),
          NameInPAN: (element["NameIn PAN"] || "").toString().trim(),
          AdhaarCardNumber: (element["Adhaar Card Number"] || "")
            .toString()
            .trim(),
          NameInAdhaarCard: (element["Name In Adhaar Card"] || "")
            .toString()
            .trim(),
          VoterIDNumber: (element["Voter-IDNumber"] || "").toString().trim(),
          NameInVoterID: (element["Name In Voter-ID"] || "").toString().trim(),
          RationCardNumber: (element["Ration Card Number"] || "")
            .toString()
            .trim(),
          NameInRationCard: (element["Name In Ration Card"] || "")
            .toString()
            .trim(),
          DrivingLicenseType:drivingLicenseType,
          LicenseBatchNumber: (element["License Batch Number"] || "")
            .toString()
            .trim(),
          DrivingLicenseNumber: (element["Driving License Number"] || "")
            .toString()
            .trim(),
          NameInDrivingLicense: (element["Name In Driving License"] || "")
            .toString()
            .trim(),
          DrivingLicenseExpireDate: dled,
          NameInPassoport : (element["Name In Passport"] || '').toString().trim(),
          PassportNumber: (element["Passport Number"] || "").toString().trim(),
          PassportRegistraionDate: prDate,
          PassportExpireDate: peDate,

          BankName: bankName,
          BankBranch: (element["Bank Branch"] || "").toString().trim(),
          AccountNumber: (element["Account Number"] || "").toString().trim(),
          IFSCCode: (element["IFSC Code"] || "").toString().trim(),
          NameAsPerBankRecord: (element["Name As Per Bank Record"] || "")
            .toString()
            .trim(),

          BloodGroup: bloodGroup,
          SkillType: skill,
          Trade: trade,
          UANNumber: (element["UAN Number"] || "").toString().trim(),
          DGMSNo: (element["DGMS No"] || "").toString().trim(),
          ESICNo: (element["ESIC No"] || "").toString().trim(),
          PFNo: (element["PF No"] || "").toString().trim(),
          Mobile: (element["Mobile"] || "").toString().trim(),

          RFullName: (element["R-Full Name"] || "").toString().trim(),
          RRelation: (element["R-Relation"] || "").toString().trim(),
          RAddress: (element["R-Address"] || "").toString().trim(),
          RLandMark: (element["R-Land Mark"] || "").toString().trim(),
          RArea: (element["R-Area"] || "").toString().trim(),
          RCountry: (element["R-Country"] || "").toString().trim(),
          RState: (element["R-State"] || "").toString().trim(),
          RDistrict: (element["R-District"] || "").toString().trim(),
          RCity: (element["R-City"] || "").toString().trim(),
          RPincode: (element["R-PinCode"] || "").toString().trim(),

          EFullName: (element["E-Full Name"] || "").toString().trim(),
          ERelation: (element["E-Relation"] || "").toString().trim(),
          EAddress: (element["E-Address"] || "").toString().trim(),
          ELandMark: (element["E-Land Mark"] || "").toString().trim(),
          EArea: (element["E-Area"] || "").toString().trim(),
          ECountry: (element["E-Country"] || "").toString().trim(),
          EState: (element["E-State"] || "").toString().trim(),
          EDistrict: (element["E-District"] || "").toString().trim(),
          ECity: (element["E-City"] || "").toString().trim(),
          EPincode: (element["E-PinCode"] || "").toString().trim(),
          // VendorCode: (element['Vendor Code'] || '').toString().trim(),
          // ContractorName: (element['Contractor Name'] || '').toString().trim(),
          // WONo: (element['WO No'] || '').toString().trim(),
          // LICNo: (element['LIC No'] || '').toString().trim(),
          // ESICApplicable: (element['ESIC Applicable'] || '').toString().trim(),
          // ESICNo: (element['ESIC No'] || '').toString().trim(),
          // WCNo: (element['WC No'] || '').toString().trim(),
        });        
      }
    }
    this.labourData = importData;
    this.gridOptions.api.setRowData(this.labourData);
    this.loadingService.hide();
    this.fileInput.nativeElement.value = null;
    this.validateDataByService();
  }

  validateDataByService() {
    const pincodeSet = new Set();

    this.labourData.forEach((item) => {
      if (item.Pincode) {
        pincodeSet.add(item.Pincode);
      }

      if (item.PermanentPincode) {
        pincodeSet.add(item.PermanentPincode);
      }

      if (item.RPincode) {
        pincodeSet.add(item.RPincode);
      }

      if (item.EPincode) {
        pincodeSet.add(item.EPincode);
      }
    });

    this.getPincodeDetails(Array.from(pincodeSet), null, null);
  }

  validateData() {
    const returnObj = {
      isValid: true,
      rowIndex: -1,
      field: "",
      msg: "",
    };

    const isValid = true;
    for (let i = 0; i < this.labourData.length; i++) {
      const keys = Object.keys(this.labourData[i]);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(i, keys[j]);
        if (!validObj.isValid) {
          returnObj.isValid = false;
          returnObj.rowIndex = i;
          returnObj.field = keys[j];
          returnObj.msg = "";
          break;
        }
      }
      if (!returnObj.isValid) {
        break;
      }
      const validObj = this.isOneAdressProofAvailable(i);
      if (!validObj.isValid) {
        returnObj.isValid = false;
        returnObj.rowIndex = i;
        returnObj.field = "PANNo";
        returnObj.msg = validObj.msg;
        break;
      }
    }
    return returnObj;
  }

  getApiRequestData() {
    const data = [];
    this.labourData.forEach((row) => {
      const tmpData = {
        UserEmail: this.userEmail,
        // ContractorVendor_Code: row.test,
        // WorkOrder_Code: row.test,
        // ECPolicy_Code: row.test,
        // License_Code: row.test,
        // ULC: row.test,
        // GatePassNo: row.test,
        Plant_Code: row.Plant_Code ? parseInt(row.Plant_Code, 10) : null,
        // Labour_Code: row.test,
        WageCriteria: row.WageCriteria,
        LabourFor: row.LabourDemandFor,
        // LabourType: row.test,
        WorkSite: row.WorkSite ? parseInt(row.WorkSite, 10) : null,
        ValidityPeriod: row.ValidityPeriod
          ? parseInt(row.ValidityPeriod, 10)
          : null,
        WorkCategory: row.WorkCategory ? parseInt(row.WorkCategory, 10) : null,
        Nationality: row.Nationality ? parseInt(row.Nationality, 10) : null,
        WorkmanCategory: row.WorkmanCategory,
        // Domicial: row.test,
        FirstName: row.FirstName,
        FatherHusbandName: row.FatherHusbandName,
        Relation: row.Relation ? parseInt(row.Relation, 10) : null,
        LastName: row.LastName,
        BirthDate: row.BirthDate,
        Gender: row.Gender ? parseInt(row.Gender, 10) : null,
        Cast: row.Cast ? parseInt(row.Cast, 10) : null,
        Religion: row.Religion ? parseInt(row.Religion, 10) : null,
        MaritalStatus: row.MaritalStatus
          ? parseInt(row.MaritalStatus, 10)
          : null,
        PoliceVerificationDate: row.PoliceVerificationDate,
        PermanentAddress: row.PermanentAddress,
        PermanentLandMark: row.PermanentLandMark,
        PermanentArea: row.PermanentArea,
        PermanentPincode: row.PermanentPincode,
        PermanentVillageCode: row.PermanentVillageCode
          ? parseInt(row.PermanentVillageCode, 10)
          : null,
        LocalAddress: row.LocalAddress,
        LocalLandMark: row.LocalLandMark,
        LocalArea: row.LocalArea,
        Pincode: row.Pincode,
        VillageCode: row.VillageCode ? parseInt(row.VillageCode, 10) : null,
        Qualification: row.Qualification
          ? parseInt(row.Qualification, 10)
          : null,
        QulificationStream: row.QulificationStream
          ? parseInt(row.QulificationStream, 10)
          : null,
        TechnicalQulification: row.TechnicalQulification
          ? parseInt(row.TechnicalQulification, 10)
          : null,
        PANNo: row.PANNo,
        NameInPAN: row.NameInPAN,
        AdhaarCardNumber: row.AdhaarCardNumber,
        NameInAdhaarCard: row.NameInAdhaarCard,
        VoterIDNumber: row.VoterIDNumber,
        NameInVoterID: row.NameInVoterID,
        RationCardNumber: row.RationCardNumber,
        NameInRationCard: row.NameInRationCard,
        LicenseBatchNumber: row.LicenseBatchNumber,
        DrivingLicenseNumber: row.DrivingLicenseNumber,
        NameInDrivingLicense: row.NameInDrivingLicense,
        DrivingLicenseExpireDate: row.DrivingLicenseExpireDate,
        PassportNumber: row.PassportNumber,
        PassportRegistraionDate: row.PassportRegistraionDate,
        PassportExpireDate: row.PassportExpireDate,
        BankName: row.BankName ? parseInt(row.BankName, 10) : null,
        BankBranch: row.BankBranch,
        AccountNumber: row.AccountNumber,
        IFSCCode: row.IFSCCode,
        NameAsPerBankRecord: row.NameAsPerBankRecord,
        BloodGroup: row.BloodGroup,
        SkillType: row.SkillType ? parseInt(row.SkillType, 10) : null,
        Trade: row.Trade ? parseInt(row.Trade, 10) : null,
        UANNumber: row.UANNumber,
        DGMCMinesCode: row.DGMSNo,
        ESICNo: row.ESICNo,
        // DGMCMinesCode: row.test, // ?
        PFNo: row.PFNo,
        Mobile: row.Mobile ? parseInt(row.Mobile, 10) : null,
        UnitRelationId: row.Department_Code
          ? parseInt(row.Department_Code, 10)
          : null, // ?
        SectionPlant_ID: row.SectionPlantRelationId3
          ? parseInt(row.SectionPlantRelationId3, 10)
          : null, // ?
        // WorkCategoryMIT_Code: row.test, // ?
        // CategoryMIL_Code: row.test, // ?
        ReferanceVillageCode: row.RPincode ? parseInt(row.RPincode, 10) : null,
        ReferanceFullName: row.RFullName,
        ReferanceRelationId: row.RRelation,
        ReferanceAddress: row.RAddress,
        ReferanceLandMark: row.RLandMark,
        ReferanceArea: row.RArea,
        EmergencyVillageCode: row.EPincode ? parseInt(row.EPincode, 10) : null,
        EmergencyFullName: row.EFullName,
        EmergencyRelationId: row.ERelation,
        EmergencyAddress: row.EAddress,
        EmergencyLandMark: row.ELandMark,
        EmergencyArea: row.EArea,
      };

      data.push(tmpData);
    });

    return data;
  }


  getIdByNameForImportLabour = (listName :string,name:string) => {
    if (name && !isNaN(+name)) return name;
    if (name === "undefined") return '';
    const idetifiedObjArr = _.get(this,listName).filter(({ text }) => (text || '').toLowerCase().trim() === name.toLowerCase().trim()) || [];
    return idetifiedObjArr.length ? idetifiedObjArr[0].id : null
  }

  checkIdentityExist = (row) =>{
      const identityExist = {
        "005217B4-D297-4D09-A0BB-4C32798612C7" :[]
      }

      row.PANNo && identityExist['005217B4-D297-4D09-A0BB-4C32798612C7'].push({ //Multiple detail pass in aray object with single entry
        "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
        "Sys_Code": null,
        "ReferencePersonType": 582,
        "Reference_Code": null,
        "TypeOfIdentityMasterItem_Code": 576, // 576 : PAN | 577 : Aadhar Card | 578 : Voter ID | 579 : Ration Card | 580 : Passport | 638 : NPR
        "IdentiyNo": row.PANNo,
        // "Otherno": null,
        "NameInIdentity": row.NameInPAN,
        // "RegistrationDate": null, //yyyy-mm-ddThh:mm:ss
        //"ExpireDate": null, //yyyy-mm-ddThh:mm:ss
        // "RenualDate": null, //yyyy-mm-ddThh:mm:ss
        "CreatedDate": "(SELECT GetDate())", //Auto Set
        "ModifiedDate": "(SELECT GetDate())", //Auto Set
        "CreateBy" :  this.loginUserId, // logged in user ID
        "ModifiedBy" :  this.loginUserId, // logged in user ID
        "IsActive": 1,
        "IsDelete": 0,
        "IsSync": 0
      })

      row.AdhaarCardNumber && identityExist['005217B4-D297-4D09-A0BB-4C32798612C7'].push({ //Multiple detail pass in aray object with single entry
        "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
        "Sys_Code": null,
        "ReferencePersonType": 582,
        "Reference_Code": null,
        "TypeOfIdentityMasterItem_Code": 577, // 576 : PAN | 577 : Aadhar Card | 578 : Voter ID | 579 : Ration Card | 580 : Passport | 638 : NPR
        "IdentiyNo": row.AdhaarCardNumber,
        // "Otherno": null,
        "NameInIdentity": row.NameInAdhaarCard,
        // "RegistrationDate": null, //yyyy-mm-ddThh:mm:ss
        // "ExpireDate": null, //yyyy-mm-ddThh:mm:ss
        // "RenualDate": null, //yyyy-mm-ddThh:mm:ss
        "CreatedDate": "(SELECT GetDate())", //Auto Set
        "ModifiedDate": "(SELECT GetDate())", //Auto Set
        "CreateBy" :  this.loginUserId, // logged in user ID
        "ModifiedBy" :  this.loginUserId, // logged in user ID
        "IsActive": 1,
        "IsDelete": 0,
        "IsSync": 0
      })

      row.VoterIDNumber && identityExist['005217B4-D297-4D09-A0BB-4C32798612C7'].push({ //Multiple detail pass in aray object with single entry
        "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
        "Sys_Code": null,
        "ReferencePersonType": 582,
        "Reference_Code": null,
        "TypeOfIdentityMasterItem_Code": 578, // 576 : PAN | 577 : Aadhar Card | 578 : Voter ID | 579 : Ration Card | 580 : Passport | 638 : NPR
        "IdentiyNo": row.VoterIDNumber,
        // "Otherno": null,
        "NameInIdentity": row.NameInVoterID,
        // "RegistrationDate": null, //yyyy-mm-ddThh:mm:ss
        // "ExpireDate": null, //yyyy-mm-ddThh:mm:ss
        // "RenualDate": null, //yyyy-mm-ddThh:mm:ss
        "CreatedDate": "(SELECT GetDate())", //Auto Set
        "ModifiedDate": "(SELECT GetDate())", //Auto Set
        "CreateBy" :  this.loginUserId, // logged in user ID
        "ModifiedBy" :  this.loginUserId, // logged in user ID
        "IsActive": 1,
        "IsDelete": 0,
        "IsSync": 0
      })

      row.RationCardNumber && identityExist['005217B4-D297-4D09-A0BB-4C32798612C7'].push({ //Multiple detail pass in aray object with single entry
        "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
        "Sys_Code": null,
        "ReferencePersonType": 582,
        "Reference_Code": null,
        "TypeOfIdentityMasterItem_Code": 579, // 576 : PAN | 577 : Aadhar Card | 578 : Voter ID | 579 : Ration Card | 580 : Passport | 638 : NPR
        "IdentiyNo": row.RationCardNumber,
        // "Otherno": null,
        "NameInIdentity": row.NameInRationCard,
        // "RegistrationDate": null, //yyyy-mm-ddThh:mm:ss
        // "ExpireDate": null, //yyyy-mm-ddThh:mm:ss
        // "RenualDate": null, //yyyy-mm-ddThh:mm:ss
        "CreatedDate": "(SELECT GetDate())", //Auto Set
        "ModifiedDate": "(SELECT GetDate())", //Auto Set
        "CreateBy" :  this.loginUserId, // logged in user ID
        "ModifiedBy" :  this.loginUserId, // logged in user ID
        "IsActive": 1,
        "IsDelete": 0,
        "IsSync": 0
      })

      row.PassportNumber && identityExist['005217B4-D297-4D09-A0BB-4C32798612C7'].push({ //Multiple detail pass in aray object with single entry
        "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
        "Sys_Code": null,
        "ReferencePersonType": 582,
        "Reference_Code": null,
        "TypeOfIdentityMasterItem_Code": 580, // 576 : PAN | 577 : Aadhar Card | 578 : Voter ID | 579 : Ration Card | 580 : Passport | 638 : NPR
        "IdentiyNo": row.PassportNumber,
        // "Otherno": null,
        "NameInIdentity": row.NameInPassportNumber, // ??????? add name for passport in exacle and grid --mg
        "RegistrationDate": row.PassportRegistraionDate, //yyyy-mm-ddThh:mm:ss
        "ExpireDate": row.PassportExpireDate, //yyyy-mm-ddThh:mm:ss
        // "RenualDate": null, //yyyy-mm-ddThh:mm:ss
        "CreatedDate": "(SELECT GetDate())", //Auto Set
        "ModifiedDate": "(SELECT GetDate())", //Auto Set
        "CreateBy" :  this.loginUserId, // logged in user ID
        "ModifiedBy" :  this.loginUserId, // logged in user ID
        "IsActive": 1,
        "IsDelete": 0,
        "IsSync": 0
      })

      // "005217B4-D297-4D09-A0BB-4C32798612C7": [ //IdentityReference   // check if values exist then only pass the object --mg 
      //   // { //Multiple detail pass in aray object with single entry for PNR ??????? // for feature refence when adding the PNR details
      //   //     "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
      //   //     "Sys_Code": null,
      //   //     "ReferencePersonType": 582,
      //   //     "Reference_Code": null,
      //   //     "TypeOfIdentityMasterItem_Code": 638, // 576 : PAN | 577 : Aadhar Card | 578 : Voter ID | 579 : Ration Card | 580 : Passport | 638 : NPR
      //   //     "IdentiyNo": 123232322332,
      //   //     "Otherno": null,
      //   //     "NameInIdentity": "Rakesh",
      //   //     "RegistrationDate": null, //yyyy-mm-ddThh:mm:ss
      //   //     "ExpireDate": null, //yyyy-mm-ddThh:mm:ss
      //   //     "RenualDate": null, //yyyy-mm-ddThh:mm:ss
      //   //     "CreatedDate": "(SELECT GetDate())", //Auto Set
      //   //     "ModifiedDate": "(SELECT GetDate())", //Auto Set
      //   //     "CreateBy" :  this.loginUserId, // logged in user ID
      //   //     "ModifiedBy" :  this.loginUserId, // logged in user ID
      //   //     "IsActive": 1,
      //   //     "IsDelete": 0,
      //   //     "IsSync": 0
      //   // }
      // ],

      return identityExist['005217B4-D297-4D09-A0BB-4C32798612C7']
  }

  loginContractorCode:any
  getApiRequestDataForImportLabour() {
    let data = {};
    //var test=this.pincodeMap[this.labourData.row.Pincode].Country_Code;
    this.labourData.forEach((row) => {
      const objectToModifi = {
        "29BA8F92-E774-45D6-A07C-975827F49171": [
          {
            "Labour_Code": "(SELECT @Id)", // Primary Key auto generated when new entry
            "Sys_Code": "(SELECT 'OFF-' + CONVERT( VARCHAR(50), @Id))", // Static Value Pass  //"OFF-" + LM.Labour_Code;
            "FirstName": row.FirstName,
            "MiddleName": row.FatherHusbandName,
            "LastName": row.LastName,
            "RelationMasterList_Code": this.getIdByNameForImportLabour('relationList', row.Relation), // Selected Value
            "DOB": moment(row.BirthDate).isValid() ? moment(row.BirthDate).format("YYYY-MM-DDTHH:mm:ss") : '', //yyyy-mm-ddThh:mm:ss // need to formate the date --mg
            "GenderMasterList_Code": row.Gender, // Selected Value
            "MaritalStatusMasterList_Code": row.MaritalStatus, // Selected Value
            "NationalityMasterList_Code": row.Nationality, // Selected Value
            "MobileNumber": row.Mobile,
            "BloodGroup": row.BloodGroup,
            "PFUANNo": row.UANNumber,
            "CastMasterList_Code": row.Cast, // Selected Value
            "ReligionMasterList_Code": row.Religion, // Selected Value
            "IsProfileComplited": 0, // Static Value 
            "IsPhysicallyChallenge": false, // True / False 
            //"DisabilityType_Code": null, // Selected Value
            //"EmailId1": 'test@gmail.com', // ????
            //"EmailId2": 'test@gmail.com', // ????
            //"EmailId3": 'test@gmail.com', // ????
            // "Height": row.Height || 0,
            //"Weight": row.Weight || 0,
            //"isConvicted": row.isConvicted || 0, // Default value 0
            //"Place": null,// ????
            //"IllnessDetails": null, // ???????
            "CreateBy": this.loginUserId, // logged in user ID
            "ModifiedBy": this.loginUserId, // logged in user ID
            "IsActive": 1,
            "IsDelete": 0,
            "IsSync": 0,
            "7E988C36-6E29-4789-A7A6-FFB677CA70A6": { // BankDetailReference
              "ID": null, // Primary Key auto generated when new entry
              "Sys_Code": null,
              "ReferenceType": 582, // Static Value 
              "Reference_Code": null, // Foreign Key auto set when new entry
              "BankNameMasterItem_Code": this.getIdByNameForImportLabour('bankNameList',row.BankName),
              "BranchName": row.BankBranch,
              //"BranchCode": "XUZCode",
              "AccountTypeMasterList_Code": 219, // for now we are passing the saving account by default --mg
              "AccountNumber": row.AccountNumber,
              "NameInBank": row.NameAsPerBankRecord, //?????
              //"MICRCode": "MICR3212131", // ????
              "IFSCCode": row.IFSCCode,
              "CreateBy": this.loginUserId, // logged in user ID
              "ModifiedBy": this.loginUserId, // logged in user ID
              "IsActive": 1,
              "IsDelete": 0,
              "IsSync": 0
            },
            "7C40046B-58DF-48B8-A365-994F1A15365A": { // GatePassRequest
              "GatePassRequest_Code": "(SELECT @Id)", // Primary Key auto generated when new entry
              "Sys_Code": "(SELECT 'OFF-' + CONVERT( VARCHAR(50), @Id))",
              "Labour_Code": null, // Foreign Key auto set when new entry
              "ESICWageCriteria": Number(row.WageCriteria === 'Wage / Salary   <   21000.00/- (ESIC Applicable)'), // 0: Wage ≥ 21000.00/- (EC Policy Applicable) | 1: Wage < 21000.00/-   (ESIC Applicable) // ?????
              "Contractor_Code":this.loginContractorCode,
              "PoliceVerificationDate": moment(row.PoliceVerificationDate).format("YYYY-MM-DDTHH:mm:ss"), //yyyy-mm-ddThh:mm:ss
              "PoliceValidityDate": moment(row.PoliceVerificationDate).isValid()
                ? moment(row.PoliceVerificationDate).add(6, 'months').format("YYYY-MM-DDTHH:mm:ss")
                : null, //yyyy-mm-ddThh:mm:ss // If PoliceVerificationDate not null then Add 6 month in PoliceVerificationDate and set PoliceValidityDate // ???? -- do it mg
              "Plant_Code": row.Plant_Code,
              "Skill_Code": row.SkillType,
              "Trade_Code": row.Trade,
              "PFNo": row.PFNo,
              "PFUANNo": row.UANNumber,
              "LabourDemandTypeMasterItem_Code":this.getIdByNameForImportLabour('labourForList',row.LabourDemandFor),
              "ESICNo": row.ESICNo,
              "DGMCMinesCode": row.DGMSNo,
              "UnitRelationId": row.Department_Code
                ? parseInt(row.Department_Code, 10)
                : null, // ? as descussion with rakesh for now temp need to find 
              "SectionPlant_ID": row.SectionPlantRelationId3
                ? parseInt(row.SectionPlantRelationId3, 10)
                : null, // ? as descussion with rakesh for now temp need to find 
              "WorkCategoryMIT_Code": row.WorkCategory,
              "CategoryMIL_Code": row.WorkmanCategory,
              "CreateBy": this.loginUserId, // logged in user ID
              "ModifiedBy": this.loginUserId, // logged in user ID
              "IsActive": 1,
              "IsDelete": 0,
              "IsSync": 0,
            },
            "BCE6B8B2-03F5-4ED5-804E-9BB8D7730D08": [ //GatepassWorksiteRelation
              { //Multiple detail pass in aray object with single entry
                "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": null,
                "GatePassRequest_Code": null,
                "WorksiteMasterItemList_Code": row.WorkSite,
                "CreateBy": this.loginUserId, // logged in user ID
                "ModifiedBy": this.loginUserId, // logged in user ID
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
              }
            ],
            "3CBA56A1-FE3A-4534-92C6-53E095C1B2F8": [ //GatePassWorkSiteCategoryRelation
              { //Multiple detail pass in aray object with single entry
                "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": null,
                "GatePassRequest_Code": null,
                "WorkSiteCategory_Code": row.WorkCategory,
                "CreateBy": this.loginUserId, // logged in user ID
                "ModifiedBy": this.loginUserId, // logged in user ID
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
              }
            ],
            "32CA1100-7454-4A2E-A118-266936F6E6E9": { // LabourContractorRelation
              "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
              "Sys_Code": null,
              "Labour_Code": null,
              "ContractorVendorMaster_Code": row.VendorCode, // ?????
              "CreateBy": this.loginUserId, // logged in user ID
              "ModifiedBy": this.loginUserId, // logged in user ID
              "IsActive": 1,
              "IsDelete": 0,
              "IsSync": 0
            },
            "4A86E19F-05DE-41B2-BDD4-84C4AF8DC77D": [ // AddressRefrence
              { //Multiple detail pass in aray object with single entry
                "Address_Code": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": "(SELECT 'OFF-' + CONVERT( VARCHAR(50), @Id))",
                "RefTypeMasterList_Code": 573, // Fix value for 572: Contractor |  573 : Labour | 574 : Employee
                "Reference_Code": null,
                "AddressTypeMasterList_Code": 558, // Fix value for Parmenent Address: 559 | Local address:  558
                "Address1": row.LocalAddress,
                "Address2": row.PermanentLandMark,
                "Address3": row.PermanentArea,
                "Country_Code": (this.pincodeMap[row.Pincode] && this.pincodeMap[row.Pincode].Country_Code) || null, // ??????  // need to find from api of the state or contry --mg
                "State_Code": (this.pincodeMap[row.Pincode] && this.pincodeMap[row.Pincode].State_Code)|| null,// row.State,
                "District_Code": (this.pincodeMap[row.Pincode] && this.pincodeMap[row.Pincode].Distct_Code) || null,//row.Distct,
                "City_Code": (this.pincodeMap[row.Pincode] && this.pincodeMap[row.Pincode].City_Code) || null,//row.City,
                "PostOffice_Code": (this.pincodeMap[row.Pincode] && this.pincodeMap[row.Pincode].Postoffice_Code) || null, // ???????  // need to find from api of the state or contry --mg
                "Village_Code": (this.pincodeMap[row.Pincode] && this.pincodeMap[row.Pincode].Village_Code) || null,
                "ZipCode": row.Pincode,
                "CreateBy" :  this.loginUserId, // logged in user ID
                "ModifiedBy" :  this.loginUserId, // logged in user ID
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
              },
              {
                "Address_Code": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": null,
                "RefTypeMasterList_Code": 573, // Fix value for 572: Contractor |  573 : Labour | 574 : Employee
                "Reference_Code": null,
                "AddressTypeMasterList_Code": 559, // Fix value for Parmenent Address: 559 | Local address:  558
                "Address1": row.PermanentAddress,
                "Address2": row.LocalLandMark,
                "Address3": row.LocalArea,
                "Country_Code": (this.pincodeMap[row.PermanentPincode] && this.pincodeMap[row.PermanentPincode].Country_Code) || null, // ?????
                "State_Code": (this.pincodeMap[row.PermanentPincode] && this.pincodeMap[row.PermanentPincode].State_Code) || null,
                "District_Code": (this.pincodeMap[row.PermanentPincode] && this.pincodeMap[row.PermanentPincode].Distct_Code)|| null,
                "City_Code": (this.pincodeMap[row.PermanentPincode] && this.pincodeMap[row.PermanentPincode].City_Code) || null,
                "PostOffice_Code": (this.pincodeMap[row.PermanentPincode] && this.pincodeMap[row.PermanentPincode].Postoffice_Code) || null, //?????
                "Village_Code": (this.pincodeMap[row.PermanentPincode] && this.pincodeMap[row.PermanentPincode].Village_Cod) || null,
                "ZipCode": row.PermanentPincode,
                "CreateBy" :  this.loginUserId, // logged in user ID
                "ModifiedBy" :  this.loginUserId,
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
              }
            ],
            "005217B4-D297-4D09-A0BB-4C32798612C7": this.checkIdentityExist(row),
            "9C4B9CA0-FA86-40D8-9E84-2175867D00D7": [ //LabourContactPerson  // econtent // reference content --mg
              { //Multiple detail pass in aray object with single entry
                "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": null,
                "Labour_Code": null,
                "ContactTypeMasterItem_Code": 562,  // add static value of the e content --mg
                "FullName": row.RFullName,
                "Relation": this.getIdByNameForImportLabour('relationList', row.RRelation),
                // "Mobile": 100, // not avaible --mg will do in feature
                "Address1": row.RAddress,
                "Address2": row.RLandMark,
                "Address3": row.RArea,
                "Country_Code": (this.pincodeMap[row.RPincode] && this.pincodeMap[row.RPincode].Country_Code) || null, // find from contry apii --mg
                "State_Code": (this.pincodeMap[row.RPincode] && this.pincodeMap[row.RPincode].State_Code) || null,
                "District_Code": (this.pincodeMap[row.RPincode] && this.pincodeMap[row.RPincode].Distct_Code) || null,
                "City_Code": (this.pincodeMap[row.RPincode] && this.pincodeMap[row.RPincode].City_Code)|| null,
                "PostOffice_Code": (this.pincodeMap[row.RPincode] && this.pincodeMap[row.RPincode].Postoffice_Code) || null, // find from contry apii --mg
                "Village_Code": (this.pincodeMap[row.RPincode] && this.pincodeMap[row.RPincode].Village_Code) || null,
                "ZipCode": row.RPincode,
                // "VillageName": "Gorwa", 
                "IdentityTypeMasterItem_Code": 100, // ?
                "IdentityNumber": "Rakesh Patel",
                "CreateBy": this.loginUserId, // logged in user ID
                "ModifiedBy": this.loginUserId, // logged in user ID
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
              },
              { //Multiple detail pass in aray object with single entry
                "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": null,
                "Labour_Code": null,
                "ContactTypeMasterItem_Code": 561,  // add static value of the e content --mg
                "FullName": row.EFullName,
                "Relation": this.getIdByNameForImportLabour('relationList', row.ERelation),
                // "Mobile": 100, // not avaible --mg will do in feature
                "Address1": row.EAddress,
                "Address2": row.ELandMark,
                "Address3": row.EArea,
                "Country_Code": (this.pincodeMap[row.EPincode] && this.pincodeMap[row.EPincode].Country_Code) || null, // find from contry apii --mg
                "State_Code": (this.pincodeMap[row.EPincode] && this.pincodeMap[row.EPincode].State_Code) || null,
                "District_Code": (this.pincodeMap[row.EPincode] && this.pincodeMap[row.EPincode].Distct_Code) || null,
                "City_Code": (this.pincodeMap[row.EPincode] && this.pincodeMap[row.EPincode].City_Code) || null,
                "PostOffice_Code": (this.pincodeMap[row.EPincode] && this.pincodeMap[row.EPincode].Postoffice_Code) || null, // find from contry apii --mg
                "Village_Code": (this.pincodeMap[row.EPincode] && this.pincodeMap[row.EPincode].Village_Code) || null,
                "ZipCode": row.EPincode,
                // "VillageName": "Gorwa", 
                "IdentityTypeMasterItem_Code": 100, // ?
                "IdentityNumber": "Rakesh Patel",
                "CreateBy": this.loginUserId, // logged in user ID
                "ModifiedBy": this.loginUserId, // logged in user ID
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
              },
            ],
            "A79CFE1F-517D-42D6-B848-359E015AE1EF": [ //LabourEducationDeails // create threee --mg
              { //Multiple detail pass in aray object with single entry
                "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
                "Sys_Code": null,
                "Labour_Code": null,
                "EducationTypeMLT_Code": row.Qualification, // we have three option for q,qs,tq going to crate three diff objects --mg
                "SchoolCollege": row.Q_SchoolCollege, // add school 
                "PasssingYear": row.Q_YearofPassing,
                "ClassGrade": row.Q_ClassOrGrade,
                "Medium": row.Q_MediumofInstruction,
                "TechnicalCourse": row.Q_SpecializedCourse,
                "Percentage": row.Q_Percentage,
                "CreateBy": this.loginUserId,
                "ModifiedBy": this.loginUserId,
                "IsActive": 1,
                "IsDelete": 0,
                "IsSync": 0
            },
            { //Multiple detail pass in aray object with single entry
              "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
              "Sys_Code": null,
              "Labour_Code": null,
              "EducationTypeMLT_Code": row.QulificationStream, // we have three option for q,qs,tq going to crate three diff objects --mg
              "SchoolCollege": row.QS_SchoolCollege, // add school 
              "PasssingYear": row.QS_YearofPassing,
              "ClassGrade": row.QS_ClassOrGrade,
              "Medium": row.QS_MediumofInstruction,
              "TechnicalCourse": row.QS_SpecializedCourse,
              "Percentage": row.QS_Percentage,
              "CreateBy": this.loginUserId,
              "ModifiedBy": this.loginUserId,
              "IsActive": 1,
              "IsDelete": 0,
              "IsSync": 0
          },
          { //Multiple detail pass in aray object with single entry
            "ID": "(SELECT @Id)", // Primary Key auto generated when new entry
            "Sys_Code": null,
            "Labour_Code": null,
            "EducationTypeMLT_Code": row.TechnicalQulification, // we have three option for q,qs,tq going to crate three diff objects --mg
            "SchoolCollege": row.TQ_SchoolCollege, // add school 
            "PasssingYear": row.TQ_YearofPassing,
            "ClassGrade": row.TQ_ClassOrGrade,
            "Medium": row.TQ_MediumofInstruction,
            "TechnicalCourse": row.TQ_SpecializedCourse,
            "Percentage": row.TQ_Percentage,
            "CreateBy": this.loginUserId,
            "ModifiedBy": this.loginUserId,
            "IsActive": 1,
            "IsDelete": 0,
            "IsSync": 0
        }
            ],
          }
        ]
      }
      data = {...data,...objectToModifi};
    });
    return data;
  }

  getConractor(){
    this.labourService.getContractors().subscribe((response: any[]) => {
      this.loginContractorCode = response[0].ContractorVendor_Code
    });
  }
  public formatToServerDate(date) {
    if (date) {
      let a = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      if (a === "Invalid date") {
        a = null;
      }
      return a;
    }

    return null;
  }

  saveData() {
    if (this.labourData.length > 0) {
      const valid = this.validateData();
      if (valid.isValid) {
        this.loading = true;
        this.labourService.importDataLatest(this.getApiRequestDataForImportLabour()).subscribe(
          (response) => {
            this.loading = false;
            if (response['29BA8F92-E774-45D6-A07C-975827F49171']) {
              this.labourData = [];
              this.gridOptions.api.setRowData(this.labourData);
              this.closeModal.next(this.labourData);
              this.logHelperService.logSuccess({
                message: "Associate Data imported successfully",
              });
              this.save.emit();
            } else {
              this.logHelperService.logError({
                message: "Error in importing data. Please try later.",
              });
            }
          },
          (err) => {
            this.loading = false;
            alert("Error in importing data. Please try later.");
          }
        );
      } else {
        if (valid.msg === "") {
          alert("Please correct data.");
        } else {
          alert(valid.msg);
        }
        setTimeout(() => {
          this.setFocusInput(valid.rowIndex, valid.field);
        }, 2000);
      }
    } else {
      this.logHelperService.logError({
        message: "Please Select File First",
      });
    }
  }
  loadGlobalSettings() {
    // this.labourService.getGlobalSettings().then((response) => {
    //   console.log(response.data);
    //   this.isAdhaarMandatory = response.data.ADHAAR01 === 1 ? true : false;
    //   this.isBankMandatory = response.data.BANK01 === 1 ? true : false;
    // }, (err) => {
    //   alert(err);
    // });
  }

  loadSkillList() {
    this.labourService.getSkillType().subscribe(
      (response: any) => {
        this.skillList = response.map((item) => {
          return {
            id: item.Skill_Code,
            text: item.SkillName,
          };
        });
      },
      (err) => {
        alert(err);
      }
    );
  }

  loadTradeList() {
    this.commonApiService.getAllTradeMaster(this.tradeMasterParams).subscribe(
      (response: any) => {
        this.tradeList = response.map((item) => {
          return {
            id: item.Trade_code,
            text: item.Name,
          };
        });
      },
      (err) => {
        alert(err);
      }
    );
  }

  // getPincodeList() {
  //   this.labourService.getPinCodeData().subscribe((response: any) => {
  //     this.pincodeList = response.Data.Table;
  //   }, (err) => {
  //     // alert(err);
  //   });
  // }

  private getShortCodeWiseMasterList() {
    forkJoin([
               this.labourService.getMasterListData_V2("GM294,GM198,GM331,GM575,GM612,GM618,GM858,GM875"),
               this.labourService.getMasterListData_V2("GM557,GM362,GM585,GM302,GM883,GM479,GM145,GM194"),
             ]).subscribe((res)=> {
      this.shortCodeWiseObjectMapping = { ...this.transformData(res[0]), ...this.transformData(res[1]) };
      this.fillShortCodeWiseDropdown();
    });
  }

  private transformData(arr) {
    return arr.reduce((acc, item) => {
      acc[item.Parent_Code] = item.Values;
      return acc;
    }, {});
  }

  private fillShortCodeWiseDropdown(): void {
    this.fillMaritalStatusDropdown();
    this.fillRelationDropdown();
    this.fillAllQualificationsDropdown();
    this.fillIdentityDropdown();
    this.fillWorkSiteDropdown();
    this.fillWorkCategoryDropdown();
    this.fillWorkManCategoryDropdown();
    this.fillValidityPeriodDropdown();
    this.fillCasteDropdown();
    this.fillReligionDropdown();
    this.fillBloodGroupDropdown();
    this.fillGenderDropdown();
    this.fillLabourDemandForDropdown();
    this.fillNationalityDropdown();
    this.fillBankDetailDropdown();
    this.fillDrivingLicenseTypeDropdown()
  }

  private getFilterDataFromshortCodeWiseObject(shortCode :string) : Record<string,string>[] {
    return this.shortCodeWiseObjectMapping[shortCode].map(({ MasterItem_Code, Name }) => ({ id: MasterItem_Code, text: Name }))
  }

  private fillBankDetailDropdown(): void {
    this.bankNameList = this.getFilterDataFromshortCodeWiseObject('145') || []
  }

  private fillDrivingLicenseTypeDropdown(): void {
    this.drivingLicenseType = this.getFilterDataFromshortCodeWiseObject('194') || []
  }

  private fillMaritalStatusDropdown(): void {
    this.maritalStatusList = [];
    this.shortCodeWiseObjectMapping['294'].forEach((item)=> {
      this.maritalStatusList.push({
                                    id: item.MasterItem_Code,
                                    text: item.Name,
                                  });
    })
  }

  private fillRelationDropdown() {
    this.relationList = [];
    this.shortCodeWiseObjectMapping['198'].forEach((item) => {
      this.relationList.push({
                               id: item.MasterItem_Code,
                               text: item.Name,
                             });
    })
  }

  private fillAllQualificationsDropdown() {
    this.qualificationList = [];
    this.qulificationStreamList = [];
    this.technicalQulificationList = [];
    this.shortCodeWiseObjectMapping['331'].forEach((item) => {
      this.qualificationList.push({
                                    id: item.MasterItem_Code,
                                    text: item.Name,
                                    ShortCode: item.ShortCode,
                                  });
      this.qulificationStreamList.push({
                                         id: item.MasterItem_Code,
                                         text: item.Name,
                                         ShortCode: item.ShortCode,
                                       });
      this.technicalQulificationList.push({
                                            id: item.MasterItem_Code,
                                            text: item.Name,
                                            ShortCode: item.ShortCode,
                                          });
    })
  }

  private fillIdentityDropdown() {
    this.identityList = [];
    this.shortCodeWiseObjectMapping['575'].forEach((item) => {
      this.identityList.push({
                               id: item.MasterItem_Code,
                               text: item.Name,
                             });
    })
  }

  private fillWorkSiteDropdown() {
    this.workSiteList = [];
    this.shortCodeWiseObjectMapping['612'].forEach((item) => {
      this.workSiteList.push({
                               id: item.MasterItem_Code,
                               text: item.Name,
                             });
    })
  }

  private fillWorkCategoryDropdown() {
    this.workSiteCategoryList = [];
    this.shortCodeWiseObjectMapping['618'].forEach((item) => {
      this.workSiteCategoryList.push({
                                       id: item.MasterItem_Code,
                                       text: item.Name,
                                     });
    })
  }

  private fillWorkManCategoryDropdown() {
    this.workmanCategoryList = [];
    this.shortCodeWiseObjectMapping['858'].forEach((item) => {
      this.workmanCategoryList.push({
                                      id: item.MasterItem_Code,
                                      text: item.Name,
                                    });
    })
  }

  private fillValidityPeriodDropdown() {
    this.validityPeriodList = [];
    this.shortCodeWiseObjectMapping['875'].forEach((item) => {
      this.validityPeriodList.push({
                                     id: item.MasterItem_Code,
                                     text: item.Name,
                                   });
    })
  }

  private fillCasteDropdown() {
    this.castList = [];
    this.shortCodeWiseObjectMapping['557'].forEach((item) => {
      this.castList.push({
                           id: item.MasterItem_Code,
                           text: item.Name,
                         });
    })
  }

  private fillReligionDropdown() {
    this.religionList = [];
    this.shortCodeWiseObjectMapping['362'].forEach((item) => {
      this.religionList.push({
                               id: item.MasterItem_Code,
                               text: item.Name,
                             });
    })
  }

  private fillBloodGroupDropdown() {
    this.bloodGroupList = [];
    this.shortCodeWiseObjectMapping['585'].forEach((item) => {
      this.bloodGroupList.push({
                                 id: item.MasterItem_Code,
                                 text: item.Name,
                                 ShortCode: item.ShortCode,
                               });
    })
  }

  private fillGenderDropdown() {
    this.genderList = [];
    this.shortCodeWiseObjectMapping['302'].forEach((item) => {
      this.genderList.push({
                             id: item.MasterItem_Code,
                             text: item.Name,
                           });
    });
  }

  private fillLabourDemandForDropdown() {
    this.labourForList = [];
    this.shortCodeWiseObjectMapping['883'].forEach((item) => {
      this.labourForList.push({
        id: item.MasterItem_Code,
        text: item.Name,
      });
    })
  }

  private fillNationalityDropdown() {
    this.nationalityList = [];
    this.shortCodeWiseObjectMapping['479'].forEach((item) => {
      this.nationalityList.push({
                                id: item.MasterItem_Code,
                                text: item.Name,
                              });
    })
  }


  // Get MasterList For DropDown
  getMasterList() {
    this.labourService.getMasterlistData().subscribe(
      (masteritemlist: any) => {
        const masterItemList = masteritemlist.Data.Table;
        const gendertypes = masterItemList.filter(
          (item) => item.Parent_Code === 302
        );
        for (let i = 0; i < gendertypes.length; i++) {
          this.genderList.push({
            id: gendertypes[i].MasterItem_Code,
            text: gendertypes[i].Name,
          });
        }
        const qualificationList = masterItemList.filter(
          (item) => item.Parent_Code === 331
        );
        for (let i = 0; i < qualificationList.length; i++) {
          this.qualificationList.push({
            id: qualificationList[i].MasterItem_Code,
            text: qualificationList[i].Name,
            ShortCode: qualificationList[i].ShortCode,
          });
        }

        const qulificationStreamList = masterItemList.filter(
          (item) => item.Parent_Code === 352
        );
        for (let i = 0; i < qulificationStreamList.length; i++) {
          this.qulificationStreamList.push({
            id: qulificationStreamList[i].MasterItem_Code,
            text: qulificationStreamList[i].Name,
            ShortCode: qulificationStreamList[i].ShortCode,
          });
        }

        const technicalQulificationList = masterItemList.filter(
          (item) => item.Parent_Code === 342
        );
        for (let i = 0; i < technicalQulificationList.length; i++) {
          this.technicalQulificationList.push({
            id: technicalQulificationList[i].MasterItem_Code,
            text: technicalQulificationList[i].Name,
            ShortCode: technicalQulificationList[i].ShortCode,
          });
        }

        const bloodGroupList = masterItemList.filter(
          (item) => item.Parent_Code === 585
        );
        for (let i = 0; i < bloodGroupList.length; i++) {
          this.bloodGroupList.push({
            id: bloodGroupList[i].MasterItem_Code, // .MasterItem_Code,
            text: bloodGroupList[i].Name,
            ShortCode: bloodGroupList[i].ShortCode,
          });
        }

        const maritalStatusList = masterItemList.filter(
          (item) => item.Parent_Code === 294
        );
        for (let i = 0; i < maritalStatusList.length; i++) {
          this.maritalStatusList.push({
            id: maritalStatusList[i].MasterItem_Code,
            text: maritalStatusList[i].Name,
          });
        }

        const bankNameList = masterItemList.filter(
          (item) => item.Parent_Code === 145
        );
        for (let i = 0; i < bankNameList.length; i++) {
          this.bankNameList.push({
            id: bankNameList[i].MasterItem_Code,
            text: bankNameList[i].Name,
          });
        }

        const nationalityList = masterItemList.filter(
          (item) => item.Parent_Code === 479
        );
        for (let i = 0; i < nationalityList.length; i++) {
          this.nationalityList.push({
            id: nationalityList[i].MasterItem_Code,
            text: nationalityList[i].Name,
          });
        }

        const workSiteList = masterItemList.filter(
          (item) => item.Parent_Code === 612
        );
        for (let i = 0; i < workSiteList.length; i++) {
          this.workSiteList.push({
            id: workSiteList[i].MasterItem_Code,
            text: workSiteList[i].Name,
          });
        }

        const labRelationshipAll = masterItemList.filter(
          (item) => item.Parent_Code === 198
        );
        this.relationList = [];
        labRelationshipAll.forEach((item) => {
          // if (item.Name === 'Father' || item.Name === 'Husband') {
          this.relationList.push({
            id: item.MasterItem_Code,
            text: item.Name,
          });
          // }
        });

        const castList = masterItemList.filter(
          (item) => item.Parent_Code === 557
        );
        for (let i = 0; i < castList.length; i++) {
          this.castList.push({
            id: castList[i].MasterItem_Code,
            text: castList[i].Name,
          });
        }

        const religionList = masterItemList.filter(
          (item) => item.Parent_Code === 362
        );
        for (let i = 0; i < religionList.length; i++) {
          this.religionList.push({
            id: religionList[i].MasterItem_Code,
            text: religionList[i].Name,
          });
        }

        const workSiteCategoryList = masterItemList.filter(
          (item) => item.Parent_Code === 618
        );
        for (let i = 0; i < workSiteCategoryList.length; i++) {
          this.workSiteCategoryList.push({
            id: workSiteCategoryList[i].MasterItem_Code,
            text: workSiteCategoryList[i].Name,
          });
        }

        const validityPeriodList = masterItemList.filter(
          (item) => item.Parent_Code === 875
        );
        for (let i = 0; i < validityPeriodList.length; i++) {
          this.validityPeriodList.push({
            id: validityPeriodList[i].MasterItem_Code,
            text: validityPeriodList[i].Name,
          });
        }

        const workmanCategoryList = masterItemList.filter(
          (item) => item.Parent_Code === 858
        );
        for (let i = 0; i < workmanCategoryList.length; i++) {
          this.workmanCategoryList.push({
            id: workmanCategoryList[i].MasterItem_Code,
            text: workmanCategoryList[i].Name,
          });
        }
      },
      () => {
        alert("Error in getting records");
      }
    );
  }

  getPincodeDetails(pincodeList, rowIndex, fieldName) {
    let newPincodeList = pincodeList.filter((item) => !this.pincodeMap[item]);
    newPincodeList = newPincodeList.filter(
      (item) => !this.pincodeNotFoundSet.has(item)
    );
    if (newPincodeList.length === 0) {
      this.revalidateRow(rowIndex, fieldName);
      return;
    }

    this.labourService.getPincodeDetails(newPincodeList).subscribe(
      (res) => {
        res.Data.forEach((item) => {
          if (item.Locations.length > 0) {
            const pincodeDetails = JSON.parse(
              JSON.stringify(item.Locations[0])
            );
            pincodeDetails.Locations = item.Locations;
            this.pincodeMap[item.Zip_Code] = pincodeDetails;
          }
        });

        newPincodeList.forEach((item) => {
          if (!this.pincodeMap[item]) {
            this.pincodeNotFoundSet.add(item);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      },
      (err) => {}
    );
  }

  revalidateRow(rowIndex, fieldName) {
    if (fieldName) {
      const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
      const rowNodes = [row]; // params needs an array
      // tslint:disable-next-line
      this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    } else {
      this.gridOptions.api.redrawRows();
    }
  }

  getUserplantDetail() {
    this.labourService.getCommonSpUserPlantAcces().subscribe(
      (res) => {
        // console.log("user plant res ----->",res)
        this.userplantDetail = _.uniqBy(res, "State");
        this.allPlantsUser = res;
        // console.log("userplantDetail ----->",this.userplantDetail)
      },
      (err) => {
        console.log("user plant err --->", err);
      }
    );
  }

  getGlobalSettings() {
    this.labourService.globalSettingCode("").subscribe(
      (res: any) => {
        this.checkPoliceVerificationRequired(res.Data.Table);
        this.checkContactReferenceDetailsRequired(res.Data.Table);
        this.checkEmergencyContactDetailsRequired(res.Data.Table);
        this.checkBankAccountDetailsRequired(res.Data.Table);
        this.checkAadharCardOrPassportRequired(res.Data.Table);
        this.checkDrivingLicenseRequired(res.Data.Table);
        this.globalSettingData = res.Data.Table.filter(
          (i) => i.Code == "MOD119PG196EXCLIMPPRO_Con"
        );
        this.globalDataSampleDownload = res.Data.Table.filter(
          (i) => i.Code == "MOD101IMPEXCL"
        );
      },
      (err) => {
        console.log("global setting err --->", err);
      }
    );
  }


  private checkPoliceVerificationRequired(table): void {
    this.policeVerificationRequiredCode = table.find(item => item.Code === 'MOD152PG195PROBGC');
    if (!this.policeVerificationRequiredCode) {
      this.policeVerificationRequiredCode = {};
    }
  }

  private checkContactReferenceDetailsRequired(table): void {
    this.referenceContactDetailsRequiredCode = table.find(item => item.Code === 'MOD152PG198PROREFC');
    if (!this.referenceContactDetailsRequiredCode) {
      this.referenceContactDetailsRequiredCode = {};
    }
  }

  private checkEmergencyContactDetailsRequired(table) : void{
    this.emergencyContactDetailsRequiredCode = table.find(item => item.Code === 'MOD152PG197PROEMG');
    if (!this.emergencyContactDetailsRequiredCode) {
      this.emergencyContactDetailsRequiredCode = {};
    }
  }

  private checkBankAccountDetailsRequired(table): void {
    this.bankAccountDetailsRequiredCode = table.find(item => item.Code === 'MOD152PG195PROBANMND');
    if (!this.bankAccountDetailsRequiredCode) {
      this.bankAccountDetailsRequiredCode = {};
    }
  }

  private checkAadharCardOrPassportRequired(table): void {
    this.aadharCardOrPassportRequiredCode = table.find(item => item.Code === 'MOD152PG195PROFAADMND');
    if (!this.aadharCardOrPassportRequiredCode) {
      this.aadharCardOrPassportRequiredCode = {};
    }
  }

  private checkDrivingLicenseRequired(table): void {
    this.drivingLicenseRequiredCode = table.find(item => item.Code === 'MOD152PG195DRVLIC');
    this.driverTradeCode = table.find(item => item.Code === 'MOD152PG195DRVLICTRADE');
    if (Object.keys(this.driverTradeCode).length) {
      this.driverTradeCodeArr = this.driverTradeCode.Value.split(",");
    }
    if (!this.drivingLicenseRequiredCode) {
      this.aadharCardOrPassportRequiredCode = {};
    }
    if (!this.driverTradeCode) {
      this.driverTradeCode = {};
    }
  }
}
