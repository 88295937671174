import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FaceRecognitionUIService } from './faceRecognitionUI.service';

@Component({
  selector: 'app-face-recognition-ui',
  templateUrl: './faceRecognitionUI.component.html',
  styleUrls: ['./faceRecognitionUI.component.css']
})
export class FaceRecognitionUIComponent implements OnInit {
  trainedData: any[];
  label: any;
  system: any;

  constructor(private faceRecognitionService: FaceRecognitionUIService) { }

  ngOnInit() {
    this.getTrainDataList();
  }

  getTrainDataList() {
    this.faceRecognitionService.getAllTrainedData().subscribe((res: any) => {
      //
      const result = res.filter((dt: any) => dt.Descriptors !== null);
      this.trainedData = result.map((singleTrainData: any) => {
        return { ...singleTrainData, Descriptors: JSON.parse(`[${singleTrainData.Descriptors}]`) };
      });
      /// only send trainded data which has full 128 array of descriptors data
      if (this.trainedData.length > 0) {
        this.trainedData = this.trainedData.filter(f => f.Descriptors ? f.Descriptors.length === 128 : '');
      }
    });
  }
  getData(e) {

    const obj = {
      Name: e._label,
      Descriptors: Array.from(e._descriptors[0]),
      System: e.system
    };
    this.faceRecognitionService.addFaceIdentityDetails(obj).subscribe((resp) => {
      this.getTrainDataList();
    });
  }

  getRecognizationPersonData(e) {
    console.log('recognization person', e);
  }

}
