import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ContractorFilterPrsenter } from './contractor-filter.presenter';
import { Subject } from 'rxjs';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { FilterTypeEnum } from '../report-filter-test/report-filter.model';
import { ReportFilterModel } from 'src/app/attendance/attendance-approve/attendance-approve.model';
import { WorkOrder, Contractor } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-contractor-filter',
  templateUrl: './contractor-filter.component.html',
  styleUrls: ['./contractor-filter.component.css'],
  viewProviders: [ContractorFilterPrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class ContractorFilterComponent implements OnInit {

  public contractorFormGroup: FormGroup;
  public saveReportData: Subject<any>;
  public onStartDateChange: Subject<any>;
  public onContractorCodeChange: Subject<number>;
  public onWorkOrderNoChange: Subject<any>;
  public getWorkOrderList: Subject<any>;
  public licenses: any[] = [];

  @Output() startDateChangeEmitter: EventEmitter<string> = new EventEmitter();
  @Output() contractorChangeEmitter: EventEmitter<number> = new EventEmitter();
  @Output() workOrderChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() contractorSaveEmitter: EventEmitter<ReportFilterModel> = new EventEmitter();
  @Output() getWorkOrderListEmitter: EventEmitter<number> = new EventEmitter();
  @Output() getContractors: EventEmitter<void> = new EventEmitter();


  @Input() public set contractors(value: Contractor[]) {
    if (value) {
      this.contractorList = [...value];
      if (this.reportFilterData && this.contractorList.length > 0) {
        if (this.reportFilterData.contractorCode) {
          this.contractorFormGroup.get('contractorCode').setValue(this.reportFilterData.contractorCode);
          this.getWorkOrderListEmitter.emit(this.reportFilterData.contractorCode);
        }
      }
    }
  }

  public get contractors(): Contractor[] {
    return this.contractorList;
  }

  @Input() public set workOrders(value: WorkOrder[]) {
    if (value) {
      this.workOrderList = [...value];
      if (this.reportFilterData && this.workOrderList.length > 0) {
        if (this.reportFilterData.workOrderNo) {
          // this.contractorFilterPresenter.onWorkOrderChange(this.reportFilterData.contractorCode.toString(),
          //   this.reportFilterData.workOrderNo.toString());

          this.workOrderChangeEmitter.emit({
            contractorCode: this.reportFilterData.contractorCode,
            workOrderNo: this.reportFilterData.workOrderNo
          });

          if (this.contractorFormGroup.get('workOrderNo').value === null) {
            this.contractorFormGroup.get('workOrderNo').setValue(this.reportFilterData.workOrderNo);
          }
        }
      }
    }
  }

  public get workOrders(): WorkOrder[] {
    return this.workOrderList;
  }


  @Input() public set reportFilterData(value: ReportFilterModel) {
    if (value) {
      this.reportFilter = { ...value };
      this.contractorFormGroup.get('startDate').setValue(this.reportFilterData.startDate);
    } else {
      this.reportFilter = null;
      this.contractorFormGroup.get('startDate').setValue(null);
      this.contractorFormGroup.get('contractorCode').setValue(null);
      this.contractorFormGroup.get('workOrderNo').setValue(null);
    }
  }

  public get reportFilterData(): ReportFilterModel {
    return this.reportFilter;
  }

  @Input() public set resetForm(value: boolean) {
    if (value) {
      this.formReset = value;
    }
  }

  public get resetForm(): boolean {
    return this.formReset;
  }

  private reportFilter: ReportFilterModel;
  private formReset: boolean;
  private contractorList: Contractor[] = [];
  private workOrderList: WorkOrder[] = [];

  constructor(private contractorFilterPresenter: ContractorFilterPrsenter) {
    this.saveReportData = new Subject();
    this.onStartDateChange = new Subject();
    this.onContractorCodeChange = new Subject();
    this.onWorkOrderNoChange = new Subject();
    this.getWorkOrderList = new Subject();

    this.contractorFormGroup = this.contractorFilterPresenter.buildForm();
  }

  ngOnInit() {
    if (this.contractors.length === 0) {
      this.getContractors.emit();
    }

    this.propertyObserver();
  }

  public onDateChange() {
    this.startDateChangeEmitter.emit(this.contractorFormGroup.get('startDate').value);
    // this.contractorFilterPresenter.onStartDateChange(this.contractorFormGroup.get('startDate').value);
  }

  public onContractorChange(): void {
    this.workOrderList = [];
    this.contractorFormGroup.get('workOrderNo').setValue(null);
    this.contractorChangeEmitter.emit(this.contractorFormGroup.get('contractorCode').value);
    // this.contractorFilterPresenter.onContractorChange(this.contractorFormGroup.get('contractorCode').value);
  }

  public onWorkOrderChange() {
    // this.contractorFilterPresenter.onWorkOrderChange(this.contractorFormGroup.get('contractorCode').value,
    //   this.contractorFormGroup.get('workOrderNo').value);
    this.workOrderChangeEmitter.next({
      contractorCode: this.contractorFormGroup.get('contractorCode').value,
      workOrderNo: this.contractorFormGroup.get('workOrderNo').value
    });
  }

  private propertyObserver(): void {
    this.contractorFilterPresenter.reportData$.subscribe((value: any) => {
      this.saveReportData.next(value);
    });

    this.contractorFilterPresenter.startDate$.subscribe((value: any) => {
      this.onStartDateChange.next(value);
    });

    this.contractorFilterPresenter.contractor$.subscribe((value: number) => {
      this.onContractorCodeChange.next(value);
    });

    this.contractorFilterPresenter.workOrder$.subscribe((value: any) => {
      this.onWorkOrderNoChange.next(value);
    });
  }

  private setFormGroup(value: any): void {
    this.contractorFormGroup.patchValue(value);
  }

  private saveContractorFilterData(contractorFormGroup: FormGroup, reportFilterData: ReportFilterModel) {
    if (contractorFormGroup.get('startDate').value !== '' || contractorFormGroup.get('contractorCode').value !== null
      || contractorFormGroup.get('workOrderNo').value !== null) {
      if (!reportFilterData) {
        this.reportFilterData = new ReportFilterModel();
      }
      const data: ReportFilterModel = contractorFormGroup.value;
      data.filterType = FilterTypeEnum.ContractorFilter;
      this.contractorSaveEmitter.next(data);
    }
  }
}
