import { Injectable } from '@angular/core';

declare var d3: any;
declare var dc: any;

interface Config {
  crossfilter: any;
  filterGroup: string;
  chartId: string;
  groupByProperty: string;
  reduceProperty: string;
  width: number;
  height: number;

  // Optional
  gap?: number;
  outerPadding?: number;
  yAxisLabel?: string;
  xAxisLabel?: string;
  elasticX?: boolean;
  elasticY?: boolean;
  margins?: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  };
}

@Injectable({
  providedIn: 'root'
})
export class BarChartService {

  constructor() { }

  getRowNonDomicileStateWiseDistributionChart(config: Config) {
    const rowNonDomicileStateWiseChart = dc.barChart(config.chartId, config.filterGroup);
    const stateDimension = config.crossfilter.dimension((d: any) => {
      return d[config.groupByProperty];
    });
    const domicileGroup = stateDimension.group().reduceCount((d: any) => {
      let cnt = 0;
      if (d.Domicile <= 'Non-Domicile') {
        cnt++;
      }
      return cnt;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(domicileGroup);

    // console.log(OTULCGroup.all());
    rowNonDomicileStateWiseChart
      .width(config.width)
      .height(config.height)
      .dimension(stateDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .x(d3.scale.ordinal())
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(rowNonDomicileStateWiseChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .xUnits(dc.units.ordinal);
    rowNonDomicileStateWiseChart.yAxis().tickFormat(this.tickValueFormat);

    if (config.margins) {
      rowNonDomicileStateWiseChart.margins(config.margins);
    }

    rowNonDomicileStateWiseChart.render();
    return rowNonDomicileStateWiseChart;
  }


  getBarCountVsOverOTChart(config: Config) {
    const barCountVsOverOTChart = dc.barChart(config.chartId, config.filterGroup);
    const oTDimension = config.crossfilter.dimension((d: any) => {
      // return d.Age;
      if (d.OverTime <= 1) return '0.5-1';
      if (d.OverTime <= 2) return '1-2';
      if (d.OverTime <= 4) return '2-4';
      if (d.OverTime <= 8) return '4-8';
      if (d.OverTime >= 8) return 'Above 8';
    });
    const oTULCGroup = oTDimension.group().reduceCount((d: any) => {
      return d.ULC;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(oTULCGroup);
    // console.log(OTULCGroup.all());
    barCountVsOverOTChart
      .width(config.width)
      .height(config.height)
      .dimension(oTDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(barCountVsOverOTChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(['0.5-1', '1-2', '2-4', '4-8', 'Above 8']))
      .xUnits(dc.units.ordinal);

    if (config.margins) {
      barCountVsOverOTChart.margins(config.margins);
    }

    barCountVsOverOTChart.yAxis().tickFormat(this.tickValueFormat);
    barCountVsOverOTChart.render();
    return barCountVsOverOTChart;
  }

  getRowAbsentisumChart(config: Config) {
    const rowAbsentisumChart = dc.barChart(config.chartId, config.filterGroup);
    const oTAbsentDimension = config.crossfilter.dimension((d: any) => {
      // return d.Age;
      let cnt = 0;
      if (d.AttendanceStatus === 'A') {
        cnt++;
      }

      if (cnt <= 5) return '1-5';
      if (cnt <= 10) return '5-10';
      if (cnt <= 15) return '10-15';
      if (cnt <= 20) return '15-20';
      if (cnt >= 20) return 'Above 20';

    });
    const oTAbsentULCGroup = oTAbsentDimension.group().reduceCount((d: any) => {
      return d.ULC;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(oTAbsentULCGroup);

    rowAbsentisumChart
      .width(config.width)
      .height(config.height)
      .dimension(oTAbsentDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(rowAbsentisumChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(['1-5', '5-10', '10-15', '15-20', 'Above 20']))
      .xUnits(dc.units.ordinal);

    if (config.margins) {
      rowAbsentisumChart.margins(config.margins);
    }

    rowAbsentisumChart.yAxis().tickFormat(this.tickValueFormat);
    rowAbsentisumChart.render();
    return rowAbsentisumChart;
  }

  getBarAgeWiseDeistributionChart(config: Config) {
    const barAgeWiseChart = dc.barChart(config.chartId, config.filterGroup);
    const ageDimension = config.crossfilter.dimension((d: any) => {
      // return d.Age;
      if (d.Age <= 25) return '15-25';
      if (d.Age <= 35) return '25-35';
      if (d.Age <= 45) return '35-45';
      if (d.Age <= 55) return '45-55';
      if (d.Age <= 65) return '55-65';
      if (d.Age <= 75) return '65-75';
    });
    const ageULCGroup = ageDimension.group().reduceCount((d) => {
      return d.ULC;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(ageULCGroup);
    barAgeWiseChart
      .width(config.width)
      .height(config.height)
      .dimension(ageDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(barAgeWiseChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(['15-25', '25-35', '35-45', '45-55', '55-65', '65-75']))
      .xUnits(dc.units.ordinal);

    if (config.margins) {
      barAgeWiseChart.margins(config.margins);
    }

    barAgeWiseChart.yAxis().tickFormat(this.tickValueFormat);
    barAgeWiseChart.render();
    return barAgeWiseChart;
  }

  tickValueFormat = (d) => {
    let newd = d;
    if ((d / 10000000) >= 1) {
      newd = `${d / 10000000}Cr`;
    } else if ((d / 100000) >= 1) {
      newd = `${d / 100000}L`;
    } else if ((d / 1000) >= 1) {
      newd = `${d / 1000}K`;
    }
    return newd;
  }


  hideLabel = (chart) => {
    chart.selectAll('text.barLabel')
      .attr('class', 'barLabel d-none');
  }

  rotateBarLabel = (chart) => {
    if (!chart.select('.bar')[0][0]) {
      return;
    }

    const y = chart.y();
    const x = chart.x();
    // tslint:disable-next-line
    const barWidth = parseInt(chart.select('.bar')[0][0].getAttribute('width'));
    const gap = chart.gap();
    const groupGap = chart.groupGap();

    chart.selectAll('text.barLabel')
      .attr('class', 'barLabel d-none')
      .attr('transform', 'rotate(-90)')
      .attr('y', (d) => {
        let groupBars = 0;
        if (d.layer === 'Actual') {
          groupBars = barWidth + groupGap;
        } else if (d.layer === 'ActualDeployed') {
          groupBars = barWidth + gap;
        } else if (d.layer === 'TotalShortage') {
          groupBars = (barWidth * 2) + gap + groupGap;
        }

        // console.log('y', x(d.x), groupBars);

        return x(d.x) + (barWidth / 2) + gap + groupGap + groupBars;
      })
      .attr('x', (d) => {
        // console.log('x', -y(0));
        return -y(0) + 40;
      })
      .attr('class', 'barLabel');
  }

  getExtendedWHSummaryChart(config: Config) {
    const barExtendedWHSummaryChart = dc.barChart(config.chartId, config.filterGroup);
    const oTDimension = config.crossfilter.dimension((d: any) => {
      if (d.OverTime === 0) return '';
      if (d.OverTime <= 1) return '0.5-1';
      if (d.OverTime <= 2) return '>1-2';
      if (d.OverTime <= 4) return '>2-4';
      if (d.OverTime <= 8) return '>4-8';
      if (d.OverTime >= 8) return '>8';
    });
    const oTULCGroup = oTDimension.group().reduceCount((d: any) => {
      return d.ULC;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.key;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(oTULCGroup);
    // console.log(OTULCGroup.all());
    barExtendedWHSummaryChart
      .width(config.width)
      .height(config.height)
      .margins({ left: 50, top: 20, right: 50, bottom: 50 })
      .dimension(oTDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(barExtendedWHSummaryChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(['0.5-1', '>1-2', '>2-4', '>4-8', '>8']))
      .xUnits(dc.units.ordinal);

    barExtendedWHSummaryChart.yAxis().tickFormat(this.tickValueFormat);
    barExtendedWHSummaryChart.render();
    return barExtendedWHSummaryChart;
  }
  // getRowChart(config: Config) {
  //   const dimension = config.crossfilter.dimension((d) => {
  //     return d[config.groupByProperty];
  //   });
  //   const group = dimension.group().reduceCount((d) => {
  //     return d[config.reduceProperty];
  //   });

  //   function removeEmptyData(sourceGroup) {
  //     return {
  //       all: () => {
  //         return sourceGroup.all().filter((d) => {
  //           return d.value !== 0;
  //         });
  //       }
  //     };
  //   }

  //   const nonEmptyData = removeEmptyData(group);

  //   return this.generateRowChart(config, dimension, nonEmptyData);
  // }
  // generateRowChart(config: Config, dimension, group) {
  //   const chart = dc.rowChart(config.chartId, config.filterGroup);
  //   chart
  //     .width(config.width)
  //     .height(config.height)
  //     .dimension(dimension)
  //     .group(group)
  //     .ordering(
  //       // tslint:disable-next-line: ter-prefer-arrow-callback
  //       function (d: { value: number; }) { return -d.value; }
  //     )
  //     .gap(config.gap ? config.gap : 4)
  //     .elasticX(config.elasticX)
  //     .label((d) => {
  //       const totalValue = d3.sum(group.all(), d => d.value);
  //       return `${d.key}: ${d.value} (${Math.round((d.value / totalValue) * 100)}%)`;
  //     })
  //     .on('postRender', (chart) => {
  //       this.addYLabel(chart, config.yAxisLabel);
  //       this.addXLabel(chart, config.xAxisLabel);
  //     });

  //   if (config.margins) {
  //     chart.margins(config.margins);
  //   }

  //   chart.render();
  //   return chart;
  // }
  // addYLabel = (chartToUpdate, displayText) => {
  //   const textSelection = chartToUpdate.svg()
  //     .append('text')
  //     .attr('class', 'y-axis-label')
  //     .attr('text-anchor', 'middle')
  //     .attr('transform', 'rotate(-90)')
  //     .attr('x', -chartToUpdate.height() / 2)
  //     .attr('y', 10)
  //     .text(displayText);
  //   const textDims = textSelection.node().getBBox();
  //   const chartMargins = chartToUpdate.margins();

  //   // Dynamically adjust positioning after reading text dimension from DOM
  //   textSelection
  //     .attr('x', -chartMargins.top - (chartToUpdate.height()
  //       - chartMargins.top - chartMargins.bottom) / 2)
  //     .attr('y', Math.max(Math.ceil(textDims.height), chartMargins.left
  //       - Math.ceil(textDims.height) - 5));
  // }
  // // Functions to add x-label & y-label to Row Charts (Unsupported by dc.js)
  // addXLabel = (chartToUpdate, displayText) => {
  //   const textSelection = chartToUpdate.svg()
  //     .append('text')
  //     .attr('class', 'x-axis-label')
  //     .attr('text-anchor', 'middle')
  //     .attr('x', chartToUpdate.width() / 2)
  //     .attr('y', chartToUpdate.height() - 10)
  //     .text(displayText);
  //   const textDims = textSelection.node().getBBox();
  //   const chartMargins = chartToUpdate.margins();

  //   // Dynamically adjust positioning after reading text dimension from DOM
  //   textSelection
  //     .attr('x', chartMargins.left + (chartToUpdate.width()
  //       - chartMargins.left - chartMargins.right) / 2)
  //     .attr('y', chartToUpdate.height() - Math.ceil(textDims.height) / 2);
  // }
  barTotalMonthlyVisitors(config: Config) {
    const barmonthlyvistiorChart = dc.barChart(config.chartId, config.filterGroup);
    const oTDimension = config.crossfilter.dimension((d: any) => {
      return d.MonthName;
    });
    const totalvisitior = oTDimension.group().reduceCount((d: any) => {
      return d.total;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(totalvisitior);
    // console.log(OTULCGroup.all());
    barmonthlyvistiorChart
      .width(config.width)
      .height(config.height)
      .margins({ left: 50, top: 20, right: 50, bottom: 50 })
      .dimension(oTDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(barmonthlyvistiorChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(['0.5-1', '>1-2', '>2-4', '>4-8', '>8']))
      .xUnits(dc.units.ordinal);
    barmonthlyvistiorChart.yAxis().tickFormat(this.tickValueFormat);
    barmonthlyvistiorChart.render();
    return barmonthlyvistiorChart;
  }
  barTotalweeklyVisitors(config: Config) {
    const barweeklyvistiorChart = dc.barChart(config.chartId, config.filterGroup);
    const oTDimension = config.crossfilter.dimension((d: any) => {
      return d.weekday;
    });
    const totalvisitior = oTDimension.group().reduceCount((d: any) => {
      return d.total;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(totalvisitior);
    // console.log(OTULCGroup.all());
    barweeklyvistiorChart
      .width(config.width)
      .height(config.height)
      .margins({ left: 50, top: 20, right: 50, bottom: 50 })
      .dimension(oTDimension)
      .group(nonEmptyData)
      .gap(config.gap ? config.gap : 4)
      .elasticX(config.elasticX)
      .elasticY(config.elasticY)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .label((d) => {
        const totalValue = d3.sum(nonEmptyData.all(), d => d.value);
        return `${d.y} (${Math.round((d.y / totalValue) * 100)}%)`;
      })
      .on('pretransition', () => {
        this.hideLabel(barweeklyvistiorChart);
      })
      .on('renderlet', this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(['0.5-1', '>1-2', '>2-4', '>4-8', '>8']))
      .xUnits(dc.units.ordinal);
    barweeklyvistiorChart.yAxis().tickFormat(this.tickValueFormat);
    barweeklyvistiorChart.render();
    return barweeklyvistiorChart;
  }
}
