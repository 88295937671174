import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-drop-down-cell-renderer',
  templateUrl: './ag-drop-down-cell-renderer.component.html',
  styleUrls: ['./ag-drop-down-cell-renderer.component.css']
})
export class AgDropDownCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  constructor() { }
  shifts = [];
  status = [];

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.shifts = params.shifts;
    this.status = params.status;
  }

  refresh(): boolean {
    return true;
  }

  selectionChange(newValue) {
    this.params.data[this.params.column.colId] = Number(newValue);
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection(Number(newValue));
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable;
    }
    return false;
  }

}
