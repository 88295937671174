import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { GLOBAL } from "src/app/app.globals";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { ImportExcelService } from "../import-excel.service";
import { PayrollExcelFileSampleComponent } from "./payroll-excel-file-sample/payroll-excel-file-sample.component";

const allowedComponentList = ['LWF', 'Room Rent', 'Food Deduction', 'Transport Deduction', 'Benevolent Fund', 'Other Deduction', 'Flexi-Benefits', 'Other Re-Imbursement', 'Other Allowance'];

@Component({
  selector: 'app-payroll-excel-upload',
  templateUrl: './payroll-excel-upload.component.html',
  styleUrls: ['./payroll-excel-upload.component.css']
})
export class PayrollHandUploadComponent {

  @ViewChild('fileInput') public fileInput: ElementRef<any>;
  @Output() public closeModal: EventEmitter<any>;

  public fileError: string;
  public formGroup: FormGroup;

  public payrollYears$: Observable<any[]> = this.importExcelService.payrollYears$;
  public payrollMonths$: Observable<any[]> = this.importExcelService.payrollMonths$;

  constructor(
    private fb: FormBuilder,
    private importExcelService: ImportExcelService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal
  ) {
    this.closeModal = new EventEmitter();
    this.formGroup = this.buildForm();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public onYearChange(): void {
    this.formGroup.get('payrollMonth').setValue(null);
    this.importExcelService.setPayrollYearChangeAction(this.formGroup.get('payrollYear').value);
  }

  public onFileChange(data: any[] = []) {
    this.formGroup.get('uploadList').setValue(data);
    console.log(data);
  }

  public onUploadFile() {
    const formValue = this.formGroup.getRawValue();
    if (!formValue.uploadList.length) {
      this.logHelperService.logError({
        message: 'Please select the file'
      });
      return;
    }

    const requestList = this.getRequestData(formValue.uploadList);
    this.importExcelService.savePayrollHandEntry(requestList).subscribe(res => {
      this.logHelperService.logSuccess({
        message: 'Records are uploaded successfully'
      });
      this.closeModal.emit();
    });
  }

  public onDownloadSampleFile(): void {
    this.getSiteCodeByUserID().subscribe(res => {
      const modalRef = this.modalService.open(PayrollExcelFileSampleComponent, {
        size: "sm"
      });
      modalRef.componentInstance.sites = res;
      modalRef.componentInstance.closeModal.subscribe(() => modalRef.close());
    })
  }

  private getRequestData(uploadedData: any[]): any[] {
    const finalList = [];
    const payrollMonthCode = this.formGroup.get('payrollMonth').value;
    uploadedData.forEach(item => {
      Object.keys(item).forEach(key => {
        if (this.checkComponentKey(key)) {
          finalList.push({
            PayrollMonth_Code: payrollMonthCode,
            Plant: item.Plant,
            GatePassNo: item.GatePassNo,
            Component: key,
            Amount: item[key],
            Remarks: null,
            UserId: GLOBAL.USER_ID
          });
        }
      });
    });

    return finalList;
  }

  private checkComponentKey(value: string): boolean {
    return !!allowedComponentList.find(item => item === value);
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      payrollYear: [null, [Validators.required]],
      payrollMonth: [null, [Validators.required]],
      uploadList: [[], [Validators.required]]
    })
  }


  private getSiteCodeByUserID(): Observable<any> {
    const requestObj = {
      UserID: GLOBAL.USER_ID,
      RequireType: 0,
      SiteMIL_Code: null,
      ActionType: null
    };

    return this.importExcelService.getUserSiteCode(requestObj);
  }
}