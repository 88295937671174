import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManualPunchComponent } from './manual-punch/manual-punch.component';
import { Router } from '@angular/router';
import { GLOBAL } from 'src/app/app.globals';
import { AttendanceService } from '../attendance.service';
import { GridApi, GridOptions } from 'ag-grid-community';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { UtilService } from 'src/app/core/services/util.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';

@Component({
  selector: 'app-attendance-manual-punch',
  templateUrl: './attendance-manual-punch.component.html',
  styleUrls: ['./attendance-manual-punch.component.css']
})
export class AttendanceManualPunchComponent implements OnInit {
  selectedCount = 0;
  private defaultColDef;
  private defaultColDef1;
  private editType;
  selectedRows: any[] = [];
  rowClassRules;
  requiredFlag = false;
  private isRowSelectable;
  getRowNodeId;
  gridColumnApi;
  gridApi: GridApi;
  gridOptions: GridOptions;
  // public numberOfRowsPerPage = 20;
  groupingMeta = ['ContractorName'];
  columnDefs;
  columnDefs1;
  rowData = [];
  rowDataAll = [];
  rowSelection;
  sectionhead: any;
  contractor: any;
  erlogin: any;
  roleCode;
  // tslint:disable-next-line: variable-name
  UserId;
  type = 0;
  forContractorLogin = false;
  displayForContractorOnly = false;
  public alertOption: SweetAlertOptions = {};
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  dateValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('DD/MM/YYYY');
    }
    return '';
  }

  datetimeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('DD/MM/YYYY HH:mm');
    }

    return '';
  }
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private sharedService: SharedService,
    private utilService: UtilService,
    private logHelperService: LogHelperService,
    private attendanceService: AttendanceService
  ) {
    this.setColumnDef();
    this.setColumnDef1();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true; // !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        // this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      // onCellDoubleClicked(value): void {
      //   console.log("in on cell clicked:", value, value.api.getSelectedRows())
      //   let selectedDt: any[] = []
      //   selectedDt = value.api.getSelectedRows()
      //   console.log("selected dt:", selectedDt)
      //   if (!selectedDt.find(e => e.rowIndex == value.data.rowIndex)) {
      //     value.columnApi.getColumn('ActualWorkingHours').getColDef().editable = false;
      //     value.columnApi.getColumn('ActualOverTime').getColDef().editable = false;
      //     value.columnApi.getColumn('Comment').getColDef().editable = false;
      //   } else {
      //     value.columnApi.getColumn('ActualWorkingHours').getColDef().editable = true;
      //     value.columnApi.getColumn('ActualOverTime').getColDef().editable = true;
      //     value.columnApi.getColumn('Comment').getColDef().editable = true;
      //   }
      // },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: true,
      headerHeight: 40,
      // isFullWidthCell: (rowNode) => {
      //     return rowNode.data.fullWidth;
      // },
    };
    this.getRowNodeId = function (data) {
      return data.group ? data.group : `${data.AttPunch_Code}`;
    };
  }

  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
      this.displayForContractorOnly = true;
      this.columnDefs = [
        {
          headerName: '#',
          width: 40,
          pinned: 'left',
          suppressMenu: true,
          valueGetter(params) {
            return params.data.group ? '' : params.node.rowIndex;
          },
          colSpan: (params) => {
            return params.data.group ? 8 : 1;
          },
        },
        {
          headerName: 'Company',
          field: 'ContractorName',
          hide: true, visible: false,
          // headerName: 'Company', field: 'ContractorName', width: 100, sortable: true, filter: true,
          // filterParams: { applyButton: true, clearButton: true },
        },
        {
          headerName: 'GatePass No', width: 80, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true },
          valueGetter(params) {
            return params.data.group ? params.data.group : params.data.GatePassNo;
          },
          colSpan: (params) => {
            return params.data.group ? 5 : 1;
          },
        },
        {
          headerName: 'Name', field: 'FullName', width: 250, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Requested Date', field: 'RequestedDate', width: 150, sortable: true, filter: true,
          valueFormatter: this.dateValueFormatter,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Plant/Unit', field: 'PlantNameUnitName', width: 70, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'IN/OUT', field: 'InOut', width: 70, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Attendance Punch', field: 'AttendancePunch', width: 150, sortable: true, filter: true,
          valueFormatter: this.datetimeValueFormatter,
          filterParams: { applyButton: true, clearButton: true }
        },
        // {
        //   headerName: 'Attendance Code', field: 'AttPunch_Code', width: 0, hide: true
        // },
      ];
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.columnDefs = [
        // tslint:disable-next-line: ter-indent
        {
          headerName: '', field: '', width: 30,
          pinned: 'left',
          sortable: false, suppressMenu: true,
          cellRenderer: this.rowIndexCellRenderer,
          filter: false,
          // checkboxSelection: (params) => {
          //   return true; // params.data.group ? false : true;
          // },
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: '#',
          width: 50,
          pinned: 'left',
          suppressMenu: true,
          valueGetter(params) {
            return params.data.group ? '' : params.node.rowIndex;
          },
          colSpan: (params) => {
            return params.data.group ? 8 : 1;
          },
        },
        {
          headerName: 'Company',
          field: 'ContractorName',
          hide: true, visible: false,
          // headerName: 'Company', field: 'ContractorName', width: 100, sortable: true, filter: true,
          // filterParams: { applyButton: true, clearButton: true },
        },
        {
          headerName: 'GatePass No', width: 100, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true },
          valueGetter(params) {
            return params.data.group ? params.data.group : params.data.GatePassNo;
          },
          colSpan: (params) => {
            return params.data.group ? 5 : 1;
          },
        },
        {
          headerName: 'Name', field: 'FullName', width: 250, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Requested Date', field: 'RequestedDate', width: 150, sortable: true, filter: true,
          valueFormatter: this.dateValueFormatter,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Plant/Unit', field: 'PlantNameUnitName', width: 90, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'IN/OUT', field: 'InOut', width: 90, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Attendance Punch', field: 'AttendancePunch', width: 150, sortable: true, filter: true,
          valueFormatter: this.datetimeValueFormatter,
          filterParams: { applyButton: true, clearButton: true }
        },
      ];
    }
  }
  setColumnDef1() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs1 = [
      {
        headerName: '#',
        width: 50,
        pinned: 'left',
        suppressMenu: true,
        valueGetter(params) {
          return params.data.group ? '' : params.node.rowIndex;
        },
        colSpan: (params) => {
          return params.data.group ? 8 : 1;
        },
      },
      {
        headerName: 'Company',
        field: 'ContractorName',
        hide: true, visible: false,
        // headerName: 'Company', field: 'ContractorName', width: 100, sortable: true, filter: true,
        // filterParams: { applyButton: true, clearButton: true },
      },
      {
        headerName: 'GatePass No', width: 100, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true },
        valueGetter(params) {
          return params.data.group ? params.data.group : params.data.GatePassNo;
        },
        colSpan: (params) => {
          return params.data.group ? 5 : 1;
        },
      },
      {
        headerName: 'Name', field: 'FullName', width: 250, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'Requested Date', field: 'RequestedDate', width: 150, sortable: true, filter: true,
        valueFormatter: this.dateValueFormatter,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'Plant/Unit', field: 'PlantNameUnitName', width: 90, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'IN/OUT', field: 'InOut', width: 90, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'Punch', field: 'INOutTime', width: 150, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'Attendance Punch', field: 'AttendancePunch', width: 150, sortable: true, filter: true,
        valueFormatter: this.datetimeValueFormatter,
        filterParams: { applyButton: true, clearButton: true }
      },
      // {
      //   headerName: 'Attendance Code', field: 'AttPunch_Code', width: 0, hide: true
      // },
    ];

  }
  private onRowEditingStarted(event) {
    if (!this.gridApi.getSelectedRows().find(e => e.rowIndex === event.rowIndex)) {
      this.gridApi.stopEditing();
    }
  }
  ngOnInit() {
    // tslint:disable-next-line: comment-format
    this.getRoleCodeByUserID();
    this.rowSelection = 'multiple';
    this.getManualPunchApprovalList(this.type);
  }
  getRoleCodeByUserID() {

    this.attendanceService.getRoleByUserID().subscribe((res: any) => {

      if (res.Status) {
        this.roleCode = res.Data;
        this.sectionhead = this.roleCode.filter(list => list.Role_Code === 107);
        this.contractor = this.roleCode.filter(list => list.Role_Code === 119);
        this.erlogin = this.roleCode.filter(list => list.Role_Code === 120);
        if (this.sectionhead.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.forContractorLogin = true;
          this.displayForContractorOnly = false;
        }
        // tslint:disable-next-line: brace-style
        else if (this.contractor.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.forContractorLogin = false;
          this.displayForContractorOnly = true;
        }
        // tslint:disable-next-line: brace-style
        else if (this.erlogin.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.forContractorLogin = false;
          this.displayForContractorOnly = true;
        }
        // tslint:disable-next-line: brace-style
        else
        // tslint:disable-next-line: brace-style
        {
          this.forContractorLogin = false;
          this.displayForContractorOnly = false;
        }
        // tslint:disable-next-line: brace-style
      }
    });
  }
  getManualPunchApprovalList(type: number) {

    this.attendanceService.getManualPunchForApprovalrs(type).subscribe((res: any) => {
      if (res.Data.Table.length > 0) {
        // this.rowData = res.Data.Table;
        this.rowDataAll = res.Data.Table;
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res.Data.Table);
        this.rowData = tempRowData;
      } else {
        this.rowData = [];
      }
    });
  }
  onTabChange(event) {
    this.getManualPunchApprovalList(event.nextId);
  }
  // tslint:disable-next-line: function-name
  ManualPunch(): void {
    const modalRef = this.modalService.open(ManualPunchComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.save.subscribe(() => {
      modalRef.close('');
      this.getManualPunchApprovalList(this.type);
    });
  }

  onApprove() {


    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length > 0) {
      // tslint:disable-next-line: ter-indent
      this.attendanceService.approveAttendanceManualPunch(this.selectedRows).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Manual Punch Approved successfully'
        });
        this.getManualPunchApprovalList(this.type);
      });
    } else {
      this.logHelperService.logError({
        message: 'Please Select Alteast One Record'
      });
    }

  }

  onRowSelected(event) {
    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    }
  }

}
