import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { AgActionCellRendererComponent } from '../../../../core/components';

@Component({
  selector: 'app-absent-policy',
  templateUrl: './absent-policy.component.html'
})
export class AbsentPolicyComponent {

  @Input() public set rowData(value: any[]) {
    if (value) {
      this._rowData = value;
    }
  }
  public get rowData(): any[] {
    return this._rowData;
  }

  @Output() public edit: EventEmitter<any>;
  @Output() public delete: EventEmitter<any>;

  private _rowData: any[] = [];

  public columnDefs: any[];

  constructor() {
    this.edit = new EventEmitter<any>();
    this.delete = new EventEmitter<any>();
  }

  public ngOnInit(): void {
    this.columnDefs = this.createColumnDef(this.rowData);
  }

  public editItem(data: any): void {
    this.edit.emit(data);
  }

  public deleteItem(data: any): void {
    this.delete.emit(data);
  }

  private createColumnDef(data: any[]): any[] {
    const columns: any[] = [];
    if (data && data.length) {
      Object.keys(data[0]).forEach((item: any) => {
        columns.push({
          headerName: item,
          headerClass: 'text-left',
          field: item,
          sortable: true,
          width: 70,
          filter: true
        });
      });

      columns.push({
        headerName: 'Action', field: '', width: 40, headerComponentParams: { enableSorting: false },
        cellStyle: { textAlign: 'center' },
        cellRendererFramework: AgActionCellRendererComponent
      });
    }

    return columns;
  }

  // private createColumnDef(): void {
  //   this.columnDefs = [
  //     {
  //       headerName: '#', field: '', width: 30,
  //       sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
  //     },
  //     {
  //       headerName: 'ID', field: 'AbsentPolicy_Code', sortable: true, width: 40, filter: true,
  //       cellStyle: { textAlign: 'center' },
  //       hide: false,
  //     },
  //     { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 70, filter: true },
  //     { headerName: 'Policy Number', headerClass: 'text-left', field: 'ShortCode', sortable: true, width: 70, filter: true },
  //     { headerName: 'Version', headerClass: 'text-left', field: 'Version', sortable: true, width: 70, filter: true },
  //     { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 70, filter: true },
  //     { headerName: 'Applicable Date', headerClass: 'text-left', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
  //     { headerName: 'Validity Date', headerClass: 'text-left', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
  //     { headerName: 'Default Policy', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 50, filter: true },
  //     { headerName: 'Allow Time', field: 'MaxEarlyOut', sortable: true, width: 50, filter: true, cellStyle: { textAlign: 'center' }, },
  //     {
  //       headerName: 'Action', field: '', width: 40,
  //       sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
  //     }
  //   ];
  // }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }
}