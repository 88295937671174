import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor() { }

  toggleSidebar() {
    if (document.querySelector('body').classList.contains('sidebar-collapse')) {
      document.querySelector('body').classList.remove('sidebar-collapse');
    } else {
      document.querySelector('body').classList.add('sidebar-collapse');
    }
  }

}
