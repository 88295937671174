import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from 'src/app/app.globals';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class SharedService {

  public static dateFormat = 'DD-MM-YYYY hh:mm';
  rowIndex = 0;
  groupIndex = 0;
  masterSettingsData = [];
  public globalSettingsDataSource$: Observable<any>;
  public alertMessages$: Observable<any>;

  /** Subject call next */
  public stepFinalDataSubject: Subject<any>;
  private masterItemDataSource: BehaviorSubject<any> = new BehaviorSubject([]);
  private menuListDataSource: BehaviorSubject<any> = new BehaviorSubject([]);
  private globalSettingsDataSource: BehaviorSubject<any>;
  private alertMessages: BehaviorSubject<any>;
  finalStepData: any;
  public subjectCheckBox: Subject<any>;

  constructor(private cookieService: CookieService, private httpClient: HttpClient) {
    this.stepFinalDataSubject = new Subject<any>();
    this.subjectCheckBox = new Subject<any>();
    this.globalSettingsDataSource = new BehaviorSubject<any>(null);
    this.globalSettingsDataSource$ = this.globalSettingsDataSource.asObservable();
    this.alertMessages = new BehaviorSubject<any>(null);
    this.alertMessages$ = this.alertMessages.asObservable();
  }

  panCardSettings = {
    mask: 'aaaaa0000a',
    pattern: '^[A-Z]{5}[0-9]{4}[A-Z]{1}?$',
    placeHolder: 'AAAPL1234C',
  };

  aadharSettings = {
    mask: '0000-0000-0000',
    pattern: '^[0-9]{12}?$',
    placeHolder: '0000-0000-0000',
  };

  numberonly = {
    mask: '0000',
    pattern: '^[0-9]*$',
    placeHolder: '',
  };

  datePickerSettings = {
    mask: '00-00-0000',
    // timePicker: true,
    requestFormat: 'YYYY-MM-DDThh:mm:ss',
    placeHolder: 'DD-MM-YYYY',
    defaultOpen: false,
    pickerMode: 'popup',
    dateBeforeEighteenYears: new Date(new Date().getFullYear() - 18, new Date().getMonth() + 1, new Date().getDate())
  };
  timePickerSettings = {
    timeHolder: '00:00',
    pickerMode: 'popup',
  };
  dateOnlyPickerSettings = {
    mask: '00-00-0000',
    // timePicker: true,
    requestFormat: 'YYYY-MM-DD',
    placeHolder: 'DD-MM-YYYY',
    defaultOpen: false,
    pickerMode: 'popup',
    dateBeforeEighteenYears: new Date(new Date().getFullYear() - 18, new Date().getMonth() + 1, new Date().getDate())
  };

  timeOnlyPickerSettings = {
    mask: '00:00',
    timePicker: true,
    requestFormat: 'HH:mm',
    placeHolder: 'HH:MM',
    defaultOpen: false,
    pickerMode: 'popup',
    regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
  };

  globalInputMask = {
    EPFFormat: 'aa/aaa/9999999/999/999',
    EPFPlaceHolder: 'MHBAN00000640000000123',
    ESICFormat: '99–99–999999–999–9999',
    ESICPlaceHolder: '31–00–123456–000–0001'
  }

  /**
   * @author Ashok Yadav.
   * @method getAllContractorData
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getAllContractorData(): Observable<any> {
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_CONTRACTORS);
  }

  /**
   * @author Ashok Yadav.
   * @method getWorkOrderByContractor
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getWorkOrderByContractr(data: any): Observable<any> {
    return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.
      GET_PASS_MANAGEMENT}GetWorkOrderByContractor?ID=${data.contractorVendorCode}&ReqType=${data.requestType}`);
  }

  /**
   * @author Ashok Yadav.
   * @method getLicenseByContractor
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getLicenseByContractor(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT
      + 'GetLicenseByContractor?ID=' + data.parentContractorVendorCode
      + '&ReqType=' + data.requestType + '&workordercode=' + data.parentWorkOrderCode);

  }

  generateRandomPassword(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$_&*^!@';
    const charactersLength = characters.length;
    // tslint:disable-next-line:no-increment-decrement
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  setCookie(key: string, value: any) {
    this.cookieService.set(key, value);
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  checkCookie(key: string) {
    return this.cookieService.check(key);
  }

  getAllCookie() {
    return this.cookieService.getAll();
  }

  deleteCookie(key: string) {
    return this.cookieService.delete(key);
  }

  deleteAllCookie() {
    return this.cookieService.deleteAll();
  }

  globalMasterItemData(): Observable<any> {

    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_MASTER}`,
      httpOptions
    );
  }

  getMenuListData(): Observable<any> {
    const queryString = $.param({
      userId: localStorage.getItem('UserID')
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_MENU_LIST}?${queryString}`,
      httpOptions
    );
  }

  // globalMasterSettingData() {
  //   const model = {
  //     Code: ''
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}`, model).pipe(
  //     map((response: any) => response));

  // }
  public globalMasterSettingData() {
    const queryString = $.param({
      Code: '',
      Search: ''
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?${queryString}`,
      httpOptions
    );
  }

  public getAlertMessageByCode(shortCode: any = null): Observable<any> {
    const requestObj = {
      ShortCode: shortCode
    }
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.COMMAN_SP_ApplicationAlertMSG}`, requestObj);
  }

  groupByMulti(obj, values) {
    if (!values.length) {
      return obj;
    }
    const byFirst = _.groupBy(obj, values[0]);
    const rest = values.slice(1);
    for (const prop in byFirst) {
      byFirst[prop] = this.groupByMulti(byFirst[prop], rest);
    }
    return byFirst;
  }

  getInnerObject(row, key) {
    let finalKey = '';
    if (!Array.isArray(row)) {
      finalKey = this.getInnerObject(row[Object.keys(row)[0]], key);
    } else {
      finalKey = _.invert(row[0])[key];
    }
    return finalKey;
  }

  iterateRows(rowData, finalArray, groupProperties) {
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        const row = rowData[key];
        if (Array.isArray(row)) {
          this.groupIndex = 0;
          finalArray.push({
            groupKey: groupProperties[groupProperties.length - 1],
            groupLevel: groupProperties.length - 1,
            parent: true,
            isVisible: true,
            group: key,
            cnt: row.length,
            childRowData: row,
            ...row
          });
          for (let index = 0; index < row.length; index++) {
            const element = row[index];
            this.rowIndex++;
            finalArray.push({
              rowIndex: this.rowIndex,
              isVisible: true,
              ...element
            });
          }
        } else {
          finalArray.push({
            groupKey: groupProperties[this.groupIndex],
            groupLevel: this.groupIndex,
            parent: true,
            isVisible: true,
            group: key,
            ...row
          });
          this.groupIndex++;
          this.iterateRows(row, finalArray, groupProperties);
        }
      }
    }
    return finalArray;
  }

  agGridGroupRowOnProperties(groupProperties: string[], data: any[]) {
    const result = this.groupByMulti(data, groupProperties);
    this.rowIndex = 0;
    this.groupIndex = 0;
    const tempRowData = this.iterateRows(result, [], groupProperties);
    return tempRowData;
  }

  calculateAge(dateString) {

    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (age > 18 && age < 60) {
      return age;
    }
  }

  adddate(dateString) {
    const today = new Date();
    const verificationdate = new Date(dateString);
    let newdate: Date;
    if (today === verificationdate) {
      newdate = new Date(today.setMonth(today.getMonth() + 6));
    } else {
      newdate = new Date(verificationdate.setMonth(verificationdate.getMonth() + 6));
    }
    return newdate;
  }

  setMasterData(data) {
    this.masterItemDataSource.next(data);
    // alert(this.masterItemDataSource.value);
  }

  getMasterData() {
    // alert(this.masterItemDataSource.value);
    return this.masterItemDataSource.asObservable();
  }

  setGlobalSettings(data) {
    this.globalSettingsDataSource.next(data);
  }

  getGlobalSettings() {
    return this.globalSettingsDataSource.asObservable();
  }

  setAlertMessages(data) {
    this.alertMessages.next(data);
  }

  getAlertMessages() {
    return this.alertMessages.asObservable();
  }

  public DownloadCLPMSsampleExel(payload): Observable<any>{
    const url = `${GLOBAL.APIS.COMMON.CLPMS_LINK_SAMPLE_EXEL}`
    return this.httpClient.post(url, payload)
  }
  public getGlobalSettingData(): Observable<any>{
    let payload = {
    "Search":null,
    "Code": null,
    "GroupCode":null,
    "SiteMIL_Code":null,
    "Plant_Code": null,
    "Company_Code":null,
    "AppName":null,
    "Module":null,
    "Page":null,
    "SettingName":null,
    "Role":null,
    "RequireType": 0 , // 0: Listing Data  | 1: data for specific code wise and display for pop-up | 2: Free Search
    "ActionType": 0 , //
    "UserId ": localStorage.getItem('UserID')
    }
    const url = `${GLOBAL.APIS.MASTER_DATA.COMMAN_GLOBALSETTINGDATA_LISTING}`
    return this.httpClient.post(url, payload)
  }
  public updateGlobalSetting(payload): Observable<any>{
    const url = `${GLOBAL.APIS.MASTER_DATA.COMMAN_GLOBAL_SETTING_DATA_INSERT_UPDATE}`
    return this.httpClient.post(url, payload)
  }
  getTableheaderData(payload){
      return this.httpClient.post(`${GLOBAL.APIS.COMMON.CLPMS_SP_ReportHeaderDetails_V1}`, payload);
    }
}
