import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    ChangeDetectorRef,
    AfterViewInit,
    Input,
    OnChanges,
    ElementRef,
    Renderer2,
    ComponentRef,
    Output,
    EventEmitter
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectStepsComponent } from '../select-steps/select-steps.component';
import { ModalConfig, BackdropColor, ModelDialogClass, DataFilter, AdvanceFilters, FormInstance } from './select-steps-filter.model';
import { GridApi, GridOptions } from 'ag-grid-community';
import { SelectStepsFilterService } from '../../services/select-steps-filter.service';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SelectStepsService } from '../select-steps/select-step.service';
import { Subject } from 'rxjs';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AdvanceFilterDynamicComponent } from './advance-filter-dynamic/advance-filter-dynamic.component';
import { AdvanceFilterTypeConditionComponent } from './advance-filter-type-condition/advance-filter-type-condition.component';

@Component({
  selector: 'app-select-steps-filter-wrapper',
  templateUrl: './select-steps-filter-wrapper.component.html',
  styleUrls: ['./select-steps-filter-wrapper.component.css']
})
export class SelectStepsFilterWrapperComponent implements OnInit, OnChanges, AfterViewInit {

  @Output() selectedValue: EventEmitter<any> = new EventEmitter();

  @Input() public config: any;
  @ViewChild('advanceFilter') public elementRef: ElementRef;
  @ViewChild('review') public reviewRef: ElementRef;
  @ViewChild('reviewStyle') public reviewStyleRef: ElementRef;
  @ViewChild('active') public activeRef: ElementRef;
  @ViewChild('activeStyle') public activeStyleRef: ElementRef;
  @ViewChild('deactivate') public deactivateRef: ElementRef;
  @ViewChild('deactivateStyle') public deactivateStyleRef: ElementRef;
  @ViewChild('debarred') public debarredRef: ElementRef;
  @ViewChild('debarredStyle') public debarredStyleRef: ElementRef;
  @ViewChild('advanceTypeConditionFilter') public advanceTypeConditionElementRef: ElementRef;
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  @ViewChild('filterTemplateRef') public filterTemplateRef: TemplateRef<HTMLElement>;
    /** This output event emmiter invoke when next button trigger */
  @Output() public changeSubmitAction: EventEmitter<any> = new EventEmitter();
  public callOnApiResponse: Subject<any> = new Subject();
  public fieldValue = new FormInstance();
  private overlayRef: OverlayRef;
  private advanceTypeConditionOverlayRef: OverlayRef;
  public fontPixel = '30px';
  private isShowFilter: boolean;
  public isActive: boolean;
  public stepsTitleName: string;
  public isDeactivate: boolean;
  public isDebarred: boolean;
  public isReview: boolean;
  private isShowTypeConditionFilter: boolean;
  public stepsData: any[];
  public stepsForm: FormGroup;
  public formGroup: FormGroup;
  public role: any;
  public rowSelectedData: any;
  public rowFilterData: any[];
  public requestType: number;
  public isContractor: boolean;
  public isWorkOrder: boolean;
  public isEcPolicy: boolean;
  public isLicense: boolean;
  public isUserList: boolean;
  public isSequence: boolean;
  public isShift: boolean;
  public isProduct: boolean;
  public dataList: any[];
  public contractorColumnDefs: any[];
  public workOrderColumnDefs: any[];
  public licenseColumnDefs: any[];
  public ecPolicyColumnDefs: any[];
  private userListColumnDefs: any[];
  public nextTriggerSteps: any;
  public confiKeys: any[] = [];
  public configIndex: number;
  public contractorStepIndex: number;
  public workOrderStepIndex: number;
  public licenseStepIndex: number;
  public ecPolicyStepIndex: number;
  public userListStepIndex: number;
  private isFirstSteps: boolean;
  private licenceData: any;
  private ecPolicyData: any;
  private isEcPolicyData: boolean;
  private isLicenceData: boolean;
  private isUserListData: boolean;
  private isShiftData: boolean;
  private licenseCode: any;
  private workOrderData: any;
  private isWorkOrderData: boolean;
  private contractorButtonConfig: any;
  private ecPolicyButtonConfig: any;
  private workOrderButtonConfig: any;
  private userListData: any;
  private userListButtonConfig: any;
  private licenseButtonConfig: any;
  private shiftButtonConfig: any;
  private productButtonConfig: any;
  private isContractorFooter: boolean;
  private isWorkOrderFooter: boolean;
  private isLicenseFooter: boolean;
  private isUserListFooter: boolean;
  private isEcPolicyFooter: boolean;
  private isShiftFooter: boolean;
  private isProductFooter: boolean;
    /** Hold form group of new get pass */
  public contactorForm: FormGroup;
    /** Hold boolean value */
  public isSelectedContractor: boolean;
    /** Hold boolean value isSelectLicencePrevCallOnce */
  public isSelectLicencePrevCallOnce: boolean;
    /** Hold boolean value */
  public isSelectedContractorAndWorkOrder: boolean;
    /** Hold boolean value of isSelectedContractorWorkOrderAndLicense */
  public isSelectedContractorWorkOrderAndLicense: boolean;
    /** Here this variable hold the value of `selected contractor` */
  public selectedContractor: string;
    /** Here this variable hold the value of `selected company` */
  public selectedCompanyName: string;
    /** Here this variable hold the value of `selectedWorkerOrderNo` */
  public selectedWorkOrderNo: string;
    /** Here this variable hold the value of `selectedWorkerOrderCode` */
  public selectedWorkOrderCode: string;
    /** Here this variable hold the value of `selectedWorkerOrderNo` */
  public selectedWorkerOrderNo: string;
    /** Here this variable hold the value of `selectedLicense` */
  public selectedLicense: string;
  public shiftData: any;
  constructor(
        private formBuilder: FormBuilder,
        private service: SelectStepsFilterService,
        private selectStepService: SelectStepsService,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef) {
    this.configIndex = 0;
    this.isActive = false;
    this.isDeactivate = false;
    this.isDebarred = false;
    this.isReview = false;
    this.isUserListFooter = false;
    this.isShowFilter = false;
    this.stepsData = [];
    this.dataList = [];
    this.nextTriggerSteps = [];
    this.role = 111;
    this.requestType = 104;
    this.isShiftData = false;
    this.stepsTitleName = null;
    this.isLicenceData = false;
    this.isEcPolicyData = false;
    this.isUserListData = false;
    this.isContractor = false;
    this.isWorkOrder = false;
    this.isUserList = false;
    this.isLicense = false;
    this.isEcPolicy = false;
    this.isEcPolicyFooter = false;
    this.isSequence = false;
    this.isShift = false;
    this.isProduct = false;
    this.isFirstSteps = false;
    this.isSelectedContractor = false;
    this.isShowTypeConditionFilter = false;
    this.isSelectedContractorAndWorkOrder = false;
    this.isSelectedContractorWorkOrderAndLicense = false;
    this.isContractorFooter = false;
    this.isWorkOrderFooter = false;
    this.isUserListFooter = false;
    this.isEcPolicyData = false;
    this.isLicenseFooter = false;
    this.isShiftFooter = false;
    this.isProductFooter = false;
  }

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
        // size: '',
    closeOnOutSideClick: false
  };
  public gridRowData: any[] = [];
  public numberOfRowsPerPage = 10;
  public gridAPI: GridApi;
  public alertOption: SweetAlertOptions = {};
  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
        // getRowWidth: (params) => {
        //   return 40;
        // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  public ngOnInit(): void {
    this.setFormControl();
    this.setConfigFilter();
  }

  public setFormControl(): void {
    this.formGroup = this.formBuilder.group({
      contractor: ['', Validators.required],
      workerOrder: ['', Validators.required],
      license: ['', Validators.required],
      ecPolicy: ['', Validators.required],
      userList: ['', Validators.required]
    });
    Object.keys(this.config).forEach((value: any) => {
      this.nextTriggerSteps.push(value);
    });
    this.stepsForm = this.formBuilder.group({});
  }

  public onTriggerSetTemplate(): void {
    this.setAdvanceFilterOverlayConfig();
    this.setAdvanceFilterTypeConditionOverlayConfig();
    this.renderer.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      console.log('simple', event);
      if (event.target.className !== 'd-flex flex-wrap align-items-center mr-3') { return; }
      this.openAdvanceFilter();
    });
    this.renderer.listen(this.advanceTypeConditionElementRef.nativeElement, 'click', (event) => {
      console.log('simpleTypeCondition', event);
      this.openAdvanceTypeConditionFilter();
    });
    this.overlayRef.backdropClick().subscribe(_ => this.openAdvanceFilter());
    this.advanceTypeConditionOverlayRef.backdropClick().subscribe(_ => this.openAdvanceTypeConditionFilter());
    this.addClickEventListner();
    this.setActiveStyle();
    this.setDeactivateStyle();
    this.setDebarredStyle();
    this.setReviewStyle();
  }

  public setConfigFilter(): void {
    Object.keys(this.config).forEach((value: any) => {
      if (value === 'contractor' && this.config[value]) {
        const contractor = {
          labelName: this.config[value].labelText, placeholderName: this.config[value].placeholderText,
          formControlName: 'contractor', name: 'contractor'
        };
        this.dataList.push(contractor);
        this.contractorColumnDefs = this.config[value].contractorColumnDefs;
        if (!this.isFirstSteps) {
          this.isFirstSteps = true;
          this.configIndex++;
          this.getAllcontractorData();
        }
      } else if (value === 'workOrder' && this.config[value]) {
        const workOrder = {
          labelName: this.config[value].labelText, placeholderName: this.config[value].placeholderText,
          formControlName: 'workerOrder', name: 'workerOrder'
        };
        this.dataList.push(workOrder);
        this.workOrderColumnDefs = this.config[value].workOrderColumnDefs;
        if (!this.isFirstSteps) {
          this.isFirstSteps = true;
          this.configIndex++;
          this.getWorkOrder();
        }
      } else if (value === 'license' && this.config[value]) {
        const license = {
          labelName: this.config[value].labelText, placeholderName: this.config[value].placeholderText,
          formControlName: 'license', name: 'license'
        };
        this.dataList.push(license);
        this.licenseColumnDefs = this.config[value].licenseColumnDefs;
        if (!this.isFirstSteps) {
          this.isFirstSteps = true;
          this.configIndex++;
          this.getLicense();
        }
      } else if (value === 'ecPolicy' && this.config[value]) {
        const ecpolicy = {
          labelName: this.config[value].labelText, placeholderName: this.config[value].placeholderText,
          formControlName: 'ecPolicy', name: 'ecPolicy'
        };
        this.dataList.push(ecpolicy);
        this.ecPolicyColumnDefs = this.config[value].ecPolicyColumnDefs;
        if (!this.isFirstSteps) {
          this.isFirstSteps = true;
          this.configIndex++;
          this.getECPolicy();
        }
      } else if (value === 'userList' && this.config[value]) {
        const userList = {
          labelName: this.config[value].labelText, placeholderName: this.config[value].placeholderText,
          formControlName: 'userList', name: 'userList'
        };
        this.dataList.push(userList);
        this.userListColumnDefs = this.config[value].userListColumnDefs;
        if (!this.isFirstSteps) {
          this.isFirstSteps = true;
          this.configIndex++;
          this.getUserList();
        }
      }
    });
    this.callOnApiResponse.subscribe((value: any) => {
      if (value === 'contractor') {
        this.configIndex++;
        this.getAllcontractorData();
      } else if (value === 'workOrder') {
        this.configIndex++;
        this.getWorkOrder();
      } else if (value === 'license') {
        this.configIndex++;
        this.getLicense();
      } else if (value === 'ecPolicy') {
        this.configIndex++;
        this.getECPolicy();
      } else if (value === 'userList') {
        this.configIndex++;
        this.getUserList();
      }
    });
  }

  public setAdvanceFilterOverlayConfig(): void {
    const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withPositions([{
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetY: -4,
              offsetX: -3
            }]);
    const config = {
      positionStrategy,
      hasBackdrop: false,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    };
    this.overlayRef = this.overlay.create(config);
  }

  public setAdvanceFilterTypeConditionOverlayConfig(): void {
    const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.advanceTypeConditionElementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withPositions([{
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetY: 1,
              offsetX: -3
            }]);
    const config = {
      positionStrategy,
      hasBackdrop: false,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    };
    this.advanceTypeConditionOverlayRef =
            this.overlay.create(config);
  }

  public getContractorFilterData(filterData: any): void {
    const data = { search: filterData.contractorCompanyName, sDate: null, eDate: null, isFilter: null, field: null };
    this.service.getContractorFilterData(data).subscribe((response: any) => {
      this.rowFilterData = response.Data.Table;
    });
  }

  public getWorkOrderFilterData(filterData: {}): void {
    const item = {};
    this.service.getWorkOrderFilterData(item).subscribe((response: any[]) => {
      this.rowFilterData = response;
    });
  }

  public getLicenseFilterData(filterData: {}): void {
    const item = {};
    this.service.getLicenceFilterData(item).subscribe((response: any[]) => {
      this.rowFilterData = response;
    });
  }

  public getEcPolicyFilterData(filterData: {}): void {
    const item = {};
    this.service.getEcPolicyFilterData(item).subscribe((response: any[]) => {
      this.rowFilterData = response;
    });
  }

  public getUserListFilterData(filterData: {}): void {
    const item = {};
    this.service.getLicenceFilterData(item).subscribe((response: any[]) => {
      this.rowFilterData = response;
    });
  }

  public getWorkorderFilterData(): void {

  }

  public openModel(e: any, stepValue: any): void {
    const input: HTMLInputElement = <HTMLInputElement>e.target;
    input.blur();
    this.stepsComp.openModel();
    if (stepValue === 'contractor' && this.contractorStepIndex !== 0) {
      const itemOnNext = { value: this.contractorStepIndex, rowData: this.workOrderData };
      this.stepsComp.subject.next(itemOnNext);
    } else if (stepValue === 'workerOrder' && this.workOrderStepIndex !== 0) {
      const itemOnNext = { value: this.workOrderStepIndex, rowData: this.workOrderData };
      this.stepsComp.subject.next(itemOnNext);
    } else if (stepValue === 'license' && this.licenseStepIndex !== 0) {
      const itemOnNext = { value: this.licenseStepIndex, rowData: this.licenceData };
      this.stepsComp.subject.next(itemOnNext);
    } else if (stepValue === 'ecPolicy' && this.ecPolicyStepIndex !== 0) {
      const itemOnNext = { value: this.ecPolicyStepIndex, rowData: this.ecPolicyData };
      this.stepsComp.subject.next(itemOnNext);
    } else if (stepValue === 'userList' && this.userListStepIndex !== 0) {
      const itemOnNext = { value: this.userListStepIndex, rowData: this.userListData };
      this.stepsComp.subject.next(itemOnNext);
    }

    // setTimeout(() => {
    //   this.setAdvanceFilterOverlayConfig();
    //   this.setAdvanceFilterTypeConditionOverlayConfig();
    //   this.renderer.listen(this.elementRef.nativeElement, 'click', (event: any) => {
    //     console.log('simple', event);
    //     if (event.target.className !== 'd-flex flex-wrap align-items-center mr-3') { return; }
    //     this.openAdvanceFilter();
    //   });
    //   this.renderer.listen(this.advanceTypeConditionElementRef.nativeElement, 'click', (event) => {
    //     console.log('simpleTypeCondition', event);
    //     this.openAdvanceTypeConditionFilter();
    //   });
    //   this.overlayRef.backdropClick().subscribe(_ => this.openAdvanceFilter());
    //   this.advanceTypeConditionOverlayRef.backdropClick().subscribe(_ => this.openAdvanceTypeConditionFilter());
    // }, 800);
    // this.addClickEventListner();
    // this.setActiveStyle();
    // this.setDeactivateStyle();
    // this.setDebarredStyle();
    // this.setReviewStyle();
  }

  public addClickEventListner(): void {
    this.renderer.listen(this.activeRef.nativeElement, 'click', (event) => {
      this.isActive = !this.isActive;
      this.isDeactivate = false;
      this.isDebarred = false;
      this.isReview = false;
      this.setActiveStyle();
      this.setDeactivateStyle();
      this.setDebarredStyle();
      this.setReviewStyle();
    });

    this.renderer.listen(this.deactivateRef.nativeElement, 'click', (event) => {
      this.isDeactivate = !this.isDeactivate;
      this.isActive = false;
      this.isDebarred = false;
      this.isReview = false;
      this.setActiveStyle();
      this.setDeactivateStyle();
      this.setDebarredStyle();
      this.setReviewStyle();
    });

    this.renderer.listen(this.debarredRef.nativeElement, 'click', (event) => {
      this.isDebarred = !this.isDebarred;
      this.isActive = false;
      this.isDeactivate = false;
      this.isReview = false;
      this.setActiveStyle();
      this.setDeactivateStyle();
      this.setDebarredStyle();
      this.setReviewStyle();
    });

    this.renderer.listen(this.reviewRef.nativeElement, 'click', (event) => {
      this.isReview = !this.isReview;
      this.isActive = false;
      this.isDeactivate = false;
      this.isDebarred = false;
      this.setActiveStyle();
      this.setDeactivateStyle();
      this.setDebarredStyle();
      this.setReviewStyle();
    });
  }

  public setActiveStyle(): void {
    // setTimeout(() => {
    this.renderer.removeStyle(this.activeStyleRef.nativeElement, 'font-size');
    this.renderer.removeStyle(this.activeStyleRef.nativeElement, 'color');
    this.renderer.setStyle(this.activeStyleRef.nativeElement, 'font-size', '30px');
    const color = this.isActive ? '#50C876' : 'lightgray';
    this.renderer.setStyle(this.activeStyleRef.nativeElement, 'color', color);
    // }, 800);
  }

  public setDeactivateStyle(): void {
    // setTimeout(() => {
    this.renderer.removeStyle(this.deactivateStyleRef.nativeElement, 'font-size');
    this.renderer.removeStyle(this.deactivateStyleRef.nativeElement, 'color');
    this.renderer.setStyle(this.deactivateStyleRef.nativeElement, 'font-size', '30px');
    const color = this.isDeactivate ? 'darkgray' : 'lightgray';
    this.renderer.setStyle(this.deactivateStyleRef.nativeElement, 'color', color);
    // }, 800);
  }

  public setDebarredStyle(): void {
    // setTimeout(() => {
    this.renderer.removeStyle(this.debarredStyleRef.nativeElement, 'font-size');
    this.renderer.removeStyle(this.debarredStyleRef.nativeElement, 'color');
    this.renderer.setStyle(this.debarredStyleRef.nativeElement, 'font-size', '30px');
    const color = this.isDebarred ? '#FF2626' : 'lightgray';
    this.renderer.setStyle(this.debarredStyleRef.nativeElement, 'color', color);
    // }, 800);
  }

  public setReviewStyle(): void {
    // setTimeout(() => {
    this.renderer.removeStyle(this.reviewStyleRef.nativeElement, 'font-size');
    this.renderer.removeStyle(this.reviewStyleRef.nativeElement, 'color');
    this.renderer.setStyle(this.reviewStyleRef.nativeElement, 'font-size', '30px');
    const color = this.isReview ? '#FF7A4D' : 'lightgray';
    this.renderer.setStyle(this.reviewStyleRef.nativeElement, 'color', color);
    // }, 800);
  }

  public openAdvanceFilter(): void {
    this.isShowFilter = !this.isShowFilter;
    if (this.isShowFilter) {
      this.showFilter();
    } else {
      this.hideFilter();
    }
  }

  public openAdvanceTypeConditionFilter(): void {
    this.isShowTypeConditionFilter = !this.isShowTypeConditionFilter;
    if (this.isShowTypeConditionFilter) {
      this.showTypeConditionFilter();
    } else {
      this.hideTypeConditionFilter();
    }
  }

  public onNextEvent(value: any): void {
    if (value.arrayItem[0].title === 'Contractor') {
      this.stepsTitleName = 'CONTRACTOR';
    } else if (value.arrayItem[0].title === 'Work Order') {
      this.stepsTitleName = 'WORKORDER';
    } else if (value.arrayItem[0].title === 'License') {
      this.stepsTitleName = 'LICENSE';
    } else if (value.arrayItem[0].title === 'Ec Policy') {
      this.stepsTitleName = 'ECPOLICY';
    } else if (value.arrayItem[0].title === 'User List') {
      this.stepsTitleName = 'USERLIST';
    }

    this.addClickEventListner();
    this.isActive = false;
    this.isDeactivate = false;
    this.isDebarred = false;
    this.isReview = false;
    this.setActiveStyle();
    this.setDeactivateStyle();
    this.setDebarredStyle();
    this.setReviewStyle();
  }

  public onPrevEvent(item: any): void {
    this.addClickEventListner();
    this.isActive = false;
    this.isDeactivate = false;
    this.isDebarred = false;
    this.isReview = false;
    this.setActiveStyle();
    this.setDeactivateStyle();
    this.setDebarredStyle();
    this.setReviewStyle();
    if (item.data[0].title === 'Contractor') {
      this.formGroup.controls.workerOrder.reset();
      this.stepsTitleName = 'CONTRACTOR';
    } else if (item.data[0].title === 'Work Order') {
      this.stepsTitleName = 'WORKORDER';
      this.formGroup.controls.license.reset();
    } else if (item.data[0].title === 'License') {
      this.stepsTitleName = 'LICENSE';
      this.formGroup.controls.ecPolicy.reset();
    } else if (item.data[0].title === 'Ec Policy') {
      this.stepsTitleName = 'ECPOLICY';
      this.formGroup.controls.userList.reset();
    }
  }

  public onCancelAction(value: any): void {

  }

  public onSubmitAction(item: any): void {
    this.changeSubmitAction.emit({ fieldData: this.fieldValue, steps: this.stepsTitleName });
    item.activeModal.dismiss('Cross click');
    this.selectedValue.emit(this.rowSelectedData);
  }

  public onCloseAction(value: any): void {
    if (this.stepsData.length > 1) {
      for (let i = 0; i < this.stepsData.length; i++) {
                //  this.stepsData.pop();
      }
    }
    this.isActive = false;
    this.isDeactivate = false;
    this.isDebarred = false;
    this.isReview = false;
    this.stepsTitleName = null;
    this.isContractor = false;
    this.isWorkOrder = false;
    this.isLicense = false;
    this.isEcPolicy = false;
    this.isUserList = false;
    this.hideFilter();
    this.hideTypeConditionFilter();
    console.log('stepsDataLength', this.stepsData);
  }

  public getSelectedRowData(item: any, value: any): void {
    if (this.requestType !== value) { return; }
    if (item.arrayItem[0].title === 'Contractor') {
      this.formGroup.patchValue({
        contractor: item.data.Company
      });
      this.stepsTitleName = 'CONTRACTOR';
      this.fieldValue.contractor = item.data;
            //  item.activeModal.dismiss('Cross click');
    } else if (item.arrayItem[0].title === 'Work Order') {
      this.formGroup.patchValue({
        workerOrder: item.data.WorkOrderNo
      });
      this.stepsTitleName = 'WORKORDER';
      this.fieldValue.workOrder = item.data;
    } else if (item.arrayItem[0].title === 'License') {
      this.formGroup.patchValue({
        license: item.data.LicenseNo
      });
      this.stepsTitleName = 'LICENSE';
      this.fieldValue.license = item.data;
    } else if (item.arrayItem[0].title === 'Ec Policy') {
      this.formGroup.patchValue({
        ecPolicy: item.data.ECPolicyNo
      });
      this.stepsTitleName = 'ECPOLICY';
      this.fieldValue.ecPolicy = item.data;
    } else if (item.arrayItem[0].title === 'User List') {
      this.formGroup.patchValue({
        userList: item.data.AlternetEmailID
      });
      this.stepsTitleName = 'USERLIST';
      this.fieldValue.userList = item.data;
    }
  }

  public onSelectQuestion(value: any): void {

  }

  public changeOnSkip(item: any): void {
    if (item.title === 'Contractor') {
      this.formGroup.controls.contractor.reset();
    } else if (item.title === 'Work Order') {
      this.formGroup.controls.workerOrder.reset();
    } else if (item.title === 'License') {
      this.formGroup.controls.licence.reset();
    } else if (item.title === 'Ec Policy') {
      this.formGroup.controls.ecPolicy.reset();
    } else if (item.title === 'User List') {
      this.formGroup.controls.userList.reset();
    }
  }

  public ngOnChanges(): void {
    if (!this.config) { return; }
    let configLength: number[];
    configLength = [];
    Object.keys(this.config).forEach((value: any, index: number) => {
      configLength.push(index);
    });
    Object.keys(this.config).forEach((value: any, index: number) => {
      if (value === 'contractor') {
        this.contractorStepIndex = index;
        if (configLength.length - 1 === index) {
          this.contractorButtonConfig = { skip: false, cancel: false, submit: true, prev: true, next: false, close: false };
        } else {
          this.contractorButtonConfig = { skip: true, cancel: false, submit: true, prev: true, next: true, close: false };
        }
      } else if (value === 'workOrder') {
        this.workOrderStepIndex = index;
        if (configLength.length - 1 === index) {
          this.workOrderButtonConfig = { skip: false, cancel: false, submit: true, prev: true, next: false, close: false };
        } else {
          this.workOrderButtonConfig = { skip: true, cancel: false, submit: true, prev: true, next: true, close: false };
        }
      } else if (value === 'license') {
        this.licenseStepIndex = index;
        if (configLength.length - 1 === index) {
          this.licenseButtonConfig = { skip: false, cancel: false, submit: true, prev: true, next: false, close: false };
        } else {
          this.licenseButtonConfig = { skip: true, cancel: false, submit: true, prev: true, next: true, close: false };
        }
      } else if (value === 'ecPolicy') {
        this.ecPolicyStepIndex = index;
        if (configLength.length - 1 === index) {
          this.ecPolicyButtonConfig = { skip: false, cancel: false, submit: true, prev: true, next: false, close: false };
        } else {
          this.ecPolicyButtonConfig = { skip: true, cancel: false, submit: true, prev: true, next: true, close: false };
        }
      } else if (value === 'userList') {
        this.userListStepIndex = index;
        if (configLength.length - 1 === index) {
          this.userListButtonConfig = { skip: false, cancel: false, submit: true, prev: true, next: false, close: false };
        } else {
          this.userListButtonConfig = { skip: true, cancel: false, submit: true, prev: true, next: true, close: false };
        }
      }
    });
  }

  public ngAfterViewInit(): void {
  }

  public onFormControl(value: string): void {
    if (value === 'contractor') {
      this.formGroup.controls.contractor.reset();
    } else if (value === 'workerOrder') {
      this.formGroup.controls.workerOrder.reset();
    } else if (value === 'license') {
      this.formGroup.controls.license.reset();
    } else if (value === 'ecPolicy') {
      this.formGroup.controls.ecPolicy.reset();
    } else if (value === 'userList') {
      this.formGroup.controls.userList.reset();
    }
  }

  private showFilter(): void {
    const filterRef: ComponentRef<AdvanceFilterDynamicComponent>
            = this.overlayRef.attach(new ComponentPortal(AdvanceFilterDynamicComponent));
    filterRef.instance.filterData.subscribe((filterData: {}) => {
      if (this.stepsTitleName === 'CONTRACTOR') {
        this.getContractorFilterData(filterData);
      } else if (this.stepsTitleName === 'WORKORDER') {
        this.getWorkOrderFilterData(filterData);
      } else if (this.stepsTitleName === 'LICENSE') {
        this.getLicenseFilterData(filterData);
      } else if (this.stepsTitleName === 'ECPOLICY') {
        this.getEcPolicyFilterData(filterData);
      } else if (this.stepsTitleName === 'USERLIST') {
        this.getUserListFilterData(filterData);
      } else if (!this.stepsTitleName) {
        this.getContractorFilterData(filterData);
      }
    });
    console.log('filterRef', filterRef);
  }

  private hideFilter(): void {
    this.overlayRef.detach();
  }

  private showTypeConditionFilter(): void {
    const filterRef: ComponentRef<AdvanceFilterTypeConditionComponent>
            = this.advanceTypeConditionOverlayRef.attach(new ComponentPortal(AdvanceFilterTypeConditionComponent));
    console.log('filterRefTypeCondition', filterRef);
  }

  private hideTypeConditionFilter(): void {
    this.advanceTypeConditionOverlayRef.detach();
  }

    /**
     * @method getAllcontractorData
     * @description Get All contractor data.
     */
  private getAllcontractorData(): void {
    const data = { search: '', sDate: '', eDate: '', isFilter: '', field: '' };
    this.service.getContractorFilterData(data).subscribe((contractorResponse) => {
      this.isContractor = true;
      this.gridRowData = contractorResponse.Data.Table;
      this.setStepArray();
    });
  }

    /**
     * @method selectContractor
     * @description Get All contractor data.
     */
  private getWorkOrder(): void {
    this.isWorkOrder = true;
    const data = {
      sDate: '', eDate: '', search: '', selectedField: '',
      filterType: '', condition: '', isActive: '', workOrderType: '', workOrderOwnerCode: '',
      workOrderOwnerHOD: '', departmentCode: '', isContractorType: '', plantCode: '', natureOfLicenseCode: '',
      natureOfWorkCode: '', contractorCode: '', subContractorCode: '', workOrderCode: '', subWorkOrderCode: '',
      WorkSiteAreaCode: '', isHRCApplicable: '', statusCode: '', userId: ''
    };
        // this.selectedContractor = item.data.ContractorVendor_Code;
    this.service.getWorkOrderFilterData(data).subscribe((rowSelectedData: any) => {
      this.workOrderData = rowSelectedData.Data.Table;
      this.isWorkOrderData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 112) {
                /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsDataWorkOrder(rowSelectedData);
      }
    });
  }

    /**
     * Invoke this method and it is set array list data of `AllContractor` for `rowData`.
     */
  private setStepArray(): void {
    console.log('first call is setStepArray');
    const firstSteps = {
      id: 1,
      type: 'grid',
      title: 'Contractor',
      button: this.contractorButtonConfig,
      header: true,
      footer: this.isWorkOrderFooter,
      columnDef: this.contractorColumnDefs,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      rowData: this.gridRowData,
      gridOptions: this.stepsGridOptions
    };
    this.stepsData.push(firstSteps);
    if (this.nextTriggerSteps[this.configIndex]) {
      this.callOnApiResponse.next(this.nextTriggerSteps[this.configIndex]);
    }
  }

    /**
     * @description When invoke this method it is set selectLicense data.
     * @param item this parameter contain selected data.
     */
  private getLicense(): void {
    this.isWorkOrder = true;
    const data = {
      search: '', sDate: '', eDate: '', stateCode: '', statusCode: '', plantCode: '',
      isActive: '', natureOfLicenseCode: '', natureOfWorkCode: '',
      totalOccupied: '', contractorCode: '', isContractorType: '', field: ''
    };
        // this.selectedContractor = item.data.ContractorVendor_Code;
    this.service.getLicenceFilterData(data).subscribe((rowSelectedData: any) => {
      this.licenceData = rowSelectedData.Data.Table;
      this.isLicenceData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 112) {
                /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsDataLicence(rowSelectedData);
      }
    });
  }

    /**
     * @description When invoke this method it is set selectLicense data.
     * @param item this parameter contain selected data.
     */
  private getECPolicy(): void {
    this.isEcPolicy = true;
    const data = {
      search: '', sDate: '', eDate: '', field: '', filterType: '', condition: '', stateCode: '', statusCode: '', plantCode: '',
      isActive: '', natureOfWorkCode: '', totalOccupied: '', contractorCode: '', workOrderCode: '',
      subWorkOrderCode: '', isContractorType: '', UserId: ''
    };
        // this.selectedContractor = item.data.ContractorVendor_Code;
    this.service.getEcPolicyFilterData(data).subscribe((rowSelectedData: any) => {
      this.ecPolicyData = rowSelectedData.Data.Table;
      this.isEcPolicyData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 112) {
                /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsDataECPolicy(rowSelectedData);
      }
    });
  }

    /**
     * @description When invoke this method it is set selectLicense data.
     * @param item this parameter contain selected data.
     */
  private getUserList(): void {
    this.isUserList = true;
    const data = {
      search: '', company: '', plant: '', designation: '', state: '', city: '', country: '',
      gender: '', isLock: '', isActive: '', isDelete: '', isSA: '', role: '', timeZone: '',
      language: '', isOrganizationUser: ''
    };
        // this.selectedContractor = item.data.ContractorVendor_Code;
    this.service.getUserList(data).subscribe((rowSelectedData: any) => {
      this.userListData = rowSelectedData.Data.Table;
      this.isUserListData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 112) {
                /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsDataUserList(rowSelectedData);
      }
    });
  }

    /**
     * @descriptions Invoke this method when click on expire list.
     */
  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

    /**
     * @method setStepsDataWorkOrder
     * @description When invoke this method it is set array steps based on licence data.
     */
  private setStepsDataWorkOrder(rowSelectedData: any): void {
    const workOrderSteps = {
      id: 2,
      type: 'grid',
      title: 'Work Order',
      button: this.workOrderButtonConfig,
      header: true,
      footer: this.isWorkOrderFooter,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.workOrderColumnDefs,
      rowData: this.workOrderData,
      gridOptions: this.stepsGridOptions,
    };
    this.stepsData.push(workOrderSteps);
    if (this.nextTriggerSteps[this.configIndex]) {
      this.callOnApiResponse.next(this.nextTriggerSteps[this.configIndex]);
    }
  }

    /**
    * @method setStepsDataLicense
    * @description When invoke this method it is set array steps based on licence data.
    */
  private setStepsDataLicence(rowSelectedData: any): void {
    const licenceSteps = {
      id: 3,
      type: 'grid',
      title: 'License',
      button: this.licenseButtonConfig,
      header: true,
      footer: this.isLicenseFooter,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.licenseColumnDefs,
      rowData: this.licenceData,
      gridOptions: this.stepsGridOptions,
    };
    this.stepsData.push(licenceSteps);
    if (this.nextTriggerSteps[this.configIndex]) {
      this.callOnApiResponse.next(this.nextTriggerSteps[this.configIndex]);
    }
    console.log('jklmn', this.stepsData);
  }

    /**
    * @method setStepsDataLicense
    * @description When invoke this method it is set array steps based on licence data.
    */
  private setStepsDataECPolicy(rowSelectedData: any): void {
    const ecPolicySteps = {
      id: 4,
      type: 'grid',
      title: 'Ec Policy',
      button: this.ecPolicyButtonConfig,
      header: true,
      footer: this.isEcPolicyFooter,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.ecPolicyColumnDefs,
      rowData: this.ecPolicyData,
      gridOptions: this.stepsGridOptions,
    };
    this.stepsData.push(ecPolicySteps);
    if (this.nextTriggerSteps[this.configIndex]) {
      this.callOnApiResponse.next(this.nextTriggerSteps[this.configIndex]);
    }
    console.log('jklmn', this.stepsData);
  }

    /**
    * @method setStepsDataLicense
    * @description When invoke this method it is set array steps based on licence data.
    */
  private setStepsDataUserList(rowSelectedData: any): void {
    const userListSteps = {
      id: 5,
      type: 'grid',
      title: 'User List',
      button: this.userListButtonConfig,
      header: true,
      footer: this.isUserListFooter,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.userListColumnDefs,
      rowData: this.userListData,
      gridOptions: this.stepsGridOptions,
    };
    this.stepsData.push(userListSteps);
    if (this.nextTriggerSteps[this.configIndex]) {
      this.callOnApiResponse.next(this.nextTriggerSteps[this.configIndex]);
    }
    console.log('jklmn', this.stepsData);
  }
}
