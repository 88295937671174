import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-expand-row',
  templateUrl: './expand-row.component.html',
  styleUrls: ['./expand-row.component.css']
})
export class ExpandRowComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  count = 0;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.count = params.rowIndex + 1;
  }

  refresh(): boolean {
    return false;
  }

  expandRow(newStatus: boolean) {
    this.params.data.expanded = newStatus;
    this.params.context.componentParent.getPunchInfo(this.params.data, this.params.rowIndex);
  }

}
