import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GridApi, GridOptions } from "ag-grid-community";
import { AgNumberCountComponent, AgSelectActionCellRendererComponent } from "src/app/core/components";
import { GatePassAccociateInformation, GatepassRequestProcess } from "../gatepass-request-process.model";
import { WeekOffCheckboxRenderComponent } from '../../../../attendance/labour-weekoff-changes/week-off-checkbox-render/week-off-checkbox-render.component';
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { AssociateResignDateCellRendererComponent } from "./associate-resign-date-cell-renderer/associate-resign-date-cell-renderer.component";
import { DATE } from "ngx-bootstrap/chronos/units/constants";
import { GatePassRequestsService } from "../../gate-pass-requests.service";

@Component({
  selector: 'app-associate-selection',
  templateUrl: './associate-selection.component.html',
  styleUrls: ['./associate-selection.component.css']
})
export class GatepassAssociateSelectionComponent implements OnInit {

  @Input() public isResingationInput: boolean;
  @Input() public stepData: GatepassRequestProcess;
  @Input() public associateCount: any;
  @Input() public associateList: any[] = [];

  @Output() public handleSave: EventEmitter<any[]>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<void>;

  public allStepDataObj: any;
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public gridAPI: GridApi;
  public selectAll: boolean;

  constructor(
    private logHelperService: LogHelperService,
    public gatepassService: GatePassRequestsService
  ) {
    this.handleSave = new EventEmitter<any[]>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<void>();

    this.createGridOptions();
  }

  public ngOnInit(): void {
    this.gatepassService.getRequestTypeText(this.stepData);
    this.createColumnDef();
  }

  public onNextAction(): void {
    const selectedRows = this.gridAPI.getSelectedRows();
    if (!selectedRows.length) {
      this.logHelperService.logError({
        message: `Please select atleast one associate from the list.`
      })
      return;
    }
    if (selectedRows.length > this.associateCount) {
      this.logHelperService.logError({
        message: `You can select maximum ${this.associateCount} associates at a time`
      })
      return;
    }

    this.handleSave.emit(selectedRows);
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  public onPrevAction(): void {
    this.handlePrevAction.emit();
  }

  private createColumnDef() {
    this.columnDefs = [
      {
        headerCheckboxSelection: true,
        field: 'RowSelect',
        headerName: ' ',
        checkboxSelection: true,
        suppressMenu: true,
        suppressSorting: true,
        width: 20,
      },
      {
        headerName: 'Code', field: 'GatePassNo', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellRenderer: ({ data }) => {
          return data.GatePassNo || '-';
        }
      },
      {
        headerName: 'Associate', field: 'Associate', width: 150, minWidth: 150, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Category', field: 'Category', width: 100,  minWidth: 120, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Gender', field: 'Gender', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: false
      },
      {
        headerName: 'Age', field: 'Age', width: 180,  minWidth: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: false
      },
      // {
      //   headerName: 'Natinality', field: 'Natinality', width: 200, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      {
        headerName: 'Skill', field: 'Skill', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Trade', field: 'Trade', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Validity Date', field: 'ValidUptoDate', width: 100, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: false
      }
    ];

    if (this.isResingationInput) {
      this.columnDefs.push({
        headerName: 'Resign Date', field: 'ResignDate', width: 130,
        sortable: false, suppressMenu: false, filter: true,
        cellRendererFramework: AssociateResignDateCellRendererComponent
      })
    }
  }

  private createData(){
    // debugger;
    return new Date();
  }

  private createGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      getRowHeight: (params) => {
        return 37;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      onSelectionChanged: (event) => {
        const selectedLength = event.api.getSelectedRows().length;
        let count = 0;
        event.api.forEachNode(node => count = count + 1);
        this.selectAll = selectedLength === count;
        if (selectedLength > this.associateCount) {
          this.logHelperService.logError({
            message: `You can selerct maximum ${this.associateCount} associates at a time`
          });
        }
      },
      suppressRowClickSelection: true,
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      // pagination: true,
      // paginationPageSize: 10,
      headerHeight: 40,
    };
  }

}
