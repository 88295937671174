import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { getListFromExcelFile } from "src/app/core/utility/utility";

@Component({
  selector: 'app-common-import-excel',
  templateUrl: './common-import-excel.component.html',
  styleUrls: ['./common-import-excel.component.css']
})
export class CommonImportExcelComponent {

  @Input() public sampleFileUrl: string;
  @ViewChild('fileInput') public fileInput: ElementRef<any>;
  @Output() public closeModal: EventEmitter<any>;
  @Output() public handleUpload: EventEmitter<any>;
  public fileError: string;

  constructor(
  ) {
    this.closeModal = new EventEmitter();
    this.handleUpload = new EventEmitter();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public onFileChange($event) {
    this.validateFile($event.target);
    this.onUploadFile();
  }

  private onUploadFile() {
    if (this.fileError) {
      this.fileError = 'Please select Excel file';
      this.handleUpload.emit([]);
      return;
    }

    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.fileInput.nativeElement.files[0]);
    fileReader.onload = (e) => this.handleUpload.emit(getListFromExcelFile(fileReader));
  }

  private validateFile(target) {
    this.fileError = '';
    if (target.files.length === 0) {
      this.fileError = 'Please select Excel file';
      target.value = '';
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = 'Cannot use multiple files';
      target.value = '';
      return false;
    }

    if (!(target.files[0].name.endsWith('.xlsx') || target.files[0].name.endsWith('.xls'))) {
      this.fileError = 'Invalid File format, please select Excel file only.';
      target.value = '';
      return false;
    }

    return true;
  }

}