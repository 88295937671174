// import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AttendanceModule } from "./attendance/attendance.module";
import { CalendarModule } from "./calendar/calendar.module";
import { ContractorModule } from "./contractor/contractor.module";
import {
  FooterComponent,
  HeaderComponent,
  Ng4LoadingSpinnerComponent,
  SidebarComponent,
  SidebarService,
} from "./core/components";
import { CoreModule } from "./core/core.module";
import { SharedService } from "./core/services";
import { DashboardModule } from "./dashboard/dashboard.module";
import { GrievanceModule } from "./grievance/grievance.module";
import { HrClearanceModule } from "./hr-clearance/hr-clearance.module";
import { LabourModule } from "./labour/labour.module";
import { LoginComponent } from "./login/login.component";
import { LoginService } from "./login/login.service";
import { ManPowerYearlyModule } from "./man-power-yearly/man-power-yearly.module";
import { ManPowerModule } from "./man-power/man-power.module";
import { MonthlyAttendanceReportComponent } from "./monthly-attendance-report/monthly-attendance-report.component";
import { ReportModule } from "./report/report.module";
import { ShiftModule } from "./shift/shift.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { YearlyBudgetModule } from "./yearly-budget/yearly-budget.module";
// import { CommunicationModule } from './communication/communication.module';
import { NotifyService } from "./common/notify.service";
// import { CommonService } from './common/common.service';
// tslint:disable-next-line: max-line-length
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ImportExcelModule } from "./import-excel/import-excel.module";
import { LeaveModule } from "./leave-module/leave.module";
import { VisitorManagementModule } from "./visitor-management/visitor-management.module";
// import { SessionExpirationAlert, SessionInteruptService } from 'session-expiration-alert';
// import { AppSessionInteruptService } from './services/app-session-interupt.service';
import { RecaptchaModule } from "ng-recaptcha";
import { CoffApprovalProcessModule } from "./coff-approval-process/coff-approval-process.module";
import { FaceRecognitionUIModule } from "./face-recognition/faceRecognitionUI.module";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ForgotPasswordService } from "./forgot-password/forgot-password.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PolicyModule } from "./policy/policy.module";
import { AgTableComponent } from "./sample/ag-table/ag-table.component";
import { Table1Component } from "./sample/ag-table/table1/table1.component";
import { Table2Component } from "./sample/ag-table/table2/table2.component";
import { Table3Component } from "./sample/ag-table/table3/table3.component";
import { Table4Component } from "./sample/ag-table/table4/table4.component";
import { Table5Component } from "./sample/ag-table/table5/table5.component";
import { SampleTableComponent } from "./sample/sample-table.component";
import { GlobalSettingModule } from "./global-setting/global-setting.module";
import { CommonApiService } from "./common/common-api.service";
import { CommonService } from './common/common.service';
import { SubContractorFilterComponent } from "./entryforday/sub-contractor-filter/sub-contractor-filter.component";
import { ContractorFilterComponent } from "./entryforday/contractor-filter/contractor-filter.component";
import { ReportFilterTestComponent } from "./entryforday/report-filter-test/report-filter.component";

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    Ng4LoadingSpinnerComponent,
    AppComponent,
    LoginComponent,
    SidebarComponent,
    MonthlyAttendanceReportComponent,
    ForgotPasswordComponent,
    AgTableComponent,
    Table1Component,
    Table2Component,
    Table3Component,
    Table4Component,
    Table5Component,
    SampleTableComponent,
    PageNotFoundComponent,
    SubContractorFilterComponent,
    ContractorFilterComponent,
    ReportFilterTestComponent,
    MonthlyAttendanceReportComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    DashboardModule,
    LabourModule,
    ContractorModule,
    LabourModule,
    CalendarModule,
    HrClearanceModule,
    GrievanceModule,
    ShiftModule,
    AttendanceModule,
    UserManagementModule,
    YearlyBudgetModule,
    ManPowerModule,
    ManPowerYearlyModule,
    ReportModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ImportExcelModule, // SessionExpirationAlertModule.forRoot()
    LeaveModule,
    VisitorManagementModule,
    RecaptchaModule,
    PolicyModule,
    GlobalSettingModule,
    FaceRecognitionUIModule,
    CoffApprovalProcessModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: "Window", useValue: window },
    LoginService,
    SidebarService,
    SharedService,
    NotifyService,
    ForgotPasswordService,
    CommonService,
    CommonApiService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
