import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  // date: Date = new Date();
  date: any = { startDate: new Date(), endDate: new Date() };
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true,
    closeOnSelect: false,
    rangepicker: true
  };
  constructor() { }

  ngOnInit() {
  }
  onDateSelect($event) {

  }
}
