import { Component, OnInit } from '@angular/core';
import { TableService } from '../service/table.service';

@Component({
  selector: 'app-table5',
  templateUrl: './table5.component.html',
  styleUrls: ['./table5.component.css']
})
export class Table5Component implements OnInit {

  public tableData : any;
  public tableHeader : any;

  constructor(private tableService : TableService) { }

  ngOnInit() {
    this.tableService.getTableData(5).subscribe((res : any) => {
      this.tableData = res;
      console.log("original", res)
      this.tableHeader = this.tableData.Table1.map((header) => {
        if(header.Value == false){
          header.child = header.RPTGroup;
        }
        return header;
      })

      this.tableHeader.forEach(element => {
        if(element.Value == false) {
          element.children = [];
          this.tableHeader.find((child, index) => {
            if(child.RPTGroup == element.RPTGroup  && child.RPTGroupChildL1 != 0 && child.RPTGroupChildL2 == false && child.RPTGroupChildL3 == 0){
              //if(!element.children[child.RPTGroupChildL1 - 1]){
                //element.children[child.RPTGroupChildL1 - 1] = [child];
                element.children.push(child);
                if(child.Value == false){
                  child.children = [];
                  this.tableHeader.find(secondChild => {
                    if(secondChild.RPTGroupChildL1 == child.RPTGroupChildL1 && secondChild.RPTGroupChildL2 != 0 && secondChild.RPTGroupChildL3 == 0){
                      child.children.push(secondChild);
                      secondChild.child = [];
                      this.tableHeader.find(thirdChild => {
                        if(thirdChild.RPTGroupChildL2 = secondChild.RPTGroupChildL2 && thirdChild.RPTGroupChildL3 != 0){
                          console.log(thirdChild)
                          // secondChild.children.push(thirdChild);
                        }
                      })
                    } 
                    // else if(!secondChild.Value) {
                      
                    // }
                  })
                }
              //}
            }
          })
        }
      });
      console.log(this.tableData)
    });
  }

}
