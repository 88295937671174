import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { AttendanceService } from "../../attendance.service";
import * as moment from "moment";
import { DecimalPipe } from "@angular/common";
import { SharedService } from "src/app/core/services";
import { Console } from "@angular/core/src/console";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GridApi, GridOptions } from "ag-grid-community";
import { GLOBAL } from "src/app/app.globals";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { SweetAlertOptions } from "sweetalert2";
// tslint:disable-next-line:max-line-length
import { ReportViewListGroupRowComponent } from "../../report-view-list-group-row/report-view-list-group-row.component";
// tslint:disable-next-line:max-line-length
import { AgActionCellPreviousDayRendererComponent } from "src/app/core/components/grid-wrapper/helper/ag-action-cell-previousday-renderer/ag-action-cell-previousday-renderer.component";
// tslint:disable-next-line:max-line-length
import { RptViewListPreviousdayrecordsComponent } from "../../attendance-manual-punch-change/rpt-view-list-privious-day-records/rpt-view-list-privious-day-records.component";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { AgSaveCellRendererComponent } from "../../ag-helper/ag-save-cell-renderer/ag-save-cell-renderer.component";
import { AgDateCellRendererComponent } from "../../ag-helper/ag-date-cell-renderer/ag-date-cell-renderer.component";
import { AgPunchInCellRendererComponent } from "../../ag-helper/ag-punch-in-cell-renderer/ag-punch-in-cell-renderer.component";
@Component({
  selector: "app-attendance-single-punch-correction",
  templateUrl: "./attendance-single-punch-correction.component.html",
  styleUrls: ["./attendance-single-punch-correction.component.css"],
})
export class AttendanceSinglePunchCorrectionComponent implements OnInit {
  [x: string]: any;
  // @ViewChild('agGrid') gridApi: AgGridAngular;
  selectedCount = 0;
  private defaultColDef;
  private editType;
  selectedRows: any[] = [];
  rowClassRules;
  requiredFlag = false;
  public closeModal: EventEmitter<any>;
  private isRowSelectable;
  getRowNodeId;
  gridColumnApi;
  private gridApi;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  columnDefs;
  rowData = [];
  rowDataAll = [];
  attResultCode1 = [];
  attResultCode: any;
  attResultCodeName: any;
  url: any;
  groupingMeta = ["ContractorName"];
  private reportValueData: any[];
  documentTypes = [];
  data = [];
  id: any;
  rowSelection;
  roleCode;
  sectionhead: any;
  contractor: any;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  savedCroppedImage: any = "";
  showCropper = false;
  forContractorLogin = false;
  public alertOption: SweetAlertOptions = {};

  @ViewChild("dialogerror") private dialogerror: SwalComponent;
  @ViewChild("uploadDocumentTemplateRef")
  uploadDocumentTemplateRef: TemplateRef<any>;
  @ViewChild("uploadDocumentTemplateRefSingle")
  uploadDocumentTemplateRefSingle: TemplateRef<any>;
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return "<div></div>";
  };
  onGridReady(params) {
    this.gridApi = params.api;
  }
  constructor(
    public decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private attendanceService: AttendanceService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService
  ) {
    this.closeModal = new EventEmitter();
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {
      //
      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = "fullRow";
    this.gridOptions = {
      context: {
        componentParent: this,
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
      },
      pagination: false,
      headerHeight: 40,
      frameworkComponents: {
        fullWidthCellRenderer: AgPunchInCellRendererComponent,
      },
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      fullWidthCellRenderer: "fullWidthCellRenderer",
      fullWidthCellRendererParams: {
        isDisable: (params) => {},

        punchDetailsUpdated: (punchData) => {
          this.punchData = punchData;
        },
      },
    };
  }

  @Input()
  public set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): Date {
    return this.selectedReportInfo;
  }

  @Input()
  public set reportData(value: any[]) {
    if (value) {
      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(
        this.groupingMeta,
        value
      );
      this.rowData = tempRowData;
      // this.rowData = value;
    }
  }

  public get reportData(): any[] {
    return this.reportValueData;
  }

  ngOnInit() {
    //  this.getWrongAttendanceList();
    // this.reportData(this.reportValueData);
    console.log("a", this.reportValueData);
  }
  public onCloseAction(): void {
    this.closeModal.next();
  }

  setColumnDef() {
    this.rowClassRules = {
      "group-row": (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        width: 15,
        pinned: "left",
        sortable: false,
        suppressMenu: true,
        filter: false,
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
      },
      // {
      //   headerName: '', field: '', width: 30,
      //   pinned: 'left',
      //   sortable: false, suppressMenu: true,
      //   filter: false,
      //   cellRendererFramework: AgActionCellPreviousDayRendererComponent,
      // },
      {
        headerName: "#",
        width: 50,
        pinned: "left",
        suppressMenu: true,
        valueGetter(params) {
          return params.data.group ? "" : params.node.rowIndex;
        },
      },
      {
        headerName: "Contractor Name",
        field: "ContractorName",
        hide: true,
        visible: false,
      },
      {
        headerName: "Code",
        field: "GatePassNo",
        width: 100,
        filter: true,
        sortable: false,
        cellStyle: { textAlign: "center" },
        colSpan: (params) => {
          return params.data.parent ? 11 : 1;
        },
        cellRendererFramework: ReportViewListGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta,
        },
      },
      {
        headerName: "Associate",
        field: "FullName",
        width: 120,
        filter: true,
        sortable: false,
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Date",
        field: "AttDate",
        width: 80,
        sortable: false,
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "In Date Time",
        field: "InPunch",
        sortable: false,
        suppressMenu: true,
        width: 250,
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.IsInOut === 1;
          },
        },
      },
      {
        headerName: "Out Date Time",
        field: "OutPunch",
        sortable: false,
        suppressMenu: true,
        width: 250,
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.IsInOut === 2;
          },
        },
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 50,
        sortable: false,
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Unit",
        field: "Unit",
        width: 50,
        sortable: false,
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Status",
        field: "Status",
        width: 50,
        sortable: false,
        cellStyle: { textAlign: "center" },
      },
      // {
      //   headerName: 'MachineCode', field: 'Machine_Code', width: 80,
      //   sortable: false, cellStyle: { textAlign: 'center' }
      // },
      {
        headerName: "MachineLocation",
        field: "MachineName",
        width: 120,
        sortable: false,
        cellStyle: { textAlign: "Left" },
      },
      // {
      //   headerName: 'AttPunch_Code', field: 'AttPunch_Code', width: 80,
      //   sortable: false, cellStyle: { textAlign: 'center' }
      // },
      {
        headerName: "",
        field: "",
        width: 40,
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: AgSaveCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            // return ((params.data.FShift_Code !== -2 || params.data.FShift_Code !== -1)
            //   && (!moment(params.data.FSInPunch).isValid() || !moment(params.data.FSOutPunch).isValid()))
            // || (params.data.SShift_Code !== -2
            //   && (!moment(params.data.SSInPunch).isValid() || !moment(params.data.SSOutPunch).isValid()));
          },
        },
      },
    ];
  }
  // onApprove() {

  //
  //   this.selectedRows = this.gridApi.getSelectedRows();
  //   this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
  //   if (this.selectedRows.length === 0) {
  //     alert('Please select atleast one row');
  //     // return;
  //   } else {
  //     console.log('this.selectedRows', this.selectedRows);
  //     let attResultCode2 = '';
  //     this.selectedRows.forEach((data) => {
  //       this.attResultCode1.push(data.AttPunch_Code);
  //       console.log('this.attResultCode', attResultCode2);
  //     });
  //     attResultCode2 = this.attResultCode1.join(',');
  //     console.log('this.attResultCode', this.attResultCode2);

  //     this.attendanceService.updateWrongPunch({ attResultCode: attResultCode2 }).subscribe((dt: any[]) => {
  //       console.log('after approve:', dt);
  //       this.alertOption = {
  //         title: 'Saved!!!',
  //         html: `<span class='customClass'>Data Saved Successfully .</span>`,
  //         type: 'success'
  //       };
  //       setTimeout(() => {
  //         const alertRef = this.dialogerror.show();
  //         alertRef.then((e) => {
  //           this.onCloseAction();
  //         });
  //       }, 10);
  //     //  this.getWrongAttendanceList();
  //     });
  //   }
  // }
  onApprove() {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter((e) => !e.groupKey);
    if (this.selectedRows.length === 0) {
      alert("Please select atleast one row");
      // return;
    } else {
      let attResultCode2 = "";
      this.selectedRows.forEach((data) => {
        this.attResultCode1.push(data.AttPunch_Code);
      });
      attResultCode2 = this.attResultCode1.join(",");

      this.attendanceService
        .updateWrongPunch({ attResultCode: attResultCode2 })
        .subscribe((dt: any[]) => {
          this.logHelperService.logSuccess({
            message: "Data Save Succesfully.",
          });
          this.closeModal.next();
          /// this.getWrongAttendanceList();
        });
    }
  }

  onDelete() {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter((e) => !e.groupKey);
    if (this.selectedRows.length === 0) {
      alert("Please select atleast one row");
      // return;
    } else {
      let attResultCode2 = "";
      this.selectedRows.forEach((data) => {
        this.attResultCode1.push(data.AttPunch_Code);
      });
      attResultCode2 = this.attResultCode1.join(",");

      this.attendanceService
        .deleteWrongPunch({ attResultCode: attResultCode2 })
        .subscribe((dt: any[]) => {
          this.logHelperService.logSuccess({
            message: "Data  Succesfully Deleted.",
          });
          this.closeModal.next();
          /// this.getWrongAttendanceList();
        });
    }
  }
  onSelectionChanged(event) {
    let data = event.api.getSelectedRows();
    data = data.filter((e) => !e.groupKey);
    this.selectedCount = data.length;
    // this.selectedCount = 1;
  }

  onRowSelected(event) {
    if (event.data.parent) {
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    } else {
      if (event.node.selected) {
        // event.node.columnApi.getColumn('Comment').getColDef().cellStyle = { 'border': '1px solid green' };
        console.log("oevent.node.selected1", event.node.selected);
      } else {
        // this.gridOptions.columnApi.getColumn('Comment').getColDef().cellStyle = {'border': 'none'}
        console.log("oevent.node.selected2", event.node.selected);
      }
    }
  }

  modelOpen(item) {
    const data = {
      AttPunch_Code: item.AttPunch_Code,
      Status: item.Status,
      AttendancePunch: item.AttendancePunch,
    };
    this.attendanceService
      .getpreviousdayrecords(data)
      .subscribe((response: any[]) => {
        this.openReportViewModal(response);
      });
  }
  openReportViewModal(response: any[]): void {
    const modalRef = this.modalService.open(
      RptViewListPreviousdayrecordsComponent,
      { size: "lg", backdrop: "static", windowClass: "modal-panel" }
    );
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  saveRecord(params) {
    const tempParams = {
      // attendance: params,

      Punch: params.IsInOut === 1 ? params.OutPunch : params.InPunch,
      IsInOut: params.IsInOut === 1 ? 2 : 1,
      ULC: params.ULC,
      UnitCode: params.Unit_Code,
      UserID: "142",
    };
    this.attendanceService.savePunchdDetails(tempParams).subscribe((res) => {
      console.log(res);
    });
  }
}
