import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { GLOBAL } from 'src/app/app.globals';
import { AgFilterCellRenderComponent } from
  'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../model/man-power.model';
import { GridOptions, GridApi } from 'ag-grid-community';
import { SelectStepsComponent } from 'src/app/core/components';
import { RoleCode, ManpowerRequestStatus } from '../../man-power.modal';
import { ManPowerService } from '../../man-power.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ag-man-power-reduce-cell-renderer',
  templateUrl: './ag-man-power-reduce-cell-renderer.component.html',
  styleUrls: ['./ag-man-power-reduce-cell-renderer.component.css'],
  providers: [ManPowerService]
})
export class AgManPowerReduceCellRendererComponent implements OnInit, ICellRendererAngularComp {

  @ViewChild('requestSelectStep') public requestSelectStep: SelectStepsComponent;
  public closeModal: EventEmitter<any>;

  public isApprovedProcessAndHRUser: boolean;
  public rowData: any;
  public sectionFullText = '';
  params;
  showInOut = GLOBAL.SHOW_IN_OUT;
  punchList;
  selectedInOut = [];
  punchData;
  datePipe = new DatePipe('en-US');
  // todayDate = moment(new Date()).format('DD-MMM-YYYY')
  todayDate = moment().format();
  removedList: any[] = [];

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };

  public contractorConfig: any;
  public gridAPI: GridApi;
  public stepsData: any[];

  public requistionGridOptions: GridOptions = <GridOptions>{
    suppressHorizontalScroll: false,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    onGridReady: (params) => {
      this.gridAPI = params.api;
    },
    onGridSizeChanged: (params) => {
      // params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: 10,
    headerHeight: 40,
  };
  selectedType: any;
  selectedWorkmanCategory: any;
  selectedDate: any;
  selectedShiftSection: any;
  selectedShift: any;
  selectedDateObj: any;
  showSaveButton = false;
  isNewEntry = true;
  constructor(private manPowerService: ManPowerService) {
    this.stepsData = [];
    this.closeModal = new EventEmitter();
  }

  ngOnInit() {
    this.showSaveButton = this.manPowerService.isEditAllow();
  }

  agInit(params: any): void {
    this.createConfigForContractor();

    this.params = params;
    this.rowData = this.params.data;
    this.rowData.workManCategoryList.forEach((workman: any) => {
      workman.Dates.forEach((dt: any) => {
        this.calculateRowspan(dt);
        dt.Shifts.forEach((shift: any) => {
          // if (shift.AssignedContractors.length !== shift.Contractors.length) {
            // shift.AssignedContractors = []
            shift.uniqueContractor.forEach((contractor: any) => {
              const isPresent = shift.AssignedContractors.find((obj: any)=> obj.ContractorVendor_Code === contractor.ContractorVendor_Code)
              if(!isPresent) {
                const obj = {
                  Assign: '0',
                  AssignId: shift.$id,
                  ContractAssign: contractor.Assign,
                  RefAssignId: contractor.$id,
                  ContractorVendor_Code: contractor.ContractorVendor_Code,
                  ContractorName: contractor.ContractorName,
                  SubContractorVendor_Code: '',
                  SubContractorName: ''
                };
                shift.AssignedContractors.push(obj);
              }
            });
          // }
          shift.totalRequirement = shift.uniqueContractor.reduce((a, b) => { return a + b.Assign; }, 0);
        });
      });
    });
    this.sectionFullText = `${this.rowData.Section1}  >>  ${this.rowData.Section2}  >>  ${this.rowData.Section3}`;
    console.log('params', this.rowData);
    this.checkValidProcess();
  }

  public checkValidProcess() {
    // const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    // // tslint:disable-next-line: max-line-length
    // const hrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD || item.Role_Code === RoleCode.HRBP || item.Role_Code === RoleCode.SECURITY);
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    const requiredRoles = [RoleCode.HRHEAD, RoleCode.HRBP, RoleCode.SECURITY, RoleCode.ERTEAM];
    // tslint:disable-next-line: max-line-length
    const hrHead = userRoles.find((item: any) => requiredRoles.includes(item.Role_Code));
    if (hrHead && this.rowData.Status === ManpowerRequestStatus.APPROVED) {
      this.isApprovedProcessAndHRUser = true;
    }
  }

  public onValueInput(assignedContractor: any): void {
    console.log('onValueInput', assignedContractor);
    if (assignedContractor.Assign === 0) {
      assignedContractor.Assign = '';
    }

  }

  refresh(): boolean {
    return true;
  }


  public openModalForRequest(type: number, workManCategory: any): void {
    if (this.isApprovedProcessAndHRUser) {
      this.selectedType = type;
      this.selectedWorkmanCategory = workManCategory;
      this.showModal();
    }
  }

  public openModalForDateWise(type: number, dateChildSections: any[]): void {
    if (this.isApprovedProcessAndHRUser) {
      this.selectedType = type;
      this.selectedDate = dateChildSections;
      this.showModal();
    }
  }

  public calculateRowspan(dateObject) {
    let rowspan = 0;
    dateObject.Shifts.forEach((shiftItem: any) => {
      let uniqueContractor = [];
      shiftItem.Contractors.forEach(con => {
        const foundCon = uniqueContractor.findIndex((c: any) => c.ContractorVendor_Code === con.ContractorVendor_Code)
        if(foundCon > -1) {
          uniqueContractor[foundCon].Assign += con.Assign
          uniqueContractor[foundCon].Req += con.Req
        } else {
          uniqueContractor.push(con)
        }
      });
      shiftItem.uniqueContractor = uniqueContractor
      const assigneeCount = shiftItem.uniqueContractor.length;
      rowspan += (assigneeCount > 0 ? assigneeCount : 1);
    });

    dateObject.rowspan = rowspan;
  }

  public calculateShortageRowspan(shiftObject) {
    let rowspan = 0;
    shiftObject.forEach((shiftItem: any) => {
      const MRShiftId = shiftItem.AssignedContractors[0].MRShiftId;
      const assigneeCount = shiftItem.AssignedContractors.find(e => e.AssignId === MRShiftId);
      rowspan += (assigneeCount > 0 ? assigneeCount : 1);
    });

    shiftObject.rowspan = rowspan;
  }

  public onSubmitAction(selectedData: any): void {
    console.log('onSubmitAction selectedData', selectedData);
  }

  public onCloseActionOfGrid(selectedData: any): void {
    console.log('onCloseActionOfGrid selectedData', selectedData);
    this.selectedType = null;
  }

  public getSelectedRowData(selectedData: any): void {
    console.log('getSelectedRowData selectedData', selectedData);
    this.setTableData(selectedData.data);
    this.requestSelectStep.closeModel();
  }

  public onEmitData(selectedData: any): void {
    console.log('onEmitData selectedData', selectedData);
    this.requestSelectStep.closeModel();
  }

  public checkReduceValidForData(assignedContractor: any, totalRequirement: any) {

    const findInvalid = assignedContractor.filter((e: any) => e.Assign > e.ContractAssign);
    if (findInvalid.length > 0) {
      findInvalid.map((x: any) => x.error = true);
      return;
    }
    if (assignedContractor.reduce((sum, item) => sum + parseInt(item.Assign, 10), 0) !== totalRequirement) {
      assignedContractor.map((x: any) => x.error = true);
    } else {
      assignedContractor.map((x: any) => x.error = false);
    }
  }

  public onSave(): void {
    const errorClass = document.querySelectorAll('.error-border');
    if (errorClass.length === 0) {
      //   console.log("in error class")
      // } else {
      //   console.log("NO ERROR")
      this.params.context.componentParent.saveData(this.rowData, this.removedList);
    }
  }

  private showModal(): void {
    this.setStepArray(this.rowData.contractorList);
    setTimeout(() => {
      console.log('stepsData', this.stepsData);
      this.requestSelectStep.openModel();
    });
  }

  private setTableData(selectedData: any): void {
    if (this.selectedType === 1) {
      this.selectedWorkmanCategory.Dates.forEach((dateItem: any) => {
        dateItem.Shifts.forEach((shiftItem: any) => {
          shiftItem.AssignedContractors.forEach((assignedContractor: any) => {
            assignedContractor.ContractorVendor_Code = selectedData.ContractorVendor_Code;
            assignedContractor.ContractorName = selectedData.Contractor;
            assignedContractor.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
            assignedContractor.SubContractorName = selectedData.SubContractor;
          });
        });
      });
    } else if (this.selectedType === 2) {
      this.selectedDate.forEach((shiftItem: any) => {
        shiftItem.AssignedContractors.forEach((assignedContractor: any) => {
          assignedContractor.ContractorVendor_Code = selectedData.ContractorVendor_Code;
          assignedContractor.ContractorName = selectedData.Contractor;
          assignedContractor.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
          assignedContractor.SubContractorName = selectedData.SubContractor;
        });
      });
    } else if (this.selectedType === 3) {
      this.selectedShiftSection.ContractorVendor_Code = selectedData.ContractorVendor_Code;
      this.selectedShiftSection.ContractorName = selectedData.Contractor;
      this.selectedShiftSection.SubContractorVendor_Code = selectedData.SubContractorVendor_Code;
      this.selectedShiftSection.SubContractorName = selectedData.SubContractor;
      // this.selectedDateObj <= this.todayDate ? this.checkShortageValidForData(this.selectedShiftSection, this.selectedShift) : '';
      // (this.selectedDateObj > this.todayDate) || this.isNewEntry ? ''
      //   : this.checkShortageValidForData(this.selectedShiftSection, this.selectedShift);
    }
  }

  filterInoutSelection(selected) {
    this.selectedInOut = [];
    this.punchData.forEach((punches) => {
      punches.forEach((punch) => {
        if (Number(punch.drpInOutMidPunch) !== -1
          && Number(punch.drpInOutMidPunch) !== 0
          && Number(punch.drpInOutMidPunch) !== NaN) {
          this.selectedInOut.push(Number(punch.drpInOutMidPunch));
        }
      });
    });
    this.punchList.forEach((punch) => {
      if (this.selectedInOut.indexOf(punch.Code) === -1) {
        punch['isDisable'] = false;
      } else {
        punch['isDisable'] = true;
      }
    });
    this.punchDetailsUpdated();
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }

  punchDetailsUpdated() {
    if (this.params.punchDetailsUpdated) {
      this.params.punchDetailsUpdated(this.punchData);
    }
  }

  dateConvertor(date: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'];
    return `${date.getDate()}-${months[date.getMonth()].substr(0, 3)}-${date.getFullYear()}`;
  }

  // config for select filter component
  private createConfigForContractor(): any {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor Code', field: 'ContractorVendor_Code',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Contractor', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRenderer: (params) => {
          let val = '';
          if (params.data.SubContractor) {
            val = `${params.data.SubContractor} / ${params.data.Contractor}`;
          } else {
            val = params.data.Contractor;
          }
          return val;
        }
      },
      {
        headerName: 'Contractor Code', field: 'ContractorVendor_Code',
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRenderer: (params) => {
          let val = '';
          if (params.data.SubContractorVendor_Code) {
            val = `${params.data.SubContractorVendor_Code} / ${params.data.ContractorVendor_Code}`;
          } else {
            val = params.data.ContractorVendor_Code;
          }
          return val;
        }
      },
      {
        headerName: 'Email Id', field: 'EmailID',
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    return contractorColumnDefs;
  }

  private setStepArray(data: any[]): void {
    const firstSteps = {
      id: 1,
      type: 'grid',
      title: 'Contractor List',
      button: { skip: false, cancel: false, submit: true, prev: false, next: false, close: true },
      header: true,
      footer: false,
      isAboveElementRef: false,
      isBelowElementRef: false,
      columnDef: this.createConfigForContractor(),
      rowData: data,
      gridOptions: this.requistionGridOptions
    };
    this.stepsData = [firstSteps];
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }
}
