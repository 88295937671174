import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

const allowedTCRoles = [108, 120];
const allowedHRRoles = [118, 121, 122];

@Component({
  selector: 'app-wo-task-action-modal',
  templateUrl: './wo-task-action-modal.component.html',
  styleUrls: ['./wo-task-action-modal.component.css']
})
export class WOTaskActionModalComponent implements OnInit {

  @Input() public statusList: any[] = [];

  @Output() public closeModal: EventEmitter<void>;
  @Output() public handleSaveAction: EventEmitter<any>;

  public formGroup: FormGroup;
  public isHR: boolean;
  public isTC: boolean;

  private userRoles: any[] = localStorage.getItem('Role') ? JSON.parse(localStorage.getItem('Role')).map(item => item.Role_Code) : [];

  constructor(
    private fb: FormBuilder
  ) {
    this.closeModal = new EventEmitter();
    this.handleSaveAction = new EventEmitter();

    this.formGroup = this.buildForm();
  }

  public ngOnInit(): void {
    this.isHR = this.userRoles.some(item => allowedHRRoles.includes(item));
    this.isTC = this.userRoles.some(item => allowedTCRoles.includes(item));
    if (!this.isHR && !this.isTC) {
      this.formGroup.get('HoldAmount').disable();
    }
  }

  public onCloseAction(): void {
    this.closeModal.emit();
  }

  public onSaveAction(): void {
    this.handleSaveAction.emit(this.formGroup.getRawValue());
  }

  public checkValidation(controlName: string): boolean {
    if (this.formGroup.valid || this.formGroup.get(controlName).valid) return false;
    if (this.formGroup.get(controlName).dirty && this.formGroup.get(controlName).touched) return true;
    return false;
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      Reason: [null, [Validators.required]],
      RequestStatus_Code: [null, [Validators.required]],
      HoldAmount: [null],
    })
  }
}