import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, GridApi, ColDef, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { AttendanceService } from '../attendance.service';
import { ModalConfig, ModelDialogClass, BackdropColor } from 'src/app/core/components/select-steps/select-steps-model/select-steps.model';
import { AgSelectActionCellRendererComponent, AgNumberCountComponent, SelectStepsComponent } from 'src/app/core/components';

@Component({
  selector: 'app-weekoff-change',
  templateUrl: './weekoff-change.component.html',
  styleUrls: ['./weekoff-change.component.css']
})
export class WeekoffChangeComponent implements OnInit {
  @ViewChild('stepsComponentContract') public contract: SelectStepsComponent;
  // Data source for labour list
  public dataSource: IDatasource;
  public selectedContractor = '';
  public responseType = {
    RoleCode: '101',
    UserId: '653',
    endRow: 50,
    searchText: '',
    startRow: 0,
    Contractorvendorcode: '',
    Departmentcode: '',
    Ecpolicycode: '',
    FilterEndDate: '',
    FilterStartDate: '',
    GatePassStatus: 0,
    LabourDemandType: -1,
    Licensecode: '',
    Mainesic: '',
    MultipleRole: '101',
    Plantcode: '',
    RoleCodeForApproval: 101,
    Roleactioncode: 555,
    Search: '',
    Skilltypecode: '',
    Statuscode: '',
    Subcontractorcode: '',
    Subecpolicycode: '',
    Subesic: '',
    Sublicensecode: '',
    Subworkordercode: '',
    UserCode: '653',
    UserRoleCode: 101,
    Workordercode: '',
    Worksitecategorycode: '',
    requesttypecode: 104,

  };
  public gridAPI: GridApi;
  public gridOptions: GridOptions;
  public selectAll: Boolean = false;
  public columnDefs: ColDef[] = [
    {
      suppressMenu: true,
      checkboxSelection: true,
      width: 80,
      headerName: '#',
    },
    {
      headerName: 'Contractor', field: 'Company', suppressMenu: true
    },
    {
      headerName: 'Request No.', field: 'Request_Code', suppressMenu: true
    },
    {
      headerName: 'Code', field: 'Labour_Code', suppressMenu: true
    },
    {
      headerName: 'ULC', field: 'ULC', suppressMenu: true
    },

    {
      headerName: 'Full Name', field: 'MiddleName', suppressMenu: true,
      cellRenderer: (e) => {
        return e.data ? `${e.data.FirstName} ${e.data.MiddleName}` : '';
      }
    },
    {
      headerName: 'Issue Date', field: 'issueDate', suppressMenu: true
    },
    {
      headerName: 'Valid Upto Date', field: 'ValidUptoDate', suppressMenu: true
    },
    {
      headerName: 'Status', field: 'Status', suppressMenu: true
    },
    {
      headerName: 'Designation', field: 'Designation', suppressMenu: true,
    },
    {
      headerName: 'Department', field: 'DepartmentName', suppressMenu: true,
    },
  ];
  public gridRowData: any = [];
  public stepsGridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    onGridReady: (params) => {
      this.gridAPIContractor = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: 10,
    headerHeight: 40,
  };
  public gridAPIContractor: GridApi;
  public gridColumnDefs = [
    {
      headerName: '#', field: '',
      width: 50, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Vendor', field: 'ShortCode',
      width: 150, filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Contractor', field: 'Company', width: 450, filterParams:
        { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true
    },
    {
      headerName: 'Email Id', field: 'EmailID', width: 450, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 70, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: true, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    closeOnOutSideClick: false
  };
  public contractDataStep = [];
  public selectedLabour: Number = 0;
  response: any;
  constructor(private attendanceService: AttendanceService) {
    this.setGridOptions();
  }

  /**
   * @description toggele labour select
   * @author Darshan Vachhani
   * @param {*} value
   * @memberof WeekoffChangeComponent
   */
  toggleSelectAll(value) {
    if (value) {
      this.gridAPI.forEachNode((item) => {
        item.setSelected(true);
      });
    } else {
      this.gridAPI.deselectAll();
    }
  }

  /**
   * @description Grid option for labour list
   * @author Darshan Vachhani
   * @memberof WeekoffChangeComponent
   */
  setGridOptions() {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.setDatasource(this.dataSource);
        this.gridAPI.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      onSelectionChanged: (event) => {
        this.selectedLabour = event.api.getSelectedRows().length;
        let count = 0;
        event.api.forEachNode(node => count = count + 1);
        if (this.selectedLabour === count) {
          this.selectAll = true;
        } else {
          this.selectAll = false;
        }
      },
      suppressRowClickSelection: true,
      pagination: true,
      headerHeight: 40,
      rowHeight: 40,
      paginationPageSize: 10,
      rowSelection: 'multiple',
      rowModelType: 'infinite',
    };
    this.dataSource = {
      getRows: (params: IGetRowsParams) => {

        this.responseType.startRow = params.startRow;
        this.responseType.endRow = params.endRow;

        // replace this.apiService with your Backend Call that returns an Observable
        this.attendanceService.getAllWorkLabours(this.responseType).subscribe((response: any) => {
          if (!response) {
            return;
          }
          params.successCallback(
            response.data, this.response.totalRow
          );

          setTimeout(() => {
            // tslint:disable-next-line: deprecation
            this.gridOptions.api.refreshView();
          }, 0);
        });
      }
    };
  }

  ngOnInit() {
    this.getAllcontractorData();
  }

  private getAllcontractorData(): void {
    this.attendanceService.getAllContractorData().subscribe((contractorResponse) => {
      // this.gridRowData = contractorResponse;
      this.contractDataStep.push({
        id: 1,
        type: 'grid',
        title: 'Select Contractor',
        button: { skip: false, cancel: true, submit: false, prevNext: false, close: false },
        header: true,
        footer: true,
        columnDef: this.gridColumnDefs,
        rowData: contractorResponse,
        gridOptions: this.stepsGridOptions
      });
      // this.contractDataStep = [];

    });
  }

  /**
   * @description week of changes save
   * @author Darshan Vachhani
   * @param {*} e
   * @memberof WeekoffChangeComponent
   */
  saveWeekChane(e) {
    const selecctedRow = this.gridOptions.api.getSelectedRows();
    if (selecctedRow.length <= 0) {

    }
  }

  openContractModal() {
    this.contract.openModel();
  }

  /**
   * @description Selected row
   * @author Darshan Vachhani
   * @param {*} e
   * @memberof WeekoffChangeComponent
   */
  getSelectedRowData(e) {
    this.responseType.Contractorvendorcode = e.data.ContractorVendor_Code;
    this.selectedContractor = e.data.Company;
    this.responseType.startRow = 0;
    this.responseType.endRow = 50;
    this.gridAPI.setDatasource(this.dataSource);
    this.gridAPI.sizeColumnsToFit();
    this.selectedLabour = 0;
    if (this.contract.modalRef) {
      this.onCloseAction();
    }
  }

  /**
   * @description Close modal
   * @author Darshan Vachhani
   * @memberof WeekoffChangeComponent
   */
  onCloseAction() {
    setTimeout(() => {
      this.contract.modalRef.componentInstance.onCloseAction('close');
    });
  }
}
