import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services';
import { GLOBAL } from '../../../app.globals';
import * as _ from 'lodash';

@Component({
  selector: 'app-download-import-sample',
  templateUrl: './download-import-sample.component.html',
  styleUrls: ['./download-import-sample.component.css']
})
export class DownloadImportSampleComponent implements OnInit {

  // downloadSampleList = [
  //   {
  //     Name: 'Goa',
  //     Url: 'assets/data/sample/import-labour/dashboard-data.excel',
  //   }
  // ];

  @Input()
  downloadSampleList = [];
  @Input()
  userplantDetail = [];
  @Input()
  globalDataSampleDownload = [];
  @Input()
  DownloadSampleExelShortCode = [];
  @Input()
  allPlantsUser = [];

  downloadLink = null;
  showPlant : boolean = false;
  paylaod = {};
  statePlantList = [];

  constructor(
    private modalService: NgbModal,
    private sharedService : SharedService
  ) { }

  ngOnInit() {
      // console.log("downloadSampleList -------->",this.downloadSampleList)
      // console.log("userplantDetail -------->",this.userplantDetail)
  }

  onChange($event) {
      // console.log("allPlantsUser -------->",this.allPlantsUser)
    // console.log("Event --->",$event)
    // this.downloadLink = $event ? $event.Url : null;
    // console.log("download link --->",this.downloadLink)

    // console.log("globalDataSampleDownload ----->",this.globalDataSampleDownload)

    if(this.globalDataSampleDownload[0].Value.toString() === "0"){
        this.showPlant = true
    }
    else{
      this.showPlant = false
    }

    if($event !== undefined){
        this.statePlantList = this.allPlantsUser.filter((i) => i.State_Code === $event.State_Code);
        // console.log("statePlantList ----->",this.statePlantList)
    }
    else{
      this.statePlantList = []
    }

    this.paylaod = {
	    Short_Code: this.DownloadSampleExelShortCode,
	    ISActiveStatus:1,
      RequireType: 1,
      ActionType:0,
      UserID: GLOBAL.USER_ID
    }

    if(this.globalDataSampleDownload[0].Value === '0'){
      this.paylaod['Plant_Code'] = $event ? $event.Plantcode0 : null;
      this.paylaod['SiteMIL_Code'] = null;
    }
    if(this.globalDataSampleDownload[0].Value === '1'){
      this.paylaod['SiteMIL_Code'] = $event ? $event.SiteMILCode : null;
      this.paylaod['Plant_Code'] = null;
      this.DownloadExcel(this.paylaod)
    }

  }

  onChangePlant($event){
    // console.log("plant event --->",$event)/
    if(this.globalDataSampleDownload[0].Value === '0'){
      this.paylaod['Plant_Code'] = $event ? $event.Plantcode0 : null
      // this.paylaod['SiteMIL_Code'] = null;
      console.log("payload ---.",this.paylaod)
      this.DownloadExcel(this.paylaod)
    }
  }

  DownloadExcel(paylaod){
    this.sharedService.DownloadCLPMSsampleExel(paylaod).subscribe((res) => {
      console.log("res --->",res);
      if(res.length !== 0){
        this.downloadLink = res[0].Excel_Link
      }
      else{
        this.downloadLink = ''
      }
    },(err) => {
        console.log("err --->",err)
    })
  }

  open(content) {
    this.downloadLink = null;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

    }, (reason) => {
    });
  }
}
