import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ManPowerComponent } from './man-power.component';
import { ManPowerRoutingModule } from './man-power-routing.module';
import { CoreModule } from '../core/core.module';
import { ManPowerService } from './man-power.service';
import { DailyManPowerLocationAssignComponent }
  from './daily-man-power-location-assign/daily-man-power-location-assign.component';
import {
  ReportingDateTimeGridCellRendererComponent
} from './reporting-date-time-grid-cell-renderer/reporting-date-time-grid-cell-renderer.component';
import { ManPowerFilterListingComponent } from './man-power-filter-listing/man-power-filter-listing.component';
import { DropDownCellEditorComponent } from './drop-down-cell-editor/drop-down-cell-editor.component';
import { DailyManPowerContentComponent }
  from './daily-man-power-location-assign/daily-man-power-content/daily-man-power-content.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManPowerListComponent } from './man-power-list/man-power-list.component';
import { ManPowerExpandRowComponent } from './ag-helper/expand-row/expand-row.component';
import { AgManPowerCellRendererComponent } from './ag-helper/ag-man-power-cell-renderer/ag-man-power-cell-renderer.component';
import { AgManPowerReduceCellRendererComponent }
  from './ag-helper/ag-man-power-reduce-cell-renderer/ag-man-power-reduce-cell-renderer.component';

import {
  AgFilterManPowerAssignAllocationComponent
} from './daily-man-power-location-assign/ag-filter-man-power-assign-allocation/ag-filter-man-power-assign-allocation.component';
import { FilterManPowerAssignComponent } from './filter-man-power-assign/filter-man-power-assign.component';
import { ManPowerAdapter } from './adapter/man-power.adapter';
import { ManPowerPrsenter } from './presenter/man-power-presenter';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ManPowerLocationComponent } from './man-power-location/man-power-location/man-power-location.component';
import { AgIconActionCellRendererComponent }
  from './ag-helper/ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportExportService } from '../reports/daily-attendance/report-export.service';
import { NgxPrintModule } from 'ngx-print';
import { ManPowerUnassignComponent } from './man-power-unassign/man-power-unassign.component';
import { FilterManPowerUnassignComponent }
  from './man-power-unassign/filter-man-power-unassign/filter-man-power-unassign.component';
import { WeekPipe } from './man-power-unassign/week.pipe';
import { ManpowerOTApprovalComponent } from './ot-approval-list/ot-approval-list.component';
import { OTApprovalListModalComponent } from './ot-approval-list/approval-list-modal/approval-list-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ManPowerRoutingModule,
    CoreModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxPaginationModule,
    NgxPrintModule,
    NgbTypeaheadModule
  ],
  entryComponents: [
    DropDownCellEditorComponent,
    ReportingDateTimeGridCellRendererComponent,
    ManPowerFilterListingComponent,
    DailyManPowerContentComponent,
    ManPowerExpandRowComponent,
    AgIconActionCellRendererComponent,
    AgManPowerCellRendererComponent,
    AgManPowerReduceCellRendererComponent,
    AgFilterManPowerAssignAllocationComponent,
    OTApprovalListModalComponent
  ],
  declarations: [
    ManPowerComponent,
    DropDownCellEditorComponent,
    DailyManPowerLocationAssignComponent,
    ReportingDateTimeGridCellRendererComponent,
    ManPowerFilterListingComponent,
    DailyManPowerContentComponent,
    ManPowerListComponent,
    ManPowerExpandRowComponent,
    AgIconActionCellRendererComponent,
    AgManPowerCellRendererComponent,
    AgManPowerReduceCellRendererComponent,
    AgFilterManPowerAssignAllocationComponent,
    FilterManPowerAssignComponent,
    FilterManPowerUnassignComponent,
    ManPowerLocationComponent,
    ManPowerUnassignComponent,
    WeekPipe,
    ManpowerOTApprovalComponent,
    OTApprovalListModalComponent
  ],
  providers: [ManPowerService, ManPowerAdapter, ManPowerPrsenter, DatePipe, ReportExportService]
})
export class ManPowerModule { }
