import { Component, OnInit, Input, ViewChild, EventEmitter, TemplateRef, HostListener } from '@angular/core';
import { NgbActiveModal, NgbTabChangeEvent, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL, ROLECODE } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CookieService } from 'ngx-cookie-service';
import { DISABLED, FormArray } from '@angular/forms/src/model';
import * as _ from 'lodash';
import { DateDetailsComponent } from '../approval-flow/date-details/date-details.component';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
declare var $;

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
let newdate: Date;
let newdate1: Date;
const today = new Date();
const today1 = (new Date(today)).toLocaleDateString();

@Component({
  selector: 'app-bulk-approval-flow',
  templateUrl: './bulk-approval-flow.component.html',
  styleUrls: ['./bulk-approval-flow.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class BulkApprovalFlowComponent implements OnInit {

  public closeModal: EventEmitter<any>;
  public userAllowedForNewRequestForHRTab = [118, 121, 122];
  public allowedUserForNewRequestForHRTab = [];
  public maxEndDate: Date = new Date();
  isModalShown = false;
  @ViewChild('autoShownModal') autoShownModal: ModalDirective;
  @ViewChild(NgbTabset) public tabSet: NgbTabset;
  public hideVerificationSection: boolean;
  @Input() selectedUsers;
  //@Input() oldselectedUsers;
  @Input() resolveObject;
  @Input() requestType;
  @Input() Request_Code;
  @Input() approvalWorkFlowDetails;
  //@Input() reqApprovalInfo;
  @Input() UserDataAccessData;
  //UserDataAccessData=[];
  approvalData: any = {};
  selectedDataList= [];
  selectedOldRowData= [];
  labourDoc = [];
  currentUserRoles : any = [];
  show = true;
  hrApproved = false;
  public activedate = false;
  public gpvaliditydate = false;
  public disableenrollment = false;
  public disablegpissue = false;
  disablegpreturned = false;
  disablegpdeactivated = false;
  tabstatus = 'N';
  verifybutton = false;
  roleCode = JSON.parse(localStorage.getItem('Role'));
  action = '';
  approve = '';
  enableAction = false;
  enableActionSaveBtn = false;
  documents = [];
  sections = [];
  cardType: any[];
  masterData = [];
  regularweekly: { Id: number, Name: string; }[];
  shiftGroup = [];
  siteshiftGroup = [];
  ohc = false;
  eic = false;
  ehs = false;
  complianceTeam: boolean= false;
  security = false;
  public hrTabAllowedUser = false;
  hrverify = false;
  section;
  labourStatus = "Active"
  nextDueDateCount: number;
  ageLimitDepend: number;
  ageLimitDay: number;
  public isVerifyPf;
  private globalSettings: any;
  public isOHCRoleCode: boolean = false;
  public isEICRoleCode: boolean = false;
  globalSettingList: any = [];
  userSelData:any=[];
  IsPrviousApproved: any = 0;
  todaydate:Date;
  documentUrl = GLOBAL.BASE_URL_DOC;
  modalRef: BsModalRef;
  dynField:any;
  dynIndexNo:any;
  dynCondition:any;
  feildType:any;
  userRolesData:any=[];
  isHRRoleCode: boolean = false;
  isHideCol: boolean = false;
  diplayFirstTable: boolean = true;
  diplaySecondTable: boolean = false;
  ShowTableVal:number=0;

  constructor(
    private modalService: NgbModal,
    private modalBootService: BsModalService,
    private gatePassRequestsService: GatePassRequestsService,
    public sharedService: SharedService,
    public activeModal: NgbActiveModal,
    private logHelperService: LogHelperService,
    public sharedDataService: SharedDataService,
    private cookieService: CookieService
  ) {
    this.closeModal = new EventEmitter();
   }
   openModal(field, indexNo, condition, template: TemplateRef<any>) {
    this.dynField = field;
    this.dynIndexNo = indexNo;
    //this.dynCondition = condition;
    let val;
    if(condition == 'date'){ 
      val = $(".field-"+this.dynField+"-"+this.dynIndexNo).val();
      this.feildType = "date";
    }else if(condition == 'dropdown'){
      val = $(".field-"+this.dynField+"-"+this.dynIndexNo+" option:selected").val();
      this.feildType = "dropdown";
    }
    
    if(val && val != "0"){
      //this.modalRef = this.modalBootService.show(template);
      this.logHelperService.confirmationBox({ title: '', html: true, 
      message: '<div><input type="radio" name="confirmOption" checked="checked" value="1"/> Do you want to applied same value in all below fields?<br><br><input type="radio" name="confirmOption" value="2"/> Do you want to applied same value in all below blank fields?<br><br><input type="radio" name="confirmOption" value="3"/> Do you want to applied same value in below continue blank fields?</div>',
    }).then((res) => {
      console.log(res)  
      let optionVa = $('input[name="confirmOption"]:checked').val();
      if(res.value && optionVa){
          this.setDynamicVal('all', optionVa)
      }else if( res.dismiss.toString() == 'cancel' && optionVa){
          this.setDynamicVal('specific', optionVa)
      }
    })
    }
  }
  ngOnInit() {
    let newUser = this.UserDataAccessData;
    newUser = newUser.filter((newUser, index, self) => index === self.findIndex((t) => (t.Role_code === newUser.Role_code)));
    console.log('approval flow');
    console.log(this.approvalWorkFlowDetails);
    //this.formArray = this.commentForm.get('userRoleCodes') as FormArray;
    
    /*for (let i = 0; i < newUser.length; i++) {
      const isRoleExist = this.approvalWorkFlowDetails.find(
        (x) => x.Role_Code === newUser[i].Role_code && x.Plant_Code == this.reqApprovalInfo.labourData.Plant_Code
      );
      if(isRoleExist){
        newUser[i].Priority = isRoleExist.Priority;
        newUser[i].isSelected = false;
        this.currentUserRoles.push({
          Name: isRoleExist.Role,
          Role_Code: isRoleExist.Role_Code,
          Priority: isRoleExist.Priority,
          RequestLevel_Code: isRoleExist.RequestLevel_Code,
          isSelected: false,
          isHide: false,
          DisblabeBox: false
        });

      }
    }
    this.currentUserRoles = this.currentUserRoles.sort((a,b) => a.Priority - b.Priority);*/
    
    //const userRoles = JSON.parse(localStorage.getItem('Role'));
    const userRoles = this.UserDataAccessData;
    console.log('useraccessrole');
    console.log(this.UserDataAccessData);
    //console.log(this.UserDataAccessData)
    
    this.isHRRoleCode = userRoles.some(el => el.Role_code == 118) ? true : false;
    this.isOHCRoleCode = userRoles.some(el => el.Role_code == 109) ? true : false;
    this.isEICRoleCode = userRoles.some(el => el.Role_code == 107) ? true : false;
    this.isHideCol = userRoles.some(el => el.Role_code == 108 || el.Role_code == 118 || el.Role_code == 120 || el.Role_code == 121 || el.Role_code == 122) ? true : false;
    this.activedate = userRoles.some(el => el.Role_code == 107) ? true : false;
    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code !== 107) {
    //   this.activedate = false;
    // } else {
    //   this.activedate = true;
    // }
    //this.isHRRoleCode = userRoles.findIndex(role => role.Role_Code == 118) >= 0 ? true : false;
    console.log(this.selectedUsers);
    setTimeout(() => {
      let parentIndex = 0;
    for(let singleDatObj of this.selectedUsers){
      let indexNo = 0;
      for(let userData of singleDatObj.items){
        // if(userData['hrTcAction'] !== null){
        //   userData['hrTcAction']['StatusMasterList_Code'] = userData.hrTcAction == null ? userData.hrAccess ? 256 : 'pending' : userData.hrTcAction.StatusMasterList_Code
        // }
        this.selectedUsers[parentIndex]['items'][indexNo]['eicAction'] = userData.eicAction == null ? userData.eicAccess ? 256 : 'pending': userData.eicAction
        
        // base on Fitness status
        if(this.selectedUsers[parentIndex]['items'][indexNo]['FitnessStatus'] == 1126){
          $(".field-CheckUpDate-"+userData.IndexNumber).addClass("mandatory"); 
          $(".field-NextScheduledate-"+userData.IndexNumber).removeClass("mandatory");
          this.selectedUsers[parentIndex]['items'][indexNo]['ohcAction'] = 256;
        }else if(this.selectedUsers[parentIndex]['items'][indexNo]['FitnessStatus'] == 1127){
          $(".field-CheckUpDate-"+userData.IndexNumber).addClass("mandatory"); 
          $(".field-NextScheduledate-"+userData.IndexNumber).removeClass("mandatory");
          this.selectedUsers[parentIndex]['items'][indexNo]['ohcAction'] = 608;
        }else if(this.selectedUsers[parentIndex]['items'][indexNo]['FitnessStatus'] == 1128){
          $(".field-CheckUpDate-"+userData.IndexNumber).removeClass("mandatory"); 
          $(".field-NextScheduledate-"+userData.IndexNumber).addClass("mandatory");
          this.selectedUsers[parentIndex]['items'][indexNo]['ohcAction'] = 248;
        }

        //base on training status
        if(this.selectedUsers[parentIndex]['items'][indexNo]['TrainingStatusMasteList_Code'] == 1130){
          $(".field-TrainingDate-"+userData.IndexNumber).addClass("mandatory"); 
          this.selectedUsers[parentIndex]['items'][indexNo]['ehsAction'] = 256;
        }else if(this.selectedUsers[parentIndex]['items'][indexNo]['TrainingStatusMasteList_Code'] == 1131){
          $(".field-TrainingDate-"+userData.IndexNumber).addClass("mandatory");
          this.selectedUsers[parentIndex]['items'][indexNo]['ehsAction'] = 608;
        }else if(this.selectedUsers[parentIndex]['items'][indexNo]['TrainingStatusMasteList_Code'] == 1133){
          $(".field-TrainingDate-"+userData.IndexNumber).removeClass("mandatory");
          this.selectedUsers[parentIndex]['items'][indexNo]['ehsAction'] = 248;
        }

        if(this.checkPrevLevelAccess(userData)){
          this.selectedDataList.push(userData);
        }
                
        indexNo++;
      }
      parentIndex++;
    }

    // for(let singleDatObj of this.oldselectedUsers){
    //   for(let userData of singleDatObj.items){
    //     this.selectedOldRowData.push(userData);
    //   }
    // }
    }, 2000);
    
    
    
   // this.selectedUsers = JSON.stringify(this.selectedUsers);
   /* console.log('listttt');
    for (let item in this.selectedUsers) {
      console.log(item)    
      for (let item2 of this.selectedUsers[item]) {
          console.log(item2)    
    }
   }*/

   //   this.selectedUsers = Object.keys(this.selectedUsers).map(function(personNamedIndex){
  //     let person = this.selectedUsers[personNamedIndex];
      
  //     return person;
  // });
  //this.userSelData = this.selectedUsers;
    this.todaydate= new Date();
    // console.log(this.todaydate);
    // let newdate = moment(new Date('18-03-2024'), 'dd/MM/yyyy').toDate();
    // console.log(newdate);
    // let newdate2 = moment(new Date('02-03-2024'), 'dd/MM/yyyy').toDate();
    // console.log(newdate2);
    // let newdate3 = new Date('18-03-2024');
    // console.log(newdate3);
    //this.todaydate = moment(new Date('02-03-2024'), 'dd/MM/yyyy').toDate();
    const promise1 = new Promise((resolve, reject) => {
      this.gatePassRequestsService.getRolePermission({
        "Request_Code": this.Request_Code, "RequireType": 0, "ActionType": 0, "UserId": localStorage.getItem('UserID')
      }).subscribe((res: any) => {
        if (res.length) {
          this.IsPrviousApproved = res[0].IsPrviousApproved;
        }
        resolve(res)
      });
    })
    const promise2 = new Promise((resolve, reject) => {
      this.gatePassRequestsService.globalSettingCode('').subscribe((res: any) => {
        this.globalSettingList = res.Data.Table;
        let roleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERYN')
        this.nextDueDateCount = res.Data.Table.find(item => item.Code === 'MOD152PG195GPMEDVDT_1').Value;
        this.ageLimitDepend = res.Data.Table.find(item => item.Code === 'MOD152PG195GPMEDVDT_3').Value;
        this.ageLimitDay = res.Data.Table.find(item => item.Code === 'MOD152PG195GPMEDEYEAGE').Value;
        this.allowedUserForNewRequestForHRTab = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERROLL').Role;
        
        // Display table code value ---- 0: EIC -> HR -> OHC | 1: EIC -> OHC->OCH-EHS 
        this.ShowTableVal = res.Data.Table.find(item => item.Code === 'MOD152PG195GPBULKAPPTBT').Value;

        let isUserRoleCodeFound = false;
        userRoles.forEach(role => {
          if (this.allowedUserForNewRequestForHRTab.includes(role.Role_Code)) {
            isUserRoleCodeFound = true;
          }
        })
        if (isUserRoleCodeFound) {
          if (roleCode.Value === "1") {
            this.isVerifyPf = true;
          } else if (roleCode.Value === "0") {
            this.isVerifyPf = false;
          }
        } else {
          this.isVerifyPf = false;
        }

        this.setActiveTabByRole();  
        resolve(res)
      })
    })
    Promise.all([promise1, promise2]).then((res) => {
      this.hideFooterForContractor();
      //this.tabvalidation();
  
      if (this.resolveObject.RequestType_Code === 105) {
        this.disablegpreturned = true;
        this.disablegpdeactivated = true;
      } else {
        this.disablegpreturned = false;
        this.disablegpdeactivated = false;
      }
  
      this.resolveObject.labourData.ReqApprovalInfo.forEach((row) => {
        if (row.Role_Code === 111) {
          this.disablegpissue = true;
        } else {
          this.disablegpissue = false;
        }
      });
      if (this.resolveObject.labourData.IsSuspended === 1) {
        this.labourStatus = "Debarrded";
      } else if (this.resolveObject.labourData.IsSuspended === 2) {
        if (this.resolveObject.labourData.SuspendEndDate) {
          const endDate = moment(this.resolveObject.labourData.SuspendEndDate);
          const diff = endDate.diff(
            moment(moment().format("YYYY-MM-DD")),
            "days"
          );
          if (
            diff > 0 ||
            endDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ) {
            this.labourStatus = "Suspended";
          } else {
            this.labourStatus = "Active";
          }
        } else {
          this.labourStatus = "Suspended";
        }
      } else {
        this.labourStatus = "Active";
      }
      console.log(this.resolveObject);
      this.approvalData = this.resolveObject.approvalData;
      this.disableActionSaveBtn(this.approvalData);
  
      const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
      this.approvalData.MaxValiditydate =moment(this.approvalData.MaxValiditydate).add(d && d.Value?Number(d.Value):0, 'days').format('YYYY-MM-DD');
      this.approvalData.gatepassValidityDate = this.approvalData.gatepassValidityDate ? moment(this.approvalData.gatepassValidityDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.CheckUpDate = this.approvalData.CheckUpDate ? moment(this.approvalData.CheckUpDate, 'dd/MM/yyyy').toDate() : null;
      
      this.approvalData.CheckUpDate = this.approvalData.CheckUpDate ? moment(this.approvalData.CheckUpDate, 'dd/MM/yyyy').toDate() : null;
      
      this.approvalData.NextScheduledate = this.approvalData.NextScheduledate ? moment(this.approvalData.NextScheduledate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.OHCNextDuedate = this.approvalData.OHCNextDuedate ? moment(this.approvalData.OHCNextDuedate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.XrayValidityDate = this.approvalData.XrayValidityDate ? moment(this.approvalData.XrayValidityDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.EyeTestingValidityDate = this.approvalData.EyeTestingValidityDate ? moment(this.approvalData.EyeTestingValidityDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.ReExamingDate = this.approvalData.ReExamingDate ? moment(this.approvalData.ReExamingDate, 'dd/MM/yyyy').toDate() : null;
      const dates = [];
      this.approvalData.ECPolicyValidityDat ? dates.push(new Date(this.approvalData.ECPolicyValidityDate)) : null;
      this.approvalData.LicenseValidityDate ? dates.push(new Date(this.approvalData.LicenseValidityDate)) : null;
      this.approvalData.POValidityDate ? dates.push(new Date(this.approvalData.POValidityDate)) : null;
      this.approvalData.MaxValiditydate ? dates.push(new Date(this.approvalData.MaxValiditydate)) : null;
      this.approvalData.MedicalValidityDate ? dates.push(new Date(this.approvalData.MedicalValidityDate)) : null;
      this.approvalData.InductionTrainingDate ? dates.push(new Date(this.approvalData.InductionTrainingDate)) : null;
      this.approvalData.XrayValidityDate ? dates.push(new Date(this.approvalData.XrayValidityDate)) : null;
      this.approvalData.EyeTestingValidityDate ? dates.push(new Date(this.approvalData.EyeTestingValidityDate)) : null;
  
      this.approvalData.minValidityDate = _.min(dates);
      this.approvalData.gatepassValidityDate = this.approvalData.minValidityDate;
      console.log(this.approvalData['SiteShiftGroup_Code'], this.approvalData.AadharCard, this.approvalData);
      this.approvalData.HrComment = this.approvalData.HrComment ? this.approvalData.HrComment : null;
  
      this.labourDoc = this.resolveObject.labourDoc;
      this.hrApproved = this.resolveObject.hrApproved;
      this.getAllSections();
      this.getMasterdata();
      this.siteshiftGroupData();
      //this.isOHCRoleCode = userRoles.findIndex(role => role.Role_Code == 109) >= 0 ? true : false;
      const isRoleExist = userRoles.some(x => this.allowedUserForNewRequestForHRTab.includes(x.Role_Code));
    console.log('isRoleExist',userRoles,isRoleExist,this.resolveObject);

      if (isRoleExist && this.approvalData.RequestTypeName == 'New Request') {
        this.enableAction = false;
        if (this.resolveObject.labourData && this.resolveObject.labourData.ULC) {
          this.verifybutton = false;
        } else {
          this.verifybutton = true;
        }
      } else {
        this.enableAction = true;
        this.verifybutton = false;
      }
  
      if (this.approvalData.IsVerifyEPF === true) {
        this.hrverify = false;
        this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
        this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
        this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
        this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();
  
  
        this.approvalData.verificationDate = this.approvalData.verificationDate;
        this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
        this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
        this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;
  
  
        this.approvalData.UserforEPF = this.approvalData.UserName;
        this.approvalData.UserforESIC = this.approvalData.UserName;
      } else if (this.approvalData.IsVerifyESIC === true) {
        this.hrverify = false;
        this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
        this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
        this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
        this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();
  
  
        this.approvalData.verificationDate = this.approvalData.verificationDate;
        this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
        this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
        this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;
  
  
        this.approvalData.UserforEPF = this.approvalData.UserName;
        this.approvalData.UserforESIC = this.approvalData.UserName;
      }
    })

    /*if (this.resolveObject.RequestType_Code === 105) {
      this.disablegpreturned = true;
      this.disablegpdeactivated = true;
    } else {
      this.disablegpreturned = false;
      this.disablegpdeactivated = false;
    }*/

    this.resolveObject.labourData.ReqApprovalInfo.forEach((row) => {
      if (row.Role_Code === 111) {
        this.disablegpissue = true;
      } else {
        this.disablegpissue = false;
      }
    });
    if (this.resolveObject.labourData.IsSuspended === 1) {
      this.labourStatus = "Debarrded";
    } else if (this.resolveObject.labourData.IsSuspended === 2) {
      if (this.resolveObject.labourData.SuspendEndDate) {
        const endDate = moment(this.resolveObject.labourData.SuspendEndDate);
        const diff = endDate.diff(
          moment(moment().format("YYYY-MM-DD")),
          "days"
        );
        if (
          diff > 0 ||
          endDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        ) {
          this.labourStatus = "Suspended";
        } else {
          this.labourStatus = "Active";
        }
      } else {
        this.labourStatus = "Suspended";
      }
    } else {
      this.labourStatus = "Active";
    }
    console.log(this.resolveObject);
    this.approvalData = this.resolveObject.approvalData;
    this.disableActionSaveBtn(this.approvalData);

    const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
    this.approvalData.MaxValiditydate =moment(this.approvalData.MaxValiditydate).add(d && d.Value?Number(d.Value):0, 'days').format('YYYY-MM-DD');
    this.approvalData.gatepassValidityDate = this.approvalData.gatepassValidityDate ? moment(this.approvalData.gatepassValidityDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.CheckUpDate = this.approvalData.CheckUpDate ? moment(this.approvalData.CheckUpDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.NextScheduledate = this.approvalData.NextScheduledate ? moment(this.approvalData.NextScheduledate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.OHCNextDuedate = this.approvalData.OHCNextDuedate ? moment(this.approvalData.OHCNextDuedate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.XrayValidityDate = this.approvalData.XrayValidityDate ? moment(this.approvalData.XrayValidityDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.EyeTestingValidityDate = this.approvalData.EyeTestingValidityDate ? moment(this.approvalData.EyeTestingValidityDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.ReExamingDate = this.approvalData.ReExamingDate ? moment(this.approvalData.ReExamingDate, 'dd/MM/yyyy').toDate() : null;
    const dates = [];
    this.approvalData.ECPolicyValidityDat ? dates.push(new Date(this.approvalData.ECPolicyValidityDate)) : null;
    this.approvalData.LicenseValidityDate ? dates.push(new Date(this.approvalData.LicenseValidityDate)) : null;
    this.approvalData.POValidityDate ? dates.push(new Date(this.approvalData.POValidityDate)) : null;
    this.approvalData.MaxValiditydate ? dates.push(new Date(this.approvalData.MaxValiditydate)) : null;
    this.approvalData.MedicalValidityDate ? dates.push(new Date(this.approvalData.MedicalValidityDate)) : null;
    this.approvalData.InductionTrainingDate ? dates.push(new Date(this.approvalData.InductionTrainingDate)) : null;
    this.approvalData.XrayValidityDate ? dates.push(new Date(this.approvalData.XrayValidityDate)) : null;
    this.approvalData.EyeTestingValidityDate ? dates.push(new Date(this.approvalData.EyeTestingValidityDate)) : null;

    this.approvalData.minValidityDate = _.min(dates);
    this.approvalData.gatepassValidityDate = this.approvalData.minValidityDate;
    console.log(this.approvalData['SiteShiftGroup_Code'],this.approvalData.AadharCard , this.approvalData);
    this.approvalData.HrComment = this.approvalData.HrComment ? this.approvalData.HrComment : null;

    this.labourDoc = this.resolveObject.labourDoc;
    this.hrApproved = this.resolveObject.hrApproved;
    this.getAllSections();
    this.getMasterdata();
    this.siteshiftGroupData();
    
    if (this.approvalData.Photo) {
      if(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(this.approvalData.Photo)){
        this.approvalData.Photo = `${GLOBAL.PHOTO_URL}${this.approvalData.Photo}`;
      }else{
        this.approvalData.Photo = `${this.approvalData.Photo}`;
      }
    }
    const isRoleExist = userRoles.some(x => this.userAllowedForNewRequestForHRTab.includes(x.Role_Code));
    if (isRoleExist && this.approvalData.RequestTypeName == 'New Request') {
      this.enableAction = false;
      if (this.resolveObject.labourData && this.resolveObject.labourData.ULC) {
        this.verifybutton = false;
      } else {
      this.verifybutton = true;
      }
    } else {
      this.enableAction = true;
      this.verifybutton = false;
    }

    if (this.approvalData.IsVerifyEPF === true) {
      this.hrverify = false;
      this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
      this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
      this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
      this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();
      this.approvalData.verificationDate = this.approvalData.verificationDate;
      this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
      this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
      this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;
      this.approvalData.UserforEPF = this.approvalData.UserName;
      this.approvalData.UserforESIC = this.approvalData.UserName;
    } else if (this.approvalData.IsVerifyESIC === true) {
      this.hrverify = false;
      this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
      this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
      this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
      this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();
      this.approvalData.verificationDate = this.approvalData.verificationDate;
      this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
      this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
      this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;
      this.approvalData.UserforEPF = this.approvalData.UserName;
      this.approvalData.UserforESIC = this.approvalData.UserName;
    }
  }
  approveSelecteduser(){
    console.log(this.selectedDataList);
    //console.log(this.selectedOldRowData);
    //console.log(this.oldselectedUsers);
    let k=0;
    let isValid = true;
    $(".row-cls").removeClass("row-mandatory-color");
    $(".inputcolor").removeClass("mandatory-color");
    // if(this.checkPreviousLevelApproval(userData, childindex, parentIndex, 'FitnessStatus')){

    // }
    //let k = 0;
    for(let userData of this.selectedDataList){
      let i = 0;
      if(this.checkPreviousLevelApproval(userData,'','','')){
      for(let key in userData){
        //if(this.checkPreviousLevelApproval(userData, i, k, key)){
        // if(key == "TrainingStatusMasteList_Code"){
        //   console.log('.field-'+key+'-'+userData.IndexNumber)
        // }
          if(
            ($('.field-'+key+'-'+userData.IndexNumber)[0] && $('.field-'+key+'-'+userData.IndexNumber).hasClass("mandatory")
            && $('.field-'+key+'-'+userData.IndexNumber).is(':enabled')) && 
            ($('.field-'+key+'-'+userData.IndexNumber).val() == undefined 
            || $('.field-'+key+'-'+userData.IndexNumber).val() == null 
            || $('.field-'+key+'-'+userData.IndexNumber).val() == '' 
            || $('.field-'+key+'-'+userData.IndexNumber).val() == 0)){
              $(".field-"+key+"-"+userData.IndexNumber).addClass("mandatory-color");
              //$(".main-row-"+userData.IndexNumber).addClass("row-mandatory-color");
          }else{
            $(".field-"+key+"-"+userData.IndexNumber).removeClass("mandatory-color");
            //$(".main-row-"+userData.IndexNumber).removeClass("row-mandatory-color");
          }
        //}
        i++;
      }
    }
      for(let key2 in userData){
        if($(".field-"+key2+"-"+userData.IndexNumber).hasClass("mandatory-color")){
          $(".main-row-"+userData.IndexNumber).addClass("row-mandatory-color");
          isValid = false;
        }
      }
      k++;
      }
      if(isValid){
        this.prepareDataForSave();
      }
    
    // this.gatePassRequestsService.approveMultipleData(this.approveSelecteduser).subscribe(() => {
    //   this.logHelperService.logSuccess({ message: `Data Saved Successfully!` });
    // });
  }
  prepareDataForSave(){
    let jsonSelUserArrayObject = [];    
    let index = 0;
    let isAction = false;
    for(let userData of this.selectedDataList){
      let jsonSibgleRow = [];
      if(userData.GatePassRequest_Code != userData.GatePassRequest_Code_Old){
        jsonSibgleRow.push({
          GatePassRequest_Code: {
            OlvValue: userData.GatePassRequest_Code_Old,
            NewValue: userData.GatePassRequest_Code
        }
        });
      }
      if(userData.Request_Code != userData.Request_Code_Old){
        jsonSibgleRow.push({
          Request_Code: {
            OlvValue: userData.Request_Code_Old,
            NewValue: userData.Request_Code
        }
        });
      }
      if(userData.verificationDate != userData.verificationDateOld){
        jsonSibgleRow.push({
          verificationDate: {
            OlvValue: userData.verificationDateOld,
            NewValue: userData.verificationDate
        }
        });
      }
      if(userData.ESICVerificationDate != userData.ESICVerificationDateOld){
        jsonSibgleRow.push({
          ESICVerificationDate: {
            OlvValue: userData.ESICVerificationDateOld,
            NewValue: userData.ESICVerificationDate
        }
        });
      }
      if(userData.IsVerifyEPF != userData.IsVerifyEPFOld){
        jsonSibgleRow.push({
          IsVerifyEPF: {
            OlvValue: userData.IsVerifyEPFOld,
            NewValue: userData.IsVerifyEPF
        }
        });
      }
      if(userData.IsVerifyESIC != userData.IsVerifyESICOld){
        jsonSibgleRow.push({
          IsVerifyESIC: {
            OlvValue: userData.IsVerifyESICOld,
            NewValue: userData.IsVerifyESIC
        }
        });
      }
      if(userData.GatePassValidityDate != userData.GatePassValidityDateOld){
        jsonSibgleRow.push({
          GatePassValidityDate: {
            OlvValue: userData.GatePassValidityDateOld,
            NewValue: userData.GatePassValidityDate
        }
        });
      }
      
        // jsonSibgleRow.push({
        //   ShiftGroupView: {
        //     SiteShiftGroupCode: [100],
        // }
        // });
      
      if(userData.FitnessStatus != userData.FitnessStatusOld){
        jsonSibgleRow.push({
          FitnessStatus: {
            OlvValue: userData.FitnessStatusOld,
            NewValue: userData.FitnessStatus
        }
        });
      }
      if(userData.CheckUpDate != userData.CheckUpDateOld){
        jsonSibgleRow.push({
          Checkupdate: {
            OlvValue: userData.CheckUpDateOld,
            NewValue: userData.CheckUpDate
        }
        });
      }
      if(userData.MedicalValidityDate != userData.MedicalValidityDateOld){
        jsonSibgleRow.push({
          NextDuedate: {
            OlvValue: userData.MedicalValidityDateOld,
            NewValue: userData.MedicalValidityDate
        }
        });
      }
      if(userData.NextScheduledate != userData.NextScheduledateOld){
        jsonSibgleRow.push({
          NextScheduledate: {
            OlvValue: userData.NextScheduledateOld,
            NewValue: userData.NextDuedate
        }
        });
      }
      if(userData.From32 != userData.From32Old){
        jsonSibgleRow.push({
          From32: {
            OlvValue: userData.From32Old,
            NewValue: userData.From32
        }
        });
      }
      if(userData.From35 != userData.From35Old){
        jsonSibgleRow.push({
          From35: {
            OlvValue: userData.From35Old,
            NewValue: userData.From35
        }
        });
      }
      if(userData.XrayExaminationrequired != userData.XrayExaminationrequiredOld){
        jsonSibgleRow.push({
          XrayExaminationrequired: {
            OlvValue: userData.XrayExaminationrequiredOld,
            NewValue: userData.XrayExaminationrequired
        }
        });
      }
      if(userData.TreatmentDetails != userData.TreatmentDetailsOld){
        jsonSibgleRow.push({
          TreatmentDetails: {
            OlvValue: userData.TreatmentDetailsOld,
            NewValue: userData.TreatmentDetails
        }
        });
      }
      if(userData.XrayValidityDate != userData.XrayValidityDateOld){
        jsonSibgleRow.push({
          XrayValidityDate: {
            OlvValue: userData.XrayValidityDateOld,
            NewValue: userData.XrayValidityDate
        }
        });
      }
      if(userData.ExaminationDetails != userData.ExaminationDetailsOld){
        jsonSibgleRow.push({
          ExaminationDetails: {
            OlvValue: userData.ExaminationDetailsOld,
            NewValue: userData.ExaminationDetails
        }
        });
      }
      if(userData.EyeTestingValidityDate != userData.EyeTestingValidityDateOld){
        jsonSibgleRow.push({
          EyeTestingValidityDate: {
            OlvValue: userData.EyeTestingValidityDateOld,
            NewValue: userData.EyeTestingValidityDate
        }
        });
      }
      if(userData.NonHazardousDetails != userData.NonHazardousDetailsOld){
        jsonSibgleRow.push({
          NonHazardousDetails: {
            OlvValue: userData.NonHazardousDetailsOld,
            NewValue: userData.NonHazardousDetails
        }
        });
      }
      if(userData.ReExamingDate != userData.ReExamingDateOld){
        jsonSibgleRow.push({
          ReExamingDate: {
            OlvValue: userData.ReExamingDateOld,
            NewValue: userData.ReExamingDate
        }
        });
      }
      if(userData.FNSNextDuedate != userData.FNSNextDuedateOld){
        jsonSibgleRow.push({
          FNSNextDuedate: {
            OlvValue: userData.FNSNextDuedateOld,
            NewValue: userData.FNSNextDuedate
        }
        });
      }
      if(userData.FNSNextScheduledate != userData.FNSNextScheduledateOld){
        jsonSibgleRow.push({
          FNSNextScheduledate: {
            OlvValue: userData.FNSNextScheduledateOld,
            NewValue: userData.FNSNextScheduledate
        }
        });
      }
      if(userData.Enrollment != userData.EnrollmentOld){
        jsonSibgleRow.push({
          Enrollment: {
            OlvValue: userData.EnrollmentOld,
            NewValue: userData.Enrollment
        }
        });
      }
      if(userData.Gatepassissue != userData.GatepassissueOld){
        jsonSibgleRow.push({
          Gatepassissue: {
            OlvValue: userData.GatepassissueOld,
            NewValue: userData.Gatepassissue
        }
        });
      }
      if(userData.Gatepassreturned != userData.GatepassreturnedOld){
        jsonSibgleRow.push({
          Gatepassreturned: {
            OlvValue: userData.GatepassreturnedOld,
            NewValue: userData.Gatepassreturned
        }
        });
      }
      if(userData.Gatepassdeactivated != userData.GatepassdeactivatedOld){
        jsonSibgleRow.push({
          Gatepassdeactivated: {
            OlvValue: userData.GatepassdeactivatedOld,
            NewValue: userData.Gatepassdeactivated
        }
        });
      }

      jsonSibgleRow.push({
        EntityComplianceInfoView: {},
      });

      let userApprovalData = []
      // hr tc action
      if(userData.hrTcAction != null && userData.hrTcAction != 'pending' && userData.hrTcAction != userData.hrTcActionOld){
        isAction = true;
        userApprovalData.push(
          {
            RequestApprover_Code: "(SELECT @Id)", // Auto-generated
            Sys_Code: null, // always null
            Request_Code: userData.Request_Code,
            RequestLevel_Code: userData.hrTcApprovalInfo !== null ? userData.hrTcApprovalInfo.RequestLevel_Code : null,
            StatusMasterList_Code: userData.hrTcAction, // approval status
            Comment: null, // user will input comments
            CreateBy: userData.UserId,
            IsActive: 1,
            IsDelete: 0,
            IsSync: 0,
            Validate: "Request_Code,RequestLevel_Code,StatusMasterList_Code",
            UpdateRequired: 1 //If 1 then update, if 0 then don't update with Validate condition.
        }
        )
      }

      // eic action
      if(userData.eicAction != null && userData.eicAction != 'pending' && userData.eicAction != userData.eicActionOld){
        isAction = true;
        userApprovalData.push(
          {
            RequestApprover_Code: "(SELECT @Id)", // Auto-generated
            Sys_Code: null, // always null
            Request_Code: userData.Request_Code,
            RequestLevel_Code: userData.eicActionApprovalInfo !== null ? userData.eicActionApprovalInfo.RequestLevel_Code : null,
            StatusMasterList_Code: userData.eicAction, // approval status
            Comment: null, // user will input comments
            CreateBy: userData.UserId,
            IsActive: 1,
            IsDelete: 0,
            IsSync: 0,
            Validate: "Request_Code,RequestLevel_Code,StatusMasterList_Code",
            UpdateRequired: 1 //If 1 then update, if 0 then don't update with Validate condition.
        }
        )
      }

      // eic action
      if(userData.ohcAction != null && userData.ohcAction != 'pending' && userData.ohcAction != userData.ohcActionOld){
        isAction = true;
        userApprovalData.push(
          {
            RequestApprover_Code: "(SELECT @Id)", // Auto-generated
            Sys_Code: null, // always null
            Request_Code: userData.Request_Code,
            RequestLevel_Code: userData.ohcActionApprovalInfo !== null ? userData.ohcActionApprovalInfo.RequestLevel_Code : null,
            StatusMasterList_Code: userData.ohcAction, // approval status
            Comment: null, // user will input comments
            CreateBy: userData.UserId,
            IsActive: 1,
            IsDelete: 0,
            IsSync: 0,
            Validate: "Request_Code,RequestLevel_Code,StatusMasterList_Code",
            UpdateRequired: 1 //If 1 then update, if 0 then don't update with Validate condition.
        }
        )
      }

      // ehc action
      if(userData.ehsAction != null && userData.ehsAction != 'pending' && userData.ehsAction != userData.ehsActionOld){
        isAction = true;
        userApprovalData.push(
          {
            RequestApprover_Code: "(SELECT @Id)", // Auto-generated
            Sys_Code: null, // always null
            Request_Code: userData.Request_Code,
            RequestLevel_Code: userData.ehsActionApprovalInfo !== null ? userData.ehsActionApprovalInfo.RequestLevel_Code : null,
            StatusMasterList_Code: userData.ehsAction, // approval status
            Comment: null, // user will input comments
            CreateBy: userData.UserId,
            IsActive: 1,
            IsDelete: 0,
            IsSync: 0,
            Validate: "Request_Code,RequestLevel_Code,StatusMasterList_Code",
            UpdateRequired: 1 //If 1 then update, if 0 then don't update with Validate condition.
        }
        )
      }

       // security action
       if(userData.securityAction != null && userData.securityAction != 'pending' && userData.securityAction != userData.securityActionOld){
        isAction = true;
        userApprovalData.push(
          {
            RequestApprover_Code: "(SELECT @Id)", // Auto-generated
            Sys_Code: null, // always null
            Request_Code: userData.Request_Code,
            RequestLevel_Code: userData.securityActionApprovalInfo !== null ? userData.securityActionApprovalInfo.RequestLevel_Code : null,
            StatusMasterList_Code: userData.securityAction, // approval status
            Comment: null, // user will input comments
            CreateBy: userData.UserId,
            IsActive: 1,
            IsDelete: 0,
            IsSync: 0,
            Validate: "Request_Code,RequestLevel_Code,StatusMasterList_Code",
            UpdateRequired: 1 //If 1 then update, if 0 then don't update with Validate condition.
        }
        )
      }
      
      jsonSibgleRow.push({
          'BDA61C46-6739-4097-9689-AA4C051AC218': [
            userApprovalData
          ]
      })

      jsonSelUserArrayObject.push({
        "F64B6B72-34C7-46B8-A36A-CA32422C5D45": jsonSibgleRow,
      });
    }
    console.log('final json');
    console.log(jsonSelUserArrayObject);
    //jsonSelUserArrayObject
    if(isAction = true){
      this.gatePassRequestsService.bulkApprovalData(jsonSelUserArrayObject).subscribe((res) => {
        
      });
    }else{
      this.logHelperService.logError({ message: 'Please select minimum input!' });
    }
  }
  private disableActionSaveBtn(data: any) {
    const roles = this.UserDataAccessData;
    const isAllowed = roles.some(item => this.allowedUserForNewRequestForHRTab.includes(item.Role_Code));
    if (!data.ULCCode && isAllowed) {
      this.enableActionSaveBtn = true;
    }
  }

  stepclose(resData) {
    const data = {
      ULC: resData ? resData.ULC : this.approvalData.ULCCode,
      ShiftGroup: this.approvalData.SiteShiftGroup_Code,
      GatePassNo: resData ? resData.GatePassNo : this.approvalData.GatePassNo,
      isCancel: !resData
    };
    this.activeModal.close(data);
  }
  public onCloseAction(): void {
    this.UserDataAccessData = [];
    this.selectedUsers = [];
    this.closeModal.next();
  }

  getBg1Class(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "active-color";
    }
    if (IsSuspended == 2) {
      return "cancel-color";
    } else if (IsSuspended == 1) {
      return "debard-color";
    } else {
      return "active-color";
    }
  }

  getBgClass(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "profile_img_green";
    }
    if (IsSuspended == 2) {
      return "profile_img_red";
    } else if (IsSuspended == 1) {
      return "profile_img_debard";
    } else {
      return "profile_img_green";
    }
  }

  getIconClass(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "fa fa-check";
    }
    if (IsSuspended == 2) {
      return "icon-saDC-debarred1";
    } else if (IsSuspended == 1) {
      return "icon-saDC-debarred";
    } else {
      return "icon-saDC-debarred";
    }

  }

  getBg2Class(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "status_text_green";
    }
    if (IsSuspended == 2) {
      return "status_text_red";
    } else if (IsSuspended == 1) {
      return "status_text_debard";
    } else {
      return "status_text_green";
    }
  }

  public tabChange(event: NgbTabChangeEvent) {
    if (event.nextId === 'tab-documents' && this.documents.length === 0) {
      this.getDocuments();
    }
  }

onChangeFitnessStatus(event, childindex, parentIndex, rowIndex, userData) {
    //let prevApproval = this.checkPreviousLevelApproval(userData, childindex, parentIndex, 'FitnessStatus');
    // let userRoleDetail = userData[0].roleListData.filter((x) => x.RoleCode == userData[0].RoleCode);
    // let prevLevel = userRoleDetail.length > 0 ? userRoleDetail.level - 1 : null;
    // let userFilerRole = userData[0].roleListData.filter((x) => x.level == prevLevel);
    if(this.checkPreviousLevelApproval(userData, childindex, parentIndex, 'FitnessStatus')){
      if(event != null && event != '' && event != 0){
        $(".field-FitnessStatus-"+rowIndex).removeClass("mandatory-color");
        $(".field-NextScheduledate-"+rowIndex).removeClass("mandatory-color");
        $(".field-CheckUpDate-"+rowIndex).removeClass("mandatory-color");
        this.selectedUsers[parentIndex]['items'][childindex]['FitnessStatus'] = event;
        if (event == 1126) {     
          $(".field-NextScheduledate-"+rowIndex).removeClass("mandatory");
          $(".field-CheckUpDate-"+rowIndex).addClass("mandatory"); 
          this.selectedUsers[parentIndex]['items'][childindex]['ohcAction'] = 256;
        } else if (event == 1127) {
          $(".field-NextScheduledate-"+rowIndex).removeClass("mandatory");
          $(".field-CheckUpDate-"+rowIndex).addClass("mandatory"); 
          this.selectedUsers[parentIndex]['items'][childindex]['ohcAction'] = 608;
        } else if (event == 1128) {
          $(".field-NextScheduledate-"+rowIndex).addClass("mandatory");
          $(".field-CheckUpDate-"+rowIndex).removeClass("mandatory"); 
          this.selectedUsers[parentIndex]['items'][childindex]['ohcAction'] = 248;
        }
      }else{
        $(".field-FitnessStatus-"+rowIndex).addClass("mandatory-color");
        if($(".field-CheckUpDate-"+rowIndex).val() == 0 && $('.field-CheckUpDate-'+rowIndex).is(':enabled')){
          $(".field-CheckUpDate-"+rowIndex).addClass("mandatory-color");
        }
        if($(".field-NextScheduledate-"+rowIndex).val() == 0 && $('.field-NextScheduledate-'+rowIndex).is(':enabled')){
          $(".field-NextScheduledate-"+rowIndex).addClass("mandatory-color");
        }
       }
       this.checkSingleRowValidation(userData);
    }
}

checkPrevLevelAccess(userData){
  let userRoleDetail = userData.roleListData.filter((x) => x.RoleCode == userData.RoleCode);
  let prevLevel = userRoleDetail.length > 0 ? userRoleDetail[0].step - 1 : null;
  let userFilerRole = userData.roleListData.filter((x) => x.step == prevLevel && !userData.ReqApprovalInfo.some(item => item.Role_Code == x.RoleCode));
  userFilerRole = userFilerRole.filter((userFilerRole, index, self) => index === self.findIndex((t) => (t.Role_code === userFilerRole.Role_code)));
  let actionField = '';
  let rolelist = [];

  if(userFilerRole.length > 0){
    userFilerRole.forEach((item) => {
      rolelist.push(item.RoleCode);
    });
    
    // if(rolelist.includes(118)){
    //   actionField = 'hrTcAction';
    // }
    // if(rolelist.includes(107)){
    //   actionField = 'eicAction';
    // }
    // if(rolelist.includes(109)){
    //   actionField = 'ohcAction';
    // }
    // if(rolelist.includes(110)){
    //   actionField = 'ehsAction';
    // }
    // if(rolelist.includes(111)){
    //   actionField = 'securityAction';
    // }
    if(rolelist.includes(118) && !userData.hrAccess){
      actionField = 'hrTcAction';
    }
    if(rolelist.includes(107) && !userData.eicAccess){
      actionField = 'eicAction';
    }
    if(rolelist.includes(109) && !userData.ohcAccess){
      actionField = 'ohcAction';
    }
    if(rolelist.includes(110) && !userData.ehsAccess){
      actionField = 'ehsAction';
    }
    if(rolelist.includes(111) && !userData.securityAccess){
      actionField = 'securityAction';
    }
    if((actionField != '') && (userData[actionField] == 'pending' || userData[actionField] == 248 || userData[actionField] == 608 || userData[actionField] == null)){
    //if((actionField != '' && (userData[actionField] == 'pending' || userData[actionField] == null))){
      return false;
    }else{
      return true;
    }
    // else{
    //   if(userFilerRole.length > 0){
    //     return false;
    //   }else{
    //     return true;
    //   }
    // }
  }else{
    return true;
  }
}
checkPreviousLevelApproval(userData, childindex, parentIndex, key=''){
  let userRoleDetail = userData.roleListData.filter((x) => x.RoleCode == userData.RoleCode);
  let prevLevel = userRoleDetail.length > 0 ? userRoleDetail[0].step - 1 : null;
  let userFilerRole = userData.roleListData.filter((x) => x.step == prevLevel && !userData.ReqApprovalInfo.some(item => item.Role_Code == x.RoleCode));
  userFilerRole = userFilerRole.filter((userFilerRole, index, self) => index === self.findIndex((t) => (t.Role_code === userFilerRole.Role_code)));
  let actionField = '';
  let actionFieldOld = '';
  let rolelist = [];

  if(userFilerRole.length > 0){
    userFilerRole.forEach((item) => {
      rolelist.push(item.RoleCode);
    });

    if(rolelist.includes(118)){
      actionField = 'hrTcAction';
    }
    if(rolelist.includes(107)){
      actionField = 'eicAction';
      actionFieldOld = 'eicActionOld';
    }
    if(rolelist.includes(109)){
      actionField = 'ohcAction';
    }
    if(rolelist.includes(110)){
      actionField = 'ehsAction';
    }
    if(rolelist.includes(111)){
      actionField = 'securityAction';
    }
    //if(actionField != '' && this.selectedUsers[parentIndex]['items'][childindex][actionField] != 256){
    if((key == "FitnessStatus" || key == "TrainingStatusMasteList_Code") && actionField != '' && userData[actionField] == 'pending' || userData[actionField] == 248 || userData[actionField] == 608){
      this.logHelperService.logError({ message: 'You can not select status because previous level action is pending or hold or rejected request.' });
      this.selectedUsers[parentIndex]['items'][childindex][key] = 0;
      $('.field-'+key+'-'+userData.IndexNumber).val(0);
      return false;
    }
    if((actionField != '') && (userData[actionField] == 'pending')){
      this.logHelperService.logError({ message: 'You can not select status because previous level action is pending or hold or rejected request.' });
      return false;
    }
    else if(userData[actionFieldOld] == null  && userData[actionField] != 256 && (key == "FitnessStatus" || key == "TrainingStatusMasteList_Code")){
      return false;
    }
    else if(userData[actionFieldOld] == null){
      return false;
    }
    else{
      return true;
    }
    // else{
    //   if(userFilerRole.length > 0){
    //     return false;
    //   }else{
    //     return true;
    //   }
    // }
  }else{
    return true;
  }
 
}
onChangeAction(userData, childindex, parentIndex, actionVal){
  let currentLevel = userData.roleListData.filter((x) => x.RoleCode == actionVal);
  userData.roleListData.forEach((item) => {
    if (item.step > currentLevel[0].step) {
      if(item.RoleCode == 109){
        this.selectedUsers[parentIndex]['items'][childindex]['FitnessStatus'] = 0;
        this.selectedUsers[parentIndex]['items'][childindex]['CheckUpDate'] = null;
        this.selectedUsers[parentIndex]['items'][childindex]['MedicalValidityDate'] = null;
        this.selectedUsers[parentIndex]['items'][childindex]['NextScheduledate'] = null;
        $("#xrayRequire"+userData.IndexNumber).prop('checked', false);
        this.selectedUsers[parentIndex]['items'][childindex]['XrayValidityDate'] = null;
        $("#eyeTestingRequire"+userData.IndexNumber).prop('checked', false);
        this.selectedUsers[parentIndex]['items'][childindex]['EyeTestingValidityDate'] = null;
        this.selectedUsers[parentIndex]['items'][childindex]['ohcAction'] = 'pending';
        $('.field-FitnessStatus-'+userData.IndexNumber).val(0);
        $('.field-CheckUpDate-'+userData.IndexNumber).val(null);
        $('.field-MedicalValidityDate-'+userData.IndexNumber).val(null);
        $('.field-NextScheduledate-'+userData.IndexNumber).val(null);
        $('.field-XrayValidityDate-'+userData.IndexNumber).val(null);
        $('.field-EyeTestingValidityDate-'+userData.IndexNumber).val(null);
      //  $('.ohc-fieldCls').val(null);
      }
      else if(item.RoleCode == 110){
        this.selectedUsers[parentIndex]['items'][childindex]['TrainingStatusMasteList_Code'] = 0;
        this.selectedUsers[parentIndex]['items'][childindex]['TrainingDate'] = null;
        this.selectedUsers[parentIndex]['items'][childindex]['TrainingValidityDate'] = null;
        this.selectedUsers[parentIndex]['items'][childindex]['ehsAction'] = 'pending';
        $('.field-TrainingStatusMasteList_Code-'+userData.IndexNumber).val(0);
        $('.field-TrainingDate-'+userData.IndexNumber).val(null);
        $('.field-TrainingValidityDate-'+userData.IndexNumber).val(null);
        //$('.ehs-fieldCls').val(null);
      }
      else if(item.RoleCode == 118){
        this.selectedUsers[parentIndex]['items'][childindex]['GatePassValidityDateHR'] = null;
        this.selectedUsers[parentIndex]['items'][childindex]['hrTcAction'] = 'pending';
        $('.field-hrTcAction-'+userData.IndexNumber).val('pending');
        $('.field-GatePassValidityDateHR-'+userData.IndexNumber).val(null);
        //$('.hrTc-sel-fieldCls').val(0);
        ///$('.hrTc-fieldCls').val(null);
      }
      else if(item.RoleCode == 111){
        this.selectedUsers[parentIndex]['items'][childindex]['securityAction'] = 'pending';
        $('.field-securityAction-'+userData.IndexNumber).val('pending');
        //$('.sucurity-action-fieldCls').val(0);
        //$('.sucurity-fieldCls').val(null);
      }
      else if(item.RoleCode == 107){
        this.selectedUsers[parentIndex]['items'][childindex]['eicAction'] = 'pending';
        $('.field-eicAction-'+userData.IndexNumber).val('pending');
        //$('.sucurity-fieldCls').val(null);
      }
    }
  });
  //let prevLevel = userRoleDetail.length > 0 ? userRoleDetail[0].step - 1 : null;

  // let userFilerRole = userData.roleListData.filter((x) => x.step == prevLevel && !userData.ReqApprovalInfo.some(item => item.Role_Code == x.RoleCode));
  // userFilerRole = userFilerRole.filter((userFilerRole, index, self) => index === self.findIndex((t) => (t.Role_code === userFilerRole.Role_code)));
  // let actionField = '';
  // let actionFieldOld = '';
  // let rolelist = [];
  // if(userFilerRole.length > 0){
  // }
}
checkSingleRowValidation(userData){
  let isValid = true;
  for(let key2 in userData){
    if($(".field-"+key2+"-"+userData.IndexNumber).hasClass("mandatory-color")){
      isValid = false;
    }
  }
  if(isValid){
    $(".main-row-"+userData.IndexNumber).removeClass("row-mandatory-color");
  }else{
    $(".main-row-"+userData.IndexNumber).addClass("row-mandatory-color");
  }
}
  onChangeTrainingStatus(event, childindex, parentIndex, rowIndex, userData){
    //if(event != null && event != '' && event != 0){
  if(this.checkPreviousLevelApproval(userData, childindex, parentIndex, 'TrainingStatusMasteList_Code')){
    if(event != null && event != '' && event != 0){
      $(".field-TrainingStatusMasteList_Code-"+rowIndex).removeClass("mandatory-color");
      if(event == 1130){
        $(".field-TrainingDate-"+rowIndex).addClass("mandatory"); 
        this.selectedUsers[parentIndex]['items'][childindex]['ehsAction'] = 256;
        //$(".field-TrainingDate-"+rowIndex).addClass("mandatory-color");
      }else if(event == 1131){
        $(".field-TrainingDate-"+rowIndex).addClass("mandatory");
        this.selectedUsers[parentIndex]['items'][childindex]['ehsAction'] = 608;
      }else if(event == 1133){
        $(".field-TrainingDate-"+rowIndex).removeClass("mandatory");
        this.selectedUsers[parentIndex]['items'][childindex]['ehsAction'] = 248;
      }
    }else{
      $(".field-TrainingStatusMasteList_Code-"+rowIndex).addClass("mandatory-color");
    }
    this.checkSingleRowValidation(userData);
  }
  }

  checkvalidation() {
    if (this.action == null || this.action === '') {
      this.logHelperService.logError({ message: 'Please select any action!' });
      return false;
    }
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 109) {
      if (this.approvalData.FitnessStatus == null || this.approvalData.FitnessStatus == ''
        || this.approvalData.CheckUpDate == null || this.approvalData.CheckUpDate == '') {
        this.logHelperService.logError({ message: 'Plaese fill detail' });
        return false;
      }
    }
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 110) {
      if (this.approvalData.FNSTraningStatus == null || this.approvalData.FNSTraningStatus === '') {
        this.logHelperService.logError({ message: 'Please select a status!' });
        return false;
      }
    }
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 118) {
      if (!this.approvalData.SiteShiftGroup_Code) {
        this.logHelperService.logError({ message: 'Please select the Site Shift Group!' });
        return false;
      }
    }

    if (this.isVerifyPf) {
      if (!this.approvalData.IsVerifyEPF) {
        this.logHelperService.logError({ message: 'Please verify the EPF details!' });
        return false;
      }
    }
    if (this.isVerifyPf) {
      if (!this.approvalData.IsVerifyESIC) {
        this.logHelperService.logError({ message: 'Please verify the ESIC details!' });
        return false;
      }
    }
    return true;
  }

  prepareReqForActionSave(action: string) {

    const labourCodes = [];
    labourCodes.push(this.resolveObject.labourData.Labour_Code);
    const objgatepass = {
      UserEmail: GLOBAL.USER.EMAIL_ID,
      RoleCode: this.approvalData.RoleCode,
      UserName: GLOBAL.USER.EMAIL_ID,
      requestTypeCode: this.resolveObject.RequestType_Code,
      Action: action,
      labourcodes: labourCodes.join(','),
      UserId: localStorage.getItem('UserID'),
      Comment: this.approvalData.HrComment,
      ULC: this.approvalData.ULCCode,
      verificationDate: this.approvalData.verificationDate,
      ESICVerificationDate: this.approvalData.ESICVerificationDate,
      IsVerifyEPF: this.approvalData.IsVerifyEPF,
      IsVerifyESIC: this.approvalData.IsVerifyESIC,
      
      EntityComplianceInfoView: {
        ContractorVendor_Code: this.approvalData.ContractorVendor_Code,
        State_Code: this.approvalData.State_Code,
        RegistrationNumber: this.approvalData.RegistrationNumber
      },
      
      GatePassValidityDate: moment(this.approvalData.gatepassValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      Section: this.approvalData.SectionName,
      ShiftGroupView: {
        SiteShiftGroupCode: [this.approvalData.SiteShiftGroup_Code],
        ULC: this.approvalData.ULC,
        Gatepassno: this.approvalData.Gatepassno,
        PlantCode: this.approvalData.PlantCode
      },
      
      FitnessStatus: this.approvalData.FitnessStatus,
      Checkupdate: moment(this.approvalData.CheckUpDate).format(this.sharedService.datePickerSettings.requestFormat),
      NextDuedate: moment(this.approvalData.OHCNextDuedate).format(this.sharedService.datePickerSettings.requestFormat),
      NextScheduledate: moment(this.approvalData.NextScheduledate).format(this.sharedService.datePickerSettings.requestFormat),
      From32: this.approvalData.From32,
      From35: this.approvalData.From35,
      XrayExaminationrequired: this.approvalData.examinationrequired,
      TreatmentDetails: this.approvalData.TreatmentDetails,
      XrayValidityDate: moment(this.approvalData.XrayValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      ExaminationDetails: this.approvalData.ExaminationDetails,
      EyeTestingValidityDate: moment(this.approvalData.EyeTestingValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      NonHazardousDetails: this.approvalData.NonHazardousDetails,
      ReExamingDate: moment(this.approvalData.ReExamingDate).format(this.sharedService.datePickerSettings.requestFormat),
      
      InductionTraining: this.approvalData.InductionTrainingDate,
      FNSTraningStatus: this.approvalData.FNSTraningStatus,
      FNSNextDuedate: moment(this.approvalData.FNSNextDuedate).format(this.sharedService.datePickerSettings.requestFormat),
      TrainingDate: moment(this.approvalData.TrainingDate).format(this.sharedService.datePickerSettings.requestFormat),
      TrainingValidityDate: moment(this.approvalData.TrainingValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      FNSNextScheduledate: moment(this.approvalData.FNSNextScheduledate).format(this.sharedService.datePickerSettings.requestFormat),
      FNSIsFireFighter: this.approvalData.FNSIsFireFighter,
      Enrollment: this.approvalData.Enrollment,
      Gatepassissue: this.approvalData.Gatepassissue,
      Gatepassreturned: this.approvalData.Gatepassreturned,
      Gatepassdeactivated: this.approvalData.Gatepassdeactivated,
      GatePassValidityDateHR: moment(this.approvalData.gatepassValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      IssueDate: this.approvalData.IssueDate,
      HrComment: this.approvalData.HrComment
    };
    return objgatepass;
  }


  getDateDetail(userData) {
    const reqeustObj = {
      ULC: null,
      GatePassNo: null,
      Request_Code: userData.appno,
      RequestType_Code: null,
      Plant_Code: null,
      Contractor_Code: null,
      WorkOrder_Code: null,
      UserID: null,
      isCheck: 1
    };
    let maxValidityDate;
    if(userData.GatePassValidityDateHR != null && userData.GatePassValidityDateHR != '' && userData.GatePassValidityDateHR != undefined){
      // maxValidityDate = userData.GatePassValidityDateHR;
      const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
      maxValidityDate = moment(Date()).add(d ? d.Value : 0, 'days').format('YYYY-MM-DD');
    }else{
      const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
      maxValidityDate = moment(Date()).add(d ? d.Value : 0, 'days').format('YYYY-MM-DD');
    }
    const modalRef = this.modalService.open(DateDetailsComponent, { ariaLabelledBy: 'modal-basic-title' });
      modalRef.componentInstance.approvalData = userData;
      modalRef.componentInstance.requestType = userData.requestTypeCode;
      modalRef.componentInstance.maxValidityDate = new Date(maxValidityDate);
      modalRef.result.then((result) => {
        if (result) {
          console.log(result);
        }
      });
    /*this.gatePassRequestsService.getMaxValidityDate(reqeustObj).subscribe((response) => {
      let maxValidityDate = userData.GatePassValidityDateHR;
      if (response && response.date) {
        const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
        maxValidityDate = moment(response.date).add(d ? d : 0, 'days').format('YYYY-MM-DD');
      }
      const modalRef = this.modalService.open(DateDetailsComponent, { ariaLabelledBy: 'modal-basic-title' });
      modalRef.componentInstance.approvalData = userData;
      modalRef.componentInstance.requestType = userData.RequestType_Code;
      modalRef.componentInstance.maxValidityDate = new Date(maxValidityDate);
      modalRef.result.then((result) => {
        if (result) {
          console.log(result);
        }
      });
    })*/

  }

  commentModel(isUpdateCall: boolean = false) {
    /*const modalRef = this.modalService.open(CommentComponent, {
      backdrop: 'static'
    });
    modalRef.componentInstance.reqApprovalInfo = this.resolveObject;
    modalRef.componentInstance.approvalWorkFlowDetails = this.ApprovalWorkFlowDetails;
    modalRef.componentInstance.UserDataAccessData = this.UserDataAccessData;
    
    modalRef.componentInstance.handleClose.subscribe((res: any) => {
      if (res) {
        this.approvalData.RoleCode = res.userRoleCodes.join(',');
        this.approvalData.HrComment = res.HrComment;
        this.actionSaveData(isUpdateCall);
      }
      modalRef.close('')
    });*/
  }
  actionSave(isUpdateCall: boolean = false) {
    let isValid = false;
    if (isUpdateCall) {
      isValid = true;
    } else {
      isValid = this.checkvalidation();
    }
    if (isValid) {
      this.commentModel(isUpdateCall);
    }
  }
  actionSaveData(isUpdateCall: boolean = false) {
    const objgatepass: any = this.prepareReqForActionSave(this.action);
    objgatepass.isUpdateCall = isUpdateCall;
    this.gatePassRequestsService.actionSaveMulti(objgatepass).subscribe((msgdata: any) => {
      if (msgdata.Data) {
        if (objgatepass.requestTypeCode === 105) {
          this.logHelperService.logSuccess({ message: `${this.action} Gate Pass cancel!` });
          this.stepclose(msgdata.Data);
        } else {
          this.logHelperService.logSuccess({ message: `Data ${this.action} Successfully!` });
          this.stepclose(msgdata.Data);
        }
        this.gatePassRequestsService.sendMailForApproval(objgatepass);
      } else {
        this.logHelperService.logError({ message: `Can't ${this.action} Data!` });
        this.stepclose(msgdata.Data);
      }
    }, (error) => {
      this.logHelperService.logError({ message: `Error in adding record!` });

    });
  }
  onCheckedChange(userData, event){
    if (event.target.checked) {
      console.log("params sel data --->", userData)
      this.selectedDataList.push(userData);
    } else {
      const index = this.selectedDataList.map(x => {
        return x.appno;
      }).indexOf(userData.appno);
      this.selectedDataList.splice(index, 1);
      $(".row-cls").removeClass("row-mandatory-color");
      $(".inputcolor").removeClass("mandatory-color"); 
    }
    console.log(this.selectedDataList);
  }
  onCheckedChangeFunc(event, index, field, userData){
    let parentIndex = $(".field-parentIndex-"+index).val();
    let itemIndex = $(".field-itemIndex-"+index).val();
    let checkupDatVal = $(".field-CheckUpDate-"+index).val();
    if(checkupDatVal != null && checkupDatVal != undefined && checkupDatVal != ''){
      if (event.target.checked) {
        let checkupdate = moment(checkupDatVal, "DD-MM-YYYY").toDate();
        let someDate = new Date(checkupdate.getFullYear()+'-'+(checkupdate.getMonth() + 1)+'-'+checkupdate.getDate());
        let result = someDate.setDate(someDate.getDate() + Number(this.nextDueDateCount));
       this.selectedUsers[parentIndex]['items'][itemIndex][field] =  new Date(result);
      }else{
        this.selectedUsers[parentIndex]['items'][itemIndex][field] = '';
      }
    }else{
      this.logHelperService.logError({
        message: 'Please select checkup date'
      });
      $("#xrayRequire"+index).prop('checked', false);
      
    }
    this.checkSingleRowValidation(userData);
  }
  onCheckedChangeEyeTesting(event, index, field, dob, userData){
    let parentIndex = $(".field-parentIndex-"+index).val();
    let itemIndex = $(".field-itemIndex-"+index).val();
    let checkupDatVal = $(".field-CheckUpDate-"+index).val();
    let getDays = 0;
    if(checkupDatVal != null && checkupDatVal != undefined && checkupDatVal != ''){
      if (event.target.checked) {
        if(this.ageLimitDepend == 0){
          getDays = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDVDT_2').Value;
        }else if(this.ageLimitDepend == 1){
          let timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
          let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
          if(this.ageLimitDay <= age){
            getDays = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDEYEAGE_B').Value;
          }else if(this.ageLimitDay >= age){
            getDays = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDEYEAGE_A').Value;
          }
        }
        let checkupdate = moment(checkupDatVal, "DD-MM-YYYY").toDate();
        let someDate = new Date(checkupdate.getFullYear()+'-'+(checkupdate.getMonth() + 1)+'-'+checkupdate.getDate());
        let result = someDate.setDate(someDate.getDate() + Number(getDays));
       this.selectedUsers[parentIndex]['items'][itemIndex][field] =  new Date(result);
      }else{
        this.selectedUsers[parentIndex]['items'][itemIndex][field] = '';
      }
    }else{
      this.logHelperService.logError({
        message: 'Please select checkup date'
      });
      $("#eyeTestingRequire"+index).prop('checked', false);
      
    }
    this.checkSingleRowValidation(userData);
  }
  defChecked(userData){
    //this.selectedDataList.push(userData);
    return true
  }
  actionUpdate() {

  }

  getDocuments() {

    this.gatePassRequestsService.getDocumentForLabourVerify(this.resolveObject.labourData.Labour_Code)
      .subscribe((verificationDoc: any) => {
        console.log("docs", verificationDoc)
        if (verificationDoc.Status) {
          this.documents = verificationDoc.Data;
        }
      });
  }

  getAllSections() {
    this.gatePassRequestsService.getAllSections().subscribe((response: any) => {
      if (response.Status) {
        this.sections = response;
        if (this.approvalData.SectionName == null || this.approvalData.SectionName === '') {
        } else {
          const sectionMasterdata = [];
          this.sections.forEach((val) => {
            if (val.Name === this.approvalData.SectionName) {
              sectionMasterdata.push(val);
            }
          });
          if (sectionMasterdata.length === 1) {
            this.section = sectionMasterdata[0].Section_Code;
          }
        }
      }

    });
  }

  getMasterdata() {
    this.sharedDataService.getMasterData().subscribe((masterList: any) => {
      console.log('master data', masterList);
      this.masterData = masterList.Data.Table;
      this.cardType = this.masterData.filter(list => list.Parent_Code === 601);
      this.regularweekly = [
        { Id: 1, Name: 'Sun' },
        { Id: 2, Name: 'Mon' }, { Id: 3, Name: 'Tue' },
        { Id: 4, Name: 'Wed' }, { Id: 5, Name: 'Thu' },
        { Id: 6, Name: 'Fri' }, { Id: 7, Name: 'Sat' }];
    });
  }

  shiftGroupData() {
    return this.gatePassRequestsService.getShiftGroupData().subscribe((res: any) => {
      this.shiftGroup = res.Data.Table;
    });
  }

  siteshiftGroupData() {
    return this.gatePassRequestsService.getSiteShiftGroupData().subscribe((res: any) => {
      this.siteshiftGroup = res.Data.Table;
      if (this.siteshiftGroup.length === 1) {
        this.approvalData.SiteShiftGroup_Code = this.siteshiftGroup[0].SiteShiftGroup_Code;
      }
    });
  }
  getIsAvailableRole(data: any) {
    const list = this.globalSettingList.find(item => item.Code === data);
    const list2 = list ? this.globalSettingList.find(item => item.Code === data).Role : null;
    const roles = this.UserDataAccessData;
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_code);
    return list2 ? roleList.some(item => list2.includes(item)) : false;
  }
  private setActiveTabByRole() {
    const roles = this.UserDataAccessData;
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);

    if (this.getIsAvailableRole('MOD52PG195REQVERROLL') && this.IsPrviousApproved === 1) {
      //this.tabSet.activeId = 'tab-hr';
      // this.hr = true;  
      this.eic = false;
      this.hrverify = true;
      this.ohc = false;
      this.ehs = false;
      this.security = false;
      this.gpvaliditydate = true;
      this.disableenrollment = true;
    }else if (this.getIsAvailableRole('MOD52PG195REQEICROLL')) {
      this.eic = true;
      this.hrverify = false;
      this.ohc = false;
      this.ehs = false;
      this.security = false;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    } 
    else if (this.getIsAvailableRole('MOD52PG195REQFNSROLL')) {
      //this.tabSet.activeId = 'tab-fns';
      // this.hr = false;
      this.eic = false;
      this.hrverify = false;
      this.ohc = false;
      this.ehs = true;
      this.security = false;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    } else if (this.getIsAvailableRole('MOD52PG195REQOHCROLL')) {
      //this.tabSet.activeId = 'tab-ohc';
      this.eic = false;
      this.hrverify = false;
      this.ohc = true;
      this.ehs = false;
      this.security = false;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    } else if (this.getIsAvailableRole('MOD52PG195REQSECROLL')) {
      //this.tabSet.activeId = 'tab-security';
      this.eic = false;
      this.hrverify = false;
      this.ohc = false;
      this.ehs = false;
      this.security = true;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    }else if (this.getIsAvailableRole('MOD52PG195REQTCROLL')) {
      this.complianceTeam = false;
      this.eic = false;
      this.hrverify = false;
      this.ohc = false;
      this.ehs = false;
      this.security = false;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    }

    this.hrTabAllowedUser = roleList.some(item => this.allowedUserForNewRequestForHRTab.includes(item));
  }

  private hideFooterForContractor() {
    const roles = this.UserDataAccessData;
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);
    if (roleList.includes(ROLECODE.Contractor)) {
      this.hideVerificationSection = true;
    }
  }

  checksixmonthdate(event, indexNo, parentIndex) {
    if (typeof event === 'string') {
    } else if (event) {
      const validitydate = new Date(event);
      
      //const d = new Date(this.approvalData.gatepassValidityDate);
      const d = new Date(this.selectedUsers[parentIndex]['items'][indexNo].gatepassValidityDate);
      if (validitydate <= d) {
        //this.approvalData.minValidityDate = moment(this.selectedUsers[parentIndex]['items'][indexNo].gatepassValidityDate);
      } else {
        this.logHelperService.logError({
          message: 'Can not add validity date greater than six month.'
        });
        return;
      }
    }
  }

  checksixmonthdateeic(event, indexNo, parentIndex) {
    if (typeof event === 'string') {
    } else if (event) {
      const validitydateeic = new Date(event);
      newdate1 = new Date(today.setMonth(today.getMonth() + 6));
      newdate1.setDate(newdate1.getDate() - 1);
      if (validitydateeic <= newdate1) {
        this.activedate = true;
      } else {
        this.logHelperService.logError({
          message: 'Can not add validity date greater than six month.'
        });
        //this.approvalData.gatepassValidityDate = null;
        this.selectedUsers[parentIndex]['items'][indexNo].gatepassValidityDate = null;
        return;
      }
    }
  }

  onChangeChackupDate(event, indexNo, parentIndex, rowIndex, userData) {    
    this.selectedUsers[parentIndex]['items'][indexNo].CheckUpDate = event;
    if (typeof event === 'string') {
    } else if (event) {
      if($('.field-CheckUpDate-'+rowIndex).is(':enabled') && event.year() != ''){
        $(".field-CheckUpDate-"+rowIndex).removeClass("mandatory-color");
      }
      if(this.selectedUsers[parentIndex]['items'][indexNo].MedicalValidityDate == null || this.selectedUsers[parentIndex]['items'][indexNo].MedicalValidityDate == ''){
        let someDate = new Date(event._d.getFullYear()+'-'+(event._d.getMonth() + 1)+'-'+event._d.getDate());
        
        // medical validity date
        let getDays = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDVDT_0').Value;
        let result = someDate.setDate(someDate.getDate() + Number(getDays));
        this.selectedUsers[parentIndex]['items'][indexNo].MedicalValidityDate = new Date(result);

        // training date
        let getTrainingDays = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDVDT_0').Value;
        let trainingresult = someDate.setDate(someDate.getDate() + Number(getTrainingDays));
        this.selectedUsers[parentIndex]['items'][indexNo].TrainingDate = new Date(trainingresult);
      }
    }else if(event == null){
      if($('.field-CheckUpDate-'+rowIndex).is(':enabled')){
        $(".field-CheckUpDate-"+rowIndex).addClass("mandatory-color");
      }
    }

    this.checkSingleRowValidation(userData);
  }
  onChangeNextSchedulDate(event, rowIndex, userData){
    if (typeof event === 'string') {
    } else if (event) {
      if($('.field-NextScheduledate-'+rowIndex).is(':enabled') && event.year() != ''){
        $(".field-NextScheduledate-"+rowIndex).removeClass("mandatory-color");
      }
    }else if(event == null){
      if($('.field-NextScheduledate-'+rowIndex).is(':enabled')){
        $(".field-NextScheduledate-"+rowIndex).addClass("mandatory-color");
      }
    }
    this.checkSingleRowValidation(userData);
  }

  // addsixmonthdate(event) {
  //   if (typeof event === 'string') {
  //   } else {
  //     const extendeddate = new Date(event);
  //     if (today === extendeddate) {
  //       newdate = new Date(today.setMonth(today.getMonth() + 6));
  //       newdate.setDate(newdate.getDate() - 1);
  //     } else {
  //       newdate = new Date(extendeddate.setMonth(extendeddate.getMonth() + 6));
  //       newdate.setDate(newdate.getDate() - 1);
  //       this.approvalData.FNSNextScheduledate = newdate;
  //       return;
  //     }
  //   }
  // }
  onChangeTrainingDate(event, rowIndex, userData){
    if (typeof event === 'string') {
    } else if(event){
      if($('.field-TrainingDate-'+rowIndex).is(':enabled') && event.year() != ''){
        $(".field-TrainingDate-"+rowIndex).removeClass("mandatory-color");
      }
    }else if(event == null){
        if($('.field-TrainingDate-'+rowIndex).is(':enabled')){
          $(".field-TrainingDate-"+rowIndex).addClass("mandatory-color");
        }
    }
    this.checkSingleRowValidation(userData);
  }

  
  IsVerifyEPF($event) {
    this.approvalData.IsVerifyEPF = $event.target.checked;
    if ($event.target.checked === true) {
      this.approvalData.verificationDate = today1;
      this.approvalData.UserforEPF = localStorage.getItem('UserName');
    } else {
      this.approvalData.verificationDate = null;
      this.approvalData.UserforEPF = null;
    }
  }

  
  IsVerifyESIC($event) {
    this.approvalData.IsVerifyESIC = $event.target.checked;
    if ($event.target.checked === true) {
      this.approvalData.ESICVerificationDate = today1;
      this.approvalData.UserforESIC = localStorage.getItem('UserName');
    } else {
      this.approvalData.ESICVerificationDate = null;
      this.approvalData.UserforESIC = null;
    }
  }

  
  IsVerifyDGMS($event) {
    if ($event.target.checked === true) {
      this.approvalData.dgmsVerificationDate = today1;
      this.approvalData.UserforDGMS = localStorage.getItem('UserEmailId');
    } else {
      this.approvalData.dgmsVerificationDate = null;
      this.approvalData.UserforDGMS = null;
    }
  }

  // tabvalidation() {
  //   if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code !== 107) {
  //     this.activedate = false;
  //   } else {
  //     this.activedate = true;
  //   }
  // }
  setDynamicVal(condition, condVal){
      //this.modalRef.hide();
      let k = 0;
      for (let index = this.dynIndexNo + 1; index < 10000; index++) {
          if($(".custom-drop-down-box").hasClass("field-"+this.dynField+"-"+index)){
            let val;
            let selectedval;
            let workOrderval = $(".field-workOrder-"+this.dynIndexNo).val();
            let parentIndex = $(".field-parentIndex-"+index).val();
            let nextWorkOrderVal = $(".field-workOrder-"+index).val();
            let itemIndex = $(".field-itemIndex-"+index).val();
            if(this.feildType == 'date'){
              val = $(".field-"+this.dynField+"-"+this.dynIndexNo).val();
              selectedval = $(".field-"+this.dynField+"-"+index).val();
            }else if(this.feildType == 'dropdown'){
              val = $(".field-"+this.dynField+"-"+this.dynIndexNo+" option:selected").val();
              selectedval = $(".field-"+this.dynField+"-"+index+" option:selected").val();
            }
            if(selectedval != null && selectedval != undefined && selectedval != '' && selectedval != '0'){
              if(condition == "specific"){
                break;
              }else if(condition == "all"){
                if(condVal == 1){
                  if(this.feildType == 'date'){
                    this.selectedUsers[parentIndex]['items'][itemIndex][this.dynField] = moment(val, "DD-MM-YYYY").toDate();;
                  }else if(this.feildType == 'dropdown'){
                    $(".field-"+this.dynField+"-"+index).val(val);
                    this.selectedUsers[parentIndex]['items'][itemIndex][this.dynField] = val;
                  }
                }else if(condVal == 3){
                  break;
                }
              }
            }else{
             
              if(condition == "specific" && workOrderval == nextWorkOrderVal){
                if(this.feildType == 'date'){
                  this.selectedUsers[parentIndex]['items'][itemIndex][this.dynField] = moment(val, "DD-MM-YYYY").toDate();
                }else if(this.feildType == 'dropdown'){
                  $(".field-"+this.dynField+"-"+index).val(val);
                  this.selectedUsers[parentIndex]['items'][itemIndex][this.dynField] = val;
                }
              }else if(condition == "all"){
                //if(condVal == 1 || condVal == 2){
                  if(this.feildType == 'date'){
                    this.selectedUsers[parentIndex]['items'][itemIndex][this.dynField] = moment(val, "DD-MM-YYYY").toDate();;
                  }else if(this.feildType == 'dropdown'){
                    $(".field-"+this.dynField+"-"+index).val(val);
                    this.selectedUsers[parentIndex]['items'][itemIndex][this.dynField] = val;
                  }
                // }else if(condVal == 3){

                // }
               
              }
              
            }
          }else{
            break;
          } 
          k++;
      }
 }
 onHidden(): void {
  this.isModalShown = false;
}

checkDisplayCond(){
  return this.isEICRoleCode && this.isHideCol ? false : true;
}
getXrayDynClass(userData){
  let tradeCode = userData.Trade_Code;
  let fixClass = 'field-getXrayDynClass-'+userData.IndexNumber;
    if(this.globalSettingList){
      let fieldVal = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDXRAYV').Value;
    if(fieldVal == 0){
      return 'non-mandatory inputcolor ohc-fieldCls '+fixClass;
    }else if(fieldVal == 1){
      return 'mandatory inputcolor ohc-fieldCls '+fixClass;
    }else if(fieldVal == 2){
      let tradeCodes = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDTRED_2').Value;
      if(tradeCodes != null && tradeCodes != ''){
        const array = tradeCodes.split(',');
        return array.includes(tradeCode) ? 'mandatory inputcolor '+fixClass : 'non-mandatory inputcolor '+fixClass;
      }else{
        return 'non-mandatory inputcolor ohc-fieldCls '+fixClass;
      }
    }
  }
}
getEyeTestingDynClass(userData){
  let tradeCode = userData.Trade_Code;
  let fixClass = 'field-EyeTestingValidityDate-'+userData.IndexNumber;
  let fieldVal = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDEYEV').Value;
  if(fieldVal == 0){
    return 'non-mandatory inputcolor ohc-fieldCls '+fixClass;
  }else if(fieldVal == 1){
    return 'mandatory inputcolor ohc-fieldCls '+fixClass;
  }else if(fieldVal == 2){
    let tradeCodes = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPMEDTRED_1').Value;
    if(tradeCodes != null && tradeCodes != ''){
      const array = tradeCodes.split(',');
      return array.includes(tradeCode) ? 'mandatory inputcolor '+fixClass : 'non-mandatory inputcolor ohc-fieldCls '+fixClass;
    }else{
      return 'non-mandatory inputcolor ohc-fieldCls '+fixClass;
    }
  }
}

  checkRequireClass(parentIndex, indexNo)
  {
    if(this.selectedUsers[parentIndex]['items'][indexNo]['FitnessStatus'] == 1128){
      return '';
    }else{
      return 'mandatory';
    }
  }
}
