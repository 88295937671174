import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';
import { GLOBAL, BASE_URL } from 'src/app/app.globals';
import { LaboursService } from 'src/app/labour/labours/labours.service';
import { GatePassRequestsService } from '../../gate-pass-requests.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { GatePassRequestProcessComponent } from '../../gatepass-request-process/gatepass-request-process.component';
import { PopupWorkmanSalaryMasterComponent } from '../../approval-flow/date-details/popup-workman-salary-master/popup-workman-salary-master.component';
import { VerificationHistoryComponent } from '../../approval-flow/verification-history/verification-history.component';
import { DateDetailsComponent } from '../../approval-flow/date-details/date-details.component';
import { DomSanitizer } from '@angular/platform-browser';
import { GatePassRequestHistoryComponent } from '../../gate-pass-request-history/gate-pass-request-history.component';

@Component({
  selector: 'app-full-row-renderer',
  templateUrl: './full-row-renderer.component.html',
  styleUrls: ['./full-row-renderer.component.css'],
})
export class FullRowRendererComponent
  implements OnInit, ICellRendererAngularComp
{
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  params;
  rowData;
  baseUrl = GLOBAL.PHOTO_URL;
  showWhile = false;
  isSecurityApproved = false;
  labourStatus = 'ACTIVE';
  public requestType: number;
  public gridRowDataAs: any[] = [];
  useraccData:any;
  // tslint:disable-next-line: variable-name
  LabourData: any;
  // Request status display
  statusText = {
    '-1': { text: 'NoStatus', bgColor: '#808080', icon: 'fa fa-dot-circle-o' },
    264: { text: 'Generated', bgColor: '#6d62e4', icon: 'fa fa-play-circle-o' },
    249: { text: 'In-process', bgColor: 'yellow', icon: 'fa fa-play-circle-o' },
    555: { text: 'Pending', bgColor: '#FFA500', icon: 'fa fa-stop-circle-o' },
    556: { text: 'InReview', bgColor: '#4ccfe0', icon: 'fa fa-pause-circle-o' },
    256: { text: 'Approved', bgColor: '#a8be37', icon: 'fa fa-check-circle' },
    423: { text: 'Overdue', bgColor: '#red', icon: 'fa fa-times-circle-o' },
    248: { text: 'On Hold', bgColor: '#blue', icon: 'fa fa-times-circle-o' },
    608: { text: 'Rejected', bgColor: '#red', icon: 'fa fa-stop-circle-o' },
  };
  // Request status display
  statuscodes = [264, 249, 555, 556, 256, 423, 248, 608];
  roleList = [];
  roleListData = [];
  // tslint:disable-next-line: variable-name
  RoleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  requestTypeText = '';
  public hideulc = false;
  public showulc = false;
  btnHide = false;
  leaveBalance = false;
  repDataValueBycode:any;
  selectedRowData: any = {};
  constructor(
    private service: LaboursService,
    private _gatePassRequestsService: GatePassRequestsService,
    private modalService: NgbModal,
    private sanitizer:DomSanitizer
  ) {}

  ngOnInit() {
    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119) {
      this.leaveBalance = true;
    } else {
      this.leaveBalance = false;
    }
  }

  agInit(params: any): void {
    this.params = params;
    this.rowData = params.data;
    if (this.rowData) {
      if (this.rowData.RequestType_Code === 103) {
        this.requestTypeText = '(New)';
      } else if (this.rowData.RequestType_Code === 104) {
        this.requestTypeText = '(Renew)';
      } else if (this.rowData.RequestType_Code === 105) {
        this.requestTypeText = '(Cancel)';
      }
      if (this.rowData.IsSuspended === 1) {
        this.labourStatus = 'Debarrded';
      } else if (this.rowData.IsSuspended === 2) {
        if (this.rowData.SuspendEndDate) {
          const endDate = moment(this.rowData.SuspendEndDate);
          const diff = endDate.diff(
            moment(moment().format('YYYY-MM-DD')),
            'days'
          );
          if (
            diff > 0 ||
            endDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ) {
            this.labourStatus = 'Suspended';
          } else {
            this.labourStatus = 'Active';
          }
        } else {
          this.labourStatus = 'Suspended';
        }
      } else {
        this.labourStatus = 'Active';
      }
      this.populateRoleList();
      if (this.rowData.Photo) {
        if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(this.rowData.Photo)) {
            this.rowData.Photo = `${GLOBAL.PHOTO_URL}${this.rowData.Photo}`;
          }
      }
      if (this.params.UserDataAccessData) {
        this.useraccData = this.params.UserDataAccessData();
      }
      // let url = '';
      // if (this.rowData.Photo && this.rowData.Photo.indexOf(this.baseUrl) > -1) {
      //   this.rowData.Photo = this.rowData.Photo.split(this.baseUrl)[1];
      //   // url = this.rowData.Photo;
      // }
      // if (this.rowData.Photo.indexOf('blank-face.jpg') === -1) {
      //   url = `${BASE_URL}Image/${this.rowData.Photo}`;
      // }
      // const http = new XMLHttpRequest();
      // http.open('HEAD', url, false);
      // http.send();
      // if (http.status !== 404) {
      //   this.rowData.Photo = url;
      // } else {
      //   this.rowData.Photo = 'assets/Image/blank-face.jpg';
      // }
      if (
        JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 118 ||
        JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 116
      ) {
        this.hideulc = true;
        this.showulc = false;
      } else {
        this.hideulc = false;
        this.showulc = true;
      }
    }
  }

  refresh(): boolean {
    return true;
  }

  getButtonColour(data) {
    if (data.isHRApproved === true) {
      if (data.IsSuspended == 1) {
        return '#ff0000';
      }  if (data.IsSuspended == 2) {
        return '#FF6701';
      }
      return;
    }  if (data.isHRApproved === false) {
      return '#fdc027';
    } else {
      return '';
    }
  }

  populateRoleList() {
    if (this.params.ApprovalWorkFlowDetails) {
      // this.rowData.ReqApprovalInfo = [];
      // tslint:disable-next-line:no-increment-decrement
      // for (let i = 0; i < this.params.reqTypePlantList().length; i++) {
        // this.params.ApprovalWorkFlowDetails = this.params.ApprovalWorkFlowDetails().sort((a,b) => a.RequestLevel_Code - b.RequestLevel_Code);
      for (let i = 0; i < this.params.ApprovalWorkFlowDetails().length; i++) {

        const element = this.params.ApprovalWorkFlowDetails()[i];
        const isRoleExist1 = this.rowData.ReqApprovalInfo.find(
          (x) => x.Role_Code === element.Role_Code
        );
        if (this.rowData.ReqApprovalInfo.length > 0) {
          if (this.rowData.RequestType_Code == element.RequestType_Code && this.rowData.Plant_Code == element.Plant_Code) {
          if (isRoleExist1) {
            this.roleListData.push({
              Name: element.Role,
              RoleCode: element.Role_Code,
              Priority: element.Priority,
              color: this.statusText[isRoleExist1.StatusMasterList_Code].bgColor,
              icon: this.statusText[isRoleExist1.StatusMasterList_Code].icon,
              level: element.RequestLevel_Code,
              requestBy: isRoleExist1.RequestedBy,
              step: element.Level,
              approveStatus: true
            });
          }
          else {
            this.roleListData.push({
                Name: element.Role,
                RoleCode: element.Role_Code,
                Priority: element.Priority,
                color: this.statusText[-1].bgColor,
                icon: this.statusText[-1].icon,
                level: element.RequestLevel_Code,
                requestBy: 'Pending',
                approveStatus: false,
                step: element.Level,
              });
            // }
          }
        }
        }
        // Request status display
        if (
          element.RequestType_Code === 103 ||
          element.RequestType_Code === 104 ||
          element.RequestType_Code === 105
        ) {
          let statusCode = -1;
          // tslint:disable-next-line:no-increment-decrement
          for (let j = 0; j < this.rowData.ReqApprovalInfo.length; j++) {
            if (
              this.rowData.ReqApprovalInfo[j].Role_Code === element.Role_Code
            ) {
              statusCode =
                this.rowData.ReqApprovalInfo[j].StatusMasterList_Code;
              if (
                this.rowData.ReqApprovalInfo[j].Role_Code === 111 &&
                this.rowData.ReqApprovalInfo[j].StatusMasterList_Code === 256 &&
                (this.RoleCode == 109 || this.RoleCode == 118)
              ) {
                this.isSecurityApproved = true;
              }
              break;
            }
          }
          if (!this.statuscodes.indexOf(statusCode)) {
            statusCode = -1;
          }
          if (this.rowData.ReqApprovalInfo.length > 0) {
            if (element.Role_Code === 107) {
              const isRoleExist1 = this.rowData.ReqApprovalInfo.find(
                (x) => x.Role_Code === 107
              );
              const isRoleExist2 = this.rowData.ReqApprovalInfo.find(
                (x) => x.Role_Code === 120
              );

              if (isRoleExist1) {
                this.roleList.push({
                  Name: element.Name,
                  Status: statusCode,
                  // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                  color:
                    this.statusText[isRoleExist1.StatusMasterList_Code].bgColor,
                  icon: this.statusText[isRoleExist1.StatusMasterList_Code]
                    .icon,
                });
              } else if (!isRoleExist2) {
                this.roleList.push({
                  Name: element.Name,
                  Status: statusCode,
                  // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                  color: this.statusText[-1].bgColor,
                  icon: this.statusText[-1].icon,
                });
              }
            } else if (element.Role_Code === 120) {
              const isRoleExist1 = this.rowData.ReqApprovalInfo.find(
                (x) => x.Role_Code === 120
              );

              if (isRoleExist1) {
                this.roleList.push({
                  Name: element.Name,
                  Status: statusCode,
                  // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                  color:
                    this.statusText[isRoleExist1.StatusMasterList_Code].bgColor,
                  icon: this.statusText[isRoleExist1.StatusMasterList_Code]
                    .icon,
                });
              }
            } else if (element.Role_Code === 121) {
              const isRoleExist1 = this.rowData.ReqApprovalInfo.find(
                (x) => x.Role_Code === 121
              );
              const isRoleExist2 = this.rowData.ReqApprovalInfo.find(
                (x) => x.Role_Code === 118
              );

              if (isRoleExist1) {
                this.roleList.push({
                  Name: element.Name,
                  Status: statusCode,
                  // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                  color:
                    this.statusText[isRoleExist1.StatusMasterList_Code].bgColor,
                  icon: this.statusText[isRoleExist1.StatusMasterList_Code]
                    .icon,
                });
              } else if (isRoleExist2) {
              } else {
                this.roleList.push({
                  Name: element.Name,
                  Status: statusCode,
                  // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                  color: this.statusText[-1].bgColor,
                  icon: this.statusText[-1].icon,
                });
              }
            } else if (element.Role_Code === 118) {
              const isRoleExist1 = this.rowData.ReqApprovalInfo.find(
                (x) => x.Role_Code === 118
              );
              if (isRoleExist1) {
                this.roleList.push({
                  Name: element.Name,
                  Status: statusCode,
                  // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                  color:
                    this.statusText[isRoleExist1.StatusMasterList_Code].bgColor,
                  icon: this.statusText[isRoleExist1.StatusMasterList_Code]
                    .icon,
                });
              }
            } else {
              this.roleList.push({
                Name: element.Name,
                Status: statusCode,
                // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                color: this.statusText[statusCode].bgColor,
                icon: this.statusText[statusCode].icon,
              });
            }
          } else {
            if (![118, 120].includes(element.Role_Code)) {
              this.roleList.push({
                Name: element.Name,
                Status: statusCode,
                // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
                color: this.statusText[statusCode].bgColor,
                icon: this.statusText[statusCode].icon,
              });
            }
          }
          // this.roleList.push({
          //   Name: element.Name,
          //   Status: statusCode,
          //   // Status: ((statusCode == null || statusCode == undefined) ? -1 : statusCode),
          //   color: this.statusText[statusCode].bgColor,
          //   icon: this.statusText[statusCode].icon
          // });
        }
      }


      // }
    }
  }
  checkPriority(role) {
    if (this.roleListData.some(el => el.Priority == role.Priority && el.approveStatus == true && el.RoleCode != role.RoleCode)) {
      return false;
    } if (this.roleListData.some(el => el.RoleCode == role.RoleCode && role.approveStatus == true))
    {
      return true;
    }
    else {
      if (this.roleListData.some(el => el.Priority == role.Priority && el.approveStatus == false && el.level < role.level)) {
        return false;
      }
      return true;

    }
  }
  copyText(rowData) {
   // Copy the text inside the text field
    const copyText = this.getReportingValu(rowData);
  // navigator.clipboard.writeText(copyText);
    window.navigator['clipboard'].writeText(copyText);
  }
  chkChangeClick(event) {
    this.params.context.componentParent.chkChangeClick(this.params, event);
  }

  openApproval() {
    this.params.context.componentParent.openApproval(this.params);
  }

  // multipleAproval() {
  //   this.params.context.componentParent.multipleAproval(this.params);
  // }

  addleavebalance() {
    this.params.context.componentParent.addleavebalance(this.params);
  }

  debarredLabour() {
    this.params.context.componentParent.debarredLabour(this.params);
  }
  openGatepassHistory() {
    const paylaod =
    {
      Request_Code:101,
      RequireType:0,
      'ActionType ':0,
      UserId:GLOBAL.USER_ID
    };
    this._gatePassRequestsService.getGatePassRequestHistoryData(paylaod)
  .subscribe((resData: any) => {
    const modalData = {
            historyData : resData
          };

      // modalData.labourDoc = docRes.Data;
        // modalData.labourData = data;


    const modalRef = this.modalService.open(GatePassRequestHistoryComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.resolveObject = modalData;
        // modalRef.componentInstance.requestType = this.requestType;
        // modalRef.componentInstance.Request_Code = data.Request_Code;
    modalRef.result.then((result) => {

        }).catch(dismiss => {});
  });
  }
  // openPFForm1() {
  //   // this.params.onPrintData(this.params.data);
  //   this.service.getLabourDetailByCode(this.params.data.Labour_Code).subscribe((response) => {
  //     this.selectedRowData = response[0];
  //     setTimeout(() => {                           // <<<---using ()=> syntax
  //       // tslint:disable-next-line: one-variable-per-declaration
  //       let printContents, popupWin: Window;
  //       printContents = document.getElementById('pfprint-section').innerHTML;
  //       popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //       popupWin.document.open();
  //       popupWin.document.write(`
  //       <html>
  //         <head>
  //           <style>
  //               table,
  //                 tr,
  //                 th,
  //                 td {
  //                     border: 0px solid black;
  //                     border-collapse: collapse;
  //                     font-family: "Trebuchet MS";
  //                     font-size: 12px;

  //                 }

  //                 tr,
  //                 th,
  //                 td {
  //                     border: 1px solid black;
  //                     padding: 0px;
  //                     text-align: left;

  //                 }
  //           </style>
  //         </head>
  //     <body onload="window.print();">${printContents}</body>

  //       </html>`

  //       );
  //       popupWin.document.close();
  //     }, 3000);
  //   });
  //   // tslint:disable-next-line:one-variable-per-declaration

  //   // this.showWhile = true;
  //   //   setTimeout(() => {
  //   //     //
  //   //     this.btnPrint.nativeElement.click();
  //   //     localStorage.setItem('report-data', JSON.stringify(this.LabourData));
  //   //   }, 100);
  //   // }
  // }

  getBgClass(IsSuspended: any, status: any) {
    if (status === 'Active') {
      return 'profile_img_green';
    }
    if (IsSuspended == 2) {
      return 'profile_img_red';
    }  if (IsSuspended == 1) {
      return 'profile_img_debard';
    } else {
      return 'profile_img_green';
    }
  }

  getBg1Class(IsSuspended: any, status: any) {
    if (status === 'Active') {
      return 'active-color';
    }
    if (IsSuspended == 2) {
      return 'cancel-color';
    }  if (IsSuspended == 1) {
      return 'debard-color';
    } else {
      return 'active-color';
    }
  }

  getIconClass(IsSuspended: any, status: any) {
    if (status === 'Active') {
      return 'fa fa-check';
    }
    if (IsSuspended == 2) {
      return 'icon-saDC-debarred1';
    }  if (IsSuspended == 1) {
      return 'icon-saDC-debarred';
    } else {
      return 'icon-saDC-debarred';
    }
  }

  getBg2Class(IsSuspended: any, status: any) {
    if (status === 'Active') {
      return 'status_text_green';
    }
    if (IsSuspended == 2) {
      return 'status_text_red';
    }  if (IsSuspended == 1) {
      return 'status_text_debard';
    } else {
      return 'status_text_green';
    }
  }

  getTextClass(IsSuspended: any, status: any) {
    if (status === 'Active') {
      return 'text-success';
    }
    if (IsSuspended == 2) {
      return 'text-warning';
    }  if (IsSuspended == 1) {
      return 'text-danger';
    } else {
      return 'text-success';
    }
  }

  getTooltip(IsSuspended: any, status: any) {
    if (status === 'Active') {
      return 'iCard Printed';
    }
    if (IsSuspended == 2) {
      return 'iCard pending to print';
    }  if (IsSuspended == 1) {
      return 'iCard pending to print';
    } else {
      return 'iCard pending to print';
    }
  }

  openPFForm() {
    this.params.context.componentParent.openPFForm(this.params.data);
  }

  openESICForm() {
    this.params.context.componentParent.openESICForm(this.params.data);
  }

  openEPFFormTwo() {
    this.params.context.componentParent.openEPFFormTwo(this.params.data);
  }

  openPopup() {
    this.params.context.componentParent.onPrintData(this.params.data);
  }
  openEditRequestPopup() {
    this.params.context.componentParent.openEditRequestPopup(this.params.data);
  }
  openModalGetPass() {
    this.params.context.componentParent.openSalaryInfoModal(this.params.data);
    // const data = {
    //   UEC: 60600005,
    //   IsLabour: 1,
    //   RequireType: 0,
    //   ActionType: 0,
    //   UserId: 142,
    // };
    // // const data = {
    // //   UEC: this.params.data.GatePassNo,
    // //   IsLabour: 1,
    // //   RequireType: 0,
    // //   ActionType: 0,
    // //   UserId: this.params.data.$id,
    // // };

    // this._gatePassRequestsService
    //   .WorkmanSalaryMaster(data)
    //   .subscribe((res: any) => {
    //     // debugger
    //     if (res) {
    //       this.gridRowDataAs = res;
    //       const modalRef = this.modalService.open(
    //         PopupWorkmanSalaryMasterComponent,
    //         { size: "lg", backdrop: "static", windowClass: "history-window" }
    //       );

    //       modalRef.componentInstance.rowData = res;
    //     }
    //   });
  }
  getImagePath(imgPath) {
    if (imgPath != null) {
      if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(imgPath)) {
        return imgPath;
      }
      return this.sanitizer.bypassSecurityTrustUrl(imgPath);

    }
    return 'assets/Image/blank-face.jpg';

  }
  getReportingValu(row) {
    if (this.params.repDataValueBycode()) {
      const code = this.params.repDataValueBycode();
      if (Number(code) == 1) {
      return row.SectionHead;
    }
      if (Number(code) == 2) {
       return row.DepartmentHead;
     }
      else if (Number(code) == 3) {
      return row.OwnerUserName;
    }else {
      return '';
    }
    }
  }
  chekcUserAccess(rowData) {
    const roleCode = JSON.parse(localStorage.getItem('RoleCode'));
    if ([108, 111, 112, 116, 115, 118, 120, 121, 122].includes(roleCode)) return true;

    const req_levelCode = rowData.ReqApprovalInfo.filter((i) => i.IsLastLevel == true);
    if (req_levelCode != undefined && req_levelCode != null && req_levelCode.length > 0) {
        // return this.params.ApprovalWorkFlowDetails().some(el => el.RequestLevel_Code == req_levelCode[0].RequestLevel_Code) ? false : true;
        // if(this.params.ApprovalWorkFlowDetails().some(el => el.RequestLevel_Code == req_levelCode[0].RequestLevel_Code) && this.params.ApprovalWorkFlowDetails().some(el => el.RequestLevel_Code == req_levelCode[0].RequestLevel_Code))
      if (this.params.ApprovalWorkFlowDetails && this.useraccData.some(el => el.SectionRelID == rowData.SectionPlant_ID) && this.params.ApprovalWorkFlowDetails().some(el => el.RequestLevel_Code == req_levelCode[0].RequestLevel_Code)) {
        return false;
      }
      return true;

    }
    return true;

   // return true;
    // this.useraccData.some(el => el.IsLastLevel == true) ? true : false;
    // this.isHRRoleCode = this.useraccData.some(el => el.Role_code == 118) ? true : false;
    // return true;
  }
}
