// /**
//  * @description: Used to restict user inputs
//  * it allows only numbers
//  * user can customize number inputs by allowing Negative() and/or Period signs.
//  * ----------------------------------------------------------------------------
//  * allowNegative: boolean; Default false
//  * allowPeriod: boolean; Default false
//  * @class: PreventNegativeDirective
//  *
//  */

import { Directive, ElementRef, Input, HostListener } from '@angular/core';
// // tslint:disable-next-line: completed-docs
// @Directive({
//   selector: '[trakitOnlyNumber]'
// })
// export class OnlyNumberDirective {

//   @Input() public allowNegative: boolean;
//   @Input() public allowPeriod: boolean;
//   @Input() public allowDigitAfterDecimal: number = 4;

//   constructor(private el: ElementRef) {
//     this.allowNegative = false;
//     this.allowPeriod = false;
//   }

//   // tslint:disable-next-line: completed-docs
//   @HostListener('keypress', ['$event']) private _onKeypress(e): void {
//     const key = e.key;
//     const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Tab', 'Backspace'];

//     // const keyCode = (e.which) ? e.which : e.keyCode;
//     // const commonKeyCodes = [8, 9];
//     const currentValue = this.el.nativeElement.value;
//     // MANAGE MINUS SIGH IN NUMBER
//     if (this.allowNegative && (
//       currentValue.toString.length < 0 ||
//       currentValue.toString().indexOf('-') < 0)
//     ) {
//       allowedKeys.push('-');
//     }

//     // MANAGE PERIOD IN NUMBER
//     if (this.allowPeriod && (
//       currentValue.toString.length < 0 ||
//       currentValue.toString().indexOf('.') < 0)
//     ) {
//       allowedKeys.push('.');
//     }

//     if (key) {
//       if (allowedKeys.indexOf(key) < 0) {
//         e.preventDefault();
//         return;
//       }
//     }


//     if (this.allowPeriod) {
//       const position = this.el.nativeElement.selectionStart;

//       // const next: string = [currentValue.slice(0, position), e.key == 'Decimal' ? '.' : e.key, currentValue.slice(position)].join('');
//       const [beforeDecimal, afterDecimal]: string[] = currentValue.split('.');
//       var dotPos = currentValue.indexOf('.');
//       if (position > dotPos && dotPos > -1 && (afterDecimal.length > this.allowDigitAfterDecimal)) {
//         e.preventDefault;
//         return;
//       }
//       // if (afterDecimal && afterDecimal.length >= this.allowDigitAfterDecimal) {
//       //   e.preventDefault();
//       // }
//     }
//   }

//   // tslint:disable-next-line: completed-docs
//   @HostListener('paste', ['$event']) private _paste(e) {
//     const data = e.clipboardData.getData('Text');
//     if (isNaN((data)) || (data.indexOf('-') > -1 && !this.allowNegative) || (data.indexOf('.') > -1 && !this.allowPeriod)) {
//       e.preventDefault();
//     }
//     // if (data.indexOf('.') !== -1 || data.indexOf(' ') !== -1) {
//     //   event.preventDefault();
//     // }
//     // e.preventDefault();
//   }

// }


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appOnlyNumber]',
})
export class OnlyNumbeDirective {

  /** Wether Period allowed or not */
  @Input() public allowPeriod: boolean;
  /** Wether Negative allowed or not */
  @Input() public allowNegative: boolean;
  /** digit Allowed After Dot Point */
  @Input() public digitAllowedAfterDotPoint: number = 4;

  /** Number RegExp */
  private numberRegex: RegExp = new RegExp(/^(0|[0-9]\d*)?$/);
  /** Negative Number Regex */
  private negativeNumberRegex: RegExp = new RegExp(/^-?(0|[0-9]\d*)?$/);
  /** Period Regex */
  private periodRegex: RegExp = new RegExp(/^(\d{0,9})([.]{1}\d{0,4})?$/g);
  // private periodRegex: RegExp = new RegExp(/^(0|[1-9]\d*)+[.]?\d{0,4}$/g);
  /** Negative Period Regex */
  private negativePeriodRegex: RegExp = new RegExp(/^(-?\d{0,4})([.]{1}\d{0,4})?$/g);

  // private periodRegex: RegExp = new RegExp(/^\d+[.]?\d{0,4}$/g);// user can put .
  // input also cannot start from , or .
  /** Special Keys */
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(
    private el: ElementRef
  ) { }

  /**
   * Updates the  value on the keydown event.
   */
  @HostListener('keydown', ['$event']) public onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    this.checkNumber(next, event);
  }


  // tslint:disable-next-line: completed-docs
  @HostListener('paste', ['$event']) public _paste(e) {
    const data = e.clipboardData.getData('Text');
    this.checkNumber(data, e);
    // if (isNaN((data)) || (data.indexOf('-') > -1 && !this.allowNegative) || (data.indexOf('.') > -1 && !this.allowPeriod)) {
    //   e.preventDefault();
    // }
    // if (data.indexOf('.') !== -1 || data.indexOf(' ') !== -1) {
    //   event.preventDefault();
    // }
    // e.preventDefault();
  }


  /** it will regex */
  private getRegex(digitAllowedAfterDotPoint: number): RegExp {
    // /^-?(0|[1-9]?\d*)+[.]?\d{0,4}$/g
    return new RegExp('^-?(0|[1-9]?\d*)+[.]?\d{0,' + digitAllowedAfterDotPoint + '}$/g');

    // return new RegExp('/^\d+[.]?\d{0,' + digitAllowedAfterDotPoint + '}$/g');
  }

  /** check if it is valid number */
  private checkNumber(data: string, event: any): void {
    if (this.allowPeriod && this.allowNegative) {
      if (data && !String(data).match(this.negativePeriodRegex)) {
        event.preventDefault();
      }
      return;
    } else if (this.allowPeriod) {
      if (data && !String(data).match(this.periodRegex)) {
        
        event.preventDefault();
      }
      return;
    }
    else if (this.allowNegative) {
      if (data && !String(data).match(this.negativeNumberRegex)) {
        event.preventDefault();
      }
      return;
    } else {
      if (data && !String(data).match(this.numberRegex)) {
        event.preventDefault();
      }
      return;
    }
  }
}