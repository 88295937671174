import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  Input,
} from "@angular/core";

import SignaturePad from "signature_pad";
@Component({
  selector: "app-signaturepad",
  templateUrl: "./signaturepad.component.html",
  styleUrls: ["./signaturepad.component.css"],
})
export class SignaturepadComponent implements OnInit, AfterViewInit {
  @ViewChild("sPad") signaturePadElement;
  signaturePad: any;
  @Output() signatureData = new EventEmitter<any>();
  @Output() clearSignature = new EventEmitter<void>();
  @Input() configuration: any; //  png , svg ,jpg ,undo
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(
      this.signaturePadElement.nativeElement
    );
    this.signaturePad.penColor = "blue";

    this.initializeCanvas();
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  SaveSignature() {
    if (!this.signaturePad.isEmpty()) {
      const dataURL = this.signaturePad.toDataURL();
      this.signatureData.emit(dataURL);
    }
  }

  download(dataURL, filename) {
    if (
      navigator.userAgent.indexOf("Safari") > -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    ) {
      window.open(dataURL);
    } else {
      const blob = this.dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  savePNG() {
    if (!this.signaturePad.isEmpty()) {
      const canvas = this.signaturePadElement.nativeElement;
      const dataURL = canvas.toDataURL();
      //const dataURL = this.signaturePad.toDataURL();
      this.download(dataURL, "signature.png");
    }
  }

  saveJPG() {
    if (!this.signaturePad.isEmpty()) {
      const canvas = this.signaturePadElement.nativeElement;
      const dataURL = canvas.toDataURL("image/jpeg");
      //const dataURL = this.signaturePad.toDataURL('image/jpeg');

      this.download(dataURL, "signature.jpg");
    }
  }

  saveSVG() {
    if (!this.signaturePad.isEmpty()) {
      const canvas = this.signaturePadElement.nativeElement;
      const dataURL = canvas.toDataURL("image/svg+xml");
      // const dataURL = this.signaturePad.toDataURL('image/svg+xml');
      this.download(dataURL, "signature.svg");
    }
  }

  initializeCanvas() {
    const canvas = this.signaturePadElement.nativeElement;
    const ctx = canvas.getContext("2d");

    // Set canvas background to white
    ctx.fillStyle = "#ffffff"; // Set white color
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill entire canvas with white color
  }

  clearCanvas() {
    const canvas = this.signaturePadElement.nativeElement;
    const ctx = canvas.getContext("2d");

    // Clear canvas by filling it with white color
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
}
