import { Component, OnInit } from '@angular/core';
import { AttendanceService } from './attendance.service';
import { GridApi, GridOptions } from 'ag-grid-community';
import {
  ExpandRowComponent,
  AgPunchInCellRendererComponent,
  AgDateCellRendererComponent,
  AgDropDownCellRendererComponent,
  AgSaveCellRendererComponent
} from './ag-helper';
import { GLOBAL } from '../app.globals';
import * as moment from 'moment';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  punchList = [];
  public userID: any;
  currentPunchRowIndex: number;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  punchData;
  columnDefs;
  rowData: any;
  constructor(
    private attendanceService: AttendanceService
  ) {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: true,
      headerHeight: 40,
      frameworkComponents: { fullWidthCellRenderer: AgPunchInCellRendererComponent },
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      fullWidthCellRenderer: 'fullWidthCellRenderer',
      fullWidthCellRendererParams: {
        isDisable: (params) => {
          if (params.data.rowData) {
            return params.data.rowData.FShift_Code === -2 || params.data.rowData.FShift_Code === -1;
          }
          return false;
        },
        punchDetailsUpdated: (punchData) => {
          this.punchData = punchData;
        }
      }
    };
  }

  ngOnInit() {
    const model = {
      UserId: localStorage.getItem('UserID')
    };
    this.attendanceService.getPunchlistOfEmployees(model).subscribe((res: any[]) => {
      this.punchList = res;
      this.rowData = res;
      setTimeout(() => {
        this.gridAPI.sizeColumnsToFit();
        // tslint:disable-next-line:align
      }, 10);
    });
    this.columnDefs = [
      {
        headerName: '#', field: 'sortCode', width: 80,
        sortable: false, suppressMenu: true,
        cellRendererFramework: ExpandRowComponent
      },
      {
        headerName: 'Code', field: 'GatePassNo', width: 80,
        sortable: false, filter: true
      },
      {
        headerName: 'IVR Code', field: 'ULC', hide: 'true',
        sortable: false, filter: true
      },
      {
        headerName: 'Date', field: 'AttDate', width: 80,
        sortable: false, suppressMenu: true
      },
      {
        headerName: 'Name', field: 'FullName', width: 120,
        sortable: false, suppressMenu: true
      },
      {
        headerName: '1st Shift', field: 'FShift_Code', sortable: false, suppressMenu: true, width: 130,
        cellRendererFramework: AgDropDownCellRendererComponent,
        cellRendererParams: {
          shifts: GLOBAL.SHIFT_LIST,
          forceSelection: (params) => {
            return (params === -2 || params === -1) ? {
              field: 'SShift_Code',
              value: -2
            } : false;
          },
        }
      },
      {
        headerName: 'In Date Time', field: 'FSInPunch', sortable: false, suppressMenu: true, width: 250,
        // valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
          },
        }
      },
      {
        headerName: 'Out Date Time', field: 'FSOutPunch', sortable: false, suppressMenu: true, width: 250,
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
          },
        }
      },
      {
        headerName: '2nd Shift', field: 'SShift_Code', sortable: false, suppressMenu: true, width: 130,
        cellRendererFramework: AgDropDownCellRendererComponent, hide: 'params.data.IsAllowSecondShiftVisible === 0',
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
          },
          shifts: GLOBAL.SHIFT_LIST.filter(shift => shift.Shift_Code !== -1)
        }
      },
      // {
      //   headerName: '2nd Shift1', field: 'SShift_Code', sortable: false, suppressMenu: true, width: 130,
      //   cellRendererFramework: AgDropDownCellRendererComponent, show: 'params.data.IsAllowSecondShiftVisible === 1',
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
      //     },
      //     shifts: GLOBAL.SHIFT_LIST.filter(shift => shift.Shift_Code !== -1)
      //   }
      // },
      {
        headerName: 'In Date Time', field: 'SSInPunch1', sortable: false, suppressMenu: true, width: 250,
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent, hide: 'params.data.IsAllowSecondShiftVisible === 0',
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
              || params.data.SShift_Code === -2;
          },
        }
      },
      // {
      //   headerName: 'In Date Time1', field: 'SSInPunch', sortable: false, suppressMenu: true, width: 250,
      //   valueFormatter: this.timeValueFormatter,
      //   cellRendererFramework: AgDateCellRendererComponent, show: 'params.data.IsAllowSecondShiftVisible === 1',
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
      //         || params.data.SShift_Code === -2;
      //     },
      //   }
      // },
      {
        headerName: 'Out Date Time', field: 'SSOutPunch1',
        sortable: false, suppressMenu: true, width: 250, hide: 'params.data.IsAllowSecondShiftVisible === 0',
        valueFormatter: this.timeValueFormatter,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
              || params.data.SShift_Code === -2;
          },
        }
      },
      // {
      //   headerName: 'Out Date Time1', field: 'SSOutPunch',
      //   sortable: false, suppressMenu: true, width: 250, show: 'params.data.IsAllowSecondShiftVisible === 1',
      //   valueFormatter: this.timeValueFormatter,
      //   cellRendererFramework: AgDateCellRendererComponent,
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1
      //         || params.data.SShift_Code === -2;
      //     },
      //   }
      // },
      {
        headerName: 'WH', field: 'WorkingHours', sortable: false, suppressMenu: true, width: 80,
      },
      {
        headerName: 'OT', field: 'OTHours', width: 80,
        sortable: false, suppressMenu: true
      },
      {
        headerName: '', field: '', width: 40,
        sortable: false, suppressMenu: true,
        cellRendererFramework: AgSaveCellRendererComponent,
        cellRendererParams: {
          isDisable: (params) => {
            // return ((params.data.FShift_Code !== -2 || params.data.FShift_Code !== -1)
            //   && (!moment(params.data.FSInPunch).isValid() || !moment(params.data.FSOutPunch).isValid()))
            // || (params.data.SShift_Code !== -2
            //   && (!moment(params.data.SSInPunch).isValid() || !moment(params.data.SSOutPunch).isValid()));
          }
        }
      }
    ];
  }
  timeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('HH:mm');
    }

    return '';
  }
  getPunchInfo(rowData, rowIndex) {
    if (this.currentPunchRowIndex === rowIndex) {
      const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex);
      expandedRow.data.expanded = false;
      this.gridAPI.updateRowData({ update: [expandedRow.data] });
      const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex + 1);
      this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      this.currentPunchRowIndex = undefined;
    } else {
      // First close previously opend row index
      if (this.currentPunchRowIndex !== undefined) {
        const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex);
        expandedRow.data.expanded = false;
        this.gridAPI.updateRowData({ update: [expandedRow.data] });
        const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex + 1);
        this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      }
      this.rowData = rowData;
      // add row
      this.currentPunchRowIndex = rowIndex;
      this.gridAPI.updateRowData({
        add: [{
          rowData,
          fullWidth: true
        }],
        addIndex: rowIndex + 1
      });
    }
  }

  saveRecord(params) {
    const tempParams = {
      attendance: params,
      punchData: this.punchData
    };
    this.attendanceService.savePunchdDetails(tempParams).subscribe((res) => { });
  }
}
