import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GLOBAL, MANPOWER_REQ_ADDREMOVE_INPUT } from 'src/app/app.globals';

@Injectable({
  providedIn: 'root'
})
export class ManPowerUnassignService {
  public subject: Subject<any>;
  constructor(private httpClient: HttpClient) {
    this.subject = new Subject();
  }

  // public getMonthlyRequition(): Observable<any> {
  //   return this.httpClient.get(GLOBAL.APIS.MAN_POWER.GET_MONTHLY_REQUITION2).pipe(map((response: any) => response));
  // }
  public getMonthlyRequition(model: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_MONTHLY_REQUITION}`;

    return this.httpClient.post(url, model);
  }

  public approveAndSavePowerRequisition(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.APPROVE_AND_SAVE_POWER_REQUISITION}`;
    return this.httpClient.post<any[]>(url, data).pipe(
      map((response: any) => response.Data));
  }
}
