import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "../app.globals";
declare var $: any;
import { Observable, of } from "rxjs";
import { Contractor } from "./attendance-approve/attendance-approve.model";
import { map } from "rxjs/operators";
import { AttendanceApproveAdapter } from "./attendance-approve/adapter/attendance-approve.adapter";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AttendanceService {
  constructor(
    private httpClient: HttpClient,
    private adapter: AttendanceApproveAdapter
  ) {}

  getPunchlistOfEmployees(data: any) {
    // const queryString = $.param({
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_EMPLOYEE_WISE_MANUAL_PUNCHES}?${queryString}`,
    //   httpOptions
    // );
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_EMPLOYEE_WISE_MANUAL_PUNCHES}`;
    return this.httpClient
      .post<any>(url, data, httpOptions)
      .pipe(map((response: any) => response));
  }

  getStatusList(data: any) {
    // const queryString = $.param({
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_EMPLOYEE_WISE_MANUAL_PUNCHES}?${queryString}`,
    //   httpOptions
    // );
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_STATUS_LIST}`;
    return this.httpClient
      .post<any>(url, data, httpOptions)
      .pipe(map((response: any) => response));
  }

  getPunchDetails(data: any) {
    // const queryString = $.param(params.LabourAttResult_Code);
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_EMPLOYEE_WISE_MANUAL_PUNCH_DETAILS}?${queryString}`,
    //   httpOptions
    // );
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_EMPLOYEE_WISE_MANUAL_PUNCH_DETAILS}`;
    return this.httpClient
      .post<any>(url, data, httpOptions)
      .pipe(map((response: any) => response));
  }

  getAllWorkLabours(labourParams): Observable<Object> {
    const model = {
      Contractor: "",
    };
    return this.httpClient
      .post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_DATA}`, model)
      .pipe(map((response: any) => response));
  }

  // const body = JSON.stringify(labourParams);
  // httpOptions['body'] = body;
  // return this.httpClient.request(
  // GLOBAL.HTTP_POST,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_DATA}`,
  // httpOptions
  // );
  // }

  savePunchdDetails(data: any) {
    // const queryString = $.param(params);
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_EMPLOYEE_WISE_MANUAL_PUNCH_DETAILS}?${queryString}`,
    //   httpOptions
    // );
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.SAVE_SINGLE_PUNCH}`;
    return this.httpClient
      .post<any>(url, data, httpOptions)
      .pipe(map((response: any) => response));
  }

  getAllContractorData(): Observable<any> {
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_CONTRACTORS
    );
  }

  getGatepassWiseShiftList(data: any): Observable<any> {
    return this.httpClient.post(
      GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GATEPASS_WISE_SHIFT_INFO,
      data
    );
    // return of([
    //   {
    //     ShiftName: 'Test',
    //     Shift_Code: 101
    //   }
    // ])
  }

  changeShift(data: any): Observable<any> {
    return this.httpClient.post(
      GLOBAL.APIS.ATTENDANCE_MANAGEMENT.CHANGE_SHIFT,
      data
    );
  }

  getActiveLabourDetails(formData) {
    //   const model = {
    //     Company_Code: '',
    //     Plant_Code: '',
    //     Unit_Code: '',
    //     Department_Code: '',
    //     Section_Code: '',
    //     NatureOfWork_Code: '',
    //     // ShiftGroupType_Code: '',
    //     Shift_Code: '',
    //     IncludingSubContractor: '',
    //     Contractors: '',
    //     SubContractors: '',
    //     WorkOrder_Code: '',
    //     SubWorkOrder_Code: '',
    //     License_Code: '',
    //     SubLicense_Code: '',
    //     ESIC: '',
    //     SubESIC: '',
    //     WCPolicy: '',
    //     SubWCPolicy: '',
    //     Gender: '',
    //     Skill_Code: '',
    //     Trade_Code: '',
    //     Qulification_Code: '',
    //     TechQualification_Code: '',
    //     StreamQualification_Code: '',
    //     Religion_Code: '',
    //     Cast_Code: '',
    //   };
    //   return this.httpClient.post(`${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_All_ACTIVELABOUR_DATA}`, model).pipe(
    //     map((response: any) => response));
    // }

    const body = JSON.stringify(formData);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_All_ACTIVELABOUR_DATA}`,
      httpOptions
    );
  }

  saveLabourWeekOffDetails(formData) {
    const body = JSON.stringify(formData);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.POST_LABOUR_WEEKOFFDETAIL}`,
      httpOptions
    );
  }
  public getApproveAttendanceList(data: any): Observable<any> {
    // const url = `${GLOBAL.APIS.REPORTS.GET_UNITWISE_PRESENT}`;
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_APPROVE_ATTENDANCE_LIST}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
    // map((response: any) => response.Data.Table));
  }
  // getApproveAttendanceList(data) {
  //   const model = {
  //     StartDate: null,
  //     EndDate: null,
  //     UserId: null,
  //     company_Code: null,
  //     plant_Code: null,
  //     SectionPlant_ID: null,
  //     Contractor: null,
  //     SubContractors: null,
  //     workOrder_Code: null,
  //     subWorkOrder_Code: null,
  //     license_Code: null,
  //     subLicense_Code: null,
  //     ESIC: null,
  //     subESIC: null,
  //     WCPolicy: null,
  //     subWCPolicy: null,
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_APPROVE_ATTENDANCE_LIST}`, model).pipe(
  //     map((response: any) => response));
  // }
  // getApproveAttendanceList(data) {
  //   const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_APPROVE_ATTENDANCE_LIST}`;
  //   return this.httpClient.post<any>(url, data, httpOptions).pipe(
  //     map((response: any) => response.Data.Table));
  // }
  // public getApproveAttendanceList(data: any): Observable<any> {
  //   const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_APPROVE_ATTENDANCE_LIST}`;
  //   return this.httpClient.post<any>(url, data).pipe(
  //     map((response: any) => response));
  // }
  // public getContractors(excludeContractorCode: string = '', workOrderCode: any = ''): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isMain=`
  //     + `&ExcludeContractor_Code=${excludeContractorCode}&workOrderCode=${workOrderCode}&contractor_Code=`;
  //   return this.httpClient.get<any[]>(url).pipe(
  //     map((response: any) => response.Data.Table));
  // }
  public getPunchFromReaderDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
  }

  public getDailyPresentDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_DAILY_PRESENT}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
  }

  public getWorkOrdersByContractorCode(
    contractorCode: number,
    plantcode: number
  ): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
    return this.httpClient
      .get<any>(url)
      .pipe(map((response: any) => response.Data));
  }

  public getSubContractorByWODataforDA(
    contractorCode: number,
    workOrderNo: number
  ): Observable<Contractor[]> {
    const url = `http://cmmsborlapi.spikyarc.com/api/UserManagement/GetSubContractorByWODataforDA?contractorCode=
    ${contractorCode}&workOrder=${workOrderNo}`;
    return this.httpClient
      .request<Contractor[]>(GLOBAL.HTTP_GET, url)
      .pipe(
        map((items: any[]) =>
          items.map((item: any) => this.adapter.toContractorResponse(item))
        )
      );
  }
  getLabourName() {
    const model = {
      Search: "",
      UserID: localStorage.getItem("UserID"),
      RoleCode: JSON.parse(localStorage.getItem("Role"))[0].Role_Code,
    };
    return this.httpClient
      .post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOURNAME}`, model)
      .pipe(map((response: any) => response));
  }

  //    return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOURNAME}?${queryString}`,
  // httpOptions
  // );
  // }
  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  getLicenses(contractorCode: any) {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient
      .get(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  getWCPolicy(contractorCode: any) {
    const url = `${GLOBAL.APIS.REPORTS.GET_WC_POLICY}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient
      .get(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS_SP}`;
    const payload = {
      "search": null,
      "Company": companyCode || 101,
      "State": null,
      "City": null,
      "Country": null,
      "UnitType": null,
      "selectedField": "Plant,Plant_Code,State_Code,State,SiteMILCode,SITE,Company_Code,IsESICCovered,ESICNumber",
      "OwnerShip": null,
      "LocatedAt": null,
      "IsESICApplicable": null,
      "WorkOrder": null,
      "SubWorkOrder": null,
      "UserID": localStorage.getItem("UserID"),
      "RequireType": 0,
      "ActionType": 0
    }
    return this.httpClient
      .post<any[]>(url, payload)
  }

  public getUnits(plantCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?Search=&PlantCode=${plantCode}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public getShift(plantCode: any, gatepassno: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFT}?Search=&PlantCode=${plantCode}&GatepassNo=${gatepassno}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public getDesignations(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DESIGNATION}?Search=&companyCode=${companyCode}&GradeCode=&LevelCode=`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response));
  }

  public getMasterItemByCode(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }
  public getDepartments(
    plantCode: any = "",
    unitCode: any = ""
  ): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS}?Search=&PlantCode=${plantCode}&UnitCode=${unitCode}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  getDepartmentData(unit) {
    const model = {
      Unit_Code: "",
    };
    return this.httpClient
      .post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}`, model)
      .pipe(map((response: any) => response));
  }
  public getDepartmentsbyUnit(unitCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS_FTR}?unitCode=${unitCode}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data));
  }

  public getSections(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SECTION}?Search=`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response));
  }
  getSectionData() {
    const model = {
      Search: "",
      // ParentSectionCode: '',
      // PlantCode: '',
      DepartmentCode: "",
      // UnitCode: '',
      // CompanyCode: ''
    };

    return this.httpClient
      .post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SECTION_DATA}`, model)
      .pipe(map((response: any) => response));
  }
  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS_SP}`;
    const payload = {
      "Search": null,
      "RequiredFields": "NatureOfWork_Code,Name,",
      "RequireType": 0,
      "ActionType": 0,
      "UserId": localStorage.getItem("UserID")
    }
    return this.httpClient
      .post<any[]>(url, payload)
      .pipe(map((response: any) => response));
  }

  public getShiftTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTTYPES}?Search=`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response));
  }

  // public getShifts(): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTS}?Search=&PlantCode=&ShiftTypeGroupCode=`;
  //   return this.httpClient.get<any[]>(url).pipe(
  //     map((response: any) => response.Data.Table));
  // }

  public getShifts(plantCode: any, shiftTypeGroupCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTS}?Search=&PlantCode=${plantCode}&ShiftTypeGroupCode=${shiftTypeGroupCode}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public getSkills(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SKILLS}?Search=&PlantCode=&ParentSkillCode=&TradeCode=`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response));
  }

  // public addFilter(data: any): Observable<any[]> {
  //
  //   const url = `${GLOBAL.APIS.REPORTS.ADD_FILTER}?User_Code=${localStorage.getItem('UserID')}
  //   &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
  //   return this.httpClient.post(url, {}).pipe(
  //     map((response: any) => response.Data));
  // }

  // public updateFilter(data: any): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.REPORTS.UPDATE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${localStorage.getItem('UserID')}
  //   &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
  //   return this.httpClient.post(url, {}).pipe(
  //     map((response: any) => response.Data));
  // }

  public addFilter(data: any): Observable<any[]> {
    const url = `${
      GLOBAL.APIS.REPORTS.ADD_FILTER
    }?User_Code=${localStorage.getItem("UserID")}
    &Page_Code=${data.Page_Code}&FilterName=${
      data.FilterName
    }&JSONTextDetails=${data.JSONTextDetails}`;
    return this.httpClient.post(url, {}).pipe(map((response: any) => response));
  }

  public addFilterData(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_FilterMaster`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
    //  return of(this.totalPlantWiseData());
  }

  public updateFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.UPDATE_FILTER}?Report_Code=${
      data.Report_Code
    }&User_Code=${localStorage.getItem("UserID")}
    &Page_Code=${data.Page_Code}&FilterName=${
      data.FilterName
    }&JSONTextDetails=${data.JSONTextDetails}`;
    return this.httpClient.post(url, {}).pipe(map((response: any) => response));
  }

  public deleteFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.DELETE_FILTER}?Report_Code=${
      data.Report_Code
    }&User_Code=${localStorage.getItem("UserID")}`;
    return this.httpClient.post(url, {}).pipe(map((response: any) => response));
  }

  public getALLSavedFilterDescripationUserWise(
    pagecode: number
  ): Observable<any[]> {
    const url = `${
      GLOBAL.APIS.REPORTS.GET_FILTER_PAGEWISE
    }?User_Code=${localStorage.getItem("UserID")}&Page_Code=${pagecode}`;
    return this.httpClient
      .get(url)
      .pipe(
        map((response: any) =>
          response.map((item: any) => this.adapter.parseJsonString(item))
        )
      );
  }
  public approveAttendanceManualPunch(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.APPROVE_ATTENDENCE_Manual_Punch}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response.Data));
  }
  public approveAttendance(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.APPROVE_ATTENDENCE_BY_SEC_HEAD}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response.Data));
  }

  public getListForApproval(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.LIST_FOR_APPROVAL}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public getListWrongPunch(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_LIST_WRONGPUNCH}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }
  public updateWrongPunch(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.UPDATE_WRONGPUNCH}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public punchbiotoclpms(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.PUNCH_TRNASFER}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public updateExceptionalAttendance(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.UPDATE_EXCEPTIONAL_ATTENDANCE}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public deleteWrongPunch(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.DELETE_WRONGPUNCH}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public revertWrongPunch(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.REVERT_WRONGPUNCH}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  // kh 22-02-2020
  public getLabourFilter(data: any): Observable<any[]> {
    /// use for lbaour filter bind
    //  @contractors VARCHAR(MAX) = NULL ,
    //     @subContractors VARCHAR(MAX) = NULL ,
    //     @plant_Code VARCHAR(MAX) = NULL ,
    //     @unit_Code VARCHAR(MAX) = NULL ,
    //     @UserId BIGINT
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_EMP}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public getLaboursFilter(data: any): Observable<any[]> {
    /// use for lbaour filter bind
    //  @contractors VARCHAR(MAX) = NULL ,
    //     @subContractors VARCHAR(MAX) = NULL ,
    //     @plant_Code VARCHAR(MAX) = NULL ,
    //     @unit_Code VARCHAR(MAX) = NULL ,
    //     @UserId BIGINT
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_EMPLOYEE}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public getEmployeewiseExceptionalAttendance(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_EMPLOYEEWISE_EXCEPTIONAL_ATTENDANCE}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }
  public getbioserverdata(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_BIO_SERVER}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }
  public getbioserverdatabulk(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_BULK_BIO_PUNCH}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  submitbioservertoclpms(data: any) {
    const url = `${GLOBAL.APIS.MAN_POWER.SAVE_BIO_PUNCH}`;
    return this.httpClient
      .post<any>(url, data, httpOptions)
      .pipe(map((response: any) => response));
  }
  // public getWrongPunchLabourFilter(data: any): Observable<any[]> {
  //
  //   /// use for lbaour filter bind
  //   //  @contractors VARCHAR(MAX) = NULL ,
  //   //     @subContractors VARCHAR(MAX) = NULL ,
  //   //     @plant_Code VARCHAR(MAX) = NULL ,
  //   //     @unit_Code VARCHAR(MAX) = NULL ,
  //   //     @UserId BIGINT
  //   const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_WRONGPUNCH_EMP}`;
  //   return this.httpClient.post(url, data).pipe(
  //     map((response: any) => response));
  // }
  // kh 22-02-2020

  public getAttendancePunchManagement(data: any): Observable<any[]> {
    /// use for lbaour filter bind

    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.ATTENDANCE_PUNCH_DATA}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public getSingleLabourPunchBetweenTwoDates(data: any): Observable<any[]> {
    /// use for lbaour filter bind

    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.LABOUR_PUNCH_DATA}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }
  public getSingleLabourPunchBetweenTwoDates1(data: any): Observable<any[]> {
    /// use for lbaour filter bind

    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.LABOUR_PUNCH_DATA1}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  public getExceptionalAttendanceDetails(data: any): Observable<any> {
    // const url = `${GLOBAL.APIS.REPORTS.GET_UNITWISE_PRESENT}`;
    const url = `${GLOBAL.APIS.REPORTS.GET_UNITWISE_PRESENT_CMN}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
  }

  public addExceptionalAttendance(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_DATA_BY_EXCEPTIONAL_ATTENDANCE}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response.Data));
  }

  public insertExceptionalAttendance(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_INSERT_EXCEPTIONAL_ATTENDANCE}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
  }

  public getExeMasterData(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_Update_Delete_List_eComplainceErrorMsg`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
    //  return of(this.totalPlantWiseData());
  }
  public approveAttendanceBySec(data: any): Observable<any> {
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_Insert_ApproveInfo`;
    const url = `${GLOBAL.APIS.MAN_POWER.APPROVE_ATTENDENCE_BY_SEC_HEAD2}`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
  }

  public rejectAttendanceBySec(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.CLPMS_SP_Bulk_AttendnaceStatuUpdate}`;
    return this.httpClient.post<any>(url, data);
  }

  getManualPunchForApprovalrs(type: number) {
    const queryString = $.param({
      StartDate: "",
      EndDate: "",
      UserID: localStorage.getItem("UserID"),
      Plant_Code: "",
      Unit_Code: "",
      Department_Code: "",
      Section_Code: "",
      ShiftGroupType_Code: "",
      Shift_Code: "",
      Type: type,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_Attendance_Aprroval_Grid}?${queryString}`,
      httpOptions
    );
  }
  getRoleByUserID() {
    const queryString = $.param({
      UserID: localStorage.getItem("UserID"),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_RoleCodeByUserID}?${queryString}`,
      httpOptions
    );
  }
  public getContractors(
    excludeContractorCode: string = "",
    workOrderCode: any = ""
  ): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isMain=&
    ExcludeContractor_Code=${excludeContractorCode}&workOrderCode=${workOrderCode}&contractor_Code=`;
    // console.log(url);
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  localUserID = localStorage.getItem("UserID");
  public getSubContractors(contractorCode: number, workOrderCode: any): Observable<any[]> {
    const payload = {
      isMain: "0",
      Contractor_Code: contractorCode,
      workOrderCode: workOrderCode,
      UserID: this.localUserID,
    };
    
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_SUBCONTRACTORS_SP}`;
    return this.httpClient.post<any>(url, payload).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          console.warn('API returned no data.');
          return [];
        }
      })
    );
  }

  public getSubWorkOrder(reqObj: any): Observable<any[]> {
    const payload = {
    Contractor_Code: reqObj.Contractor_Code, 
    SubContractor_Code: reqObj.reqObj, 
    WorkOrder_Code: reqObj.WorkOrder_Code, 
    UserId: this.localUserID,
    };
    
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_SUBWORKORDER}`;
    return this.httpClient.post<any>(url, payload).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          console.warn('API returned no data.');
          return [];
        }
      })
    );
  }

  public getLabourList(): Observable<any[]> {
    const payload = {
      UserId: this.localUserID,
      RoleCode: 118
    };
    
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_LABOUR}`;
    return this.httpClient.post<any>(url, payload).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          console.warn('API returned no data.');
          return [];
        }
      })
    );
  }

  public getRecoveryType(): Observable<any[]> {
    const payload = {
      Filter: {
              ShortCode: "GM1136",
              RequireType: 1,
              ActionType: 0,
             UserId: this.localUserID
            },
      Fields: [
              {
                  "keyName": "Parent_Code",
                  "DisplayName": "ParentName"
              },
              {
                  "keyName": "MasterItem_Code",
                  "DisplayName": "Name",
                  "ExtraFields": [
                      "Pincode_Code",
                      "ZipCode",
                      "ShortName",
                      "ShortCode",
                      "Page_Code",
                      "Module_Code",
                      "MouleName",
                      "AppsGroups_Code",
                      "AppGroupName",
                      "IsDelete",
                      "IsActive"
                  ] 
              }  
              ]
       }
    
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_RECOVERYTYPE}`;
    return this.httpClient.post<any>(url, payload).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          return [];
        }
      })
    );
  }
  public getDateOfRecovery(date:any): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_DATEOFRECOVERYTYPE}`;
    return this.httpClient.post<any>(url, date).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          return [];
        }
      })
    );
  }

  public onSubmitLoanAndRecoveries(payload:any): Observable<any[]> {
 
    const url = `${GLOBAL.APIS.COMMON.COMMON_INSERT_UPDATE_ALL_SP}`;
    return this.httpClient.post<any>(url, payload).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          return [];
        }
      })
    );
  }
  public getLoanAndRecoveriesData(reqObj:any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMMON.COMMON_LIST_LOANANDRECOVERIES}`;
    return this.httpClient.post<any>(url, reqObj).pipe(map((response: any) => {
        if (response) {
          return response;
        } else {
          return [];
        }
      })
    );
  }
  

  public getShiftGroupTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTGROUPTYPES}?Search=`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public getContractorsbydefault(
    userId: any,
    roleCode: any
  ): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORSBYDEFAULT}?UserId=${userId}&RoleCode=${roleCode}`;
    // console.log(url);
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }
  // Import Manual Attendance Data
  public submitrequestWeekOFFData(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.SUBMITREQUEST_WEEK_OFF_DATA}`,
      httpOptions
    );
  }
  getpreviousdayrecords(data: any) {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_PRIVIOUSDAY_RECORD}`;
    return this.httpClient
      .post<any>(url, data, httpOptions)
      .pipe(map((response: any) => response));
  }

  public attendancereprocess(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.MAN_POWER.ATTENDANCE_REPROCESS}`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response.Data));
  }
  getActiveLabourSyc(filterParm) {
    const body = JSON.stringify(filterParm);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      // `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_ACTIVELABOUR_DATA_Syc}`,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_LISTING_ACTIVE_LABOUR}`,
      httpOptions
    );
  }
  getActiveLabour(filterParm) {
    const body = JSON.stringify(filterParm);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_ACTIVELABOUR_DATA}`,
      httpOptions
    );
  }

  public checkExistingWeekOffDate(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.CHECK_WEEK_OFF_DATE_DUPLICATION}`;
    return this.httpClient.post<any>(url, data);
  }

  public checkExistingWeekOffShiftDate(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.CHECK_WEEK_OFF_SHIFT_DATE_DUPLICATION}`;
    return this.httpClient.post<any>(url, data);
  }

  public getUserOTApprovalList(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.GET_CLPMS_LIST_OT_APPROVAL_MM}`;
    return this.httpClient.post<any>(url, data);

    // return of(this.getOTApprovalDataTestData());
  }

  public saveUserOTApprovalList(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MAN_POWER.SUBMIT_OT_APPROVAL}`;
    return this.httpClient.post<any>(url, data);

    // return of(this.getOTApprovalDataTestData());
  }

  private getOTApprovalDataTestData(): any[] {
    return [
      {
        date: "14/5/2022",
        sections: [
          {
            name: "Section 1",
            shifts: [
              {
                name: "Shift 11",
              },
              {
                name: "Shift 12",
              },
            ],
          },
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 21",
              },
              {
                name: "Shift 22",
              },
            ],
          },
        ],
      },
      {
        date: "15/5/2022",
        sections: [
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 11",
              },
              {
                name: "Shift 12",
              },
            ],
          },
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 21",
              },
              {
                name: "Shift 22",
              },
            ],
          },
        ],
      },
      {
        date: "15/5/2022",
        sections: [
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 11",
              },
              {
                name: "Shift 12",
              },
            ],
          },
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 21",
              },
              {
                name: "Shift 22",
              },
            ],
          },
        ],
      },
      {
        date: "15/5/2022",
        sections: [
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 11",
              },
              {
                name: "Shift 12",
              },
            ],
          },
          {
            name: "Section 2",
            shifts: [
              {
                name: "Shift 21",
              },
              {
                name: "Shift 22",
              },
            ],
          },
        ],
      },
    ];
  }

  public DownloadCLPMSsampleExel(payload): Observable<any> {
    const url = `${GLOBAL.APIS.COMMON.CLPMS_LINK_SAMPLE_EXEL}`;

    const paylaod = {
      Plant_Code: null,
      SiteMIL_Code: 950,
      Short_Code: "LBRIMPEXL001",
      ISActiveStatus: 1,
      RequireType: 1,
      ActionType: 0,
      UserID: GLOBAL.USER_ID,
    };

    return this.httpClient.post(url, paylaod);
  }

  public globalSettingCode(code: string) {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?Code=${code}`;

    return this.httpClient.get(url);
  }

  public getCommonSpUserPlantAcces() {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMMON_SP_USERPLANTACCES}`;

    let payload = {
      UserID: GLOBAL.USER_ID,
      RequireType: 1,
      SiteMIL_Code: null,
      ActionType: null,
    };

    return this.httpClient.post<any[]>(url, payload);
  }
}
