import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GrievanceRoutingModule } from './grievance-routing.module';
import { GrievanceComponent } from './grievance.component';
import { CoreModule } from '../core/core.module';
import { SubGrievanceComponent } from './sub-grievance/sub-grievance.component';
import { AgActionsComponent } from './helper';


@NgModule({
  declarations: [
    GrievanceComponent,
    SubGrievanceComponent,
    AgActionsComponent
  ],
  imports: [
    CommonModule,
    GrievanceRoutingModule,
    CoreModule.forRoot(),
  ],
  exports:[
    AgActionsComponent
  ]
})

export class GrievanceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GrievanceModule,
    };
  }
}
