import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SharedService } from '../../../core/services';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { ShiftgroupService } from '../shiftgroup.service';
import { Router, ActivatedRoute } from '@angular/router';
// import { SwalComponent } from '@toverux/ngx-sweetalert2';
// import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-create-shift-group',
  templateUrl: './create-shift-group.component.html',
  styleUrls: ['./create-shift-group.component.css']
})
export class CreateShiftGroupComponent implements OnInit {
  public wizardSteps: any;
  public checkList: any = [];
  plants = [];
  companyData = [];
  shifts = [];
  editShiftGroupCode: any;
  editshiftgroupData: any = {};
  // @ViewChild('dialogerror') public dialogerror: SwalComponent;
  // public alertOption: SweetAlertOptions = {};
  @ViewChild('shiftgroupForm') shiftgroupForm: NgForm;
  editMode: boolean;
  constructor(
    public sharedService: SharedService,
    private shiftGroupService: ShiftgroupService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private logHelperService: LogHelperService,
  ) {
    this.wizardSteps = [
      {
        id: 'shiftgroup',
        name: 'shift group',
        isValid: () => {
          return true;
        },
      }
    ];
  }

  ngOnInit() {
    setTimeout(() => {
      this.edit();
      this.setCheckList();
      // call here
    }, 10);
  }

  edit() {
    this.editShiftGroupCode = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.editShiftGroupCode) {
      this.editMode = true;
      this.shiftGroupService.getShiftGroupByID(this.editShiftGroupCode).subscribe((response: any) => {
        console.log('shiftgroup', response.Data);
        const shiftgroup = response.Data;
        if (!shiftgroup) {
          return;
        }
        this.editshiftgroupData = shiftgroup;
        // Step 1
        // shiftgroup
        console.log(shiftgroup.Company_Code);
        this.shiftgroupForm.controls['Company_Code'].setValue(shiftgroup.Company_Code);
        this.shiftgroupForm.controls['Plant_Code'].setValue(shiftgroup.Plant_Code);
        this.shiftgroupForm.controls['Shift_Code'].setValue(shiftgroup.Shift_Codes[0]);
        this.shiftgroupForm.controls['Name'].setValue(shiftgroup.Name);
        this.shiftgroupForm.controls['ShortName'].setValue(shiftgroup.ShortName);
        this.shiftgroupForm.controls['StartDate'].setValue(shiftgroup.StartDate);
        this.shiftgroupForm.controls['EndDate'].setValue(shiftgroup.EndDate);
        this.getCompanyData();
        this.getPlants();
        this.getShiftData();
      });
    } else {
      this.getPlants();
      this.getCompanyData();
      this.getShiftData();
    }
  }
  saveAndNew() {
    this.onSubmit(this.shiftgroupForm.value, true);
  }
  updateAndNew() {
    this.onSubmit(this.shiftgroupForm.value, true);
  }

  clear() {
    this.shiftgroupForm.reset();
  }

  getCompanyData() {
    this.shiftGroupService.getCompanyData().subscribe((data: []) => {
      this.companyData = data;
      this.shiftgroupForm.controls['Company_Code'].setValue(this.editshiftgroupData.Company_Code);

    });
  }
  getPlants() {
    this.shiftGroupService.getPlants().subscribe((data: any) => {
      this.plants = data;
      this.shiftgroupForm.controls['Plant_Code'].setValue(this.editshiftgroupData.Plant_Code);
    });
  }

  getShiftData() {
    this.shiftGroupService.getShifts().subscribe((data: any) => {
      console.log(data);
      this.shifts = data;
      this.shiftgroupForm.controls['Shift_Code'].setValue(this.editshiftgroupData.Shift_Codes[0]);
    });
  }


  onSubmit(formData: any, saveAndNew: boolean): void {
    // const shiftGroup: any = {
    //   Plan_Code: formData.Plant_Code,
    //   Shift_Code: formData.Shift_Code,
    //   Name: formData.Name,
    //   ShortName: formData.ShortName,
    //   StartDate: formData.StartDate,
    //   EndDate: formData.EndDate,
    //   Shift_Codes: [formData.Shift_Code]
    // };
    let shiftGroup: any;
    shiftGroup = { ...this.editshiftgroupData, ...formData };
    shiftGroup.Shift_Codes = [];
    shiftGroup.Shift_Codes.push(formData.Shift_Code);
    if (shiftGroup.ShiftGroup_Code) {
      this.shiftGroupService.updateShiftGroup(shiftGroup).subscribe((result: any) => {
        if (saveAndNew) {
          this.showShiftGroupInfo(false, false);
          this.resetFormAfterSave();
        } else {
          this.showShiftGroupInfo(false, true);

        }
      });
    } else {

      this.shiftGroupService.addShiftGroup(shiftGroup).subscribe((result: any) => {
        if (saveAndNew) {
          this.showShiftGroupInfo(true, true);
          this.resetFormAfterSave();
        } else {
          this.showShiftGroupInfo(true, true);
        }
      });
    }
  }

  private resetFormAfterSave() {
    const id = this.activatedRoute.snapshot.params.id;
    console.log('test', id);

    if (id) {
      setTimeout(() => {
        this.router.navigate(['/shift/create-shift-group/']);
      }, 500);
    }
  }

  //   this.shiftgroupForm.reset();
  //   this.setDefaultECPolicyObj();

  //  // this.setControlValue(false);

  // }

  // private setDefaultECPolicyObj() {
  //   // this.shiftGroup = {
  //   //   ParentContractorVendor_Code: null,
  //   //   ContractorVendor_Code: null,
  //   //   ParentWorkOrderCode: null,
  //   //   ChildWorkOrderCode: null,
  //   //   Plants: null,
  //   //   NatureOfWorkList: null,
  //   //   SatusMasterList_Code: null,
  //   //   DocumentImageMasterViews: []
  //   // };
  // }

  private showShiftGroupInfo(isAdd: boolean, redirect: boolean) {
    const message = isAdd ? 'Shift Group is successfully added.' : 'Shift Group is successfully updated.';
    this.logHelperService.logSuccess({
      message
    });

    if (redirect) {
      setTimeout(() => {
        this.router.navigate(['shift/shift-group']);
      }, 3000);
    }
  }

  private setCheckList() {
    this.checkList = [
      {
        id: 1,
        text: 'Shift group ',
        open: true,
        childrens: [
          {
            text: 'Plant  is required',
            title: 'Plant',
            desc: 'Plant is required.',
            isValid: () => {
              return (this.shiftgroupForm.controls.Plant_Code.valid);
            },
            hasError: () => {
              return ((this.shiftgroupForm.controls.Plant_Code.dirty || this.shiftgroupForm.controls.Plant_Code.touched)
                && this.shiftgroupForm.controls.Plant_Code.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          }, {
            text: 'Shift  is required',
            title: 'Shift',
            desc: 'Shift is required.',
            isValid: () => {
              return (this.shiftgroupForm.controls.Shift_Code.valid);
            },
            hasError: () => {
              return ((this.shiftgroupForm.controls.Shift_Code.dirty || this.shiftgroupForm.controls.Shift_Code.touched)
                && this.shiftgroupForm.controls.Shift_Code.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          }, {
            text: 'Name  is required',
            title: 'Name',
            desc: 'Name is required.',
            isValid: () => {
              return (this.shiftgroupForm.controls.Name.valid);
            },
            hasError: () => {
              return ((this.shiftgroupForm.controls.Name.dirty || this.shiftgroupForm.controls.Name.touched)
                && this.shiftgroupForm.controls.Name.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          }, {
            text: 'ShortName  is required',
            title: 'ShortName',
            desc: 'ShortName is required.',
            isValid: () => {
              return (this.shiftgroupForm.controls.ShortName.valid);
            },
            hasError: () => {
              return ((this.shiftgroupForm.controls.ShortName.dirty || this.shiftgroupForm.controls.ShortName.touched)
                && this.shiftgroupForm.controls.ShortName.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          }
        ]
      }
    ];
  }
}
