import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-select-api',
  templateUrl: './ag-select-api.component.html',
  styleUrls: ['./ag-select-api.component.css']
})
export class AgSelectApiComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  count = 0;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }
}
