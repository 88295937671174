import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractorsComponent } from './contractors/contractors.component';
import { WorkOrdersComponent } from './work-orders/work-orders.component';
import { EcPoliciesComponent } from './ec-policies/ec-policies.component';
import { LicencesComponent } from './licences/licences.component';
import { CreateSubContractorComponent } from './contractors';
import { WorkOrderFormWizardComponent } from './work-orders/work-order-form/work-order-form-wizard/work-order-form-wizard.component';
import { EcPoliciesFormComponent } from './ec-policies/ec-policies-form/ec-policies-form.component';
import { LicencesFormComponent } from './licences/licenses-form/licenses-form.component';
import { CreateContractorComponent } from './contractors/create-contractor/create-contractor.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {
    path: 'contractors',
    component: ContractorsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'work-orders',
    component: WorkOrdersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ec-policies',
    component: EcPoliciesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-ecpolicy',
    component: EcPoliciesFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-ecpolicy/:id',
    component: EcPoliciesFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'licences',
    component: LicencesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-licence',
    component: LicencesFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-licence/:id',
    component: LicencesFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-contractor',
    component: CreateContractorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-sub-contractor',
    component: CreateSubContractorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-sub-contractor/:id',
    component: CreateSubContractorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'work-order',
    component: WorkOrderFormWizardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-work-order/:id',
    component: WorkOrderFormWizardComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractorRoutingModule { }
