import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTabChangeEvent, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL, ROLECODE } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
let newdate: Date;
let newdate1: Date;
const today = new Date();
const today1 = (new Date(today)).toLocaleDateString();

@Component({
  selector: 'app-gate-pass-request-history',
  templateUrl: './gate-pass-request-history.component.html',
  styleUrls: ['./gate-pass-request-history.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class GatePassRequestHistoryComponent implements OnInit {
  @Input() resolveObject;
  @Input() requestType;
  @Input() Request_Code;
  //historyData: any = {};
  historyData: any[] = [];
  paginatedData: any[] = [];
  currentPage: number = 1;
  recordsPerPage: number = 10;
  totalPages: number = 0;
  userRoles = JSON.parse(localStorage.getItem('Role'));

  constructor(
    public sharedService: SharedService,
    public activeModal: NgbActiveModal,
    public sharedDataService: SharedDataService,
  ) { }

  // historyColumnDefs = [
  //   {
  //     headerName: 'Request_Code', field: 'Request_Code', width: 250,
  //     filterParams: { applyButton: true, clearButton: true }
  //   }
  //   // {
  //   //   headerName: 'Name', field: 'NameInIdentity', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true }
  //   // },
  //   // {
  //   //   headerName: 'Issue Date', field: 'IssueDate', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true },
  //   //   cellRenderer: (data) => {
  //   //     return data.data.ValidUptoDate ? moment(data.data.IssueDate).format('DD/MM/YYYY') : '-'
  //   //   }
  //   // },
  //   // {
  //   //   headerName: 'Validity Date', field: 'ValidUptoDate', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true,
  //   //   cellRenderer: (data) => {
  //   //     return data.data.ValidUptoDate ? moment(data.data.ValidUptoDate).format('DD/MM/YYYY') : '-'
  //   //   }
  //   // },
  //   // {
  //   //   headerName: 'Vendor Code', field: 'ContractorVendor_Code', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true }
  //   // },
  //   // {
  //   //   headerName: 'Contactor Name', field: 'Contractor Name', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true
  //   // },
  //   // {
  //   //   headerName: 'Order Number', field: 'PO/WO Number', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true }
  //   // },
  //   // {
  //   //   headerName: 'License No', field: 'ContractorLicense', width: 250,
  //   //   filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
  //   // },
  //   // {
  //   //   headerName: 'Wage Criteria', field: 'ESICWageCriteria', width: 250,
  //   //   suppressMenu: true
  //   // },
  //   // {
  //   //   headerName: 'EC Policy', field: 'EC Policy', width: 250,
  //   //   suppressMenu: true,
  //   // }
  // ];

  // gridOptions = {
  //   columnDefs: this.historyColumnDefs,
  //   getRowHeight: (params) => {
  //     return 39;
  //   },
  //   onGridReady: (params) => {
  //     params.api.sizeColumnsToFit();
  //   },
  //   onGridSizeChanged: (params) => {
  //     params.api.sizeColumnsToFit();
  //   },
  //   icons: {
  //     sortNone: '<i class="fa fa-sort"/>',
  //     sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
  //     sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
  //   },
  //   pagination: true,
  //   paginationPageSize: 10,
  //   headerHeight: 39
  // };
 
  ngOnInit() {
    this.historyData = this.resolveObject.historyData || [];

    const keyMapping: { [key: string]: string } = {
      "Action Date": "ActionDate",
      "Approved By": "ApprovedBy"
    };
    
    this.historyData = this.historyData.map(item => {
      return Object.keys(item).reduce((acc, key) => {
        const newKey = keyMapping[key] || key;
        acc[newKey] = item[key];
    
        if (newKey === "Status" && item[key] === "Pending") {
          acc[newKey] = "Approved";
        }
    
        return acc;
      }, {} as { [key: string]: any });
    });
    this.calculatePagination();
    // this.siteshiftGroupData();
  }

  calculatePagination() {
    this.totalPages = Math.ceil(this.historyData.length / this.recordsPerPage);
    this.updatePaginatedData();
  }

  updatePaginatedData() {
    const startIndex = (this.currentPage - 1) * this.recordsPerPage;
    const endIndex = startIndex + this.recordsPerPage;
    this.paginatedData = this.historyData.slice(startIndex, endIndex);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePaginatedData();
    }
  }

  stepclose(resData) {
    const data = {
      isCancel: !resData
    };
    this.activeModal.close(data);
  }


  shiftGroupData() {
    // return this.gatePassRequestsService.getShiftGroupData().subscribe((res: any) => {
    //   this.shiftGroup = res.Data.Table;
    // });
  }

  siteshiftGroupData() {
    // return this.gatePassRequestsService.getSiteShiftGroupData().subscribe((res: any) => {
    //   this.siteshiftGroup = res.Data.Table;
    //   if (this.siteshiftGroup.length === 1) {
    //     this.historyData.SiteShiftGroup_Code = this.siteshiftGroup[0].SiteShiftGroup_Code;
    //   }
    // });
  }


}
