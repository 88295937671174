import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewContainerRef,
  TemplateRef,
  ComponentFactoryResolver,
  ComponentRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  OwlDateTimeComponent,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
} from "ng-pick-datetime";
import {
  ReportFilterData,
  FilterTypeEnum,
  Contractor,
} from "src/app/attendance/attendance-approve/attendance-approve.model";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EntryForThePrsenter } from "../../entryfortheday/entryfortheday-presenter/entryfortheday.presenter";
import { SharedService } from "src/app/core/services";
import { isNullOrUndefined } from "util";
import { MY_MOMENT_FORMATS } from "src/app/core/core.module";
import { ContractorFilterComponent } from "src/app/entryforday/contractor-filter/contractor-filter.component";
import { SubContractorFilterComponent } from "src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
// import { ReportWageRegisterService } from "./rpt-wage-register.service";
// import { RptRowViewWageRegisterComponent } from "./rpt-row-view-wage-register/rpt-row-view-wage-register.component";
import { forkJoin } from "rxjs";
import { WageRegisterFormBService } from "./wage-register-form-b.service";
import { RowWageRegisterFormBComponent } from "./row-wage-register-form-b/row-wage-register-form-b.component";
import * as moment from "moment";

declare var $;

@Component({
  selector: "app-wage-register-from-b",
  templateUrl: "./wage-register-from-b.component.html",
  styleUrls: ["./wage-register-from-b.component.css"],
  viewProviders: [EntryForThePrsenter],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "en-IN" },
    WageRegisterFormBService
  ],
})
export class WageRegisterFromBComponent implements OnInit {
  @ViewChild("dialogerror") private dialogerror: SwalComponent;
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  public activeFilter: string;
  @ViewChild("endDate") public endDate: OwlDateTimeComponent<any>;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;

  public savedFilters: any[] = [];
  public groupByList: any[];
  public stateList: any[];
  public companyList: any[];
  public plantList: any[];
  public unitList: any[];
  public plantLit: any[];
  public departmentList: any[];
  public sectionList: any[];
  public companyNatureofWorkList: any[] = [];
  public shiftGroupTypeList: any[] = [];
  public shiftTypeList: any[] = [];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  public genderList: any[] = [];
  public skillList: any[] = [];
  public designationList: any[] = [];
  public qualificationList: any[] = [];
  public technicalQualificationList: any[] = [];
  public qualificationStreamList: any[] = [];
  public religionList: any[] = [];
  public castList: any[] = [];
  public jobTypeList: any[] = [];
  public labourNatureOfWorkList: any[] = [];
  public workMenTypeList: any[] = [];
  public contAndSubContList: any[] = [
    { contAndSubCont: 1, name: "Only Contractor" },
    { contAndSubCont: 2, name: "Only SubContractor" },
    { contAndSubCont: 3, name: "Both" },
  ];
  // it will store the ref. of container where attach the html pass by developer
  @ViewChild("contentRef", { read: ViewContainerRef })
  private contentRef: ViewContainerRef;

  @ViewChild("templateRef") templateRef: TemplateRef<any>;

  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;

  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;
  selectedContractor;
  selectedSubContractor;

  private filterType: FilterTypeEnum;
  public roleCode = JSON.parse(localStorage.getItem("Role"))[0].Role_Code;
  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;

  private contractorCode: number;
  private workOrderNo: number;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    // private entryfordayService: EntryfordayService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private entryForThePrsenter: EntryForThePrsenter,
    private wageRegisterFormBService: WageRegisterFormBService,
    public sharedService: SharedService
  ) {
    this.formGroup = this.entryForThePrsenter.buildForm();
    this.getLastMonthDate();
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();
    const userId = localStorage.getItem("UserID");
    this.formGroup.get("JSONText").get("contAndSubCont").setValue(3);
    const roleCode = JSON.parse(localStorage.getItem("Role"))[0].Role_Code;
    if (userId && roleCode === 119) {
      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  private getAllData() {
    this.setGroupByList();
    // this.getState();
    this.getCompany();
    this.getContractors();
  }
  getContractors() {
    //
    this.wageRegisterFormBService
      .getContractors()
      .subscribe((response: Contractor[]) => {
        this.contractors = response;
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.Contractors
        ) {
          this.formGroup
            .get("JSONText")
            .get("Contractors")
            .setValue(this.selectedFilterData.JSONText.Contractors);
          this.getWorkOrdersByContractorCode(
            this.selectedFilterData.JSONText.Contractors,
            this.selectedFilterData.JSONText.Plant_Code
          );
          // this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
          // this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
        }
      });
  }
  getContractorsSelectedByDefault(userId: any, roleCode: any) {
    this.wageRegisterFormBService
      .getContractorsbydefault(userId, roleCode)
      .subscribe((response: Contractor[]) => {
        this.contractors = response;
        this.selectedContractor =
          this.contractors && this.contractors.length
            ? this.contractors[0]
            : null;
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.Contractors
        ) {
          this.formGroup
            .get("JSONText")
            .get("Contractors")
            .setValue(this.selectedFilterData.JSONText.Contractors);
          this.getWorkOrdersByContractorCode(
            this.selectedFilterData.JSONText.Contractors,
            this.selectedFilterData.JSONText.plantcode
          );
          // this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
          // this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
        } else if (this.contractors.length === 1) {
          this.formGroup
            .get("JSONText")
            .get("Contractors")
            .setValue(this.contractors[0].ContractorVendor_Code);
          this.getWorkOrdersByContractorCode(
            this.selectedFilterData.JSONText.Contractors,
            this.selectedFilterData.JSONText.plantcode
          );
          // this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
          // this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
        }
      });
  }

  private getWorkOrdersByContractorCode( contractorCode: any, plantcode: any ): void {
    this.wageRegisterFormBService
      .getWorkOrdersByContractorCode(contractorCode, plantcode)
      .subscribe((response: any[]) => {
        this.contractorWorkOrders = response;
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.WorkOrder_Code
        ) {
          this.formGroup
            .get("JSONText")
            .get("WorkOrder_Code")
            .setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
          if (
            this.selectedFilterData &&
            this.selectedFilterData.JSONText.WorkOrder_Code
          ) {
            this.getSubContractors(
              this.selectedFilterData.JSONText.WorkOrder_Code
            );
          }
        } else if (this.contractorWorkOrders.length === 1) {
          this.formGroup
            .get("JSONText")
            .get("WorkOrder_Code")
            .setValue(this.contractorWorkOrders[0].WorkOrder_Code);
          this.getSubContractors(this.contractorWorkOrders[0].WorkOrder_Code);
        }
      });
  }

  private getSubWorkOrdersBySubContractorCode(
    contractorCode: any,
    plantcode: any
  ): void {
    this.wageRegisterFormBService
      .getWorkOrdersByContractorCode(contractorCode, plantcode)
      .subscribe((response: any[]) => {
        this.subContractorWorkOrders = response;
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.SubWorkOrder_Code
        ) {
          this.formGroup
            .get("JSONText")
            .get("SubWorkOrder_Code")
            .setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
        } else if (this.subContractorWorkOrders.length === 1) {
          this.formGroup
            .get("JSONText")
            .get("SubWorkOrder_Code")
            .setValue(this.subContractorWorkOrders[0].WorkOrder_Code);
        }
      });
  }

  private getSubContractors(workOrderNo: any = ""): void {
    this.wageRegisterFormBService
      .getContractors("", workOrderNo)
      .subscribe((response: any[]) => {
        this.subContractors = response;
        this.formGroup
          .get("JSONText")
          .get("SubContractors")
          .setValue(this.selectedFilterData.JSONText.SubContractors);
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.SubContractors
        ) {
          this.getSubWorkOrdersBySubContractorCode(
            this.selectedFilterData.JSONText.SubContractors,
            this.selectedFilterData.JSONText.plantcode
          );
          // this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
          // this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        } else if (this.subContractors.length === 1) {
          this.formGroup
            .get("JSONText")
            .get("SubContractors")
            .setValue(this.subContractors[0].ContractorVendor_Code);
          this.getSubWorkOrdersBySubContractorCode(
            this.subContractors[0].ContractorVendor_Code,
            this.selectedFilterData.JSONText.plantcode
          );
        }
      });
  }

  getCompany() {
    this.wageRegisterFormBService.getCompanies().subscribe((response: any[]) => {
        this.companyList = response;
        this.formGroup.get("JSONText").get("Company_Code").setValue(this.selectedFilterData.JSONText.Company_Code);
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.Company_Code
        ) {
          this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        } else if (this.companyList.length === 1) {
          this.formGroup.get("JSONText").get("Company_Code").disable();
          this.formGroup
            .get("JSONText")
            .get("Company_Code")
            .setValue(this.companyList[0].Company_Code);
          this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        }
      });
  }
  getState(plantCode: any) {
    this.wageRegisterFormBService
      .getStatesList(plantCode)
      .subscribe((response: any[]) => {
        this.stateList = response;
        if (this.stateList.length === 1) {
          this.formGroup
            .get("JSONText")
            .get("State_Code")
            .setValue(this.stateList[0].State_Code);
        }
        // this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
        // if (this.selectedFilterData && this.selectedFilterData.JSONText.State_Code) {
        //   this.getPlants(this.selectedFilterData.JSONText.State_Code);
        // }
      });
  }
  getPlants(companyCode: any): void {
    this.wageRegisterFormBService
      .getPlants(companyCode)
      .subscribe((response: any[]) => {
        this.plantList = response;
        if (
          this.selectedFilterData &&
          this.selectedFilterData.JSONText.Plant_Cdde
        ) {
          this.getState(this.selectedFilterData.JSONText.Plant_Cdde);
        } else if (this.plantList.length === 1) {
          this.formGroup.get("JSONText").get("Plant_Cdde").disable();
          this.formGroup
            .get("JSONText")
            .get("Plant_Cdde")
            .setValue(this.companyList[0].Plant_Cdde);
          this.getState(this.selectedFilterData.JSONText.Plant_Cdde);
        }
      });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: "1",
        label: "Contractor Wise",
      },
      // {
      //   value: '1',
      //   label: 'Contractor PO Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor and Department Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor, PO and Department Wise'
      // }
    ];
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get("JSONText").get("Company_Code").value;
    this.formGroup.get("JSONText").get("Plant_Code").setValue(null);
    this.selectedFilterData.JSONText.Plant_Code = null;
    if (companyCode) {
      this.getPlants(companyCode);
    }
  }
  onPlantChange() {
    const plantCode = this.formGroup.get("JSONText").get("Plant_Code").value;
    // this.formGroup.get('JSONText').get('State_Code').setValue(null);
    // this.selectedFilterData.JSONText.Plant_Code = null;
    if (plantCode) {
      this.getState(plantCode);
    }
  }

  onContractorChange($event) {
    this.selectedContractor = $event;
    this.formGroup.get("JSONText").get("WorkOrder_Code").setValue(null);
    this.formGroup.get("JSONText").get("License_Code").setValue(null);
    this.formGroup.get("JSONText").get("WCPolicy").setValue(null);
    this.formGroup.get("JSONText").get("WorkOrder_Code").setValue(null);
    this.formGroup.get("JSONText").get("SubContractors").setValue(null);
    this.formGroup.get("JSONText").get("SubWorkOrder_Code").setValue(null);
    this.formGroup.get("JSONText").get("SubLicense_Code").setValue(null);
    this.formGroup.get("JSONText").get("SubWCPolicy").setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    const contractorCode = this.formGroup.get("JSONText").get("Contractors").value;
    const plantcode = this.formGroup.get("JSONText").get("Plant_Code").value;
    if (contractorCode) {
      if (this.formGroup.get("JSONText").get("WorkOrder_Code").disabled) {
        this.formGroup.get("JSONText").get("WorkOrder_Code").enable();
      }
      if (this.formGroup.get("JSONText").get("License_Code").disabled) {
        this.formGroup.get("JSONText").get("License_Code").enable();
      }
      if (this.formGroup.get("JSONText").get("WCPolicy").disabled) {
        this.formGroup.get("JSONText").get("WCPolicy").enable();
      }
      if (this.formGroup.get("JSONText").get("SubContractors").disabled) {
        this.formGroup.get("JSONText").get("SubContractors").enable();
      }
      if (this.formGroup.get("JSONText").get("SubWorkOrder_Code").disabled) {
        this.formGroup.get("JSONText").get("SubWorkOrder_Code").enable();
      }
      if (this.formGroup.get("JSONText").get("SubLicense_Code").disabled) {
        this.formGroup.get("JSONText").get("SubLicense_Code").enable();
      }
      if (this.formGroup.get("JSONText").get("SubWCPolicy").disabled) {
        this.formGroup.get("JSONText").get("SubWCPolicy").enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      // this.getLicensesByContractorCode(contractorCode);
      // this.getWCPolicyByContractorCode(contractorCode);
    } else {
      this.formGroup.get("JSONText").get("WorkOrder_Code").disable();
      this.formGroup.get("JSONText").get("License_Code").disable();
      this.formGroup.get("JSONText").get("WCPolicy").disable();
      this.formGroup.get("JSONText").get("WorkOrder_Code").disable();
      this.formGroup.get("JSONText").get("SubContractors").disable();
      this.formGroup.get("JSONText").get("SubWorkOrder_Code").disable();
      this.formGroup.get("JSONText").get("SubLicense_Code").disable();
      this.formGroup.get("JSONText").get("SubWCPolicy").disable();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get("JSONText").get("WorkOrder_Code").value;
    this.formGroup.get("JSONText").get("SubContractors").setValue(null);
    this.formGroup.get("JSONText").get("SubWorkOrder_Code").setValue(null);
    this.formGroup.get("JSONText").get("SubWCPolicy").setValue(null);
    this.formGroup.get("JSONText").get("SubLicense_Code").setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
    }
  }

  public onSubContractorChange($event) {
    this.selectedSubContractor = $event;
    const subContractorCode = this.formGroup.get("JSONText").get("SubContractors").value;
    const plantcode = this.formGroup.get("JSONText").get("Plant_Code").value;
    this.formGroup.get("JSONText").get("SubWorkOrder_Code").setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.subContractorWorkOrders = [];
    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantcode);
      // this.getWCPolicyBySubContractorCode(subContractorCode);
      // this.getLicensesBySubContractorCode(subContractorCode);
    }
  }

  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();
    if (
      formValue.JSONText.dateRange &&
      formValue.JSONText.dateRange.length > 0
    ) {
      if (formValue.JSONText.State_Code > 0) {
        if (formValue.JSONText.Plant_Code > 0) {
          if (formValue.JSONText.Contractors > 0) {
            formValue.JSONText.Date = moment(
              formValue.JSONText.dateRange[0]
            ).format(this.sharedService.datePickerSettings.requestFormat);
            formValue.JSONText.EDate = moment(
              formValue.JSONText.dateRange[1]
            ).format(this.sharedService.datePickerSettings.requestFormat);

            if (
              formValue.JSONText.dateRange &&
              formValue.JSONText.dateRange.length > 0
            ) {
              formValue.JSONText.Date = moment(
                formValue.JSONText.dateRange[0]
              ).format(this.sharedService.datePickerSettings.requestFormat);
              formValue.JSONText.EDate = moment(
                formValue.JSONText.dateRange[1]
              ).format(this.sharedService.datePickerSettings.requestFormat);
            } else {
              formValue.Date = null;
              formValue.EDate = null;
            }

            let contCode = "";
            let ptCode = "";
            let subconcode = null;
            let workOrderCode = null;

            let subWorkOrderCode = null;

            if (formValue.JSONText.Contractors) {
              if ((this.roleCode = 119)) {
                const cc = formValue.JSONText.Contractors;
                contCode = cc.toString();
              } else {
                contCode = formValue.JSONText.Contractors;
              }
            } else {
              contCode = null;
            }
            if (formValue.JSONText.SubContractors) {
              subconcode = formValue.JSONText.SubContractors;
            }

            if (formValue.JSONText.Plant_Code) {
              ptCode = formValue.JSONText.Plant_Code;
            } else {
              ptCode = null;
            }
            if (formValue.JSONText.WorkOrder_Code) {
              workOrderCode = formValue.JSONText.WorkOrder_Code;
            } else {
              workOrderCode = null;
            }
            if (formValue.JSONText.subContractorWorkOrders) {
              subWorkOrderCode = formValue.JSONText.subContractorWorkOrders;
            } else {
              subWorkOrderCode = null;
            }

            const uid = localStorage.getItem("UserID");
            formValue.filterText = {
              StartDate: formValue.JSONText.Date,
              EndDate: formValue.JSONText.EDate,
              plant_Code: ptCode,
              contractors: contCode,
              onlyContorSubContOrBoth: null,
              workOrder_Code: null,
              license_Code: null,
              WCPolicy: null,
              ESIC: null,
              subContractors: null,
              subWorkOrder_Code: null,
              subLicense_Code: null,
              subWCPolicy: null,
              subESIC: null,
              UserId: uid,
            };
            const request = {
              FromDate: formValue.JSONText.Date,
              ToDate: formValue.JSONText.EDate,
              Contractor_Code: parseInt(contCode),
              IsContractorData: 3,
              Plant_Code: parseInt(ptCode),
              WorkOrder_Code: workOrderCode ? parseInt(workOrderCode) : 0,
              Sub_Contractor: null,
              SubWorkOrder_Code: null,
              RequireType: 1,
              ActionType: 1,
              UserId: uid,
            };
            const headerDetailReqObj = {
              Plant_Code: formValue.JSONText.Plant_Code,
              Contractor_Code: contCode,
            };
            const wageReportData$ =
              this.wageRegisterFormBService.getWageReportData(request);
            const headerData$ =
              this.wageRegisterFormBService.getWageReportHeaderDetails(
                headerDetailReqObj
              );
            forkJoin(wageReportData$, headerData$).subscribe(
              ([wageDataResponse, headerData]: [any, any]) => {
                this.openReportViewModal(
                  wageDataResponse.Table5,
                  (headerData && headerData[0]) || null
                );
              }
            );

            // this.reportWageRegisterService.getWageReportData(request).subscribe((response: any[]) => {
            //   console.log('Form D report', response);
            //   this.openReportViewModal(response);
            // });
          } else {
            this.logHelperService.logError({
              message: "Please Select Contractor",
            });
          }
        }
        // tslint:disable-next-line: brace-style
        else {
          this.logHelperService.logError({
            message: "Please Select plant",
          });
        }
      } else {
        this.logHelperService.logError({
          message: "Please Select State",
        });
      }
    }
    //   // tslint:disable-next-line: brace-style
    else {
      this.logHelperService.logError({
        message: "Please Select DateRange",
      });
    }
  }
  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {});
  }

  openReportViewModal(response: any[], headerData: any): void {
    const formValue = this.formGroup.getRawValue();
    // tslint:disable-next-line: indent
    if (
      formValue.JSONText.dateRange &&
      formValue.JSONText.dateRange.length > 0
    ) {
      formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(
        this.sharedService.datePickerSettings.requestFormat
      );
      formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(
        this.sharedService.datePickerSettings.requestFormat
      );
    }
    response.forEach((item: any) => {
      item.ContractorName = this.selectedContractor
        ? this.selectedContractor.Company
        : "";
      if (formValue.JSONText.SubContractors && this.selectedSubContractor) {
        // item.ContractorNameWithSubContractor = `${item.Contractor}[${formValue.JSONText.SubContractorName[0]}]`;
        item.ContractorNameWithSubContractor = `${this.selectedSubContractor.Company}[${item.Contractor}]`;
      } else {
        item.ContractorNameWithSubContractor = item.Contractor;
      }
    });
    const modalRef = this.modalService.open(RowWageRegisterFormBComponent, { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.selectedReportData = formValue.JSONText;
    modalRef.componentInstance.headerData = headerData;
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.stateList = this.stateList;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }

  private getLastMonthDate(): void {
    const currentDate = new Date();
    const previousMonthLastDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );
    const previousMonthFirstDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    this.formGroup
      .get("JSONText")
      .get("dateRange")
      .setValue([previousMonthFirstDate, previousMonthLastDate]);
  }

  public onFilterTab(filterObj: any): void {
    // console.log('filterObj', filterObj);
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  private clearData() {
    this.formGroup.get("FilterName").clearValidators();
    this.formGroup.get("FilterName").updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {},
    };
    this.manageVisibilityOfSteps();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ["", Validators.required],
    });
  }

  private openModal() {
    console.log("on save", this.selectedFilterData);
    this.formGroup
      .get("FilterName")
      .setValue(
        this.selectedFilterData ? this.selectedFilterData.FilterName : ""
      );
    this.modalRef = this.modalService.open(this.templateRef, { size: "sm" });
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: "Criteria",
        active: true,
      },
      {
        id: 2,
        name: "Company",
        active: false,
      },
      {
        id: 3,
        name: "Contractor",
        active: false,
      },
      {
        id: 4,
        name: "Sub-Contractor",
        active: false,
      },
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $("#content-container").children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css("display", "");
        item.active = true;
      } else {
        $(children[index]).css("display", "none");
        item.active = false;
      }
    });
  }

  bindCompanyTree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.PlantTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          id: val.Company_Code,
          companyCode: val.Company_Code,
          name: val.Name,
          currentname: "company",
          isActive: false,
          selected: false,
          children: this.bindPlantTree(subDataFilter, val.Company_Code, isEdit),
        });
      } else {
        convertedData.push({
          id: val.Company_Code,
          companyCode: val.Company_Code,
          name: val.Name,
          currentname: "company",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindPlantTree(filteredData, companyCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val: any, key) => {
      const subDataFilter = val.UnitTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          companyCode,
          id: val.Company_Code,
          plantCode: val.Plant_Code,
          name: val.Name,
          currentname: "plant",
          isActive: false,
          selected: false,
          children: this.bindUnitTree(
            subDataFilter,
            companyCode,
            val.Plant_Code,
            isEdit
          ),
        });
      } else {
        convertedData.push({
          companyCode,
          id: val.Company_Code,
          plantCode: val.Plant_Code,
          name: val.Name,
          currentname: "plant",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindUnitTree(filteredData, companyCode, plantCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.DepartmentTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          companyCode,
          plantCode,
          id: val.Unit_Code,
          unitCode: val.Unit_Code,
          name: val.Name,
          currentname: "unit",
          isActive: false,
          selected: false,
          children: this.bindDepartmentTree(
            subDataFilter,
            companyCode,
            plantCode,
            val.Unit_Code,
            isEdit
          ),
        });
      } else {
        convertedData.push({
          companyCode,
          plantCode,
          id: val.Unit_Code,
          unitCode: val.Unit_Code,
          name: val.Name,
          currentname: "unit",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindDepartmentTree(
    filteredData,
    companyCode,
    plantCode,
    unitCode,
    isEdit: boolean
  ) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          companyCode,
          plantCode,
          unitCode,
          id: val.Department_Code,
          sectionCode: val.Department_Code,
          name: val.Name,
          currentname: "department",
          isActive: false,
          selected: false,
          children: this.bindSection1Tree(subDataFilter, isEdit),
        });
      } else {
        convertedData.push({
          companyCode,
          plantCode,
          unitCode,
          id: val.Department_Code,
          sectionCode: val.Department_Code,
          name: val.Name,
          currentname: "department",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindSection1Tree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push({
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          name: val.Name,
          currentname: "section1",
          isActive: false,
          selected: false,
          children: this.bindSection2Tree(
            subDataFilter,
            val.SectionPlantRelationId,
            isEdit
          ),
        });
      } else {
        let isCheck = false;

        const sectionCodes = this.formGroup
          .get("JSONText")
          .get("Section_Code").value;
        if (sectionCodes) {
          if (
            sectionCodes.find((item) => item.id === val.SectionPlantRelationId)
          ) {
            isCheck = true;
            console.log("isCheck", isCheck);
          }
        }
        convertedData.push({
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          name: val.Name,
          currentname: "section1",
          isActive: false,
          selected: false,
          checked: isCheck,
        });
      }
    });
    return convertedData;
  }

  bindSection2Tree(filteredData, section1Code, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push({
          section1Code,
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          name: val.Name,
          currentname: "section2",
          isActive: false,
          selected: false,
          children: this.bindSectionTree(
            subDataFilter,
            section1Code,
            val.SectionPlantRelationId,
            isEdit
          ),
        });
      } else {
        let isCheck = false;

        const sectionCodes = this.formGroup
          .get("JSONText")
          .get("Section_Code").value;

        if (sectionCodes) {
          if (
            sectionCodes.find((item) => item.id === val.SectionPlantRelationId)
          ) {
            isCheck = true;
            console.log("isCheck", isCheck);
          }
        }
        convertedData.push({
          section1Code,
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          name: val.Name,
          currentname: "section2",
          isActive: false,
          selected: false,
          checked: isCheck,
        });
      }
    });
    return convertedData;
  }

  bindSectionTree(filteredData, section1Code, section2Code, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      let isCheck = false;
      const sectionCodes = this.formGroup
        .get("JSONText")
        .get("Section_Code").value;
      if (sectionCodes) {
        if (
          sectionCodes.find((item) => item.id === val.SectionPlantRelationId)
        ) {
          isCheck = true;
          console.log("isCheck", isCheck);
        }
      }
      convertedData.push({
        section1Code,
        section2Code,
        id: val.SectionPlantRelationId,
        sectionCode: val.SectionPlantRelationId,
        name: val.Name,
        currentname: "section3",
        isActive: false,
        selected: false,
        checked: isCheck,
      });
    });
    return convertedData;
  }
}
