import { AgActionCellRendererComponent } from "../../../../../core/components/grid-wrapper/helper/ag-action-cell-renderer/ag-action-cell-renderer.component";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { GatePassRequestsService } from "../../../gate-pass-requests.service";
import { AssociateEffectiveDateCellRendererComponent } from "../../../ag-helper/associate-resign-date-cell-renderer/associate-resign-date-cell-renderer.component";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { editButtonRenderer } from "../../../ag-helper/htmlElement/buttonElement.component";
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { SharedService } from "src/app/core/services";
import { GLOBAL } from "src/app/app.globals";

@Component({
  selector: "app-popup-workman-salary-master",
  templateUrl: "./popup-workman-salary-master.component.html",
  styleUrls: ["./popup-workman-salary-master.component.css"],
  host: {
    class: "h-100 overflow-hidden d-flex flex-column",
  },
})
export class PopupWorkmanSalaryMasterComponent implements OnInit {
  private pRowData: any;
  private wageDetails: any;
  isCeilingAmountDisabled: boolean = true;
  @Input() public set rowData(value: any) {
    if (value) {
      this.pRowData = value;
      this.setControlValues(value.data);
    }
  }
  @ViewChild(editButtonRenderer) editButtonRenderer;

  public get rowData(): any {
    return this.pRowData;
  }

  @Input() public set checkMinWage(value: any) {
    if (value) {
      this.wageDetails = value;
    }
  }
  public get checkMinWage(): any {
    return this.wageDetails;
  }

  public formGroup: FormGroup;
  public greaterThan: boolean[] = [];

  public get salaryDetails(): FormArray {
    return this.formGroup.get("salaryDetails") as FormArray;
  }
  constructor(
    public sharedService: SharedService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private _gatePassRequestsService: GatePassRequestsService,
    private logHelperService: LogHelperService
  ) {
    this.formGroup = this.fb.group({
      salaryDetails: this.fb.array([]),
      epfCalculation: [0],
      ceilingAmount: ['', [Validators.pattern(/^\d+$/)], null, [Validators.required, this.ceilingAmountValidator(15000)]],
      ceilingAmountDate: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (this.isCeilingAmountDisabled) {
      this.formGroup.get('ceilingAmount').disable();
    }
    this.salaryDetails.controls.forEach((group: FormGroup, index: number) => {
      group.valueChanges.subscribe((value) => {
        const minWageAmount = this.checkMinWage[index];
        if (value.Amount >= minWageAmount.Amount) {
          this.greaterThan[index] = false;
        } else {
          this.greaterThan[index] = true;
        }
      });
    });
  }

  ceilingAmountValidator(minValue: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value !== null && value < minValue) {
        return { minValue: { requiredValue: minValue, actualValue: value } };
      }
      return null;
    };
  }

  onDropdownChange(data): void {
    if (data == 1) {
      this.formGroup.get('ceilingAmount').enable();
      this.formGroup.get('ceilingAmount').setValue(15000);
      this.isCeilingAmountDisabled = false;

    } else if (data == 0) {
      this.formGroup.get('ceilingAmount').disable();
      this.formGroup.get('ceilingAmount').reset();
      this.isCeilingAmountDisabled = true;
    }
  }

  public onCheckboxChange(form: FormGroup): void {
    const isSelected = form.get("isSelected").value;
    if (isSelected) {
      form.get("Amount").enable();
      form.get("EffectiveDate").enable();
      form.get("Amount").setValidators([Validators.required]);
      form.get("EffectiveDate").setValidators([Validators.required]);
    } else {
      form.get("Amount").disable();
      form.get("EffectiveDate").disable();
      form.get("Amount").clearValidators();
      form.get("EffectiveDate").clearValidators();
    }
  }

  public checkValidation(form: FormGroup, controlName: string): boolean {
    if (!form || !form.get(controlName)) {
      return false;
    }
    const control = form.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }


  public checkValidationSuccess(form: FormGroup, controlName: string): boolean {
    if (form.valid || form.get(controlName).valid) return true;
    return false;
  }

  saveAllDataButton() {
    if (!this.formGroup.valid) {
      for (const formGroup of this.salaryDetails.controls) {
        formGroup.get("Amount").markAsDirty();
        formGroup.get("Amount").markAsTouched();
        formGroup.get("Amount").updateValueAndValidity();
        formGroup.get("EffectiveDate").markAsDirty();
        formGroup.get("EffectiveDate").markAsTouched();
        formGroup.get("EffectiveDate").updateValueAndValidity();
      }
      return;
    }

    const controlValues = this.salaryDetails.getRawValue();

    const selectedValues = controlValues.filter((item) => item.isSelected);

    if (!selectedValues.length) {
      this.logHelperService.logError({
        message: "Please select at least one item.",
      });
      return;
    }

    const data = this.rowData.editObj;
    let finalList = controlValues.map((res) => ({
      UEC: res.UEC,
      Component_Code: res.Component_Code,
      GatePassRequest_Code: data.GatePassRequest_Code,
      Plant_Code: data.Plant_Code,
      Contractor_Code: data.ContractoreVendor,
      Clinet_Code: null,
      EffectiveDate: res.EffectiveDate,
      Amount: res.Amount,
      UserId: GLOBAL.USER_ID,
      Salary_Code: res.HandEntryValueId || null,
    }));

    if (this.rowData.editObj) {
      const data = this.rowData.editObj;
      const ceilingAmountDate = this.formGroup.get('ceilingAmountDate').value || null;
      const amount = this.isCeilingAmountDisabled ? 0 : this.formGroup.get('ceilingAmount').value;

      const additionalItems = this.removedItems.map((item) => ({
        UEC: item.ULC,
        Component_Code: item.Component_Code,
        GatePassRequest_Code: data.GatePassRequest_Code,
        Plant_Code: data.Plant_Code,
        Contractor_Code: data.ContractoreVendor,
        Clinet_Code: null,
        EffectiveDate: ceilingAmountDate,
        Amount: item.Component_Code === 114 ? (this.isCeilingAmountDisabled ? 0 : 1) : amount,
        UserId: GLOBAL.USER_ID,
        Salary_Code: item.Salary_Code || null,
      }));
      finalList = finalList.concat(additionalItems);
    }

    this._gatePassRequestsService.insertUpdatePayrollHandentry(finalList).subscribe((res: any) => {
      this.logHelperService.logSuccess({
        message: "Data saved successfully.",
      });
      this.activeModal.dismiss();
    });
  }

  onFillAllDates(index: number): void {
    const selectedDate = this.salaryDetails.at(index).get('EffectiveDate').value;

    if (!selectedDate) {
      this.logHelperService.logError({
        message: 'Please select a date in the current row first.',
      });
      return;
    }

    for (let i = index + 1; i < this.salaryDetails.length; i++) {
      this.salaryDetails.at(i).patchValue({
        EffectiveDate: selectedDate,
      });
    }
    this.formGroup.patchValue({
      ceilingAmountDate: selectedDate,
    });
  }


  public getTotalAmount(): number {
    let total = this.salaryDetails.controls.map((group) => group.get("Amount").value || 0).reduce((sum, current) => sum + parseFloat(current), 0);

    // if (!this.isCeilingAmountDisabled) {
    //   const ceilingAmount = this.formGroup.get("ceilingAmount").value || 0;
    //   total += parseFloat(ceilingAmount);
    // }

    return total;
  }

  removedItems: any[] = []
  private setControlValues(list: any): void {
    console.log('Original list:', list);

    const removedItems = list.filter((item: any) =>
      item.Short_Code === "PF_CEILING" || item.Short_Code === "PF_CEILING_AMOUNT"
    );

    const remainingItems = list.filter((item: any) =>
      item.Short_Code !== "PF_CEILING" && item.Short_Code !== "PF_CEILING_AMOUNT"
    );

    const control = this.formGroup.get("salaryDetails") as FormArray;
    control.controls = [];

    for (const task of remainingItems) {
      const formGroup: any = this.buildForm(task);
      control.push(formGroup);
    }

    this.removedItems = removedItems;
  }


  private buildForm(data: any): FormGroup {
    const isExist = !!data.HandEntryValueId;
    return this.fb.group({
      isSelected: [{ value: !isExist, disabled: !isExist }],
      ShortName: [
        { value: data.ShortName, disabled: isExist },
        !isExist ? [Validators.required] : undefined,
      ],
      Amount: [
        { value: data.Amount, disabled: isExist }, !isExist ? [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)] : undefined,
      ],
      EffectiveDate: [
        { value: data.EffectiveDate, disabled: isExist },
        !isExist ? [Validators.required] : undefined,
      ],
      UEC: [data.GatePassNo],
      Component_Code: [data.Component_Code],
      HandEntryValueId: [data.HandEntryValueId || null],
    });
  }
}
