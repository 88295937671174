import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { GLOBAL } from './app.globals';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PolicyRoutingModule } from './policy/policy-routing.module';
import { CoffApprovalProcessRoutingModule } from './coff-approval-process/coff-approval-process-routing.module';
import { SampleTableComponent } from './sample/sample-table.component';
import { AuthGuard } from './auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    // redirectTo: GLOBAL.USER.ROLE_CODE === 119 ? 'contractor-dashboard' : 'dash-board-statstics',
    redirectTo: 'dash-board-statstics',
    pathMatch: 'full',
    canActivate: [AuthGuard]
    // component: LoginComponent
  },
  {
    path: 'sample/sample-table',
    component: SampleTableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management',
    loadChildren: './user-management/user-management.module#UserManagementModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar',
    loadChildren: './calendar/calendar.module#CalendarModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'labour',
    loadChildren: './labour/labour.module#LabourModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'contractor',
    loadChildren: './contractor/contractor.module#ContractorModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'communication',
    loadChildren: './communication/communication.module#CommunicationModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'grievance',
    loadChildren: './grievance/grievance.module#GrievanceModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'hrc',
    loadChildren: './hr-clearance/hr-clearance.module#HrClearanceModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'hrc-demo',
    loadChildren:
      './hr-clearance-demo/hr-clearance.module#HrClearanceDemoModule',
      canActivate: [AuthGuard]
  },
  {
    path: 'labour',
    loadChildren: './labour/labour.module#LabourModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: './report/report.module#ReportModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance',
    loadChildren: './attendance/attendance.module#AttendanceModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'budget',
    loadChildren: './yearly-budget/yearly-budget.module#YearlyBudgetModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'shift',
    loadChildren: './shift/shift.module#ShiftModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'man-power',
    // loadChildren: './module#ManPowerModule'
    loadChildren: './man-power/man-power.module#ManPowerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'company-commons-master',
    loadChildren:
      './company-commons-master/company-commons-master.module#CompanyCommonsMasterModule',
      canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    // loadChildren:
    //   './reports/daily-attendance/daily-attendance.module#DailyAttendanceReportsModule'
    loadChildren:
      './reports/reports.module#ReportsModule',
      canActivate: [AuthGuard]
  },
  {
    path: 'daily-attendance',
    loadChildren: './reports/daily-attendance/daily-attendance.module#DailyAttendanceReportsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'reference-control',
    loadChildren:
      './reference-control/reference-control.module#ReferenceControlModule',
      canActivate: [AuthGuard]
  },
  {
    path: 'man-power-yearly',
    loadChildren:
      './man-power-yearly/man-power-yearly.module#ManPowerYearlyModule',
      canActivate: [AuthGuard]
  },
  {
    path: 'master-module',
    loadChildren:
      './master-module/master-module.module#MasterModuleModule',
      canActivate: [AuthGuard]
  },
  {
    path: 'leave-module',
    loadChildren: './leave-module/leave.module#LeaveModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'visitor-management',
    loadChildren: './visitor-management/visitor-management.module#VisitorManagementModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'policy',
    loadChildren: './policy/policy.module#PolicyModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'coff-approval-process',
    loadChildren: './coff-approval-process/coff-approval-process.module#CoffApprovalProcessModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'syncfusion-pivot-table',
    loadChildren: './core/components/syncfusion-pivot-table/syncfusion-ej2.module#SyncfusionEj2Module',
    canActivate: [AuthGuard]
  },
  {
    path: 'payroll',
    loadChildren: './payroll/payroll.module#PayrollModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'global-setting',
    loadChildren: './global-setting/global-setting.module#GlobalSettingModule',
    canActivate: [AuthGuard]
  },
  {
    path:'not-found',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
