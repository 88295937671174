import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftRoutingModule } from './shift-routing.module';
import { ShiftgroupComponent } from './shiftgroup/shiftgroup.component';
import { ShiftgroupService } from './shiftgroup/shiftgroup.service';
import { CoreModule } from '../core/core.module';
import { CreateShiftGroupComponent } from './shiftgroup/create-shift-group/create-shift-group.component';
@NgModule({
  imports: [
    CommonModule,
    ShiftRoutingModule,
    CoreModule.forRoot()
  ],
  declarations: [ShiftgroupComponent,
    CreateShiftGroupComponent],
  providers: [
    ShiftgroupService
  ]
})
export class ShiftModule { }
