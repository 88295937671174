import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Overlay } from '@angular/cdk/overlay';
import { WorkOrderFormWizardComponent } from './work-order-form-wizard/work-order-form-wizard.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-work-order-form-modal',
  templateUrl: './work-order-form-modal.component.html',

})
export class WorkOrderFormModalComponent implements OnInit {


  constructor(private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.isValidType()) {
      setTimeout(() => {
        this.openModal();
      });
    } else {
      alert('Please provide valid type.');
    }
  }

  private isValidType(): boolean {
    const type: string = this.activatedRoute.snapshot.queryParamMap.get('type');
    return type === '1' || type === '2';
  }
  openModal() {
    const modalRef = this.modalService.open(WorkOrderFormWizardComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
}
