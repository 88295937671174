import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GLOBAL } from '../../app.globals';
import { UserManagementService } from '../user-management.service';
import { CheckboxCellComponent } from '../checkbox-cell/checkbox-cell.component';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';

@Component({
  selector: 'app-module-role-management',
  templateUrl: './module-role-management.component.html',
  styleUrls: ['./module-role-management.component.css']
})
export class ModuleRoleManagementComponent implements OnInit {

  gridAPI: GridApi;
  gridOptions: GridOptions;
  dataList = [];
  columnDefs = [];
  rowData: any;
  orignalData = [];
  pinnedTopRowData = [];
  moduleTable = [];
  roleTable = [];

  onCheckChange = (rowIndex, field) => {
  }

  onCheckAllChange = (params, rowIndex, field, event) => {
    const isChecked = event.currentTarget.checked;
    if (confirm(`Are you sure to ${isChecked ? 'select' : 'un-select'} all?`)) {
      const data = params.data[params.colDef.field];
      const model = {
        moduleId: data.moduleId,
        value: isChecked,
        relation: 'RoleModuleRelation',
      };

      this.userManagementService.updateAccessInformation(model).subscribe((res: any) => {
        if (res.Status) {
          console.log(res.Data.Message);
          // Select all manually
          this.dataList.forEach((item) => {
            item[params.colDef.field].value = isChecked;
          });
        }
        if (!res.Status) {
          event.currentTarget.checked = !isChecked;
        }
      }, (err) => {
      });

      // // Select all from service side
      // const response = {
      //   selectedRoleIds: '116',
      //   moduleId: 100,
      //   moduleName: 'Access & Rights '
      // };

      // // tslint:disable-next-line:prefer-const
      // const selectedRoleIds = response.selectedRoleIds ? response.selectedRoleIds.split(',').map(Number) : [];
      // this.dataList.forEach((item) => {
      //   const obj = item[response.moduleName];
      //   obj.value = selectedRoleIds.indexOf(obj.roleId) >= 0 ? true : false;
      // });
    } else {
      console.log('on Cancel');
      event.currentTarget.checked = !isChecked;
    }
  }

  constructor(
    private userManagementService: UserManagementService, private loadingService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.returndata();
  }

  returndata() {
    this.userManagementService.getModuleRoleList().subscribe((res: any) => {
      if (res.Status) {
        this.moduleTable = this.copy(res.Data.Table);
        this.roleTable = this.copy(res.Data.Table1);
        this.orignalData = res.Data.Table;
        this.updateAllRows(this.copy(this.moduleTable), this.copy(this.roleTable));
      }
    }, (error) => {
      this.loadingService.hide();
    });
  }

  updateAllRows(moduleData, roleData) {
    const columnDefs = [];
    const rowData = [];

    if (moduleData.length > 0) {
      moduleData.forEach((data) => {
        columnDefs.push({
          headerName: data.moduleName, field: data.moduleName,
          cellRendererFramework: CheckboxCellComponent, headerClass: 'text-center',
          width: 100, colId: data.moduleId,
          cellRendererParams: {
            onCheckChange: this.onCheckChange,
            onCheckAllChange: this.onCheckAllChange
          }
        });
      });
    }

    if (roleData.length > 0) {
      const checkAllRow = {
        role: 'Select all roles',
        isCheckAllCell: true,
      };

      moduleData.forEach((modules) => {
        checkAllRow[modules.moduleName] = {
          moduleId: modules.moduleId,
          value: false
        };
      });
      this.pinnedTopRowData.push(checkAllRow);

      roleData.forEach((data) => {
        const obj = { role: data.roleName };
        moduleData.forEach((modules) => {
          // tslint:disable-next-line:prefer-const
          let selectedRoleIds = modules.selectedRoleIds ? modules.selectedRoleIds.split(',').map(Number) : [];
          obj[modules.moduleName] = {
            moduleId: modules.moduleId,
            roleId: data.roleId,
            value: selectedRoleIds.indexOf(data.roleId) >= 0 ? true : false
          };
        });
        rowData.push(obj);
      });
    }
    setTimeout(() => {
      this.dataList = rowData;
      const dataCol = [
        {
          headerName: 'Role', field: 'role',
          sortable: false, filter: false, width: 100, pinned: true,
          headerClass: 'text-center'
        }, {
          headerName: 'Module', field: 'Module', headerClass: 'text-center',
          children: []
        }
      ];
      dataCol[1].children = columnDefs;
      this.columnDefs = dataCol;
      this.setGridOption();
    }, 500);
  }

  setGridOption() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      pinnedTopRowData: this.pinnedTopRowData,
      rowData: this.dataList,
      rowHeight: 32,
      headerHeight: 34,
      defaultColDef: {
        width: 100,
        resizable: true
      }, context: {
        componentParent: this
      }
    };
  }

  countSelectedPages(cell) {
    this.gridOptions.api.refreshCells();
  }

  onSave() {
    const data = [];
    this.gridOptions.api.forEachNode((rowNode) => {
      const selectedId = [];
      let selectedRole = '';
      Object.entries(rowNode.data).forEach(
        (valuePair: any) => {
          if (typeof (valuePair[1]) === 'object') {
            if (selectedRole === '') {
              selectedRole = valuePair[1].roleId;
            }
            if (valuePair[1].value) {
              selectedId.push(valuePair[1].moduleId);
            }
          }
        }
      );
      const objPageWise = {
        pageId: selectedRole,
        selectedModuleId: selectedId
      };
      data.push(objPageWise);
    });
    setTimeout(() => {
      this.userManagementService.setModuleRoleManagementParam(this.orignalData, this.columnDefs, data);
    }, 500);
  }

  copy(data) {
    return JSON.parse(JSON.stringify(data));
  }
}
