import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';
import { Subject } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ReportExportService } from '../../report-export.service';
import * as _ from 'lodash';
import { RowListGroupTimecardPlantwiseComponent } from '../row-list-group-timecard-plantwise/row-list-group-timecard-plantwise.component';
import { formatDate } from '@angular/common';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
@Component({
  selector: 'app-report-view-list-timecard-plantwise',
  templateUrl: './report-view-list-timecard-plantwise.component.html',
  styleUrls: ['./report-view-list-timecard-plantwise.component.css']
})
export class ReportViewListTimecardPlantwiseComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public tableRowData1: any[] = [];
  public myMap: any[] = [];
  public myMap1: any[] = [];
  public reportDate: string;
  public totalData: number;
  private selectedReportInfo: Date;
  public totalShifColspan = 0;
  cValue = new Date();
  myDate = formatDate(this.cValue, 'dd-MM-yyyy', 'en-US');
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  @ViewChild('tablePrint') public tablePrint: ElementRef<any>;
  @Input()
  public set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): Date {
    return this.selectedReportInfo;
  }


  @Input()
  public set reportData(value: any[]) {
    if (value) {

      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
      // this.rowData = value;
    }
  }
  public get reportData(): any[] {
    return this.reportValueData;
  }
  public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  columnDefs: any[] = [];
  rowData = [];
  groupingMeta = ['ContractorNameWithSubContractor', 'WorkmenCategory'];
  private reportValueData: any[];

  constructor(private sharedService: SharedService, private router: Router,
    private excelService: ReportExportService) {
    this.closeModal = new EventEmitter();
  }

  ngOnInit() {
    this.totalData = this.reportValueData.length;
    console.log('a', this.totalData);
    this.reportDate = this.dateConvertor(this.selectedReportInfo);
    console.log('b', this.reportDate);
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };
    this.createColumnDef();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public onPrint(): void {

    const data = {
      selectedReportData: this.selectedReportData,
      reportData: this.reportData
    };
    // this.myMap = this.getGroupRowTableData1(this.reportData);
    // console.log('	this.myMap', this.myMap);
    // this.myMap1 = Array.from(this.myMap.keys());
    this.tableRowData = this.getGroupRowTableData(this.reportData);
    console.log('	this.tableRowData', this.tableRowData);
    setTimeout(() => {
      this.btnPrint.nativeElement.click();
      localStorage.setItem('report-data', JSON.stringify(data));
      // this.router.navigate(['/reports/rpt-timecard-summary']);
    }, 100);
  }

  public exportExcel(): void {
    // this.tableRowData = this.getGroupRowTableData(this.reportData);
    this.excelService.exportAsExcelFile(this.reportData, 'LabourPerforamnceReport', this.myDate);
  }
  public exportToPdf(): void {

    // const data = {
    //   selectedReportData: this.selectedReportData,
    //   reportData: this.reportData
    // };
    const doc = new jsPDF();
    doc.autoTable({
      // const data :  this.selectedReportData,
      html: this.tablePrint.nativeElement,
      theme: 'grid',
      styles: { halign: 'center' },
      headStyles: {
        fillColor: '#cdeefa',
        textColor: '#444851',
        cellPadding: 1,
        valign: 'middle',
        fontSize: 7
      },
      willDrawCell: (data) => {
        console.log('data', data);
        const doc = data.doc;
        const rows = data.table.body;
        if (data.section === 'head') {
          if (data.row.index === 0) {
            doc.setFillColor('#FFFFFF');
            data.cell.styles.lineWidth = 0;
            data.cell.styles.valign = 'bottom';
          } else if (data.row.index === 1) {
            doc.setFillColor('#FFFFFF');
            data.cell.styles.halign = 'right';
            data.cell.styles.lineWidth = 0;
          } else {
            data.cell.styles.lineWidth = 1;
          }
        }
      },
      bodyStyles: {
        cellPadding: 1,
        fontSize: 7,
        valign: 'middle'
      },
    });

    doc.save('TimeCard.pdf');
  }



  private createColumnDef(): void {
    this.columnDefs = [
      {
        headerName: 'Sr. No', field: '', width: 40,
        sortable: false, suppressMenu: true, cellRenderer: this.rowIndexCellRenderer,
      },
      // {
      //   headerName: 'Contractor Name',
      //   field: 'ContractorNameWithSubContractor', width: 300, visible: false, filter: true,
      //   // pinned: 'left',

      // },
      {
        headerName: 'GatePassNo.',
        field: 'GatePassNo', width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
          // tslint:disable-next-line: ter-indent
        },
        
      },
      {
        headerName: 'Work Order',
        field: 'WorkOrder', width: 120, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRendererFramework: RowListGroupTimecardPlantwiseComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta
        },
      },
      {
        headerName: 'Date ', field: 'Date',
        width: 150, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Day ', field: 'Day',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'LOC ', field: 'Plant',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'License', field: 'License',
      //   width: 100, sortable: true, filter: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      // {
      //   headerName: 'ECPolicy', field: 'ECPolicy',
      //   width: 100, sortable: true, filter: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Unit ', field: 'Unit',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Department ', field: 'Department',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },

      {
        headerName: 'Shift', field: 'ShiftName',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'Shift Time', field: 'ShiftTime',
      //   width: 100, sortable: true, filter: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Time IN', field: 'InTime',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Time Out', field: 'OutTime',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Late In', field: 'LateComming',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Early Out', field: 'EarlyGoing',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'WHR', field: 'WHR',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Extra WHR', field: 'OT',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Status', field: 'AttendanceStatus',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
    ];
  }

  private rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }

  onEdit(params) {
    // this.editClick.emit(params);
  }

  onDelete(params) {
    // this.deleteClick.emit(params);
  }
  // getGroupRowTableData(reportData: any[]): any[] {
  //   const tableData = [];
  //
  //   const groupData1 = new Set(reportData.map(item => item.FullName));
  //   groupData1.forEach((groupItem: string) => {
  //     let index = 1;
  //     const items = reportData.filter(item => item.FullName === groupItem);
  //     items.forEach((item: any) => {
  //       item.index = index;
  //       index++;
  //     });

  //     const rowItems = {
  //       items,
  //       FullName: groupItem
  //     };
  //     tableData.push(rowItems);
  //   });
  //   console.log('groupDataFullName', groupData1);
  //   console.log('tableDataFullName', tableData);
  //   return tableData;
  // }

  // getGroupRowTableData1(reportData: any[]): any[] {
  // 	const tableData1 = [];
  // 	const tableMap1 = {
  // 		"Contact A": {
  // 			"Labour": {
  // 				"Test A": [{}, {}],
  // 				"Test B": [{}],
  // 				"Test C": [{}, {}],
  // 			}
  // 		}
  // 	};
  // 	const tableMap = {};
  // 	let contractorName = '';
  // 	reportData.forEach((data) => {
  // 		if (data.ContractorName === contractorName) {
  // 			let labourMap = tableMap[data.ContractorName];
  // 		} else {
  // 			tableMap[data.ContractorName] = {};
  // 		}
  // 	});
  // 	return tableData1;
  // }

  nestGroupBy = (seq, keys) => {
    // tslint:disable-next-line: curly
    if (!keys.length)
      return seq;
    // tslint:disable-next-line: no-var-keyword
    // tslint:disable-next-line: prefer-const
    let first = keys[0];
    const rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return this.nestGroupBy(value, rest);
    });
  }

  getGroupRowTableData1(reportData: any[]): any[] {
    const data = this.nestGroupBy(reportData, ['ContractorName', 'PlantIn', 'UnitInLocation', 'ShiftName', 'FullName']);
    console.log('data', data);
    const tableData1 = [];
    return data;
  }
  // getGroupRowTableData1(reportData: any[]): any[] {
  //   //  const data = this.nestGroupBy(reportData, ['ContractorName']);
  //   const data = this.nestGroupBy(reportData, ['ContractorName', 'PlantName', 'UnitInLocation', 'ShiftName']);

  //   console.log('data', data);
  //   const tableData1 = [];
  //   return data;
  // }
  getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    const index = 1;
    // reportData =  reportData.filter((item,index)=>index <= 10)
    const groupData = new Set(reportData.map(item => item.FullName));
    groupData.forEach((groupItem: string) => {
      const items = reportData.filter(item => item.FullName === groupItem);
      items.forEach((item: any) => {
        item.index = index;
        index++;
      });
      const rowItems = {
        // isTotalRow: true,
        LabourName: 'Total',
        Srn: '',
        totalPresentDays: 0,
        totalAbsents: 0,
        // totalWeekoffPresent: 0,
        totalLeaves: 0,
        totalODDays: 0,
        totalCODays: 0,
        totalHLDays: 0,
        // totalHPDays: 0,
        // totalLeavesDays: 0,
        totalTPDDays: 0,
        // totalLWPDays: 0,
        totalWeekoff: 0,
        totalMonthDays: 0,
        // totalFirstShift: 0,
        // totalGenShift: 0,
        // totalSecondShift: 0,
        // totalThirdShift: 0,
        // tslint:disable-next-line:object-shorthand-properties-first
        items,
        FullName: groupItem,
        ContractorName: items.length > 0 ? items[0].ContractorName : '',
        GatePassNo: items.length > 0 ? items[0].GatePassNo : '',
        PFUANNo: items.length > 0 ? items[0].PFUANNo : '',
        PFNo: items.length > 0 ? items[0].PFNo : '',
        ESICNo: items.length > 0 ? items[0].ESICNo : '',
        Gender: items.length > 0 ? items[0].Gender : '',
        Skill: items.length > 0 ? items[0].Skill : '',
        WorkmenCategory: items.length > 0 ? items[0].WorkmenCategory : '',
        Plant: items.length > 0 ? items[0].Plant : '',
        Designation: items.length > 0 ? items[0].Designation : '',
        MonthName: items.length > 0 ? items[0].MonthName : '',

      };

      let index = 1;


      // tslint:disable-next-line:variable-name
      items.forEach((FullName) => {

        // rowItems.totalPresentDays = rowItems.totalPresentDays + FullName.totalPresentDays;
        // rowItems.totalAbsents = rowItems.totalAbsents + FullName.totalAbsents;
        // rowItems.totalWeekoff = rowItems.totalWeekoff + FullName.totalWeekoff;
        // rowItems.totalODDays = rowItems.totalODDays + FullName.totalODDays;
        // rowItems.totalHLDays = rowItems.totalHLDays + FullName.totalHLDays;
        // rowItems.totalCODays = rowItems.totalCODays + FullName.totalCODays;
        // rowItems.totalTPDDays = rowItems.totalTPDDays + rowItems.totalTPDDays;
        // rowItems.totalLeaves = rowItems.totalLeaves + FullName.totalLeaves;
        // totalRow.totalMonthDays = totalRow.totalPresentDays + labour.totalMonthDays;	KH 06-01-2020
        // rowItems.totalMonthDays = rowItems.totalMonthDays + FullName.totalMonthDays; // 	KH 06-01-2020

        // if (count > maxRecordsPerPage) {
        //   newData.push(newContractor);
        //   count = 1;
        //   newContractor = {
        //     Contractor: contractor.Contractor,
        //     Contractor_Code: contractor.Contractor_Code,
        //     Contractor_Address: contractor.Contractor_Address,
        //     Company_Address: contractor.CompanyAdd,
        //     MonthName: contractor.MonthName,
        //     Company_Name: contractor.CompanyName,
        //     NatureOfWork: contractor.NatureOfWork,
        //     ObjData: []
        //   };
        // }

        if (FullName) {
          // FullName.forEach((attendance) => {
          if (FullName.Date) {
            const dateValue = parseInt(FullName.Date.substring(0, 2), 10);
            FullName.dateValue = dateValue;
            FullName[`A${dateValue}`] = this.getAttendanceStatus(FullName);
            if (FullName.AttendanceStatus === 'P' || FullName.AttendanceStatus === 'WP') {
              rowItems.totalPresentDays = rowItems.totalPresentDays + 1;
            } else if (FullName.AttendanceStatus === 'A') {
              rowItems.totalAbsents = rowItems.totalAbsents + 1;
            } else if (FullName.AttendanceStatus === 'OD') {
              rowItems.totalODDays = rowItems.totalODDays + 1;
            } else if (FullName.AttendanceStatus === 'CO') {
              rowItems.totalCODays = rowItems.totalCODays + 1;
            } else if (FullName.AttendanceStatus === 'PL'
              || FullName.AttendanceStatus === 'CL' || FullName.AttendanceStatus === 'SL') {
              rowItems.totalLeaves = rowItems.totalLeaves + 1;
            } else if (FullName.AttendanceStatus === 'H') {
              rowItems.totalHLDays = rowItems.totalHLDays + 1;
            } else if (FullName.AttendanceStatus === 'WO') {
              rowItems.totalWeekoff = rowItems.totalWeekoff + 1;
            } else if (FullName.AttendanceStatus === 'P' || FullName.AttendanceStatus === 'OD'
              || FullName.AttendanceStatus === 'PL' || FullName.AttendanceStatus === 'CO'
              || FullName.AttendanceStatus === 'CL' || FullName.AttendanceStatus === 'SL'
              || FullName.AttendanceStatus === 'H' || FullName.AttendanceStatus === 'WO'
              // tslint:disable-next-line:space-in-parens
              || FullName.AttendanceStatus === 'A' ) {
              rowItems.totalMonthDays = rowItems.totalPresentDays + rowItems.totalWeekoff + rowItems.totalAbsents;
            }
            rowItems.totalTPDDays = rowItems.totalPresentDays + rowItems.totalODDays +
              rowItems.totalCODays + rowItems.totalHLDays + rowItems.totalLeaves;

            rowItems.totalMonthDays = rowItems.totalTPDDays + rowItems.totalWeekoff + rowItems.totalAbsents;
          }
          // });
          FullName['Srn'] = index++;
        }

        // count = count + 1;
        //  newContractor.ObjData.push(labour);
      });

      tableData.push(rowItems);
    });
    return tableData;
    console.log('data', tableData);


  }

  // tslint:disable-next-line:variable-name
  getAttendanceStatus(FullName) {
    return FullName ? FullName.AttendanceStatus : '';
  }
  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    // tslint:disable-next-line: max-line-length
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} to ${date1.getDate()}-${months[date1.getMonth()]}-${date1.getFullYear()}`;
  }
}
