import { Component, OnInit, ViewChild, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../core/components/select-steps/select-steps-model/select-steps.model';
// tslint:disable-next-line: max-line-length
import { NgSelectConfig } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { ActivatedRoute } from '@angular/router';
import { VisitorManagementService } from '../visitor-management.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { GLOBAL } from 'src/app/app.globals';
// import { WebcamImage } from 'ngx-webcam/src/app/modules/webcam/domain/webcam-image';
import { Observable, Subject } from 'rxjs';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FaceRecognitionUIService } from 'src/app/face-recognition/faceRecognitionUI.service';
import { FileDoc } from 'src/app/core/components/file-upload/file-upload.component';
import { FileUploadService } from 'src/app/core/components/file-upload/file-upload.service';
import { SelectStepsComponent } from 'src/app/core/components/select-steps/select-steps.component';
import { VisitorFormModalComponent } from '../visitor-form-modal/visitor-form-modal.component';
let fileToReturn: File;
@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.css']
})
export class VisitorComponent implements OnInit {
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('tempRef1') public tempRef1: any;
  @ViewChild('tempRefApproval') public tempRefApproval: any;
  @ViewChild('uploadDocumentTemplateRef') public uploadDocumentTemplateRef: any;
  @ViewChild('takephoto') public takephoto: any;
  @ViewChild('registerface') public registerface: any;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') public canvas: ElementRef<any>;
  // public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  rowData: any;
  system:string="Visitory";
  label:string="Rakesh";
  private selectedFilterData: any = { JSONText: {} };
  /// public shiftMasterForm: FormGroup;
  visitorForm = new FormGroup({
    Company_Code: new FormControl(''),
    Plant_Code: new FormControl(''),
    Unit_Code: new FormControl(''),
    Department_Code: new FormControl(''),
    yourcompany: new FormControl(''),
    name: new FormControl(''),
    jobtitle: new FormControl(''),
    mobileno: new FormControl(''),
    pgender: new FormControl(''),
    emailid: new FormControl(''),
    otp: new FormControl(''),
    emergencycontactname: new FormControl(''),
    emergencycontactno: new FormControl(''),
    address: new FormControl(''),
    address1: new FormControl(''),
    zipcode: new FormControl(''),
    PCountry_Code: new FormControl(''),
    PState_Code: new FormControl(''),
    PDistrict_Code: new FormControl(''),
    PCity_Code: new FormControl(''),
    PPostOffice_Code: new FormControl(''),
    LVillage_Code: new FormControl(''),
    visitorcategoryasterList_Code: new FormControl(''),
    TypeOfIdentityMasterItem_Code: new FormControl(''),
    addharNo: new FormControl(''),
    drvingLicense: new FormControl(''),
    electionCard: new FormControl(''),
    breanginglaptop1: new FormControl(''),
    date: new FormControl(''),
    starttime: new FormControl(''),
    Enddate: new FormControl(''),
    endtime: new FormControl(''),
    actualdate: new FormControl(''),
    actualtime: new FormControl(''),
    temp: new FormControl(''),
    totannumber: new FormControl(''),
    lockernumber: new FormControl(''),
    visitorpurposeMasterList_Code: new FormControl(''),
    purposeofvisit1: new FormControl(''),
    mobileallowed: new FormControl(''),
    havematchbox: new FormControl(''),
    laptopallowed: new FormControl(''),
    havetoolkit: new FormControl(''),
    name1: new FormControl(''),
    identitytype: new FormControl(''),
    mobilenumber: new FormControl(''),
    pemailid: new FormControl(''),
    pjobtitle: new FormControl(''),
    Gender: new FormControl(''),
    ptemp: new FormControl(''),
    iteamtype: new FormControl(''),
    iteamdescription: new FormControl(''),
    serialno: new FormControl(''),
    isreturnable: new FormControl(''),
  });

  employeeDetailForm = new FormGroup({
    searchedKeyword: new FormControl(''),
  });
  approvalForm = new FormGroup({
    statusMasterItem_Code: new FormControl(''),
    acceptingorrejectingremark: new FormControl(''),
    instructiontoreception: new FormControl(''),
    allwedlaptop: new FormControl(''),
    allwedmobile: new FormControl(''),
  });
  docForm = new FormGroup({
  });
  takephotoForm = new FormGroup({
  });
  registerfaceForm = new FormGroup({
  });
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public modalRef: any;
  public activeref: any;
  public gridAPI: GridApi;
  public numberOfRowsPerPage = 10;
  pcountries = [];
  pStates = [];
  pDistricts = [];
  pCities = [];
  pPos = [];
  pVillages = [];
  lVillages = [];
  visitorcategory: any;
  visitorpurposetypeList: any;
  identityTypes: any;
  statusType: any;
  show = false;
  showdrivinglicence = false;
  showelectioncard = false;
  masterData:any = [];
  uploadedPhoto: FileDoc[] = [];
  gendertypes: any;
  iteamtypes: any;
  iteamsViews = [];
  hideUpdateiteams = true;
  edititeamsIndex = -1;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  savedCroppedImage: any = '';
  showCropper = false;
  // tslint:disable-next-line: variable-name
  OtherPersonDetailViews = [];
  hideUpdateOtherPerson = true;
  editipersonIndex = -1;
  documentTypes = [];
  photopath: any;
  photoname: any;
  selectedRowData: any[] = [];
  documents: any[] = [];
  uploadedFiles: FileDoc[] = [];
  public companyMasterData: any[] = [];
  public plantMasterData: any[] = [];
  public shiftGroupTypeData: any[] = [];
  public companyMasterModel: any;
  public plantMasterModel: any;
  public companyData: any[] = [];
  public units: any[] = [];
  public plants: any[] = [];
  public departmentData: any[] = [];
  trainedData: any[];
  editshiftCode1: string;
  editMode = false;
  teashow = false;
  shiftname: any;
  shortCode: any;
  shortname: any;
  allData: any;
  data: any;
  earlyallowedouthours = 0;
  isactive = true;
  issuedate = false;
  optionsChecked = [];
  visitorCode: any;
  // tslint:disable-next-line: variable-name
  employee_Code: any;
  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };
  constructor(private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private logHelperService: LogHelperService,
    private activatedRoute: ActivatedRoute,
    public sharedDataService: SharedDataService,
    private fileUploadService: FileUploadService,
    private modalService: NgbModal, private service: VisitorManagementService,
    private renderer: Renderer2,
    private faceRecognitionService: FaceRecognitionUIService) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
  }
  async ngOnInit() {

    this.getAllVisitor();
    // this.getCompanyData();
    // this.getPlants();
    // this.getUnits();
    // this.getDepartmentData();
    // this.getCountry();
    // this.startCamera();
    await this.service.getDocumentTypes().subscribe((res: any) => {
      this.documentTypes = res.Data;
    });

    setTimeout(() => {
      this.sharedDataService.getMasterData().subscribe((masterList: any) => {
        //
        console.log('master data', masterList);
        //masterList.Data.Table
        this.masterData = masterList.Data.Table;
  
        this.visitorcategory = this.masterData.filter(list => list.Parent_Code === 1065);
        this.visitorpurposetypeList = this.masterData.filter(list => list.Parent_Code === 1073);
        this.gendertypes = this.masterData.filter(list => list.Parent_Code === 302);
        this.iteamtypes = this.masterData.filter(list => list.Parent_Code === 1079);
        this.identityTypes = [
          { Id: 1, Name: 'Aadhar Card' },
          { Id: 2, Name: 'Driving License' },
          { Id: 3, Name: 'Election Card' }
        ];
        this.statusType = [
          { Id: 1, Name: 'Approved' },
          { Id: 2, Name: 'Reject' },
        ];
      });
    }, 3000);
    
  }
  getAllVisitor() {

    this.service.getAllVisitor().subscribe((res: any) => {
      this.data = res;
    });
  }
  startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
      alert('Sorry, camera not available.');
    }
  }
  // tslint:disable-next-line: member-ordering
  constraints = {
    video: {
      facingMode: 'environment',
      width: { ideal: 4096 },
      height: { ideal: 2160 }
    }
  };
  handleError(error) {
    console.log('Error: ', error);
  }
  attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  }
  capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', 100);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', 100);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  }
  // npm i ngx-webcam
  triggerSnapshot(): void {

    this.trigger.next();
  }
  // handleImage(webcamImage: WebcamImage): void {
  //   console.log('received webcam image', webcamImage);
  //   this.webcamImage = webcamImage;
  // }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  getCompanyData() {
    return this.service.getCompanyMasterData().subscribe((response: any) => {

      this.companyData = response;
      if (this.companyData.length === 1) {
        this.visitorForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);

      } else {
        this.companyData = response;
      }
    });
  }

  getPlants() {
    return this.service.getPlantMasterData().subscribe((response: any) => {
      this.plants = response;
      if (this.plants.length === 1) {
        this.visitorForm.controls['Plant_Code'].setValue(this.plants[0].Plant_Code);
      } else {
        this.plants = response;
      }
    });
  }

  getUnits() {

    return this.service.getUnitMasterData(this.visitorForm.controls['Plant_Code'].value || 100).subscribe((res: any) => {
      this.units = res.Data.Table;
      if (this.units.length === 1) {
        this.visitorForm.controls['Unit_Code'].setValue(this.units[0].Unit_Code);
      } else {
        this.units = res.Data.Table;
      }
    });
  }
  getCountry() {
    return this.service.getCountry().subscribe((response: any) => {
      this.pcountries = response;
    });
  }
  getDepartmentData() {

    // tslint:disable-next-line: max-line-length
    return this.service.getDepartmentData(this.visitorForm.controls['Unit_Code'].value || 100).subscribe((res: any) => {
      this.departmentData = res.Data.Table;
    });
  }

  getStatesByCountryIdP() {

    return this.service.getStatesByCountryId(this.visitorForm.controls['PCountry_Code'].value || 100)
      .subscribe((response: any) => {
        this.pStates = response;
      });
  }
  getDistrictsByStateP() {
    this.service.getDistrictsByState(this.visitorForm.controls['PState_Code'].value || 100).subscribe((response: any) => {
      this.pDistricts = response;
    });
  }

  getCitiesByDistrictP() {
    this.service.getCitiesByDistrict(this.visitorForm.controls['PDistrict_Code'].value || 100).subscribe((response: any) => {
      this.pCities = response;
    });
  }
  getPostOfficeByCityP() {
    this.service.getPOsByCity(this.visitorForm.controls['PCity_Code'].value || 100).subscribe((res: any) => {
      this.pPos = res;
    });
  }
  getVillageP() {
    this.service.getVillageByPostOffice(this.visitorForm.controls['PPostOffice_Code'].value || 100).subscribe((response: any) => {
      this.lVillages = response.Data.Table;
    });
  }

  changeIdentitytype() {
    if (this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].value === 1) {
      this.show = true;
      this.showdrivinglicence = false;
      this.showelectioncard = false;
    }
    if (this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].value === 2) {
      this.showdrivinglicence = true;
      this.show = false;
      this.showelectioncard = false;
    }
    if (this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].value === 3) {
      this.showelectioncard = true;
      this.showdrivinglicence = false;
      this.show = false;
    }
  }

  // tslint:disable-next-line: function-name
  AddIteams(index?: number) {

    if (this.visitorForm.controls.iteamtype.value != null) {
      if (typeof index !== 'undefined') {
        this.iteamsViews[index] = {
          ItemTypeMasterItem_Code: this.visitorForm.controls.iteamtype.value,
          ItemDescription: this.visitorForm.controls.iteamdescription.value,
          SerialNo: this.visitorForm.controls.serialno.value,
          IsReturnableIteam: this.visitorForm.controls.isreturnable.value,
        };
        this.visitorForm.controls['iteamtype'].reset();
        this.visitorForm.controls['iteamdescription'].reset();
        this.visitorForm.controls['serialno'].reset();
        this.visitorForm.controls['isreturnable'].reset();
      } else {
        this.iteamsViews.push({
          ItemTypeMasterItem_Code: this.visitorForm.controls.iteamtype.value,
          ItemDescription: this.visitorForm.controls.iteamdescription.value,
          SerialNo: this.visitorForm.controls.serialno.value,
          IsReturnableIteam: this.visitorForm.controls.isreturnable.value,
        });
        this.visitorForm.controls['iteamtype'].reset();
        this.visitorForm.controls['iteamdescription'].reset();
        this.visitorForm.controls['serialno'].reset();
        this.visitorForm.controls['isreturnable'].reset();
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Iteamtype First'
      });
    }
    return;

  }

  edititeams(index) {
    this.edititeamsIndex = index;
    this.hideUpdateiteams = false;
    this.visitorForm.controls['iteamtype'].setValue(this.iteamsViews[index].ItemTypeMasterItem_Code);
    this.visitorForm.controls['iteamdescription'].setValue(this.iteamsViews[index].ItemDescription);
    this.visitorForm.controls['serialno'].setValue(this.iteamsViews[index].SerialNo);
    this.visitorForm.controls['isreturnable'].setValue(this.iteamsViews[index].IsReturnableIteam);
  }

  updateiteams() {
    this.AddIteams(this.edititeamsIndex);
  }

  deleteiteams(index) {
    this.iteamsViews.splice(index, 1);
    this.hideUpdateiteams = true;
  }



  // tslint:disable-next-line: function-name
  AddOtherPersonDetail(index?: number) {

    // tslint:disable-next-line: max-line-length
    if (this.visitorForm.controls.name1.value != null && this.visitorForm.controls.Gender.value != null && this.visitorForm.controls.pjobtitle.value != null) {
      if (typeof index !== 'undefined') {
        this.OtherPersonDetailViews[index] = {
          Name: this.visitorForm.controls.name1.value,
          pjobtitle: this.visitorForm.controls.pjobtitle.value,
          mobilenumber: this.visitorForm.controls.mobilenumber.value,
          Gender: this.visitorForm.controls.Gender.value,
          ptemp: this.visitorForm.controls.ptemp.value,
        };
        this.visitorForm.controls['name1'].reset();
        this.visitorForm.controls['pjobtitle'].reset();
        this.visitorForm.controls['mobilenumber'].reset();
        this.visitorForm.controls['Gender'].reset();
        this.visitorForm.controls['ptemp'].reset();

      } else {
        this.OtherPersonDetailViews.push({
          Name: this.visitorForm.controls.name1.value,
          JobTitle: this.visitorForm.controls.pjobtitle.value,
          MobileNo: this.visitorForm.controls.mobilenumber.value,
          GenderMasterItem_Code: this.visitorForm.controls.Gender.value,
          Temperature: this.visitorForm.controls.ptemp.value,
          EmailID: this.visitorForm.controls.pemailid.value,
          IdentityType_Code: this.visitorForm.controls.identitytype.value,

        });
        this.visitorForm.controls['name1'].reset();
        this.visitorForm.controls['pjobtitle'].reset();
        this.visitorForm.controls['mobilenumber'].reset();
        this.visitorForm.controls['Gender'].reset();
        this.visitorForm.controls['ptemp'].reset();
        this.visitorForm.controls['pemailid'].reset();
        this.visitorForm.controls['identitytype'].reset();
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Name , JobTitle , Gender First'
      });
    }
    return;
  }

  editiperson(index) {
    this.editipersonIndex = index;
    this.hideUpdateOtherPerson = false;
    this.visitorForm.controls['name1'].setValue(this.OtherPersonDetailViews[index].Name);
    this.visitorForm.controls['pjobtitle'].setValue(this.OtherPersonDetailViews[index].JobTitle);
    this.visitorForm.controls['mobilenumber'].setValue(this.OtherPersonDetailViews[index].MobileNo);
    this.visitorForm.controls['Gender'].setValue(this.OtherPersonDetailViews[index].GenderMasterItem_Code);
    this.visitorForm.controls['ptemp'].setValue(this.OtherPersonDetailViews[index].Temperature);
    this.visitorForm.controls['pemailid'].setValue(this.OtherPersonDetailViews[index].EmailID);
    this.visitorForm.controls['identitytype'].setValue(this.OtherPersonDetailViews[index].IdentityType_Code);
  }

  updateiPersonDetail() {
    this.AddOtherPersonDetail(this.editipersonIndex);
  }

  deleteperson(index) {
    this.OtherPersonDetailViews.splice(index, 1);
    this.hideUpdateOtherPerson = true;
  }

  public onStartDateChange(event): void {
  }

  public onStartDateChange1(event): void {
  }


  public openModal(): void {

    this.clearData();
    this.activeref = this.modalService.open(VisitorFormModalComponent,
      { size: 'lg', backdrop: 'static' });
    this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].setValue(1);
    this.show = true;
  }
  // tslint:disable-next-line: function-name
  Host() {
    console.log('form data open modal', this.visitorForm.getRawValue());
    const value = this.visitorForm.getRawValue();
    if (value.Company_Code != null && value.Plant_Code != null && value.yourcompany != null
      && value.name != null && value.jobtitle != null && value.emailid != null
      && value.otp != null && value.emergencycontactname != null && value.emergencycontactno != null && value.address != null
      && value.zipcode != null && value.PCountry_Code != null && value.PState_Code != null && value.PDistrict_Code != null
      && value.date != null && value.starttime != null && value.Enddate != null && value.endtime != null
      && value.temp != null) {
      this.modalRef = this.modalService.open(this.tempRef1,
        { size: 'lg', backdrop: 'static' });
      this.employeeDetail();
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotory Fields First'
      });
    }
  }
  openApproval(rowData, $event) {

    this.clearData();
    this.modalRef = this.modalService.open(this.tempRefApproval,
      { size: 'lg', backdrop: 'static' });
    this.selectedRowData.push(rowData);
    console.log(this.selectedRowData);
    this.visitorCode = rowData.Visitor_Code;
    this.employee_Code = rowData.RequestRefCode;
    this.getDocuments(this.visitorCode);
  }
  getDocuments(visitorCode) {

    this.service.getDocumentForLabourVerify(visitorCode)
      .subscribe((verificationDoc: any) => {
        if (verificationDoc.Status) {
          this.documents = verificationDoc.Data;
        }
      });
  }
  // tslint:disable-next-line: function-name
  UploadDoc(): void {

    this.modalRef = this.modalService.open(this.uploadDocumentTemplateRef,
      { size: 'lg', backdrop: 'static', keyboard: false });
  }
  // tslint:disable-next-line: function-name
  TakePhoto(): void {

    this.modalRef = this.modalService.open(this.takephoto);
  }

  // tslint:disable-next-line: function-name
  RegisterFace(): void {
    this.modalRef = this.modalService.open(this.registerface);
  }

  // getTrainDataList() {
  //   this.faceRecognitionService.getAllTrainedData().subscribe((res: any) => {
  //     //
  //     this.trainedData = res.map((singleTrainData) => {
  //       return { ...singleTrainData, Descriptors: JSON.parse(singleTrainData.Descriptors) };
  //     });
  //     /// only send trainded data which has full 128 array of descriptors data
  //     this.trainedData = this.trainedData.filter(f => f.Descriptors.length === 128);
  //   });
  // }
  // saveCroppedImage(): void {
  //   this.savedCroppedImage = this.croppedImage;
  //   this.modalRef.close();
  // }
  employeeDetail() {

    return this.service.getEmployeeDetail().subscribe((res: any) => {
      this.allData = res;
    });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
  }
  public onvisitordetail(): void {
    this.clearData();
    this.activeref.close('click');
  }
  public onCloseAproval(): void {
    this.clearData();
    this.selectedRowData = [];
    this.modalRef.close('click');
  }
  private clearData() {
    this.visitorForm.reset();
    this.employeeDetailForm.reset();
    this.approvalForm.reset();

    this.teashow = false;
    this.show = false;
  }
  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
  }
  public companyMasterE(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
  }

  public plantMasterE(event): void {
    event.srcElement.blur();
    event.preventDefault();
  }

  // tslint:disable-next-line: function-name
  IsCheck(item, event) {

    this.allData[item] = event.target.checked;
    if (this.allData[item] === true) {
      if (this.optionsChecked.indexOf(item.Employee_Code) === -1) {
        this.optionsChecked.push(item.Employee_Code);

      } else {
        this.optionsChecked.splice(this.optionsChecked.indexOf(item.Employee_Code), 1);
      }
    } else {
      this.optionsChecked.splice(this.optionsChecked.indexOf(item.Employee_Code), 1);

    }
  }
  getTrainDataList() {
    this.faceRecognitionService.getAllTrainedData().subscribe((res: any) => {
      //
      this.trainedData = res.map((singleTrainData) => {
        return { ...singleTrainData, Descriptors: JSON.parse(singleTrainData.Descriptors) };
      });
      /// only send trainded data which has full 128 array of descriptors data
      this.trainedData = this.trainedData.filter(f => f.Descriptors.length === 128);
    });
  }
  getData(e) {
    const obj = {
      Reference_Code: 100,
      Page_Code: 424,
      Name: e._label,
      Descriptors: Array.from(e._descriptors[0]),
      System: e.system
    };
    this.faceRecognitionService.addFaceIdentityDetails(obj).subscribe((resp) => {
      this.getTrainDataList();
    });
  }
  // tslint:disable-next-line: function-name
  public SubmitRequest(): void {

    console.log('form data open modal', this.visitorForm.getRawValue());
    const value = this.visitorForm.getRawValue();
    const value1 = this.employeeDetailForm.getRawValue();
    //if (this.optionsChecked.length > 0 && this.optionsChecked.length <= 1) {
      const data: any = {
        Shift_Code: this.editshiftCode1,
        Company_Code: value.Company_Code,
        Plant_Code: value.Plant_Code,
        Unit_Code: value.Unit_Code,
        Department_Code: value.Department_Code,
        VisitorCompany: value.yourcompany,
        FullName: value.name,
        JobTitle: value.jobtitle,
        MobileNo: value.mobileno,
        GenderMAsterItem_Code: value.pgender,
        EmailID: value.emailid,
        OTP: value.otp,
        EmergancyContactName: value.emergencycontactname,
        EmergancyContactNo: value.emergencycontactno,
        Address1: value.address,
        Address2: value.address1,
        ZipCode: value.zipcode,
        Country_Code: value.PCountry_Code,
        State_Code: value.PState_Code,
        District_Code: value.PDistrict_Code,
        City_Code: value.PCity_Code,
        PostOffice_Code: value.PPostOffice_Code,
        Village_Code: value.LVillage_Code,
        VisitorCategory_Code: value.visitorcategoryasterList_Code,
        IDProofType_Code: value.TypeOfIdentityMasterItem_Code,
        AddharNo: value.addharNo,
        DrivingLicenceNo: value.drvingLicense,
        ElectionCardNo: value.electionCard,
        BringLaptop: value.breanginglaptop1,
        StartTime: value.starttime,
        StartDate: value.date,
        EndDate: value.Enddate,
        EndTime: value.endtime,
        ActualDate: value.actualdate,
        ActualTime: value.actualtime,
        Temperature: value.temp,
        TokenNumber: value.totannumber,
        LockerNumber: value.lockernumber,
        VisitorPurposeType_Code: value.visitorpurposeMasterList_Code,
        Visitorpurpose: value.purposeofvisit1,
        HaveMobile: value.mobileallowed,
        HaveToolkit: value.havetoolkit,
        HaveLaptop: value.laptopallowed,
        HaveMatchBox: value.havematchbox,
        CreatedBy: localStorage.getItem('UserID'),
        RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
        RequestRefCode: this.optionsChecked,
        OtherPersonDetailViews: this.OtherPersonDetailViews,
        iteamsViews: this.iteamsViews,
      };
      const uploadParams = {
        Visitor_Code: ''
      };
      if (this.photopath) {
        this.uploadedPhoto = this.photopath;
      }
      // if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.SendRequest(data).subscribe((response: any) => {
          uploadParams.Visitor_Code = response.Data;
          if (uploadParams.Visitor_Code) {
            this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.VISITOR_MANAGEMENT.UPLOAD_DOC)
              .subscribe((res) => {
                this.logHelperService.logSuccess({
                  message: 'Request Submitted successfully'
                });
                this.getAllVisitor();
                this.docForm.reset();
                this.clearData();
                this.modalRef.close();
                this.activeref.close();
                this.docForm.reset();
              });
          }
          if (uploadParams.Visitor_Code) {
            this.fileUploadService.uploadPhoto(this.uploadedPhoto, uploadParams, GLOBAL.APIS.VISITOR_MANAGEMENT.UPLOAD_VISITOR_PHOTO)
              .subscribe((res) => {
                this.logHelperService.logSuccess({
                  message: 'Request Submitted successfully'
                });
                this.getAllVisitor();
                this.docForm.reset();
                this.clearData();
                this.modalRef.close();
                this.activeref.close();
                this.docForm.reset();
              });
          }
          this.logHelperService.logSuccess({
            message: 'Request Submitted successfully'
          });
          this.getAllVisitor();
          this.clearData();
          this.modalRef.close();
          this.activeref.close();
          this.docForm.reset();
        });
      }
    // } else {
    //   this.logHelperService.logError({
    //     message: 'Please Select Only One Employee '
    //   });
    // }
  }
  // tslint:disable-next-line: function-name
  public ApproveRequest(): void {

    console.log('form data open modal', this.approvalForm.getRawValue());
    const value = this.approvalForm.getRawValue();
    const data: any = {
      StatusMasterList_Code: 1,
      allowedMobile: value.allwedmobile,
      AllowedLabptop: value.allwedlaptop,
      Remark: value.acceptingorrejectingremark,
      Instruction: value.instructiontoreception,
      Visitor_Code: this.visitorCode,
      Employee_Code: this.employee_Code,
      CreatedBy: localStorage.getItem('UserID'),
    };
    {
      this.service.ApproveRejectRequest(data).subscribe((response: any) => {
        if (response.Status) {
          this.logHelperService.logSuccess({
            message: 'Request Submitted successfully'
          });
          this.getAllVisitor();
          this.clearData();
          this.modalRef.close();
        }
      });
    }
  }
  // tslint:disable-next-line: function-name
  public RejectRequest(): void {

    console.log('form data open modal', this.approvalForm.getRawValue());
    const value = this.approvalForm.getRawValue();
    const data: any = {
      StatusMasterList_Code: 2,
      allowedMobile: value.allwedmobile,
      AllowedLabptop: value.allwedlaptop,
      Remark: value.acceptingorrejectingremark,
      Instruction: value.instructiontoreception,
      Visitor_Code: this.visitorCode,
      Employee_Code: this.employee_Code,
      CreatedBy: localStorage.getItem('UserID'),
    };
    {
      this.service.ApproveRejectRequest(data).subscribe((response: any) => {
        if (response.Status) {
          this.logHelperService.logSuccess({
            message: 'Request Submitted successfully'
          });
          this.getAllVisitor();
          this.clearData();
          this.modalRef.close();
        }
      });
    }
  }
  // tslint:disable-next-line: function-name
  public HoldRequest(): void {

    console.log('form data open modal', this.approvalForm.getRawValue());
    const value = this.approvalForm.getRawValue();
    const data: any = {
      StatusMasterList_Code: 3,
      allowedMobile: value.allwedmobile,
      AllowedLabptop: value.allwedlaptop,
      Remark: value.acceptingorrejectingremark,
      Instruction: value.instructiontoreception,
      Visitor_Code: this.visitorCode,
      Employee_Code: this.employee_Code,
      CreatedBy: localStorage.getItem('UserID'),
    };
    {
      this.service.ApproveRejectRequest(data).subscribe((response: any) => {
        if (response.Status) {
          this.logHelperService.logSuccess({
            message: 'Request Submitted successfully'
          });
          this.getAllVisitor();
          this.clearData();
          this.modalRef.close();
        }
      });
    }
  }

  onChangeStartEndTime($event) {
    const startTime = this.visitorForm.get('starttime').value;
    const endTime = this.visitorForm.get('endtime').value;

    if (startTime && endTime) {
      const totalShiftTime = this.calculateDifferenceTime(startTime, endTime);
      this.visitorForm.get('actualtime').setValue(totalShiftTime);
    }
  }

  private calculateDifferenceTime(startTime, endTime): any {
    try {
      const date1 = new Date(`2020-01-01 ${startTime}`).getTime();
      const date2 = new Date(`2020-01-01 ${endTime}`).getTime();
      const msec = date2 - date1;
      let mins = Math.floor(msec / 60000);
      let hrs = Math.floor(mins / 60);
      mins = mins % 60;
      hrs = hrs % 24;
      const hrsStr = hrs > 9 ? hrs : `0${hrs}`;
      const minsStr = mins > 9 ? mins : `0${mins}`;
      return `${hrsStr}:${minsStr}`;
    } catch {
      return null;
    }
  }
  onChangeteaTime($event) {
    const startTime4 = this.visitorForm.get('teastartendtime').value;
    const endTime4 = this.visitorForm.get('teaendtime').value;

    if (startTime4 && endTime4) {
      const teaTiime = this.calculateDifferenceTime2(startTime4, endTime4);
      this.visitorForm.get('teatime').setValue(teaTiime);
    }
  }

  private calculateDifferenceTime2(startTime4, endTime4): any {
    try {
      const date5 = new Date(`2020-01-01 ${startTime4}`).getTime();
      const date6 = new Date(`2020-01-01 ${endTime4}`).getTime();
      const msec = date6 - date5;
      let mins = Math.floor(msec / 60000);
      let hrs = Math.floor(mins / 60);
      mins = mins % 60;
      hrs = hrs % 24;
      const hrsStr = hrs > 9 ? hrs : `0${hrs}`;
      const minsStr = mins > 9 ? mins : `0${mins}`;
      return `${hrsStr}:${minsStr}`;
    } catch {
      return null;
    }
  }

  onChangeLunchStartEndTime($event) {
    const startTime1 = this.visitorForm.get('lunchstarttime').value;
    const endTime1 = this.visitorForm.get('lunchendtime').value;

    if (startTime1 && endTime1) {
      const lunchTime = this.calculateDifferenceTime1(startTime1, endTime1);
      this.visitorForm.get('lunchtime').setValue(lunchTime);
    }
  }

  private calculateDifferenceTime1(startTime1, endTime1): any {
    try {
      const date3 = new Date(`2020-01-01 ${startTime1}`).getTime();
      const date4 = new Date(`2020-01-01 ${endTime1}`).getTime();
      const msec = date4 - date3;
      let mins = Math.floor(msec / 60000);
      let hrs = Math.floor(mins / 60);
      mins = mins % 60;
      hrs = hrs % 24;
      const hrsStr = hrs > 9 ? hrs : `0${hrs}`;
      const minsStr = mins > 9 ? mins : `0${mins}`;
      return `${hrsStr}:${minsStr}`;
    } catch {
      return null;
    }
  }
  generateOTP() {
    const digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
    alert(OTP);
  }
  saveCroppedImage(): void {
    this.savedCroppedImage = this.croppedImage;
    this.modalRef.close();
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded', this.showCropper);
  }
  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  rotateLeft(imageCropper: ImageCropperComponent) {
    imageCropper.rotateLeft();
  }
  rotateRight(imageCropper: ImageCropperComponent) {
    imageCropper.rotateRight();
  }
  flipHorizontal(imageCropper: ImageCropperComponent) {
    imageCropper.flipHorizontal();
  }
  flipVertical(imageCropper: ImageCropperComponent) {
    imageCropper.flipVertical();
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    // console.log(this.imageChangedEvent);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log('Event', event);

    fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    );
    this.photopath = fileToReturn;
    this.photoname = this.imageChangedEvent.target.files[0].name;
    console.log('this.photopath', this.photopath);
    console.log('this.photoname', this.photoname);

    console.log('File Details', this.imageChangedEvent.target.files[0]);
    console.log('Return File', fileToReturn);
    return fileToReturn;
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
