import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from 'src/app/core/services';

@Component({
  selector: 'app-associate-resign-date-cell-renderer',
  templateUrl: './associate-resign-date-cell-renderer.component.html',
  styleUrls: ['./associate-resign-date-cell-renderer.component.css']
})
export class AssociateResignDateCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  isValid = true;
  invalidMsg = '';
  inputClass = 'cellInput';
  width: 90;
  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.params.data.resignDate = new Date();
  }

  agInit(params) {
    // debugger;
    this.params = params;
    this.width = this.params.width ? this.params.width : 90;
    this.params.data.resignDate = new Date();
    this.validateColumn();
  }

  refresh() {
    return true;
  }

  onValueChange($event) {
    this.params.data[this.params.column.colId] = $event.target.value;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection($event.target.value);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }

    this.params.onInputTextChange(this.params.rowIndex, this.params.column.colId);
  }

  valueChanges(){ 
    // debugger; 
  }
  validateColumn() {

    // const obj = this.params.isColValid(this.params.rowIndex, this.params.column.colId);
    // this.isValid = obj.isValid;
    // this.inputClass = 'cellInput';
    // this.invalidMsg = '';
    // if (obj.requireValidation) {
    //   this.inputClass += ' require-validation-cell-input';
    //   this.invalidMsg = obj.msg;
    // } else if (!obj.isValid) {
    //   this.inputClass += ' invalid-cell-input';
    //   this.invalidMsg = obj.msg;
    // }
  }

  // isDisable() {
  //   if (this.params.isDisable) {
  //     return this.params.isDisable(this.params);
  //   }
  //   return false;
  // }
}
