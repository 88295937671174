import { Component, OnInit } from '@angular/core';
import {
  ManPowerModel, monthList, plantList, siteList, glList, costCenterList,
  yearTypeList, departmentList, sectionList, manPowerTypeList
} from './man-power-yearly.modal';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SharedService } from '../core/services/shared.service';

@Component({
  selector: 'app-man-power',
  templateUrl: './man-power-yearly.component.html',
  styleUrls: ['./man-power-yearly.component.css']
})
export class ManPowerComponent implements OnInit {
  siteList = siteList;
  plantList = plantList;
  glList = glList;
  manPowerTypeList = manPowerTypeList;

  costCenterList = costCenterList;
  selectedPlant: 0;

  yearTypeList = yearTypeList;

  yearList = [
    '2019-2020',
    '2018-2019',
    '2017-2018',
  ];
  isOpenSectionEntry = false;
  unitList = [];
  sectionList = sectionList;
  monthList = monthList;
  monthObjectList = [];
  departmentList = departmentList;
  manPowerObject: ManPowerModel = new ManPowerModel();
  todayDate = new Date();
  showUnitWiseBlock: boolean;
  constructor(public sharedService: SharedService) { }

  ngOnInit() {
  }

  onPlantChange() {
    this.manPowerObject.plant = this.plantList[this.selectedPlant];
    this.unitList = this.manPowerObject.plant.UnitList;
  }

  updateUnitBudgetAmt(unitWiseDataIndex) {
    this.manPowerObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      element.Amount = (Number(this.manPowerObject.plant.UnitList[unitWiseDataIndex].BudgetAmount) / 12).toFixed(2);
      return element;
    });
  }

  updateUnitWiseAmount(amountIndex, unitWiseDataIndex) {
    let remainingTotalAmt = 0;
    this.manPowerObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index <= amountIndex) {
        remainingTotalAmt += Number(element.Amount);
      }
    });

    const remainingIndex = 12 - (amountIndex + 1);
    const remainingAmt = Number((this.manPowerObject.plant.UnitList[unitWiseDataIndex].BudgetAmount - remainingTotalAmt)
      / remainingIndex).toFixed(2);
    this.manPowerObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index > amountIndex) {
        element.Amount = remainingAmt;
      }
      return element;
    });
  }

  addDate(section) {
    const lastDate = section.Date[section.Date.length - 1];
    const ndate = moment(lastDate, 'DD-MM-YYYY').add(1, 'days');
    section.Date.push(ndate.format('DD/MM/YY'));
    for (let i = 0; i <= section.shiftList.length - 1; i++) {
      section[section.shiftList[i]].push(0);
    }

  }
  dateTimeChange() {
    for (let i = 0; i <= this.sectionList.length - 1; i++) {
      this.sectionList[i].month = _.cloneDeep(this.monthList);
      for (let y = 0; y <= this.sectionList[i].shiftList.length - 1; y++) {
        this.sectionList[i][this.sectionList[i].shiftList[y]] = [];
        for (let z = 0; z <= this.monthList.length - 1; z++) {
          this.sectionList[i][this.sectionList[i].shiftList[y]].push(_.cloneDeep(this.manPowerObject.budgetedManpower));
        }
      }
    }

  }

  replaceShiftValues(value, shift, valueIndex, sectionIndex) {
    for (let i = valueIndex; i <= this.sectionList[sectionIndex][shift].length - 1; i++) {
      this.sectionList[sectionIndex][shift][i] = value;
    }
  }

  showSectionEntry() {
    this.dateTimeChange();
    this.isOpenSectionEntry = true;
  }
}

