import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-cell-renderer',
  templateUrl: './ag-action-cell-renderer.component.html',
  styleUrls: ['./ag-action-cell-renderer.component.css']
})
export class AgActionCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public isEdit = true;
  public isDelete = true;
  public isUpdate = false;
  public isRightArrow = false;
  public isPrint = false;
  public isEmail = false;
  public isPasswordReset = false;
  public isInfoShow = false;
  public isDetailsShow = false;
  public isuploadPhoto = false;

  constructor() { }

  agInit(params: any): void {
    if (!params.data.parent) {
      this.params = params;
    }
    if (params.isEdit === false) {
      this.isEdit = false;
    }
    if (params.isDelete === false) {
      this.isDelete = false;
    }
    this.isUpdate = params.isUpdate;
    this.isRightArrow = params.isRightArrow;
    this.isPrint = params.isPrintButton;
    this.isEmail = params.isEmail;
    this.isPasswordReset = params.isPasswordReset;
    this.isInfoShow = params.isInfoShow;
    this.isDetailsShow = params.isDetailsShow;
    this.isuploadPhoto = params.isUploadPhoto;
  }

  refresh(): boolean {
    return false;
  }

  onEdit() {
    this.params.context.componentParent.onEdit(this.params.data);
  }
  onPrint() {
    this.params.context.componentParent.onPrint(this.params.data);
  }
  onEmail() {
    this.params.context.componentParent.onEmail(this.params.data);
  }
  onView() {
    this.params.context.componentParent.onView(this.params.data);
  }
  onDelete() {
    this.params.context.componentParent.onDelete(this.params.data, null, this.params.isParent);
  }

  onUpdate() {
    this.params.context.componentParent.onUpdate(this.params.data, null, this.params.isParent);
  }

  onRightArrowClick() {
    this.params.context.componentParent.onRightArrowClick(this.params.data, null, this.params.isParent);
  }

  resetPassword() {
    this.params.context.componentParent.resetPassword(this.params.data);
  }

  modelOpen() {
    this.params.context.componentParent.modelOpen(this.params.data);
  }
  
  onUploadPhoto() {
    this.params.context.componentParent.onUploadPhoto(this.params.data);
  }
}
