import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services';

@Component({
  selector: 'app-ag-filter-man-power-assign-allocation',
  templateUrl: './ag-filter-man-power-assign-allocation.component.html',
  styleUrls: ['./ag-filter-man-power-assign-allocation.component.css']
})
export class AgFilterManPowerAssignAllocationComponent implements OnInit {

  public selectAll: Boolean = true;
  public color: string;
  public index: number;
  public controlName: any;
  public saveUsername: boolean;
  constructor(private shared: SharedService) {
    this.saveUsername = false;
    this.shared.subjectCheckBox.subscribe((value: any) => {
      console.log(this.controlName);
      if (this.controlName && this.controlName !== value) {
        this.controlName = null;
      }
    });
  }
  count = 0;

  ngOnInit() {
  }

  agInit(params: any) {

    this.index = params.rowIndex;
    if (params.data.IsActive) {
      this.color = 'green';
    } else {
      this.color = 'red';
    }
    this.count = params.data[params.colDef.field];
    // this.count =  this.index;
  }

  refresh(): boolean {
    return false;
  }


  toggleSelectAll(params) {
  }

  public clickCheckBox(value: any) {
  }

  public checkBox(event: string, value: any) {
    if (this.controlName) {
      this.controlName = null;
    } else {
      this.controlName = value;
      this.shared.subjectCheckBox.next(value);
    }
  }

  public noCheckBox(event: string) {

  }

}
