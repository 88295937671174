import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyRoutingModule } from './policy-routing.module';
import { LatecomingPolicyComponent } from './list-of-policy/latecoming-policy/latecoming-policy.component';
import { CoreModule } from '../core/core.module';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { ListOfPolicyComponent } from './list-of-policy/list-of-policy.component';
import { ListOfRowPolicyComponent } from './list-of-policy/list-of-row-policy/list-of-row-policy.component';
import { HalfdayPolicyComponent } from './list-of-policy/halfday-policy/halfday-policy.component';
import { EarlyInOutPolicyComponent } from './list-of-policy/policy-tab/early-in-out-policy/early-in-out-policy.component';
import { LateInOutPolicyComponent } from './list-of-policy/policy-tab/late-in-out-policy/late-in-out-policy.component';
import { AbsentPolicyComponent } from './list-of-policy/policy-tab/absent-policy/absent-policy.component';
import { HalfDayPolicyComponent } from './list-of-policy/policy-tab/half-day-policy/half-day-policy.component';
import { OtPolicyComponent } from './list-of-policy/policy-tab/ot-policy/ot-policy.component';
import { COffPolicyComponent } from './list-of-policy/policy-tab/coff-policy/coff-policy.component';
import { LunchPolicyComponent } from './list-of-policy/policy-tab/lunch-policy/lunch-policy.component';
import { WeekOffPolicyComponent } from './list-of-policy/policy-tab/weekoff-policy/weekoff-policy.component';
import { PolicyService } from './list-of-policy/policy.service';
import { PolicyHistoryComponent } from './list-of-policy/policy-history/policy-history.component';
import { CommonPolicyGridComponent } from './list-of-policy/policy-tab/common-policy-grid/common-policy-grid.component';

@NgModule({
  declarations: [
    LatecomingPolicyComponent,
    ListOfPolicyComponent,
    ListOfRowPolicyComponent,
    HalfdayPolicyComponent,
    EarlyInOutPolicyComponent,
    LateInOutPolicyComponent,
    AbsentPolicyComponent,
    HalfDayPolicyComponent,
    OtPolicyComponent,
    COffPolicyComponent,
    LunchPolicyComponent,
    WeekOffPolicyComponent,
    PolicyHistoryComponent,
    CommonPolicyGridComponent
  ],
  imports: [
    CommonModule,
    PolicyRoutingModule,
    CoreModule.forRoot(),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    PolicyService
  ]

})
export class PolicyModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PolicyModule,
    };
  }
}
