import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceSycprocessComponent } from './attendance-sycprocess/attendance-sycprocess.component';
import { BulkPunchtransferComponent } from './bulk-punchtransfer/bulk-punchtransfer.component';
import { PunchtransferProcessComponent } from './punchtransfer-process/punchtransfer-process.component';

@Component({
  selector: 'app-synchronize-utility',
  templateUrl: './synchronize-utility.component.html',
  styleUrls: ['./synchronize-utility.component.css']
})
export class SynchronizeUtilityComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }
  synchronizeprocess(): void {
    const modalRef = this.modalService.open(AttendanceSycprocessComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  bioservertoclpms(): void {
    const modalRef = this.modalService.open(PunchtransferProcessComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  bulkbioservertoclpms(): void {
    const modalRef = this.modalService.open(BulkPunchtransferComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
}
