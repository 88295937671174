export class YearlyBudgetModel {
  site: number;
  plant: any;
  gL: number;
  costCenter: number;
  budgetAmount = 24000;
  yearType: number;
  year: String = '2019-2020';
}

export const monthList = [
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
  'JAN',
  'FEB',
  'MAR',
];

export const monthListFin = [
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
  'JAN',
  'FEB',
  'MAR',
];

export const monthListMid = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

export const plantList = [
  {
    Id: 1,
    Name: 'plant1',
    UnitList: [
      { Id: 1, Name: 'P1-Unit -1', BudgetAmount: 0 },
      { Id: 2, Name: 'P1-Unit -2', BudgetAmount: 0 },
      { Id: 3, Name: 'P1-Unit -3', BudgetAmount: 0 },
    ]
  },
  {
    Id: 2,
    Name: 'plant2',
    UnitList: [
      { Id: 1, Name: 'P2-Unit -1', BudgetAmount: 0 },
      { Id: 2, Name: 'P2-Unit -2', BudgetAmount: 0 },
      { Id: 3, Name: 'P2-Unit -3', BudgetAmount: 0 },
    ]
  },
  {
    Id: 3,
    Name: 'plant3',
    UnitList: [
      { Id: 1, Name: 'P3-Unit -1', BudgetAmount: 0 },
      { Id: 2, Name: 'P3-Unit -2', BudgetAmount: 0 },
      { Id: 3, Name: 'P3-Unit -3', BudgetAmount: 0 },
    ]
  },
];

export const siteList = [
  { Id: 1, Name: 'site1' },
  { Id: 2, Name: 'site2' },
  { Id: 3, Name: 'site3' },
  { Id: 4, Name: 'site4' },
];
export const glList = [
  { Id: 1, Name: 'GL1' },
  { Id: 2, Name: 'GL2' },
  { Id: 3, Name: 'GL3' },
  { Id: 4, Name: 'GL4' },
];

export const costCenterList = [
  { Id: 1, Name: 'Cost Center - 1' },
  { Id: 2, Name: 'Cost Center - 2' },
  { Id: 3, Name: 'Cost Center - 3' },
  { Id: 4, Name: 'Cost Center - 4' },
];

export const yearTypeList = [
  { Id: 1, Name: 'Financial' },
  { Id: 2, Name: 'Mide-Year' }
];

export const manPowerTypeList = [
  { Id: 1, name: 'Non-Budgeted' },
  { Id: 2, name: 'Budgeted' }
];

export const yearList = [
  '2019-2020',
  '2018-2019',
  '2017-2018',
];
