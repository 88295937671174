import { Directive } from '@angular/core';
import { DropdownDirective } from './dropdown.directive';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngxDropdownMenu]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class.dropdown-menu]': 'true',
    '[class.show]': 'dropdown.isOpen'
  }
})
export class DropdownMenuDirective {
  constructor(
    public dropdown: DropdownDirective
  ) { }
}
