import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FilterTypeEnum } from '../attendance-approve.model';

@Injectable()
export class AttendanceApprovePrsenter {

  public reportData$: Observable<FormGroup>;
  private reportData: Subject<FormGroup>;
  public startDate$: Observable<string>;
  private startDate: Subject<string>;
  public contractor$: Observable<number>;
  private contractor: Subject<number>;
  public workOrder$: Observable<any>;
  private workOrder: Subject<any>;
  constructor(private fb: FormBuilder) {
    this.reportData = new Subject();
    this.reportData$ = this.reportData.asObservable();

    this.startDate = new Subject();
    this.startDate$ = this.startDate.asObservable();

    this.contractor = new Subject();
    this.contractor$ = this.contractor.asObservable();

    this.workOrder = new Subject();
    this.workOrder$ = this.workOrder.asObservable();
  }

  public buildForm(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        UserID: [null],
        rdoDateType: [null],
        Date: [],
        Sdate: [null],
        Edate: [null],
        groupBy: [null],
        Company_Code: [null],
        Plant_Code: [null],
        Unit_Code: [null],
        Department_Code: [null],
        Section_Code: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        dateRange: [null],
      })
    });
  }

  public bindForm(formGroup: FormGroup, data: any) {
    formGroup.patchValue(data);
  }

  public saveContractorFilterData(contractorFormGroup: FormGroup) {
    if (contractorFormGroup.get('startDate').value !== '' || contractorFormGroup.get('contractorCode').value !== null
      || contractorFormGroup.get('workOrderNo').value !== null) {
      const data: any = contractorFormGroup.value;
      data.filterType = FilterTypeEnum.ContractorFilter;
      this.reportData.next(data);
    }
  }

  public onStartDateChange(startDate: string): void {
    if (startDate !== '') {
      this.startDate.next(startDate);
    }
  }

  public onContractorChange(contractorCode: string): void {
    if (contractorCode !== null) {
      this.contractor.next(parseInt(contractorCode, 10));
    } else {
      this.contractor.next(null);
    }
  }

  public onWorkOrderChange(contractorCode: string, workOrderNo: string): void {
    if (contractorCode !== null && workOrderNo !== null) {
      this.workOrder.next({
        contractorCode,
        workOrderNo
      });
    }
  }
}
