import { Component, OnInit } from '@angular/core';
import { LeaveService } from '../leave.service';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-leave-apply-from',
  templateUrl: './leave-apply-from.component.html',
  styleUrls: ['./leave-apply-from.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class LeaveApplyFromComponent implements OnInit {

  public leavetypes: [];
  datePickerPickerMode: string;
  datePickerPlaceHolder: string;
  constructor(
    private leaveService: LeaveService,
    public sharedService: SharedService,
  ) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
  }

  ngOnInit() {
    this.getLeaveType();
  }
  public getLeaveType(): void {
    this.leaveService.getLeaveType().subscribe((response: any) => {
      this.leavetypes = response.Data.Table;
    });
  }
}
