import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../app.globals';
import { Observable, of, Subject } from 'rxjs';
import { WOHRCTotalAPIResponse, WOTaskRequest } from './wo-task-list/wo-task-model';
import { map, switchMap } from 'rxjs/operators';
declare var $: any;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class HrClearanceService {

  private yearChangedActionSubject = new Subject<number>();
  private yearChangedAction$ = this.yearChangedActionSubject.asObservable();

  public yearList$: Observable<any[]> = this.getYears();
  public monthList$: Observable<any> = this.yearChangedAction$.pipe(switchMap(id => id ? this.getMonthsByYear({ FinancialYearCode: id }) : of(null)));

  private monthChangedActionSubject = new Subject<any>();
  private monthChangedAction$ = this.monthChangedActionSubject.asObservable();
  public hrcList$: Observable<any> = this.monthChangedAction$.pipe(switchMap(data => this.getHRCList(data)));


  // private generalUndertakingTaskListActionSubject = new Subject<number>();
  // private generalUndertakingTaskListAction$ = this.generalUndertakingTaskListActionSubject.asObservable();
  // public generalUndertakingTaskList$: Observable<any[]> = this.generalUndertakingTaskListAction$.pipe(switchMap(data => this.getGeneralUndertakingTaskList(data)));


  constructor(
    private httpClient: HttpClient
  ) { }

  public yearActionChange(yearCode: number): void {
    this.yearChangedActionSubject.next(yearCode);
  }

  public monthActionChange(data: any): void {
    this.monthChangedActionSubject.next(data);
  }

  getYearList() {
    return {
      Years: [
        {
          startDate: '2018-04-01',
          endDate: '2019-03-31'
        },
        {
          startDate: '2019-04-01',
          endDate: '2020-03-31'
        },
        {
          startDate: '2020-04-01',
          endDate: '2021-03-31'
        }
      ]
    }
  }

  getHRCList({ PayrollMonth_Code }: any) {
    const requestObj = {
      PayrollMonth_Code,
      ContractorVendor_Code: null,
      Role_Code: null,
      Status: null,
      Rolename: null,
      UserID: GLOBAL.USER_ID,
      RequireType: 1,
      ActionType: 0
    };

    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.SP_Listing_HRC_Filter}`, requestObj);
  }

  getTaskListByWorkOrder({ WorkOrder_Code, PayrollMonth_Code, Plant_Code, HRC_Code }: WOTaskRequest): Observable<any[]> {
    const requestObj = {
      WorkOrder_Code,
      PayrollMonth_Code,
      Plant_Code,
      HRC_Code,
      RequireType: 0,
      ActionType: 0,
      UserId: GLOBAL.USER_ID

      // "WorkOrder_Code": 101,
      // "PayrollMonth_Code": 147,
      // "Plant_Code": 100,
      // "HRC_Code": null,
      // "RequireType": 0,
      // "ActionType": 0,
      // "UserId": 142
    }
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_SL_HRC_WorkorderTaskDeatilData}`, requestObj);
  }

  public getHRCTotalData({ WorkOrder_Code, HRC_Code, PayrollMonth_Code }: WOTaskRequest): Observable<any[]> {
    const requestObj = {
      Filter: {
        Workorder_Code: WorkOrder_Code,
        HRC_Code: HRC_Code,
        payrollmonthcode: PayrollMonth_Code,
        RequireType: 0,
        ActionType: 0,
        UserId: GLOBAL.USER_ID

        // Workorder_Code: 101,
        // HRC_Code: 1,
        // payrollmonthcode: 147,
        // RequireType: 0,
        // ActionType: 0,
        // UserId: 142
      }
    }
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_SP_HRC_WOTaskTotal}`, requestObj);
  }

  public getYears(): Observable<any[]> {
    const requestObj = {
      selectedFild: null,
      RequireType: 1, // 1: Financial wise year | 2: Calendar wise year , + display current year
      ActionType: 0,
      UserId: GLOBAL.USER_ID
    }
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_SP_FinancialYear_V1}`, requestObj);
  }

  public getMonthsByYear({ FinancialYearCode }: any): Observable<any[]> {
    const requestObj = {
      PlantCode: null,
      FinancialYearCode,
      UserId: GLOBAL.USER_ID
    };

    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.SP_GetMonthFromFinacialYearCode}`, requestObj);
  }

  public getStatusList(requestObj: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.SP_GetMasterItemListData_V1}`, requestObj);
  }
  public getDocumentTypes(requestObj: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.Common_Document_ModulePageVSList}`, requestObj);
  }

  public getHRCDetailsRoleWise({ Plant_Code, HRCCode, Request_Code }: any): Observable<any[]> {
    const requestObj = {
      Filter: {
        Request_Code: Request_Code || null,
        RequestType_Code: 106,
        Referance_Code: HRCCode,
        Plant_Code,
        RequireType: 0,
        ActionType: 0,
        UserId: GLOBAL.USER_ID
      }
    };

    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_HRC_RequestDetails}`, requestObj);
  }

}
