import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from '../app.globals';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class CalenderService {

  constructor(private httpClient: HttpClient) {

  }
  public addcompliancetask(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_COMPLIANCE_TASK}`;
    return this.httpClient.post(url, data);
  }

  public getCompanyMasterData(): Observable<any> {
    const model = {
      Search: '',
      StatusOfIndustry: '',
      EntityTypeCode: '',
      LegalstatusCode: '',
      StateCode: '',
      CityCode: '',
      CountryCode: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA}`, model);
  }

  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA
  //   + '?search=&StatusOfIndustry=&EntityTypeCode=&LegalStatusCode=&StateCode=&CityCode=&Co');
  // }
  public getPlantMasterData(): Observable<any> {
    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA}`, model);
  }

  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA
  //  + '?search=&CompanyCode=&StateCode=&CityCode=&CountryCode=&UnitType=&Ownership=&LocatedAt=&ISESICApplicable=');
  // }

  public getShiftGroupMasterData(): Observable<any> {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_Group_MASTER_DATA}`, model);
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_Group_MASTER_DATA
  // + '?Search=&PlantCode=');
  // }
  public getShiftGroupType(): Observable<any> {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Shift_Group_Type}`, model);
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.Shift_Group_Type
  //  + '?Search=&PlantCode=');
  // }

  public getsiteshiftMasterData(): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SITE_SHIFT_GROUP_DATA + '?Search=');
  }

  public getUnitMasterData(plant): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ALL_UNIT + '?search=&PlantCode=plant');
  }
  public getFinacialYearData(): Observable<any> {
    //   const model = {
    //     Search: '',
    //     Department_Code:'',
    //   };
    //   return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FINACIAL_YEAR}` , model);
    // }
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FINACIAL_YEAR);
  }
  public getAllFunctionBudgetData(): Observable<any> {
    // tslint:disable-next-line: ter-indent
    return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FUNCTION_MASTER_DATA_FOR_GRID);
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}Get_FunctionBudgetMaster_Listing`;
    // this.http.get<any>(url).pipe(map((response: any) => response));
    // return this.httpClient.post(url, '');
  }
  // public getFunctionMasterData(): Observable<any> {
  //   // tslint:disable-next-line: prefer-template
  //   return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_FUNCTION_MASTER_DATA_FOR_GRID);
  // }






  getdeptMaster() {
    const queryString = $.param({
      Department_Code: '',
      Name: '',
    });


    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTMASTERS}?${queryString}`,
      httpOptions
    );

  }

  getAllDeptData() {
    const queryString = $.param({
      Department_Code: '',
      Name: '',

    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTMASTERS}?${queryString}`,
      httpOptions
    );

  }



  getAllHolidayMasterData() {
    const model = $.param({
      Search: '',
      sDate: '',
      eDate: '',
      CompanyCode: '',
      PlantCode: ''
    });
    return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_DATA}`, model);
  }
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_DATA}?${queryString}`,
  // httpOptions
  // );
  // }

  getHolidayMaster() {
    const model = $.param({
      Search: '',
      sDate: '',
      eDate: '',
      CompanyCode: '',
      PlantCode: ''
    });
    return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_DATA}`, model);
  }
  // const queryString = $.param({
  //  CompanyCode: '',
  // PlantCode: '',
  //   Unit_code: '',
  // DeptName: '',
  // });
  //   return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.MASTER_DATA.GET_HOLIDAY_DATA}?${queryString}`,
  // httpOptions
  // );

  // }

  getDepartmentData(unit) {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}`, model);
  }

  //  const queryString = $.param({
  //    UnitCode: JSON.stringify(unit)
  //  });
  //  return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //    `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}?${queryString}`,
  //    httpOptions
  //  );
  // }
  getSectionData() {
    const model = $.param({
      Search: '',
      ParentSectionCode: '',
      PlantCode: '',
      DepartmentCode: '',
      UnitCode: '',
      CompanyCode: ''
    });
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SECTION_DATA}`, model);

    // return this.httpClient.request(
    //  GLOBAL.HTTP_GET,
    // `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SECTION_DATA}?${model}`,
    //   httpOptions
    //  );
  }
  // tslint:disable-next-line: function-name
  CheckSectionRelationData(sectionCode, departmentcode, unitCode) {
    const queryString = $.param({
      UnitCode: JSON.stringify(unitCode),
      DepartmentCode: JSON.stringify(departmentcode),
      SectionCode: JSON.stringify(sectionCode),
      SubSectionLevelCode1: '',
      SubSectionLevelCode2: '',
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SECTION_RELATION_DATA}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckSubSectionRelationData(sectionCode, subsecetionlevelcode1, departmentcode, unitCode) {
    const queryString = $.param({
      UnitCode: JSON.stringify(unitCode),
      DepartmentCode: JSON.stringify(departmentcode),
      SectionCode: JSON.stringify(sectionCode),
      SubSectionLevelCode1: JSON.stringify(subsecetionlevelcode1),
      SubSectionLevelCode2: '',
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SECTION_RELATION_DATA}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckSubSection2RelationData(sectionCode, subsecetionlevelcode1, subsectionlevelcode2, departmentcode, unitCode) {
    const queryString = $.param({
      UnitCode: JSON.stringify(unitCode),
      DepartmentCode: JSON.stringify(departmentcode),
      SectionCode: JSON.stringify(sectionCode),
      SubSectionLevelCode1: JSON.stringify(subsecetionlevelcode1),
      SubSectionLevelCode2: JSON.stringify(subsectionlevelcode2),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SECTION_RELATION_DATA}?${queryString}`,
      httpOptions
    );
  }
  /**
   * getDepartmentData
   * @author Sonal Prajapati
   */

  getSectionData1(parentCode, parentSectionCode, plant, department, unit, company) {
    const model = {
      Search: '',
      ParentSection_Code: '',
      Plant_Code: '',
      Department_Code: '',
      Unit_Code: '',
      Company_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1}`, model);
  }
  //  const queryString = $.param({
  //    Search: '',
  //    ParentCode: JSON.stringify(parentCode),
  //    ParentSectionCode: JSON.stringify(parentSectionCode),
  //    PlantCode: JSON.stringify(plant),
  //     DepartmentCode: JSON.stringify(department),
  //    UnitCode: JSON.stringify(unit),
  //    CompanyCode: JSON.stringify(company)
  //  });
  //  return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //   `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1}?${queryString}`,
  //   httpOptions
  // );
  // }
  // tslint:disable-next-line: function-name
  GetShiftByPlant(parentCode) {
    const queryString = $.param({
      Search: '',
      PlantCode: JSON.stringify(parentCode),
      ShiftTypeGroupCode: ''
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MAN_POWER.GET_SHIFT_MASTER_DATA}?${queryString}`,
      httpOptions
    );
  }
  public getShiftGroup(): Observable<any> {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SHIFT_GROUP_LIST}`, model);
  }

  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SHIFT_GROUP_LIST + '?search=&CompanyCode=&PlantCode=');
  // }

  // public getCountryMasterData(): Observable<any> {
  //   const model = {
  //     Search: '',
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Country_Master_LIST}`, model);
  // }

  // tslint:disable-next-line: prefer-template
  //  return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Country_Master_LIST + '?Search=');
  // }
  public getStateMasterData(): Observable<any> {

    const model = {
      Search: '',
      Country_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_State_Master_LIST}`, model);
  }

  // tslint:disable-next-line: prefer-template
  //  return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_State_Master_LIST + '?Search=&CountryCode=');
  // }
  public getDistrictMasterData(): Observable<any> {
    const model = {
      Search: '',
      StateCode: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_District_Master_LIST}`, model);
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_District_Master_LIST + '?Search=&StateCode=');
  // }
  public getCityMasterData(): Observable<any> {
    const model = {
      Search: '',
      District_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_City_Master_LIST}`, model);
  }
  // tslint:disable-next-line: prefer-template
  //   return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_City_Master_LIST + '?Search=&DistrictCode=');
  // }

  public getPlant(): Observable<any> {

    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_LIST}`, model);
  }


  // tslint:disable-next-line: prefer-template
  //  return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_LIST + '?Search=&PlantCode=&ParentSkillCode=&TradeCode=');
  // }
  getact() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ACT}`,
      httpOptions
    );
  }
  //   getAllShift() {
  //     return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftMaster}` , model);
  // }
  //   const queryString = $.param({
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftMaster}?${queryString}`,
  //     httpOptions
  //   );
  // }
  getUnit() {
    const queryString = $.param({});
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_UNIT_LIST}?${queryString}`,
      httpOptions
    );
  }

  //   getAllShiftGroup() {
  //     return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftGroupMaster}` , model);
  // }
  //  const queryString = $.param({
  //   });
  // return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ShiftGroupMaster}?${queryString}`,
  //    httpOptions
  //   );
  // }
  getAllSiteShiftGroup() {
    const queryString = $.param({
      Search: ''
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SiteShiftGroupMaster}?${queryString}`,
      httpOptions
    );
  }
  addShift(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_Shift}`,
      httpOptions
    );
  }
  addShiftGroup(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_Shift_Group}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  InsertSiteShiftGroupData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.SAVE_Site_Shift_Group}`,
      httpOptions
    );
  }
  addSectionPlantMasterData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_SECTION_PLANT_MASTER_DATA}`,
      httpOptions
    );
  }
  addHolidayMasterData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_HOLIDAY_MASTER}`,
      httpOptions
    );
  }
  addPlantMasterData(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_PLANT_MASTER}`,
      httpOptions
    );
  }

  public addlegislationMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_LEGISLATION_MASTER}`;
    return this.httpClient.post(url, data);
  }
  addFunctionBudget(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.ADD_FUNCTION_BUDGET_MASTER}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdateShift(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Update_Shift}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdatePlantMaster(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.UPDATE_PLANT_MASTER}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdateShiftGroup(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Update_Shift_GRoup}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  UpdateSiteShiftGroup(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.Update_Site_Shift_GRoup}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetShiftDataForEdit(shiftCode) {

    const queryString = $.param({
      shiftCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_ID}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  DeleteSiteShiftGroupData(siteshiftgroupCode) {

    const queryString = $.param({
      siteshiftgroupCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.DELETE_SITE_SHIFT_GROUP}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetSiteShiftGroupDataForEdit(siteShiftGroupCode) {

    const queryString = $.param({
      siteShiftGroupCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Site_Shift_GRoupDAta_FOR_EDIT}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetShiftGroupProfile(shiftgroupCode) {

    const queryString = $.param({
      shiftgroupCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Shift_GRoup_ID}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  GetPlantProfile(plantcode) {

    const queryString = $.param({
      plantcode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATAFOR_EDIT}?${queryString}`,
      httpOptions
    );
  }


  // GetUnitProfile(Unit_Code, StatementType) {
  //
  //   const queryString = $.param({
  //     Unit_Code,
  //     StatementType
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}?${queryString}`,
  //     httpOptions
  //   );
  // }
  public getUnitProfile(unit): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      Unit_Code: unit,
      Plant_Code: null,
      Name: null,
      ShortCode: null,
      ShortName: null,
      StatementType: 'Edit'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  getSectionPlantMasterLsiting(): Observable<any> {
    const model = {
      UserId: localStorage.getItem('UserID'),
      PlantCode: null,
      UnitCode: null,
      DepartmentCode: null
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TABLE}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  getCountry() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}`, model).
      pipe(
        map((response: any) => response));
  }

    // return this.httpClient
    //   .request(
    //     GLOBAL.HTTP_GET,
    //    `${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_DATA}?Search=`,
    //     httpOptions
    //   );
 // }
  getStatesByCountryId(country) {
    const model = {
      Search: '',
      Country_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`, model).
      pipe(
        map((response: any) => response));
  }
  // const queryString = $.param({
  // Search: '',
  //   CountryCode: JSON.stringify(country)
  // });
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}?${queryString}`,
  // httpOptions
  // );
  // }
  getDistrictsByState(state) {
    const model = {
      Search: '',
      StateCode: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}`, model).
      pipe(
        map((response: any) => response));
  }
  // const queryString = $.param({
  //   Search: '',
  //   StateCode: JSON.stringify(state)
  // });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}?${queryString}`,
  //     httpOptions
  //   );
  // }
  getCitiesByDistrict(district) {
    const model = {
      Search: '',
      District_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}`, model).
      pipe(
        map((response: any) => response));
  }
  //   const queryString = $.param({
  //     Search: '',
  //     DistrictCode: JSON.stringify(district)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}?${queryString}`,
  //     httpOptions
  //   );
  // }
  getPOsByCity(city) {

    const model = {
      Search: '',
      City_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`, model);
  }
  //   const queryString = $.param({
  //     Search: '',
  //     CityCode: JSON.stringify(city)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // tslint:disable-next-line: function-name
  CheckPlant(name, plantcode) {

    const queryString = $.param({
      Name: name,
      PlantCode: plantcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_PLANT_NAME}?${queryString}`,
      httpOptions
    );
  }

  public checkUnit(plantcode, name, unitcode): Observable<any> {
    const model = {
      UserID: localStorage.getItem('UserID'),
      Unit_Code: unitcode,
      Plant_Code: plantcode,
      Name: name,
      ShortCode: null,
      ShortName: null,
      StatementType: 'ChkUnit'

    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  CheckShortCode(shortcode, plantcode) {

    const queryString = $.param({
      ShortCode: shortcode,
      PlantCode: plantcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_PLANT_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckShortName(shortname, plantcode) {

    const queryString = $.param({
      ShortName: shortname,
      PlantCode: plantcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_PLANT_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: function-name
  CheckSiteShiftGroupName(name, siteshiftcodecode) {

    const queryString = $.param({
      Name: name,
      SiteShiftGroupCode: siteshiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SITESHIFTNAME_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckSiteshiftShortCode(shortcode, siteshiftcodecode) {

    const queryString = $.param({
      ShortCode: shortcode,
      SiteShiftGroupCode: siteshiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SITESHIFTNAME_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  ChecksiteshiftShortName(shortname, siteshiftcodecode) {

    const queryString = $.param({
      ShortName: shortname,
      SiteShiftGroupCode: siteshiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SITESHIFTNAME_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }


  // tslint:disable-next-line: function-name
  CheckShiftGroupName(name, shiftcodecode) {

    const queryString = $.param({
      Name: name,
      ShiftGroupCode: shiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFGROUPNAME_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftgroupShortCode(shortcode, shiftcodecode) {

    const queryString = $.param({
      ShortCode: shortcode,
      ShiftGroupCode: shiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFGROUPNAME_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftgroupShortName(shortname, shiftcodecode) {

    const queryString = $.param({
      ShortName: shortname,
      ShiftGroupCode: shiftcodecode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFGROUPNAME_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: function-name
  CheckShiftName(name, shiftcode) {

    const queryString = $.param({
      Name: name,
      ShiftCode: shiftcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFT_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftShortCode(shortcode, shiftcode) {

    const queryString = $.param({
      ShortCode: shortcode,
      ShiftCode: shiftcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFT_SHORT_CODE}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  CheckshiftShortName(shortname, shiftcode) {

    const queryString = $.param({
      ShortName: shortname,
      ShiftCode: shiftcode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_SHIFT_SHORT_NAME}?${queryString}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  getMasterParentData(): Observable<any[]> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_MASTER_PARENT_DATA}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  getlegislationProfile(legislationCode) {

    const model = {
      Legislation_Code: legislationCode
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_LEGISLATION_MASTER_DATAFOR_EDIT}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));

  }
  public getUnitwiseDetails(data: any): Observable<any> {


    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post < any >(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public addUnitMasterData(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post < any >(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }

  public updateUnitMaster(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_InsertUnitMaster`;
    return this.httpClient.post < any >(url, data).pipe(
      map((response: any) => response)
    );
    //  return of(this.totalPlantWiseData());
  }
  // // tslint:disable-next-line: function-name
  // public getsubdatafromparent(parentcode: any): Observable<any> {
  //   const model = {
  //     parentcode: parentcode
  //   };
  //   const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_SUBDATA_FROM_PARENTCODE}`;
  //   return this.httpClient.post(url, model).
  //     pipe(
  //       map((response: any) => response));
  // }
  // tslint:disable-next-line: function-name
  public insertMasterItemListData(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_MASTER_ITEM_DATA}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }
  getAllNatureOfWork() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_MASTER_DATA}`, model).pipe(
      map((response: any) => response));
  }
  // const queryString = $.param({
  // Search: '',
  //   });
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.MAN_POWER.GET_NATURE_OF_WORK_MASTER_DATA}?${queryString}`,
  // httpOptions
  // );
  // }

  public addNatureOfWorkMaster(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_UPDATE_NATURE_OF_WORK_DATA}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  GetNatureOfWorkEdit(natureofworkcode) {
    const model = {
      NatureOfWork_Code: natureofworkcode
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DATA_BY_NATUREOFWORK_CODE}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }

  // tslint:disable-next-line: function-name
  ChecknatureofworkName(name, natureofworkcode) {

    const queryString = $.param({
      natureofworkcode,
      Name: name
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.CHECK_NATURE_OF_WORK_NAME}?${queryString}`,
      httpOptions
    );
  }
  getAllData(): Observable<any> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_ALL_GAZZATE_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getLegislationData(): Observable<any> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_LEGISLATION_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getLegislationforgrid(): Observable<any> {
    const model = {
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_LEGISLATION_MASTER_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  getrulesByactData(code): Observable<any> {
    const model = {
      code
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_RULES_BY_ACT_DATA}`;
    return this.httpClient.post(url, model).pipe(
      map((response: any) => response));
  }
  public addgazzetenotification(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.INSERT_UPDATE_GAZZETE_NOTIFICATION}`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  GetholidayProfile(holidayCode) {

    const queryString = $.param({
      holidayCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_HOLIDAY_BY_ID}?${queryString}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: function-name
  Updatholiday(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.UPDATE_HOLIDAY_MASTER}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  deleteholidayProfile(holidayCode) {

    const queryString = $.param({
      holidayCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.DELETE_HOLIDAY_BY_ID}?${queryString}`,
      httpOptions
    );
  }
  // Get Contractor code
  getDocumentTypes() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES).pipe(
      map((response: any) => response));
  }
}
