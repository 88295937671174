import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from 'src/app/core/services';
import { AttendanceService } from '../../attendance.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-ag-punch-in-cell-renderer',
  templateUrl: './ag-punch-in-cell-renderer.component.html',
  styleUrls: ['./ag-punch-in-cell-renderer.component.css']
})
export class AgPunchInCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  showInOut = GLOBAL.SHOW_IN_OUT;
  constructor(
    public sharedService: SharedService,
    public attendanceService: AttendanceService
  ) { }
  punchList;
  selectedInOut = [];
  punchData;

  ngOnInit() {
    this.punchList = GLOBAL.TYPE_OF_PUNCHES;
    const params = this.params.data.rowData;
    const model = {
      User_ID: localStorage.getItem('UserID'),
      AttResult_Code: params.LabourAttResult_Code
    };
    this.attendanceService.getPunchDetails(model).subscribe((punchData: any[]) => {
      punchData.forEach((punch) => {
        punch['momentAttendancePunch'] = moment(punch.AttendancePunch).format('YYYY-MM-DD');
      });
      this.punchData = _.groupBy(punchData, 'momentAttendancePunch');
      this.punchData = _.values(this.punchData);
    });
  }

  agInit(params: any): void {
    this.params = params;
    // this.count = params.rowIndex + 1;
  }

  refresh(): boolean {
    return false;
  }

  filterInoutSelection(selected) {
    this.selectedInOut = [];
    this.punchData.forEach((punches) => {
      punches.forEach((punch) => {
        if (Number(punch.drpInOutMidPunch) !== -1
          && Number(punch.drpInOutMidPunch) !== 0
          && Number(punch.drpInOutMidPunch) !== NaN) {
          this.selectedInOut.push(Number(punch.drpInOutMidPunch));
        }
      });
    });
    this.punchList.forEach((punch) => {
      if (this.selectedInOut.indexOf(punch.Code) === -1) {
        punch['isDisable'] = false;
      } else {
        punch['isDisable'] = true;
      }
    });
    this.punchDetailsUpdated();
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }

  punchDetailsUpdated() {
    if (this.params.punchDetailsUpdated) {
      this.params.punchDetailsUpdated(this.punchData);
    }
  }

}
