import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-group-row',
  templateUrl: './group-row.component.html',
  styleUrls: ['./group-row.component.css']
})
export class GroupRowComponent implements ICellRendererAngularComp {


  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  toggleRow(rowData) {
    const rowDataMaster = this.params.context.componentParent.rowData;
    const rowIndex = this.params.rowIndex + 1;
    for (let i = rowIndex; i < rowIndex + rowData.children.length; i++) {
      const element = rowDataMaster[i];
      element.isVisible = !element.isVisible;
    }
    this.params.context.componentParent.rowData = rowDataMaster;
    this.params.api.onFilterChanged();
  }

}
