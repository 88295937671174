export class AccidentMaster {
  public Accident_Code: string;
  public DateOfNotice: string;
  public TimeOfNotice: string;
  public Contractor_Code: Number;
  public ULC: Number;
  public GatePassNo: Number;
  public AccidentPlace: string;
  public AccidentDateTime: string;
  public CauseOfInjury: string;
  public NatureOfInjury: string;
  public InjuryPersonName: string;
  public PersonName: string;
  public Address: string;
  public Occupation: string;
  public EntryPersonName: string;
  public EntryPersonAddress: string;
  public Designation: string;
  public WitnessName1: string;
  public WitnessAddress1: string;
  public WitnessOccupation1: string;
  public WitnessName2: string;
  public WitnessAddress2: string;
  public WitnessOccupation2: string;
  public Remarks: string;
  public StatementType: string;
  public UserId: string;


  constructor(Accident_Code: string = null, DateOfNotice: string = null, TimeOfNotice: string = null, ContractorCode: number = null, 
    ULC: number = null,GatePassNo: number = null,  UserId: string = null, StatementType: string = null,
    AccidentDateTime: string = null,CauseOfInjury: string = null,NatureOfInjury: string = null,PersonName: string = null,
    Address: string = null,Occupation: string = null,EntryPersonName: string = null,EntryPersonAddress: string = null,AccidentPlace: string = null,
    Designation: string = null,WitnessName1: string = null,WitnessAddress1: string = null,WitnessOccupation1: string = null,
    WitnessName2: string = null,WitnessOccupation2: string = null,WitnessAddress2: string = null,Remarks: string = null,) {
    this.Accident_Code = Accident_Code;
    this.DateOfNotice = DateOfNotice;
    this.TimeOfNotice = TimeOfNotice;
    this.Contractor_Code = ContractorCode;
    this.ULC = ULC;
    this.GatePassNo = GatePassNo;
    this.AccidentPlace = AccidentPlace;
    this.AccidentDateTime = AccidentDateTime;
    this.CauseOfInjury = CauseOfInjury;
    this.NatureOfInjury = NatureOfInjury;
    this.PersonName = PersonName;
    this.Address = Address;
    this.Occupation = Occupation;
    this.EntryPersonName = EntryPersonName;
    this.EntryPersonAddress = EntryPersonAddress;
    this.Designation = Designation;
    this.WitnessName1 = WitnessName1;
    this.WitnessAddress1 = WitnessAddress1;
    this.WitnessOccupation1 = WitnessOccupation1;
    this.WitnessName2 = WitnessName2;
    this.WitnessAddress2 = WitnessAddress2;
    this.WitnessOccupation2 = WitnessOccupation2;
    this.Remarks = Remarks;
    this.UserId = UserId;
    this.StatementType = StatementType;
    
  }
}