import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-cell-active-renderer',
  templateUrl: './ag-action-cell-active-renderer.component.html',
  styleUrls: ['./ag-action-cell-active-renderer.component.css']
})
export class AgActionCellActiveRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  // showactive = true;
  // hidedeactive = false;
  constructor() { }

  ngOnInit() {
    // if (this.params.data.IsActive === true) {
    //   this.showactive = true;
    //   this.hidedeactive = false;
    // } else if (this.params.data.IsActive === false) {
    //   this.hidedeactive = true;
    //   this.showactive = false;
    // }
  }

  agInit(params: any): void {
    if (!params.data.parent) {
      this.params = params;
    }
  }

  refresh(): boolean {
    return false;
  }

  // isActiveUser() {
  //   this.params.context.componentParent.isActiveUser(this.params.data);
  // }

  resetPassword() {
    this.params.context.componentParent.resetPassword(this.params.data);
  }

}
