import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { AccessCheckboxCellComponent } from '../access-checkbox-cell/checkbox-cell.component';
import { UserManagementService } from '../user-management.service';


@Component({
  selector: 'app-user-department-section',
  templateUrl: './user-department-section.component.html',
  styleUrls: ['./user-department-section.component.css']
})
export class UserDepartmentSectionComponent implements OnInit {

  gridAPI: GridApi;
  gridOptions: GridOptions;
  dataList = [];
  columnDefs = [];
  rowData: any;
  orignalData = [];
  pUserId = null;
  pDepartmentSectionList = [];
  pRoles = [];
  pSelectedRole: string;
  public selectedRow: any[];
  selectedSections: any[] = [];

  @Input()
  set departmentSectionList(data: any[]) {
    this.pDepartmentSectionList = data;
  }

  @Input()
  set roles(data: any[]) {
    this.pRoles = data;
  }

  @Input()
  set userId(userId: number) {
    this.pUserId = userId;
  }

  @Input()
  set selectedRole(data: string) {
    this.pSelectedRole = data;
  }

  @Input() userFullName: string;

  @Output() closeModel = new EventEmitter<boolean>();

  constructor(
    private userManagementService: UserManagementService
  ) { }

  ngOnInit() {
    this.getDepartmentSectionList();
  }

  populateData() {
    if (!this.pSelectedRole || !this.pRoles || this.pRoles.length === 0
      || !this.pDepartmentSectionList || this.pDepartmentSectionList.length === 0) { // || this.pRoles.length === 0
      return;
    }

    const roles = this.pRoles.filter(item => this.pSelectedRole.indexOf(item.Role_Code) > -1);
    this.pDepartmentSectionList.forEach((data) => {
      const found = this.selectedSections.find(item => item.SectionId3 === data.LevelID2);
      let selectedRoles = [];
      if (found && found.selectedRoles) {
        selectedRoles = found.selectedRoles.split(', ').map(item => parseInt(item, 10));
      }

      roles.forEach((roleItem) => {
        const selectedRole = selectedRoles.findIndex(item => item === roleItem.Role_Code) > -1;
        data[`Role_${roleItem.Role_Code}`] = {
          userId: this.pUserId,
          sectionId3: data.LevelID2,
          roleCode: roleItem.Role_Code,
          value: selectedRole
        };
      });
    });

    setTimeout(() => {
      this.dataList = this.pDepartmentSectionList;
      const dataCol: any[] = [
        {
          headerName: 'Plant', field: 'Plant0',
          sortable: false, filter: false, width: 50, minWidth: 100, pinned: false,
          headerClass: 'text-center',
        },
        {
          headerName: 'Unit', field: 'Unit0',
          sortable: false, filter: false, width: 50, minWidth: 100, pinned: false,
          headerClass: 'text-center',
        },
        {
          headerName: 'Department', field: 'Department0',
          sortable: false, filter: false, width: 100, minWidth: 100, pinned: false,
          headerClass: 'text-center',
        },
        {
          headerName: 'Section 1', field: 'SectionName0',
          sortable: false, filter: false, width: 100, minWidth: 100, pinned: false,
          headerClass: 'text-center',
        },
        {
          headerName: 'Section 2', field: 'SectionName1',
          sortable: false, filter: false, width: 100, minWidth: 100, pinned: false,
          headerClass: 'text-center',
        },
        {
          headerName: 'Section 3', field: 'SectionName2',
          sortable: false, filter: false, width: 100, minWidth: 100, pinned: false,
          headerClass: 'text-center',
        },
      ];
      roles.forEach((data) => {
        dataCol.push({
          headerName: data.Name, field: `Role_${data.Role_Code}`,
          cellRendererFramework: AccessCheckboxCellComponent, headerClass: 'text-center',
          width: 100, minWidth: 100, colId: `Role_${data.Role_Code}`,
          cellRendererParams: {
            onCheckChange: this.onCheckChange,
          }
        });
      });
      this.columnDefs = dataCol;
      this.setGridOption();
    }, 500);
  }

  setGridOption() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowData: this.dataList,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      rowHeight: 32,
      headerHeight: 34,
      defaultColDef: {
        resizable: true
      }, context: {
        componentParent: this
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
    };
  }

  getSelectedRows() {
    // const selectedRow = this.gridAPI.getSelectedRows();
    this.selectedRow = this.gridAPI.getSelectedRows();
    this.userManagementService.setFormData(this.selectedRow);
  }

  onCheckChange = (data, params) => {
    console.log('data', data);
    this.userManagementService.updateUserAccessDataRelation(data).subscribe((res) => {
    }, (err) => {
      data.value = !data.value;
    });
  }

  getDepartmentSectionList(): void {
    const getDepartmentSectionList = this.userManagementService.getDepartmentSectionList();
    const getUserRoleList = this.userManagementService.getUserRoleList();
    const getUserDataAccess = this.userManagementService.getUserDataAccess(this.pUserId);

    forkJoin(getDepartmentSectionList, getUserRoleList, getUserDataAccess).
      subscribe(([departmentSectionList, roles, selectedSections]:
        [any[], any[], any[]]) => {
        this.departmentSectionList = departmentSectionList;
        this.roles = roles;
        this.selectedSections = selectedSections;
        this.populateData();
      });
  }

  onSave(): void {
    this.userManagementService.addUserfordepartment(this.selectedRow).subscribe((response: any) => {
      if (response) {
        this.onClose();
      }
    });
  }

  onClose(): void {
    this.closeModel.emit(false);
  }

}
