import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedService } from 'src/app/core/services';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { VerificationHistoryComponent } from '../approval-flow/verification-history/verification-history.component';
import { FormGroup } from '@angular/forms';
import { FilterTypeEnum } from 'src/app/reports/common-report.model';
declare var $;
let userID: string;
userID = localStorage.getItem('UserID');
@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css']
})
export class FilterPopupComponent implements OnInit {
  @Input() public filter;
/// Filter
  savedFilters = [];
  roles = GLOBAL.ROLES;
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  public activeFilter: string;


  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;
  private filterType: FilterTypeEnum;
  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;

  /////
  documentUrl = GLOBAL.DOC_URL;
  photoUrl = GLOBAL.PHOTO_URL;
  selectedRowData: any[] = [];
  public lStates: any[] = [];
  statelist = [];
  leavetypes = [];
  public disablesavebutton = true;
  public months: any[];
  public balance: any[];
  currentmonth = [];
  optionsChecked = [];
  approvalData: any;
  labourData: any;
  approvalOn = {
    FirstName: true,
    MiddleName: true,
    LastName: true,
    DOB: false,
    VoterId: false,
    AadharCard: true,
    LicenseNo: false,
    PFUANNo: false,
    PanCard: false,
    BankAc: false
  };
  public checkdocverify = false;
  documents = [];
  contractorVendors = [];
  workOrders = [];
  licensesByWo = [];
  ecPoliciesByWo = [];
  subContractorsByWo = [];
  subWorkOrders = [];
  subLicences = [];
  subEcPoliciesByWo = [];
  departments = [];
  masterData = [];
  workmanCategory = [];
  plants = [];
  skills = [];
  public isverifydisable = false;
  public disableverifybutton = true;
  selectedULC = 0;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private gatePassRequestsService: GatePassRequestsService,
    private logHelperService: LogHelperService,
    public sharedService: SharedService,
  ) { }
  async ngOnInit() {

    this.createFilterTab();
    this.getContractorData();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();



    await this.gatePassRequestsService.getALLDepartments().subscribe((res: any) => {
      this.departments = res;
      if (res.length > 0) {
        this.departments = res;
      }
      if (res.Data) {
        this.departments = res.Data.Table;
      }
    });
    await this.gatePassRequestsService.getALLWorkSiteCategories().subscribe((res: any[]) => {
      this.masterData = res;
      this.workmanCategory = this.masterData.filter(list => list.Parent_Code === 858);
    });
    await this.gatePassRequestsService.getALLPlants().subscribe((response: any) => {
      this.plants = response;
      if (response.length > 0) {
        this.plants = response;
      }
      if (response.Data) {
        this.plants = response;
      }
    });
    await this.gatePassRequestsService.getALLSkills().subscribe((response: any) => {
      //   this.skills = response;
      if (response.length > 0) {
        this.skills = response;
      }
      if (response.Data) {
        this.skills = response;
      }
    });
    // this.getAllcontractorData();
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    const userId = localStorage.getItem('UserID');
    if (roleCode === 119) {
     // this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      // this.getAllcontractorData();
    }
    this.getStates();
    this.getLeaveType();
    this.getMonth();
    // if (this.selectedRowData.Photo) {
    //   this.selectedRowData.Photo = GLOBAL.PHOTO_URL + this.selectedRowData.Photo;
    // }
  }

  // private getAllcontractorData(): void {
  //   this.gatePassRequestsService.getAllContractorData().subscribe((contractorResponse) => {
  //     //   this.gridRowData = contractorResponse.Data.Table;
  //     if (contractorResponse.length > 0) {
  //       this.gridRowData = contractorResponse;
  //     }
  //     if (contractorResponse.Data) {
  //       this.gridRowData = contractorResponse.Data.Table;
  //     }
  //     this.setStepArray();
  //   });
  // }


  public getLeaveType(): void {
    this.gatePassRequestsService.getLeaveTypeData().subscribe((response: any) => {
      this.leavetypes = response;
    });
  }

  public getMonth(): void {
    this.gatePassRequestsService.getMonth().subscribe((response: any) => {
      this.months = response;
      // this.addleave.controls['PayrollMonth_Code'].setValue(this.months[0].PayrollMonth_Code);
    });
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'COMPANY',
        active: true
      },
      {
        id: 2,
        name: 'GATE PASS',
        active: false
      },
      {
        id: 3,
        name: 'CONTRACTOR',
        active: false
      },
      {
        id: 4,
        name: 'SUB-CONTRACTOR',
        active: false
      }
    ];
  }


  private getStates(): void {

    this.gatePassRequestsService.getAllState().subscribe((response: any) => {

      this.statelist = response;
      this.lStates = this.statelist.filter(list => list.State_Code === 111);
    });
  }


  // private getContractorsSelectedByDefault(userId: any, roleCode: any): void {
  //   this.gatePassRequestsService.getContractorsbydefault(userId, roleCode).subscribe((contractorResponse) => {
  //     if (contractorResponse.length > 0) {
  //       this.gridRowData = contractorResponse;
  //     }
  //     if (contractorResponse.Data) {
  //       this.gridRowData = contractorResponse.Data.Table;
  //     }
  //     this.setStepArray();
  //   });
  // }

  getContractorData() {
    this.gatePassRequestsService.getContractorVendorData().subscribe((response: any) => {
      this.contractorVendors = response.Data.Table;
    });
  }

  public onFilterTab(filterObj: any): void {

    // // if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
    // //   this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
    // //     .get('Contractors').value.length > 1)) {
    //   this.logHelperService.logError({
    //     message: 'Please first fullfill data in contractor.'
    //   });
    //   // this.alertOption = {
    //   //   title: 'OOPS!!!',
    //   //   html: `<span class='customClass'>Please first fullfill data in contractor.</span>`,
    //   //   type: 'error'
    //   // },
    //   //   setTimeout(() => {
    //   //     this.openAlert();
    //   //   }, 10);
    //   return;
    // }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }

  filterData() {
    this.activeModal.close(this.filter);
  }

  onContractorChange(event) {
    // tslint:disable-next-line:max-line-length
    this.gatePassRequestsService.getWorkOrderByContractor(event.ContractorVendor_Code, this.filter.plantCode || 100).subscribe((res: any[]) => {
      this.workOrders = res;
    });
  }


  onWorkOrderChange(event) {
    this.gatePassRequestsService.getLicenseByWO(this.filter.contractorVendorCode, event.WorkOrder_Code)
          .subscribe((res: any) => {
            this.licensesByWo = res;
          });
    // this.gatePassRequestsService.getEcpolicyByWO(event.WorkOrder_Code)
    //   .subscribe((res: any[]) => {
    //     this.ecPoliciesByWo = res;
    //   });
    // this.gatePassRequestsService.getSubContractorByWOData(this.filter.contractorVendorCode, event.WorkOrder_Code)
    //   .subscribe((res: any[]) => {
    //     this.subContractorsByWo = res;
    //   });





    if (event) {

      const data = {
        contractorCode : this.filter.contractorVendorCode,
        plantCode :this.filter.plantCode,
        workOrderCode : this.filter.workorderCode
      };


          // this.selectedContractor = item.data.ContractorVendor_Code;
      this.gatePassRequestsService.getEcPolicyFilterDataSP(data).subscribe((rowSelectedData: any) => {

        this.ecPoliciesByWo = rowSelectedData;

      // .subscribe((res: any[]) => {
      //   this.licensesByWo = res;
      });
      this.gatePassRequestsService.getEcpolicyByWO(event.WorkOrder_Code)
      .subscribe((res: any[]) => {
        this.ecPoliciesByWo = res;
      });
      this.gatePassRequestsService.getSubContractorByWOData(this.filter.contractorVendorCode, event.WorkOrder_Code)
      .subscribe((res: any[]) => {
        this.subContractorsByWo = res;
      });


      const filterText = {
        plant_Code: this.filter.plantCode,
        WorkOrder_Code: event.WorkOrder_Code,
        UserId: localStorage.getItem('UserID'),
        contractors: this.filter.contractorVendorCode,
      };

      this.gatePassRequestsService
        .getContractorSubContractorData(filterText)
        .subscribe((response: any[]) => {
          this.subContractorsByWo = response;
            // this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
            //   this.filter.plantCode);
            // this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        });
    }
  }


  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.gatePassRequestsService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subWorkOrders = response;
    });
  }


  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.gatePassRequestsService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subLicences = response;
    });
  }

  getSubWorkOrderBySC(event) {
    // tslint:disable-next-line:max-line-length
    this.gatePassRequestsService.getWorkOrdersByContractorCode(event.ContractorVendor_Code, this.filter.plantCode).subscribe((response: any[]) => {
      this.subWorkOrders = response;
      this.getLicenseBySubWO(event.ContractorVendor_Code);
    });
  }

  // tslint:disable-next-line:variable-name
  getLicenseBySubWO(ContractorVendor_Code) {
    this.gatePassRequestsService.getLicenses(ContractorVendor_Code)
          .subscribe((res: any[]) => {
            this.subLicences = res;
          });
  }
  onFilterNameClick() {}
  deleteFilterItem() {}

}
