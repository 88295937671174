import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';
import * as IMask from 'imask';
@Directive({
  selector: '[appCustomMask]'
})
export class CustomMaskDirective implements AfterViewInit {
  @Input() maskInput;
  @Input() isRegExp = false;
  @Input() isDate = false;
  @Input() minDate;
  @Input() maxDate;

  constructor(private el: ElementRef) {
    // el.nativeElement.style.backgroundColor = 'yellow';

  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    let maskInput: any = {
      mask: this.maskInput,
    };
    if (this.isRegExp) {
      maskInput = {
        mask: new RegExp(this.maskInput)
      };
    }

    if (this.isDate) {
      maskInput = {
        mask: Date,  // enable date mask

        // other options are optional
        pattern: 'd{-}`m{-}`Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
        // you can provide your own blocks definitions, default blocks for date mask are:
        blocks: {
          d: {
            mask: IMask.default.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
          m: {
            mask: IMask.default.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          Y: {
            mask: IMask.default.MaskedRange,
            from: 1900,
            to: 9999,
          }
        },
        // define date -> str convertion
        format: (date) => {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          const year = date.getFullYear();

          if (day < 10) { day = `0${day}`; }
          if (month < 10) { month = `0${month}`; }

          return [day, month, year].join('-');
        },
        // define str -> date convertion
        parse(str) {
          const yearMonthDay = str.split('-');
          return new Date(`${yearMonthDay[1]}-${yearMonthDay[0]}-${yearMonthDay[2]}`);
        },
      };
    }

    IMask.default(
      this.el.nativeElement,
      maskInput
    );
  }
}
