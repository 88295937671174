import { monthListFin, monthListMid } from './../yearly-budget.modal';
import { ManPowerService } from './../../man-power/man-power.service';
import { filter } from 'rxjs/operators';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { Component, OnInit, Output, EventEmitter, HostBinding } from '@angular/core';
import {
  YearlyBudgetModel, plantList, glList,
  costCenterList, yearTypeList, manPowerTypeList
} from '../yearly-budget.modal';
import * as _ from 'lodash';
import { YearlyBudgetService } from '../yearly-budget.service';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { RowFormDGroupListComponent } from 'src/app/reports/daily-attendance/rpt-monthly-form-d-report/row-form-d-group-list/row-form-d-group-list.component';
import { SweetAlertOptions } from 'sweetalert2';
import { forEach } from 'lodash';

@Component({
  selector: 'app-yearly-budget-form',
  templateUrl: './yearly-budget-form.component.html',
  styleUrls: ['./yearly-budget-form.component.css']
})
export class YearlyBudgetFormComponent implements OnInit {
  sectionCodeData = new FormControl();
  @HostBinding('class') class = 'd-flex flex-column h-100 overflow-hidden';
  public alertOption: SweetAlertOptions = {};
  @Output() closeModal: EventEmitter<string>;
  @Output() afterSave: EventEmitter<void>;
  public companyList: any[];
  public formGroup: FormGroup;
  siteListData: any;
  public AlllSection = [];
  plantList: any[] = [];
  monthListControl: FormArray;
  glList = glList;
  manPowerTypeList = manPowerTypeList;
  masterListData: any = [];
  costCenterList = costCenterList;
  selectedPlant: 0;
  yearTypeList = yearTypeList;
  public dateRange: any[];
  weeklyOffDateList: any[] = [];
  holidayDateList: any[] = [];
  workmanCategoryList: any[] = [];
  stateList: any[] = [];
  unitList: any[] = [];
  departmentList: any[] = [];
  sectionTreeList: any[] = [];
  objMerg: any[] = [];
  unitDepartmentSectionTreeData: any[] = [];

  isOpenSectionEntry = false;
  monthListFin = monthListFin;
  monthListMid = monthListMid;
  monthObjectList = [];
  yearlyBudgetObject: YearlyBudgetModel = new YearlyBudgetModel();
  todayDate = new Date();
  showUnitWiseBlock: boolean;
  private selectedSectionList: any[] = [];
  private selectedworkmenCategoryList: any[] = [];
  private sectionTreeResponses = [];
  monthdata = new FormArray([]);
  public yearListData = [];
  private manPowerObj = {};
  private modalRef: any;
  public minDate = new Date(new Date().setDate(new Date().getDate() - 1));
  budgetForm: FormGroup;
  FunctionData: any;
  constructor(private yearlyBudgetService: YearlyBudgetService,
    private manPowerService: ManPowerService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder) {
    this.closeModal = new EventEmitter();
    this.afterSave = new EventEmitter();
  }

  ngOnInit() {
    this.getAllData();
    this.budgetForm = this.fb.group({
      Company_Code: [null, [Validators.required]],
      Site_Code: [null, [Validators.required]],
      Plant_Code: [null],
      Unit_Code: [null],
      Department_Code: [null],
      SectionCodes: [null],
      YearType: [null, [Validators.required]],
      Year: [null, [Validators.required]],
      Function_Code: [null, [Validators.required]],
      PlantWiseAmount: this.fb.array([]),
      MonthWiseData: this.fb.array([])
    })
  }

  private getAllData(): void {
    this.getFunctionData();
    this.getCompany();
    this.getSiteData();
    // this.getPlantTreeStructure();
  }

  public onCloseAction() {
    this.closeModal.next('click');
  }

  public onCompanyChange(): void {

  }

  public get f() {
    return this.budgetForm.controls
  }

  calculateAmountUnitWise() {
    const data = this.budgetForm.get('MonthWiseData') as FormArray;
    while (data.length !== 0) {
      data.removeAt(0)
    }
    let totalIndex: any;
    const formValue = this.budgetForm.value;
    this.AlllSection = [];
    this.plantList.forEach(element => {
      this.AlllSection = [];
      const unitList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === element.Plant_Code).Values;
      unitList.forEach((unit, index) => {
        this.departmentList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === element.Plant_Code).Values
          .find(itm => itm.Unitcode0 === unit.Unitcode0).Values
          .map((e) => { return { Department_Code: e.Departmentcode0, Name: e.Department0 }; });
        this.departmentList.forEach(department => {
          const sectionList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === element.Plant_Code).Values
            .find(itm => itm.Unitcode0 === unit.Unitcode0).Values
            .find(itm => itm.Departmentcode0 === department.Department_Code).Values;
          sectionList.forEach(sectionlvl0 => {
            sectionlvl0.Values.forEach(sectionlvl1 => {
              sectionlvl1.Values.forEach(section => {
                  section.PlantCode = element.Plant_Code,
                    section.PlantName = element.Name,
                    section.UnitName = unit.Unit0,
                    section.UnitCode = unit.Unitcode0,
                    section.DepartmentCode = department.Department_Code,
                    section.DepartmentName = department.Name,
                    section.Sectionlvl0Id = sectionlvl0.LevelID0,
                    section.Sectionlvl0Name = sectionlvl0.SectionName0,
                    section.Sectionlvl1Id = sectionlvl1.LevelID1,
                    section.Sectionlvl1Name = sectionlvl1.SectionName1,
                    section.Sectionlvl2Id = section.LevelID2,
                    section.Sectionlvl2Name = section.SectionName2,
                    section.SectionCode = section.Sectioncode2,
                    this.AlllSection.push(section);
              });
            });
          
          });



        });
      });
      this.AlllSection.forEach(section => {
        totalIndex = totalIndex != undefined ? totalIndex + 1 : 0;
        const plantList = formValue.PlantWiseAmount;
        const plantObj = plantList.find(a => a.PlantCode === section.PlantCode);
        const budgetAmount = plantObj.Amount;
        if (formValue.Year &&
          budgetAmount > 0 &&
          this.AlllSection.length > 0) {
          const yearObjectArray = formValue.YearType === 1 ? this.getYearName(formValue.Year).split('-') : this.getYearName(formValue.Year);
          const monthObjectList = [];
          const totalBudgetAmount = (budgetAmount
            / this.AlllSection.length).toFixed(2);
          const months = formValue.YearType === 1 ? this.monthListFin : this.monthListMid;
          months.map((value, index) => {
            const valueObject = {
              Period: '',
              Amount: (Number(totalBudgetAmount) / 12).toFixed(2)
            };
            if (formValue.YearType === 1) {
              if (index < 9) {
                valueObject.Period = `${value}-${yearObjectArray[0]}`;
              } else {
                valueObject.Period = `${value}-${yearObjectArray[1]}`;
              }
            } else {
              valueObject.Period = `${value}-${yearObjectArray}`;
            }

            monthObjectList.push(valueObject);
          });
          //   unitList.map((value) => {
          element.BudgetAmount = totalBudgetAmount;
          element.MonthObjectList = _.cloneDeep(monthObjectList);
          //    });


          //  this.budgetForm.get('MonthWiseData').patchValue(this.budgetForm.get('SectionCodes').value)

          // unitList.forEach((element, index) => {
          const data = this.budgetForm.get('MonthWiseData') as FormArray;
          data.push(this.fb.group({
            PlantCode: section.PlantCode,
            PlantName: section.PlantName,
            UnitName: section.UnitName,
            UnitCode: section.UnitCode,
            BudgetAmount: element.BudgetAmount,
            DepartmentCode: section.DepartmentCode,
            DepartmentName: section.DepartmentName,
            SectionCode: section.SectionCode,
            SectionName: section.SectionName0,
            Sectionlvl0Name : section.Sectionlvl0Name,
            Sectionlvl1Id : section.Sectionlvl1Id,
            Sectionlvl1Name : section.Sectionlvl1Name,
            Sectionlvl2Id : section.Sectionlvl2Id,
            Sectionlvl2Name : section.Sectionlvl2Name,
            MonthObjectList: this.fb.array([])
          }));

          element.MonthObjectList.forEach(data => {
            const Data = ((this.budgetForm.get('MonthWiseData') as FormArray).at(totalIndex) as FormGroup);
            (Data.get('MonthObjectList') as FormArray).push(this.fb.group({
              Amount: data.Amount,
              Period: data.Period
            }))
          });

          // });
        } else {
          this.showUnitWiseBlock = false;
        }

      });
      this.showUnitWiseBlock = true;
      console.log('FormValue', this.budgetForm.value)
    });
  }


  getYearName(Id) {
    const yearDetail = this.yearListData.find(a => a.Id === Id);
    return yearDetail.Name;
  }

  createUnitWiseList() {
    if (this.budgetForm.invalid) {
      return;
    } else {
      this.getPlantTreeStructure();
    }

    // const data = this.sectionCodeData;
    // if (this.budgetForm.get('Year').value &&
    //   this.yearlyBudgetObject.budgetAmount > 0 &&
    //   this.budgetForm.get('SectionCodes').value.length > 0) {
    //   const yearObjectArray = this.yearlyBudgetObject.year.split('-');
    //   const monthObjectList = [];
    //   const totalBudgetAmount = (this.yearlyBudgetObject.budgetAmount
    //     / this.budgetForm.get('SectionCodes').value.length).toFixed(2);
    //   const months = this.budgetForm.get('YearType').value === 1 ? this.monthListFin : this.monthListMid;
    //   months.map((value, index) => {
    //     const valueObject = {
    //       Period: '',
    //       Amount: (Number(totalBudgetAmount) / 12).toFixed(2)
    //     };

    //     if (index < 9) {
    //       valueObject.Period = `${value}-${yearObjectArray[0]}`;
    //     } else {
    //       valueObject.Period = `${value}-${yearObjectArray[1]}`;
    //     }
    //     monthObjectList.push(valueObject);
    //   });
    //   this.budgetForm.get('SectionCodes').value.map((value) => {
    //     value.BudgetAmount = totalBudgetAmount;
    //     value['MonthObjectList'] = _.cloneDeep(monthObjectList);
    //   });


    //   //  this.budgetForm.get('MonthWiseData').patchValue(this.budgetForm.get('SectionCodes').value)

    //   this.budgetForm.get('SectionCodes').value.forEach((element, index) => {
    //     const data = this.budgetForm.get('MonthWiseData') as FormArray;
    //     data.push(this.fb.group({
    //       BudgetAmount: element.BudgetAmount,
    //       Id: element.id,
    //       ItemName: element.itemName,
    //       MonthObjectList: this.fb.array([])
    //     }));

    //     element.MonthObjectList.forEach(data => {
    //       const Data = ((this.budgetForm.get('MonthWiseData') as FormArray).at(index) as FormGroup);
    //       (Data.get('MonthObjectList') as FormArray).push(this.fb.group({
    //         Amount: data.Amount,
    //         Period: data.Period
    //       }))
    //     });

    //   });

    //   this.showUnitWiseBlock = true;
    //   console.log('controls', this.budgetForm.controls)
    // } else {
    //   this.showUnitWiseBlock = false;
    // }

  }

  createDataforMonth(MonthObjectList, index) {
    const control = <FormArray>this.monthdata;
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
    control.reset();
    let val = 0;
    if (index === 0) {
      val = 500;
    } else {
      val = 0;
    }
    MonthObjectList.forEach(element => {
      control.push(this.fb.group({
        Amount: element.Amount + val,
        Period: element.Period + ' ' + val
      }))
    });
    return control;
  }

  addValueForMonth(element) {
    return this.fb.group({
      Amount: element.Amount,
      Period: element.Period
    });

  }

  MonthWiseControls(): FormArray {
    return this.budgetForm.get('MonthWiseData') as FormArray;
  }

  MonthlyControlsData(Index: number): FormArray {
    return this.MonthWiseControls()
      .at(Index)
      .get('MonthObjectList') as FormArray;
  }


  addUnitWiseEntry() {
    // this.budgetForm.get('MonthWiseData').value.forEach((value, index) => {
    //   this.onYearChange(index);
    // });
  }

  updateUnitBudgetAmt($event, unitWiseDataIndex) {
    if (this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex].BudgetAmount > Number($event.currentTarget.value)) {
      const remainingToalAmt = this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex].BudgetAmount - Number($event.currentTarget.value);
      const remainingAmt = Number(remainingToalAmt / (this.budgetForm.get('MonthWiseData').value.length - 1)).toFixed(2);

      this.budgetForm.get('MonthWiseData').value.map((value, index) => {
        if (index !== unitWiseDataIndex) {
          value.BudgetAmount = Number(value.BudgetAmount) + Number(remainingAmt);
          this.budgetForm.get('MonthWiseData').value[index]['MonthObjectList'].map((element, index) => {
            element.Amount = (Number(value.BudgetAmount) / 12).toFixed(2);
            return element;
          });
        }
      });
      this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex].BudgetAmount = Number($event.currentTarget.value);
      this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
        element.Amount = (Number(this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex].BudgetAmount) / 12).toFixed(2);
        return element;
      });

    } else {
      $event.currentTarget.value = this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex].BudgetAmount;
    }
  }

  updateUnitWiseAmount(amountIndex, unitWiseDataIndex) {
    let remainingTotalAmt = 0;
    this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index <= amountIndex) {
        remainingTotalAmt += Number(element.Amount);
      }
    });

    const remainingIndex = 12 - (amountIndex + 1);
    const remainingAmt = Number((this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex].BudgetAmount - remainingTotalAmt)
      / remainingIndex).toFixed(2);
    this.budgetForm.get('MonthWiseData').value[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index > amountIndex) {
        const Data = ((this.budgetForm.get('MonthWiseData') as FormArray).at(unitWiseDataIndex) as FormGroup);
        ((Data.get('MonthObjectList') as FormArray).at(index) as FormGroup).get('Amount').patchValue(remainingAmt);
        // this.budgetForm.get('MonthWiseData['+unitWiseDataIndex+'].MonthObjectList['+amountIndex+'].Amount').setValue(remainingAmt);
        element.Amount = remainingAmt;
      }
      return element;
    });
  }

  getCompany() {
    this.yearlyBudgetService.getCompanies().subscribe((response: any[]) => {
      this.companyList = response;
    });
  }

  getSiteData() {
    this.sharedDataService.getMasterData().subscribe((masterList: any) => {
      this.masterListData = masterList.Data.Table;
      this.siteListData = this.masterListData.filter(a => a.Parent_Code === 949);
    });
  }

  getFunctionData() {
    this.yearlyBudgetService.getFunctionData().subscribe((res: any) => {
      this.FunctionData = res;
    });
  }

  onSiteChange(data) {
    this.yearlyBudgetService.getPlantBySite(data.MasterItem_Code, this.f.Function_Code.value).subscribe((response: any[]) => {
      this.plantList = response;
      if (plantList.length > 0) {
        this.plantList.forEach(element => {
          const data = this.budgetForm.get('PlantWiseAmount') as FormArray;
          data.push(this.fb.group({
            PlantCode: element.Plant_Code,
            PlantName: element.Name,
            Amount: parseFloat('0.00').toFixed(2)
          }));
        });
      }
    });
  }

  public onPlantChange(): void {
    this.unitList = [];
    this.departmentList = [];
    this.sectionTreeList = [];

    this.budgetForm.controls['Unit_Code'].setValue(null);
    this.budgetForm.controls['Department_Code'].setValue(null);
    this.budgetForm.controls['SectionCodes'].setValue(null);

    const plantCode = this.budgetForm.controls['Plant_Code'].value;
    this.yearlyBudgetObject.plant = this.plantList[this.selectedPlant];

    if (plantCode) {
      // this.getUnits(plantCode);
      // this.getPlantTreeStructure(plantCode);
      this.getUnits();
    }
  }

  getPlantTreeStructure() {
    this.unitDepartmentSectionTreeData = [];
    const PLANT_UNIT_DEP_REQUEST =
    // tslint:disable-next-line:ter-indent
    {
      // tslint:disable-next-line:ter-indent
      Filter: {
        UserId: localStorage.getItem('UserID')
      },
      // tslint:disable-next-line:ter-indent
      Fields: [
        {
          KeyName: 'Plantcode0',
          DisplayName: 'Plant0'
        },
        {
          KeyName: 'Unitcode0',
          DisplayName: 'Unit0'
        },
        {
          KeyName: 'UnitDeptID0',
          DisplayName: 'Department0',
          ExtraFields: [
            'Departmentcode0'
          ]
        },
        {
          KeyName: 'LevelID0',
          DisplayName: 'SectionName0',
          ExtraFields: [
            'Sectioncode0'
          ]
        },
        {
          KeyName: 'LevelID1',
          DisplayName: 'SectionName1',
          ExtraFields: [
            'Sectioncode1'
          ]
        },
        {
          KeyName: 'LevelID2',
          DisplayName: 'SectionName2',
          ExtraFields: [
            'Sectioncode2',
            'Short_Code'
          ]
        }
      ]
      // tslint:disable-next-line:ter-indent
    };
    console.log('API eMerge2', PLANT_UNIT_DEP_REQUEST);
    const formValue = this.budgetForm.value;
    this.manPowerService.getDepartmentPlantUnitSectionTree(formValue.Plant_Code, null, null, PLANT_UNIT_DEP_REQUEST).subscribe((response: any[]) => {
      this.unitDepartmentSectionTreeData = response;
      // this.getUnits();
      // this.getPlants();
      this.calculateAmountUnitWise();
    });
  }

  public onUnitChange(): void {
    this.departmentList = [];
    this.sectionTreeList = [];

    this.budgetForm.controls['Department_Code'].setValue(null);
    this.budgetForm.controls['SectionCodes'].setValue(null);

    this.f.Department_Code.setValue(null);
    this.f.SectionCodes.setValue(null);

    const plantCode = this.budgetForm.controls['Plant_Code'].value;
    const unitCode = this.budgetForm.controls['Unit_Code'].value;
    if (plantCode && unitCode) {
      this.getDepartments(plantCode, unitCode);
    }
  }

  bindSectionTree(filteredData, section1Code, section2Code, isEdit: boolean, firstLevelLength, secondeLevelLength) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      let isCheck = false;
      const sectionCodes = this.budgetForm.controls['SectionCodes'].value;
      if (sectionCodes && sectionCodes.length > 0) {
        if (sectionCodes.find(item => item.id === val.LevelID2)) {
          isCheck = true;
          console.log('isCheck', isCheck);
        }
      } else if (firstLevelLength === 1 && secondeLevelLength === 1 && filteredData.length === 1) {
        isCheck = true;
      }
      convertedData.push(
        {
          section1Code,
          section2Code,
          id: val.LevelID2,
          sectionCode: val.LevelID2,
          itemName: val.SectionName2,
          currentname: 'section3',
          isActive: false,
          selected: false,
          checked: isCheck
        }
      );
    });
    return convertedData;
  }


  bindSection2Tree(filteredData, section1Code, isEdit: boolean, firstLevelLength) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.Values;
      if (subDataFilter.length > 0) {
        const childrens = [] = this.bindSectionTree(subDataFilter, section1Code, val.Sectioncode1,
          isEdit, firstLevelLength, filteredData.length);
        convertedData.push(
          {
            section1Code,
            id: val.Sectioncode1,
            sectionCode: val.Sectioncode1,
            itemName: val.SectionName1,
            currentname: 'section2',
            isActive: false,
            selected: false,
            children: childrens,
            isCheck: firstLevelLength === 1 && filteredData.length === 1 && childrens.length === 0 ? true : false,
          }
        );
      } else {
        let isCheck = false;

        const sectionCodes = this.budgetForm.controls['SectionCodes'].value;
        if (sectionCodes.length > 0) {
          if (sectionCodes.find(item => item.id === val.Sectioncode1)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        } else if (firstLevelLength === 1 && filteredData.length === 1) {
          isCheck = true;
        }
        convertedData.push(
          {
            section1Code,
            id: val.Sectioncode1,
            sectionCode: val.Sectioncode1,
            itemName: val.SectionName1,
            currentname: 'section2',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  bindSection1Tree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.Values;
      if (subDataFilter.length > 0) {
        const childrens = [] = this.bindSection2Tree(subDataFilter, val.Sectioncode0, isEdit, filteredData.length);
        convertedData.push(
          {
            id: val.Sectioncode0,
            sectionCode: val.Sectioncode0,
            itemName: val.SectionName0,
            currentname: 'section1',
            isActive: false,
            selected: false,
            children: childrens,
            isCheck: (filteredData.length === 1 && childrens.length === 0) ? true : false,
          }
        );
      } else {
        let isCheck = false;

        const sectionCodes = this.budgetForm.controls['SectionCodes'].value;
        if (sectionCodes.length > 0) {
          if (sectionCodes.find(item => item.id === val.Sectioncode0)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        } else if (filteredData.length === 1) {
          isCheck = true;
        }
        convertedData.push(
          {
            id: val.Sectioncode0,
            sectionCode: val.Sectioncode0,
            itemName: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }


  bindSectionEdit(response: any[], isEdit: boolean, formData) {
    console.log(response);
    // let resp = response;
    const resp = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === formData.Plant_Code).Values
      .find(itm => itm.Unitcode0 === formData.Unit_Code).Values
      .find(itm => itm.Departmentcode0 === formData.Department_Code).Values;
    // .map((e) => { return { Department_Code: e.UnitDeptID0, Name: e.Department0 }; });
    const convertedData = [];
    resp.forEach((val) => {
      // val.Values.forEach((plant) => {
      //   plant.Values.forEach((unit) => {
      //     unit.Values.forEach((department) => {
      //       department.Values.forEach((section1) => {
      convertedData.push(val);

      // section1.SectionList.forEach((sectionList2) => {
      //   sectionList2.SectionList.forEach((sectionList3) => {

      //   });
      // });
    });
    //     });
    //   });
    // });
    // });
    console.log('convertedData', convertedData);
    this.sectionTreeList = this.bindSection1Tree(convertedData, isEdit);
    console.log('sectionTreeList after', this.sectionTreeList);
  }

  private getSectionList(plantCode: any, unitCode: any, departmentCode: any): void {
    // this.manPowerService.getDepartmentPlantUnitSectionTree(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
    // this.manPowerForm.controls['SectionCodes'].setValue(this.manPower.SectionCodes);
    this.sectionTreeResponses = this.unitDepartmentSectionTreeData;
    this.bindSectionEdit(this.unitDepartmentSectionTreeData, false, this.budgetForm.value);
    // this.manPowerForm.form.get('SectionCodes').setValue([200]);
    // });
  }

  public onDepartmentChange(): void {
    this.sectionTreeList = [];
    this.budgetForm.controls['SectionCodes'].setValue(null);
    this.f.SectionCodes.setValue(null);

    const companyCode = this.budgetForm.get('Company_Code').value;
    // const stateCode = this.budgetForm.get('State_Code').value;
    const plantCode = this.budgetForm.controls['Plant_Code'].value;
    const unitCode = this.budgetForm.controls['Unit_Code'].value;
    const departmentCode = this.budgetForm.controls['Department_Code'].value;
    if (plantCode && unitCode && departmentCode) {
      // this.getManpowerBudget(companyCode, null, plantCode, departmentCode);
      this.getSectionList(plantCode, unitCode, departmentCode);
    }
  }

  public showSectionEntry() {
    if (this.budgetForm.valid) {
      const companyCode = this.budgetForm.get('Company_Code').value;
      // const stateCode = this.budgetForm.get('State_Code').value;
      const plantCode = this.budgetForm.controls['Plant_Code'].value;
      const unitCode = this.budgetForm.controls['Unit_Code'].value;
      const departmentCode = this.budgetForm.controls['Department_Code'].value;
      const sectionCodes = this.budgetForm.controls['SectionCodes'].value.map(item => item.id);
      // const workmenCatCodes = this.budgetForm.controls['CategoryWorkmenMIL_Code'].value.map(item => item.id);
      const workmenCatCodes = this.budgetForm.controls['CategoryWorkmenMIL_Code'].value;

      this.manPowerObj = this.budgetForm.getRawValue();

      //   this.getAllSectionShiftList(companyCode, null, plantCode, unitCode, departmentCode, sectionCodes, workmenCatCodes);
    } else {
      this.showAlert('error', 'Please select required field(s).', 'Error');
    }
  }

  private getUnits(): void {
    const plantCode = this.budgetForm.controls['Plant_Code'].value;
    const companyCode = this.budgetForm.get('Company_Code').value;
    this.unitList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === plantCode).Values;
    // .find(itm => itm.Unitcode0 === companyCode).Values;

    // if (this.unitList.length === 1) {
    this.budgetForm.get('Unit_Code').setValue(this.unitList[0].Unitcode0);
    // }
  }

  private getDepartments(plantCode: any, unitCode: any): void {
    const companyCode = this.budgetForm.get('Company_Code').value;
    this.departmentList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === plantCode).Values
      .find(itm => itm.Unitcode0 === unitCode).Values
      .map((e) => { return { Department_Code: e.UnitDeptID0, Name: e.Department0 }; });
    // if (this.departmentList.length === 1) {
    this.budgetForm.get('Department_Code').setValue(this.departmentList[0].Department_Code);
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
  }

  onSelectSection(data): void {
    console.log('data', data);
    this.selectedSectionList = data;
    this.budgetForm.get('SectionCodes').setValue(this.selectedSectionList)
  }

  onYearChange() {
    const yearType = this.f.YearType.value;
    this.f.Year.setValue(null)
    const yearData = [];
    this.yearlyBudgetService.getYearData().subscribe((res: any) => {
      res.forEach(element => {
        yearData.push({ Id: element.FinancialYear_Code, Name: yearType === 1 ? element.YearName : element.Year })
      });
      this.yearListData = yearData;
    })
  }

  onBackFromList() {
    this.showUnitWiseBlock = false;
  }


  onSaveClick() {
    const data = this.budgetForm.getRawValue();
    data.User_Id = localStorage.getItem('UserID');
    this.yearlyBudgetService.saveData(data).subscribe(() => {
      this.showUnitWiseBlock = false;
      this.closeModal.next();
    })

  }

}

