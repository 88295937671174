import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'monthName'
})
@Injectable()
export class MonthNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    let finalStr = '';
    const [name, year] = value.split('-');
    if (name) {
      finalStr = name.substring(0, 3);
    }
    if (year) {
      finalStr = `${finalStr}-${year.slice(-2)}`;
    }

    return finalStr;
  }
}
