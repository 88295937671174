import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/app.globals';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WOTask } from './wo-task-model';

@Injectable()
export class WOTaskListService {


  private taskListActionSubject = new Subject<number>();
  private taskListAction$ = this.taskListActionSubject.asObservable();
  public taskList$: Observable<any[]> = this.getWOTaskList({});
  public taskDetail$: Observable<any> = this.taskListAction$.pipe(switchMap(id => id ? this.getTaskDetail(id) : of(null)));
  public taskDocuments$: Observable<any[]> = this.taskListAction$.pipe(switchMap(id => id ? this.getDocuments(id) : of([])));

  private generalUndertakingTaskListActionSubject = new Subject<number>();
  private generalUndertakingTaskListAction$ = this.generalUndertakingTaskListActionSubject.asObservable();
  public generalUndertakingTaskList$: Observable<any[]> = this.generalUndertakingTaskListAction$.pipe(switchMap(data => this.getGeneralUndertakingTaskList(data)));

  constructor(
    private httpClient: HttpClient
  ) { }

  public setTaskDetailAction(taskCode: number): void {
    this.taskListActionSubject.next(taskCode);
  }

  public setGeneralUndertakingTaskAction(id: number): void {
    this.generalUndertakingTaskListActionSubject.next(id);
  }

  public getWOTaskList(params): Observable<any[]> {
    return this.httpClient.get<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.WO_TASK_LIST}`);
  }

  public getTaskDetail(taskCode: number): Observable<any[]> {
    const requestObj = {
      Filter: {
        Task_Code: taskCode,
        RequireType: 0,
        ActionType: 0,
        UserId: GLOBAL.USER_ID

        // "Task_Code": 100,
        // "RequireType": 0,
        // "ActionType": 0,
        // "UserId": 142
      }
    }
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.WO_TASK_DETAILS}`, requestObj);
  }

  public getGeneralUndertakingTaskList(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMA_SP_HRCUndertakingCheckList}`, data);
  }

  public saveHRCRequest(requestObj: any): Observable<any> {
    return this.httpClient.post<any>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_HRC_RequestActionSubmit}`, requestObj);
  }

  public saveHRCTaskInput(requestObj: any): Observable<any> {
    return this.httpClient.post<any>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_SP_InsertUpdate_HRCTaskInput}`, requestObj);
  }

  public saveHRCUnderTakingList(data: any[]): Observable<any> {
    return this.httpClient.post<any>(`${GLOBAL.APIS.USER_MANAGEMENT.CLPMS_SP_HRCUndertakingSave}`, data);
  }

  public hrcUploadDoc(data: any, files: File[]): Observable<any> {
    const formData = new FormData();
    formData.append('DShort_Code', data.DShort_Code);
    formData.append('UserID', GLOBAL.USER_ID);
    formData.append('RequireType', data.RequireType || 0);
    formData.append('Referance_Code', data.Referance_Code || 0);
    formData.append('RefTypeMasterList_Code', data.RefTypeMasterList_Code || 0);
    formData.append('Image_Type', data.Image_Type);

    files.forEach((element, index) => {
      formData.append(`file${String(index)}`, element, element['name']);
    });
    return this.httpClient.post<any>(`${GLOBAL.APIS.USER_MANAGEMENT.UploadCommonDoc}`, formData);
  }

  public getDocuments(task_Code: any): Observable<any> {
    const requestObj = {
      Referance_Code: task_Code,  // HRC_Code | Labour_Code | 
      RefTypeMasterList_Code: 1057,
      Page_Code: null,
      RequireType: 1,//0: All document with RefTypeMasterList_Code | 1: Referance wise document  
      ActionType: 0,
      UserId: GLOBAL.USER_ID
    };

    return this.httpClient.post<any>(`${GLOBAL.APIS.USER_MANAGEMENT.Common_Document_ReferacePageWiseDocList}`, requestObj);
  }
}
