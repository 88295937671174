import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectionWrapperService {

  public contractorData: any[];
  public workOrderData: any[];
  public licenceData: any[];
  public shiftData: any[];
  public productData: any[];
  public labourData: any[];
  constructor() {
    this.contractorData = [];
    this.workOrderData = [];
    this.licenceData = [];
    this.shiftData = [];
    this.productData = [];
    this.labourData = [];
  }

  public setContractorData(contractorData: any[]): void {
    this.contractorData = contractorData;
  }

  public getContractorData(): any[] {
    return this.contractorData;
  }

  public setLicenceData(licenceData: any[]): void {
    this.licenceData = licenceData;
  }

  public getLicenceData(): any[] {
    return this.licenceData;
  }

  public setWorkorderData(workOrderData: any[]): void {
    this.workOrderData = workOrderData;
  }

  public getWorkOrderData(): any[] {
    return this.contractorData;
  }

  public setShiftData(shiftData: any[]): void {
    this.shiftData = shiftData;
  }

  public getShiftData(): any[] {
    return this.shiftData;
  }

  public setProductData(productData: any[]): void {
    this.productData = productData;
  }

  public getProductData(): any[] {
    return this.productData;
  }

  public setLabourData(labourData: any[]): void {
    this.labourData = labourData;
  }

  public getLabourData(): any[] {
    return this.labourData;
  }
}
