import { TemplateRef } from '@angular/core';

export class Contractor {
  contractorVendorCode: number;
  company: string;
}

export class WorkOrder {
  workOrderNo: string;
  workOrderCode: number;
}

export class ReportFilterModel {
  startDate: Date;
  contractorCode: number;
  workOrderNo: number;
  license: string;
  subContractorCode: number;
  subContractorWorkOrderNo: number;
  subContractorLicense: string;
  filterType: number;
  filterName: any;
  // FilterName: string;
}

export class ReportFilterData {
  id: number;
  filterType: string;
  templateRef: TemplateRef<any>;
  data: any;
}

export enum FilterTypeEnum {
  ContractorFilter = 1,
  SubContractorFilter = 2
}

export enum MasterItemCode {
  QUALIFICATION = 'GM331',
  TECHNICALQUALIFICATION = 'GM342',
  QUALIFICATIONSTREAM = 'GM352',
  RELIGION = 'GM362',
  // CASTS = 'GM845',
 CASTS = 'GM557',
  GENDER = 'GM302',
  LANGUAGE = 'GM11002',
  MachineUseFor = 'GM11010',
  MachineAttachedWith = 'GM11013',
  IsInOut = 'GM11017',
  VerificationMode = 'GM11021',
  MachineType = 'GM11026',
  ExceptionalAttendanceReason = 'GM11067'
}
