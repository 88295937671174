import { Component, OnInit, ViewChild } from '@angular/core';
import { AgActionCellRendererComponent } from '../../core/components';
import { Router } from '@angular/router';
import { WorkOrdersService } from './work-orders.service';
import * as moment from 'moment';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { WorkOrderGroupRowComponent } from './work-order-group-row/work-order-group-row.component';
import { SharedService } from 'src/app/core/services';
import { CellClickedEvent, GridApi } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.css']
})
export class WorkOrdersComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  ForContractorLogin = true;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  groupingMeta = ['CompanyName'];

  contractorType = 1;
  columnDefs: any;
  rowData = [];
  numberOfRowsPerPage = 10;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  rowClassRules;
  showSpinner: boolean;

  // gridOptions = <GridOptions>{
  //   context: {
  //     componentParent: this
  //   },
  //   getRowHeight: (params) => {
  //     return 37;
  //   },
  //   // getRowWidth: (params) => {
  //   //   return 40;
  //   // },
  //   onGridReady: (params) => {
  //     this.gridAPI = params.api;
  //     params.api.sizeColumnsToFit();
  //   },
  //   onGridSizeChanged: (params) => {
  //     params.api.sizeColumnsToFit();
  //   },
  //   icons: {
  //     sortNone: '<i class="fa fa-sort"/>',
  //     sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
  //     sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
  //   },
  //   pagination: true,
  //   paginationPageSize: this.numberOfRowsPerPage,
  //   headerHeight: 50,
  // };

  constructor(private router: Router,
    // tslint:disable-next-line:align
    private workOrderService: WorkOrdersService,
    private sharedService: SharedService,
    public datepipe: DatePipe) { }

  ngOnInit() {

    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119)
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = false;
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = true;
    }
    this.createColumnDef();
    // this.rowClassRules = {
    //   'group-row': (params) => {
    //     return params.data.groupLevel === 0;
    //   },
    // };
    this.getWorkOrderList(this.contractorType);
  }

  async getWorkOrderList(contractorType: number) {
    this.workOrderService.getAllWorkOrders(contractorType, null).subscribe((res: []) => {
      if (res.length > 0) {
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
        this.rowData = tempRowData;
      } else {
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, null);
        this.rowData = tempRowData;
        this.showSpinner = false;
      }
    });
  }

  onTabChange(event) {
    this.getWorkOrderList(event.nextId);
  }

  editWorkOrder(rowData) {
    this.router.navigate([`/contractor/edit-work-order/${rowData.WorkOrder_Code}`]);
  }
  printWorkOrder(rowData) {
    this.workOrderService.getPrintprintWorkOrderdata(rowData.WorkOrder_Code).subscribe((response: any) => {
      if (response) {
        let currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
        let bodycontent = `<div class="print-form">
       <div class="pageheader" style="text-align: center;">
         <p><h2><b style="text-decoration: underline;">FORM – III</b></h2></p>
       
       <p style="font-size: 12px">[Under rule 21(2) 0f the Contract Labour (Regulation and Abolition) Central Rules, 1971; and rule 7(3) of the
         Inter-State Migrant Workmen (Regulation of Employment and Conditions of Service) Central Rules, 1980]
         </p>
          </div>
          <br>
          <p style="text-align: center;"><b>Form of Certificate by Principal Employer</b></p>
          <br>
         <div class="content" style="text-align: justify;style="font-size: 13px">
           <p>Certified that:</p>
           <p>
             1. I have engaged the applicant <span class="dyn-nm-cls">`+ response[0]['Company'] + `</span>. as a contractor in my
             establishment for the work of <span class="dyn-nm-cls">`+ response[0]['NatureOfWork'] + `</span>to be carried out from <span class="dyn-nm-cls">` + this.datepipe.transform(response[0]['OrderStartDate'], 'dd-MM-yyyy') + `</span> to <span class="dyn-nm-cls">` + this.datepipe.transform(response[0]['OrderEndDate'], 'dd-MM-yyyy') + `</span>
           </p>
           <p>
             2. I undertake to be bound by all the provisions of the Contract Labour (Regulation and
               Abolition) Act, 1970 (37 of 1970) and the Contract Labour (Regulation and Abolition)
               Central Rules, 1971 / the Inter-State Migrant Workmen (Regulation of Employment
               and Conditions of Service) Act, 1979 (30 of 1979) and the Inter-State Migrant
               Workmen (Regulation of Employment and Conditions of Service) Central Rules, 1980
               in so far as the provisions are applicable to me in respect of the employment of
               contract labour/inter-state migrant workmen by the applicant in my establishment.
           </p>
           <p>
             3. The engagement of contract labour in the said work is not prohibited under sub section (1) of section 10 of the Contract Labour (Regulation and Abolition) Act, 1970 (37 of 1970) or an award or a settlement.
           </p>
       </div>
       <div class="pagefooter">
         <div class="right-content" style="text-align: end;">
           <span style="font-size: 13px">Signature of the Principal Employer</span><br>
         <span style="font-size: 13px">Name and address of the establishment</span><br>
         <span style="font-size: 13px">`+ response[0]['ComanyName'] + `</span><br>
         <span style="font-size: 13px">`+ response[0]['EstablismentAddress'] + `</span>
         </div>
         <div class="left-content">
         <p style="font-size: 13px">Place : `+ response[0]['Location'] + `</p>
         <p style="font-size: 13px">Date : `+ currentDate + `</p>
         </div>
         <br>
         <div class="left-content" style="text-align: justify; font-size: 13px">
         <p style="font-size: 13px">Registration Number of the Principal Employer’s establishment:</p>
         <p style="font-size: 13px">Name of the work: `+ response[0]['NatureOfWork'] + `</p>
         <p style="font-size: 13px">LIN:- `+ response[0]['LinNo'] + `</p>
         </div>
       </div>
     </div>`;
        let printDetails, popupWin: Window;
        printDetails = bodycontent;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
     <html>
       <head>
         <style>
         body{
          font-family: Arial, sans-serif;
          letter-spacing: 0.4px;
          font-weight: 400;
          line-height: 1.7;
         }
             .dyn-nm-cls{
              padding-right: 16px;
              border-bottom: 1px solid;
              padding-left: 16px;
              font-weight: bold;
             }
         </style>
       </head>
   <body onload="window.print();">${printDetails}</body>
     </html>`
        );
        popupWin.document.close();
      }
    });
  }

  deleteWorkOrder(rowData) {
    if (confirm('Are you sure to delete this work order?')) {
      this.workOrderService.deleteWorkOrder(rowData.WorkOrder_Code).subscribe((res: any) => {
        if (res.Data === 1) {
          this.rowData = this.rowData.filter(item => item.WorkOrder_Code !== rowData.WorkOrder_Code);
          this.openAlert('Success!!!', 'success', 'Work order is deleted successfully.');
        } else {
          this.openAlert('Error!!!', 'error', 'Failed to delete work order.');
        }
      });
    }
  }

  private openAlert(title: string, type: any, message: string): void {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`,
      timer: 2000
    };
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  private createColumnDef(): void {
    this.columnDefs = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellRenderer: this.rowIndexCellRenderer, pinned: 'left',
      },
      {
        headerName: 'Purchase Order', field: 'WorkOrder_Code',
        width: 150, sortable: true, filter: true,
        pinned: 'left',
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        hide: true
      },
      {
        headerName: 'Contractor Name',
        field: 'CompanyName',
        width: 200, pinned: 'left',
        hide: false,
        cellStyle: { textAlign: 'left' }, headerClass: 'text-left',
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
        },
        cellRendererFramework: WorkOrderGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta
        },
      },
      {
        headerName: 'Order No', field: 'WorkOrderNo', width: 200,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: function (params) { return params.value ? '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' : '' },
        tooltip: (params) => params.value,
        onCellClicked: (event: CellClickedEvent) => {
          const cellValue = event.value;
          if (navigator && navigator['clipboard']) {
            navigator['clipboard'].writeText(cellValue)
              .then(() => { });
          }
        },
      },
      {
        headerName: 'Plant Name', field: 'ParentPlantName',
        width: 150, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: function (params) { return params.value ? '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' : '' },
        tooltip: (params) => params.value,
        onCellClicked: (event: CellClickedEvent) => {
          const cellValue = event.value;
          if (navigator && navigator['clipboard']) {
            navigator['clipboard'].writeText(cellValue)
              .then(() => { });
          }
        },
      },
      // {
      //   headerName: 'Contractor Name', field: 'CompanyName', width: 250,
      //   filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Order Owner', field: 'Owner', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: function (params) { return params.value ? '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' : '' },
        tooltip: (params) => params.value,
        onCellClicked: (event: CellClickedEvent) => {
          const cellValue = event.value;
          if (navigator && navigator['clipboard']) {
            navigator['clipboard'].writeText(cellValue)
              .then(() => { });
          }
        },
      },
      {
        headerName: 'Department', field: 'DepartmentName', width: 200,
        filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: function (params) { return params.value ? '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' : '' },
        tooltip: (params) => params.value,
        onCellClicked: (event: CellClickedEvent) => {
          const cellValue = event.value;
          if (navigator && navigator['clipboard']) {
            navigator['clipboard'].writeText(cellValue)
              .then(() => { });
          }
        },
      },
      
      {
        headerName: 'Start Date', field: 'OrderStartDate', width: 100,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellRenderer: (params) => {
          if (!params.data.parent) {
            return moment(params.value).format('DD/MM/YYYY');
          }
        },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'End Date', field: 'OrderEndDate', width: 100,
        filter: true, filterParams: { applyButton: true, clearButton: true }, sortable: true,
        cellRenderer: (params) => {
          if (!params.data.parent) {
            return moment(params.value).format('DD/MM/YYYY');
          }
        },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Person', field: 'Person', width: 80,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Action', field: '', width: 100,
        headerComponentParams: { enableSorting: false },
        cellRendererFramework: AgActionCellRendererComponent,
        cellRendererParams: { isPrintButton: true }
      }
    ];
  }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }

  updateNumberOfRowsPerPage() {
    this.gridAPI.paginationSetPageSize(this.numberOfRowsPerPage);
    this.gridAPI.sizeColumnsToFit();
  }

  onEdit(params) {
    console.log('edit');
  }

  onDelete(params) {
    console.log('delete');
  }
}
