import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import {
  WizardStep, Checklist, FileDoc, FileUploadService,
  AgNumberCountComponent, AgActionCellRendererComponent
} from '../../core/components';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { TreeviewItem } from 'ngx-treeview';
import { GLOBAL } from 'src/app/app.globals';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/core/services';
import { GrievanceService } from '../grievance.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AgActionsComponent } from '../helper';
import { async } from 'q';

@Component({
  selector: 'app-sub-grievance',
  templateUrl: './sub-grievance.component.html',
  styleUrls: ['./sub-grievance.component.css']
})
export class SubGrievanceComponent implements OnInit {
  actiondetail: string;
  resolveddate: any;
  formChangesSubscription: Subscription;
  @ViewChild('grievanceForm') grievanceForm: NgForm;
  checkList: Checklist[] = [];
  wizardSteps: WizardStep[] = [];
  contractorType = 1;
  contractorData: any;
  subContractorData: any;
  masterData: any;
  impactData: any;
  priorityData: any;
  plants: any;
  imagesrc: any;
  statuses: any;
  errexpecteddate: boolean;
  expecteddate;
  actionData = [];
  per;
  actionstatus;
  filter = 0;
  userEmail = 'rajnish.mehat@bolr.co.in';
  userId = 653;
  userRoleCode = 101;
  date: Date;
  dates: Date;
  categoryData: any;
  uploadedFiles: FileDoc[] = [];
  closeResult: string;
  rowData = [];
  rowData1 = [];
  defaultColDef;
  acctions = [];
  labourWorkData: any;
  getEcData: any;
  rowSelection;
  data;
  workOrder;
  documentTypes: [];
  ediGrievanceCode: string;
  columnDefs = [
    {
      headerName: '#', field: 'sortCode', width: 100, headerComponentParams:
        { enableSorting: false }, suppressMenu: true, cellRendrer: AgNumberCountComponent
    },
    {
      headerName: 'IVR No', field: 'GatePassNo',
      width: 300, filterParams: { applyButton: true, clearButton: true }, suppressMenu: true
    },
    {
      headerName: 'Labour Code',
      field: 'Labour_Code', width: 200, filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'LabourName',
      field: 'FullName', width: 300,
      filterParams: { applyButton: true, clearButton: true }, suppressMenu: true
    },
    {
      headerName: 'Select All', field: 'Select All', width: 200,
      headerComponentParams: { enableSorting: false },
      suppressMenu: true, headerCheckboxSelection: true,
      checkboxSelection: true
    }
  ];

  columnDef = [
    {
      headerName: '#', field: 'sortCode', width: 40, headerComponentParams:
        { enableSorting: false }, suppressMenu: true, cellRendrer: AgNumberCountComponent
    },
    {
      headerName: 'IVR No', field: 'GatePassNo',
      width: 100, filterParams: { applyButton: true, clearButton: true }, suppressMenu: true
    },
    {
      headerName: 'Labour Code',
      field: 'Labour_Code', width: 100, filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'LabourName',
      field: 'FullName', width: 200,
      filterParams: { applyButton: true, clearButton: true }, suppressMenu: true
    },
    {
      headerName: 'Action', field: '', width: 100,
      headerComponentParams: { enableSorting: false },
      cellRendrer: AgActionsComponent,
    }
  ];
  modeOfCompliantData: any;
  grivanceCode: any;
  editMode = false;
  ediGrievanceData: any;
  disableAddContactPerson: boolean;
  disableAddContactState: boolean;
  constructor(
    private grievanceService: GrievanceService,
    private modalService: NgbModal,
    public sharedService: SharedService,
    private fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.date = new Date();
    this.dates = new Date();
    this.defaultColDef = {
      width: 100,
      resizable: true
    };
    this.rowSelection = `multiple`;
  }

  async ngOnInit() {
    this.formChangesSubscription = this.grievanceForm.form.valueChanges.subscribe((form:any) => {
      if (form['category'] === '') {
        form['category'] = null;
      }
      if (form['Contractor'] === '') {
        form['Contractor'] = null;
      } if (form['subContractor'] === '') {
        form['subContractor'] = null;
      }
      if (form['mode'] === '') {
        form['mode'] = null;
      }
      if (form['plant'] === '') {
        form['plant'] = null;
      }
      if (form['priotity'] === '') {
        form['priotity'] = null;
      }
      if (form['impact1'] === '') {
        form['impact1'] = null;
      }
    });


    this.dates = new Date();
    this.dates.setDate(this.dates.getDate() + 2);
    this.grievanceService.getGrievanceCode().subscribe((data: any) => {
      this.grivanceCode = data;
    });
    this.grievanceService.getDocumentTypes().subscribe((docTypes: []) => {
      this.documentTypes = docTypes;
    });

    this.grievanceService.getMasterItemData().subscribe((masterList: any) => {
      this.masterData = masterList;
      this.masterData = masterList;
      this.impactData = this.masterData.filter(list => list.Parent_Code === 430);
      this.priorityData = this.masterData.filter(list => list.Parent_Code === 430);
      this.categoryData = this.masterData.filter(list => list.Parent_Code === 540);
      this.modeOfCompliantData = this.masterData.filter(list => list.Parent_Code === 834);
    });
    this.getContractorList(this.contractorType);
    this.wizardPage();
    this.checklistPage();
    this.getPlants();
    this.grivanceData();

    this.ediGrievanceCode = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.ediGrievanceCode) {
      this.editMode = true;
      this.grievanceService.getGrivanceByID(this.ediGrievanceCode).subscribe((response) => {
        const grivance = response[0];
        if (!grivance) {
          return;
        }
        this.ediGrievanceData = grivance;
        // Step 1
        this.grievanceForm.controls['grievanceCode'].setValue(grivance.Grievance_Code);
        this.grievanceForm.controls['grievanceSubject'].setValue(grivance.GrievanceSubject);
        this.grievanceForm.controls['category'].setValue(grivance.GrievanceCategory);
        this.grievanceForm.controls['grievanceDate'].setValue(grivance.GrievancDate);
        this.grievanceForm.controls['dateOfEntry'].setValue(grivance.DateofEntry);
        this.grievanceForm.controls['personemail'].setValue(grivance.EmailId);
        this.grievanceForm.controls['Contractor'].setValue(grivance.ContactorVendor_Code);
        this.grievanceForm.controls['subContractor'].setValue(grivance.SubContractorVendor_Code);
        this.grievanceForm.controls['nameOfCompliant'].setValue(grivance.NameOfComplainant);
        this.grievanceForm.controls['mobileNo'].setValue(grivance.Mobile);
        this.grievanceForm.controls['phoneNo'].setValue(grivance.PhoneNumber);
        this.grievanceForm.controls['impact1'].setValue(grivance.Impact);
        this.grievanceForm.controls['priotity'].setValue(grivance.Priority);
        this.grievanceForm.controls['mode'].setValue(grivance.ModeOfComplian);
        this.grievanceForm.controls['plant'].setValue(grivance.Plant_Code);
        // this.grievanceForm.controls['description1'].setValue(grivance.Description);
        this.grievanceForm.controls['expectedDate'].setValue(grivance.ExpectedDate);
        this.grievanceForm.controls['status1'].setValue(grivance.StatusCode);
        // this.grievanceForm.controls['closeDate'].setValue(grivance.PermentDistrict_Code);


        if (grivance.DocumentImageMasterViews) {
          this.uploadedFiles = [];
          grivance.DocumentImageMasterViews.forEach((file) => {
            this.uploadedFiles.push({
              ID: file.ID,
              file: {
                lastModified: file.ModifiedDate,
                name: file.UploadFileName
              },
              documentTypeCode: file.DocumentTypeMasterList_Code,
              isUploaded: true,
              url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder
            });
          });

        }
      });
    } else {
      this.editMode = false;
    }
  }

  onSelectionChanged(params) {
    this.data = params.api.getSelectedRows();
  }

  addLabour1(event) {
    this.rowData1 = this.data;
  }

  clearAll() {
    this.rowData1 = [];
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onRowClick(event) {
    this.rowSelection = 'multiple';
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    }
  }

  getContractorList(contractorType: number) {
    this.grievanceService.getAllContractors(contractorType).subscribe((res: []) => {
      this.contractorData = res;
    });
  }

  onContractorChange(event) {
    this.grievanceService.getWorkOrderByContractor(event.ContractorVendor_Code).subscribe((res: any[]) => {
      this.subContractorData = res;
    });
  }

  onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  grivanceData() {
    this.grievanceService.getGrievanceData().subscribe((data: any) => {
      this.statuses = data;
    });
  }

  getlabourByWorkOrder(event) {
    this.grievanceService.getLabourByWorkorder(event.WorkOrder_Code).subscribe((data: any) => {
      this.labourWorkData = data;
      this.rowData = data.data;
    });
  }

  getEICUserByWorkorer(event) {
    this.grievanceService.getEICUserByWorkorer(event.WorkOrder_Code).subscribe((data: any) => {
      this.workOrder = event.WorkOrder_Code;
      this.getEcData = data;
    });
  }

  deleteContractor(index: number) {
    this.rowData1.splice(index, 1);
  }

  /**
   * GET plant REQUEST
   * @author Sonal Prajapati
   */
  getPlants() {
    this.grievanceService.getPlants().subscribe((data: any) => {
      this.plants = data;
    });
  }

  wizardPage() {

    this.wizardSteps = [
      {
        id: 'grievance',
        name: 'Grievance Information',
        isValid: () => {
          return this.grievanceForm.controls.grievanceSubject.valid
            && this.grievanceForm.controls.category.valid
            && this.grievanceForm.controls.Contractor.valid
            && this.grievanceForm.controls.nameOfCompliant.valid
            && this.grievanceForm.controls.mobileNo.valid;
        },
      },
      {
        id: 'Labour',
        name: 'Labour',
        isValid: () => {
          return true;
        }
      },
      {
        id: 'Document',
        name: 'Document',
        isValid: () => {
          return true;
        }
      },
      {
        id: 'CourseActionTaken',
        name: 'CourseActionTaken',
        isValid: () => {
          return true;
        }
      },

    ];

  }

  checklistPage() {
    this.checkList = [
      {
        id: 1,
        text: 'Grievance Subject',
        open: true,
        childrens: [
          {
            text: 'Grievance Subject',
            title: 'NameandCode',
            desc: 'Grievance Subject Grievance Details Are Must Required.',
            isValid: () => {
              return (this.grievanceForm.controls.grievanceSubject.valid && this.grievanceForm.controls.category.valid);
            },
            hasError: () => {
              return ((this.grievanceForm.controls.grievanceSubject.dirty || this.grievanceForm.controls.grievanceSubject.touched
                || this.grievanceForm.controls.category.dirty
                || this.grievanceForm.controls.category.touched) &&
                this.grievanceForm.controls.grievanceSubject.invalid || this.grievanceForm.controls.category.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Select Contractor',
            title: 'Select Contractor',
            desc: 'Contractor must be Required',
            isValid: () => {
              return (this.grievanceForm.controls.Contractor.valid);
            },
            hasError: () => {
              return ((this.grievanceForm.controls.Contractor.dirty || this.grievanceForm.controls.Contractor.touched)
                && this.grievanceForm.controls.Contractor.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',

          },

          {
            text: 'Name Of Compliant and Mobile No',
            title: 'Name Of Compliant and Mobile No',
            desc: 'Name Of Compliant and Mobile No Required Must',
            isValid: () => {
              return (this.grievanceForm.controls.nameOfCompliant.valid
                && this.grievanceForm.controls.mobileNo.valid);
            },
            hasError: () => {
              return ((this.grievanceForm.controls.nameOfCompliant.dirty
                || this.grievanceForm.controls.nameOfCompliant.touched)
                && this.grievanceForm.controls.nameOfCompliant.invalid)
                || ((this.grievanceForm.controls.mobileNo.dirty || this.grievanceForm.controls.mobileNo.touched)
                  && this.grievanceForm.controls.mobileNo.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',

          },
        ],
      },
    ];
  }

  onSubmit(grievanceForm, redirect = true) {

    const grivance = {
      Grievance_Code: this.grivanceCode,
      GrievanceSubject: grievanceForm.grievanceSubject,
      GrievanceCategory: grievanceForm.category,
      GrievancDate: grievanceForm.grievanceDate,
      DateofEntry: this.date,
      ContactorVendor_Code: grievanceForm.Contractor,
      ContractorVendor_Code1: grievanceForm.Contractor,
      // WorkOrder_Code: this.workOrder,
      LabourData: this.rowData1,
      EmailId: grievanceForm.personemail,
      NameOfComplainant: grievanceForm.nameOfCompliant,
      Impact: grievanceForm.impact1,
      Priority: grievanceForm.priotity,
      Description: grievanceForm.description1,
      Mobile: grievanceForm.mobileNo,
      PhoneNumber: grievanceForm.phoneNo,
      UserId: this.userId,
      ExpectedDate: this.date,
      StatusMasterItem: grievanceForm.status1,
      ActionDetail: grievanceForm.actionDetail,
      CloseDate: grievanceForm.closeDate,
      rolecode: this.userRoleCode,
      SubContractorVendor_Code: grievanceForm.subContractor,
      ModeOfComplian: grievanceForm.mode,
      Plant_Code: grievanceForm.plant,
      // EmailsendValue: grievanceForm.Emailvalue //,
      EmailsendValue: 3,
      ActionViews: [],
      WorkOrder_Code: 1381

    };
    const uploadParams = {
      contractorvendor_code: ''
    };

    if (this.editMode) {
      this.grievanceService.postGrivance(grivance).subscribe((result: any) => {
        console.log(result, 'result');
        uploadParams.contractorvendor_code = result.Code;
        if (uploadParams.contractorvendor_code) {
          this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_DOC)
            .subscribe((res) => {
              if (redirect) {
                this.router.navigate(['grievances']);
              } else {
                this.grievanceForm.reset();
                this.clearForm();
              }
            });
        }
      });
    } else {
      this.grievanceService.postGrivance(grivance).subscribe((result: any) => {
        uploadParams.contractorvendor_code = result.Code;
        if (uploadParams.contractorvendor_code) {
          this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_DOC)
            .subscribe((res) => {
              if (redirect) {
                this.router.navigate(['grievances']);
              } else {
                this.grievanceForm.reset();
                this.clearForm();
              }
            });
        }
      });
    }
  }
  saveAndNew() {
    this.onSubmit(this.grievanceForm.value, false);
  }

  clearForm() {
    console.log(this.grievanceForm);
    this.grievanceForm.reset();
    console.log(this.grievanceForm, 'after reset value');
  }

  onCancel() {
    this.grievanceForm.reset();
    this.router.navigate(['grievances']);
  }

  updateAndNew() {
    this.onSubmit(this.grievanceForm.value, false);
    this.grievanceForm.reset();
  }
}
