import { Injectable } from "@angular/core";
import { CommonHttpClientService } from "./common-http-client.service";
import { GLOBAL } from "../app.globals";
import { TradeMasterParams } from "./common-api.model";

@Injectable({
  providedIn: "root",
})
export class CommonApiService {
  constructor(public commonHttpClientService: CommonHttpClientService) {}

  getAllTradeMaster(tradeMasterParams: TradeMasterParams) {
    const body = JSON.stringify(tradeMasterParams);

    return this.commonHttpClientService.post(
      `${GLOBAL.APIS.COMMON.GET_ALL_TRADE}`,
      body
    );
  }
}
