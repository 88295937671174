import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SharedService } from 'src/app/core/services';
import { FileDoc, WizardStep, Checklist } from 'src/app/core/components';
import { takeUntil } from 'rxjs/operators';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { LicencesService } from '../licences.service';
import { isNullOrUndefined } from 'util';
import { MasterItemCode, NatureOfLicenceCode } from '../../contractor.enum';
import { GLOBAL } from 'src/app/app.globals';
import * as moment from 'moment';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-licenses-form',
  templateUrl: './licenses-form.component.html',
  styleUrls: ['./licenses-form.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class LicencesFormComponent implements OnInit, OnDestroy {

  @ViewChild('licenseForm') public licenseForm: NgForm;
  // @ViewChild('select') select: NgSelectComponent;

  public checkList: Checklist[] = [];
  public wizardSteps: WizardStep[] = [];
  public stateList: any[] = [];
  public natureOfLicenseList: any = [];
  public companyList: any[] = [];
  public plantList: any[] = [];
  public contractorWorkOrderList: any[] = [];
  public contractorList: any[] = [];
  public subContractorList: any[] = [];
  public subContractorWorkOrderList: any[] = [];
  public statuses: any[] = [];
  public natureOfWorkList: any[] = [];
  public verifyByList: any[] = [];
  // datepicker control config
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  // selector component config
  public contractorConfig: any;
  public subContractorConfig: any;
  public workOrderConfig: any;
  public subContractorWOConfig: any;
  public editMode = false;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  public isSubContractor = false;
  public documentTypes = [];
  public uploadedFiles: FileDoc[] = [];
  public isValidStartDate = false;

  public minDate = new Date();
  public minEndDate: Date;
  public maxEndDate: Date;

  private onDestroy: Subject<any>;
  // private license: any = {};
  logHelperService: any;
  licenceno: any;
  headerTitleName:string='';
  public tempdate = false;
  public status = false;
  public isverified = false;
  public verifiedby = false;
  public allowedFileTypes: any = '';
  public maxSizeAllowed: any = '';
  public allowedIssueDate:any='';
  globalSettings: any;
  isContractor: any = false;

  public licenseMainData: any = {};
  public licenseWorkOrderData: any = {};
  public licenseNatureOfWorkData: any[] = [];
  public licenseDocumentData: any[] = [];
  public licenseFormDataSnapshot: any;


  constructor(
    private sharedService: SharedService,
    private licenseService: LicencesService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.onDestroy = new Subject();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
  }

  ngOnInit() {
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      if (res) {
        this.globalSettings = res.Data.Table;
        this.maxSizeAllowed = Number(this.globalSettings.find(item => item.Code === 'DOCSIZELIC1').Value) * 1024;
        this.allowedFileTypes = this.globalSettings.find(item => item.Code === 'DOCFORMATLIC1').Value;
        this.allowedIssueDate = this.globalSettings.find(item => item.Code === 'MOD119PG192LICDSVSTD').Value;
      }
    });
    const roles = JSON.parse(localStorage.getItem('Role'));
    this.isContractor = roles.some(item => item.Role_Code === 119);
    if (this.checkContractorType()) {
      this.bindWizardSteps();
      setTimeout(() => {
        this.getLicense();
        this.getDocumentTypes();
        this.bindCheckList();
      }, 10);
    }

    // tslint:disable-next-line: variable-name
    // tslint:disable-next-line: prefer-const
    let roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    const userId = localStorage.getItem('UserID');
    // tslint:disable-next-line: triple-equals
    if (roleCode == 118 || roleCode == 103 || roleCode == 104 || roleCode == 101) {
      this.tempdate = true;
    }
    // tslint:disable-next-line: brace-style
    else {
      this.tempdate = false;
    }
    if (roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.licenseService.getContractorsbydefault(userId, roleCode).subscribe((response: any[]) => {
      console.log('getContractors response', response);
      this.contractorList = response;
      if (this.licenseMainData.ParentContractorVendor_Code) {
        this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.licenseMainData.ParentContractorVendor_Code);
        this.getContractorWorkOrder(this.licenseMainData.ParentContractorVendor_Code);
      }
      else{
        this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.contractorList[0].ContractorVendor_Code);
        this.getContractorWorkOrder(this.contractorList[0].ContractorVendor_Code);
      }
    });
  }

  public onCompanyChange(): void {
    this.plantList = [];
    this.licenseForm.controls['Plants'].setValue(null);
    const stateCode = this.licenseForm.controls['State_Code'].value;
    const companyCode = this.licenseForm.controls['Company'].value;
    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  public ngOnDestroy() {
    this.onDestroy.next(true);
    // window.removeEventListener('scroll', this.onScroll, true);
  }

  public onStateChange(): void {
    // this.companyList = [];
    // this.contractorWorkOrderList = [];
    // this.subContractorList = [];
    // this.subContractorWorkOrderList = [];
    // this.licenseForm.controls['ParentContractorVendor_Code'].setValue(null);
    // this.licenseForm.controls['ParentWorkOrderCode'].setValue(null);
    // if (this.isSubContractor) {
    //   this.licenseForm.controls['ContractorVendor_Code'].setValue(null);
    //   this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
    // }
    // this.licenseForm.controls['Company'].setValue(null);
    // this.licenseForm.controls['Plants'].setValue(null);
    // this.license.Company = null;
    // this.license.Plants = null;

    // const stateCode = this.licenseForm.controls['State_Code'].value;
    // if (stateCode) {
    //   this.licenseForm.controls['Company'].enable();
    //   this.licenseForm.controls['Plants'].enable();
    // } else {
    //   this.licenseForm.controls['Company'].disable();
    //   this.licenseForm.controls['Plants'].disable();
    // }
  }

  public onContractorChange(data: any): void {
    if (!data) return;
    if (this.licenseMainData.ParentContractorVendor_Code) {
      this.licenseMainData.ParentContractorVendor_Code = data.ContractorVendor_Code;
    }
    this.contractorWorkOrderList = [];
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];

    this.licenseForm.controls['ParentWorkOrderCode'].setValue(null);
    this.licenseWorkOrderData.ParentWorkOrder_Code = null;
    if (this.isSubContractor) {
      this.licenseForm.controls['ContractorVendor_Code'].setValue(null);
      this.licenseMainData.ContractorVendor_Code = null;
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
      this.licenseWorkOrderData.ChildWorkOrder_Code = null;
    }
    const contractorCode = this.licenseForm.controls['ParentContractorVendor_Code'].value;
    if (contractorCode) {
      this.getContractorWorkOrder(contractorCode);
    }
  }

  public onContractorWOChange(data: any): void {
    if (!data) return;
    if (this.licenseWorkOrderData.ParentWorkOrder_Code) {
      this.licenseWorkOrderData.ParentWorkOrder_Code = data.WorkOrder_Code;
    }
    if (this.isSubContractor) {
      this.licenseForm.controls['ContractorVendor_Code'].setValue(null);
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
      this.licenseMainData.ContractorVendor_Code = null;
      this.licenseWorkOrderData.ChildWorkOrder_Code = null;
    }
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];
    const parentWorkOrderCode = this.licenseForm.controls['ParentWorkOrderCode'].value;
    const contractorCode = this.licenseForm.controls['ParentContractorVendor_Code'].value;

    if (parentWorkOrderCode && contractorCode && this.isSubContractor) {
      this.getSubContractorsByWorkOrder(contractorCode, parentWorkOrderCode);
    }
  }

  public onSubContractorChange(data: any): void {

    if (!data) return;
    if (this.licenseMainData.ContractorVendor_Code) {
      this.licenseMainData.ContractorVendor_Code = data.ContractorVendor_Code;
    }
    this.subContractorWorkOrderList = [];
    this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
    this.licenseWorkOrderData.ChildWorkOrder_Code = null;
    const ContractorCode = this.licenseForm.controls['ParentContractorVendor_Code'].value;
    const workorderCode = this.licenseForm.controls['ParentWorkOrderCode'].value;
    const subContractorCode = this.licenseForm.controls['ContractorVendor_Code'].value;
    if (subContractorCode) {
      this.getSubContractorWorkOrder(ContractorCode, workorderCode, subContractorCode);
    }
  }

  public onSubContractorWOChange(data: any): void {
    if (!data) return;
    if (this.licenseWorkOrderData.ChildWorkOrder_Code) {
      this.licenseWorkOrderData.ChildWorkOrder_Code = data.WorkOrder_Code;
    }
  }

  public onStartDateChange(): void {
    if (this.licenseForm.controls['StartDate'].valid) {
      //this.licenseForm.controls['StartDate'].patchValue(new Date((new Date(this.licenseForm.controls['StartDate'].value)).getTime() + (60 * 60 * 24 * 1000)));
      const startDate = this.licenseForm.controls['StartDate'].value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        // this.licenseForm.controls['StartDate'].setValue('');
        if(this.allowedIssueDate == 1){
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>Please enter current date or greater than current date.</span>`,
          type: 'warning'
        };
        this.openAlert();
      }
        // return;
      }
      const licenseTypeMasterListCode = this.licenseForm.controls['LicenseTypeMasterList_Code'].value;
      this.maxEndDate = this.setEndDateByLicenceType(licenseTypeMasterListCode, startDate);
      const endDate = this.licenseForm.controls['EndDate'].value;
      // this.isValidStartDate = true;
      this.licenseForm.controls['EndDate'].enable();
      this.minEndDate = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      //this.minEndDate = startDate;
      if (startDate > endDate) {
        if (this.licenseForm.controls['EndDate'].valid) {
          this.licenseForm.controls['EndDate'].setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      this.licenseForm.controls['EndDate'].disable();
      this.licenseForm.controls['EndDate'].setValue('');
    }
  }

  public onNatureOfLicenceChange() {
    const licenseTypeMasterListCode = this.licenseForm.controls['LicenseTypeMasterList_Code'].value;
    if (licenseTypeMasterListCode) {
      this.licenseForm.controls['StartDate'].enable();
      this.licenseForm.controls['StartDate'].setValue('');
      this.licenseForm.controls['EndDate'].setValue('');
    } else {
      this.licenseForm.controls['StartDate'].disable();
      this.licenseForm.controls['EndDate'].disable();

      this.licenseForm.controls['StartDate'].setValue('');
      this.licenseForm.controls['EndDate'].setValue('');

    }
  }

  public onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  public saveAndNew(): void {
    this.onSubmit(this.licenseForm.value, true);
  }
  // save() {
  //
  //   this.onSubmit(this.licenseForm.value, true);
  //
  //   this.router.navigate(['contractor/licences']);
  //   this.clearForm();
  // }
  public updateAndNew(): void {
    this.onSubmit(this.licenseForm.value, true);
  }

  public clearForm(): void {
    this.licenseForm.reset();
  }

  public onCancel() {
    this.router.navigate(['/contractor/licences']);
  }

  public onSubmit(formValue: any, saveAndNew: boolean): void {

    console.log('form value Licesce', formValue);
    if (this.uploadedFiles.length === 0) {
      this.showAlert('error', 'Please add license documents.', 'Error!!!');
      return;
    }

    //create license
    if (!this.licenseFormDataSnapshot || !Object.keys(this.licenseFormDataSnapshot).length) {
      let licenseObj: any;
      licenseObj = { ...this.licenseMainData, ...formValue };
      licenseObj.roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
      licenseObj.CreateBy = localStorage.getItem('UserID');
      licenseObj.StartDate = new Date((new Date(licenseObj.StartDate)).getTime() + (60 * 60 * 24 * 1000));
      if (!this.isSubContractor) {
        licenseObj.ContractorVendor_Code = licenseObj.ParentContractorVendor_Code;
        licenseObj.ParentContractorVendor_Code = null;
      }
      licenseObj.DocumentImageMasterViews = [];
      const filesToUpload = this.getNotUploadedItems(this.uploadedFiles);
      licenseObj.filesToUpload = filesToUpload.length ? filesToUpload : [];
      licenseObj.NatureOfWorkList = licenseObj.NatureOfWorkList && licenseObj.NatureOfWorkList.length ? licenseObj.NatureOfWorkList : [];
      Promise.all(
        licenseObj.filesToUpload.map(async (fileDoc) => {
          const base64str = await this.fileToBase64(fileDoc['file']); // Convert file to Base64
          return { ...fileDoc, base64str }
        })
      ).then((filesArray) => {
        licenseObj.filesToUpload = filesArray;
        this.licenseService.addLicense(licenseObj).pipe(takeUntil(this.onDestroy)).subscribe(
          (response: any) => {
            if (response) {
              if (saveAndNew) {
                this.showLicenseInfo(true, false);
                this.resetFormAfterSave();
              } else {
                this.showLicenseInfo(true, true);
              }
            } else {
              this.alertOption = {
                title: 'Error!!!',
                html: `<span class='customClass'>Some Error has occured! Please try again.</span>`,
                type: 'error'
              };
              this.openAlert();
            }
          }
        );
      });
    }
    else {
      // this.licenseService.updateLicense(licenseObj).pipe(takeUntil(this.onDestroy)).subscribe(
      //   (response: any) => {
      //     if (response.Data) {

      //       // this.saveDocuments(response.Data, saveAndNew, false);
      //     } else {
            this.alertOption = {
              title: 'Error!!!',
              html: `<span class='customClass'>Some error has occured! Please try again.</span>`,
              type: 'error'
            };
            this.openAlert();
      //     }
      //   }
      // );
    }
  }

  private fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1]; // Remove data URI prefix
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file); // Read file as Base64
    });
  };

  // private saveDocuments(licenseCode: any, saveAndNew: boolean, isAdd: boolean) {
  //   console.log('saveDocuments response', licenseCode);

  //   const uploadParams = {
  //     License_Code: licenseCode
  //   };
  //   const filesToUpload = this.getNotUploadedItems(this.uploadedFiles);
  //   if (uploadParams.License_Code && filesToUpload.length > 0) {
  //     this.licenseService.uploadDocuments(filesToUpload, uploadParams)
  //       .pipe(takeUntil(this.onDestroy)).subscribe((res) => {
  //         if (saveAndNew) {
  //           this.showLicenseInfo(isAdd, false);
  //           this.resetFormAfterSave();
  //         } else {
  //           this.showLicenseInfo(isAdd, true);
  //         }
  //       });
  //   } else if (saveAndNew) {
  //     this.showLicenseInfo(isAdd, false);
  //     this.resetFormAfterSave();
  //   } else {
  //     this.showLicenseInfo(isAdd, true);
  //   }
  // }

  private showLicenseInfo(isAdd: boolean, redirect: boolean) {

    const message = isAdd ? 'License detail is successfully added.' : 'License detail is successfully updated.';
    this.alertOption = {
      title: 'Success!!!',
      html: `<span class='customClass'>${message}</span>`,
      type: 'success',
      timer: 2000
    };
    this.openAlert();
    if (redirect) {
      setTimeout(() => {
        this.router.navigate(['contractor/licences']);
      }, 3000);
    }
    this.clearForm();
  }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }

  private setEndDateByLicenceType(licenseTypeMasterListCode: number, startDate: any): Date {
    let maxDate = null;
    if (licenseTypeMasterListCode === NatureOfLicenceCode.Acknowledgment) {
      maxDate = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 1));
    } else {
      maxDate = new Date(new Date(startDate).setFullYear(new Date(startDate).getFullYear() + 1));
    }
    return maxDate;
  }

  private checkContractorType(): boolean {
    let isValidType = false;
    const id: string = this.activatedRoute.snapshot.params.id;
    if (id) {
      isValidType = true;
      this.editMode = true;
    } else {
      const type: string = this.activatedRoute.snapshot.queryParamMap.get('type');
      if (type === '1' || type === '0') {
        this.isSubContractor = type === '0';
        isValidType = true;
      }
    }
    return isValidType;
  }

  private bindWizardSteps() {
    this.wizardSteps = [
      {
        id: 'licenseDetails',
        name: 'License Info',
        isValid: () => {
          // return true;
          return this.wizard1Validation();
        },
      },
      {
        id: 'documentDetails',
        name: 'Documents',
        isValid: () => { return true; }
      }
    ];
  }
  // tslint:disable-next-line: function-name
  CheckLicenceNo() {

    // tslint:disable-next-line: max-line-length
    const id = this.activatedRoute.snapshot.params.id;
    this.licenseService.checkLicenseNoAvailability(this.licenseForm.controls.LicenseNo.value, id).subscribe((res: any) => {

      this.licenceno = res.Data;
      if (this.licenceno > 0)
      // tslint:disable-next-line: brace-style
      {
        this.alertOption = {
          title: 'Error!!!',
          html: `<span class='customClass'>That License no already exists.  Enter correct value or different.</span>`,
          type: 'error'
        };
        this.openAlert();
        this.licenseForm.controls['LicenseNo'].reset();
      }
    });
    return true;
  }
  private wizard1Validation(): boolean {
    let flag = false;
    if (this.licenseForm.controls.State_Code) {
      flag = this.licenseForm.controls.State_Code.valid
        && this.licenseForm.controls.LicenseTypeMasterList_Code.valid
        && this.licenseForm.controls.Company.valid
        && this.licenseForm.controls.Plants.valid
        && this.licenseForm.controls.ParentContractorVendor_Code.valid
        && this.licenseForm.controls.ParentWorkOrderCode.valid
        && this.licenseForm.controls.LicenseNo.valid
        && this.licenseForm.controls.Person.valid
        && this.licenseForm.controls.StartDate.valid
        && this.licenseForm.controls.EndDate.valid
        && this.licenseForm.controls.NatureOfWorkList.valid
        && this.licenseForm.controls.LinNumber.valid;

      if (flag) {
        flag = this.checkValidEndDate();
      }

      if (flag && this.isSubContractor) {
        flag = this.licenseForm.controls.ContractorVendor_Code.valid
          && this.licenseForm.controls.ChildWorkOrderCode.valid;
      }
    }
    return flag;
  }

  private checkValidEndDate() {
    if (this.licenseForm.controls.StartDate.value !== '' && this.licenseForm.controls.EndDate.value !== '') {
      const startDate: Date = new Date(this.licenseForm.controls.StartDate.value);
      const endDate: Date = new Date(this.licenseForm.controls.EndDate.value);
      if (startDate > endDate) {
        return false;
      }
    }
    return true;
  }

  private getLicense() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.licenseService.getLicenseByLicenseCode(id).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
        if (Object.keys(response).length > 0) {
          this.licenseMainData = response['Table1'][0];
          this.licenseWorkOrderData = response['Table2'][0];
          this.licenseNatureOfWorkData = response['Table3'];
          this.licenseDocumentData = response['Table4'];
          if (this.licenseMainData.ParentContractorVendor_Code) {
            this.isSubContractor = true;
          } else {
            this.licenseMainData.ParentContractorVendor_Code = this.licenseMainData.ContractorVendor_Code;
            this.licenseMainData.ContractorVendor_Code = null;
          }

          this.licenseFormDataSnapshot = {
            licenseMainData: this.licenseMainData,
            licenseWorkOrderData: this.licenseWorkOrderData,
            licenseNatureOfWorkData: this.licenseNatureOfWorkData,
            licenseDocumentData: this.licenseDocumentData,
          }

          this.getAllData();
          this.setControlValue(true);
        }
      });
    } else {
      this.licenseFormDataSnapshot = null;
      this.setDefaultLicenseObj();
      this.setControlValue(false);
      this.getAllData();
    }
  }

  private setDefaultLicenseObj() {
    this.licenseMainData = {
      ParentContractorVendor_Code: null,
      ContractorVendor_Code: null,
      Plants: null,
      SatusMasterList_Code: null,
      VerifyBy: null,
    };

    this.licenseWorkOrderData = {
      ParentWorkOrder_Code: null,
      ChildWorkOrder_Code: null,
    }

    this.licenseDocumentData = null;
    this.licenseNatureOfWorkData = null;
  }

  private setControlValue(isEdit: boolean): void {
    //patch document & preview document code
    this.uploadedFiles = [];
    if (this.licenseDocumentData && this.licenseDocumentData.length) {
      this.licenseDocumentData.forEach((fileConfig) => {
        this.uploadedFiles.push({
          ID: fileConfig.ID,
          file: {
            lastModified: fileConfig.ModifiedDate,
            name: fileConfig.UploadFileName
          },
          documentTypeCode: fileConfig.DocumentTypeMasterList_Code,
          isUploaded: true,
          url: this.constructDocumentPreviewPath(fileConfig)
        });
      });
    }

    this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.licenseMainData.ParentContractorVendor_Code);
    this.licenseForm.controls['ParentWorkOrderCode'].setValue(this.licenseWorkOrderData.ParentWorkOrder_Code);
    if (this.licenseForm.controls['ContractorVendor_Code']) {
      this.licenseForm.controls['ContractorVendor_Code'].setValue(this.licenseMainData.ContractorVendor_Code);
    }
    if (this.licenseForm.controls['ChildWorkOrderCode']) {
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(this.licenseWorkOrderData.ChildWorkOrder_Code);
    }
    this.licenseForm.controls['LicenseNo'].setValue(this.licenseMainData.LicenseNo);
    this.headerTitleName = this.licenseMainData.LicenseNo;
    this.licenseForm.controls['Person'].setValue(this.licenseMainData.Person);
    this.licenseForm.controls['StartDate'].setValue(this.licenseMainData.StartDate);
    this.licenseForm.controls['EndDate'].setValue(this.licenseMainData.EndDate);
    this.licenseForm.controls['LinNumber'].setValue(this.licenseMainData.LinNumber);
    this.licenseForm.controls['ExtensionDate'].setValue(this.licenseMainData.ExtensionDate);
    this.licenseForm.controls['VerifyDate'].setValue(this.licenseMainData.VerifyDate);
    // this.licenseForm.controls['VerifyBy'].setValue(this.license.VerifyBy);
    this.licenseForm.controls['Comment'].setValue(this.licenseMainData.Comment);
    if (isEdit) {
      this.isValidStartDate = true;
      this.licenseForm.controls['EndDate'].enable();
      this.minEndDate = new Date((new Date(this.licenseMainData.StartDate)).getTime() + (60 * 60 * 24 * 1000));
      // this.maxEndDate = this.setEndDateByLicenceType(this.license.LicenseTypeMasterList_Code, this.license.StartDate);
    }
  }

    private getAllData() {
    this.getStates();
    this.getCompanies();
    this.getNatureOfLicense();
    this.getStatus();
    this.getNatureOfWorks();
    this.getVerifyByList();
  }

  private getStates(): void {
    this.licenseService.getState().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getState response', response);
      this.stateList = response;
      this.licenseForm.controls['State_Code'].setValue(this.licenseMainData.State_Code);
    });
  }

  private getCompanies(): void {
    this.licenseService.getCompanies().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.companyList = response;
      if (!isNullOrUndefined(this.licenseMainData.Company_Code)) {
        this.licenseForm.controls['Company'].setValue(this.licenseMainData.Company_Code);
        this.getPlants(this.licenseMainData.Company_Code);
      } else {
        this.licenseForm.controls['Company'].setValue(null);
        if (this.companyList.length === 1) {
          this.licenseForm.controls['Company'].setValue(this.companyList[0].Company_Code);
          this.getPlants(this.companyList[0].Company_Code);
        }
      }
    });
  }

  getContractors() {

    this.licenseService.getContractors(false).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorList = response;
      if (this.licenseMainData.ParentContractorVendor_Code) {
        this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.licenseMainData.ParentContractorVendor_Code);
        this.getContractorWorkOrder(this.licenseMainData.ParentContractorVendor_Code);
      }
      else {
        if (this.contractorList.length === 1) {
          this.licenseForm.controls['ParentContractorVendor_Code'].patchValue(this.contractorList[0].ContractorVendor_Code);
          this.getContractorWorkOrder(this.contractorList[0].ContractorVendor_Code);
        }
      }
    });
  }

  public getNatureOfLicense(): void {
    this.licenseService.getMasterItemListByCode(MasterItemCode.NATURE_OF_LICENSE).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any[]) => {
        this.natureOfLicenseList = response;
        if (this.licenseMainData.LicenseTypeMasterList_Code) {
          this.licenseForm.controls['LicenseTypeMasterList_Code'].setValue(this.licenseMainData.LicenseTypeMasterList_Code);
        }
        else {
          if (this.natureOfLicenseList.length === 1)
            this.licenseForm.controls['LicenseTypeMasterList_Code'].patchValue(this.natureOfLicenseList[0].MasterItem_Code);
        }
      });
  }

  private getStatus(): void {
    this.licenseService.getMasterItemListByCode(MasterItemCode.STATUS_CODE).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any[]) => {
        this.statuses = response;
        this.licenseForm.controls['SatusMasterList_Code'].setValue(this.licenseMainData.SatusMasterList_Code);
      });
  }

  private getNatureOfWorks(): void {
    this.licenseService.getNatureOfWorks().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getNatureOfWorks response', response);
      this.natureOfWorkList = response;
      if(this.licenseNatureOfWorkData.length){
        this.licenseForm.controls['NatureOfWorkList'].setValue(this.licenseNatureOfWorkData.map((e) => e.NatureOfWork_Code));
      }
    });
  }

  private getSubContractorsByWorkOrder(contractorCode, workorderCode): void {

    this.licenseService.getContractors(true,contractorCode,workorderCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {

      this.subContractorList = response;
      if(this.licenseMainData.ContractorVendor_Code){
        this.licenseForm.controls['ContractorVendor_Code'].setValue(this.licenseMainData.ContractorVendor_Code);
        this.getSubContractorWorkOrder(contractorCode, workorderCode, this.licenseMainData.ContractorVendor_Code);
      }
      else{
        if(this.subContractorList.length === 1){
        this.licenseForm.controls['ContractorVendor_Code'].patchValue(this.subContractorList[0].ContractorVendor_Code);
        this.getSubContractorWorkOrder(contractorCode, workorderCode, this.licenseMainData.ContractorVendor_Code);
        }
      }
    });
  }

  private getPlants(companyCode: any): void {
    this.licenseService.getPlants(companyCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.plantList = response;
      if (this.licenseMainData.Plants) {
        this.licenseForm.controls['Plants'].setValue(this.licenseMainData.Plants);
      }
      else{
        if(this.plantList.length === 1){
        this.licenseForm.controls['Plants'].patchValue(this.plantList[0].Plant_Code);
        }
      }
    });
  }

  private getContractorWorkOrder(contractorCode: number): void {

    this.licenseService.getWorkOrderFilterData(contractorCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorWorkOrderList = response;
      console.log(" ~ LicencesFormComponent ~ this.licenseService.getWorkOrderFilterData ~ this.licenseWorkOrderData.ParentWorkOrder_Code:", this.licenseWorkOrderData.ParentWorkOrder_Code)
      if(this.licenseWorkOrderData.ParentWorkOrder_Code){
        this.licenseForm.controls['ParentWorkOrderCode'].setValue(this.licenseWorkOrderData.ParentWorkOrder_Code);
        if(this.isSubContractor){
          this.getSubContractorsByWorkOrder(contractorCode, this.licenseWorkOrderData.ParentWorkOrder_Code);
        }
      }
      else{
        if(this.contractorWorkOrderList.length === 1){
          this.licenseForm.controls['ParentWorkOrderCode'].patchValue(this.contractorWorkOrderList[0].WorkOrder_Code);
          if(this.isSubContractor){
            this.getSubContractorsByWorkOrder(contractorCode,this.contractorWorkOrderList[0].WorkOrder_Code);
          }
        }
      }
    });
  }

  private getSubContractorWorkOrder(contractorCode, workorderCode, subContractorCode: number): void {
    this.licenseService.getWorkOrderFilterDataForSubWorkorder(contractorCode, subContractorCode, workorderCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.subContractorWorkOrderList = response;
      if (this.licenseWorkOrderData.ChildWorkOrder_Code) {
        this.licenseForm.controls['ChildWorkOrderCode'].setValue(this.licenseWorkOrderData.ChildWorkOrder_Code);
      }
      else {
        if (this.subContractorWorkOrderList.length === 1) {
          this.licenseForm.controls['ChildWorkOrderCode'].patchValue(this.subContractorWorkOrderList[0].WorkOrder_Code);
        }
      }
    });
  }

  private getVerifyByList(): void {
    this.licenseService.getVerifyByList().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getState response', response);
      this.verifyByList = response;
      this.licenseForm.controls['VerifyBy'].setValue(this.licenseMainData.VerifyBy ? this.licenseMainData.VerifyBy.toString() : null);
    });
  }

  private bindCheckList() {
    this.checkList.push(
      {
        id: 1,
        text: 'Licenses Info',
        open: true,
        childrens: [
          {
            text: 'State',
            title: 'State',
            desc: 'State is required.',
            isValid: () => {
              return (this.licenseForm.controls.State_Code.valid);
            },
            hasError: () => {
              return ((this.licenseForm.controls.State_Code.dirty || this.licenseForm.controls.State_Code.touched)
                && this.licenseForm.controls.State_Code.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Company & Plant',
            title: 'Company & Plant',
            desc: 'Company and Plant is required.',
            isValid: () => {
              return (this.licenseForm.controls.Company.valid
                && this.licenseForm.controls.Plants.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.Company.dirty
                || this.licenseForm.controls.Company.touched)
                && this.licenseForm.controls.Company.invalid)
                || ((this.licenseForm.controls.Plants.dirty
                  || this.licenseForm.controls.Plants.touched)
                  && this.licenseForm.controls.Plants.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Contractor Details',
            title: 'Contractor Details',
            desc: 'Contractor and Work Order is required.',
            isValid: () => {
              return (this.licenseForm.controls.ParentContractorVendor_Code.valid
                && this.licenseForm.controls.ParentWorkOrderCode.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.ParentContractorVendor_Code.dirty
                || this.licenseForm.controls.ParentContractorVendor_Code.touched)
                && this.licenseForm.controls.ParentContractorVendor_Code.invalid)
                || ((this.licenseForm.controls.ParentWorkOrderCode.dirty
                  || this.licenseForm.controls.ParentWorkOrderCode.touched)
                  && this.licenseForm.controls.ParentWorkOrderCode.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'License Details',
            title: 'License Details',
            desc: 'License No, Nature of license is required.',
            isValid: () => {
              return (this.licenseForm.controls.LicenseNo.valid
                && this.licenseForm.controls.LicenseTypeMasterList_Code.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.LicenseNo.dirty || this.licenseForm.controls.LicenseNo.touched)
                && this.licenseForm.controls.LicenseNo.invalid)
                || ((this.licenseForm.controls.LicenseTypeMasterList_Code.dirty
                  || this.licenseForm.controls.LicenseTypeMasterList_Code.touched)
                  && this.licenseForm.controls.LicenseTypeMasterList_Code.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'License Validity',
            title: 'License Validity',
            desc: 'Issue Date and Validity Date is required.',
            isValid: () => {
              return (this.licenseForm.controls.StartDate.valid
                && this.licenseForm.controls.EndDate.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.StartDate.dirty || this.licenseForm.controls.StartDate.touched)
                && this.licenseForm.controls.StartDate.invalid)
                || ((this.licenseForm.controls.EndDate.dirty || this.licenseForm.controls.EndDate.touched)
                  && this.licenseForm.controls.EndDate.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Maximum Associates',
            title: 'Maximum Associates',
            desc: 'Maximum Associates is required.',
            isValid: () => {
              return (this.licenseForm.controls.Person.valid);
            },
            hasError: () => {
              return ((this.licenseForm.controls.Person.dirty
                || this.licenseForm.controls.Person.touched)
                && this.licenseForm.controls.Person.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },

        ]
      },
      {
        id: 2,
        text: 'Documents',
        open: true,
        childrens: [
          {
            text: 'License Documents',
            title: 'License Documents',
            desc: 'License Document is required.',
            isValid: () => {
              return (this.uploadedFiles.length > 0);
            },
            hasError: () => {
              return (this.uploadedFiles.length === 0);
            },
            isMandatory: true,
            isVisible: true,
            step: '2',
          }
        ]
      }
    );
    if (this.isSubContractor) {
      const subContractorhekListObj = {
        text: 'Sub Contractor Details',
        title: 'Sub Contractor Details',
        desc: 'Sub Contractor and Sub-Work Order is required.',
        isValid: () => {
          return (this.licenseForm.controls.ContractorVendor_Code.valid
            && this.licenseForm.controls.ChildWorkOrderCode.valid);
        },
        hasError: () => {
          return (((this.licenseForm.controls.ContractorVendor_Code.dirty
            || this.licenseForm.controls.ContractorVendor_Code.touched)
            && this.licenseForm.controls.ContractorVendor_Code.invalid)
            || ((this.licenseForm.controls.ChildWorkOrderCode.dirty
              || this.licenseForm.controls.ChildWorkOrderCode.touched)
              && this.licenseForm.controls.ChildWorkOrderCode.invalid));
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(3, 0, subContractorhekListObj);
    }
  }

  private getDocumentTypes() {
    const res = {
      "Page_Code": 192,
      "RequireType": 1,
      "ActionType": 0,
      "SelectedField": "Name,DocumentType_Code,page_code",
      "Search": null,
      "UserId": GLOBAL.USER_ID
    }
    this.licenseService.getDocumentTypesPageModuleWise(res).pipe(takeUntil(this.onDestroy)).subscribe((docTypes: any[]) => {
      this.documentTypes = docTypes;
    });
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  private resetFormAfterSave() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      const isDirect = this.licenseMainData.IsDirect;
      setTimeout(() => {
        if (isDirect) {
          this.router.navigate(['/contractor/add-licences/'], { queryParams: { type: '1' } });
        } else {
          this.router.navigate(['/contractor/add-licences/'], { queryParams: { type: '0' } });
        }
      }, 500);
    }

    this.licenseForm.reset();
    this.setDefaultLicenseObj();
    this.setControlValue(false);
  }

  constructDocumentPreviewPath(file: any) {
    const url = GLOBAL.BASE_URL_DOC;
    if (file.ParentRootFolder && file.UploadFileName) {
      return url + "\\" + file.ParentRootFolder + file.UploadFileName;
    }
    return url;
  }
}
