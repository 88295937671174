import { Component, OnInit } from '@angular/core';
import { GLOBAL, ReportBuilderAPI } from 'src/app/app.globals';
import { ReportBuilderService } from './report-builder.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { PivotConfig } from 'src/app/core/components';
import { GridApi, GridOptions } from 'ag-grid-community';
import { AgParamsFormComponent } from './ag-helper/ag-params-form/ag-params-form.component';
import { AgExpandRowComponent } from './ag-helper/ag-expand-row/ag-expand-row.component';
import { AgSelectApiComponent } from './ag-helper/ag-select-api/ag-select-api.component';

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.css']
})
export class ReportBuilderComponent implements OnInit {

  // apiList = GLOBAL.REPORT_BUILDER_APIS;

  dataset = [];
  reportConfig: PivotConfig;
  showAPISelection = true;
  expandRowIndex: number;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  columnDefs = [
    {
      headerName: '', field: 'Selected', width: 20,
      sortable: false, suppressMenu: true,
      cellRendererFramework: AgSelectApiComponent
    },
    {
      headerName: '#', field: '', width: 30,
      sortable: false, suppressMenu: true,
      cellRendererFramework: AgExpandRowComponent
    },
    {
      headerName: 'Description', field: 'Description',
      sortable: false, filter: true, suppressMenu: true
    },
    {
      headerName: 'Primary Key', field: 'PrimaryKeyName',
      sortable: false, filter: true, suppressMenu: true
    },
    // {
    //   headerName: '', field: '', width: 40,
    //   sortable: false, suppressMenu: true,
    //   cellRendererFramework: AgSaveCellRendererComponent,
    //   cellRendererParams: {
    //     isDisable: (params) => {
    //       return ((params.data.FShift_Code !== -2 || params.data.FShift_Code !== -1)
    //         && (!moment(params.data.FSInPunch).isValid() || !moment(params.data.FSOutPunch).isValid()))
    //         || (params.data.SShift_Code !== -2
    //           && (!moment(params.data.SSInPunch).isValid() || !moment(params.data.SSOutPunch).isValid()));
    //     }
    //   }
    // }
  ];
  rowData = [];

  constructor(
    private reportBuilderService: ReportBuilderService,
    private logHelperService: LogHelperService
  ) {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 200;
        }
        return 45;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: false,
      headerHeight: 40,
      frameworkComponents: { fullWidthCellRenderer: AgParamsFormComponent },
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      fullWidthCellRenderer: 'fullWidthCellRenderer',
      fullWidthCellRendererParams: {
        isDisable: (params) => {
          // if (params.data.rowData) {
          //   return params.data.rowData.FShift_Code === -2 || params.data.rowData.FShift_Code === -1;
          // }
          // return false;
        },
        punchDetailsUpdated: (punchData) => {
          // this.punchData = punchData;
        }
      }
    };
  }

  prepareRowData(apis) {
    const temp = [];
    for (let index = 0; index < apis.length; index++) {
      const api = apis[index];
      temp.push(api);
      api.fullWidth = true;
      api.expanded = false;
      temp.push(api);
    }
    return temp;
  }

  ngOnInit() {
    this.reportBuilderService.getAPIList().subscribe((response: ReportBuilderAPI[]) => {
      this.rowData = response;
    });
    // this.rowData = GLOBAL.REPORT_BUILDER_APIS;
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  getDataForAPI(api: ReportBuilderAPI) {
    const promise = new Promise((resolve) => {
      try {
        this.reportBuilderService.getDataForAPI(api).subscribe((res: any) => {
          resolve(res.Data.Table);
        });
      } catch (error) {
        resolve(error);
      }
    });
    return promise;
  }

  async fetchData(acc) {
    this.dataset = [];
    await this.asyncForEach(this.rowData, async (api) => {
      if (api.Selected) {
        await this.getDataForAPI(api).then((data: []) => {
          this.dataset = this.dataset.concat(data);
          // api.Selected = false;
        });
      }
    });
    console.log('Done');
    console.log(this.dataset.length);
    this.showAPISelection = false;
  }

  onUpdateReport(newConfig: PivotConfig) {
    this.reportConfig = newConfig;
    console.log(this.reportConfig);
  }

  saveReport() {
    const selectedAPIs = [];
    for (let index = 0; index < this.rowData.length; index++) {
      const api = this.rowData[index];
      if (api.Selected) {
        selectedAPIs.push(api);
      }
    }
    const reqParam = {
      selectedAPIs,
      reportConfig: this.reportConfig,
      reportDescription: 'Contractor vs Labours'
    };
    console.log(JSON.stringify(reqParam));
  }

  expandRow(rowData, rowIndex) {

    if (this.expandRowIndex === rowIndex) {
      // delete row
      const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.expandRowIndex);
      expandedRow.data.expanded = false;
      this.gridAPI.updateRowData({ update: [expandedRow.data] });
      const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.expandRowIndex + 1);
      this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      this.expandRowIndex = undefined;
    } else {
      // First close previously opend row index
      if (this.expandRowIndex !== undefined) {
        const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.expandRowIndex);
        expandedRow.data.expanded = false;
        this.gridAPI.updateRowData({ update: [expandedRow.data] });
        const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.expandRowIndex + 1);
        this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      }
      // add row
      this.expandRowIndex = rowIndex;
      this.gridAPI.updateRowData({
        add: [{
          rowData,
          fullWidth: true
        }],
        addIndex: rowIndex + 1
      });
    }
  }

}
