import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { CoreModule } from '../core/core.module';
import { ReportBuilderService } from './report-builder/report-builder.service';
import { AgParamsFormComponent } from './report-builder/ag-helper/ag-params-form/ag-params-form.component';
import { AgExpandRowComponent } from './report-builder/ag-helper/ag-expand-row/ag-expand-row.component';
import { AgSelectApiComponent } from './report-builder/ag-helper/ag-select-api/ag-select-api.component';
import { DatePickerComponent } from './report-builder/ag-helper/ag-params-form/date-picker/date-picker.component';
@NgModule({
  exports: [
    ReportBuilderComponent
  ],
  declarations: [
    ReportBuilderComponent,
    AgParamsFormComponent,
    AgExpandRowComponent,
    AgSelectApiComponent,
    DatePickerComponent
  ],
  entryComponents: [
    AgParamsFormComponent,
    AgExpandRowComponent,
    AgSelectApiComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    CoreModule.forRoot()
  ],
  providers: [ReportBuilderService]
})
export class ReportModule { }
