import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'formatFileSize'
})
@Injectable()
export class FormatFileSize implements PipeTransform {
  transform(value: string | number, formatType = '', showFormatType = false): string {
    if (+value == 0) return '0' + formatType ? ' Bytes' : '';
    const k = 1024;
    const decimals = 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    if (formatType === 'MB') return ((+value / (k * k)).toFixed(decimals)).toString() + (showFormatType ? ' MB' : '');

    const i = Math.floor(Math.log(+value) / Math.log(k));
    return parseFloat((+value / Math.pow(k, i)).toFixed(decimals)) + (showFormatType ? ' ' + sizes[i] : '');
  }
}