import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-advance-filter-type-condition',
  templateUrl: './advance-filter-type-condition.component.html',
  styleUrls: ['./advance-filter-type-condition.component.css']
})
export class AdvanceFilterTypeConditionComponent implements OnInit {
  public advanceFilterForm: FormGroup;
  public isFilterCondition: boolean;
  public isFilterType: boolean;
  constructor(private formBuilder: FormBuilder) {
    this.isFilterCondition = false;
    this.isFilterType = false;
  }

  ngOnInit() {
    this.advanceFilterForm = this.formBuilder.group({
      filter: ['', Validators.required],
    });
    this.advanceFilterForm.patchValue({
      filter: 'FILTER_CONDITION'
    });
    this.isFilterCondition = true;
    this.advanceFilterForm.get('filter').valueChanges.subscribe((value: string) => {
      if (value === 'FILTER_CONDITION') {
        this.isFilterCondition = true;
        this.isFilterType = false;
      } else {
        this.isFilterCondition = false;
        this.isFilterType = true;
      }
    });
  }
}
