import { TemplateRef } from '@angular/core';

export class ReportFilterData {
  id: number;
  filterType: string;
  templateRef: TemplateRef<any>;
  data: any;
}

export enum FilterTypeEnum {
  ContractorFilter = 1,
  SubContractorFilter = 2
}
