import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CellClickedEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import { EditEmailComponent } from 'src/app/core/components/edit-email/edit-email.component';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import {
  AgActionCellRendererComponent,
  AgNumberCountComponent,
} from '../../core/components';
import { ContractorDetailsComponent } from './contractor-details/contractor-details.component';
import { ContractorsService } from './contractors.service';

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

declare var $;
const dateFormat = 'DD-MM-YYYY';
const date1 = new Date();
date1.setDate(date1.getDate() - 1);
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})

export class ContractorsComponent implements OnInit {
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  private modalRef: NgbModalRef;
  forContractorLogin = true;
  leaveContractor = false;
  hidesubgrid = false;
  leavetype = [];
  states:any = []
  datePickerPickerMode: string;
  datePickerPlaceHolder: string;
  public contractorcode: number;
  leaveform: FormGroup;
  public get leaveTypeFormArray(): any {
    return this.leaveform.get('leaveTypeFormArray');
  }
  leavetypes = [];
  isChecked = [];
  optionsChecked = [];
  statusChecked = [];
  public contractor: [];
  public leave: [];
  identityDetails = [];
  final = [];
  public disablesavebutton = false;
  hidevaliditydate = false;
  public leavedatatable: any;
  public checkapplicability: any[] = [];
  public flag: boolean;
  public closeModal: EventEmitter<any>;
  @Input() public globalSettings: any[];
  public isHidePOInput: boolean = true;

  // tslint:disable-next-line: member-ordering
  //this.quickFilterParser = (quickFilter) => quickFilter.split(',');
  contractorType = 1;
  columnDefs: any = [
    {
      headerName: '#', field: '', cellStyle: { textAlign: 'left' }, width: 10,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'CCN', field: 'ContractorVendor_Code', cellStyle: { textAlign: 'left' }, width: 20,
      //sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Company', field: 'Company', width: 100, cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.data.Company,
      sortable: true, filter: true,
      suppressMenu: false, cellStyle: { textAlign: 'left' }, headerClass:'text-left',
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => { });
        }
      },
    },
    {
      headerName: 'Vendor Code', field: 'ShortCode', width: 50, cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => "Copy to Vendor Code",
      sortable: true, filter: true,
      suppressMenu: false,
      cellStyle: { textAlign: 'center' }, headerClass:'align-center',
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => { });
        }
      },
    },
    {
      headerName: 'PAN No',  headerClass: 'panCen text-center', field: 'PANNo', width: 30, cellRenderer: function (params) { return '<span class="hover-shadow"> ' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => "Copy to PAN No",
      sortable: true, filter: true,
      suppressMenu: false,
      cellStyle: { textAlign: 'center' },
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => { });
        }
      },
    },
    {
      headerName: 'EmailID', field: 'EmailID', width: 100, cellRenderer: function (params) { return '<span class="hover-shadow"> ' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltipValueGetter: params => 'Copy to EmailID',
      sortable: true, filter: true,
      suppressMenu: false,
      cellStyle: { textAlign: 'left' }, headerClass:'text-left',
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => { });
        }
      },
    },
    {
      headerName: 'Status', field: 'Status', cellStyle: { textAlign: 'left' }, width: 100, hide: this.isHidePOInput,
      //sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },
      cellRenderer: ({ data }) => {
        if (!data.Status) {
          return null;
        }
        if (data.Status === "Deactivate") {
          return `<span class="badge badge-danger">${data.Status}</span>`;
        }
        else if (data.Status === "Active")
          return `<span class="badge badge-success">${data.Status}</span>`;
      }
    },
    {
      headerName: 'Action', field: '',  headerClass: 'text-center', cellStyle: { textAlign: 'center' }, width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent,
      cellRendererParams: { isPrintButton: false, isEmail: false, isDetailsShow: true },
    }
  ];
  gridAPI: any;
  rowData = [];
  public isFormSubmitted: boolean;

  constructor(
    private router: Router,
    public loadingService: Ng4LoadingSpinnerService,
    private contractorsService: ContractorsService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
    public sharedService: SharedService,
    //private clipboard: Clipboard,
    private fb: FormBuilder,
    public datepipe: DatePipe) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.closeModal = new EventEmitter();

    this.leaveform = this.fb.group({
      ContractorVendor_Code: [null],
      leaveTypeFormArray: this.fb.array([])
    });
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      this.sharedService.setGlobalSettings(res);
      if (res) {
        this.globalSettings = res.Data.Table;
        const ContractorSetting = this.globalSettings.find(item => item.Code === 'MOD52PG195REQCONSTATUS');
        this.columnDefs[this.columnDefs.length - 1].cellRendererParams.isEmail = this.getIsAvailableRole('MOD52PG195REQVERROLL');
        if (ContractorSetting && ContractorSetting.Value === '1') {
          this.isHidePOInput = false;
          this.columnDefs[this.columnDefs.length - 2].hide = false;
          this.gridAPI.columnApi.setColumnsVisible(['Status'], true);
        }
        else if (ContractorSetting && ContractorSetting.Value === '0') {
          this.isHidePOInput = true;
        }
        this.gridAPI.api.setColumnDefs(this.columnDefs);
       // this.gridAPI.api.isQuickFilterPresent();
       // this.gridAPI.api.setGridOption('quickFilterText', 'new filter text');

      }
    });
  }
  ngOnInit() {
    new Promise((resolve, reject) => {
      this.contractorsService.getStatesByCountryId(100)
        .subscribe((response: any) => {
          this.states = response;
          resolve(0);
        },
          error => {
            reject();
          });
    });
    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119)
    // tslint:disable-next-line: brace-style
    {
      this.forContractorLogin = false;
      this.leaveContractor = false;
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.forContractorLogin = true;
      this.leaveContractor = true;
    }
    this.getContractorList(this.contractorType);
    this.getLeaveType();
    this.getContractor();
  }
  getIsAvailableRole(data: any) {
    const list = this.globalSettings.find(item => item.Code === data);
    const list2 = list ? this.globalSettings.find(item => item.Code === data).Value : null;
    const roles = JSON.parse(localStorage.getItem('Role'));
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);
    return list2 ? roleList.some(item => list2.includes(item)) : false;
  }

  getContractorList(contractorType: number) {
    this.contractorsService.getAllContractors(contractorType).subscribe((response: any) => {
      if (response) {
        this.rowData = response;
      }
    });
  }

  onTabChange(event) {
    this.getContractorList(event.nextId);
  }

  editContractor(rowData) {
    this.router.navigate([`/edit-sub-contractor/${rowData.ContractorVendor_Code}`]);
  }

  gridReady(data) {
    this.gridAPI = data;
  }
  emailContractor(rowData) {
    const modalRef = this.modalService.open(EditEmailComponent, {
      backdrop: 'static'
    });
    modalRef.componentInstance.conData = rowData;
    modalRef.componentInstance.handleClose.subscribe((res: any) => {
      if (res) {
        this.contractorsService.updateEmailID(res).subscribe((res: any) => {
          let ind = this.rowData.findIndex(rest => rest.$id === rowData.$id);
          this.rowData[ind].EmailID = res.NewEmailID;
        });
      }
      modalRef.close('')
    });
  }
  viewContractor(rowData) {
    const modalRef = this.modalService.open(ContractorDetailsComponent, {
      backdrop: 'static'
    });
    modalRef.componentInstance.conData = rowData;
    modalRef.componentInstance.handleClose.subscribe((res: any) => {
      if (res) {
        //
      }
      modalRef.close('')
    });
  }

  public openContractorLeaveConfiguration(): void {
    const modalRef = this.modalService.open(this.templateRef, { size: 'lg', backdrop: 'static', keyboard: false });
    this.leaveform.reset();
    this.hidesubgrid = false;
    this.disablesavebutton = false;
    this.optionsChecked = [];
    this.statusChecked = [];
  }

  private buildForm(dataObj: any, isDisabled?: boolean): FormGroup {
    return this.fb.group({
      isChecked: [false],
      LeaveType_Code: [dataObj.LeaveType_Code],
      ApplicableDate: [''],
      AllowLeave: [{ value: null, disabled: isDisabled }, [Validators.maxLength(4)]],
    });
  }

  public onCloseAction(): void {
    this.closeModal.next('click');
    this.modalService.dismissAll(this.templateRef);
    this.optionsChecked = [];
    this.statusChecked = [];
  }

  public getLeaveType(): void {
    this.contractorsService.getLeaveTypeData().subscribe((response: any) => {
      this.leavetypes = response;
      this.setControlValues(this.leavetypes);
    });
  }

  private setControlValues(leaveTypes: any[]): void {
    const control = this.leaveform.get('leaveTypeFormArray') as FormArray;
    control.controls = [];
    for (const leaveType of leaveTypes) {
      const formGroup: any = this.buildForm(leaveType);
      control.push(formGroup);
    }
  }

  public getLeaveTypes(): void {
    this.contractorsService.getLeaveTypes(this.contractorcode).subscribe((response: any) => {
      this.leave = response;
      if (response.length > 0) {
        this.hidesubgrid = true;
      } else {
        this.hidesubgrid = false;
      }
    });
  }

  public getContractor(): void {
    this.contractorsService.getContractor().subscribe((response: any) => {
      this.contractor = response;
    });
  }

  onCheckedLeave(leavetype: any, index: number, event) {
    const control = this.leaveTypeFormArray.controls[index];
    if (control.controls.isChecked.value) {
      control.controls.ApplicableDate.setValidators([Validators.required]);
      control.controls.ApplicableDate.updateValueAndValidity();
      control.controls.AllowLeave.setValidators([Validators.required, Validators.maxLength(4)]);
      control.controls.AllowLeave.updateValueAndValidity();
    } else {
      control.controls.ApplicableDate.setValue('');
      control.controls.AllowLeave.setValue('');

      control.controls.ApplicableDate.clearValidators();
      control.controls.ApplicableDate.updateValueAndValidity();
      control.controls.AllowLeave.clearValidators();
      control.controls.AllowLeave.updateValueAndValidity();
    }
  }

  // tslint:disable-next-line: function-name
  IsCheckLeaveStatus(leavetype, event) {
    this.leavetypes[leavetype] = event.target.checked;
    if (this.leavetypes[leavetype] === true) {
      if (this.statusChecked.indexOf(leavetype.LeaveType_Code) === -1) {
        this.statusChecked.push(leavetype.LeaveType_Code);
        this.hidevaliditydate = true;
        // tslint:disable-next-line: no-parameter-reassignment
        leavetype.statusChecked = this.statusChecked;
      } else {
        this.statusChecked.splice(this.statusChecked.indexOf(leavetype.LeaveType_Code), 1);
        // tslint:disable-next-line: no-parameter-reassignment
        leavetype.statusChecked = this.statusChecked;
      }
    } else {
      this.statusChecked.splice(this.statusChecked.indexOf(leavetype.LeaveType_Code), 1);
      this.hidevaliditydate = false;
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.statusChecked = this.statusChecked;
    }
  }

  // tslint:disable-next-line: function-name
  IsContrcatorSelected(event) {
    this.leaveform.get('leaveTypeFormArray').reset();
    if (event === undefined) {
      this.disablesavebutton = false;
    } else {
      if (event.ContractorVendor_Code !== null) {
        this.disablesavebutton = true;
        this.contractorcode = event.ContractorVendor_Code;
      } else {
        this.disablesavebutton = false;
        this.contractorcode = event.ContractorVendor_Code;
      }
    }
    this.getLeaveTypes();
  }

  // tslint:disable-next-line: function-name
  Applicability(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      // const value = this.leaveform.getRawValue();
      // //  const applicableDate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
      // const adate = new Date(moment(event).format(this.sharedService.datePickerSettings.requestFormat));
      // const applicabilitydate = adate;
      // tslint:disable-next-line: no-parameter-reassignment
      // leavetype.ApplicableDate = applicabilitydate;
    }
  }

  // tslint:disable-next-line: function-name
  Validity(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      const vdate = new Date(moment(event).format(this.sharedService.datePickerSettings.requestFormat));
      const validitydate = vdate;
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.ValidityDate = validitydate;
    }
  }

  // tslint:disable-next-line: function-name
  AllowedLeave(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      const totalallowleave = parseInt(event.target.value, 10);
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.AllowLeave = totalallowleave;
    }
  }

  public addLeaveType() {
    this.isFormSubmitted = !this.leaveform.valid;
    const leaveform = this.leaveform.getRawValue();
    leaveform.leaveTypeFormArray.forEach((data) => {
      data.ApplicableDate = moment(data.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    });
    const arrLeave = leaveform.leaveTypeFormArray;
    if (this.leaveform.valid) {
      const selectedLeaves = arrLeave
        .map((value, index) => {
          return { ...value, LeaveType_Code: this.leavetypes[index].LeaveType_Code };
        })
        .filter(value => value.isChecked);
      if (selectedLeaves.length > 0) {
        this.disablesavebutton = true;
        const userId = localStorage.getItem('UserID');
        const leavedata: any = {
          leavetypes: selectedLeaves,
          UserId: localStorage.getItem('UserID'),
          ContractorVendor_Code: leaveform.ContractorVendor_Code
        };
        this.contractorsService.addLeave(leavedata).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'LeaveType add successfully'
            });
            this.closeModal.next('click');
            this.modalService.dismissAll(this.templateRef);
            this.leaveform.reset();
            this.disablesavebutton = false;
            this.router.navigate(['contractors']);
          } else {
            this.logHelperService.logError({
              message: 'LeaveType not added'
            });
            this.closeModal.next('click');
            this.modalService.dismissAll(this.templateRef);
            this.leaveform.reset();
            this.disablesavebutton = false;
            this.router.navigate(['contractors']);
          }
        });
      } else {
        if (selectedLeaves.length === 0) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Please Select atleast one LeaveType!'
          });
        } else if (this.optionsChecked.length > 0 && this.statusChecked.length > 0) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Leave can be activated or de-activated only!'
          });
        } else if (leaveform.ContractorVendor_Code === null) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Please Select Contractor!'
          });
        } else if (leaveform.ApplicableDate === null) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Please Select Applicable Date!'
          });
        }
      }
    }
  }

  /**
   * Open modal popup of details page
   * @author Sagar Dhrangdhariya
   * @param {*} $event
   * @memberof ContractorsComponent
   */
  public openDetails(event, contractorType): void {
    this.modalRef = this.modalService.open(ContractorDetailsComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-full-size' }
      );
    localStorage.setItem('stateList', JSON.stringify(this.states));
    this.modalRef.componentInstance.data = event;
    this.modalRef.componentInstance.contractorType = contractorType;
    this.modalRef.componentInstance.states = this.states;
    this.modalRef.componentInstance.handleClose.subscribe((res: any) => this.modalRef.close(''));
  }
}
