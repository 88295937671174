import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HrClearanceService } from './hr-clearance.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { GroupRowComponent } from './group-row/group-row.component';
import { HrcStatusComponent } from './hrc-status/hrc-status.component';
import { GLOBAL } from '../app.globals';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AgActionCellRendererComponent } from '../core/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WOTaskListComponent } from './wo-task-list/wo-task-list.component';
import { WOHRCDetail, WOHRCTotalAPIResponse, WOTask, WOTaskRequest } from './wo-task-list/wo-task-model';
import { LogHelperService } from '../core/services/log-helper.service';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SharedService } from '../core/services';
import { getAlertMessageByShortCode } from '../core/utility/utility';
@Component({
  selector: 'app-hr-clearance',
  templateUrl: './hr-clearance.component.html',
  styleUrls: ['./hr-clearance.component.css']
})
export class HrClearanceComponent implements OnInit {

  @ViewChild('#filterTemplate') filterTemplate: TemplateRef<any>;

  public yearList$: Observable<any[]> = this.hrClearanceService.yearList$.pipe(
    tap(res => {
      if (!res) return;
      const currentYearObj = res.find(item => item.IsCurrentYear === 1);
      if (currentYearObj) {
        this.activeYear = currentYearObj.FinancialYear_Code;
        this.isFirstTimeYearLoaded = true;
        this.onYearChange();
      }
    })
  );
  public monthList$: Observable<any[]> = this.hrClearanceService.monthList$.pipe(
    tap(res => {
      if (!res || this.activeMonth) return;
      const currentMonthObj = res.find(item => item.IsCurrentMonth === 1);
      if (currentMonthObj) {
        this.activeMonthChange(currentMonthObj.PayrollMonth_Code, currentMonthObj.MonthName);
      }
    })
  );
  public hrcList$: Observable<any[]> = this.hrClearanceService.hrcList$.pipe(map(res => this.getHRCList(res)));


  private isFirstTimeYearLoaded = false;

  gridApi;
  gridColumnApi;

  columnDefs;
  rowData = [];

  years = [];
  months = [];
  activeYear;
  activeMonth;
  activeMonthName;
  monthMapping = {
    0: 'Jan', 1: 'Feb', 2: 'Mar', 3: 'Apr', 4: 'May', 5: 'Jun', 6: 'Jul', 7: 'Aug', 8: 'Sep', 9: 'Oct', 10: 'Nov', 11: 'Dec'
  };
  totalRecords = 0;

  hrcGridOptions = {
    context: {
      componentParent: this
    },
    headerHeight: 39,
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: node => node.data.isVisible
  };

  rowIndexCellRenderer = (params) => {
    if (params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }

  actionCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px;padding-left: 15px;cursor: pointer;"><i class="fa fa-arrow-right"></i></div>`;
    }
    return '';
  }

  public alertMessages: any[];

  constructor(
    private hrClearanceService: HrClearanceService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
    private sharedService: SharedService
  ) {
    this.columnDefs = [
      {
        headerName: '#', field: 'sortCode', width: 30,
        sortable: false, suppressMenu: true, cellRenderer: this.rowIndexCellRenderer
      },
      {
        headerName: 'Contractor Name',
        field: 'ContractorName',
        width: 180, hide: true
      },
      {
        headerName: 'EIC User',
        field: 'EICUser',
        width: 130,
        colSpan: (params) => {
          return params.data.parent ? 6 : 1;
        },
        cellRendererFramework: GroupRowComponent
      },
      {
        headerName: 'HRC Applicable',
        field: 'HRCApplicable',
        width: 90,
        type: 'number'
      },
      {
        headerName: 'Department Name',
        field: 'DepartmentName',
        width: 100,
      },
      {
        headerName: 'Order Date',
        field: 'OrderDate',
        width: 150,
      },
      {
        headerName: 'Plant',
        field: 'Plant',
        width: 100,
      },
      {
        headerName: 'HRC Status',
        field: 'HRCStatus',
        width: 120,
      },
      {
        headerName: 'Status',
        field: '',
        width: 120,
        cellRendererFramework: HrcStatusComponent
      },
      {
        headerName: 'Action',
        field: '',
        width: 50,
        // cellRenderer: this.actionCellRenderer
        cellRendererFramework: AgActionCellRendererComponent,
        cellRendererParams: {
          isRightArrow: true,
          isEdit: false,
          isDelete: false
        }
      },

    ];
  }

  ngOnInit() {
    this.loadYears();

    this.sharedService.alertMessages$.subscribe((alertMessages: any[]) => {
      this.alertMessages = alertMessages;
    });
  }

  findActiveMonth(months) {
    return months.filter((elem) => {
      return elem.indexOf(this.monthMapping[new Date().getMonth() - 1]) > -1;
    })[0];
  }

  setMonthList() {
    this.months = [];
    const startDate = moment(this.activeYear.split('_')[0]);
    const endDate = moment(this.activeYear.split('_')[1]);

    const startOfMonth = moment(startDate.startOf('month').format('YYYY-MM-DD'));
    while (startOfMonth.isBefore(endDate)) {
      this.months.push(startOfMonth.format('MMM-YY'));
      startOfMonth.add(1, 'month');
    }
    this.activeMonth = this.findActiveMonth(this.months);
  }

  loadYears() {
    // const date = new Date();
    // const currYear = moment(date).format('YYYY');
    // this.years = [];
    // const getData = this.hrClearanceService.getYearList();
    // const data = getData.Years;
    // // tslint:disable-next-line:no-increment-decrement
    // for (let i = 0; i < data.length; i++) {
    //   const startYear = moment(data[i].startDate).format('YYYY');
    //   const endYear = moment(data[i].endDate).format('YYYY');
    //   if (startYear === endYear) {
    //     this.years.push({
    //       id: `${data[i].startDate}_${data[i].endDate}`,
    //       Name: startYear
    //     });
    //   } else {
    //     this.years.push({
    //       id: `${data[i].startDate}_${data[i].endDate}`,
    //       Name: `${startYear} - ${endYear}`,
    //     });
    //   }
    // }

    // this.activeYear = this.years[1].id;
    // this.setMonthList();

    // this.getHRCList();
    // $scope.loadContractorData();
  }

  activeMonthChange(newMonth: number, monthName: string) {
    this.activeMonth = newMonth;
    this.hrClearanceService.monthActionChange({ PayrollMonth_Code: this.activeMonth });
    // this.getHRCList();
    this.activeMonthName = monthName;
  }

  onYearChange() {
    // this.setMonthList();
    // this.getHRCList();
    this.hrClearanceService.yearActionChange(this.activeYear);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

  }

  getHRCList(res: any[]) {
    // const contractorVendorCode = 148;
    // const params = {
    //   contractorVendorCode,
    //   payrollMonthCode: 169,
    //   roleCode: GLOBAL.USER.ROLE_CODE,
    //   userCode: localStorage.getItem('UserID'),
    // };
    // this.hrClearanceService.getHRCList(params).subscribe((res: any[]) => {
    if (!res.length) return [];
    this.totalRecords = res.length;
    const result = res.reduce((ar, item) => {
      const index = item.ContractorCode; // getting date Month-Year
      // tslint:disable-next-line:no-parameter-reassignment
      const item1 = ar.filter((a) => {
        return a.ContractorCode === index;
      })[0]; // getting item if already present in array
      const indexOf = ar.indexOf(item1); // getting index of _item if _item is already present
      if (indexOf > -1) {
        const children = ar[indexOf].children;
        item['isVisible'] = true;
        children.push(item);
        ar[indexOf] = {
          children,
          parent: true,
          isVisible: true,
          ContractorCode: index,
          ContractorName: item.ContractorName,
          Submitted: Number(item1.Submitted) + Number(item.ContractorStatus === 'Submitted' ? 1 : 0),
          Pending: Number(item1.Pending) + Number((item.EICStatus === 'Approved' || item.EICStatus === 'OnHold'
            || item.TCStatus === 'Verified' || item.TCStatus === 'OnHold'
            || item.HRStatus === 'OnHold') ? 1 : 0),
          Approved: Number(item1.Approved) + Number((item.EICStatus === 'Rejected' || item.TCStatus === 'Rejected'
            || item.HRStatus === 'Rejected' || item.HRStatus === 'Approved') ? 1 : 0)
        }; // we sum the count of existing _item
      } else {
        const children = [];
        item['isVisible'] = true;
        children.push(item);
        ar.push({
          children,
          parent: true,
          isVisible: true,
          ContractorCode: index,
          ContractorName: item.ContractorName,
          Submitted: item.ContractorStatus === 'Submitted' ? 1 : 0,
          Pending: (item.EICStatus === 'Approved' || item.EICStatus === 'OnHold'
            || item.TCStatus === 'Verified' || item.TCStatus === 'OnHold'
            || item.HRStatus === 'OnHold') ? 1 : 0,
          Approved: (item.EICStatus === 'Rejected' || item.TCStatus === 'Rejected'
            || item.HRStatus === 'Rejected' || item.HRStatus === 'Approved') ? 1 : 0,
        }); // we push a new _item
      }
      return ar;
    }, []);
    const tempRowData = [];
    // tslint:disable-next-line:no-increment-decrement
    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      element['rowIndex'] = index + 1;
      element['isVisible'] = true;
      tempRowData.push(element);
      if (element.children.length) {
        // tslint:disable-next-line:no-increment-decrement
        for (let childIndex = 0; childIndex < element.children.length; childIndex++) {
          const child = element.children[childIndex];
          child['isVisible'] = true;
          tempRowData.push(child);
        }
      }
    }
    // this.rowData = tempRowData;

    console.log('rowData: -- ', this.rowData);
    return tempRowData;
    // });
  }

  onPaginationChanged() {

  }

  viewWOTask(woData) {
    console.log('woData', woData);
    const [isValid, message] = this.checkValidationOnViewTask(woData)
    if (!isValid) {
      if (message) {
        this.logHelperService.logError({ message });
        return;
      }
      this.logHelperService.logError({ message: 'Invalid action' });
      return;
    }

    const requestObj: WOTaskRequest = {
      WorkOrder_Code: woData.WorkOrderCode,
      PayrollMonth_Code: woData.PayrollMonth_Code,
      HRC_Code: woData.HRCCode,
      Plant_Code: woData.Plant_Code,
    };
    const hrcTotalData$ = this.hrClearanceService.getHRCTotalData(requestObj);
    const taskList$ = this.hrClearanceService.getTaskListByWorkOrder(requestObj);
    const statusList$ = this.getStatusList();
    const documentTypes$ = this.getDocumentTypes();
    const hrcDetailsRoleWise$ = this.hrClearanceService.getHRCDetailsRoleWise(woData).pipe(
      map((res: any) => {
        if (res && res.Table1 && res.Table1 && res.Table4 && res.Table4.length) {
          res.Table1.forEach(item => {
            item.isCompleted = !!res.Table4.find(i => i.RequestLevel_Code === item.RequestLevel_Code);
          });
        }

        return res;
      })
    );
    forkJoin(taskList$, hrcTotalData$, statusList$, documentTypes$, hrcDetailsRoleWise$).subscribe(([taskList, totalData, statusList, documentTypes, hrcDetailsRoleWise]: [WOTask[], any, any[], any[], any]) => {
      if (!taskList.length) {
        this.logHelperService.logError({
          message: 'No data found.'
        })
        return;
      }
      console.log('taskList', taskList);
      console.log('totalData', totalData);

      this.openTaskModal(taskList, totalData, woData, statusList, documentTypes, hrcDetailsRoleWise);
    })
  }

  private openTaskModal(taskList: any[], totalData: WOHRCTotalAPIResponse, woData: WOHRCDetail, statusList: any[], documentTypes: any[], hrcDetailsRoleWise: any): void {
    const modalRef = this.modalService.open(WOTaskListComponent, {
      size: 'lg', backdrop: 'static',
      windowClass: 'modal-panel modal-scroll overflow-hidden'
    });
    modalRef.componentInstance.statusList = statusList;
    modalRef.componentInstance.documentTypes = documentTypes;
    modalRef.componentInstance.hrcDetails = { ...woData, monthName: this.activeMonthName };
    modalRef.componentInstance.taskList = taskList;
    modalRef.componentInstance.hrcDetailsRoleWise = hrcDetailsRoleWise.Table1 || [];;
    modalRef.componentInstance.alertMessages = this.alertMessages || [];
    modalRef.componentInstance.hrcContractorDetail = totalData.Table1 ? totalData.Table1[0] : null;
    modalRef.componentInstance.hrcContractorTotalData = totalData.Table2 ? totalData.Table2[0] : null;
    modalRef.componentInstance.hrcRequestFlowDetails = hrcDetailsRoleWise.Table6 && hrcDetailsRoleWise.Table6.length ? hrcDetailsRoleWise.Table6[0] : null;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }

  openFilter() {
    this.modalService.open(this.filterTemplate);
  }

  private checkValidationOnViewTask(woData: any): any {
    if (woData.IsHrcApplicable <= 0) {
      return [false, getAlertMessageByShortCode(this.alertMessages, 'MSG205_6')]
    }
    if ([1, 2].includes(woData.IsHrcApplicable) && woData.Task === 0) {
      return [false, getAlertMessageByShortCode(this.alertMessages, 'MSG205_5')];
    }

    return [true, null];
  }

  private getStatusList(): Observable<any[]> {
    const requestObj = {
      ShortCode: null,
      ParenCode: '245,246',
      Search: null,
      PageCode: 205,
      RequirType: 2,
      ActionType: 0,
      UserId: GLOBAL.USER_ID
    };

    return this.hrClearanceService.getStatusList(requestObj);
  }

  private getDocumentTypes(): Observable<any[]> {
    const requestObj = {
      DocumentType_Code: null,
      Module_Code: 122,
      Page_Code: 205,
      Plant_Code: null,
      SpecificFor: 0, //-- 0: All | 1: Company | 2:Contractor | 3: Labour | 4: Contractor & Labour | 5: Company & Contractor
      RequireType: 2, // -- 1: List | 2: Drop down
      ActionType: 0,
      UserId: GLOBAL.USER_ID
    };

    return this.hrClearanceService.getDocumentTypes(requestObj);
  }
}
