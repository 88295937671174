import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalSettingRoutingModule } from './global-setting-routing.module';
import { GlobalSettingComponent } from './global-setting.component';
import { CoreModule } from '../core/core.module';
import { EditGlobalSettingComponent } from './edit-global-setting/edit-global-setting.component';

@NgModule({
  exports: [
    GlobalSettingComponent
  ],
  declarations: [
    GlobalSettingComponent,
    EditGlobalSettingComponent
  ],
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    GlobalSettingRoutingModule
  ],
  providers: [
  ],
  entryComponents: [
    EditGlobalSettingComponent
  ]
})

export class GlobalSettingModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GlobalSettingModule,
    };
  }
}
