import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { AccidentMaster } from '../accident.model';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { getLocaleTimeFormat } from '@angular/common';
declare var $;
const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'HH:MM'
const date1: Date = new Date();

export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: timeFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-associate-accident-form',
  templateUrl: './associate-accident-form.component.html',
  styleUrls: ['./associate-accident-form.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }],
})
export class AssociateAccidentFormComponent implements OnInit {

  @Input() public associateObj: AccidentMaster;
  @Input() public contractors: any[] = [];
  @Input() public empList: any[] = [];

  @Output() public save: EventEmitter<AccidentMaster>;
  @Output() public close: EventEmitter<void>;

  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private logHelperService: LogHelperService
  ) {
    this.save = new EventEmitter<AccidentMaster>();
    this.close = new EventEmitter<void>();
    this.formGroup = this.bindFormGroup();
  }

  ngOnInit() {
    if (this.associateObj) {
      this.formGroup.patchValue(this.associateObj);
    }
  }

  public saveUnit(): void {
    if (this.formGroup.valid) {
      const formValue: AccidentMaster = this.formGroup.getRawValue();
      const data = new  AccidentMaster();
      data.UserId = GLOBAL.USER_ID;
      data.DateOfNotice = formValue.DateOfNotice;
      data.DateOfNotice = formValue.DateOfNotice;
      data.Contractor_Code = formValue.Contractor_Code;
      data.ULC = formValue.ULC;
      if (this.associateObj) {
        data.StatementType = 'Update';
        data.Accident_Code = this.associateObj.Accident_Code;
      } else {
        data.StatementType = 'Insert';
      }

      this.save.emit(data);
    } else {
      this.logHelperService.logError({
        message: 'Please enter all required fields.'
      });
    }
  }

  public cancelUnit(): void {
    this.close.emit();
  }

  private bindFormGroup(): FormGroup {
    return this.fb.group({
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortName: ['', [Validators.required]],
      ShortCode: ['', [Validators.required]],
    });
  }
}