import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HalfdayPolicyComponent } from './list-of-policy/halfday-policy/halfday-policy.component';
import { LatecomingPolicyComponent } from './list-of-policy/latecoming-policy/latecoming-policy.component';
import { ListOfPolicyComponent } from './list-of-policy/list-of-policy.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  // {
  //   path: 'lateinpolicy',
  //   component: LatecomingPolicyComponent
  // },
  {
    path: 'list-of-policy',
    component: ListOfPolicyComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'list-of-policy/:id',
  //   component: EarlygoingPolicyComponent
  // },

  {
    path: 'list-of-latepolicy',
    component: LatecomingPolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-of-halfdaypolicy',
    component: HalfdayPolicyComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PolicyRoutingModule { }
