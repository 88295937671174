
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyDecimal]'
})
export class OnlyDecimalDirective {

  constructor(private el: ElementRef) { }

  @Input() appOnlyDecimal: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if (this.appOnlyDecimal) {

      // Only one time Dot ( . ) allowed
      // tslint:disable-next-line:deprecation
      if (e.keyCode === 190 && event.target.value && event.target.value.indexOf('.') > -1) {
        e.preventDefault();
      }

      // tslint:disable-next-line:deprecation
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        // tslint:disable-next-line:deprecation
        (e.keyCode === 65 && e.ctrlKey) ||
        // Allow: Ctrl+C
        // tslint:disable-next-line:deprecation
        (e.keyCode === 67 && e.ctrlKey) ||
        // Allow: Ctrl+X
        // tslint:disable-next-line:deprecation
        (e.keyCode === 88 && e.ctrlKey) ||
        // Allow: home, end, left, right
        // tslint:disable-next-line:deprecation
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      // tslint:disable-next-line:deprecation
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }
}
