import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { GLOBAL } from 'src/app/app.globals';
import { DepartmenttrainingService } from '../department-training/department-training.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlertOptions } from 'sweetalert2';
import { GridOptions, GridApi } from 'ag-grid-community';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { UtilService } from 'src/app/core/services/util.service';
import { SharedService } from 'src/app/core/services';
import { AttendanceService } from 'src/app/attendance/attendance.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { AgActionCellRendererComponent, AgNumberCountComponent, FileDoc, FileUploadService } from 'src/app/core/components';
import { LabourCGMPTrainingBulkUploadWithIndividualDocComponent } from './bulk-upload-with-individual-doc/bulk-upload-with-individual-doc.component';
// tslint:disable-next-line: max-line-length
import { AgActionCellDocRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-doc-render/ag-action-cell-doc-render.component';
import { LabourCGMPTrainingFilterComponent } from './labour-cgmp-training-filter/labour-cgmp-training-filter.component';

@Component({
  selector: 'app-labour-cgmp-training',
  templateUrl: './labour-cgmp-training.component.html',
  styleUrls: ['./labour-cgmp-training.component.css']
})
export class LabourCgmpTrainingComponent implements OnInit {

  // tslint:disable-next-line: variable-name
  selectedCount = 0;
  private defaultColDef;
  private editType;
  selectedRows: any[] = [];
  rowClassRules;
  requiredFlag = false;
  private isRowSelectable;
  getRowNodeId;
  gridColumnApi;
  gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs;
  rowData = [];
  rowDataAll = [];
  url: any;
  groupingMeta = ['Contractor'];
  private reportValueData: any[];
  uploadedFiles: FileDoc[] = [];
  documentTypes = [];
  data = [];
  id: any;
  rowSelection;
  roleCode;
  sectionhead: any;
  contractor: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  savedCroppedImage: any = '';
  showCropper = false;
  forContractorLogin = false;
  public showFileUploadBtn = false;
  public alertOption: SweetAlertOptions = {};
  public activeModal: NgbModalRef;
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  @ViewChild('uploadDocumentTemplateRef') uploadDocumentTemplateRef: TemplateRef<any>;
  @ViewChild('uploadDocumentTemplateRefSingle') uploadDocumentTemplateRefSingle: TemplateRef<any>;

  private allowedRoles = [120, 121, 122, 107];

  private rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  // tslint:disable-next-line: member-ordering
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private utilService: UtilService,
    private sharedService: SharedService,
    private fileUploadService: FileUploadService,
    private departmenttrainingService: DepartmenttrainingService,
    private attendanceService: AttendanceService,
    private logHelperService: LogHelperService,
  ) {
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true; // !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        // this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: false,
      headerHeight: 40,
      // isFullWidthCell: (rowNode) => {
      //     return rowNode.data.fullWidth;
      // },
    };
    this.getRowNodeId = function (data) {
      return data.group ? data.group : `${data.GatePassNo}`;
    };
  }
  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
      this.columnDefs = [
        {
          headerName: '#',
          width: 30,
          pinned: 'left',
          suppressMenu: true,
          cellRenderer: this.rowIndexCellRenderer,
          valueGetter(params) {
            return params.data.group ? '' : params.node.rowIndex;
          },
          colSpan: (params) => {
            return params.data.group ? 11 : 1;
          },
        },
        {
          headerName: 'Company',
          field: 'Contractor',
          hide: true, visible: false,
          cellStyle: { fontweight: 'bold', color: 'Black' },
        },
        {
          headerName: 'GatePass No', width: 65, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true },
          valueGetter(params) {
            return params.data.group ? params.data.group : params.data.GatePassNo;
          },
          colSpan: (params) => {
            return params.data.group ? 6 : 1;
          },
        },
        {
          headerName: 'Name', field: 'Name', width: 95, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'plant', field: 'plant', width: 60, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Unit', field: 'Unit', width: 60, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Department', field: 'Department', width: 80, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Section Head', field: 'SectionHead', width: 90, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Training Name', field: 'trainingName', width: 110, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Training Date', field: 'TrainingDate', width: 120, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Status', field: 'TrainingStatus', width: 70, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Doc', field: '', width: 30,
          sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellDocRendererComponent
        },
      ];
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.columnDefs = [
        // tslint:disable-next-line: ter-indent
        {
          headerName: '', field: '', width: 30,
          pinned: 'left',
          sortable: false, suppressMenu: true,
          filter: false,
          checkboxSelection: (params) => {
            return params.data.group ? '' : params.node.rowIndex;
          },
          //  checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: '#',
          width: 30,
          pinned: 'left',
          suppressMenu: true,
          cellRenderer: this.rowIndexCellRenderer,
          valueGetter(params) {
            return params.data.group ? '' : params.node.rowIndex;
          },
          colSpan: (params) => {
            return params.data.group ? 11 : 1;
          },
        },
        {
          headerName: 'Company',
          field: 'Contractor',
          hide: true, visible: false,
          cellStyle: { fontweight: 'bold', color: 'Black' },

        },
        {
          headerName: 'GatePass No', width: 65, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true },
          valueGetter(params) {
            return params.data.group ? params.data.group : params.data.GatePassNo;
          },
          colSpan: (params) => {
            return params.data.group ? 6 : 1;
          },
        },
        {
          headerName: 'Name', field: 'Name', width: 95, sortable: false, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'plant', field: 'plant', width: 60, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Unit', field: 'Unit', width: 60, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Department', field: 'Department', width: 80, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Section Head', field: 'SectionHead', width: 90, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Training Name', field: 'trainingName', width: 110, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Training Date', field: 'TrainingDate', width: 120, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Status', field: 'TrainingStatus', width: 70, sortable: true, filter: true,
          filterParams: { applyButton: true, clearButton: true }
        },
        {
          headerName: 'Doc', field: '', width: 30,
          sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellDocRendererComponent
        },
      ];
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.rowSelection = 'multiple';
    this.getRoleCodeByUserID();
    this.getDepaartmentTrainingData();
    this.departmenttrainingService.getDocumentTypes().subscribe((res: any) => {
      this.documentTypes = res.Data;
    });
  }
  getRoleCodeByUserID() {
    // 120,121,122
    this.attendanceService.getRoleByUserID().subscribe((res: any) => {
      if (res.Status) {
        this.roleCode = res.Data;
        this.showFileUploadBtn = this.roleCode.some(item => this.allowedRoles.includes(item.Role_Code))
        // this.sectionhead = this.roleCode.filter(list => list.Role_Code === 107);
        // this.contractor = this.roleCode.filter(list => list.Role_Code === 119);
        // if (this.sectionhead.length > 0) {
        //   this.forContractorLogin = true;
        // }
        // else if (this.contractor.length > 0) {
        //   this.forContractorLogin = false;
        // } else {
        //   this.forContractorLogin = false;
        // }
      }
    });
  }

  getDepaartmentTrainingData() {
    this.departmenttrainingService.getAllCGMPTtTrainingData().subscribe((response: any) => {
      this.rowDataAll = response;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, response);
      this.rowData = tempRowData;
    });
  }
  // tslint:disable-next-line: function-name
  DepartmentTrainingFilter(): void {
    const modalRef = this.modalService.open(LabourCGMPTrainingFilterComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.closeModal.subscribe((value: any) => {
      // tslint:disable-next-line: whitespace
      if (value) {
        console.log('value', value);
        this.rowDataAll = value;
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
        this.rowData = tempRowData;
      }
      modalRef.close(value);
    });
  }
  onSave() {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    // const contractor: any = {
    //   DocumentImageMasterViews: []
    // };
    // if (this.uploadedFiles.length) {
    //   this.uploadedFiles.forEach((file) => {
    //     if (file.isUploaded) {
    //       contractor.DocumentImageMasterViews.push({
    //         ID: file.ID,
    //         DocumentTypeMasterList_Code: file.documentTypeCode
    //       });
    //     } else {
    //       contractor.DocumentImageMasterViews.push({
    //         DocumentTypeMasterList_Code: file.documentTypeCode
    //       });
    //     }
    //   });
    // }
    // tslint:disable-next-line: ter-indent
    this.departmenttrainingService.SaveLabourTrainingDetail(this.selectedRows).subscribe((dt: any) => {
      // if (table1 != null) {
      //   this.data.push(table1.Table1);
      // }
      // this.fileUploadService.uploadFiles(this.uploadedFiles, '', GLOBAL.APIS.LABOUR_MANAGEMENT.UPLOAD_DEPARTMENT_DOC)
      //   .subscribe((res) => {
      //     console.log('after approve:', dt);
      //     this.logHelperService.logSuccess({
      //       message: 'Sucessfully Training Extended'
      //     });
      //     this.getDepaartmentTrainingData();
      //   });
      console.log('after approve:', dt);
      this.logHelperService.logSuccess({
        message: 'Sucessfully Training Extended'
      });
      this.getDepaartmentTrainingData();
    });
  }
  onRowSelected(event) {
    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.Contractor === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    }
  }
  @Input()
  public set reportData(value: any[]) {

    if (value) {

      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
      // this.rowData = value;
    }
  }
  captureImage(): void {
    this.activeModal = this.modalService.open(this.uploadDocumentTemplateRef, { size: 'lg', backdrop: 'static', keyboard: false });
  }
  saveCroppedImage(): void {
    this.savedCroppedImage = this.croppedImage;
    this.activeModal.close();
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    // console.log(this.imageChangedEvent);
  }
  onFilesChanged(newFiles) {

    this.uploadedFiles = newFiles;
  }
  // onEdit() {
  //
  //   this.activeModal = this.modalService.open(this.uploadDocumentTemplateRefSingle, { size: 'lg', backdrop: 'static', keyboard: false });
  // }
  // saveDoc() {
  //
  //   this.fileUploadService.uploadFiles(this.uploadedFiles, '', GLOBAL.APIS.LABOUR_MANAGEMENT.UPLOAD_DEPARTMENT_SINGLE_DOC)
  //     .subscribe((res) => {
  //     });
  // }
  async onUploadSave() {

    // this.activeModal = this.modalService.open(this.uploadDocumentTemplateRefSingle, { size: 'lg', backdrop: 'static', keyboard: false });
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length > 0) {
      this.openReportViewModal(this.selectedRows);
    } else {
      this.logHelperService.logError({
        message: 'Please Select Alteast One Record'
      });
    }
  }
  openReportViewModal(selectedRows: any[]): void {

    const modalRef = this.modalService.open(LabourCGMPTrainingBulkUploadWithIndividualDocComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.reportData = selectedRows;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.save.subscribe(() => {
      modalRef.close('');
      this.getDepaartmentTrainingData();
    });
  }

  docClick(item) {

    if (item.ParentRootFolder != null) {
      window.open(GLOBAL.BASE_URL_DOC + item.ParentRootFolder, '_blank');
    } else {
      this.logHelperService.logError({
        message: 'No Document Availble For This Record'
      });
    }
  }
}
