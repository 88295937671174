import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from 'src/app/core/services';

@Component({
  selector: 'app-ag-params-form',
  templateUrl: './ag-params-form.component.html',
  styleUrls: ['./ag-params-form.component.css']
})
export class AgParamsFormComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

}
