import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaceRecognitionUIRoutingModule } from './faceRecognitionUI-routing.module';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CoreModule } from '../core/core.module';
import { FaceRecognitionUIService } from './faceRecognitionUI.service';
import { FaceRecognitionUIComponent } from './faceRecognitionUI.component';
import { FaceTrainCameraComponent } from './face-train-cam/face-train-cam.component';
import { FaceRecognizatonComponent } from './face-recognization/face-recognization.component';
import { FaceTrainImageFileComponent } from './face-file-upload/face-train-file.component';


@NgModule({
  exports: [
    FaceRecognitionUIRoutingModule
  ],
  declarations: [
    // FaceRecognitionUIComponent,
    // FaceRecognizatonComponent,
    // FaceTrainCameraComponent,
    // FaceTrainImageFileComponent


  ],
  imports: [
    CommonModule,
    FaceRecognitionUIRoutingModule,
    CoreModule.forRoot(),

  ],
  providers: [
    FaceRecognitionUIService
  ],

})
export class FaceRecognitionUIModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: FaceRecognitionUIModule,
      providers: [
        FaceRecognitionUIService,


        // WorkOrderFormService
      ]
    };
  }
}
