import { YearlyBudgetService } from './../yearly-budget.service';
import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { YearlyBudgetFormComponent } from '../yearly-budget-form/yearly-budget-form.component';
import { GridApi, GridOptions } from 'ag-grid-community';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { DatePipe, formatDate, Time } from '@angular/common';
import { map, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { result } from 'lodash';
import { BackdropColor } from 'src/app/man-power/model/man-power.model';
import { ModelDialogClass } from 'src/app/core/components/select-steps-filter-wrapper/select-steps-filter.model';
import { LeaveService } from 'src/app/leave-module/leave.service';
import { AgActionCellRendererComponent } from 'src/app/core/components';
import { SharedService } from 'src/app/core/services';
import { LeaveCriteria } from 'src/app/leave-module/leave.model';
import { IDataOptions, ToolbarItems } from '@syncfusion/ej2-angular-pivotview';
import { GridSettings } from '@syncfusion/ej2-pivotview/src/pivotview/model/gridsettings';

@Component({
  selector: 'app-yearly-budget-list',
  templateUrl: './yearly-budget-list.component.html',
  styleUrls: ['./yearly-budget-list.component.css']
})
export class YearlyBudgetListComponent implements OnInit {

   @ViewChild('templateRef') templateRef: TemplateRef<any>;
  @ViewChild('coffTemplateRef') coffTemplateRef: TemplateRef<any>;

  
  showPivotTable = false;
  public dataSourceSettings: IDataOptions;
  public gridSettings: GridSettings;

  toolbarOptions: ToolbarItems[] = ['Export'];

  // public gridOptions: any[];
  leaveform: FormGroup;
  // = new FormGroup({
  //   // LeaveType_Code: new FormControl(''),
  //   // employee: new FormControl(''),
  //   Labour_Code: new FormControl(''),
  //   // leavebalance: new FormControl(''),
  //   LeaveType_Code: new FormControl(''),
  //   StartDate: new FormControl(''),
  //   EndDate: new FormControl(''),
  //   totalleavedays: new FormControl(''),
  //   notes: new FormControl(''),
  //   Section_Code: new FormControl(''),
  //   leavecriteria: new FormControl(''),
  //   Plant_Code: new FormControl('')
  //   // LeaveType: new FormControl('')
  // });
  private isRowSelectable;
  private defaultColDef;
  private editType;
  rowClassRules;
  leavetypes = [];
  otherLeaveCriteriaList = [
    {
      leavecriteria: 3,
      name: 'Second Half Leave'
    }
  ];

  hidesubgrid = false;
  assignedleavetypes = [];
  leavebalance = [];
  employees = [];
  sectionhead = [];
  hidebutton = false;
  hideupdatebutton = false;
  disableupdatebutton = false;
  hidesavebutton = false;
  rowDataAll = [];
  groupingMeta = ['Contractor'];
  public userId = localStorage.getItem('UserID');
  public plants: any[] = [];
  public ULC: any[] = [];
  public rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  public gatepassno: number;
  public validUptoDate: any;
  public ulc: number;
  public disablesavebutton = true;
  showonsave = false;
  showonedit = false;
  selectedRows: any[] = [];
  // public disabletotaldays = false;
  public closeModal: EventEmitter<any>;
  public modalRef: any;
  public coffModalRef: any;
  editLeaveCode: string;
  getmonthofstartdate: number;
  public daysDiff: number;
  public actualdaysDiff: number;
  editMode = false;
  show1 = false;
  show = false;
  show2 = false;
  forContractorLogin = false;
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public leavecriterialist: any[];
  public editLeaveObj: any;
  type = 1;
  columnDefs;
  columnDefs1;
  getRowNodeId;
  gridColumnApi;
  gridApi: GridApi;
  gridOptions: GridOptions;
  dates = [];
  rowData = [];
  // dataarray: { Date: Date; Name: string; }[];
  public modalConfig: any = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };
  private destroy: Subject<void>;
  public leaveBalanceList: any[];
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  public coffLeaves: any[] = [];
  public currentCOFFDateObject: any;

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  public get getLeaveTypeControl(): any {
    return this.leaveform.get('LeaveTypes');
  }

  constructor(
    private router: Router,
    private budgetService: YearlyBudgetService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.destroy = new Subject();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

    this.leaveform = fb.group({
      Labour_Code: [''],
      LeaveType_Code: [''],
      StartDate: [''],
      EndDate: [''],
      totalleavedays: [''],
      notes: [''],
      Section_Code: [''],
      leavecriteria: [''],
      Plant_Code: new FormControl(''),
      LeaveTypes: fb.array([])
    });

    this.setColumnDef();
    this.setColumnDef1();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true; // !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        // this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: false,
      headerHeight: 40,
      // isFullWidthCell: (rowNode) => {
      //     return rowNode.data.fullWidth;
      // },
    };
    this.getRowNodeId = function (data) {
      return data.group ? data.group : `${data.LeaveApp_Code}`;
    };
  }


  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs = [
      {
        headerName: '#',
        width: 50,
        pinned: 'left',
        suppressMenu: true,
        valueGetter(params) {
          return params.data.group ? '' : params.node.rowIndex;
        },
        colSpan: (params) => {
          return params.data.group ? 8 : 1;
        },
      },
      // {
      //   headerName: 'Contractor', field: 'Contractor', autoHeight: true, cellClass: 'cell-wrap-text',
      //   minWidth: 10, sortable: false, filter: true, filterParams: { applyButton: true, clearButton: true }
      // },
      // {
      //   headerName: 'Code', field: 'Code', minWidth: 10,
      //   suppressMenu: true
      // },
      {
        headerName: 'Year', field:  'YearName', minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true },
      },
      {
        headerName: 'Month', field: 'MonthName', minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true },
      },
      {
        headerName: 'Plant', field: 'PlantName', autoHeight: true, cellClass: 'cell-wrap-text',
        minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'Unit', field: 'UnitName', minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true },
      },
      {
        headerName: 'Deparment', field: 'DepartmentName', minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true },
      },
      {
        headerName: 'Section', field: 'SectionName', autoHeight: true, cellClass: 'cell-wrap-text',
        minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      {
        headerName: 'Amount', field: 'BudgetAmount',
        minWidth: 10, suppressMenu: true
      },
      {
        headerName: 'Cost Center', field: 'CostCenter_Code', minWidth: 10, sortable: false, filter: true,
        filterParams: { applyButton: true, clearButton: true }
      },
      // {
      //   headerName: 'Created By', field: 'UserName', minWidth: 10, sortable: false, filter: true,
      //   filterParams: { applyButton: true, clearButton: true }
      // },
      {
        headerName: 'Action', field: '', minWidth: 80,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent,
        pinned: 'right'
      }
    ];

  }
  setColumnDef1() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
 

  }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
      this.hidebutton = true;
    } else {
      this.hidebutton = false;
    }
    this.getAllBudgetData();
    this.getLeaveType();
    this.getLabourName();
    // this.getPlants();
    // this.getAssignedLeaveTypes();
    this.getLeaveBalance();
    this.getSectionHead();
    this.leaveform.controls['totalleavedays'].disable();
  }

  toggleTableView() {
    this.showPivotTable = !this.showPivotTable;
    setTimeout(() => {
      this.setPivotTable();
    }, 100);
  }

  setPivotTable() {
    this.gridSettings = {
    } as GridSettings;

    this.dataSourceSettings = {
      enableSorting: true,
      columns: [{ name: 'YearName', caption: 'Year' }, { name: 'MonthName', caption: 'Mpnth' }],
      values: [{ name: 'BudgetAmount', caption: 'Amount' }],
      dataSource: this.rowDataAll,
      rows: [
        // { name: 'YearName' },
        // { name: 'MonthName' },
        { name: 'PlantName' },
        { name: 'UnitName' },
        { name: 'DepartmentName' },
      ],
      formatSettings: [{ name: 'BudgetAmount', maximumFractionDigits: 2 }],
      expandAll: false,
      filters: []
    };
  }



  public addYearlyBudget(): void {
    this.openManPowerModal();
  }

  private openManPowerModal(response: any = null): void {
    const modalRef = this.modalService.open(YearlyBudgetFormComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' }
    );
    if (response) {
      modalRef.componentInstance.manPower = response;
    }
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
      this.getAllBudgetData()
    });

    modalRef.componentInstance.afterSave.subscribe((value: string) => {
      modalRef.close(value);
      // this.getManPowerList();
    });
  }


  
  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }


  // editLeave(rowData) {
  //   this.router.navigate([`/edit-sub-contractor/${rowData.ContractorVendor_Code}`]);
  //   console.log('edit', rowData);
  // }





 

  // getPlants() {
  //   return this.leaveService.getPlantMasterData().subscribe((response: any) => {
  //     this.plants = response;
  //     if (this.plants.length === 1) {
  //       this.leaveform.controls['Plant_Code'].setValue(this.plants[0].Plant_Code);
  //     } else {
  //       this.plants = response;
  //     }
  //   });
  // }

  onEdit($event) {

    this.editLeaveCode = $event.LeaveApp_Code;
    // if (this.editLeaveCode) {
    //   this.openStateSelectionPopup();
    //   this.getLeaveBalance();
    //   this.editMode = true;
    //   this.hidesavebutton = true;
    //   this.disablesavebutton = true;
    //   // tslint:disable-next-line: max-line-length
    //   this.leaveService.GetdataofAppliedLeave(this.editLeaveCode).subscribe((response: any) => {
    //     const leaveform = response[0];
    //     if (!leaveform) {
    //       return;
    //     }
    //     this.editLeaveObj = leaveform;
    //     this.leaveform.controls['Labour_Code'].setValue(leaveform.Labour_Code);
    //     this.leaveform.controls['LeaveType_Code'].setValue(leaveform.LeaveType_Code);
    //     this.leaveform.controls['StartDate'].setValue(leaveform.AssignedDate);
    //     this.leaveform.controls['EndDate'].setValue(leaveform.EndDate);
    //     this.gatepassno = leaveform.GatePassNo;
    //     // this.leaveform.controls['Labour_Code'].disable();
    //     // this.leaveform.controls['StartDate'].disable();
    //     // this.leaveform.controls['EndDate'].disable();
    //     this.getLabourPerformanceReportData();

    //     // this.getLeaveTypeControl.controls['Plant_Code'].setValue(leaveform.Plant_Code);
    //     // this.leaveform.controls['LeaveType_Code'].setValue(leaveform.LeaveType_Code);
    //     // this.leaveform.controls['leavecriteria'].setValue(leaveform.LeaveCriteria);
    //     // this.leaveform.controls['leavecriteria'].setValue(leaveform.LeaveCriteria);
    //     // this.leaveform.controls['totalleavedays'].setValue(leaveform.TotalDaysOfLeave);
    //     // this.leaveform.controls['notes'].setValue(leaveform.Notes);
    //   });
    // }
    // // tslint:disable-next-line: brace-style
    // else {
    //   this.editMode = false;
    // }
  }

  // deleteLeave(rowData) {
  //   console.log(rowData);
  //   const deleteLeaveData: any = {
  //     GatePassNo: rowData.Code,
  //     EndDate: rowData.EndDate,
  //     IsActive: rowData.IsActive,
  //     IsDelete: rowData.IsDelete,
  //     LeaveApp_Code: rowData.LeaveApp_Code,
  //     LeaveType_Code: rowData.LeaveType_Code,
  //     Notes: rowData.Notes,
  //     AssignedDate: rowData.StartDate,
  //     TotalDaysOfLeave: rowData.TotalDaysOfLeave,
  //     ULC: rowData.ULC,
  //     UserId: localStorage.getItem('UserID'),
  //   };
  //   if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
  //     const today = new Date();
  //     const currentmonth = today.getMonth() + 1;
  //     const startdate = new Date(rowData.StartDate);
  //     this.getmonthofstartdate = startdate.getMonth() + 1;
  //     if (this.getmonthofstartdate === currentmonth) {
  //       this.leaveService.deleteLeave(deleteLeaveData).subscribe((response: any) => {
  //         if (response) {
  //           this.logHelperService.logSuccess({
  //             message: 'Your LeaveType is successfully cancelled.'
  //           });
  //           this.leaveform.reset();
  //           this.router.navigate(['leave-module/leave-module']);
  //           this.getAppliedLevaes(this.type);
  //         } else {
  //           this.logHelperService.logError({
  //             message: 'Your LeaveType is not cancelled!'
  //           });
  //           this.leaveform.reset();
  //           this.router.navigate(['leave-module/leave-module']);
  //           this.getAppliedLevaes(this.type);
  //         }
  //       });
  //     } else {
  //       this.logHelperService.logError({
  //         message: 'Leave is not cancelled by Contractor!'
  //       });
  //       this.leaveform.reset();
  //       this.router.navigate(['leave-module/leave-module']);
  //       this.getAppliedLevaes(this.type);
  //     }
  //   } else {
  //     this.leaveService.deleteLeave(deleteLeaveData).subscribe((response: any) => {
  //       if (response) {
  //         this.logHelperService.logSuccess({
  //           message: 'Your LeaveType is successfully cancelled.'
  //         });
  //         this.leaveform.reset();
  //         this.router.navigate(['leave-module/leave-module']);
  //         this.getAppliedLevaes(this.type);
  //       } else {
  //         this.logHelperService.logError({
  //           message: 'Your LeaveType is not cancelled!'
  //         });
  //         this.leaveform.reset();
  //         this.router.navigate(['leave-module/leave-module']);
  //         this.getAppliedLevaes(this.type);
  //       }
  //     });
  //   }
  // }

  cancellationbycontractor() {
    const today = new Date();
    const currentmonth = today.getMonth();
  }

  getAllBudgetData() {
    this.budgetService.getBudgetListing().subscribe((res: any) => {
      if (res.length > 0) {
        this.rowDataAll = res;
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
        this.rowData = tempRowData;
      } else {
        this.rowData = [];
      }
    });
  }

  public openStateSelectionPopup(): void {
    // let control = this.leaveform.get('LeaveTypes') as FormArray;
    // control = this.fb.array([]);
    this.editLeaveObj = null;

    this.dates = [];
    const control = this.leaveform.get('LeaveTypes') as FormArray;
    control.controls = [];

    this.show = false;
    this.show2 = false;
    this.show1 = false;
    // tslint:disable-next-line: max-line-length
    // this.modalRef = this.modalService.open(this.templateRef, { size: 'lg', backdrop: 'static', keyboard: false });
    // this.leaveform.reset();
    if (this.leavetypes.length > 0) {
      this.modalRef = this.modalService.open(this.templateRef, { size: 'lg', backdrop: 'static', keyboard: false });
      this.leaveform.reset();
      this.leavebalance = [];
    } else {
      this.logHelperService.logError({
        message: 'Leave of this contractor has not been applied!!'
      });
    }
    if (this.editLeaveCode) {
      this.hideupdatebutton = true;
      this.disableupdatebutton = true;
      this.hidesavebutton = false;
      this.disablesavebutton = false;
      this.showonsave = true;
      this.showonedit = false;
    } else {
      this.hidesavebutton = true;
      this.disablesavebutton = true;
      this.hideupdatebutton = false;
      this.disableupdatebutton = false;
      this.showonsave = false;
      this.showonedit = true;
    }
  }

  public onCloseModel(): void {
    this.modalRef.close('click');
    this.editLeaveCode = null;
    this.dates = [];
    const control = this.leaveform.get('LeaveTypes') as FormArray;
    control.controls = [];
    this.leaveform.patchValue({
      Labour_Code: null,
      LeaveType_Code: null,
      StartDate: null,
      EndDate: null
    });
    this.editLeaveObj = null;
  }

  public isCOFFLeaveSelected(index: number): void {
    const value = (this.leaveform.get('LeaveTypes') as FormArray).controls[index].get('coffCodes').value;
    return value && value.length;
  }

  public openCOFFLeaveModal(dateObject: any, index: number): void {
    const controls = this.leaveform.get('LeaveTypes') as FormArray;
    this.currentCOFFDateObject = {
      leaveCriteria: controls.controls[index].get('leavecriteria').value,
      itemIndex: index
    };
    // this.getLeaveTypeControl.controls[index].controls['otherLeavecriteria'].value
    const date = this.datePipe.transform(dateObject.date, 'yyyy/MM/dd');
    // this.leaveService.gateCOFFLeaves(date, this.gatepassno).subscribe(
    //   (response: any[]) => {
    //     this.coffLeaves = response;
    //     const coffCodes = controls.controls[index].get('coffCodes').value;
    //     if (coffCodes) {
    //       this.coffLeaves.forEach((item: any) => {
    //         if (coffCodes.includes(item.COFF_Code)) {
    //           item.isSelected = true;
    //         }
    //       });
    //     }
    //     this.coffModalRef = this.modalService.open(this.coffTemplateRef, { size: 'lg', backdrop: 'static', keyboard: false });
    //   });
  }

  public closeCOFFLeaveModal(): void {
    this.coffModalRef.close('click');
  }

  public saveSelectedCOFFLeave(): void {
    const selectedLeaves = this.coffLeaves.filter(item => item.isSelected).map(item => item.COFF_Code);
    if (!selectedLeaves.length) {
      this.showAlert('error', 'Please select at least one item', 'No Leave selected!');
      return;
    }
    this.getLeaveTypeControl.controls[this.currentCOFFDateObject.itemIndex].controls['coffCodes'].setValue(selectedLeaves);
    this.currentCOFFDateObject = null;
    this.coffModalRef.close('click');
    this.coffLeaves = this.coffLeaves.map((item: any) => {
      return { ...item, isSelected: false };
    });
  }

  public onCOFFLeaveSelection(event: any, coffLeave: any) {
    coffLeave.isSelected = !!(!coffLeave.isSelected);
    const totalSelectedLeaves = this.coffLeaves.filter(item => item.isSelected);
    if (totalSelectedLeaves.length) {
      const totalSelectedLeavesCount = totalSelectedLeaves.reduce((total, currentItem) => currentItem.RemainingBalance + total, 0);
      const leaveCriteriaValue = this.currentCOFFDateObject.leaveCriteria === 1 ? 1 : 0.5;
      // if (leaveCriteriaValue > totalSelectedLeavesCount) {
      //   this.showAlert('error', 'You can not selected full day COFF leave.', 'Not allowed!');
      //   coffLeave.isSelected = false;
      //   event.preventDefault();
      // }
    }
  }

  public getLeaveType(): void {
    // this.leaveService.getAppliedLeaveType().subscribe((response: any) => {
    //   this.leavetypes = response;
    // });
  }

  // public getAssignedLeaveTypes() {
  //   this.leaveService.getAssignedLeaveTypes().subscribe((response: any) => {
  //     this.assignedleavetypes = response.Data.Table;
  //   });
  // }

  public onchangeGatepass(event) {
    this.show = false;
    this.show2 = false;
    this.show1 = false;
    this.leaveform.controls['StartDate'].setValue(null);
    this.leaveform.controls['EndDate'].setValue(null);
    this.dates = [];
    const control = this.leaveform.get('LeaveTypes') as FormArray;
    control.controls = [];
    // this.getulc(this.leaveform.controls['Labour_Code'].value);
    this.countTotalDyas(this.leaveform.controls['EndDate'].value);
    if (typeof event === 'string') {
    } else if (event) {
      this.employees.forEach((data) => {
        if (this.leaveform.controls['Labour_Code'].value === data.Labour_Code) {
          this.gatepassno = data.GatePassNo;
          this.ulc = data.ULC;
          this.validUptoDate = data.ValidUptoDate;
        }
      });
      this.getLeaveBalance();
    } else if (event === null) {
      this.leavebalance = [];
    }
  }


  public getSectionHead() {
    // this.leaveService.getsetionhead().subscribe((response: any) => {
    //   this.sectionhead = response;
    // });
  }

  public getLeaveBalance() {
    // this.leaveService.getLeaveBalance(null, this.userId, this.rolecode, this.gatepassno).subscribe((response: any) => {
    //   this.leavebalance = response;
    //   if (response.length > 0) {
    //     this.hidesubgrid = true;
    //   } else {
    //     this.hidesubgrid = false;
    //   }

    // });
  }

  public getLabourName() {
    // this.leaveService.getLabourName().subscribe((response: any) => {
    //   this.employees = response;
    // });
  }

  public datevalidation(event) {
    if (typeof event === 'string') {
    } else if (event) {
      const today = new Date();
      const today1 = new Date(today).toDateString();
      const newtoday = moment(today, 'MM/DD/YYYY').toDate();
      const date1 = new Date(event).toDateString();
      const newdate = moment(event, 'MM/DD/YYYY').toDate();
      const startdate = moment(this.leaveform.controls['StartDate'].value, 'MM/DD/YYYY').toDate();
      const enddate = moment(this.leaveform.controls['EndDate'].value, 'MM/DD/YYYY').toDate();
      const startdate1 = new Date(startdate).toDateString();
      const enddate1 = new Date(enddate).toDateString();
      if (this.leaveform.controls['StartDate'].value !== null && this.leaveform.controls['EndDate'].value !== null) {
        if (enddate >= startdate) {
          // this.countTotalDyas(event);
        } else {
          this.logHelperService.logError({
            message: 'Start Date should be less than End Date.'
          });
          this.leaveform.controls['StartDate'].setValue(null);
          this.leaveform.controls['EndDate'].setValue(null);
          this.leaveform.controls['totalleavedays'].setValue(null);
          return;
        }
        if (((startdate1 === today1) || (startdate > newtoday)) && ((enddate1 === today1) || (enddate > newtoday))) {
          // this.countTotalDyas(event);
        } else {
          this.logHelperService.logError({
            message: 'Can not add date less than current date.'
          });
          this.leaveform.controls['StartDate'].setValue(null);
          this.leaveform.controls['EndDate'].setValue(null);
          this.leaveform.controls['totalleavedays'].setValue(null);
          return;
        }
      } else if (this.leaveform.controls['StartDate'].value !== null || this.leaveform.controls['EndDate'].value !== null) {
        if (((date1 === today1) || (newdate > newtoday))) {
          // this.countTotalDyas(event);
        } else {
          this.logHelperService.logError({
            message: 'Can not add date less than current date.'
          });
          this.leaveform.controls['StartDate'].setValue(null);
          this.leaveform.controls['EndDate'].setValue(null);
          this.leaveform.controls['totalleavedays'].setValue(null);
          return;
        }
      }
    }
  }

  countTotalDyas(event) {
    if (typeof event === 'string') {
    } else if (event) {
      this.getLabourPerformanceReportData();
    }
  }
  // getulc(event)
  // {
  //   this.leaveService.getulcylabourcode(event).subscribe((response: any[]) => {
  //   }
  // }

  private getCriteraByStartAndEndDate(items: any[]) {
    const finalList = [];
    items.forEach((item: any) => {
      if (item.LeaveDate === item.EndDate) {
        finalList.push(item);
      } else {
        const startDate = new Date(item.LeaveDate);
        const endDate = new Date(item.EndDate);
        if (endDate > startDate) {
          while (startDate <= endDate) {
            const newItem = { ...item, LeaveDate: new Date(startDate) };
            newItem.Count = newItem.LeaveCriteria === 1 ? 1 : 0.5;
            finalList.push(newItem);
            startDate.setDate(startDate.getDate() + 1);
          }
          finalList[finalList.length - 1].LeaveCriteria = item.EndLeaveCriteria;
          finalList[finalList.length - 1].Count = item.EndLeaveCriteria === 1 ? 1 : 0.5;

        }
      }
    });

    return finalList;
  }

  private getLabourPerformanceReportData(): void {
    const labourObj = {
      Labour_Code: this.ulc,
      StartDate: this.datePipe.transform(this.leaveform.controls['StartDate'].value, 'yyyy/MM/dd'),
      EndDate: this.datePipe.transform(this.leaveform.controls['EndDate'].value, 'yyyy/MM/dd'),
      // JoiningDate: this.joiningdate
    };
    // EndDate: moment(this.leaveform.controls['EndDate'].value, 'MM/DD/YYYY').toDate()

    //const performancesReportData$: Observable<any[]> = this.leaveService.getLabourPerformanceReportData(labourObj);
    //const leaveBalance$: Observable<any[]> = this.leaveService.getLeaveBalance(null, this.userId, this.rolecode, this.gatepassno);
    //const lastPlantPresent$: Observable<any[]> = this.leaveService.getLastPlantPresent(labourObj.StartDate, this.gatepassno, this.userId);
    // // tslint:disable-next-line:max-line-length
    // //const leaveCriteria$: Observable<any[]> = this.leaveService.getLeavesCriteriaByDates(labourObj.StartDate, labourObj.EndDate, this.gatepassno, this.userId)
    //   .pipe(map((items: any[]) => this.getCriteraByStartAndEndDate(items)));
    // // const joiningdate$: Observable<any[]> = this.leaveService.getJoiningDate(labourObj.JoiningDate, this.gatepassno, this.userId);

    // // new Date(this.leaveform.controls['StartDate'].value).toDateString(), this.gatepassno).subscribe(() => { })

    // forkJoin(performancesReportData$, leaveBalance$, lastPlantPresent$, leaveCriteria$)
    //   // this.leaveService.getLabourPerformanceReportData(labourObj)
    //   .pipe(
    //     takeUntil(this.destroy)
    //   ).subscribe(
    //     // .subscribe((response: any[]) => {
    //     ([attendanceList, leaveBalanceList, lastPlantPresent, leaveCriteriaList]: [any[], any[], any, any[]]) => {
    //       this.leaveBalanceList = leaveBalanceList;
    //       // this.leaveBalanceList[0].Balance = 0
    //       // this.disablesavebutton = leaveBalanceList.length > 0;
    //       if (this.leaveform.controls['LeaveType_Code'].value !== 116) {
    //         const leaveTypeCode = this.leaveform.get('LeaveType_Code').value;
           
    //       }

    //       const startdate = Date.parse(this.leaveform.controls['StartDate'].value);
    //       const enddate = Date.parse(this.leaveform.controls['EndDate'].value);
    //       const startDate = new Date(startdate);
    //       const endDate = new Date(enddate);
    //       const value = 1;
    //       this.dates = [];
    //       const control = this.leaveform.get('LeaveTypes') as FormArray;
    //       control.controls = [];

    //       this.daysDiff = enddate - startdate;
    //       this.daysDiff = Math.floor(this.daysDiff / (1000 * 60 * 60 * 24));
    //       this.actualdaysDiff = this.daysDiff + 1;

    //       while (startDate <= endDate) {
    //         let dateObj = {
    //           date: new Date(startDate),
    //           count: '',
    //           date1: '',
    //           dayName: this.getDayNameFromDay(startDate.getDay()),
    //           dayNumber: startDate.getDay(),
    //           isDisabled: false,
    //           isHalfDayPresent: false,
    //           isFirstHalfPresent: false,
    //           leavecriterialist: [],
    //           AttendanceStatus: '',
    //           LeaveType_Code: this.leaveform.controls['LeaveType_Code'].value,
    //           Plant_Code: null,
    //           LeaveCriteria: null,
    //           otherLeaveType_Code: null,
    //           otherLeavecriteria: null
    //         };
    //         // const attendanceObj = attendanceList.find((item: any) => item.Date.toDateString() === dateObj.date.toDateString());
    //         const attendanceObj = attendanceList.find((item: any) => item.Date &&
    //           new Date(item.Date).toDateString() === dateObj.date.toDateString());
    //         if (attendanceObj) {
    //           dateObj = {
    //             ...dateObj, Plant_Code: attendanceObj.Plant_Code || (lastPlantPresent ? lastPlantPresent.Plant_Code : null),
    //             AttendanceStatus: attendanceObj.AttendanceStatus
    //           };
    //           const statusList = ['WO', 'WP', 'H', 'P', 'CL', 'PL', 'SL'];

    //           // const disabledDate: boolean = attendanceObj.AttendanceStatus === 'WO' || attendanceObj.AttendanceStatus === 'WP'
    //           //   || attendanceObj.AttendanceStatus === 'H' || attendanceObj.AttendanceStatus === 'WO';
    //           // attendanceObj.AttendanceStatus === 'P'
    //           if (!dateObj.isDisabled && (statusList.includes(attendanceObj.AttendanceStatus) || attendanceObj.LeaveType_Code)) {
    //             dateObj = {
    //               ...dateObj, isDisabled: !attendanceObj.FHalfPresent && !attendanceObj.SHalfPresent ? true : !attendanceObj.IsPresentHalf,
    //               // ...dateObj, isDisabled: true
    //               // isHalfDayPresent: attendanceObj.IsPresentHalf, isFirstHalfPresent: attendanceObj.FHalfPresent
    //             };
    //             // tslint:disable-next-line: max-line-length
    //             if (['WO', 'H'].includes(attendanceObj.AttendanceStatus) && (this.leaveform.controls['LeaveType_Code'].value === 116 || this.leaveform.controls['LeaveType_Code'].value === 121)) {
    //               dateObj = {
    //                 ...dateObj, isDisabled: false
    //               };
    //             }
    //             const isPresent = attendanceList.some(a => a.AttendanceStatus === 'P');
    //             const startDay = new Date(startdate).getDay();
    //             const endDay = new Date(enddate).getDay();
    //             // tslint:disable-next-line: max-line-length
    //             if (!isPresent && ['WO'].includes(attendanceObj.AttendanceStatus) && (startDay === 6 && endDay === 1 || startDay === 1 && endDay === 3 || startDay === 3 && endDay === 5 || startDay === 5 && endDay === 1) && (this.leaveform.controls['LeaveType_Code'].value === 101 || this.leaveform.controls['LeaveType_Code'].value === 102 || this.leaveform.controls['LeaveType_Code'].value === 103)) {
    //               dateObj = {
    //                 ...dateObj, isDisabled: false
    //               };
    //             }
    //           } else {
    //             dateObj = {
    //               ...dateObj,
    //               isHalfDayPresent: attendanceObj.IsPresentHalf, isFirstHalfPresent: attendanceObj.FHalfPresent
    //             };
    //           }

    //         } else {
    //           dateObj = { ...dateObj, Plant_Code: lastPlantPresent && lastPlantPresent.Plant_Code };
    //         }

    //         if (leaveCriteriaList) {
    //           const dateCriteria = leaveCriteriaList.find(item => new Date(item.LeaveDate).toDateString() === dateObj.date.toDateString());
    //           if (dateCriteria) {
    //             dateObj.Plant_Code = dateCriteria.Plant_Code;
    //             dateObj.LeaveType_Code = dateCriteria.LeaveType_Code;
    //             dateObj.LeaveCriteria = dateCriteria.LeaveCriteria;
    //             dateObj.otherLeaveType_Code = dateCriteria.otherLeaveType_Code;
    //             dateObj.otherLeavecriteria = dateCriteria.otherLeavecriteria;
    //             dateObj.count = dateCriteria.Count;
    //             if (dateObj.otherLeavecriteria) {
    //               dateObj.isDisabled = true;
    //             }
    //           }
    //         }

    //         this.dates.push(dateObj);
    //         const formGroup: any = this.initiateForm(dateObj);
    //         if (dateObj.LeaveCriteria === 1) {
    //           dateObj.isDisabled = true;
    //           formGroup.get('otherLeaveType_Code').disable();
    //           formGroup.get('isDisabled').setValue(true);
    //         }
    //         control.push(formGroup);

    //         startDate.setDate(startDate.getDate() + 1);

    //         if (!this.editLeaveObj && dateObj.LeaveType_Code) {
    //           formGroup.controls.LeaveType_Code.setValue(dateObj.LeaveType_Code);
    //         }
    //       }

    //       this.bindLeaveListForEdit();
    //       console.log(this.leaveform.value);
    //       this.leavebalance.forEach((data) => {
    //         if (this.leaveform.controls['LeaveType_Code'].value === data.LeaveType_Code) {
    //           if (data.Balance < this.leaveform.controls['totalleavedays'].value) {
    //             this.logHelperService.logError({
    //               message: `You can apply leave less than' ${data.Balance} Days!`
    //             });
    //             this.leaveform.controls['StartDate'].setValue(null);
    //             this.leaveform.controls['EndDate'].setValue(null);
    //             // this.leaveform.controls['totalleavedays'].setValue(null);
    //           }
    //         }
    //       });
    //     });
  }

  public onDelete(leaveObj: any, index: number): void {
    leaveObj.isDeleted = true;
    this.getLeaveTypeControl.controls[index].controls.isDeleted.setValue(true);
  }

  public onCancel(leaveObj: any, index: number): void {
    // leaveObj.isDeleted = true;
    // this.getLeaveTypeControl.controls[index].controls.isDeleted.setValue(true);
    const data = {
      LeaveApp_Code: this.editLeaveObj.LeaveApp_Code,
      Date: moment(leaveObj.date, 'MM/DD/YYYY').toDate(),
      Totaldays: leaveObj.count,
      UserID: localStorage.getItem('UserID')
    };
    // this.leaveService.cancelationrequest(data).subscribe((response: any) => {
    //   if (response) {
    //     this.logHelperService.logSuccess({
    //       message: 'LeaveType add successfully'
    //     });
    //   }
    // });
  }

  private bindLeaveListForEdit(): void {
    if (this.editLeaveObj) {
      const controls = this.getLeaveTypeControl.controls;
      let isCOFFLeave = false;
      if (this.editLeaveObj.LeaveType_Code === 120) {
        isCOFFLeave = true;
      }
      // controls[0].controls.coffCodes.setValue(this.editLeaveObj.coffCodes);
      if (this.editLeaveObj.TotalDaysOfLeave === 1 && controls.length === 1) {
        const coffCodes = this.getCOFFCodeForEdit(this.editLeaveObj.COFF_Code1, this.editLeaveObj.COFF_Code2);
        controls[0].controls.coffCodes.setValue(coffCodes);
        this.dates[0].isCOFFLeave = isCOFFLeave;

        controls[0].controls.Plant_Code.setValue(this.editLeaveObj.Plant_Code);
        controls[0].controls.LeaveType_Code.setValue(this.editLeaveObj.LeaveType_Code);
        controls[0].controls.leavecriteria.setValue(this.editLeaveObj.LeaveCriteria);
        controls[0].controls.count.setValue(this.dates[0].count);

        this.dates[0].count = this.editLeaveObj.TotalDaysOfLeave;
        return;
      }
      if (this.editLeaveObj.TotalDaysOfLeave === 2 && controls.length === 2) {
        for (const control of controls) {
          const index = controls.indexOf(control);
          if (index === 0) {
            this.dates[index].count = this.editLeaveObj.LeaveCriteria === LeaveCriteria.FULL_TIME ? 1 : 0.5;
            control.controls.leavecriteria.setValue(this.editLeaveObj.LeaveCriteria);
          } else {
            this.dates[index].count = this.editLeaveObj.EndLeaveCriteria === LeaveCriteria.FULL_TIME ? 1 : 0.5;
            control.controls.leavecriteria.setValue(this.editLeaveObj.EndLeaveCriteria);
          }
          control.controls.Plant_Code.setValue(this.editLeaveObj.Plant_Code);
          control.controls.LeaveType_Code.setValue(this.editLeaveObj.LeaveType_Code);
          this.editLeaveObj.otherLeaveType_Code = 101;
          this.editLeaveObj.otherLeavecriteria = 3;

          control.controls.otherLeaveType_Code.setValue(this.editLeaveObj.otherLeaveType_Code || null);
          control.controls.otherLeavecriteria.setValue(this.editLeaveObj.otherLeavecriteria || null);
          control.controls.count.setValue(this.dates[index].count);

          const coffCodes = this.getCOFFCodeForEdit(this.editLeaveObj.COFF_Code1, this.editLeaveObj.COFF_Code2);
          control.controls.coffCodes.setValue(coffCodes);
          this.dates[0].isCOFFLeave = isCOFFLeave;
        }

        return;
      }
      // if (this.editLeaveObj.TotalDaysOfLeave.toString().includes('.')) {
      const [, afterDecimalCount] = this.editLeaveObj.TotalDaysOfLeave.toString().split('.');
      for (const control of controls) {
        const index = controls.indexOf(control);
        this.dates[index].count = 1;
        control.controls.count.setValue(this.dates[index].count);
        control.controls.leavecriteria.setValue(LeaveCriteria.FULL_TIME);
        if (index === 0) {
          if (this.editLeaveObj.LeaveCriteria !== 1) {
            this.dates[index].count = 0.5;
            control.controls.count.setValue(this.dates[index].count);
            control.controls.leavecriteria.setValue(this.editLeaveObj.LeaveCriteria);
          }
        } else if (index === controls.length - 1) {
          if (afterDecimalCount && this.editLeaveObj.EndLeaveCriteria !== 1) {
            this.dates[index].count = 0.5;
            control.controls.count.setValue(this.dates[index].count);
            control.controls.leavecriteria.setValue(this.editLeaveObj.EndLeaveCriteria);
          }
        } else {
          // control.controls.leavecriteria.setValue(LeaveCriteria.FULL_TIME);
        }
        control.controls.Plant_Code.setValue(this.editLeaveObj.Plant_Code);
        control.controls.LeaveType_Code.setValue(this.editLeaveObj.LeaveType_Code);
        const coffCodes = this.getCOFFCodeForEdit(this.editLeaveObj.COFF_Code1, this.editLeaveObj.COFF_Code2);
        control.controls.coffCodes.setValue(coffCodes);
        this.dates[0].isCOFFLeave = isCOFFLeave;
      }
      // }
    }
  }

  private getCOFFCodeForEdit(coffCode1: number, coffCode2: number) {
    let coffCodes = [];
    if (coffCode1 && coffCode2) {
      coffCodes = [coffCode1, coffCode2];
    } else if (coffCode1) {
      coffCodes = [coffCode1];
    } else {
      coffCodes = null;
    }
    return coffCodes;
  }

  private getDayNameFromDay(day: number): string {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day];
  }

  private initiateForm(dataObj: any): FormGroup {
    return this.fb.group({
      date: [dataObj.date, null],
      date1: [null],
      Plant_Code: [{ value: dataObj.Plant_Code, disabled: (dataObj.isDisabled || (!!(dataObj.LeaveCriteria))) }],
      LeaveType_Code: [{ value: dataObj.LeaveType_Code || null, disabled: (dataObj.isDisabled || (!!(dataObj.LeaveCriteria))) }],
      leavecriteria: [{ value: dataObj.LeaveCriteria, disabled: (dataObj.isDisabled || (!!(dataObj.LeaveCriteria))) }],
      count: [{ value: dataObj.count || '' }],
      isDisabled: [dataObj.isDisabled],
      isDeleted: [false],
      otherLeaveType_Code: [{ value: dataObj.otherLeaveType_Code, disabled: !(!!(dataObj.LeaveCriteria)) }],
      otherLeavecriteria: [{ value: dataObj.otherLeavecriteria, disabled: true }],
      coffCodes: ['']
    });
  }

  public cancelLeave(): void {
    const data = {
      LeaveApp_Code: this.editLeaveObj.LeaveApp_Code,
      Date: null,
      Totaldays: null,
      UserID: localStorage.getItem('UserID')
    };
    // this.leaveService.cancelationrequest(data).subscribe((response: any) => {
    //   if (response) {
    //     this.logHelperService.logSuccess({
    //       message: 'LeaveType add successfully'
    //     });
    //     this.modalRef.close('click');
    //     this.leaveform.reset();
    //     this.editLeaveCode = null;
    //     this.disablesavebutton = true;
    //     this.hidesavebutton = true;
    //     // this.router.navigate(['leave-module/leave-module']);
    //     this.getAppliedLevaes(this.type);
    //   } else {
    //     this.logHelperService.logError({
    //       message: 'LeaveType not added'
    //     });
    //     this.modalRef.close('click');
    //     this.leaveform.reset();
    //     this.editLeaveCode = null;
    //     this.disablesavebutton = true;
    //     this.hidesavebutton = true;
    //     // this.router.navigate(['leave-module/leave-module']);
    //   }
    // });
  }

  public applyLeave(): void {
    const controlValues = this.leaveform.getRawValue().LeaveTypes;
    const isSelected = controlValues.every(item => item.Plant_Code !== null && item.LeaveType_Code !== null && item.leavecriteria !== null);
    if (isSelected) {
      const isCoffNotSelected = controlValues.find((item: any) => {
        if (item.LeaveType_Code === 120 && item.count && (!item.coffCodes || !item.coffCodes.length)) {
          return true;
        }
        return false;
      });

      if (isCoffNotSelected) {
        this.showAlert('warning', 'Please select COFF.', 'COFF not selected!');
        return;
      }

      const leaveTypes = this.setLeaveDataForSave(controlValues);
      if (leaveTypes && leaveTypes.length > 0) {
        console.log('leaveTypes', leaveTypes);
        if (this.editLeaveObj) {
          leaveTypes[0].LeaveApp_Code = this.editLeaveObj.LeaveApp_Code;

        }
        // this.leaveService.applyLeave(leaveTypes).subscribe((response: any) => {
        //   this.dates = [];
        //   const control = this.leaveform.get('LeaveTypes') as FormArray;
        //   control.controls = [];
        //   if (response) {
        //     this.logHelperService.logSuccess({
        //       message: 'LeaveType add successfully'
        //     });

        //     this.modalRef.close('click');
        //     this.leaveform.reset();
        //     this.editLeaveCode = null;
        //     this.disablesavebutton = true;
        //     this.hidesavebutton = true;

        //     // this.router.navigate(['leave-module/leave-module']);
        //     this.getAppliedLevaes(this.type);
        //   } else {
        //     this.logHelperService.logError({
        //       message: 'LeaveType not added'
        //     });
        //     this.modalRef.close('click');
        //     this.leaveform.reset();
        //     this.editLeaveCode = null;
        //     this.disablesavebutton = true;
        //     this.hidesavebutton = true;
        //     this.router.navigate(['leave-module/leave-module']);
        //   }
        // });
      }
    } else {
      this.showAlert('warning', 'Please select Plant, Leave type and Leave criteria.', 'No leave Selected!');
    }
  }

  private setLeaveDataForSave(selectedLeaveTypes: any[]): any[] {

    const finalList: any[] = [];
    const leaveTypes = selectedLeaveTypes.map((item: any) => {
      const coffObj = {
        COFF_Code1: item.coffCodes && item.coffCodes.length ? item.coffCodes[0] : null,
        COFF_Code2: item.coffCodes && item.coffCodes.length >= 2 ? item.coffCodes[1] : null
      };
      if (!item.Plant_Code || !item.LeaveType_Code || !item.leavecriteria) {
        return { ...item, ...coffObj, isDeleted: true };
      }
      return { ...item, ...coffObj };
    });
    leaveTypes.forEach((leave, index) => {
      let data = finalList.length ? { ...finalList[finalList.length - 1] } : null;
      if (leave.isDeleted) {
        if (data) {
          data = { ...data, canGroup: false };
          finalList[finalList.length - 1] = data;
        }
        return;
      }
      if (!leave.isDisabled) {
        if (!data) {
          const firstObj = this.createLeaveModel(leave);
          // if (leave.LeaveType_Code || leave.otherLeaveType_Code || leave.COFF_Code1) {
          if (leave.otherLeaveType_Code || leave.COFF_Code1) {
            firstObj.canGroup = false;
            firstObj.endsession = leave.otherLeavecriteria;
            firstObj.totalLeaveCount = leave.leavecriteria === 1 ? 1 : 0.5;
            if (leave.otherLeavecriteria) {
              firstObj.totalLeaveCount = 1;
            }
          }

          finalList.push(firstObj);
        } else if (leave.otherLeaveType_Code) {
          if (data.canGroup) {
            data = {
              ...data,
              endsession: data.leavecriteria
            };
            finalList[finalList.length - 1] = data;
          }

          const firstObj = this.createLeaveModel(leave);
          firstObj.canGroup = false;
          firstObj.endsession = leave.otherLeavecriteria;
          // firstObj.totalLeaveCount = leave.leavecriteria === 1 ? 1 : 0.5;
          firstObj.totalLeaveCount = 1;
          finalList.push(firstObj);
        } else if (leave.COFF_Code1) {
          if (data.canGroup) {
            data = {
              ...data,
              endsession: data.leavecriteria
            };
            finalList[finalList.length - 1] = data;
          }

          const firstObj = this.createLeaveModel(leave);
          firstObj.canGroup = false;
          firstObj.endsession = leave.otherLeavecriteria;
          firstObj.totalLeaveCount = leave.leavecriteria === 1 ? 1 : 0.5;
          if (leave.otherLeavecriteria) {
            firstObj.totalLeaveCount = 1;
          }
          finalList.push(firstObj);
        } else if (data.canGroup && data.Plant_Code === leave.Plant_Code && data.LeaveType_Code === leave.LeaveType_Code) {
          if (data.leavecriteria === 2 || data.leavecriteria === 3) {
            data = { ...data, canGroup: false };
          }
          if (data.leavecriteria !== leave.leavecriteria) {
            data = { ...data, canGroup: false };
          }
          const leaveCount = leave.leavecriteria === 1 ? data.totalLeaveCount + 1 : data.totalLeaveCount + 0.5;
          data = {
            ...data, totalLeaveCount: leaveCount,
            startDate: new Date(data.startDate).toDateString(),
            endDate: new Date(leave.date).toDateString(),
            endsession: leave.leavecriteria
          };
          finalList[finalList.length - 1] = data;
        } else {
          const firstObj = this.createLeaveModel(leave);
          finalList.push(firstObj);
        }
      } else {
        if (data) {
          const isSunday = new Date(leave.date).getDay() === 0;
          const nextData = leaveTypes[index - 1] ? { ...leaveTypes[index - 1] } : null;
          const isMonday = nextData ? new Date(nextData.date).getDay() === 1 : false;
          if (isSunday && isMonday && data.canGroup) {
            data = {
              ...data, totalLeaveCount: data.totalLeaveCount + 1,
              startDate: new Date(data.startDate).toDateString(),
              endDate: new Date(leave.date).toDateString(),
            };

            finalList[finalList.length - 1] = data;
          } else if (isSunday && isMonday) {
            const firstObj = this.createLeaveModel(leave);
            finalList.push(firstObj);
          } else {
            data = { ...data, canGroup: false };
            finalList[finalList.length - 1] = data;
          }
        }
      }
    });

    return finalList;
  }

  private createLeaveModel(leave: any): any {

    return {
      startDate: new Date(leave.date).toDateString(),
      endDate: new Date(leave.date).toDateString(),
      totalLeaveCount: leave.count,
      Plant_Code: leave.Plant_Code,
      LeaveType_Code: leave.LeaveType_Code,
      leavecriteria: leave.leavecriteria,
      canGroup: true,
      startsession: leave.leavecriteria,
      endsession: null,
      Labour_Code: this.leaveform.controls['Labour_Code'].value,
      UserID: localStorage.getItem('UserID'),
      otherLeaveType_Code: leave.otherLeaveType_Code,
      otherLeavecriteria: leave.otherLeavecriteria,
      // coffCodes: leave.coffCodes,
      COFF_Code1: leave.coffCodes && leave.coffCodes.length ? leave.coffCodes[0] : null,
      COFF_Code2: leave.coffCodes && leave.coffCodes.length >= 2 ? leave.coffCodes[1] : null
    };

  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  onApprove() {
    // this.selectedRows = this.gridApi.getSelectedRows();
    // this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    // if (this.selectedRows.length > 0) {
    //   // tslint:disable-next-line: ter-indent
    //   this.leaveService.approveCancelationRequest(this.selectedRows).subscribe((dt: any[]) => {
    //     console.log('after approve:', dt);
    //     this.logHelperService.logSuccess({
    //       message: 'Manual Punch Approved successfully'
    //     });
    //     this.getAppliedLevaes(this.type);
    //   });
    // } else {
    //   this.logHelperService.logError({
    //     message: 'Please Select Alteast One Record'
    //   });
    // }

  }

  getJoingDate() {
    const value = this.leaveform.getRawValue();
    const startdate = moment(value.StartDate).format(this.sharedService.datePickerSettings.requestFormat);
    const enddate = moment(value.EndDate).format(this.sharedService.datePickerSettings.requestFormat);
    const validUptoDate = this.validUptoDate;
    if ((enddate) >= (startdate)) {
      if ((enddate) > (validUptoDate)) {
        this.logHelperService.logError({
          message: 'Please enter a leave end date less than or equal to validity date.'
        });
        this.leaveform.controls['EndDate'].setValue(null);

      }

    } else {
      this.logHelperService.logError({
        message: 'EndDate should be grater than StartDate.'
      });
      this.leaveform.controls['EndDate'].setValue(null);

    }

  }
}
