import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-policy-history',
  templateUrl: './policy-history.component.html',
  styleUrls: ['policy-history.component.css']
})
export class PolicyHistoryComponent {

  @Input() public set rowData(value: any[]) {
    if (value) {
      this._rowData = value;
      this.columnDefs = this.setFormGridColumnDef(value);
    }
  }
  public get rowData(): any[] {
    return this._rowData;
  }

  @Input() public columnDefs: any[] = [];
  @Output() public edit: EventEmitter<any>;
  @Output() public delete: EventEmitter<any>;

  numberOfRowsPerPage = 10;
  gridAPI: GridApi;
  gridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
    },
    onColumnResized: (params) => {
      params.api.resetRowHeights();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 50,
  };

  // public columnDefs: any[];
  private _rowData: any[] = [];

  constructor() {
  }

  public ngOnInit(): void {
  }

  private setFormGridColumnDef(list: any[]): any[] {
    const columns = [];
    if (list && list.length) {
      const firstObj = list[0];
      Object.keys(firstObj).forEach((item: any) => {
        columns.push({
          headerName: item, field: item, sortable: true, width: 40, filter: true,
          cellStyle: { textAlign: 'center' }, hide: false,
        });
      });
    }
    return columns;
  }
}