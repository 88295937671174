import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-user-approval-list-modal',
  templateUrl: './approval-list-modal.component.html',
  styleUrls: ['./approval-list-modal.component.css'],
  host: {
    class: 'h-100 overflow-hidden d-flex flex-column'
  }
})
export class UserOTApprovalListModalComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public reportDate: string;
  public totalData: number;
  private selectedReportInfo: any;
  public totalShifColspan = 0;
  public dates: any[] = [];

  myDate: any;
  @Input()
  // tslint:disable-next-line: indent
  public set selectedReportData(value: any) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): any {
    return this.selectedReportInfo;
  }

  @Input()
  public set reportData(value: any) {
    if (value) {
      this.reportValueData = value;
      //  const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      //  this.rowData = tempRowData;
    }
  }
  public get reportData(): any {
    return this.reportValueData;
  }
  // public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  public showAlert: EventEmitter<any>;
  public selectedItems: EventEmitter<any>;
  // columnDefs: any[] = [];
  // rowData = [];
  // groupingMeta = ['ContractorName'];
  private reportValueData: any[];
  public groupedData: any;
  public finalReportData: any[] = [];
  public isCheckAll: boolean;

  constructor() {
    this.closeModal = new EventEmitter();
    this.showAlert = new EventEmitter();
    this.selectedItems = new EventEmitter();
  }

  ngOnInit() {
    // this.reportDate = this.dateConvertor(this.selectedReportData);
    this.totalData = this.reportData;
    // this.finalReportData = this.getGroupRowTableData(this.reportData);
    console.log('reportData', this.reportData);

  }

  public checkAllForDate(dateObj: any) {
    dateObj.isCheck = !dateObj.isCheck;
    if (dateObj.sections) {
      dateObj.sections = dateObj.sections.map(item => {
        item.isCheck = !dateObj.isCheck;
        return this.checkSection(item);
      });
    }
  }

  public checkSection(sectionObj: any) {
    sectionObj.isCheck = !sectionObj.isCheck;
    if (sectionObj.shifts) {
      sectionObj.shifts = sectionObj.shifts.map(item => {
        item.isCheck = !sectionObj.isCheck;
        return this.checkShift(item);
      });
    }
    return sectionObj;
  }

  public checkShift(shiftObj: any) {
    shiftObj.isCheck = !shiftObj.isCheck;
    if (shiftObj.assoicates) {
      shiftObj.assoicates = shiftObj.assoicates.map(item => {
        return { ...item, isCheck: shiftObj.isCheck }
      });
    }

    return shiftObj;
  }

  public checkAssociates(assoicateObj: any) {
    assoicateObj.isCheck = !assoicateObj.isCheck;
  }

  public onApprove(): void {
    this.selectedItems.emit({ selectedItems: this.reportData, isAccept: true });
  }

  public onReject(): void {
    this.selectedItems.emit({ selectedItems: this.reportData, isAccept: false });
  }

  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    // tslint:disable-next-line: max-line-length
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} to ${date1.getDate()}-${months[date1.getMonth()]}-${date1.getFullYear()}`;
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }
}
