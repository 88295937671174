import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateRangeFilterComponent, FileDoc, FileUploadService } from 'src/app/core/components';
import { DatePipe, NgStyle } from '@angular/common';
import { VisitorManagementService } from '../visitor-management.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { GLOBAL } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import { IndiaMapService } from 'src/app/dashboard/charts/india-map.service';
import { GroupBarChartService } from 'src/app/dashboard/charts/group-bar-chart.service';
import { BarChartService } from 'src/app/dashboard/charts/bar-chart.service';
import { VisitorFormModalComponent } from '../visitor-form-modal/visitor-form-modal.component';
import { VerifyVisitorComponent } from '../verify-visitor/verify-visitor.component';
import { AppQrCodeService } from 'src/app/core/components/app-qr-code/app-qr-code.service';

declare var d3: any;
declare var crossfilter: any;
declare var dc: any;
declare var $: any;
declare var chroma: any;
@Component({
  selector: 'app-visitor-dashboard',
  templateUrl: './visitor-dashboard.component.html',
  styleUrls: ['./visitor-dashboard.component.css']
})

export class VisitorDashboardComponent implements OnInit {
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('uploadDocumentTemplateRef') public uploadDocumentTemplateRef: any;
  @ViewChild('tempRef1') public tempRef1: any;
  modalRef: NgbModalRef;
  allData: any;
  allPreAuthorizedData: any;
  activityData: any;
  pcountries = [];
  pStates = [];
  pDistricts = [];
  pCities = [];
  pPos = [];
  pVillages = [];
  visitorcategory: any;
  visitorpurposetypeList: any;
  identityTypes: any;
  statusType: any;
  show = false;
  showdrivinglicence = false;
  showelectioncard = false;
  masterData = [];
  gendertypes: any;
  iteamtypes: any;
  iteamsViews = [];
  hideUpdateiteams = true;
  edititeamsIndex = -1;
  mycolor: string;
  // tslint:disable-next-line: variable-name
  OtherPersonDetailViews = [];
  hideUpdateOtherPerson = true;
  editipersonIndex = -1;
  documentTypes = [];
  selectedRowData: any[] = [];
  uploadedFiles: FileDoc[] = [];
  public companyMasterData: any[] = [];
  public plantMasterData: any[] = [];
  public shiftGroupTypeData: any[] = [];
  public companyMasterModel: any;
  public plantMasterModel: any;
  public companyData: any[] = [];
  public units: any[] = [];
  public plants: any[] = [];
  public departmentData: any[] = [];
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  croppedImage: any = '';
  savedCroppedImage: any = '';
  editshiftCode1: string;
  editMode = false;
  teashow = false;
  shiftname: any;
  shortCode: any;
  shortname: any;
  data: any;
  allEmployeeData: any;
  earlyallowedouthours = 0;
  isactive = true;
  issuedate = false;
  optionsChecked = [];
  visitorCode: any;
  totalTodayVisitor: any;
  totalCurrentMonthVisitor: any;
  totalLastMonthVisitor: any;
  // tslint:disable-next-line: variable-name
  employee_Code: any;
  public activeref: any;
  barTotalMonthlyVisitors;
  barTotalweeklyVisitors;
  spanTotalMale;
  spanTotalFemale;

  visitorForm = new FormGroup({
    Company_Code: new FormControl(''),
    Plant_Code: new FormControl(''),
    Unit_Code: new FormControl(''),
    Department_Code: new FormControl(''),
    yourcompany: new FormControl(''),
    name: new FormControl(''),
    jobtitle: new FormControl(''),
    mobileno: new FormControl(''),
    emailid: new FormControl(''),
    otp: new FormControl(''),
    emergencycontactname: new FormControl(''),
    emergencycontactno: new FormControl(''),
    address: new FormControl(''),
    address1: new FormControl(''),
    zipcode: new FormControl(''),
    PCountry_Code: new FormControl(''),
    PState_Code: new FormControl(''),
    PDistrict_Code: new FormControl(''),
    PCity_Code: new FormControl(''),
    PPostOffice_Code: new FormControl(''),
    LVillage_Code: new FormControl(''),
    visitorcategoryasterList_Code: new FormControl(''),
    TypeOfIdentityMasterItem_Code: new FormControl(''),
    addharNo: new FormControl(''),
    drvingLicense: new FormControl(''),
    electionCard: new FormControl(''),
    breanginglaptop1: new FormControl(''),
    date: new FormControl(''),
    starttime: new FormControl(''),
    Enddate: new FormControl(''),
    endtime: new FormControl(''),
    actualdate: new FormControl(''),
    actualtime: new FormControl(''),
    temp: new FormControl(''),
    totannumber: new FormControl(''),
    lockernumber: new FormControl(''),
    visitorpurposeMasterList_Code: new FormControl(''),
    purposeofvisit1: new FormControl(''),
    mobileallowed: new FormControl(''),
    havematchbox: new FormControl(''),
    laptopallowed: new FormControl(''),
    havetoolkit: new FormControl(''),
    name1: new FormControl(''),
    identitytype: new FormControl(''),
    mobilenumber: new FormControl(''),
    pemailid: new FormControl(''),
    pjobtitle: new FormControl(''),
    Gender: new FormControl(''),
    ptemp: new FormControl(''),
    iteamtype: new FormControl(''),
    iteamdescription: new FormControl(''),
    serialno: new FormControl(''),
    isreturnable: new FormControl(''),
  });
  employeeDetailForm = new FormGroup({
  });
  docForm = new FormGroup({
  });
  
  constructor(private sharedService: SharedService,
    private visitorManagementService: VisitorManagementService,
    public sharedDataService: SharedDataService,
    public fileUploadService: FileUploadService,
    private logHelperService: LogHelperService,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private indiaMapService: IndiaMapService,
    private groupBarChartService: GroupBarChartService,
    private barChartService: BarChartService,
    private appQrCodeService: AppQrCodeService
  ) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

  }

  async ngOnInit() {
    this.getDataSet();
    this.getVisitorTimeLineDashboard();
    this.getVisitorPreAuthorizedDashboard();
    this.getVisitorActivityDashboard(null);
    this.getCompanyData();
    this.getPlants();
    this.getUnits();
    this.getDepartmentData();
    this.getCountry();
    this.gettotalcount();
    // this.startCamera();
    await this.visitorManagementService.getDocumentTypes().subscribe((res: any) => {
      this.documentTypes = res.Data;
    });

    this.sharedDataService.getMasterData().subscribe((masterList: any) => {
      //
      console.log('master data', masterList);
      this.masterData = masterList.Data.Table;

      this.visitorcategory = this.masterData.filter(list => list.Parent_Code === 968);
      this.visitorpurposetypeList = this.masterData.filter(list => list.Parent_Code === 976);
      this.gendertypes = this.masterData.filter(list => list.Parent_Code === 302);
      this.iteamtypes = this.masterData.filter(list => list.Parent_Code === 983);
      this.identityTypes = [
        { Id: 1, Name: 'Aadhar Card' },
        { Id: 2, Name: 'Driving License' },
        { Id: 3, Name: 'Election Card' }
      ];
      this.statusType = [
        { Id: 1, Name: 'Approved' },
        { Id: 2, Name: 'Reject' },
      ];
    });
  }
  getDataSet() {
    this.visitorManagementService.getDataSet().subscribe(res => this.bindChart(res));
  }

  bindChart(dataReal) {
    console.log('data', dataReal);
    const ndx1 = crossfilter(dataReal);

    this.visitorManagementService.getIndiaJson().subscribe((res) => {
      this.indiaMapService.generatemap(ndx1, res);
    });
    this.barTotalMonthlyVisitors = this.barChartService.barTotalMonthlyVisitors({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barTotalMonthlyVisitors',
      groupByProperty: null,
      reduceProperty: null,
      width: 550,
      height: 200,
      gap: 4,
      yAxisLabel: 'Total',
      xAxisLabel: 'Month Name',
      elasticX: true,
      elasticY: true,
    });

    this.barTotalweeklyVisitors = this.barChartService.barTotalweeklyVisitors({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barTotalweeklyVisitors',
      groupByProperty: null,
      reduceProperty: null,
      // width: 550,
      width: 430,
      height: 150,
      gap: 4,
      yAxisLabel: 'Total',
      xAxisLabel: 'day',
      elasticX: true,
      elasticY: true,
    });

    this.spanTotalMale = this.getCountChartGender(ndx1, '#spanTotalMale', true, false);
    this.spanTotalFemale = this.getCountChartGender(ndx1, '#spanTotalFemale', false, true);
  }
  getCountChartGender(ndx1, chartId, isMale, isFemale) {
    const chart = dc.numberDisplay(chartId, 'group1');
    const group = ndx1.groupAll().reduceSum((d) => {
      let cnt = 0;
      if (isMale && (d.Gender === 'Male' || d.Gender === 'MALE')) {
        cnt++;
      }

      if (isFemale && (d.Gender === 'Female' || d.Gender === 'FEMALE')) {
        cnt++;
      }
      return cnt;
    });

    chart
      .formatNumber(d3.format('.'))
      // .dimension(dimension)
      .valueAccessor(d => d)
      .group(group);
    chart.render();
    return chart;
  }
  getVisitorTimeLineDashboard() {

    this.visitorManagementService.VisitorTimeLineDashboard().subscribe((res: any) => {
      this.allData = res;
    });
  }
  gettotalcount() {

    this.visitorManagementService.gettotalcount().subscribe((res: any) => {
      this.totalTodayVisitor = res[0].TotalTodayVisitor;
      this.totalCurrentMonthVisitor = res[0].TotalCurrentMonthVisitor;
      this.totalLastMonthVisitor = res[0].TotalLastMonthVisitor;
    });
  }
  getVisitorPreAuthorizedDashboard() {

    this.visitorManagementService.VisitorPreAuthorizedDashboard().subscribe((res: any) => {
      this.allPreAuthorizedData = res;
    });
  }
  // tslint:disable-next-line: function-name
  SignIn(data) {

    this.getVisitorActivityDashboard(data.Visitor_Code);
  }

  getVisitorActivityDashboard(data: any) {

    this.visitorManagementService.VisitorActivityDashboard(data).subscribe((res: any) => {
      this.activityData = res;
    });
  }
  public openModal(): void {

    this.clearData();
    this.activeref = this.modalService.open(VisitorFormModalComponent,
      { size: 'lg', backdrop: 'static' });
      this.activeref.componentInstance.genderTypes = this.gendertypes;
      this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].setValue(1);
      this.show = true;
  }
  saveCroppedImage(): void {
    this.savedCroppedImage = this.croppedImage;
    this.modalRef.close();
  }
  private clearData() {
    this.visitorForm.reset();
    this.employeeDetailForm.reset();
    this.teashow = false;
    this.show = false;
  }
  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
  }
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
  }
  public onvisitordetail(): void {
    this.clearData();
    this.activeref.close('click');
  }
  changeIdentitytype() {
    if (this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].value === 1) {
      this.show = true;
      this.showdrivinglicence = false;
      this.showelectioncard = false;
    }
    if (this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].value === 2) {
      this.showdrivinglicence = true;
      this.show = false;
      this.showelectioncard = false;
    }
    if (this.visitorForm.controls['TypeOfIdentityMasterItem_Code'].value === 3) {
      this.showelectioncard = true;
      this.showdrivinglicence = false;
      this.show = false;
    }
  }
  // tslint:disable-next-line: function-name
  UploadDoc(): void {

    this.modalRef = this.modalService.open(this.uploadDocumentTemplateRef,
      { size: 'lg', backdrop: 'static', keyboard: false });
  }
  // tslint:disable-next-line: function-name
  Host() {
    this.modalRef = this.modalService.open(this.tempRef1,
      { size: 'lg', backdrop: 'static' });
    this.employeeDetail();
  }
  public onCloseAproval(): void {
    this.clearData();
    this.selectedRowData = [];
    this.modalRef.close('click');
  }
  employeeDetail() {

    return this.visitorManagementService.getEmployeeDetail().subscribe((res: any) => {
      this.allEmployeeData = res;
    });
  }
  getCompanyData() {
    return this.visitorManagementService.getCompanyMasterData().subscribe((response: any) => {
      this.companyData = response;
      if (this.companyData.length === 1) {
        // this.labourForm.control.value.Company;
        this.visitorForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);

      } else {
        this.companyData = response;
      }
    });
  }

  getPlants() {
    return this.visitorManagementService.getPlantMasterData().subscribe((res: any) => {
      this.plants = res;
      if (this.plants.length === 1) {
        this.visitorForm.controls['Plant_Code'].setValue(this.plants[0].Plant_Code);
      } else {
        this.plants = res;
      }
    });
  }

  getUnits() {

    return this.visitorManagementService.getUnitMasterData(this.visitorForm.controls['Plant_Code'].value || 100).subscribe((res: any) => {
      this.units = res.Data.Table;
      if (this.units.length === 1) {
        this.visitorForm.controls['Unit_Code'].setValue(this.units[0].Unit_Code);
      } else {
        this.units = res.Data.Table;
      }
    });
  }
  getCountry() {
    return this.visitorManagementService.getCountry().subscribe((response: any) => {
      this.pcountries = response;
    });
  }
  getDepartmentData() {

    // tslint:disable-next-line: max-line-length
    return this.visitorManagementService.getDepartmentData(this.visitorForm.controls['Unit_Code'].value || 100).subscribe((res: any) => {
      this.departmentData = res.Data.Table;
    });
  }

  getStatesByCountryIdP() {

    return this.visitorManagementService.getStatesByCountryId(this.visitorForm.controls['PCountry_Code'].value || 100)
      .subscribe((response: any) => {
        this.pStates = response;
      });
  }
  getDistrictsByStateP() {
    this.visitorManagementService.getDistrictsByState(this.visitorForm.controls['PState_Code'].value || 100).subscribe((response: any) => {
      this.pDistricts = response;
    });
  }

  getCitiesByDistrictP() {
    this.visitorManagementService.
    getCitiesByDistrict(this.visitorForm.controls['PDistrict_Code'].value || 100)
    .subscribe((response: any) => {
      this.pCities = response;
    });
  }
  getPostOfficeByCityP() {
    this.visitorManagementService.getPOsByCity(this.visitorForm.controls['PCity_Code'].value || 100).subscribe((res: any) => {
      this.pPos = res;
    });
  }
  getVillageP() {
    // tslint:disable-next-line: max-line-length
    this.visitorManagementService.getVillageByPostOffice(this.visitorForm.controls['PPostOffice_Code'].value || 100).subscribe((response: any) => {
      this.pVillages = response;
    });
  }


  // tslint:disable-next-line: function-name
  AddIteams(index?: number) {

    if (typeof index !== 'undefined') {
      this.iteamsViews[index] = {
        ItemTypeMasterItem_Code: this.visitorForm.controls.iteamtype.value,
        ItemDescription: this.visitorForm.controls.iteamdescription.value,
        SerialNo: this.visitorForm.controls.serialno.value,
        IsReturnableIteam: this.visitorForm.controls.isreturnable.value,
      };
      this.visitorForm.controls['iteamtype'].reset();
      this.visitorForm.controls['iteamdescription'].reset();
      this.visitorForm.controls['serialno'].reset();
      this.visitorForm.controls['isreturnable'].reset();
    } else {
      this.iteamsViews.push({
        ItemTypeMasterItem_Code: this.visitorForm.controls.iteamtype.value,
        ItemDescription: this.visitorForm.controls.iteamdescription.value,
        SerialNo: this.visitorForm.controls.serialno.value,
        IsReturnableIteam: this.visitorForm.controls.isreturnable.value,
      });
      this.visitorForm.controls['iteamtype'].reset();
      this.visitorForm.controls['iteamdescription'].reset();
      this.visitorForm.controls['serialno'].reset();
      this.visitorForm.controls['isreturnable'].reset();
    }
    return;

  }

  edititeams(index) {
    this.edititeamsIndex = index;
    this.hideUpdateiteams = false;
    this.visitorForm.controls['iteamtype'].setValue(this.iteamsViews[index].ItemTypeMasterItem_Code);
    this.visitorForm.controls['iteamdescription'].setValue(this.iteamsViews[index].ItemDescription);
    this.visitorForm.controls['serialno'].setValue(this.iteamsViews[index].SerialNo);
    this.visitorForm.controls['isreturnable'].setValue(this.iteamsViews[index].IsReturnableIteam);
  }

  updateiteams() {
    this.AddIteams(this.edititeamsIndex);
  }

  deleteiteams(index) {
    this.iteamsViews.splice(index, 1);
    this.hideUpdateiteams = true;
  }



  // tslint:disable-next-line: function-name
  AddOtherPersonDetail(index?: number) {

    if (typeof index !== 'undefined') {
      this.OtherPersonDetailViews[index] = {
        Name: this.visitorForm.controls.name1.value,
        pjobtitle: this.visitorForm.controls.pjobtitle.value,
        mobilenumber: this.visitorForm.controls.mobilenumber.value,
        Gender: this.visitorForm.controls.Gender.value,
        ptemp: this.visitorForm.controls.ptemp.value,
      };
      this.visitorForm.controls['name1'].reset();
      this.visitorForm.controls['pjobtitle'].reset();
      this.visitorForm.controls['mobilenumber'].reset();
      this.visitorForm.controls['Gender'].reset();
      this.visitorForm.controls['ptemp'].reset();

    } else {
      this.OtherPersonDetailViews.push({
        Name: this.visitorForm.controls.name1.value,
        JobTitle: this.visitorForm.controls.pjobtitle.value,
        MobileNo: this.visitorForm.controls.mobilenumber.value,
        GenderMasterItem_Code: this.visitorForm.controls.Gender.value,
        Temperature: this.visitorForm.controls.ptemp.value,
        EmailID: this.visitorForm.controls.pemailid.value,
        IdentityType_Code: this.visitorForm.controls.identitytype.value,

      });
      this.visitorForm.controls['name1'].reset();
      this.visitorForm.controls['pjobtitle'].reset();
      this.visitorForm.controls['mobilenumber'].reset();
      this.visitorForm.controls['Gender'].reset();
      this.visitorForm.controls['ptemp'].reset();
      this.visitorForm.controls['pemailid'].reset();
      this.visitorForm.controls['identitytype'].reset();
    }
    return;

  }

  editiperson(index) {
    this.editipersonIndex = index;
    this.hideUpdateOtherPerson = false;
    this.visitorForm.controls['name1'].setValue(this.OtherPersonDetailViews[index].Name);
    this.visitorForm.controls['pjobtitle'].setValue(this.OtherPersonDetailViews[index].JobTitle);
    this.visitorForm.controls['mobilenumber'].setValue(this.OtherPersonDetailViews[index].MobileNo);
    this.visitorForm.controls['Gender'].setValue(this.OtherPersonDetailViews[index].GenderMasterItem_Code);
    this.visitorForm.controls['ptemp'].setValue(this.OtherPersonDetailViews[index].Temperature);
    this.visitorForm.controls['pemailid'].setValue(this.OtherPersonDetailViews[index].EmailID);
    this.visitorForm.controls['identitytype'].setValue(this.OtherPersonDetailViews[index].IdentityType_Code);
  }

  updateiPersonDetail() {
    this.AddOtherPersonDetail(this.editipersonIndex);
  }

  deleteperson(index) {
    this.OtherPersonDetailViews.splice(index, 1);
    this.hideUpdateOtherPerson = true;
  }
  // tslint:disable-next-line: function-name
  IsCheck(item, event) {

    this.allData[item] = event.target.checked;
    if (this.allData[item] === true) {
      if (this.optionsChecked.indexOf(item.Employee_Code) === -1) {
        this.optionsChecked.push(item.Employee_Code);

      } else {
        this.optionsChecked.splice(this.optionsChecked.indexOf(item.Employee_Code), 1);
      }
    } else {
      this.optionsChecked.splice(this.optionsChecked.indexOf(item.Employee_Code), 1);

    }
  }
  // tslint:disable-next-line: function-name
  public SubmitRequest(): void {

    console.log('form data open modal', this.visitorForm.getRawValue());
    const value = this.visitorForm.getRawValue();
    const value1 = this.employeeDetailForm.getRawValue();
    if (this.optionsChecked.length > 0 && this.optionsChecked.length <= 1) {
      const data: any = {
        Shift_Code: this.editshiftCode1,
        Company_Code: value.Company_Code,
        Plant_Code: value.Plant_Code,
        Unit_Code: value.Unit_Code,
        Department_Code: value.Department_Code,
        VisitorCompany: value.yourcompany,
        FullName: value.name,
        JobTitle: value.jobtitle,
        MobileNo: value.mobileno,
        EmailID: value.emailid,
        OTP: value.otp,
        EmergancyContactName: value.emergencycontactname,
        EmergancyContactNo: value.emergencycontactno,
        Address1: value.address,
        Address2: value.address1,
        ZipCode: value.zipcode,
        Country_Code: value.PCountry_Code,
        State_Code: value.PState_Code,
        District_Code: value.PDistrict_Code,
        City_Code: value.PCity_Code,
        PostOffice_Code: value.PPostOffice_Code,
        Village_Code: value.LVillage_Code,
        VisitorCategory_Code: value.visitorcategoryasterList_Code,
        IDProofType_Code: value.TypeOfIdentityMasterItem_Code,
        AddharNo: value.addharNo,
        DrivingLicenceNo: value.drvingLicense,
        ElectionCardNo: value.electionCard,
        BringLaptop: value.breanginglaptop1,
        StartTime: value.starttime,
        StartDate: value.date,
        EndDate: value.Enddate,
        EndTime: value.endtime,
        ActualDate: value.actualdate,
        ActualTime: value.actualtime,
        Temperature: value.temp,
        TokenNumber: value.totannumber,
        LockerNumber: value.lockernumber,
        VisitorPurposeType_Code: value.visitorpurposeMasterList_Code,
        Visitorpurpose: value.purposeofvisit1,
        HaveMobile: value.mobileallowed,
        HaveToolkit: value.havetoolkit,
        HaveLaptop: value.laptopallowed,
        HaveMatchBox: value.havematchbox,
        CreatedBy: localStorage.getItem('UserID'),
        RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
        RequestRefCode: this.optionsChecked,
        OtherPersonDetailViews: this.OtherPersonDetailViews,
        iteamsViews: this.iteamsViews,
      };
      const uploadParams = {
        Visitor_Code: ''
      };
      // if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.visitorManagementService.SendRequest(data).subscribe((response: any) => {
          uploadParams.Visitor_Code = response.Data;
          if (uploadParams.Visitor_Code) {
            this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.VISITOR_MANAGEMENT.UPLOAD_DOC)
              .subscribe((res) => {
                this.logHelperService.logSuccess({
                  message: 'Request Submitted successfully'
                });
                this.getVisitorTimeLineDashboard();
                this.getVisitorPreAuthorizedDashboard();
                this.getVisitorActivityDashboard(null);
                this.docForm.reset();
                this.clearData();
                this.modalRef.close();
                this.activeref.close();
                this.docForm.reset();
              });
          }
          this.logHelperService.logSuccess({
            message: 'Request Submitted successfully'
          });
          this.getVisitorTimeLineDashboard();
          this.getVisitorPreAuthorizedDashboard();
          this.getVisitorActivityDashboard(null);
          this.clearData();
          this.modalRef.close();
          this.activeref.close();
          this.docForm.reset();
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select Only One Employee '
      });
    }
  }

  // tslint:disable-next-line: function-name
  CheckOut(activity) {

    this.visitorManagementService.cheout(activity.Visitor_Code).subscribe((response: any) => {
      this.logHelperService.logSuccess({
        message: 'Successfully Updated'
      });
      this.getVisitorTimeLineDashboard();
      this.getVisitorActivityDashboard(null);
    });
  }

  verifyVisitor() {
    this.activeref = this.modalService.open(VerifyVisitorComponent,
      { size: 'lg', backdrop: 'static' });
  }

  readQrCode() {
    this.appQrCodeService.generateQRCode('QR Code');
  }
}
