import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { CoreModule } from '../core/core.module';
import { ContractorAdapter } from './contractor-adapter/contractor.adpater';
import { ContractorRoutingModule } from './contractor-routing.module';
import { ContractorsComponent, ContractorsService, CreateSubContractorComponent, } from './contractors';
import { ContractorDetailsComponent } from './contractors/contractor-details/contractor-details.component';
import { CreateContractorComponent } from './contractors/create-contractor/create-contractor.component';
import { DetailsGridWrapperComponent } from './details-grid-wrapper/details-grid-wrapper.component';
import { EcPoliciesComponent, EcPoliciesService } from './ec-policies';
import { EcPoliciesFormComponent } from './ec-policies/ec-policies-form/ec-policies-form.component';
import { LicencesComponent } from './licences/licences.component';
import { LicencesService } from './licences/licences.service';
import { LicencesFormComponent } from './licences/licenses-form/licenses-form.component';
import { OnlyNumbeDirective } from './work-orders/work-order-form/only-number.directive';
import { WorkOrderFormModalComponent } from './work-orders/work-order-form/work-order-form-modal.component';
import { WorkOrderFormWizardComponent } from './work-orders/work-order-form/work-order-form-wizard/work-order-form-wizard.component';
import { WorkOrderGroupRowComponent } from './work-orders/work-order-group-row/work-order-group-row.component';
import { WorkOrdersComponent } from './work-orders/work-orders.component';
import { WorkOrdersService } from './work-orders/work-orders.service';
import { CreateSubWorkOrderComponent } from './work-orders/create-sub-work-order/create-sub-work-order.component';
import { WorkOrderCellRendererComponent } from './work-orders/work-order-cell-renderer/work-order-cell-renderer.component';

@NgModule({
  exports: [
    ContractorRoutingModule
  ],
  declarations: [
    ContractorsComponent,
    CreateSubContractorComponent,
    CreateContractorComponent,
    EcPoliciesComponent,
    WorkOrdersComponent,
    LicencesComponent,
    WorkOrderFormModalComponent,
    WorkOrderFormWizardComponent,
    OnlyNumbeDirective,
    EcPoliciesFormComponent,
    LicencesFormComponent,
    WorkOrderGroupRowComponent,
    ContractorDetailsComponent,
    DetailsGridWrapperComponent,
    CreateSubWorkOrderComponent,
    WorkOrderCellRendererComponent
  ],
  imports: [
    CommonModule,
    ContractorRoutingModule,
    CoreModule.forRoot(),
    SweetAlert2Module,
    //ClipboardModule,
  ],
  providers: [
    ContractorsService,
    EcPoliciesService,
    WorkOrdersService,
    ContractorAdapter,
    LicencesService,
  ],
  entryComponents: [
    WorkOrderFormWizardComponent,
    WorkOrderGroupRowComponent,
    ContractorDetailsComponent
  ]
})
export class ContractorModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ContractorModule,
      providers: [
        ContractorsService,
        EcPoliciesService,
        // WorkOrderFormService
      ]
    };
  }
}
