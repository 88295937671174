import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator, FormControl, NgModel } from '@angular/forms';
import { TreeviewConfig, TreeviewI18n, DropdownTreeviewComponent, TreeviewItem, TreeviewHelper } from '../ngx-treeview';
import { isNil } from 'lodash';

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutocompleteTreeviewComponent),
  multi: true
};
export const DROPDOWN_CONTROL_VALIDATION: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AutocompleteTreeviewComponent),
  multi: true,
};
const noop = () => {
};

@Component({
  selector: 'app-autocomplete-treeview',
  templateUrl: './autocomplete-treeview.component.html',
  styleUrls: ['./autocomplete-treeview.component.css'],
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR, DROPDOWN_CONTROL_VALIDATION],
})
export class AutocompleteTreeviewComponent implements OnInit, ControlValueAccessor, Validator {

  /**
   * @description if set to true treeview component will be displayed
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() treeView = false;

  /**
   * @description
   */
  @Input() isReactiveForm: boolean;

  @Input() formControl: FormControl;

  @Output() private changeSelectValue: EventEmitter<any[]> = new EventEmitter();
  private onTouchedCallback: (_: any) => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  /**
   * @description reference of tree view drop down component
   * @type {DropdownTreeviewComponent}
   * @memberof AutocompleteTreeviewComponent
   */
  @ViewChild(DropdownTreeviewComponent) dropdownTreeviewComponent: DropdownTreeviewComponent;

  /**
   * @description local variable of items
   * @private
   * @type {any[]}
   * @memberof AutocompleteTreeviewComponent
   */
  private selectedItemsLocal: any[] = [];


  /**
   * @description items list for tree view
   * @type {TreeviewItem[]}
   * @memberof AutocompleteTreeviewComponent
   */
  treeViewItems: TreeviewItem[] = [];

  /**
   * @description local variable for auto complete list
   * @private
   * @type {any[]}
   * @memberof AutocompleteTreeviewComponent
   */
  private autoCompleteItems: any[] = [];

  /**
   * @description Global configuration for auto complete
   * @type {object}
   * @memberof AutocompleteTreeviewComponent
   */
  config: object;

  /**
   * @description list of selection items
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() public set items(value: any[]) {
    if (this.treeView) {
      if (value) {
        this.treeViewItems = this.populateTreeviewItems(value);
      }
    } else {
      this.autoCompleteItems = value;
    }
  }
  public get items(): any[] {
    if (this.treeView) {
      return this.treeViewItems;
      // tslint:disable-next-line:no-else-after-return
    } else {
      return this.autoCompleteItems;
    }
  }

  /**
   * @description if true component will be disabled
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() disabled = false;

  /**
   * @description if set to false checkboxes will be hidden
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() showCheckBox = true;

  /**
   * @description name of the control for template driven form validations
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() name;

  /**
   * @description if set to false only single selection will be allowed
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() multiSelect = true;

  /**
   * @description placeholder of the component
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() placeHolder = '';

  /**
   * @description apply extra classes on component
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() extraClass = '';

  /**
   * @description identifier value in the array of items
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() primaryKey = 'id';

  /**
   * @description displau label
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() labelKey = 'itemName';

  /**
   * @description enables check all if set to true
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() enableCheckAll = false;

  /**
   * @description ref of the component to manipulate the template driven form validations
   * @type {NgModel}
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() ref: NgModel;

  /**
   * @description default position of the dropdown
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() position = 'bottom';

  /**
   * @description two way data binding of the values
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() public set ngModel(value: any[]) {
    this.selectedItemsLocal = value;
    this.ngModelChange.emit(value);
  }
  public get ngModel(): any[] {
    return this.selectedItemsLocal;
  }
  @Output() ngModelChange: EventEmitter<any[]> = new EventEmitter();

  /**
   * @description if set to true treeview component will be displayed without dropdown theme
   * @memberof AutocompleteTreeviewComponent
   */
  @Input() isDropDownDisplay = true;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  /**
   *Creates an instance of AutocompleteTreeviewComponent.
   * @author Amit Mahida
   * @param {TreeviewI18n} i18n
   * @memberof AutocompleteTreeviewComponent
   */
  constructor(
    public i18n: TreeviewI18n
  ) {
  }

  ngOnInit() {
    if (this.treeView) {
      this.extraClass = `inputcolor ${this.extraClass}`,
        this.config = TreeviewConfig.create({
          hasFilter: true,
          hasAllCheckBox: this.enableCheckAll,
          hasCollapseExpand: true,
          decoupleChildFromParent: true,
          maxHeight: 250,
          labelKey: this.labelKey,
          primaryKey: this.primaryKey,
          placeHolder: this.placeHolder
        });
    } else {
      this.config = {
        singleSelection: !this.multiSelect,
        enableCheckAll: this.enableCheckAll,
        text: this.placeHolder,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: `inputcolor ${this.extraClass}`,
        badgeShowLimit: 2,
        enableSearchFilter: true,
        enableFilterSelectAll: true,
        showCheckbox: this.showCheckBox,
        labelKey: this.labelKey,
        primaryKey: this.primaryKey,
        position: this.position,
        disabled: this.disabled
      };
    }
  }

  onOpen() {
    if (this.isReactiveForm) {
      this.formControl.markAsTouched();
    } else {
      this.ref.control.markAsTouched();
    }
  }

  onItemSelect() {
    let selectedValue;
    if (this.isReactiveForm) {
      this.formControl.markAsDirty();
      selectedValue = this.formControl;
    } else {
      this.ref.control.markAsDirty();
      selectedValue = this.ngModel;
    }
    if (!this.multiSelect) {
      this.onChange.emit(selectedValue[0][this.primaryKey]);
      (this.isReactiveForm) ? this.formControl.setValue(selectedValue[0][this.primaryKey]) :
        this.ref.control.setValue(selectedValue[0][this.primaryKey]);
    } else {
      this.onChange.emit(this.ngModel);
      (this.isReactiveForm) ? this.formControl.setValue(selectedValue[0][this.primaryKey]) :
        this.ref.control.setValue(this.ngModel);
    }
  }
  onItemDeSelect() {
    if (this.isReactiveForm) {
      this.onChange.emit(this.formControl.value);
      this.formControl.setValue(this.formControl.value);
    } else {
      this.onChange.emit(this.ngModel);
      this.ref.control.setValue(this.ngModel);
    }

  }
  onSelectAll() {
    if (this.isReactiveForm) {
      this.onChange.emit(this.formControl.value);
      this.formControl.setValue(this.formControl.value);
    } else {
      this.onChange.emit(this.ngModel);
      this.ref.control.setValue(this.ngModel);
    }
  }
  onDeSelectAll() {
    if (this.isReactiveForm) {
      this.onChange.emit(this.formControl.value);
      this.formControl.setValue(this.formControl.value);
    } else {
      this.onChange.emit(this.ngModel);
      this.ref.control.setValue(this.ngModel);
    }
  }


  /**
   * @description event when ngModel value updates
   * @author Amit Mahida
   * @param {*} value
   * @memberof AutocompleteTreeviewComponent
   */
  writeValue(value: any) {
    if (value !== undefined && value !== null && value !== '') {
      if (!this.multiSelect) {
        try {
          if (value.length > 1) {
            this.ngModel = [value[0]];
            // throw new MyException(404, { msg: 'Single Selection Mode, Selected Items cannot have more than one item.' });
          } else {
            this.ngModel = value;
          }
        } catch (e) {
          console.error(e.body.msg);
        }
      } else {
        // if (this.settings.limitSelection) {
        //   this.selectedItems = value.slice(0, this.settings.limitSelection);
        // }
        // else {
        this.ngModel = value;
        // }
        // if (this.selectedItems.length === this.data.length && this.data.length > 0) {
        //   this.isSelectAll = true;
        // }
      }
    } else {
      if (this.isReactiveForm) {
        this.formControl.markAsUntouched();
      } else {
        this.ngModel = [];
        this.ref.control.markAsUntouched();
      }
    }
  }

  /**
   * @description From ControlValueAccessor interface
   * @author Amit Mahida
   * @param {*} fn
   * @memberof AutocompleteTreeviewComponent
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /**
   * @description From ControlValueAccessor interface
   * @author Amit Mahida
   * @param {*} fn
   * @memberof AutocompleteTreeviewComponent
   */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /**
   * @description
   * @author Amit Mahida
   * @param {FormControl} c
   * @returns {*}
   * @memberof AutocompleteTreeviewComponent
   */
  public validate(c: FormControl): any {
    return null;
  }

  select(item: TreeviewItem) {
    if (item.children === undefined) {
      this.selectItem(item);
    }
  }

  /**
   * @description returns all selected options in tree
   * @author Amit Mahida
   * @param {any[]} selectedItems
   * @memberof AutocompleteTreeviewComponent
   */
  onSelectedChange(selectedItems: any[]) {
    if (this.isReactiveForm) {
      this.formControl.setValue(selectedItems);
    } else {
      this.ngModel = selectedItems;
      this.ref.control.setValue(this.ngModel);
    }
    if (selectedItems.length > 0) {
      this.changeSelectValue.emit(selectedItems);
    }
  }

  private selectItem(item: TreeviewItem) {
    if (this.isReactiveForm) {
      this.formControl.markAsDirty();
    } else {
      this.ref.control.markAsDirty();
    }

    if (!this.multiSelect) {
      this.dropdownTreeviewComponent.dropdownDirective.close();
    }
  }

  /**
   * @description populates tree view array from given items list
   * @author Amit Mahida
   * @param {any[]} [items=[]]
   * @returns {TreeviewItem[]}
   * @memberof AutocompleteTreeviewComponent
   */
  populateTreeviewItems(items: any[] = []): TreeviewItem[] {
    const treeViewItems: TreeviewItem[] = [];
    items.forEach((element) => {
      treeViewItems.push(new TreeviewItem(element, false, this.primaryKey, this.labelKey));
    });
    return treeViewItems;
  }
}
