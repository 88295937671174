import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AgActionCellRendererComponent } from 'src/app/core/components';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';
import { Subject } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
// tslint:disable-next-line: max-line-length
import * as _ from 'lodash';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-rpt-view-list-privious-day-records',
  templateUrl: './rpt-view-list-privious-day-records.component.html',
  styleUrls: ['./rpt-view-list-privious-day-records.component.css']
})
export class RptViewListPreviousdayrecordsComponent implements OnInit {

  rowClassRules;
  public tableRowData: any[] = [];
  public tableRowData1: any[] = [];
  public myMap: any[] = [];
  public myMap1: any[] = [];
  public reportDate: string;
  public totalData: number;
  private selectedReportInfo: Date;
  cValue = new Date();
  myDate = formatDate(this.cValue, 'dd-MM-yyyy', 'en-US');
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  @Input()
  public set selectedReportData(value: Date) {
    if (value) {

      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): Date {
    return this.selectedReportInfo;
  }


  @Input()
  public set reportData(value: any[]) {
    if (value) {

      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
      // this.rowData = value;
    }
  }
  public get reportData(): any[] {
    return this.reportValueData;
  }
  public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  columnDefs: any[] = [];
  rowData = [];
  groupingMeta = ['Company'];
  private reportValueData: any[];

  constructor(private sharedService: SharedService, private router: Router
  ) {
    this.closeModal = new EventEmitter();
  }

  ngOnInit() {
    this.totalData = this.reportValueData.length;
    console.log('a', this.totalData);
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };
    this.createColumnDef();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public onPrint(): void {

    const data = {
      selectedReportData: this.selectedReportData,
      reportData: this.reportData
    };
    this.myMap = this.getGroupRowTableData1(this.reportData);
    console.log('	this.myMap', this.myMap);
    // this.myMap1 = Array.from(this.myMap.keys());

    this.tableRowData = this.getGroupRowTableData(this.reportData);
    setTimeout(() => {
      this.btnPrint.nativeElement.click();
      localStorage.setItem('report-data', JSON.stringify(data));
      // this.router.navigate(['/reports/rpt-timecard-summary']);
    }, 100);
  }


  private createColumnDef(): void {
    this.columnDefs = [
      {
        headerName: 'Sr. No', field: '', width: 40,
        sortable: false, suppressMenu: true, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'Company',
        field: 'Company',
        hide: true, visible: false,
      },
      {
        headerName: 'ULC', width: 100, sortable: true, filter: true,
        filterParams: { applyButton: true, clearButton: true },
        valueGetter(params) {
          return params.data.group ? params.data.group : params.data.ULC;
        },
        colSpan: (params) => {
          return params.data.group ? 3 : 1;
        },
      },
      {
        headerName: 'Punch',
        field: 'Punch', width: 80, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'IN/OUT',
        field: 'IN/OUT', width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center'
      },
      {
        headerName: 'IsDeleted',
        field: 'IsDeleted', width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center'
      },
    ];
  }

  private rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }

  onEdit(params) {
    // this.editClick.emit(params);
  }

  onDelete(params) {
    // this.deleteClick.emit(params);
  }

  nestGroupBy = (seq, keys) => {
    // tslint:disable-next-line: curly
    if (!keys.length)
      return seq;
    // tslint:disable-next-line: no-var-keyword
    // tslint:disable-next-line: prefer-const
    let first = keys[0];
    const rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return this.nestGroupBy(value, rest);
    });
  }

  getGroupRowTableData1(reportData: any[]): any[] {
    // tslint:disable-next-line: max-line-length
    const data = this.nestGroupBy(reportData, ['ContractorName']);
    console.log('data', data);
    const tableData1 = [];
    return data;
  }
  getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    let index = 1;
    // reportData =  reportData.filter((item,index)=>index <= 10)
    const groupData = new Set(reportData.map(item => item.ContractorName));
    groupData.forEach((groupItem: string) => {
      const items = reportData.filter(item => item.ContractorName === groupItem);
      items.forEach((item: any) => {
        item.index = index;
        index++;
      });
      const rowItems = {
        items,
        ContractorName: groupItem
      };
      tableData.push(rowItems);
    });
    return tableData;
  }
  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    // tslint:disable-next-line: max-line-length
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} To ${date1.getDate()}-${months[date1.getMonth()]}-${date1.getFullYear()}`;
  }
}
