import { AbstractControl, ValidatorFn } from '@angular/forms';

export function percentageValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value == null || control.value === '') {
      return null; // Allow empty value if needed
    }
    const value = control.value as string;
    // Check if the value matches the format with one decimal place
    const validFormat = /^(100(\.00)?|[1-9]{1,2}(\.[0-9]{1,2})?)$|^(100(\.0{1,2})?)$|^([1-9]?[0-9](\.[0-9]{1,2})?)$/.test(value);

    if (!validFormat) {
      return { 'invalidPercentage': true };
    }

    const numericValue = parseFloat(value);
    if (numericValue < 0 || numericValue > 100) {
      return { 'invalidPercentage': true };
    }
    return null;
  };
}
