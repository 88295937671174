import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  pageLink : string[] = []

  constructor() { }
  private isAuthenticated = false;

  userPermission(pages: any) {
    this.pageLink = this.extractPageLinks(pages);
    const pageLinkJSON = JSON.stringify(this.pageLink);
    // Store the JSON string in local storage
    localStorage.setItem("pageLinks", pageLinkJSON);
    // Recursive function to extract PageLinks that are not '#'
  }
  extractPageLinks(data: any): string[] {
    const pageLinks: string[] = [];
    if (data) {
      for (const menu of data) {
        for (const i of menu.Menus) {
            for (const subMenu of i.subMenus) {
              this.extractPageLinksRecursive(subMenu, pageLinks);
          }
        }
      }
    }

    return pageLinks;
  }

  extractPageLinksRecursive(menu: any, pageLinks: string[]) {
    if (menu.PageLink && menu.PageLink !== "#") {
      if(!!menu.PageLink.startsWith('/')) {
        pageLinks.push(menu.PageLink);
      } else {
        pageLinks.push('/' + menu.PageLink);
      }
      
    }

    if (menu.subMenus && menu.subMenus.length > 0) {
      for (const subMenu of menu.subMenus) {
        this.extractPageLinksRecursive(subMenu, pageLinks);
      }
    }
  }

  hasPermission(permission: string): boolean {
    const pages = JSON.parse(localStorage.getItem('pageLinks'));
    const parts = permission.split('/');
    const lastPart = parts[parts.length - 1];

    // Check if the last part is a number using a regular expression
    const isNumeric = /^\d+$/.test(lastPart);

    // Exclude the numeric check by removing the last part and then comparing the URL
    const cleanedUrl = isNumeric ? parts.slice(0, -1).join('/') : permission;
    return pages.includes(cleanedUrl);
  }

  // Check if the user is authenticated
  isAuthenticatedUser() {
    this.isAuthenticated = localStorage.getItem('token') ? true : false;

    return this.isAuthenticated;
  }
}
