import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbTabChangeEvent, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL, ROLECODE } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CookieService } from 'ngx-cookie-service';
import { DISABLED, FormGroup } from '@angular/forms/src/model';
import * as _ from 'lodash';
import { FormBuilder } from '@angular/forms';
import { LabourProfileImageComponent } from '../../labours/create-sub-labour/labour-profile-image/labour-profile-image.component';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-edit-gatepass-request',
  templateUrl: './edit-gatepass-request.component.html',
  styleUrls: ['./edit-gatepass-request.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class EditGatepassRequestModalComponent implements OnInit {

  @ViewChild('uploadDocumentTemplateRef') uploadDocumentTemplateRef: TemplateRef<any>;

  @Input() public data: any;
  @Input() public siteshiftGroupList: any[] = [];
  @Output() public saveObj: EventEmitter<any>;
  @Output() public close: EventEmitter<void>;
  public formGroup: FormGroup;

  croppedImage: any = '';
  savedCroppedImage: any = '';

  constructor(
    private fb: FormBuilder,
    private gatePassRequestsService: GatePassRequestsService,
    private modalService: NgbModal
  ) {
    this.saveObj = new EventEmitter();
    this.close = new EventEmitter();

    this.formGroup = this.buildFormGroup();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.formGroup.patchValue(this.data);
      this.formGroup.get('OldSiteShiftGroup_Code').setValue(this.data.SiteShiftGroup_Code);
      this.savedCroppedImage = this.data.Photo;
      this.croppedImage = this.data.Photo;
    }
    this.checkUserRole();
    this.getSiteShiftGroupData();
  }

  public saveData(): void {
    const formValue = this.formGroup.getRawValue();
    this.saveObj.emit({ ...this.data, ...formValue, Photo: this.croppedImage });
  }

  public stepclose(): void {
    this.close.emit();
  }

  public captureImage(): void {
    const activeModal = this.modalService.open(LabourProfileImageComponent, { size: 'lg', backdrop: 'static' });
    activeModal.componentInstance.image = this.savedCroppedImage;
    activeModal.componentInstance.isShowbutton = true;

    activeModal.componentInstance.croppedImage.subscribe((arg: any) => {
      this.savedCroppedImage = arg;
      this.croppedImage = arg;
      activeModal.close();
    });

    activeModal.componentInstance.closeModal.subscribe(() => {
      activeModal.close();
    });
  }

  public onShiftChange(): void {
    const shiftGroupCode = this.formGroup.get('SiteShiftGroup_Code').value;
    if(shiftGroupCode) {
      this.formGroup.get('ApplicableDate').setValue(moment());
    }
  }

  private getSiteShiftGroupData() {
    return this.gatePassRequestsService.getSiteShiftGroupData().subscribe((res: any) => {
      this.siteshiftGroupList = res.Data.Table;
      if (this.data) {
        this.formGroup.get('SiteShiftGroup_Code').setValue(this.data.SiteShiftGroup_Code);
      }
    });
  }

  private checkUserRole(): void {
    const currentUserRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (currentUserRoles) {
      const isRoleExist1 = currentUserRoles.some(item => [118, 120, 121, 122].includes(item.Role_Code));
      if (!isRoleExist1) {
        this.formGroup.get('ValidUptoDate').disable();
        this.formGroup.get('SiteShiftGroup_Code').disable();
        this.formGroup.get('ApplicableDate').disable();
        this.formGroup.get('IsEnrolled').disable();
      }
      const isRoleExit2 = currentUserRoles.some(item => [110, 120].includes(item.Role_Code));
      if (!isRoleExit2) {
        this.formGroup.get('IsFireFighter').disable();
      }
      const isRoleExit3 = currentUserRoles.some(item => [119].includes(item.Role_Code));
      if (!isRoleExit3) {
        
        this.formGroup.get('IsGatePassIssued').disable();
        this.formGroup.get('JoiningDate').disable();
      }
      const isRoleExit4 = currentUserRoles.some(item => [118, 120, 121, 122, 119].includes(item.Role_Code));
      if (!isRoleExit4) {

      }
    }
  }


  private buildFormGroup(): FormGroup {
    return this.fb.group({
      ValidUptoDate: [null],
      SiteShiftGroup_Code: [null],
      OldSiteShiftGroup_Code: [null],
      ApplicableDate: [moment()],
      IsEnrolled: [null],
      IsFireFighter: [null],
      IsGatePassIssued: [null],
      JoiningDate: [null],
      PHotoUpdate: [null],
    });
  }
}