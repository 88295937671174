import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
declare var $: any;
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ShiftgroupService {
  httpClient: any;

  constructor(
    private http: HttpClient
  ) { }

  getCompanyData() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_COMPANY_DATA}`,
      httpOptions
    );
  }

  getPlants() {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_PLANTS}?Search=&Company=&State=&City=&Country=&SDate=&EDate=&UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`);
  }

  getShifts() {
    const model = {
      Search: '',
      Plant_Code: '',
      ShiftTypeGroup: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_SHIFTS}`, model);
  }

  //  return this.http.request(
  //  GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_SHIFTS}?Search=&PlantCode=&ShiftTypeGroupCode=`,
  // httpOptions
  // );
  // }

  getShiftGroupByID(shiftgroupCode) {
    return this.http.get(
      `${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_SHIFTGROUPBYID}?shiftgroupCode=${shiftgroupCode}`,
      // `http://localhost:59557/api/Shift/GetShiftGroupProfile?shiftgroupCode=${shiftgroupCode}`,
    );
  }

  addShiftGroup(shiftGroupParams): Observable<Object> {
    const body = JSON.stringify(shiftGroupParams);
    httpOptions['body'] = body;
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.SHIFT_MANAGEMENT.POST_INSERT_SHIFTGROUP_DATA}`,
      // 'http://localhost:59557/api/Shift/InsertShiftGroupData',
      httpOptions
    );
  }

  updateShiftGroup(shiftGroupParams): Observable<Object> {
    const body = JSON.stringify(shiftGroupParams);
    httpOptions['body'] = body;
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.SHIFT_MANAGEMENT.POST_UPDATE_SHIFTGROUP_DATA}`,
      // 'http://localhost:59557/api/Shift/UpdateShiftGroup',
      httpOptions
    );
  }

  /**
   * getAllShiftGroups
   */
  getAllShiftGroups() {
    const model = {
      Search: '',
      Plant_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_All_SHIFT_GROUP_DATA}`, model);
  }

  //    return this.http.request(
  //    GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_All_SHIFT_GROUP_DATA}?Search=&PlantCode=`,
  // 'http://localhost:59557/api/Master/GetShiftGroup?Search=&PlantCode=',
  // httpOptions
  // );
  // }

  /**
   * GetCompanyData
   */
  getShiftGroupCode() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.SHIFT_MANAGEMENT.GET_SHIFT_GROUP_CODE}`,
      httpOptions
    );
  }


}
