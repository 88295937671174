import { Contractor, WorkOrder } from '../department-training.model';

export class DepartmenttrainingAdapter {
  toContractorResponse(item: any): Contractor {
    const contractor: Contractor = new Contractor();
    contractor.company = item.Company;
    contractor.contractorVendorCode = item.ContractorVendor_Code;

    return contractor;
  }

  toWorkOrderResponse(item: any): WorkOrder {
    const workOrder: WorkOrder = new WorkOrder();
    workOrder.workOrderNo = item.WorkOrderNo;
    workOrder.workOrderCode = item.WorkOrder_Code;

    return workOrder;
  }

  convertResponse<T>(item: any): T[] {
    return item.Data.Table;
  }
  convertToDataResponse<T>(item: any): T[] {
    return item.Data;
  }

  parseJsonString(item: any): any {
    item.JSONText = item.JSONText ? JSON.parse(item.JSONText) : '';
    return item;
  }
}
