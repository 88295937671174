import { ViewChildren, QueryList, NgZone, OnDestroy } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { closeNgSelect } from '../utility/utility';

/** base classes for closing the select dropdown */
export class BaseCloseSelectDropdown implements OnDestroy {

  /** ng-select dropdown reference */
  @ViewChildren(NgSelectComponent) public ngSelects: QueryList<NgSelectComponent>;
  /** Destroy of customer form presentation component */
  public destroy: Subject<void>;

  constructor(public window: Window, public zone: NgZone) {
    this.destroy = new Subject<void>();
    zone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll, true);
    });
  }

  /** it will on component destroy */
  public ngOnDestroy(): void {
    this.window.removeEventListener('scroll', this.scroll, true);
    this.destroy.next();
    this.destroy.complete();
  }

  /** on scroll event for hide the ng-select dropdown */
  public scroll = (event: any): void => {
    this.zone.run(() => {
      if (this.ngSelects) {
        this.ngSelects.forEach((item: NgSelectComponent) => {
          closeNgSelect(item, event);
        });
      }
    });
  }
}
