import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/core/services';

import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
  private locModel;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Input() public set model(value: any) {
    this.locModel = value;
    let newVal = '';
    if (moment(value).isValid()) {
      newVal = moment(value).format('YYYY-MM-DD');
    }
    this.modelChange.emit(newVal);
  }
  public get model() {
    return this.locModel;
  }
  @Input() id;
  @Input() name;

  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
  }

}
