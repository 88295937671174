import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgActionCellRendererComponent } from '../../../../core/components';

@Component({
  selector: 'app-weekoff-policy',
  templateUrl: './weekoff-policy.component.html'
})
export class WeekOffPolicyComponent {


  @Input() public set rowData(value: any[]) {
    if (value) {
      this._rowData = value;
    }
  }
  public get rowData(): any[] {
    return this._rowData;
  }

  @Output() public edit: EventEmitter<any>;
  @Output() public delete: EventEmitter<any>;

  private _rowData: any[] = [];

  public columnDefs: any[];


  constructor() {
    this.edit = new EventEmitter<any>();
    this.delete = new EventEmitter<any>();
  }

  public ngOnInit(): void {
    this.columnDefs = this.createColumnDef(this.rowData);
  }

  public editItem(data: any): void {
    this.edit.emit(data);
  }

  public deleteItem(data: any): void {
    this.delete.emit(data);
  }

  private createColumnDef(data: any[]): any[] {
    const columns: any[] = [];
    if (data && data.length) {
      Object.keys(data[0]).forEach((item: any) => {
        if (item !== 'Policy_Code') {
          columns.push({
            headerName: item,
            // headerClass: 'text-left',
            field: item,
            sortable: true,
            width: 70,
            filter: true
          });
        }
      });

      columns.push({
        headerName: 'Action', field: '', width: 40, headerComponentParams: { enableSorting: false },
        cellStyle: { textAlign: 'center' },
        cellRendererFramework: AgActionCellRendererComponent
      });
    }

    return columns;
  }

  // private createColumnDef(): void {
  //   this.columnDefs = [
  //     {
  //       headerName: '#', field: '', width: 30,
  //       sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
  //     },
  //     {
  //       headerName: 'ID', field: 'WeekOFFPolicy_Code', sortable: true, width: 30, filter: true,
  //       cellStyle: { textAlign: 'center' },
  //       hide: false,
  //     },
  //     { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 70, filter: true },
  //     { headerName: 'Policy Number', headerClass: 'text-left', field: 'ShortCode', sortable: true, width: 70, filter: true },
  //     { headerName: 'Version', headerClass: 'text-left', field: 'Version', sortable: true, width: 70, filter: true },
  //     { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 70, filter: true },
  //     { headerName: 'Applicable Date', headerClass: 'text-left', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
  //     { headerName: 'Validity Date', headerClass: 'text-left', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
  //     { headerName: 'Default For', field: 'SetDefault', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },

  //     { headerName: '1W SA', headerClass: 'text-left', field: 'FirstWeek1', sortable: true, width: 70, filter: true },
  //     { headerName: '1W SU', headerClass: 'text-left', field: 'FirstWeek2', sortable: true, width: 70, filter: true },
  //     // { headerName: 'IsPresentOTWeek1', field: 'IsPresentOTWeek1', sortable: true, width: 70, filter: true },
  //     { headerName: '2W SA', headerClass: 'text-left', field: 'SecoundWeek1', sortable: true, width: 70, filter: true },
  //     { headerName: '2W SU', headerClass: 'text-left', field: 'SecoundWeek2', sortable: true, width: 70, filter: true },
  //     // { headerName: 'IsPresentOTSWeek2', field: 'IsPresentOTSWeek2', sortable: true, width: 70, filter: true },
  //     { headerName: '3W SA', headerClass: 'text-left', field: 'ThirdWeek1', sortable: true, width: 70, filter: true },
  //     { headerName: '2W SU', headerClass: 'text-left', field: 'ThirdWeek2', sortable: true, width: 70, filter: true },
  //     // { headerName: 'IsPresentOTTWeek3', field: 'IsPresentOTTWeek3', sortable: true, width: 70, filter: true },
  //     { headerName: '4W SA', headerClass: 'text-left', field: 'FourthWeek1', sortable: true, width: 70, filter: true },
  //     { headerName: '4W SU', headerClass: 'text-left', field: 'FourthWeek2', sortable: true, width: 70, filter: true },
  //     { headerName: '5W SA', headerClass: 'text-left', field: 'FifthWeek1', sortable: true, width: 70, filter: true },
  //     { headerName: '5W SU', headerClass: 'text-left', field: 'FifthWeek2', sortable: true, width: 70, filter: true },
  //     // { headerName: 'IsPresentOTFWeek4', field: 'IsPresentOTFWeek4', sortable: true, width: 70, filter: true },
  //     // { headerName: 'FifthWeek1', field: 'FifthWeek1', sortable: true, width: 70, filter: true },
  //     // { headerName: 'FifthWeek2', field: 'FifthWeek2', sortable: true, width: 70, filter: true },
  //     // { headerName: 'IsPresentOTFTWeek5', field: 'IsPresentOTFTWeek5', sortable: true, width: 70, filter: true },
  //     // { headerName: 'SixthWeek1', field: 'SixthWeek1', sortable: true, width: 70, filter: true },
  //     // { headerName: 'SixthWeek2', field: 'SixthWeek2', sortable: true, width: 70, filter: true },
  //     // { headerName: 'IsPresentOTSIWeek6', field: 'IsPresentOTSIWeek6', sortable: true, width: 70, filter: true },

  //     {
  //       headerName: 'Action', field: '', width: 40,
  //       sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
  //     }

  //   ];

  // }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }
}