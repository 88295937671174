
export class WeekOffFilterModel {
  contractorName: string;
  weekOffDays: string;
  fromDate: Date;
  toDate: Date;
  weekOffType: null;


  constructor() {
    this.contractorName = '';
    this.weekOffDays = 'sunday';
    this.fromDate = null;
    this.toDate = null;
    this.weekOffType = null;
  }
}
