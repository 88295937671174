import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { DashboardService } from "../dashboard.service";
import { PieChartService } from "../charts/pie-chart.service";
import { RowChartService } from "../charts/row-chart.service";
import { BarChartService } from "../charts/bar-chart.service";
import { GroupBarChartService } from "../charts/group-bar-chart.service";
import { LineChartService } from "../charts/line-chart.service";
import { IndiaMapService } from "../charts/india-map.service";
import { DrilldownChartService } from "../charts/drilldown-chart.service";
import { MenuSelectChartService } from "../charts/menu-select-chart.service";
import { CookieService } from "ngx-cookie-service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  AgNumberCountComponent,
  CustomDateRangeFilterComponent,
} from "src/app/core/components";
import { DatePipe } from "@angular/common";
import { SharedService } from "src/app/core/services";

declare var d3: any;
declare var crossfilter: any;
declare var dc: any;
declare var $: any;
const heightOfContainer = 500;
const legendHeight = 150;
const legendY = heightOfContainer - legendHeight;
@Component({
  selector: "app-dashboard-statistics",
  templateUrl: "./dashboard-statistics.component.html",
  styleUrls: ["./dashboard-statistics.component.css"],
})
export class DashboardStatisticsComponent implements OnInit {
  @ViewChild("totalDashboardTabHeaderRef")
  public totalDashboardTabHeaderRef: ElementRef<any>;
  @ViewChild("attendanceStaticsTabHeaderRef")
  public attendanceStaticsTabHeaderRef: ElementRef<any>;
  @ViewChild("budgetStaticsTabHeaderRef")
  public budgetStaticsTabHeaderRef: ElementRef<any>;
  @ViewChild("totalDashboardTabContentRef")
  public totalDashboardTabContentRef: ElementRef<any>;
  @ViewChild("attendanceStaticsTabContentRef")
  public attendanceStaticsTabContentRef: ElementRef<any>;
  @ViewChild("budgetStaticsTabContentRef")
  public budgetStaticsTabContentRef: ElementRef<any>;
  tableColDefs: any[] = [];
  public showTotalDashboardTab: boolean = true;
  public showAttendanceStaticsTab: boolean = true;
  public showBudgetStaticsTab: boolean = true;

  dispatch;
  filterAllRecords: number = 10000000000000;
  modalRef: NgbModalRef;
  oldChartWidth = 0;
  oldChartHeight = 0;
  piePlantWisePresentStatusChart;
  pieShiftWiseShortageChart;
  spanTotalMale;
  spanTotalFemale;
  pieShiftWisePresentStatusChart;
  pieFunctionWisePresentStatusChart;
  pieSkillWiseChartTotDash;
  pieGenderWiseChartTotDash;
  pieDomicileWiseChartTotDash;

  drillDownPlantWisePresent;
  drillDownPlantWiseRequirementVsShortage;
  barCategoryWisePresentStatusChart;
  barNonComplianceWisePresentStatusChart;
  barExtendedWHSummaryChart;
  rowContractorWiseContinuesWorkingChart;
  barTotalReqShortageChart;
  // barStateWisePresentStatusChart;
  barContractorWisePresentStatusChart;
  barContractorWiseChartTotDash;
  groupBarWeeklyShiftWiseChart;

  // barUnitWiseBudgetVSActual;
  drillDownUnitWiseBudgetVSActual;
  drillDownUnitWiseBudgetManpowerVSActual;
  lineBudgetvsActual;
  lineBudgetManpowervsActual;

  showDataTable = false;
  show = false;
  dataTableChart;
  dataTableDataDimension;
  dataTableSummaryDimension;
  dataTableDimensionBudget;

  dataTablePresentDimension;
  dataTableAvailableDimesion;
  dataTableActiveGatePassDimension;
  dataTableContractorDimension;
  dataTablePurchaseOrderDimension;
  dataTableNonComplianceDimension;

  menuselectBudgetActualCategory;
  menuselectBudgetActualPlant;
  menuselectBudgetActualUnit;

  menuselectActualCategory;
  menuselectBudgetManpowerActualCategory;
  menuselectBudgetManpowerActualPlant;
  menuselectBudgetManpowerActualUnit;

  menuselectBudgetActualDrilldownCategory;
  menuselectBudgetActualDrilldownUnit;

  menuselectBudgetManpowerActualDrilldownCategory;
  menuselectBudgetManpowerActualDrilldownUnit;
  inheadcount = [];
  outheadcount = [];
  requirement: any;
  shortage: any;
  total: number;
  count: any;
  activeGatePassCount = 0;
  quarterMonth = {
    JAN: "Q1",
    FEB: "Q1",
    MAR: "Q1",
    APR: "Q2",
    MAY: "Q2",
    JUN: "Q2",
    JUL: "Q3",
    AUG: "Q3",
    SEP: "Q3",
    OCT: "Q4",
    NOV: "Q4",
    DEC: "Q4",
  };

  userId;

  years = [new Date().getFullYear(), new Date().getFullYear() - 1];
  todayDate = null;
  lastLiveData = null;
  startDate = null;
  endDate = null;
  filteredDate = "";
  // lastLiveData = {
  //   Table: [],
  //   Table1: [],
  //   Table2: [],
  // };

  totalContractorCount;
  totalPurchaseOrder;

  totalContractorCountData;
  totalPurchaseOrderData;
  agGridData: Array<any> = [];
  skillWiseAgGridData;
  spanTotalAvailableCount;
  spanActiveGatePassCount;
  totalContractorCountTabledata;
  totalPurchaseOrderTabledata;
  barNonComplianceWisePresentStatusChartTabledata;
  isShowChartFullScreen = false;

  constructor(
    private dashboardService: DashboardService,
    private pieChartService: PieChartService,
    private rowChartService: RowChartService,
    private barChartService: BarChartService,
    private groupBarChartService: GroupBarChartService,
    private lineChartService: LineChartService,
    private indiaMapService: IndiaMapService,
    private drilldownChartService: DrilldownChartService,
    private menuSelectChartService: MenuSelectChartService,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {
    this.userId = Number(
      new URLSearchParams(this.cookieService.get("OceanSystem")).get("UserID")
    );
  }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem("Role"))[0].Role_Code === 119) {
      this.show = false;
    } else {
      this.show = true;
    }
    setTimeout(() => {
      this.showHideTab();
    }, 2000);
  }

  initAfterValidation() {
    this.dispatch = d3.dispatch(
      "drillDownPlantWiseRequirementVsShortageReset",
      "drillDownPlantWiseRequirementVsShortageBack",
      "drillDownUnitWiseBudgetVSActualReset",
      "drillDownUnitWiseBudgetVSActualBack",
      "drillDownUnitWiseBudgetManpowerVSActualReset",
      "drillDownUnitWiseBudgetManpowerVSActualBack",
      "piePlantWisePresentStatusChartReset",
      "piePlantWisePresentStatusChartBack",
      "drillDownPlantWisePresentReset",
      "drillDownPlantWisePresentBack"
    );
    this.startDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.todayDate = this.startDate;
    this.filteredDate = this.datePipe.transform(new Date(), "dd MMM yyyy");

    this.showTotalDashboardTab = true;
    // this.callDashboardCommonApi();

    if (this.showTotalDashboardTab) {
      this.getRealTimeHeadCount(this.startDate);
      this.getRealtimeHeadcountSummary(this.startDate, this.endDate);
      this.getTotalContractor();
      this.getTotalWorkOrder();
    }

    if (this.showAttendanceStaticsTab) {
      this.getAllDataSet(this.startDate, this.endDate);
      this.getAllInHeadCount("", "");
      this.getAllOutHeadCount("", "");
      this.getAllRequirementAndShortage("");
      this.getTotalActiveGatePass();
      this.getWeeklyShiftData(this.startDate, this.endDate);
    }
    this.filteredDate = this.datePipe.transform(new Date(), "dd MMM yyyy");

    if (this.showBudgetStaticsTab) {
      this.getAllDataSetBudget("", "");
    }
    this.showTotalDashboardTab = true;
    //this.callDashboardCommonApi();

    this.getNonCompianceLabourList(this.startDate, this.endDate);
  }

  private showHideTab() {
    this.sharedService.globalSettingsDataSource$.subscribe((res) => {
      if (res && res.Data && res.Data.Table) {
        const globalSettings = res.Data.Table;
        this.showTotalDashboardTab = this.getValueByCode(
          globalSettings,
          "MOD125PG101HSTAB001"
        );
        this.showAttendanceStaticsTab = this.getValueByCode(
          globalSettings,
          "MOD125PG101HSTAB002"
        );
        this.showBudgetStaticsTab = this.getValueByCode(
          globalSettings,
          "MOD125PG101HSTAB003"
        );

        if (this.showTotalDashboardTab) {
          this.renderer.addClass(
            this.totalDashboardTabHeaderRef.nativeElement,
            "active"
          );
          this.renderer.addClass(
            this.totalDashboardTabContentRef.nativeElement,
            "active"
          );
          if (
            this.attendanceStaticsTabHeaderRef &&
            this.attendanceStaticsTabHeaderRef.nativeElement
          ) {
            this.renderer.removeClass(
              this.attendanceStaticsTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.removeClass(
            this.attendanceStaticsTabContentRef.nativeElement,
            "active"
          );
          if (
            this.budgetStaticsTabHeaderRef &&
            this.budgetStaticsTabHeaderRef.nativeElement
          ) {
            this.renderer.removeClass(
              this.budgetStaticsTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.removeClass(
            this.budgetStaticsTabContentRef.nativeElement,
            "active"
          );
        } else if (this.showAttendanceStaticsTab) {
          if (
            this.totalDashboardTabHeaderRef &&
            this.totalDashboardTabHeaderRef.nativeElement
          ) {
            this.renderer.removeClass(
              this.totalDashboardTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.removeClass(
            this.totalDashboardTabContentRef.nativeElement,
            "active"
          );
          if (
            this.attendanceStaticsTabHeaderRef &&
            this.attendanceStaticsTabHeaderRef.nativeElement
          ) {
            this.renderer.addClass(
              this.attendanceStaticsTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.addClass(
            this.attendanceStaticsTabContentRef.nativeElement,
            "active"
          );
          if (
            this.budgetStaticsTabHeaderRef &&
            this.budgetStaticsTabHeaderRef.nativeElement
          ) {
            this.renderer.removeClass(
              this.budgetStaticsTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.removeClass(
            this.budgetStaticsTabContentRef.nativeElement,
            "active"
          );
        } else if (this.showBudgetStaticsTab) {
          if (
            this.totalDashboardTabHeaderRef &&
            this.totalDashboardTabHeaderRef.nativeElement
          ) {
            this.renderer.removeClass(
              this.totalDashboardTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.removeClass(
            this.totalDashboardTabContentRef.nativeElement,
            "active"
          );
          if (
            this.attendanceStaticsTabHeaderRef &&
            this.attendanceStaticsTabHeaderRef.nativeElement
          ) {
            this.renderer.removeClass(
              this.attendanceStaticsTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.removeClass(
            this.attendanceStaticsTabContentRef.nativeElement,
            "active"
          );
          if (
            this.budgetStaticsTabHeaderRef &&
            this.budgetStaticsTabHeaderRef.nativeElement
          ) {
            this.renderer.addClass(
              this.budgetStaticsTabHeaderRef.nativeElement,
              "active"
            );
          }
          this.renderer.addClass(
            this.budgetStaticsTabContentRef.nativeElement,
            "active"
          );
        } else {
          this.renderer.removeClass(
            this.totalDashboardTabContentRef.nativeElement,
            "active"
          );
          this.renderer.removeClass(
            this.attendanceStaticsTabContentRef.nativeElement,
            "active"
          );
          this.renderer.removeClass(
            this.budgetStaticsTabContentRef.nativeElement,
            "active"
          );
        }
      }

      this.initAfterValidation();
    });
  }

  private getValueByCode(globalSettings: any[], code: string): boolean {
    const firstTab = globalSettings.find((item) => item.Code === code);
    if (!firstTab) return false;
    return firstTab.Value === "1";
  }

  onDateRangeChange($event) {
    this.filteredDate = this.datePipe.transform(
      $event.startDate,
      "dd MMM yyyy"
    );
    this.startDate = this.datePipe.transform($event.startDate, "yyyy-MM-dd");

    this.endDate = this.datePipe.transform($event.endDate, "yyyy-MM-dd");

    if (this.showTotalDashboardTab) {
      this.getRealTimeHeadCount(this.startDate);
      this.getRealtimeHeadcountSummary(this.startDate, this.endDate);
      this.getTotalContractor();
      this.getTotalWorkOrder();
    }

    if (this.showAttendanceStaticsTab) {
      this.getAllDataSet(this.startDate, this.endDate);
      this.getAllInHeadCount("", "");
      this.getAllOutHeadCount("", "");
      this.getAllRequirementAndShortage("");
      this.getTotalActiveGatePass();
      this.getWeeklyShiftData(this.startDate, this.endDate);
    }

    if (this.showBudgetStaticsTab) {
      this.getAllDataSetBudget("", "");
    }
    //this.callDashboardCommonApi();
    this.getNonCompianceLabourList(this.startDate, this.endDate);
    // this.getTotalActiveGatePass();

    // this.getAllDataSetBudget(
    //   this.datePipe.transform($event.startDate, 'yyyy-MM-dd'),
    //   this.datePipe.transform($event.endDate, 'yyyy-MM-dd')
    // );
    // this.getAllInHeadCount(
    //   this.datePipe.transform($event.startDate, 'yyyy-MM-dd'),
    //   this.datePipe.transform($event.endDate, 'yyyy-MM-dd')
    // );
    // this.getAllOutHeadCount(
    //   this.datePipe.transform($event.startDate, 'yyyy-MM-dd'),
    //   this.datePipe.transform($event.endDate, 'yyyy-MM-dd')
    // );
    // this.getAllRequirementAndShortage(
    //   this.datePipe.transform($event.startDate, 'yyyy-MM-dd')
    // );
  }
  getAllInHeadCount(startDate, endDate) {
    this.dashboardService
      .getAllInHeadCount(this.userId, startDate, endDate)
      .subscribe((res: any) => {
        this.inheadcount = res.length;
      });
  }
  getAllOutHeadCount(startDate, endDate) {
    this.dashboardService
      .getAllOutHeadCount(this.userId, startDate, endDate)
      .subscribe((res: any) => {
        this.outheadcount = res.length;
      });
  }
  getAllRequirementAndShortage(startDate) {
    this.dashboardService
      .getAllRequirementAndShortage(this.userId, startDate)
      .subscribe((res: any) => {
        let total = 0;
        let total1 = 0;
        let count;
        for (count = 0; count < res.length; count++) {
          total += res[count].Shortage;
          total1 += res[count].Requirement;
        }
        this.shortage = total;
        this.requirement = total1;
      });
  }

  getRealTimeHeadCount(startDate: any) {
    // const date= moment(startDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat);
    // this.dashboardService.getRealTimeHeadCount(startDate, false).subscribe(res => {
    // console.log('getRealTimeHeadCount', res);
    // this.bindTotalDashboardChart(res);
    // });
  }

  getRealtimeHeadcountSummary(startDate: any, endDate: any) {
    // const date= moment(startDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat);
    this.dashboardService
      .getRealtimeHeadcountSummary(startDate, endDate)
      .subscribe((res) => {
        this.bindTotalDashboardChart(res);
        //this.skillWiseAgGridData = res;
      });
  }

  bindTotalDashboardTable(data) {
    const ndxTotalTable = crossfilter(data);
  }

  getTotalContractor() {
    this.dashboardService.getContractorFilter().subscribe((res) => {
      this.totalContractorCount = res.length;
      this.totalContractorCountData = res;
      this.totalContractorCountTabledata = res;
      const ndxAll = crossfilter(this.totalContractorCountData);
      this.dataTableContractorDimension = ndxAll.dimension((d) => {
        return "All";
      });
    });
  }

  getTotalWorkOrder() {
    this.dashboardService.getWorkOrderFilterData().subscribe((res) => {
      this.totalPurchaseOrder = res.length;
      this.totalPurchaseOrderTabledata = res;
      this.totalPurchaseOrderData = res;
      const ndxAll = crossfilter(this.totalPurchaseOrderData);
      this.dataTablePurchaseOrderDimension = ndxAll.dimension((d) => {
        return "All";
      });
    });
  }

  agGridTotalDashboardRowData: any[] = [];
  agGridTotalDashboardColumnDefs: any[] = null;
  totalDashboardNdxAll;
  totalDashboardAPIData = [];

  bindTotalDashboardChart(data) {
    // Data Sample
    // "$id": "1",
    // "Contractor": "Adecco India Pvt Ltd",
    // "MainWO": "465465",
    // "Sub-Contractor": null,
    // "SubWO": null,
    // "Code": 12100736,
    // "Associate": "NAME_4 F-4",
    // "Gender": "M",
    // "Skill": "Skilled",
    // "Trade": "Office Boy",
    // "Department": "Production",
    // "DemandType": "General",
    // "State": "GOA",
    // "Age": "43",
    // "In Time": null,
    // "Out Time": null,
    // "OT": "0"

    const ndxAll = crossfilter(data);
    this.spanActiveGatePassCount = data;
    this.totalDashboardAPIData = data;
    this.totalDashboardNdxAll = ndxAll;

    this.setTotalDashboardTableForContractor();

    // ndxAll.find
    this.skillWiseAgGridData = data.filter((item) => item["In Time"]);
    const ndxPresent = crossfilter(data.filter((item) => item["In Time"]));
    this.spanTotalAvailableCount = data.filter(
      (item) => item["In Time"] && !item["Out Time"]
    );
    const ndxAvailableHeadCount = crossfilter(
      data.filter((item) => item["In Time"] && !item["Out Time"])
    );

    this.dataTableActiveGatePassDimension = ndxAll.dimension((d) => {
      return "All";
    });
    this.dataTablePresentDimension = ndxPresent.dimension((d) => {
      return "All";
    });
    this.dataTableAvailableDimesion = ndxAvailableHeadCount.dimension((d) => {
      return "All";
    });

    this.dashboardService.getIndiaJson().subscribe((res) => {
      this.indiaMapService.generatemap(ndxPresent, res);
    });

    this.pieSkillWiseChartTotDash = this.pieChartService.getPieChart({
      crossfilter: ndxPresent,
      filterGroup: "group3",
      chartId: "#pieSkillWiseChartTotDash",
      groupByProperty: "Skill",
      reduceProperty: null,
      width: 400,
      height: 200,
      legend: true,
      legendValue: true,
      legendPerc: true,
      label: true,
      labelValue: true,
      labelPerc: true,
      // redius?: number;
      innerRadius: 0,
      // cx?: number;
      // cy: 115,
      slicesCap: 4,
      externalLabels: false,
      externalRadiusPadding: 30,
      renderLabel: true,
    });

    // this.pieGenderWiseChartTotDash = this.pieChartService.getPieChart({
    //   crossfilter: ndx1,
    //   filterGroup: 'group3',
    //   chartId: '#pieGenderWiseChartTotDash',
    //   groupByProperty: 'Gender',
    //   reduceProperty: null,
    //   width: 400,
    //   height: 200,
    //   legend: true,
    //   legendValue: true,
    //   legendPerc: true,
    //   label: true,
    //   labelValue: true,
    //   labelPerc: true,
    //   // redius?: number;
    //   innerRadius: 0,
    //   // cx?: number;
    //   // cy: 115,
    //   slicesCap: 2,
    //   externalLabels: false,
    //   externalRadiusPadding: 30,
    //   renderLabel: true,
    // });

    this.pieDomicileWiseChartTotDash = this.pieChartService.getPieChart({
      crossfilter: ndxPresent,
      filterGroup: "group3",
      chartId: "#pieDomicileWiseChartTotDash",
      groupByProperty: "DomicialStatus",
      reduceProperty: null,
      width: 400,
      height: 200,
      legend: true,
      legendValue: true,
      legendPerc: true,
      label: true,
      labelValue: true,
      labelPerc: true,
      // redius?: number;
      innerRadius: 0,
      // cx?: number;
      // cy: 115,
      slicesCap: 2,
      externalLabels: false,
      externalRadiusPadding: 30,
      renderLabel: true,
    });

    this.barContractorWiseChartTotDash = this.rowChartService.getRowChart({
      crossfilter: ndxPresent,
      filterGroup: "group3",
      chartId: "#barContractorWiseChartTotDash",
      groupByProperty: "Contractor",
      reduceProperty: "Code",
      width: 270,
      height: 416,
      gap: 4,
      yAxisLabel: "Contractor Name",
      xAxisLabel: "Count",
      elasticY: true,
    });

    const chartPresentCount = dc.numberDisplay("#totalPresentCount", "group3");
    const groupPresentCount = ndxPresent.groupAll().reduceSum((d) => {
      return d["In Time"] ? 1 : 0;
    });

    chartPresentCount
      .formatNumber(d3.format("."))
      // .dimension(dimension)
      .valueAccessor((d) => d)
      .group(groupPresentCount);
    chartPresentCount.render();

    const chartAvailableCount = dc.numberDisplay(
      "#spanTotalAvailableCount",
      "group3"
    );
    const groupAvailableCount = ndxPresent.groupAll().reduceSum((d) => {
      return d["Out Time"] ? 0 : 1;
    });

    chartAvailableCount
      .formatNumber(d3.format("."))
      // .dimension(dimension)
      .valueAccessor((d) => d)
      .group(groupAvailableCount);
    chartAvailableCount.render();

    const chartActiveGatePass = dc.numberDisplay(
      "#spanActiveGatePassCount",
      "group3"
    );
    const groupActiveGatePass = ndxAll.groupAll().reduceSum((d) => {
      return 1;
    });

    chartActiveGatePass
      .formatNumber(d3.format("."))
      // .dimension(dimension)
      .valueAccessor((d) => d)
      .group(groupActiveGatePass);
    chartActiveGatePass.render();

    this.spanTotalMale = this.getCountChartGender(
      ndxPresent,
      "#spanTotalMale",
      "group3",
      true,
      false
    );
    this.spanTotalFemale = this.getCountChartGender(
      ndxPresent,
      "#spanTotalFemale",
      "group3",
      false,
      true
    );
  }

  getNonCompianceLabourList(startDate, endDate) {
    this.dashboardService
      .getNonComplianceLabourList(startDate, endDate)
      .subscribe((res: any) => {
        // this.bindChart(res.Table1, res.Table2);
        // console.log('getNonCompianceLabourList', res);
        this.barNonComplianceWisePresentStatusChartTabledata = res;
        const ndxNonCompliance = crossfilter(res);
        this.dataTableNonComplianceDimension = ndxNonCompliance.dimension(
          (d) => {
            return "All";
          }
        );

        this.barNonComplianceWisePresentStatusChart =
          this.rowChartService.getRowChart({
            crossfilter: ndxNonCompliance,
            filterGroup: "group1",
            chartId: "#barNonComplianceWisePresentStatusChart",
            groupByProperty: "ErrorDetails",
            reduceProperty: "$id",
            width: 300,
            height: 280,

            gap: 4,
            yAxisLabel: "Non-Compliance",
            xAxisLabel: "HC Numbers",
            elasticY: true,
          });
      });
  }

  getTotalActiveGatePass() {
    this.dashboardService.getTotalActiveGatePass().subscribe((count: any) => {
      // this.activeGatePassCount = count;
    });
  }

  getWeeklyShiftData(startDate, endDate) {
    this.dashboardService
      .getWeeklyShiftData(startDate, endDate)
      .subscribe((res: any) => {
        if (res && res.length) {
          const barKeyList = [];

          const newData = [];

          Object.keys(res[0]).forEach((key) => {
            if (key != "$id" && key != "Shift") {
              barKeyList.push(key);
            }
          });

          res.forEach((item) => {
            barKeyList.forEach((key) => {
              const newRecord = {};
              newRecord["Shift"] = item.Shift;
              newRecord["Date"] = key;
              newRecord["Value"] = item[key];
              newData.push(newRecord);
            });
          });

          // console.log('newData', newData);
          // const ndx1 = crossfilter(newData);
          // ndx1, chartId, groupProperty, xLabel, yLabel, width, height, barKeyList, barLabelMap
          // this.groupBarWeeklyShiftWiseChart = this.groupBarChartService.getGroupBarShiftWiseWeeklyChart(
          //   ndx1, '#groupBarWeeklyShiftWiseChart', 'Date', 'Dates', 'Count', 400, 230
          // )
        }

        // console.log('getWeeklyShiftData', res);
        // Todo: How to display shift wise weekly data in Bar Chart
      });
  }

  getAllDataSet(startDate, endDate, lastUpdateTime?) {
    this.dashboardService
      .getAllDataSet(
        localStorage.getItem("UserID"),
        startDate,
        endDate,
        lastUpdateTime
      )
      .subscribe((res: any) => {
        this.bindChart(res.Table1, res.Table2);
        // Todo: Fetch only updated data every 30 seconds
        // if (this.todayDate === startDate) {
        //   this.mergeDataAndBindChart(res);
        //   const newLastUpdateTime = res.Table[0].lastUpdateTime.replace('T', ' ');
        //   setTimeout(() => {
        //     this.getAllDataSet(startDate, endDate, newLastUpdateTime);
        //   }, 30000); // Every 30 seconds refresh call
        // } else {
        //   this.lastLiveData = null;
        //   this.bindChart(res.Table1, res.Table2);
        // }
      });
  }

  mergeDataAndBindChart(res) {
    if (this.lastLiveData) {
      if (res.Table1.length === 0 && res.Table2.length === 0) {
        // If there is no new data
        return;
      }
      this.lastLiveData.Table = res.Table;
      const newData = [];
      res.Table1.forEach((item) => {
        const found = this.lastLiveData.Table1.find(
          (data) => data.Key === item.Key
        );
        if (found) {
          Object.assign(found, item);
        } else {
          this.lastLiveData.Table1.push(item);
        }
      });

      res.Table2.forEach((item) => {
        const found = this.lastLiveData.Table2.find(
          (data) => data.GatepassNo === item.GatepassNo
        );
        if (found) {
          Object.assign(found, item);
        } else {
          this.lastLiveData.Table2.push(item);
        }
      });
    } else {
      this.lastLiveData = res;
    }
    this.bindChart(this.lastLiveData.Table1, this.lastLiveData.Table2);
  }

  getAllDataSetBudget(startDate, endDate) {
    this.dashboardService
      .getAllDataSetBudget(this.userId, startDate, endDate)
      .subscribe((res) => this.bindChartbud(res));
  }

  bindChart(dataReal, dataReal2) {
    // // console.log('data', dataReal);
    const ndx1 = crossfilter(dataReal);
    const ndx2 = crossfilter(dataReal2);
    // const uLCWiseGroup = [];
    // $.each(dataReal2, (key: any, val: any) => {
    //   if (uLCWiseGroup.length === 0) {
    //     uLCWiseGroup.push({
    //       ULC: val.ULC,
    //       Gender: val.Gender,
    //       State: val.State,
    //       Cast: val.Cast,
    //       Domicile: val.Domicile,
    //       Religion: val.Religion,
    //       NatureofWork: val.NatureofWork,
    //       District: val.District,
    //       Skill: val.Skill,
    //       Age: val.Age,
    //       TotalReq: val.TotalReq,
    //       ActualDeployed: val.ActualDeployed,
    //       TotalShortage: val.TotalShortage
    //     });
    //   } else {
    //     const found = uLCWiseGroup.some(el => el.ULC === val.ULC);
    //     if (!found) {
    //       uLCWiseGroup.push({
    //         ULC: val.ULC,
    //         Gender: val.Gender,
    //         State: val.State,
    //         Cast: val.Cast,
    //         Domicile: val.Domicile,
    //         Religion: val.Religion,
    //         NatureofWork: val.NatureofWork,
    //         District: val.District,
    //         Skill: val.Skill,
    //         Age: val.Age,
    //         TotalReq: val.TotalReq,
    //         ActualDeployed: val.ActualDeployed,
    //         TotalShortage: val.TotalShortage
    //       });
    //     }
    //   }
    // });
    // const maleData = $.grep(uLCWiseGroup, (v: any) => {
    //   return v.Gender === 'Male' || v.Gender === 'MALE';
    // });
    // const malendx1 = crossfilter(maleData);
    // const femaleData = $.grep(uLCWiseGroup, (v: any) => {
    //   return v.Gender === 'Female' || v.Gender === 'FEMALE';
    // });
    // const femalendx1 = crossfilter(femaleData);
    // const goaStateData = $.grep(uLCWiseGroup, (v: any) => {
    //   return v.State === 'GOA';
    // });
    // const nonComplianceData = $.grep(dataReal2, (v: any) => {
    //   return v.NonComplianceStatus != null && v.NonComplianceStatus !== '';
    // });
    // const positiveShortageData = $.grep(dataReal, (v: any) => {
    //   return v.TotalShortage >= 0;
    // });
    // const ndxGoa = crossfilter(goaStateData);
    // const uLCWisendx = crossfilter(uLCWiseGroup);

    // const nonComplndx = crossfilter(nonComplianceData);
    const positiveShortage = ndx1; // crossfilter(positiveShortageData);

    // tslint:disable-next-line: max-line-length
    // this.piePlantWisePresentStatusChart = this.getPieChart(ndx1, '#piePlantWisePresentStatusChart', 'PlantName', 'ActualDeployed', 400, 200, 30);

    // this.piePlantWisePresentStatusChart = this.pieChartService.getDrillDownPieChartRequirementShortage({
    //   crossfilter: ndx1,
    //   filterGroup: 'group1',
    //   chartId: '#piePlantWisePresentStatusChart',
    //   groupByProperty: 'PlantName',
    //   reduceProperty: null,
    //   width: 400,
    //   height: 200,
    //   legend: false,
    //   legendValue: true,
    //   legendPerc: true,
    //   label: false,
    //   labelValue: true,
    //   labelPerc: true,
    //   // redius?: number;
    //   // innerRadius: 0,
    //   // cx?: number;
    //   // cy?: number;
    //   slicesCap: 4,
    //   dispatch: this.dispatch,
    //   externalLabels: true,
    //   externalRadiusPadding: 30,
    //   renderLabel: true,
    // });

    // tslint:disable-next-line: max-line-length
    this.pieShiftWisePresentStatusChart =
      this.pieChartService.getShiftWisePresentStatusChart({
        crossfilter: ndx2,
        crossfilter2: ndx1,
        filterGroup: "group1",
        chartId: "#pieShiftWisePresentStatusChart",
        groupByProperty: "ShiftGName",
        reduceProperty: null,
        width: 400,
        height: 200,
        legend: true,
        legendValue: true,
        legendPerc: true,
        label: true,
        labelValue: true,
        labelPerc: true,
        // redius?: number;
        innerRadius: 0,
        // cx?: number;
        // cy: 115,
        slicesCap: 4,
        externalLabels: false,
        externalRadiusPadding: 30,
        renderLabel: true,
      });

    this.pieShiftWiseShortageChart =
      this.pieChartService.getShiftWiseShortageStatus({
        crossfilter: ndx1,
        crossfilter2: ndx2,
        filterGroup: "group1",
        chartId: "#pieShiftWiseShortageChart",
        groupByProperty: "Shift",
        reduceProperty: null,
        width: 400,
        height: 200,
        legend: true,
        legendValue: true,
        legendPerc: true,
        label: false,
        labelValue: true,
        labelPerc: true,
        // redius?: number;
        innerRadius: 0,
        // cx: 150,
        // cy: 115,
        slicesCap: 4,
        externalLabels: false,
        externalRadiusPadding: 30,
        renderLabel: true,
      });
    // tslint:disable-next-line: max-line-length
    // this.pieFunctionWisePresentStatusChart = this.getPieChart(ndx1, '#pieFunctionWisePresentStatusChart', 'WorkFunction', 'ActualDeployed', 400, 200, 30);
    this.pieFunctionWisePresentStatusChart =
      this.pieChartService.getFunctionWisePresentStatus({
        crossfilter: ndx2,
        filterGroup: "group1",
        chartId: "#pieFunctionWisePresentStatusChart",
        groupByProperty: "WorkFunction",
        reduceProperty: "ActualDeployed",
        width: 400,
        height: 200,

        legend: true,
        legendValue: true,
        legendPerc: true,
        label: true,
        labelValue: true,
        labelPerc: true,
        // redius?: number;
        innerRadius: 0,
        // cx?: number;
        // cy?: number;
        slicesCap: 4,
        externalLabels: false,
        externalRadiusPadding: 30,
        renderLabel: true,
      });

    // this.spanTotalMale = this.getCountChartGender(ndx2, '#spanTotalMale', true, false);
    // this.spanTotalFemale = this.getCountChartGender(ndx2, '#spanTotalFemale', false, true);
    // console.log(this.spanTotalMale)
    // this.dashboardService.getIndiaJson().subscribe((res) => {
    //   this.indiaMapService.generatemap(ndx2, res);
    // });

    // this.getPartitionPieChart(ndx1);
    // this.barTotalReqShortageChart(ndx1);

    // tslint:disable-next-line: max-line-length

    // tslint:disable-next-line: max-line-length
    // tslint:disable-next-line: max-line-length
    // this.barTotalReqShortageChart = this.groupBarChartService.getGroupBarChart(ndx1, '#barTotalReqShortageChart', 'FinYear', 'Total', 'Shortage', 230, 200);
    this.barTotalReqShortageChart = this.groupBarChartService.getGroupBarChart({
      crossfilter: positiveShortage,
      filterGroup: "group1",
      chartId: "#barTotalReqShortageChart",
      groupProperty: "FinYear",
      width: 300,
      height: 205,
      yLabel: "HC Numbers",
      legendX: 2,
      legendWidth: 105,
      labelValueFormatted: true,
      margins: {
        left: 50,
        top: 25,
        right: 50,
        bottom: 25,
      },
    });
    // this.barExtendedWHSummaryChart = this.getBarExtendedWHSummaryChart(ndx1);
    this.barExtendedWHSummaryChart =
      this.barChartService.getExtendedWHSummaryChart({
        crossfilter: ndx2,
        filterGroup: "group1",
        chartId: "#barExtendedWHSummaryChart",
        groupByProperty: null,
        reduceProperty: null,
        width: 400,
        height: 200,

        gap: 4,
        yAxisLabel: "HC EWH",
        xAxisLabel: "EWH Range",
        elasticX: true,
        elasticY: true,
      });
    // this.barStateWisePresentStatusChart = this.getbarStateWisePresentStatusChart(ndx1);
    // this.rowContractorWiseContinuesWorkingChart = this.getRowChartContractorWiseChart(ndx1);

    // this.barCategoryWisePresentStatusChart = this.getbarCategoryWisePresentStatusChart(ndx1);
    this.barCategoryWisePresentStatusChart =
      this.rowChartService.getRowCategoryWisePresentChart({
        crossfilter: ndx2,
        crossfilter2: ndx1,
        filterGroup: "group1",
        chartId: "#barCategoryWisePresentStatusChart",
        groupByProperty: "WorkmanCategory",
        reduceProperty: "ULC",
        width: 390,
        height: 200,

        gap: 4,
        yAxisLabel: "Category",
        xAxisLabel: "HC Numbers",
        elasticY: true,
        margins: {
          top: 20,
          right: 0,
          bottom: 40,
          left: 30,
        },
      });

    this.rowContractorWiseContinuesWorkingChart =
      this.rowChartService.getRowChartContractorWiseCWChart({
        crossfilter: ndx1,
        crossfilter2: ndx2,
        filterGroup: "group1",
        chartId: "#rowContractorWiseContinuesWorkingChart",
        groupByProperty: "Contractor",
        reduceProperty: "TotalShortage",
        width: 390,
        height: 440,

        gap: 4,
        yAxisLabel: "Contractor",
        xAxisLabel: "HC Numbers",
        elasticY: true,
      });

    // this.barContractorWisePresentStatusChart = this.getbarContractorWisePresentStatusChart(ndx1);
    this.barContractorWisePresentStatusChart =
      this.rowChartService.getRowContractorWisePresentChart({
        crossfilter: ndx1,
        crossfilter2: ndx2,
        filterGroup: "group1",
        chartId: "#barContractorWisePresentStatusChart",
        groupByProperty: "Contractor",
        reduceProperty: "ActualDeployed",
        width: 390,
        height: 530,

        gap: 4,
        yAxisLabel: "Contractor",
        xAxisLabel: "",
        elasticY: true,
        legend: false,
      });
    // tslint:disable-next-line: max-line-length
    // this.drillDownPlantWiseRequirementVsShortage = this.drilldownChartService.getDrillDownChartRequirementShortage(ndx1, '#drillDownPlantWiseRequirementVsShortage', 800, 200, this.dispatch);
    this.drillDownPlantWiseRequirementVsShortage =
      this.drilldownChartService.getDrillDownChartRequirementShortage({
        crossfilter: positiveShortage,
        crossfilter2: ndx2,
        filterGroup: "group1",
        chartId: "#drillDownPlantWiseRequirementVsShortage",
        width: 550,
        height: 200,
        dispatch: this.dispatch,

        legend: true,
        //xAxisLabel: 'Location',
        yAxisLabel: "HC Numbers",
        labelValueFormatted: true,
        margins: {
          left: 50,
          top: 20,
          right: 50,
          bottom: 50,
        },
      });

    this.drillDownPlantWisePresent =
      this.drilldownChartService.getDrillDownChartPlatWisePresent({
        crossfilter: positiveShortage,
        crossfilter2: ndx2,
        filterGroup: "group1",
        chartId: "#drillDownPlantWisePresent",
        width: 600,
        height: 200,
        dispatch: this.dispatch,
        legend: true,
        //xAxisLabel: 'Location',
        yAxisLabel: "HC Numbers",
        labelValueFormatted: true,
        margins: {
          left: 50,
          top: 20,
          right: 50,
          bottom: 50,
        },
      });

    // tslint:disable-next-line: max-line-length
    // this.barPlantWiseRequirementVsShortage = this.groupBarChartService.getGroupBarChart(ndx1, '#barPlantWiseRequirementVsShortage', 'PlantName', 'Plant', 'Shortage', 800, 220);
    this.dataTableSummaryDimension = ndx1.dimension((d) => {
      return "All";
    });
    this.dataTableDataDimension = ndx2.dimension((d) => {
      return "All";
    });

    this.menuselectActualCategory = this.menuSelectChartService.getMenuSelect({
      crossfilter: ndx1,
      crossfilter2: ndx2,
      filterGroup: "group1",
      chartId: "#menuselectActualCategory",
      groupByProperty: "CategoryName",
      groupByProperty2: "WorkmanCategory",
      promptText: "All categories",
    });

    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftAReq",
      "A",
      "TotalReq"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftADep",
      "A",
      "ActualDeployed"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftAShort",
      "A",
      "TotalShortagePec"
    );

    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftGenReq",
      "GEN",
      "TotalReq"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftGenDep",
      "GEN",
      "ActualDeployed"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftGenShort",
      "GEN",
      "TotalShortagePec"
    );

    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftBReq",
      "B",
      "TotalReq"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftBDep",
      "B",
      "ActualDeployed"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftBShort",
      "B",
      "TotalShortagePec"
    );

    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftCReq",
      "C",
      "TotalReq"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftCDep",
      "C",
      "ActualDeployed"
    );
    this.getOverallManningStatusDataTableChart(
      ndx1,
      "#shiftCShort",
      "C",
      "TotalShortagePec"
    );
  }
  bindChartbud(dataReal) {
    // // console.log('Budget', dataReal);
    const ndx1 = crossfilter(dataReal);
    const ndx2 = crossfilter(dataReal);
    const ndx3 = crossfilter(dataReal);
    const ndx4 = crossfilter(dataReal);

    // this.getLineChart(ndx1);
    this.lineBudgetvsActual = this.lineChartService.getLineChartMonthWise({
      crossfilter: ndx3,
      filterGroup: "group-line-1",
      chartId: "#lineBudgetVSActual",
      groupByProperty: "Month",
      reduceProperty: "Budget",
      reduceProperty2: "Actual",
      xAxisLabel: "",
      yAxisLabel: "Amount Cr.",
      width: 600,
      height: 200,
    });
    this.lineBudgetManpowervsActual =
      this.lineChartService.getLineChartMonthWise({
        crossfilter: ndx4,
        filterGroup: "group-line-2",
        chartId: "#lineBudgetManpowervsActual",
        groupByProperty: "Month",
        reduceProperty: "Requirement",
        reduceProperty2: "Present",
        legendText: "Budgeted Manpower",
        legendText2: "Actual",
        xAxisLabel: "",
        yAxisLabel: "HC Numbers",
        width: 600,
        height: 200,
      });
    // tslint:disable-next-line: max-line-length
    // this.barUnitWiseBudgetVsActual = this.getGroupBarBudgetChart(ndx1, '#barUnitWiseBudgetVsActual', 'UnitName', '', '', 800, 200);
    // tslint:disable-next-line: max-line-length
    // this.drillUnitWiseBudgetVSActual = this.groupBarChartService.getGroupBarBudgetChart(ndx1, '#drillUnitWiseBudgetVSActual', 'Unit', '', '', 700, 200);
    this.drillDownUnitWiseBudgetVSActual =
      this.drilldownChartService.getDrillDownChartBudgetRequirementShortage({
        crossfilter: ndx1,
        filterGroup: "group-drill-1",
        chartId: "#drillDownUnitWiseBudgetVSActual",
        width: 600,
        height: 200,
        dispatch: this.dispatch,
        legend: true,
        xAxisLabel: "",
        yAxisLabel: "Amount Cr.",
        labelValueFormatted: true,
        margins: {
          left: 60,
          top: 20,
          right: 10,
          bottom: 50,
        },
      });

    // tslint:disable-next-line: max-line-length
    // this.barUnitWiseManpowerBudgetVSActual = this.groupBarChartService.getGroupBarManPBudgetChart(ndx1, '#barUnitWiseManpowerBudgetVSActual', 'Unit', '', '', 700, 200);
    this.drillDownUnitWiseBudgetManpowerVSActual =
      this.drilldownChartService.getDrillDownChartManBudgetRequirementShortage({
        crossfilter: ndx2,
        filterGroup: "group-drill-2",
        chartId: "#drillDownUnitWiseBudgetManpowerVSActual",
        width: 600,
        height: 200,
        dispatch: this.dispatch,
        legend: true,
        xAxisLabel: "",
        yAxisLabel: "HC Numbers",
        labelValueFormatted: true,
        margins: {
          left: 50,
          top: 15,
          right: 10,
          bottom: 50,
        },
      });

    this.menuselectBudgetActualDrilldownCategory =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx1,
        filterGroup: "group-drill-1",
        chartId: "#menuselectBudgetActualDrilldownCategory",
        groupByProperty: "WorkmanCategory",
        promptText: "All categories",
      });

    this.menuselectBudgetActualDrilldownUnit =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx1,
        filterGroup: "group-drill-1",
        chartId: "#menuselectBudgetActualDrilldownUnit",
        groupByProperty: "Function",
        promptText: "All functions",
      });

    this.menuselectBudgetManpowerActualDrilldownCategory =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx2,
        filterGroup: "group-drill-2",
        chartId: "#menuselectBudgetManpowerActualDrilldownCategory",
        groupByProperty: "WorkmanCategory",
        promptText: "All categories",
      });

    this.menuselectBudgetManpowerActualDrilldownUnit =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx2,
        filterGroup: "group-drill-2",
        chartId: "#menuselectBudgetManpowerActualDrilldownUnit",
        groupByProperty: "Function",
        promptText: "All functions",
      });

    this.menuselectBudgetActualCategory =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx3,
        filterGroup: "group-line-1",
        chartId: "#menuselectBudgetActualCategory",
        groupByProperty: "WorkmanCategory",
        promptText: "All categories",
      });

    this.menuselectBudgetActualPlant =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx3,
        filterGroup: "group-line-1",
        chartId: "#menuselectBudgetActualPlant",
        groupByProperty: "Plant",
        promptText: "All plants",
      });

    this.menuselectBudgetActualUnit = this.menuSelectChartService.getMenuSelect(
      {
        crossfilter: ndx3,
        filterGroup: "group-line-1",
        chartId: "#menuselectBudgetActualUnit",
        groupByProperty: "Unit",
        promptText: "All units",
      }
    );

    this.menuselectBudgetManpowerActualCategory =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx4,
        filterGroup: "group-line-2",
        chartId: "#menuselectBudgetManpowerActualCategory",
        groupByProperty: "WorkmanCategory",
        promptText: "All categories",
      });

    this.menuselectBudgetManpowerActualUnit =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx4,
        filterGroup: "group-line-2",
        chartId: "#menuselectBudgetManpowerActualUnit",
        groupByProperty: "Unit",
        promptText: "All units",
      });

    this.menuselectBudgetManpowerActualPlant =
      this.menuSelectChartService.getMenuSelect({
        crossfilter: ndx4,
        filterGroup: "group-line-2",
        chartId: "#menuselectBudgetManpowerActualPlant",
        groupByProperty: "Plant",
        promptText: "All plants",
      });
  }

  getPieChart(
    ndx1,
    chartId,
    groupByProperty,
    reduceProperty,
    width,
    height,
    innerRadius
  ) {
    const chart = dc.pieChart(chartId, "group1");
    const dimension = ndx1.dimension((d) => {
      return d[groupByProperty];
    });
    const group = dimension.group().reduceCount((d) => {
      return d[reduceProperty];
    });

    chart
      .width(width)
      .height(height)
      .slicesCap(4)
      .innerRadius(innerRadius)
      .renderLabel(false)
      .cx(100)
      .cy(100)
      .radius(120)
      // .externalLabels(20) // Lable Distance set from pie chart
      .externalRadiusPadding(30)
      // .legend(dc.legend().x(220).y(10).itemWidth(100))
      .legend(
        dc
          .legend()
          .x(220)
          .y(10)
          .itemWidth(100)
          .legendText((d) => {
            const totalValue = d3.sum(group.all(), (d) => d.value);
            return `${d.name}: ${d.data} (${Math.round(
              (d.data / totalValue) * 100
            )}%)`;
          })
      )
      .dimension(dimension)
      .group(group)
      .on("pretransition", (pieChart) => {
        pieChart.selectAll(".pie-slice title").text((d) => {
          return `${d.data.key}: ${d.data.value} (${Math.round(
            ((d.endAngle - d.startAngle) / Math.PI) * 50
          )}%)`;
        });
      });
    chart.render();
    return chart;
  }

  getCountChartGender(ndx1, chartId, groupId, isMale, isFemale) {
    // console.log(ndx1.groupAll().reduceSum())
    const chart = dc.numberDisplay(chartId, groupId);
    const group = ndx1.groupAll().reduceSum((d) => {
      let cnt = 0;

      // console.log("hello",d)
      if (
        isMale &&
        (d.Gender === "M" || d.Gender === "Male" || d.Gender === "MALE")
      ) {
        cnt++;
      }

      if (
        isFemale &&
        (d.Gender === "F" || d.Gender === "Female" || d.Gender === "FEMALE")
      ) {
        cnt++;
      }
      return cnt;
    });
    // console.log(group)

    chart
      .formatNumber(d3.format("."))
      // .dimension(dimension)
      .valueAccessor((d) => {
        // console.log(d)
        return d;
      })
      .group(group);
    chart.render();
    return chart;
  }

  getbarCategoryWisePresentStatusChart(ndx1) {
    const rowContractorWiseChart = dc.rowChart(
      "#barCategoryWisePresentStatusChart",
      "group1"
    );
    const workmanDimension = ndx1.dimension((d: any) => {
      return d.WorkmanCategory;
    });

    const contractorULCGroup = workmanDimension
      .group()
      .reduceCount((d: any) => {
        return d.ULC;
      });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup
            .all()
            .filter((d) => {
              return d.value !== 0;
            })
            .slice(0, 5);
        },
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    rowContractorWiseChart
      .width(390)
      .height(220)
      .elasticX(true)
      .dimension(workmanDimension)
      .label((d) => {
        const totalValue = d3.sum(fakeGroup.all(), (d) => d.value);
        return `${d.key}: ${d.value} (${Math.round(
          (d.value / totalValue) * 100
        )}%)`;
      })
      .on("postRender", (chart) => {
        this.addYLabel(chart, "Category");
      })
      .group(fakeGroup);
    rowContractorWiseChart.render();

    return rowContractorWiseChart;
  }

  getbarNonComplianceWisePresentStatusChartChart(nonComplndx) {
    const rowContractorWiseChart = dc.rowChart(
      "#barNonComplianceWisePresentStatusChart",
      "group1"
    );
    const nonComplianceDimension = nonComplndx.dimension((d: any) => {
      return d.NonComplianceStatus;
    });

    const uLCGroup = nonComplianceDimension.group().reduceCount((d: any) => {
      return d.ActualDeployed;
    });

    // function getTops(contractorULCGroup) {
    //   return {
    //     all: () => {
    //       return contractorULCGroup.all().filter((d) => {
    //         return d.value !== 0;
    //       }).slice(0, 5);
    //     }
    //   };
    // }
    // const fakeGroup = getTops(contractorULCGroup);

    rowContractorWiseChart
      .width(390)
      .height(220)
      .elasticX(true)
      .dimension(nonComplianceDimension)
      .label((d) => {
        // tslint:disable-next-line
        return `${d.key}: ${d.value}`;
      })
      .on("postRender", (chart) => {
        this.addYLabel(chart, "Non-Compliance");
      })
      .group(uLCGroup);
    rowContractorWiseChart.render();

    return rowContractorWiseChart;
  }

  getBarExtendedWHSummaryChart(ndx1) {
    const barExtendedWHSummaryChart = dc.barChart(
      "#barExtendedWHSummaryChart",
      "group1"
    );
    const oTDimension = ndx1.dimension((d: any) => {
      // return d.Age;
      if (d.WorkingHrs <= 1) return "0.5-1";
      if (d.WorkingHrs <= 2) return ">1-2";
      if (d.WorkingHrs <= 4) return ">2-4";
      if (d.WorkingHrs <= 8) return ">4-8";
      if (d.WorkingHrs >= 8) return ">8";
    });
    const oTULCGroup = oTDimension.group().reduceCount((d: any) => {
      return d.ActualDeployed;
    });

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        },
      };
    }

    const nonEmptyData = removeEmptyData(oTULCGroup);
    // // console.log(OTULCGroup.all());
    barExtendedWHSummaryChart
      .width(240)
      .height(200)
      .dimension(oTDimension)
      .group(nonEmptyData)
      .elasticX(true)
      .elasticY(true)
      .yAxisLabel("Count")
      .xAxisLabel("WH")
      .label((d) => {
        // tslint:disable-next-line
        return `${d.y}`;
      })
      .on("pretransition", () => {
        this.hideLabel(barExtendedWHSummaryChart);
      })
      .on("renderlet", this.rotateBarLabel)
      .x(d3.scale.ordinal().domain(["0.5-1", ">1-2", ">2-4", ">4-8", ">8"]))
      .xUnits(dc.units.ordinal);

    barExtendedWHSummaryChart.yAxis().tickFormat(this.tickValueFormat);
    barExtendedWHSummaryChart.render();
    return barExtendedWHSummaryChart;
  }

  getbarStateWisePresentStatusChart(ndx1) {
    const rowStateWisePresentStatusChart = dc.rowChart(
      "#barStateWisePresentStatusChart",
      "group1"
    );
    const stateDimension = ndx1.dimension((d: any) => {
      return d.State;
    });

    const contractorULCGroup = stateDimension.group().reduceCount((d: any) => {
      return d.ActualDeployed;
    });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup
            .all()
            .filter((d) => {
              return d.value !== 0;
            })
            .slice(0, 20);
        },
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    rowStateWisePresentStatusChart
      .width(390)
      .height(520)
      .elasticX(true)
      .dimension(stateDimension)
      .label((d) => {
        const totalValue = d3.sum(fakeGroup.all(), (d) => d.value);
        return `${d.key}: ${d.value} (${Math.round(
          (d.value / totalValue) * 100
        )}%)`;
      })
      .on("postRender", (chart) => {
        this.addYLabel(chart, "State");
      })
      .group(fakeGroup);
    rowStateWisePresentStatusChart.render();

    return rowStateWisePresentStatusChart;
  }

  getbarContractorWisePresentStatusChart(ndx1) {
    const rowbarContractorWisePresentChart = dc.rowChart(
      "#barContractorWisePresentStatusChart",
      "group1"
    );
    const contractorDimension = ndx1.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorULCGroup = contractorDimension
      .group()
      .reduceCount((d: any) => {
        return d.ActualDeployed;
      });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup
            .all()
            .filter((d) => {
              return d.value !== 0;
            })
            .slice(0, 20);
        },
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    rowbarContractorWisePresentChart
      .width(390)
      .height(480)
      .elasticX(true)
      .dimension(contractorDimension)
      .label((d) => {
        const totalValue = d3.sum(fakeGroup.all(), (d) => d.value);
        return `${d.key}: ${d.value} (${Math.round(
          (d.value / totalValue) * 100
        )}%)`;
      })
      .on("postRender", (chart) => {
        this.addYLabel(chart, "Contractor");
      })
      .group(fakeGroup);
    rowbarContractorWisePresentChart.render();

    return rowbarContractorWisePresentChart;
  }
  getRowChartContractorWiseChart(ndx1) {
    const rowContractorWiseChart = dc.rowChart(
      "#rowContractorWiseContinuesWorkingChart",
      "group1"
    );
    const contractorDimension = ndx1.dimension((d: any) => {
      return d.Contractor;
    });

    const contractorULCGroup = contractorDimension
      .group()
      .reduceCount((d: any) => {
        let cnt = 0;
        if (d.IsTenDays === true) {
          cnt++;
        }
        return cnt;
      });

    function getTops(contractorULCGroup) {
      return {
        all: () => {
          return contractorULCGroup
            .all()
            .filter((d) => {
              return d.value !== 0;
            })
            .slice(0, 20);
        },
      };
    }
    const fakeGroup = getTops(contractorULCGroup);

    rowContractorWiseChart
      .width(390)
      .height(445)
      .elasticX(true)
      .dimension(contractorDimension)
      .label((d) => {
        const totalValue = d3.sum(fakeGroup.all(), (d) => d.value);
        return `${d.key}: ${d.value} (${Math.round(
          (d.value / totalValue) * 100
        )}%)`;
      })
      .on("postRender", (chart) => {
        this.addYLabel(chart, "Contractors");
      })
      .group(fakeGroup);
    rowContractorWiseChart.render();

    return rowContractorWiseChart;
  }

  tooltipHtml = (n, d) => {
    /* function to create html content string in tooltip div. */
    return `<h4>${n}</h4><table>
      <tr><td>Count: </td><td>${d ? d.count : 0}</td></tr>
      </table>`;
  };
  showDatatable(isSummary, budgetTable?) {
    this.showDataTable = true;
    if (this.dataTableChart) {
      $("#dataTableChart").html("");
      this.dataTableChart = null;
    }
    setTimeout(() => {
      if (budgetTable) {
        this.dataTableChart = this.getDataTableChartBudget();
      } else {
        if (isSummary) {
          this.dataTableChart = this.getSummaryDataTableChart();
        } else {
          this.dataTableChart = this.getDataTableChart();
        }
      }
    }, 100);
  }

  showDatatableByChartId(chartId, $event, openFullScreen) {
    //$('#dataTableChart').html('');
    this.dataTableChart = null;
    if ($event) {
      const classList: DOMTokenList =
        $event.target.closest("div.dashboard-card").classList;
      classList.add("fullscreen-chart");
    }
    //this.agGridData = this.skillWiseAgGridData;
    setTimeout(() => {
      if (
        chartId == "totalPresentCount" ||
        chartId == "pieSkillWiseChartTotDash" ||
        chartId == "barContractorWiseChartTotDash" ||
        chartId == "mapChart"
      ) {
        this.dataTableChart = this.getDataTableRealTimeHeadCountSummary(
          "group3",
          this.dataTablePresentDimension
        );
        //this.dataTableChart = this.getDataTableRealTimeHeadCountSummary('group3', this.skillWiseAgGridData);
      } else if (chartId == "spanTotalAvailableCount") {
        //this.dataTableChart = this.getDataTableRealTimeHeadCountSummary('group3', this.spanTotalAvailableCount);
        this.dataTableChart = this.getDataTableRealTimeHeadCountSummary(
          "group3",
          this.dataTableAvailableDimesion
        );
      } else if (chartId == "spanActiveGatePassCount") {
        //this.dataTableChart = this.getDataTableRealTimeHeadCountSummary('group3', this.spanActiveGatePassCount);
        this.dataTableChart = this.getDataTableRealTimeHeadCountSummary(
          "group3",
          this.dataTableActiveGatePassDimension
        );
      } else if (chartId == "totalContractorCount") {
        //this.dataTableChart = this.getDataTableContractor('group3', this.totalContractorCountTabledata);
        this.dataTableChart = this.getDataTableContractor(
          "group3",
          this.dataTableContractorDimension
        );
      } else if (chartId == "totalPurchaseOrder") {
        //this.dataTableChart = this.getDataTableWorkOrder('group3', this.totalPurchaseOrderTabledata);
        this.dataTableChart = this.getDataTableWorkOrder(
          "group3",
          this.dataTablePurchaseOrderDimension
        );
      } else if (chartId == "barNonComplianceWisePresentStatusChart") {
        //this.dataTableChart = this.getDataTableNonCompliance('group3', this.barNonComplianceWisePresentStatusChartTabledata);
        this.dataTableChart = this.getDataTableNonCompliance(
          "group3",
          this.dataTableNonComplianceDimension
        );
      }
    }, 100);
  }

  showChart() {
    $("#dataTableChart").html("");
    this.dataTableChart = null;
    this.showDataTable = false;
  }

  resetChart(chart, group?) {
    const group2 = group ? group : "group1";
    chart.filterAll(group2);
    dc.redrawAll(group2);
  }

  resetChart2(chart, group?) {
    setTimeout(() => {
      this.resetChart(chart, group);
    }, 100);
  }

  getOverallManningStatusDataTableChart2(ndx1) {
    const dimension = ndx1.dimension((d) => {
      return d.ShiftGName;
    });
    const chart1 = dc.tableview(
      "#overallManningStatusDataTableChart",
      "group1"
    );
    chart1
      .width(300)
      .height(200)
      .dimension(this.getGroupByDimensionRequirementAndDeployed(dimension))
      .columns([
        {
          title: "Shift",
          data: "ShiftGName",
          sortable: true,
          width: "80px",
          render: (data, type, row, meta) => {
            return row.value.ShiftGName;
          },
        },
        {
          title: "Planned",
          data: "TotalReq",
          sortable: false,
          width: "90px",
          render: (data, type, row, meta) => {
            return row.value.TotalReq;
          },
        },
        {
          title: "Actual",
          data: "ActualDeployed",
          sortable: false,
          width: "90px",
          render: (data, type, row, meta) => {
            return row.value.ActualDeployed;
          },
        },
        {
          title: "Shortage",
          sortable: false,
          width: "90px",
          render: (data, type, row, meta) => {
            return `${row.value.TotalShortagePec}%`;
          },
        },
      ])
      .enableAutoWidth(false)
      .enableColumnReordering(false)
      .enablePagingInfo(false)
      .enablePaging(false)
      .enablePagingSizeChange(false)
      .enableSearch(false)
      .enableScrolling(false)
      .responsive(false)
      .select(false)
      .fixedHeader(false)
      .sortBy((d) => {
        // console.log('Sorting ------->', d);
        return d.value.ShiftGName;
      });
    // .order(d3.ascending);
    // .ordering((d) => { return -d.TotalReq; });
    // .sortBy([['TotalReq', 'asc']]);

    return chart1;
  }

  getOverallManningStatusDataTableChart(ndx1, id, shift, key) {
    const dimension = ndx1.dimension((d) => {
      return d.ShiftGName;
    });
    const chart = dc.numberDisplay(id, "group1");

    chart
      .valueAccessor((d) => {
        if (d && d.value) {
          return d.value[key];
        }
        return 0;
      })
      .group(
        this.removeOtherShiftData(
          this.getGroupByDimensionRequirementAndDeployed(dimension),
          shift
        )
      );

    if (key === "TotalShortagePec") {
      chart.formatNumber(d3.format(",%"));
    } else {
      chart.formatNumber(d3.format("."));
    }
    chart.render();
    return chart;
  }

  removeOtherShiftData(sourceGroup, shift) {
    return {
      top: () => {
        return sourceGroup.all().filter((d) => {
          return d.value.ShiftGName === shift;
        });
      },
    };
  }

  getGroupByDimensionRequirementAndDeployed(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.ShiftGName = v.ShiftGName;
        if (v.IsShortageClear === 0) {
          p.TotalReq += +v.ConractorAssigne;
        }

        p.ActualDeployed += +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round(
            (p.ActualDeployed * 100) / p.TotalReq
          );
          p.TotalShortagePec =
            (100 - (p.ActualDeployed * 100) / p.TotalReq) / 100;
        }

        return p;
      },
      (p: any, v: any) => {
        p.ShiftGName = v.ShiftGName;
        if (v.IsShortageClear === 0) {
          p.TotalReq -= +v.ConractorAssigne;
        }

        p.ActualDeployed -= +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round(
            (p.ActualDeployed * 100) / p.TotalReq
          );
          p.TotalShortagePec =
            (100 - (p.ActualDeployed * 100) / p.TotalReq) / 100;
        }
        return p;
      },
      () => {
        return {
          ShiftGName: "",
          TotalReq: 0,
          ActualDeployed: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
          TotalShortage: 0,
          TotalShortagePec: 0,
        };
      }
    );
  }

  getSummaryDataTableChart() {
    const group = this.dataTableSummaryDimension.group();
    const chart1 = dc.tableview("#dataTableChart", "group1");
    chart1
      .dimension(this.dataTableSummaryDimension)
      .group(group)
      .columns([
        { title: "FinYear", data: "FinYear" },
        { title: "PayMonth", data: "PayMonth" },
        { title: "RequirmentDate", data: "RequirmentDate" },
        { title: "SiteName", data: "SiteName" },
        { title: "PlantName", data: "PlantName" },
        { title: "UnitName", data: "UnitName" },
        { title: "DeptName", data: "DeptName" },
        { title: "SectionName1", data: "SectionName1" },
        { title: "SectionName2", data: "SectionName2" },
        { title: "SectionName3", data: "SectionName3" },
        { title: "ShiftGName", data: "ShiftGName" },
        { title: "CategoryName", data: "CategoryName" },
        { title: "Contractor", data: "Contractor" },
        { title: "TotalReq", data: "TotalReq" },
        { title: "ActualDeployed", data: "ActualDeployed" },
        { title: "TotalShortage", data: "TotalShortage" },
        { title: "ConractorAssigne", data: "ConractorAssigne" },
        { title: "IsShortageClear", data: "IsShortageClear" },
      ])
      .enableAutoWidth(true)
      .enableColumnReordering(true)
      .enablePaging(true)
      .enablePagingSizeChange(true)
      .enableSearch(true)
      .enableScrolling(false)
      .scrollingOptions({
        scrollY: "calc(100vh - 250px)",
        scrollCollapse: true,
        deferRender: true,
      })
      .rowId("Id")
      // .showGroups(true)
      // .groupBy('Expt')
      .responsive(true)
      .select(false)
      .fixedHeader(false)
      .buttons(["pdf", "csv", "excel", "print"])
      .sortBy([["PlantName", "asc"]]);
    return chart1;
  }

  getDataTableChart() {
    const group = this.dataTableDataDimension.group();
    const chart1 = dc.tableview("#dataTableChart", "group1");
    chart1
      .dimension(this.dataTableDataDimension)
      .group(group)
      .columns([
        { title: "FinYear", data: "FinYear" },
        { title: "PayMonth", data: "PayMonth" },
        { title: "PlantCode", data: "PlantCode" },
        { title: "PlantName", data: "PlantName" },
        { title: "PlantBudget", data: "PlantBudget" },
        { title: "UnitCode", data: "UnitCode" },
        { title: "UnitBudget", data: "UnitBudget" },
        { title: "DeptCode", data: "DeptCode" },
        { title: "TotalBudgetAmt", data: "TotalBudgetAmt" },
        { title: "TotalReq", data: "TotalReq" },
        { title: "ActualDeployed", data: "ActualDeployed" },
        { title: "TotalShortage", data: "TotalShortage" },
        { title: "ContractorCode", data: "ContractorCode" },
        { title: "Contractor", data: "Contractor" },
        { title: "ULC", data: "ULC" },
        { title: "LabourName", data: "LabourName" },
        { title: "Gender", data: "Gender" },
        { title: "Age", data: "Age" },
        { title: "Religion", data: "Religion" },
        { title: "Cast", data: "Cast" },
        { title: "Domicile", data: "Domicile" },
        { title: "Cost", data: "Cost" },
        { title: "State", data: "State" },
        { title: "District", data: "District" },
        { title: "NatureofWork", data: "NatureofWork" },
        { title: "Skill", data: "Skill" },
        { title: "Shift", data: "Shift" },
        { title: "AttendanceStatus", data: "AttendanceStatus" },
        { title: "NonComplianceStatus", data: "NonComplianceStatus" },
        { title: "WorkCategory", data: "WorkCategory" },
        { title: "WorkmanCategory", data: "WorkmanCategory" },
        { title: "WorkSite", data: "WorkSite" },
        { title: "WorkFunction", data: "WorkFunction" },
        { title: "Site", data: "Site" },
        { title: "WageCritiaria", data: "WageCritiaria" },
        { title: "Qulification", data: "Qulification" },
      ])
      .enableAutoWidth(true)
      .enableColumnReordering(true)
      .enablePaging(true)
      .enablePagingSizeChange(true)
      .enableSearch(true)
      .enableScrolling(false)
      .scrollingOptions({
        scrollY: "calc(100vh - 250px)",
        scrollCollapse: true,
        deferRender: true,
      })
      .rowId("Id")
      // .showGroups(true)
      // .groupBy('Expt')
      .responsive(true)
      .select(false)
      .fixedHeader(false)
      .buttons(["pdf", "csv", "excel", "print"])
      .sortBy([["PlantName", "asc"]]);
    return chart1;
  }

  getDataTableChartBudget() {
    const group = this.dataTableDimensionBudget.group();
    const chart1 = dc.tableview("#dataTableChart", "group2");
    chart1
      .dimension(this.dataTableDimensionBudget)
      .group(group)
      .columns([
        { title: "State", data: "State" },
        { title: "Plant", data: "Plant" },
        { title: "Unit", data: "Unit" },
        { title: "Function", data: "Function" },
        { title: "Month", data: "Month" },
        { title: "Budget", data: "Budget" },
        { title: "NatureofWork", data: "NatureofWork" },
        { title: "Actual", data: "Actual" },
      ])
      .enableAutoWidth(true)
      .enableColumnReordering(true)
      .enablePaging(true)
      .enablePagingSizeChange(true)
      .enableSearch(true)
      .enableScrolling(false)
      .scrollingOptions({
        scrollY: "calc(100vh - 250px)",
        scrollCollapse: true,
        deferRender: true,
      })
      .rowId("Id")
      // .showGroups(true)
      // .groupBy('Expt')
      .responsive(true)
      .select(false)
      .fixedHeader(false)
      .buttons(["pdf", "csv", "excel", "print"])
      .sortBy([["State", "asc"]]);
    return chart1;
  }

  getDataTableRealTimeHeadCountSummary(groupId, dimension) {
    this.tableColDefs = [
      {
        headerName: "#",
        field: "",
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent,
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Work Order",
        field: "MainWO",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Code",
        field: "Code",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Associate",
        field: "Associate",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Gender",
        field: "Gender",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Skill",
        field: "Skill",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Trade",
        field: "Trade",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Department",
        field: "Department",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "DemandType",
        field: "DemandType",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "State",
        field: "State",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Age",
        field: "Age",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "In Time",
        field: "In Time",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Out Time",
        field: "Out Time",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "OT",
        field: "OT",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
    ];

    const columns = [
      { title: "Contractor", data: "Contractor" },
      { title: "Work Order", data: "MainWO" },
      { title: "Code", data: "Code" },
      { title: "Associate", data: "Associate" },
      { title: "Gender", data: "Gender" },
      { title: "Skill", data: "Skill" },
      { title: "Trade", data: "Trade" },
      { title: "Department", data: "Department" },
      { title: "DemandType", data: "DemandType" },
      { title: "State", data: "State" },
      { title: "Age", data: "Age" },
      { title: "In Time", data: "In Time" },
      { title: "Out Time", data: "Out Time" },
      { title: "OT", data: "OT" },
    ];
    this.showDataTable = true;
    this.agGridData = dimension.top(this.filterAllRecords);
    //this.getDataTableChartCommon(groupId, dimension, columns)
  }

  getDataTableContractor(groupId, dimension) {
    this.tableColDefs = [
      {
        headerName: "#",
        field: "",
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent,
      },
      {
        headerName: "Company",
        field: "Company",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "EmailID",
        field: "EmailID",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "ShortCode",
        field: "ShortCode",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "PANNo",
        field: "PANNo",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Status",
        field: "Status",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
    ];
    const columns = [
      { title: "Company", data: "Company" },
      { title: "EmailID", data: "EmailID" },
      { title: "ShortCode", data: "ShortCode" },
      { title: "PANNo", data: "PANNo" },
      { title: "Status", data: "Status" },
    ];
    this.showDataTable = true;
    this.agGridData = dimension.top(this.filterAllRecords);
    //this.getDataTableChartCommon(groupId, dimension, columns)
  }

  getDataTableWorkOrder(groupId, dimension) {
    this.tableColDefs = [
      {
        headerName: "#",
        field: "",
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent,
      },
      {
        headerName: "Work Order No",
        field: "WorkOrderNo",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Company Name",
        field: "CompanyName",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Owner",
        field: "Owner",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "HOD",
        field: "HOD",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Order Start Date",
        field: "OrderStartDate",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Order End Date",
        field: "OrderEndDate",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Person",
        field: "Person",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Parent Plant Name",
        field: "ParentPlantName",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Child Plant Name",
        field: "ChildPlantName",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Department Name",
        field: "DepartmentName",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Nature Of Work",
        field: "NatureOfWork",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Work Site Area",
        field: "WorkSiteArea",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
    ];
    this.showDataTable = true;
    this.agGridData = dimension.top(this.filterAllRecords);
    const columns = [
      { title: "Work Order No", data: "WorkOrderNo" },
      { title: "Company Name", data: "CompanyName" },
      { title: "Owner", data: "Owner" },
      { title: "HOD", data: "HOD" },
      { title: "Order Start Date", data: "OrderStartDate" },
      { title: "Order End Date", data: "OrderEndDate" },
      { title: "Person", data: "Person" },
      { title: "Parent Plant Name", data: "ParentPlantName" },
      { title: "Child Plant Name", data: "ChildPlantName" },
      { title: "Department Name", data: "DepartmentName" },
      { title: "Nature Of Work", data: "NatureOfWork" },
      { title: "Work Site Area", data: "WorkSiteArea" },
    ];
    //this.getDataTableChartCommon(groupId, dimension, columns)
  }

  getDataTableNonCompliance(groupId, dimension) {
    this.tableColDefs = [
      {
        headerName: "#",
        field: "",
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent,
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Sub-Contractor",
        field: "Sub-Contractor",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Code",
        field: "Code",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Associate",
        field: "Associate",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Punch Time",
        field: "Punch Time",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
      {
        headerName: "Error Details",
        field: "ErrorDetails",
        width: 10,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
      },
    ];
    const columns = [
      { title: "Plant", data: "Plant" },
      { title: "Contractor", data: "Contractor" },
      { title: "Sub-Contractor", data: "Sub-Contractor" },
      { title: "Code", data: "Code" },
      { title: "Associate", data: "Associate" },
      { title: "Punch Time", data: "Punch Time" },
      { title: "Error Details", data: "ErrorDetails" },
    ];
    this.showDataTable = true;
    this.agGridData = dimension.top(this.filterAllRecords);
    // this.getDataTableChartCommon(groupId, dimension, columns)
  }

  getDataTableChartCommon(groupId, dimension, columns) {
    const group = dimension.group();
    const chart1 = dc.tableview("#dataTableChart", groupId);
    chart1
      .dimension(dimension)
      .group(group)
      .columns(columns)
      .enableAutoWidth(true)
      .enableColumnReordering(true)
      .enablePaging(true)
      .enablePagingSizeChange(true)
      .enableSearch(true)
      .enableScrolling(false)
      .scrollingOptions({
        scrollY: "calc(100vh - 250px)",
        scrollCollapse: true,
        deferRender: true,
      })
      .rowId("Id")
      // .showGroups(true)
      // .groupBy('Expt')
      .responsive(true)
      .select(false)
      .fixedHeader(false)
      .buttons(["pdf", "csv", "excel", "print"]);
    return chart1;
  }

  getGroupBarChart(
    ndx1,
    chartId,
    groupProperty,
    xLabel,
    yLabel,
    width,
    height
  ) {
    const chart = dc.barChart(chartId, "group1");

    const barKeyList = ["TotalReq", "ActualDeployed", "TotalShortage"];
    const barLabelMap = {
      TotalReq: "Total Requirements",
      ActualDeployed: "Actual Deployed",
      TotalShortage: "Total Shortage",
    };

    const dimension = ndx1.dimension((d: any) => {
      if (d[groupProperty] !== null) {
        return d[groupProperty];
      }
    });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.TotalReq += +v.TotalReq;
        p.ActualDeployed += +v.ActualDeployed;
        p.TotalShortage += +v.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round(
            (p.ActualDeployed * 100) / p.TotalReq
          );
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        p.TotalReq -= +v.TotalReq;
        p.ActualDeployed -= +v.ActualDeployed;
        p.TotalShortage -= +v.TotalShortage;

        const total = p.TotalReq + p.ActualDeployed + p.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round(
            (p.ActualDeployed * 100) / p.TotalReq
          );
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          ActualDeployed: 0,
          TotalShortage: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
          TotalShortagePec: 0,
        };
      }
    );

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.TotalReq !== 0;
          });
        },
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 20, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${
            d.value[this.layer]
          } (${d.value[this.layer + "Pec"]}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${d.y} (${d.data.value[d.layer + "Pec"]}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on("pretransition", () => {
        this.hideLabel(chart);
      })
      // .on('renderlet', this.rotateBarLabel)
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    // chart.yAxis().tickFormat(this.tickValueFormat);

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupBarBudgetChart(
    ndx1,
    chartId,
    groupProperty,
    xLabel,
    yLabel,
    width,
    height,
    isQuarter?
  ) {
    const chart = dc.barChart(chartId, "group2");

    const barKeyList = ["Budget", "Actual"];
    const barLabelMap = {
      Budget: "Budget",
      Actual: "Actual",
    };

    const dimension = ndx1.dimension((d: any) => {
      if (isQuarter) {
        const q = this.quarterMonth[d[groupProperty]];
        return q ? q : d[groupProperty];
      }

      return d[groupProperty];
    });
    const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Budget !== 0 && d.value.Actual !== 0;
          });
        },
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 50, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${
            d.value[this.layer]
          } (${d.value[this.layer + "Pec"]}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${d.y} (${d.data.value[d.layer + "Pec"]}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true))
      .on("pretransition", () => {
        this.hideLabel(chart);
      })
      .on("renderlet", this.rotateBarLabel)
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupByDimensionBudgetAndActual(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);

        if (p.Budget > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }

        return p;
      },
      (p: any, v: any) => {
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);

        if (p.Budget > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0,
          BudgetPec: 0,
          ActualPec: 0,
        };
      }
    );
  }

  // getPartitionPieChart(ndx1) {
  //   const width = 150;
  //   const height = 150;
  //   const radius = (Math.min(width, height) / 2) - 10;

  //   const x = d3.scale.linear()
  //     .range([0, 2 * Math.PI]);

  //   const y = d3.scale.sqrt()
  //     .range([0, radius]);

  //   const pieDimension = ndx1.dimension((d: any) => {
  //     return 'Total';
  //   });
  //   const shortageGroup = pieDimension.group().reduceSum((d) => {
  //     return d.TotalShortage;
  //   });

  //   const deployedGroup = pieDimension.group().reduceSum((d) => {
  //     return d.ActualDeployed;
  //   });

  //   const myData = {
  //     name: 'Total',
  //     children: [{
  //       name: 'Requirement',
  //       children: [{
  //         name: 'Present',
  //         value: deployedGroup.all()[0].value
  //       }, {
  //         name: 'Shortage',
  //         value: shortageGroup.all()[0].value
  //       }]
  //     }]
  //   };

  //   const valueSum = deployedGroup.all()[0].value + shortageGroup.all()[0].value;

  //   const allItems = ['Requirement', 'Present', 'Shortage'];
  //   const myColors = myScale(allItems.length);

  //   const partition = d3.layout.partition()
  //     .value((d) => {
  //       return d.value;
  //     });

  //   const arc = d3.svg.arc()
  //     .startAngle((d) => {
  //       return Math.max(0, Math.min(2 * Math.PI, x(d.x)));
  //     })
  //     .endAngle((d) => {
  //       return Math.max(0, Math.min(2 * Math.PI, x(d.x + d.dx)));
  //     })
  //     .innerRadius((d) => {
  //       return Math.max(0, y(d.y));
  //     })
  //     .outerRadius((d) => {
  //       return Math.max(0, y(d.y + d.dy));
  //     });

  //   const svg = d3.select('#chart').append('svg')
  //     .attr('width', width)
  //     .attr('height', height)
  //     .attr('id', 'container') // added
  //     .append('g')
  //     .attr('transform', `translate(${width / 2},${(height / 2)})`);

  //   // Add the mouseleave handler to the bounding circle.
  //   d3.select('#container').on('mouseleave', mouseleave); // added
  //   drawLegend();

  //   let totalValue = 0;

  //   const path = svg.selectAll('path')
  //     .data(partition.nodes(myData))
  //     .enter().append('path')
  //     .attr('d', arc)
  //     .style('fill', (d) => {
  //       if (d.parent == null) {
  //         return '#FAFAFA';
  //       }
  //       return myColors[allItems.indexOf(d.name)];
  //     })
  //     .on('click', click)
  //     .on('mouseover', mouseover) // added
  //     .append('title')
  //     .text((d) => {
  //       const percentage = +(100 * d.value / valueSum).toPrecision(3);
  //       let percentageString = `${percentage}%`;
  //       if (percentage < 0.1) {
  //         percentageString = '< 0.1%';
  //       }
  //       return `${d.name} \n${d.value} (${percentageString})`;
  //     })
  //     .classed('tooltip', true);

  //   totalValue = path.node().__data__.value;

  //   // Fade all but the current sequence, and show it in the breadcrumb trail.
  //   function mouseover(d) {
  //     const percentage = +(100 * d.value / totalValue).toPrecision(3);
  //     let percentageString = `${percentage}%`;
  //     if (percentage < 0.1) {
  //       percentageString = '< 0.1%';
  //     }

  //     const sequenceArray = getAncestors(d);

  //     // Fade all the segments.
  //     svg.selectAll('path')
  //       .style('opacity', 0.3);

  //     // Then highlight only those that are an ancestor of the current segment.
  //     svg.selectAll('path')
  //       .filter((node) => {
  //         return (sequenceArray.indexOf(node) >= 0);
  //       })
  //       .style('opacity', 1);
  //   }

  //   // Given a node in a partition layout, return an array of all of its ancestor
  //   // nodes, highest first, but excluding the root.
  //   function getAncestors(node) {
  //     const path = [];
  //     let current = node;
  //     while (current.parent) {
  //       path.unshift(current);
  //       current = current.parent;
  //     }
  //     return path;
  //   }

  //   // Restore everything to full opacity when moving off the visualization.
  //   function mouseleave(d) {

  //     // Hide the breadcrumb trail
  //     svg.select('#trail')
  //       .style('visibility', 'hidden');

  //     // Deactivate all segments during transition.
  //     // svg.selectAll('path').on('mouseover', null);

  //     // Transition each segment to full opacity and then reactivate it.
  //     svg.selectAll('path')
  //       .style('opacity', 1);

  //   }

  //   function click(d) {
  //     svg.transition()
  //       .duration(750)
  //       .tween('scale', () => {
  //         const xd = d3.interpolate(x.domain(), [d.x, d.x + d.dx]);
  //         const yd = d3.interpolate(y.domain(), [d.y, 1]);
  //         const yr = d3.interpolate(y.range(), [d.y ? 20 : 0, radius]);
  //         return (t) => {
  //           x.domain(xd(t));
  //           y.domain(yd(t)).range(yr(t));
  //         };
  //       })
  //       .selectAll('path')
  //       .attrTween('d', (d) => {
  //         return () => {
  //           return arc(d);
  //         };
  //       });
  //   }

  //   svg.select(self.frameElement).style('height', `${height}px`);

  //   function myScale(steps) {
  //     let cols;
  //     let t;
  //     let colors = '#337AB7, deeppink, orange'.replace(/(, *| +)/g, ',').split(',');
  //     if (steps === 1) {
  //       return [colors[0]];
  //     }
  //     colors = chroma.bezier(colors);
  //     const cs = chroma.scale(colors).mode('lab').correctLightness(true);
  //     cols = [];
  //     const ref = (() => {
  //       let k;
  //       let results;
  //       results = [];
  //       for (let i = k = 0, ref = steps; 0 <= ref ? k < ref : k > ref; i = 0 <= ref ? ++k : --k) {
  //         results.push(i / (steps - 1));
  //       }
  //       return results;
  //     })();
  //     for (let j = 0, len = ref.length; j < len; j++) {
  //       t = ref[j];
  //       cols.push(cs(t).hex());
  //     }
  //     return cols;
  //   }

  //   function drawLegend() {

  //     // Dimensions of legend item: height, spacing, radius of rounded rect. width will be set dynamically
  //     const li = {
  //       h: 30,
  //       s: 3,
  //       r: 3,
  //       w: 100,
  //     };

  //     const legend = d3.select('#legend').append('svg:svg')
  //       .attr('width', li.w)
  //       .attr('height', d3.keys(myColors).length * (li.h + li.s));

  //     const labelVsColors = {};

  //     for (let i = 0; i < allItems.length; i++) {
  //       labelVsColors[allItems[i]] = myColors[i];
  //     }

  //     const g = legend.selectAll('g')
  //       .data(d3.entries(labelVsColors))
  //       .enter().append('svg:g')
  //       .attr('transform', (d, i) => {
  //         return `translate(0, ${i * (li.h + li.s)})`;
  //       });

  //     g.append('svg:rect')
  //       .attr('rx', li.r)
  //       .attr('ry', li.r)
  //       .attr('width', li.w)
  //       .attr('height', li.h)
  //       .style('fill', (d) => {
  //         return d.value;
  //       }).on('mouseover', (d) => {
  //         const nodes = flatten(myData);
  //         const n = nodes.find((d1) => { return (d1.name === d.key); });
  //         mouseover(n);
  //       }).on('mouseleave', mouseleave);

  //     g.append('svg:text')
  //       .attr('x', li.w / 2)
  //       .attr('y', li.h / 2)
  //       .attr('dy', '0.35em')
  //       .attr('text-anchor', 'middle')
  //       .style('pointer-events', 'none')
  //       .text((d) => {
  //         return d.key;
  //       });
  //   }

  //   function flatten(root) {
  //     const nodes = [];
  //     let i = 0;

  //     function recurse(node) {
  //       if (node.children) node.children.forEach(recurse);
  //       if (!node.id) node.id = ++i;
  //       nodes.push(node);
  //     }

  //     recurse(root);
  //     return nodes;
  //   }

  // }

  resetChartAll() {
    // kh  this.dispatchCall('drillDownPlantWiseRequirementVsShortageReset', this.drillDownPlantWiseRequirementVsShortage);
    // kh  this.dispatchCall('drillDownUnitWiseBudgetVSActualReset', this.drillDownUnitWiseBudgetVSActual);
    // kh  this.dispatchCall('drillDownUnitWiseBudgetManpowerVSActualReset', this.drillDownUnitWiseBudgetManpowerVSActual);
    // kh this.dispatchCall('piePlantWisePresentStatusChartReset', this.piePlantWisePresentStatusChart);
    // this.dispatchCall('drillDownSiteWiseShortageForTheDayChartReset', this.drillDownSiteWiseShortageForTheDayChart);
    // this.dispatchCall('drillDownPlantWisePresentStatusReset', this.drillDownPlantWisePresentStatus);
    // this.dispatchCall('drillDownPlantWiseBudgetVsActualReset', this.drillDownPlantWiseBudgetVsActual);
    this.dispatchCall(
      "drillDownPlantWisePresentReset",
      this.drillDownPlantWisePresent
    );

    // kh //this.renderallCharts(['group1', 'group2', 'group-line-1', 'group-line-2', 'group-drill-1', 'group-drill-2']);

    // this.dispatch = d3.dispatch(
    //   'drillDownPlantWiseRequirementVsShortageReset',
    //   'drillDownPlantWiseRequirementVsShortageBack',
    //   'drillDownUnitWiseBudgetVSActualReset',
    //   'drillDownUnitWiseBudgetVSActualBack',
    //   'drillDownUnitWiseBudgetManpowerVSActualReset',
    //   'drillDownUnitWiseBudgetManpowerVSActualBack',
    //   'piePlantWisePresentStatusChartReset',
    //   'piePlantWisePresentStatusChartBack',
    //   'drillDownPlantWisePresentReset',
    //   'drillDownPlantWisePresentBack',
    // );
    this.getAllDataSet(this.startDate, this.endDate);
    this.getNonCompianceLabourList(this.startDate, this.endDate);
    this.getAllDataSetBudget("", "");
    this.getAllInHeadCount("", "");
    this.getAllOutHeadCount("", "");
    this.getAllRequirementAndShortage("");
    this.getTotalActiveGatePass();
    this.getWeeklyShiftData(this.startDate, this.endDate);
  }

  renderallCharts(groups) {
    groups.forEach((group) => {
      dc.filterAll(group);
    });

    groups.forEach((group) => {
      dc.renderAll(group);
    });
  }

  addYLabel = (chartToUpdate, displayText) => {
    const textSelection = chartToUpdate
      .svg()
      .append("text")
      .attr("class", "y-axis-label")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(0)")
      .attr("x", -chartToUpdate.height() / 2)
      .attr("y", 10)
      .text(displayText);
    const textDims = textSelection.node().getBBox();
    const chartMargins = chartToUpdate.margins();

    // Dynamically adjust positioning after reading text dimension from DOM
    textSelection
      .attr(
        "x",
        -chartMargins.top -
          (chartToUpdate.height() - chartMargins.top - chartMargins.bottom) / 2
      )
      .attr(
        "y",
        Math.max(
          Math.ceil(textDims.height),
          chartMargins.left - Math.ceil(textDims.height) - 5
        )
      );
  };

  tickValueFormat = (d) => {
    let newd = d;
    if (d / 10000000 >= 1) {
      newd = `${d / 10000000}Cr`;
    } else if (d / 100000 >= 1) {
      newd = `${d / 100000}L`;
    } else if (d / 1000 >= 1) {
      newd = `${d / 1000}K`;
    }
    return newd;
  };

  hideLabel = (chart) => {
    chart.selectAll("text.barLabel").attr("class", "barLabel d-none");
  };

  rotateBarLabel = (chart) => {
    if (!chart.select(".bar")[0][0]) {
      return;
    }

    const y = chart.y();
    const x = chart.x();
    // tslint:disable-next-line
    const barWidth = parseInt(chart.select(".bar")[0][0].getAttribute("width"));
    const gap = chart.gap();
    const groupGap = chart.groupGap();

    // // console.log('gap', gap);
    // // console.log('groupGap', groupGap);
    // // console.log('barWidth', barWidth);

    chart
      .selectAll("text.barLabel")
      .attr("class", "barLabel d-none")
      .attr("transform", "rotate(-90)")
      .attr("y", (d) => {
        let groupBars = 0;
        if (d.layer === "Actual") {
          groupBars = barWidth + groupGap;
        } else if (d.layer === "ActualDeployed") {
          groupBars = barWidth + gap;
        } else if (d.layer === "TotalShortage") {
          groupBars = barWidth * 2 + gap + groupGap;
        }

        // // console.log('y', x(d.x), groupBars);

        return x(d.x) + barWidth / 2 + gap + groupGap + groupBars;
      })
      .attr("x", (d) => {
        // // console.log('x', -y(0));
        return -y(0) + 40;
      })
      .attr("class", "barLabel");
  };

  dispatchCall(event, chart) {
    this.dispatch[event](chart);
  }

  showFullscreen($event, chart) {
    if (chart) {
      this.oldChartWidth = chart.width();
      this.oldChartHeight = chart.height();
      chart
        .width(window.innerWidth - 150)
        .height(window.innerHeight - 150)
        .render();
    }

    const classList: DOMTokenList =
      $event.target.closest("div.dashboard-card").classList;
    classList.add("fullscreen-chart");
  }

  cancelFullscreen($event, chart) {
    this.showDataTable = false;
    this.agGridData = [];
    $("#dataTableChart").html("");
    this.dataTableChart = null;

    if (chart) {
      chart.width(this.oldChartWidth).height(this.oldChartHeight).render();
    }

    const classList: DOMTokenList =
      $event.target.closest("div.dashboard-card").classList;
    classList.remove("fullscreen-chart");
  }

  onStartDateChange($event) {
    if ($event) {
      this.onDateRangeChange({
        startDate: $event.value,
        endDate: $event.value,
      });
    }
  }

  openDateFilter() {
    this.modalRef = this.modalService.open(CustomDateRangeFilterComponent, {
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal modal-panel",
      size: "lg",
      centered: true,
    });

    this.modalRef.componentInstance.dateChange.subscribe((res) => {
      this.onDateRangeChange(res);
      this.modalRef.close();
    });
  }

  setTotalDashboardTableColumnsForSummary(workorderColumns: boolean) {
    const tempColumns: any[] = [
      {
        headerName: "#",
        field: "SerialNo",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 20,
        sortable: false,
        suppressMenu: true,
      },
    ];

    if (workorderColumns) {
      tempColumns.push(
        {
          headerName: "Sub-Contractor",
          field: "Sub-Contractor",
          width: 15,
          sortable: false,
          suppressMenu: true,
        },
        {
          headerName: "Work Order",
          field: "SubWO",
          width: 10,
          sortable: false,
          suppressMenu: true,
        }
      );
    }

    tempColumns.push(
      {
        headerName: "No. of Gate Passes Details",
        children: [
          {
            headerName: "Total",
            field: "gpTotal",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "General",
            field: "gpGeneral",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Shutdown",
            field: "gpShutdown",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
        ],
      },
      {
        headerName: "Attendance",
        children: [
          {
            headerName: "Total",
            field: "atTotal",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "General",
            field: "atGeneral",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Shutdown",
            field: "atShutdown",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Supervisor",
            field: "atSupervisor",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
        ],
      },
      {
        headerName: "Absents",
        children: [
          {
            headerName: "Total",
            field: "absTotal",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "General",
            field: "absGeneral",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Shutdown",
            field: "absShutdown",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Supervisor",
            field: "absSupervisor",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
        ],
      },
      {
        headerName: "Inside",
        children: [
          {
            headerName: "Total",
            field: "inTotal",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "General",
            field: "inGeneral",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Shutdown",
            field: "inShutdown",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
        ],
      },
      {
        headerName: "Outside",
        children: [
          {
            headerName: "Total",
            field: "outTotal",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "General",
            field: "outGeneral",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Shutdown",
            field: "outShutdown",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
          {
            headerName: "Supervisor",
            field: "outSupervisor",
            width: 10,
            sortable: false,
            suppressMenu: true,
          },
        ],
      }
    );

    this.agGridTotalDashboardColumnDefs = tempColumns;
  }

  setTotalDashboardTableDataForSummary(dimension, contractorName?) {
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.Contractor = v.Contractor;
        p["Sub-Contractor"] = v["Sub-Contractor"];
        p["SubWO"] = v["SubWO"];
        p.gpTotal += 1;

        if (v.DemandType == "General") {
          p.gpGeneral += 1;

          if (v["In Time"]) {
            p.atGeneral += 1;

            if (v["Out Time"]) {
              p.outGeneral += 1;
            } else {
              p.inGeneral += 1;
            }
          } else {
            p.absGeneral += 1;
          }
        } else if (v.DemandType == "Shutdown") {
          p.gpShutdown += 1;

          if (v["In Time"]) {
            p.atShutdown += 1;

            if (v["Out Time"]) {
              p.outShutdown += 1;
            } else {
              p.inShutdown += 1;
            }
          } else {
            p.absShutdown += 1;
          }
        }

        if (v.Trade == "Supervisor") {
          p.gpTrade += 1;

          if (v["In Time"]) {
            p.atSupervisor += 1;

            if (v["Out Time"]) {
              p.outSupervisor += 1;
            } else {
              p.inSupervisor += 1;
            }
          } else {
            p.absSupervisor += 1;
          }
        }

        p.atTotal = p.atGeneral + p.atShutdown;
        p.absTotal = p.absGeneral + p.absShutdown;
        p.inTotal = p.inGeneral + p.inShutdown;
        p.outTotal = p.outGeneral + p.outShutdown;
        return p;
      },
      (p: any, v: any) => {
        p.gpTotal -= 1;

        if (v.DemandType == "General") {
          p.gpGeneral -= 1;

          if (v["In Time"]) {
            p.atGeneral -= 1;

            if (v["Out Time"]) {
              p.outGeneral -= 1;
            } else {
              p.inGeneral -= 1;
            }
          } else {
            p.absGeneral -= 1;
          }
        } else if (v.DemandType == "Shutdown") {
          p.gpShutdown -= 1;

          if (v["In Time"]) {
            p.atShutdown -= 1;

            if (v["Out Time"]) {
              p.outShutdown -= 1;
            } else {
              p.inShutdown -= 1;
            }
          } else {
            p.absShutdown -= 1;
          }
        }

        if (v.Trade == "Supervisor") {
          p.gpTrade -= 1;

          if (v["In Time"]) {
            p.atSupervisor -= 1;

            if (v["Out Time"]) {
              p.outSupervisor -= 1;
            } else {
              p.inSupervisor -= 1;
            }
          } else {
            p.absSupervisor -= 1;
          }
        }

        return p;
      },
      () => {
        return {
          Contractor: "",
          "Sub-Contractor": "",
          SubWO: "",
          gpTotal: 0,
          gpGeneral: 0,
          gpShutdown: 0,
          gpSupervisor: 0,
          atTotal: 0,
          atGeneral: 0,
          atShutdown: 0,
          atSupervisor: 0,
          absTotal: 0,
          absGeneral: 0,
          absShutdown: 0,
          absSupervisor: 0,
          inTotal: 0,
          inGeneral: 0,
          inShutdown: 0,
          inSupervisor: 0,
          outTotal: 0,
          outGeneral: 0,
          outShutdown: 0,
          outSupervisor: 0,
        };
      }
    );

    let counter = 1;
    this.agGridTotalDashboardRowData = amountSumGroup
      .all()
      .filter((item) => {
        if (contractorName) {
          return contractorName == item.value.Contractor;
        } else {
          return true;
        }
      })
      .map((item) => {
        item.value["SerialNo"] = counter;
        counter++;
        return item.value;
      });
  }

  setTotalDashboardTableColumnsForData() {
    const tempColumns: any[] = [
      {
        headerName: "#",
        field: "SerialNo",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 20,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Work Order",
        field: "MainWO",
        width: 15,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Sub-Contractor",
        field: "Sub-Contractor",
        width: 20,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Work Order",
        field: "SubWO",
        width: 15,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Code",
        field: "Code",
        width: 10,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Associate",
        field: "Associate",
        width: 20,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Gatepass Type",
        field: "DemandType",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Category",
        field: "Category",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Gender",
        field: "Gender",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "State",
        field: "State",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Trade",
        field: "Trade",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Age",
        field: "Age",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Department",
        field: "Department",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "In Time",
        field: "In Time",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "Out Time",
        field: "Out Time",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "WH",
        field: "WH",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
      {
        headerName: "OT",
        field: "OT",
        width: 5,
        sortable: false,
        suppressMenu: true,
      },
    ];

    this.agGridTotalDashboardColumnDefs = tempColumns;
  }

  lastContractorName = "";
  showDataOnly = false;

  onTotalDashboardCellClick($event) {
    // console.log('onTotalDashboardCellClick Column', $event.column.colId);
    // console.log('onTotalDashboardCellClick', $event);

    const colId = $event.column.colId;
    const colValue = $event.value;
    if (colId == "Contractor") {
      if (!this.tableFilters.length) {
        this.tableFilters.push(["Contractor", colValue]);
        this.lastContractorName = colValue;
        this.setTotalDashboardTableForContractor(colValue);
      }
    } else if (
      colId.startsWith("gp") ||
      colId.startsWith("at") ||
      colId.startsWith("abs") ||
      colId.startsWith("in") ||
      colId.startsWith("out")
    ) {
      this.showDataOnly = true;
      this.setTotalDashboardTableData(colId, $event.data);
    }
  }

  setTotalDashboardTableForContractor(contractorName?) {
    this.agGridTotalDashboardColumnDefs = null;
    this.agGridTotalDashboardRowData = null;
    setTimeout(() => {
      if (contractorName) {
        const dimension = this.totalDashboardNdxAll.dimension((d: any) => {
          return `${d["Contractor"]}-${d["SubWO"]}`;
        });
        this.setTotalDashboardTableColumnsForSummary(true);
        this.setTotalDashboardTableDataForSummary(dimension, contractorName);
      } else {
        const dimension = this.totalDashboardNdxAll.dimension((d: any) => {
          return d["Contractor"];
        });
        this.setTotalDashboardTableColumnsForSummary(false);
        this.setTotalDashboardTableDataForSummary(dimension);
      }
    }, 100);
  }

  setTotalDashboardTableData(colId, rowData) {
    this.agGridTotalDashboardColumnDefs = null;
    this.agGridTotalDashboardRowData = null;
    setTimeout(() => {
      this.setTotalDashboardTableColumnsForData();
      let counter = 1;
      this.agGridTotalDashboardRowData = this.totalDashboardAPIData
        .filter((v) => {
          if (rowData.Contractor != v.Contractor) {
            return false;
          }

          if (this.tableFilters.length) {
            if (rowData.SubWO != v.SubWO) {
              return false;
            }
          }

          if (v.DemandType == "General") {
            if (colId == "gpGeneral" || colId == "gpTotal") return true;

            if (v["In Time"]) {
              if (colId == "atGeneral" || colId == "atTotal") return true;

              if (v["Out Time"]) {
                if (colId == "outGeneral" || colId == "outTotal") return true;
              } else {
                if (colId == "inGeneral" || colId == "inTotal") return true;
              }
            } else {
              if (colId == "absGeneral" || colId == "absTotal") return true;
            }
          } else if (v.DemandType == "Shutdown") {
            if (colId == "gpShutdown" || colId == "gpTotal") return true;

            if (v["In Time"]) {
              if (colId == "atShutdown" || colId == "atTotal") return true;

              if (v["Out Time"]) {
                if (colId == "outShutdown" || colId == "outTotal") return true;
              } else {
                if (colId == "inShutdown" || colId == "inTotal") return true;
              }
            } else {
              if (colId == "absShutdown" || colId == "absTotal") return true;
            }
          }

          if (v.Trade == "Supervisor") {
            if (colId == "gpTrade") return true;

            if (v["In Time"]) {
              if (colId == "atSupervisor") return true;

              if (v["Out Time"]) {
                if (colId == "outSupervisor") return true;
              } else {
                if (colId == "inSupervisor") return true;
              }
            } else {
              if (colId == "absSupervisor") return true;
            }
          }

          return false;
        })
        .map((item) => {
          item["SerialNo"] = counter;
          counter++;
          return item;
        });
    }, 100);
  }

  tableFilters = [];
  onTableFilterBack() {
    if (this.showDataOnly) {
      if (this.tableFilters.length) {
        this.setTotalDashboardTableForContractor(this.tableFilters[0][1]);
      } else {
        this.setTotalDashboardTableForContractor();
      }
      this.showDataOnly = false;
    } else {
      this.tableFilters.pop();
      this.setTotalDashboardTableForContractor();
    }
  }

  callDashboardCommonApi() {
    if (this.showTotalDashboardTab) {
      this.getRealTimeHeadCount(this.startDate);
      this.getRealtimeHeadcountSummary(this.startDate, this.endDate);
      this.getTotalContractor();
      this.getTotalWorkOrder();
    }

    if (this.showAttendanceStaticsTab) {
      this.getAllDataSet(this.startDate, this.endDate);
      this.getAllInHeadCount("", "");
      this.getAllOutHeadCount("", "");
      this.getAllRequirementAndShortage("");
      this.getTotalActiveGatePass();
      this.getWeeklyShiftData(this.startDate, this.endDate);
    }

    if (this.showBudgetStaticsTab) {
      this.getAllDataSetBudget("", "");
    }
  }
}
