import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  Input,
  Renderer2,
  AfterViewChecked,
  AfterContentInit,
  AfterContentChecked
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services';
import { AgGridNg2 } from 'ag-grid-angular';
import { SelectStepsService } from '../select-step.service';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-select-step-content',
  templateUrl: './select-step-content.component.html',
  styleUrls: ['./select-step-content.component.css'],
  providers: [SelectStepsService]
  // encapsulation: ViewEncapsulation.None,
})
export class SelectStepContentComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  @ViewChild('componentRef', { read: ViewContainerRef }) public componentRef: ViewContainerRef;
  @ViewChild('outerTemplateRef', { read: ViewContainerRef }) public outerTemplateReference: ViewContainerRef;
  @ViewChild('aboveGridElementRef', { read: ViewContainerRef }) public aboveGridRef: ViewContainerRef;
  @ViewChild('belowGridElementRef', { read: ViewContainerRef }) public belowGridRef: ViewContainerRef;
  @ViewChild('outerContentRef', { read: ViewContainerRef }) public outerContentRef: ViewContainerRef;
  @ViewChild('inputRef') private inputRefence: ElementRef<HTMLElement>;
  @ViewChild('modalContent') private modalContent: ElementRef<HTMLElement>;
  @ViewChild('agGrid') public agGrid: AgGridNg2;
  public subject: Subject<any>;
  public content: any;
  public header: any;
  public footer: any;

  /** Register data of row selected */
  public mapRowData = new Map();
  public storeRowData: any[];
  public isOuterTemplateRef: boolean;
  public outerTemplateRef: any;
  /** This input value is contain form group which pass by user */
  public stepsForm: FormGroup;
  /** This input value contain form Array which is pass from the user */
  public formArrayItems: FormArray;
  /** Set steps data value */
  public stepsData: any[];
  /** Set steps data value */
  public columnDefs: any[];
  /** Set steps data value */
  public rowData: any[];
  /** Set gridOption data for grid */
  public gridOptions: any;
  /** Set row selection config */
  public rowSelection: string;
  /** Set next click data */
  public nextData: any[];
  /** Set prev data */
  public previousData: any[];
  /** `nextFirstStep`, `nextSecondStep`*/
  public nextFirstStep: number;
  public nextSecondStep: number;
  /** `prevFirstStep`, `prevSecondStep`*/
  public prevFirstStep: number;
  public prevSecondStep: number;
  /** Set bollean value tru or false */
  public isNextFirst: boolean;
  public spliceData: any[];
  /** Set boolean value true or false */
  public isNextButtonDisabled: boolean;
  /** Set boolean value true or false */
  public isPrevButtonDisabled: boolean;
  /** Set boolean value to the isPrevButton */
  public isPrevButton: boolean;
  /** Set boolean value to the isPrevButton */
  public isNextButton: boolean;
  /** Hold boolean value of `isHeader` */
  public isHeader: boolean;
  /** Hold boolean value of `isOuterContentModal` */
  public isOuterContentModal: boolean;
  /** store form value */
  public formValue: string;
  /** Set boolean value skip button */
  public isSkipButton: boolean;
  /** Set boolean value skip button */
  public isTemplateRef: boolean;
  /** Set boolean value cancel button */
  public isCancelButton: boolean;
  /** Set boolean value submit button */
  public isSubmitButton: boolean;
  /** Set boolean value close button */
  public isCloseButton: boolean;
  /** Hold isRowSelectedData boolean */
  public isRowSelectedData: boolean;
  /** Set boolean value skip button */
  public isFooter: boolean;
  /** Set boolean value skip button */
  public isSubmitButtonDisabled: boolean;
  /** Set boolean value skip button */
  public isCancelButtonDisabled: boolean;
  /** Hold boolean value of view chacked */
  public isAfterViewChecked: boolean;
  /** Hold boolean value */
  public isAfterViewCheckedOnRow: boolean;
  /** Hold boolean value */
  public isAfterViewCheckedBodyTemplate: boolean;
  /** Set steps inside this map variable */
  public selectedData: any[];
  /** Hold row selected data */
  public isClickedOnceOnRow: boolean;
  /** Hold prevChangeValue value */
  private prevChangeValue: string;
  /** Hold  rowSelectedData */
  @Input() public rowSelectedData: any;

  @Input() public selectionLabourCount: number;

  constructor(
    private renderer: Renderer2,
    // public sharedService: SelectStepsService,
    public activeModal: NgbActiveModal,
    public router: Router,
    public sharedService: SharedService
  ) {
    // ----------------------- //
    this.subject = new Subject<any>();
    this.rowSelection = 'single';
    // ------------------- //
    this.stepsData = [];
    this.selectedData = [];
    // this.steps = new Map<string, Object>();
    // ------------------------------//
    this.isNextFirst = false;
    this.nextData = this.spliceData;
    this.previousData = this.stepsData;
    this.isHeader = false;
    this.isFooter = false;
    this.isTemplateRef = false;
    this.isOuterTemplateRef = false;
    /** this  */
    this.nextFirstStep = 0;
    this.nextSecondStep = 1;
    // ----------------------------- //
    this.prevFirstStep = 0;
    this.prevSecondStep = 0;
    // ----------------------------------- //
    this.isNextButtonDisabled = false;
    this.isPrevButtonDisabled = false;
    // ------------------------------------------ //
    this.isCancelButton = false;
    this.isSkipButton = false;
    this.isSubmitButton = false;
    this.isCloseButton = false;
    this.isPrevButton = false;
    // -------------------------------------- //
    this.isNextButtonDisabled = false;
    this.isCancelButtonDisabled = false;
    // -------------------------------------- //
    this.isAfterViewChecked = false;
    this.isAfterViewCheckedOnRow = false;
    this.isAfterViewCheckedBodyTemplate = false;
    this.isOuterContentModal = false;
    this.isClickedOnceOnRow = false;
    this.isRowSelectedData = false;
  }

  /** Here this lifecycle which call next action method  */
  public ngOnInit(): void {
    if (!this.isOuterTemplateRef) {
      this.onNextAction();
    }
  }

  /** to-do */
  public ngOnChanges(): void {
    // console.log('here-content', this.rowSelectedData);
  }

  /** Invoke when close button trigger */
  public onCloseAction(item?: any): void {
    if (item === 'close') {
      this.activeModal.dismiss('Cross click');
    } else {
      this.activeModal.dismiss('Cross click');
      /** set data which is emit the parent */
      const emitData = { action: 'close', form: this.stepsForm };
      // this.sharedService.subject.next(emitData);
      this.subject.next(emitData);
      /** Reset form */
      this.stepsForm && this.stepsForm.reset();
    }
  }

  /** Invoke when skip button trigger */
  public onSkipAction(stepsData: any): void {
    /** set data which is emit the parent */
    const emitData = { action: 'skip', form: this.stepsForm, steps: stepsData };
    // this.sharedService.subject.next(emitData);
    this.subject.next(emitData);
    this.onNextAction();
  }
  /** Invoke when cancel button trigger */
  public onCancelAction(): void {
    /** set data which is emit the parent */
    const emitData = { action: 'cancel', form: this.stepsForm };
    // this.sharedService.subject.next(emitData);
    this.subject.next(emitData);
  }
  /** Invoke when submit button trigger */
  public onSubmitAction(): void {
    /** set data which is emit the parent */
    const emitData = { action: 'submit', agGrid: this.agGrid, form: this.stepsForm, activeModal: this.activeModal };
    // this.sharedService.subject.next(emitData);
    this.subject.next(emitData);
  }

  /** Invoke lifecycle it is get input refence element and when vlue change input change button proprty status  */
  public ngAfterViewInit(): void {
    const controlNameAttr = this.inputRefence;
    if (this.isOuterTemplateRef) {
      this.outerTemplateReference.createEmbeddedView(this.outerTemplateRef);
    }
  }

  /** Invoke when click on prev button */
  public onPrevAction(formName: any, type: string): void {
    const stepsData = this.stepsData;
    this.nextFirstStep = this.prevFirstStep;
    this.nextSecondStep = this.prevSecondStep;
    const currentDisplayData = stepsData.slice(this.prevFirstStep, this.prevSecondStep);
    this.spliceData = currentDisplayData;
    /** After fetch steps data set `previous` step count and buttton disabled property value  */
    this.prevFirstStep -= 1;
    this.prevSecondStep -= 1;
    this.isPrevButtonDisabled = (this.prevFirstStep <= 0 && this.prevSecondStep === 0) ? true : false;
    if (currentDisplayData[0].type === 'grid') {
      this.isNextButtonDisabled = true;
    } else {
      this.isNextButtonDisabled =
        (this.nextFirstStep < this.stepsData.length && this.nextSecondStep === this.stepsData.length) ? true : false;
    }
    /** set data which is emit the parent */
    const emitData = { action: 'prev', data: this.spliceData };
    // this.sharedService.subject.next(emitData);
    this.subject.next(emitData);
    /** Get steps status and set some config based on step level */
    // this.getStepsStatusAndSetValue(formName, type);
    this.isPrevButton = (this.nextFirstStep > 0) ? true : false;
    /** Here set steps config  */
    this.setStepsConfig(currentDisplayData, 'prev');
    this.isAfterViewChecked = true;
    this.isAfterViewCheckedOnRow = true;
    this.getRegisterRowData(currentDisplayData);
  }

  /** Invoke when click on next button*/
  public onNextAction(type?: string, formControlName?: string): void {

    this.setNextAndPrevButtonStepsValue();
    const stepsData = this.stepsData;
    console.log('this.stepsData', this.stepsData);
    const currentDisplayData = stepsData.slice(this.nextFirstStep, this.nextSecondStep);
    this.spliceData = currentDisplayData;
    /** After fetch steps data set `previous` step count and buttton disabled property value  */
    this.isNextButtonDisabled = (this.nextFirstStep < this.stepsData.length) ? true : false;
    /** set submit button to */
    this.isSubmitButtonDisabled = true;
    /** After fetch steps data set `previous` step count and buttton disabled property value  */
    this.isPrevButton = (this.nextFirstStep > 0) ? true : false;
    /** set data which is emit the parent */
    if (this.stepsForm) {
      const emitData = { action: 'next', form: this.stepsForm, stepData: currentDisplayData };
      // this.sharedService.subject.next(emitData);
      this.subject.next(emitData);
    }
    if (this.spliceData[0].hasOwnProperty('isAboveElementRef')
      && this.spliceData[0].hasOwnProperty('isBelowElementRef') && this.spliceData[0].hasOwnProperty('rowData')) {
      setTimeout(() => {
        this.setAboveAndBelowTemplateRef();
      });
    }
    this.setConfigOnNavigateAndOther(currentDisplayData);
    this.isAfterViewChecked = true;
  }

  /**
   * when invoke this method it is
   */
  public onRowClicked(item: any): void {
    if (this.stepsData.length !== 7) {
      this.selectedData.push(item.data);
      const emitData = { action: 'rowData', data: item.data, arrayItem: this.spliceData, activeModal: this.activeModal };
      this.isClickedOnceOnRow = true;
      // this.sharedService.subject.next(emitData);
      this.subject.next(emitData);
      this.selectedData = [];
      this.isAfterViewCheckedOnRow = true;
      this.isNextButtonDisabled = false;
      this.isSubmitButtonDisabled = false;
    }
  }

  /**
   * Invoke this method when change on drop down.
   */
  public onChangeDropDown(id: number): void {
    const emitData = { action: 'changeDropDown', data: id };
    // this.sharedService.subject.next(emitData);
    this.subject.next(emitData);
  }
  /**
   * Invoke this method anbd it ios set template refe above `form` or `grid`
   */
  private setAboveAndBelowTemplateRef(): void {
    /** Here if steps have template ref */
    if (this.spliceData[0].type === 'grid' && this.spliceData[0].rowData !== 'rowSelectedData'
      && this.aboveGridRef && this.spliceData[0].hasOwnProperty('templateRef')) {
      this.aboveGridRef.clear();
      this.aboveGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
      this.subject.next({ action: 'isRenderTemplate' });
    } else if (this.spliceData[0].type === 'grid' && this.spliceData[0].rowData !== 'rowSelectedData'
      && this.belowGridRef && this.spliceData[0].hasOwnProperty('templateRef')) {
      this.belowGridRef.clear();
      this.belowGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
      this.subject.next({ action: 'isRenderTemplate' });
    } else if (this.spliceData[0].type === 'radio' && this.spliceData[0].rowData === 'rowSelectedData'
      && this.aboveGridRef && this.spliceData[0].hasOwnProperty('templateRef')) {
      this.aboveGridRef.clear();
      this.aboveGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
      this.subject.next({ action: 'isRenderTemplate' });
    } else if (this.spliceData[0].type === 'radio' && this.spliceData[0].rowData === 'rowSelectedData'
      && this.belowGridRef && this.spliceData[0].hasOwnProperty('templateRef')) {
      this.belowGridRef.clear();
      this.belowGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
      this.subject.next({ action: 'isRenderTemplate' });
    } else if (this.spliceData[0].type === 'grid' && this.isTemplateRef
      && this.aboveGridRef && this.spliceData[0].hasOwnProperty('templateRef')) {
      this.aboveGridRef.clear();
      this.aboveGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
      this.isTemplateRef = false;
      this.subject.next({ action: 'isRenderTemplate' });
    }
  }

  /**
   * When invoke this method it is set class.
   */
  private setClassModalContent(currentDisplayData: any[]): void {
    if (currentDisplayData[0].type === 'grid') {
      this.renderer.addClass(this.modalContent.nativeElement, 'lg-modal');
      this.renderer.removeClass(this.modalContent.nativeElement, 'sm-modal');
    } else {
      this.renderer.addClass(this.modalContent.nativeElement, 'sm-modal');
      this.renderer.removeClass(this.modalContent.nativeElement, 'lg-modal');
    }
  }

  /** This methos set config when array splice data have type `navigate` */
  /** And based on there set `steps config` and `componentRef` */
  private setConfigOnNavigateAndOther(currentDisplayData: any[]): void {
    if (this.nextSecondStep === this.stepsData.length && currentDisplayData[0].type === 'navigate') {
      this.activeModal.close();
      const data = { type: 'emitData', form: this.stepsForm.value };
      // this.sharedService.subject.next(data);
      this.subject.next(data);
      this.router.navigate([currentDisplayData[0].routerLink]);
      this.setStepsConfig(currentDisplayData, 'next');
    } else {
      this.setStepsConfig(currentDisplayData, 'next');
    }
  }

  /** When invoke this method it is set prev and next steps value */
  /** And also set value tru false of button which is display or not */
  private setNextAndPrevButtonStepsValue(): void {
    if (this.isNextFirst) {
      this.prevFirstStep = (this.nextFirstStep += 1) - 1;
      this.prevSecondStep = (this.nextSecondStep += 1) - 1;
      this.isPrevButtonDisabled = false;
    } else {
      this.isPrevButtonDisabled = true;
      this.isNextFirst = true;
    }
  }

  /**
   * @method setStepsConfig
   * This method get step splice data and set single select multi select config.
   * And then set form control name and then set button config.
   * @param currentDisplayData This parameter contain steps data which is pass by the users `@Input()` , `[stepsData]=""`
   */
  private setStepsConfig(currentDisplayData: any[], stepType?: any): void {
    // this.setStepsStatus(formName, type, formControlName);
    this.getStepsSpliceData(this.spliceData, stepType);
    this.setFormControlName(currentDisplayData);
    /** Set button config which is display or not */
    /** when click next or prev here first set array button config  */
    this.setButtonOrModalHeaderAndFooterConfig(currentDisplayData);
    /** Here create body template which is pass by the user */
    setTimeout(() => {
      this.createTemplateRefOfModalBody();
    });
  }

  /**
   * @method setButtonOrModalHeaderAndFooterConfig
   * When invoke this methis it set modal `header` and `footer` config.
   * @param currentDisplayData This parameter contain steps data which pass by users
   */
  private setButtonOrModalHeaderAndFooterConfig(currentDisplayData: any[]): void {
    if (currentDisplayData[0].hasOwnProperty('header')) {
      this.isHeader = currentDisplayData[0].header ? true : false;
    }
    if (currentDisplayData[0].hasOwnProperty('footer')) {
      this.isFooter = currentDisplayData[0].footer ? true : false;
    }
    if (currentDisplayData[0].hasOwnProperty('button')) {
      this.setButtonConfig(currentDisplayData);
    } else {
      this.isFooter = false;
    }
    if (!this.isPrevButton) {
      this.isPrevButton = false;
    } else {
      this.isPrevButton = (this.nextFirstStep > 0) ? true : false;
    }
  }

  /** This method is use to */
  private setButtonConfig(spliceData: any[]): void {

    Object.keys(spliceData[0].button).forEach((item: string) => {
      switch (item) {
        case ('skip'):
          this.isSkipButton = spliceData[0].button[item];
          break;
        case ('cancel'):
          this.isCancelButton = spliceData[0].button[item];
          break;
        case ('submit'):
          this.isSubmitButton = spliceData[0].button[item];
          break;
        case ('close'):
          this.isCloseButton = spliceData[0].button[item];
          break;
        case ('prev'):
          this.isPrevButton = spliceData[0].button[item];
          break;
        case ('next'):
          this.isNextButton = spliceData[0].button[item];
          break;
      }
      if (this.isFooter) {
        return;
      }
      this.isFooter = spliceData[0].button[item] === true ? true : false;
    });
  }

  /**
   * When invoke this method it is set form control.
   * Set form control name
   */
  private setFormControlName(currentDisplayData: any[]): void {
    if (currentDisplayData[0].hasOwnProperty('formControlName')) {
      if (currentDisplayData[0].formControlName) {
        this.stepsForm.get(currentDisplayData[0].formControlName).valueChanges.subscribe((item) => {
          if (this.prevChangeValue === item) { return; }
          this.isNextButtonDisabled = (this.nextSecondStep === this.stepsData.length) ? true : false;
          const emitData = { action: 'selectQuestions', value: item };
          // this.sharedService.subject.next(emitData);
          this.subject.next(emitData);
          this.prevChangeValue = item;
        });
        if (this.stepsForm.controls[currentDisplayData[0].formControlName].value) {
          this.isNextButtonDisabled = (this.nextSecondStep === this.stepsData.length) ? true : false;
        }
      }
    }
  }

  /** This method is get steps information */
  private getStepsSpliceData(spliceData: any[], stepType: string): void {
    /** Set single select and multi select of grid configuration  */
    this.setSingleAndMultiSelectionConfigGrid(spliceData);
    this.setRowDataAndGridOption(spliceData, stepType);
  }

  /**
   * When invoke this method it is set single select and multi select grid selection
   * @param spliceData This parameter contain steps array data which is pass by user.
   */
  private setSingleAndMultiSelectionConfigGrid(spliceData: any[]): void {
    if (spliceData[0].type === 'grid' && this.stepsForm && this.stepsForm.value.hasOwnProperty('gridRowSelection')) {
      if (this.stepsForm.value['gridRowSelection'] === 'SINGLE_SELECT') {
        this.rowSelection = 'single';
      } else if (this.stepsForm.value['gridRowSelection'] === 'MULTI_SELECT') {
        this.rowSelection = 'multiple';
      }
    }
    if (spliceData[0].hasOwnProperty('rowSelection')) {
      if (spliceData[0].rowSelection === 'single') {
        this.rowSelection = 'single';
      } else if (spliceData[0].rowSelection === 'multiple') {
        this.rowSelection = 'multiple';
      }
    }
  }

  /**
   * When invoke this method it is set grid config.
   * Like `rowData` and `gridOptions`.
   */
  private setRowDataAndGridOption(spliceData: any[], stepType: string): void {
    if (spliceData[0].type === 'grid') {
      this.columnDefs = spliceData[0].columnDefs;
      if (spliceData[0].rowData === 'rowSelectedData') {
        this.rowData = this.rowSelectedData;
        if (stepType === 'next') {
          this.mapRowData.set(spliceData[0].title, this.rowData);
        }
        this.isRowSelectedData = true;
      } else {
        this.rowData = spliceData[0].rowData;
        this.isRowSelectedData = true;
      }
      this.gridOptions = spliceData[0].gridOptions;
    } else {
      if (this.rowSelectedData) {
        this.mapRowData.set(spliceData[0].title, this.rowSelectedData);
      }
      this.isRowSelectedData = true;
    }
  }

  /**
   * When invoke this method it is get register row data.
   */
  private getRegisterRowData(spliceData: any[]): void {
    if (spliceData[0].type === 'grid') {
      this.columnDefs = spliceData[0].columnDefs;
      if (spliceData[0].rowData === 'rowSelectedData') {
        this.rowData = this.mapRowData.get(spliceData[0].title);
        this.isRowSelectedData = true;
      } else {
        this.rowData = spliceData[0].rowData;
      }
      this.gridOptions = spliceData[0].gridOptions;
    } else {
      this.isRowSelectedData = true;
    }
  }

  /**
   * This lyfecylce
   */
  public ngAfterViewChecked(): void {
    /** This method add and remove class of modal content */
    if (this.isAfterViewChecked) {
      this.setClassModalContent(this.spliceData);
      this.isAfterViewChecked = false;
    }
    /** Here create template above or below body Template or grid */
    if (this.isAfterViewCheckedOnRow && this.isRowSelectedData) {
      this.createTemplateContentProjection();
    }
  }

  /**
   * when invoke this method it is create template by create embeded view.
   * Here create template when it is not `grid` or `input form`.
   */
  private createTemplateRefOfModalBody(): void {
    if (this.spliceData[0].hasOwnProperty('bodyTemplateRef')) {
      if (this.spliceData[0].type === 'modalBodyTemplate' && this.spliceData[0].bodyTemplateRef && this.componentRef) {
        this.componentRef.createEmbeddedView(this.spliceData[0].bodyTemplateRef);
        this.isAfterViewCheckedBodyTemplate = false;
      }
    }
  }

  /**
   * when invoke this method it is create template by create embeded view.
   * This is create either `above` or `grid`.
   */
  private createTemplateContentProjection(): void {
    if (this.spliceData[0].hasOwnProperty('isAboveElementRef')
      && this.spliceData[0].hasOwnProperty('isBelowElementRef') && this.spliceData[0].hasOwnProperty('rowData')) {
      this.contentprojectionIfGrid();
      this.contentprojectionIfForm();
    }
  }

  /**
   * @method contentprojectionIfGrid
   * Invoke this method and set outside template dynamic.
   * This method set config if it is ag-grid.
   */
  private contentprojectionIfGrid(): void {
    if (!this.spliceData[0].hasOwnProperty('templateRef')) { return; }
    if (this.spliceData[0].type === 'grid' && (this.spliceData[0].rowData === 'rowSelectedData' || this.spliceData[0].templateRef)
      && this.spliceData[0].isAboveElementRef && this.aboveGridRef) {
      this.aboveGridRef.clear();
      this.aboveGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
    } else if (this.spliceData[0].type === 'grid'
      && (this.spliceData[0].rowData === 'rowSelectedData' || this.spliceData[0].templateRef)
      && this.spliceData[0].isBelowElementRef && this.belowGridRef) {
      this.belowGridRef.clear();
      this.belowGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
    }
  }

  /**
   * @method contentprojectionIfForm
   * Invoke this method and set outside template dynamic.
   * This method set config if it is form.
   */
  private contentprojectionIfForm(): void {
    if (!this.spliceData[0].hasOwnProperty('templateRef')) { return; }
    if ((this.spliceData[0].type === 'radio' || this.spliceData[0].type === 'dropDown')
      && (this.spliceData[0].rowData === 'rowSelectedData' || this.spliceData[0].templateRef)
      && this.spliceData[0].isAboveElementRef && this.aboveGridRef) {
      this.aboveGridRef.clear();
      this.aboveGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
    } else if ((this.spliceData[0].type === 'radio' || this.spliceData[0].type === 'dropDown')
      && (this.spliceData[0].rowData === 'rowSelectedData' || this.spliceData[0].templateRef)
      && this.spliceData[0].isBelowElementRef && this.belowGridRef) {
      this.belowGridRef.clear();
      this.belowGridRef.createEmbeddedView(this.spliceData[0].templateRef);
      this.isAfterViewCheckedOnRow = false;
      this.isRowSelectedData = false;
    }
  }

  onSelectionChanged(event) {
    this.selectedData = [];
    const emitData = { action: 'rowData', data: event.api.getSelectedRows(), arrayItem: this.spliceData, activeModal: this.activeModal };
    this.subject.next(emitData);

    if (event.api.getSelectedRows().length > 0) {
      if (this.selectionLabourCount === 0) {
        this.isSubmitButtonDisabled = false;
      } else {
        if (event.api.getSelectedRows().length >= this.selectionLabourCount) {
          this.isSubmitButtonDisabled = true;
        } else {
          this.isSubmitButtonDisabled = false;
        }
        this.isSubmitButtonDisabled = false;
      }
    } else {
      this.isSubmitButtonDisabled = true;
      // this.isSubmitButtonDisabled = event.api.getSelectedRows().length > 0
      // && (this.selectionLabourCount == 0 || event.api.getSelectedRows().length <= this.selectionLabourCount) ? false : true;
      this.isAfterViewCheckedOnRow = true;
      this.isNextButtonDisabled = false;
    }
  }
}
