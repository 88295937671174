import { Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[appPassword]'
})
export class AppPasswordDirective {
  private shown = false;
  constructor(private el: ElementRef) {
    this.setup();
  }
  toggle(span: HTMLElement) {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.setAttribute('style', 'position: relative; float: right; bottom: 20px; color: rgb(0, 0, 0)');
    } else {
      span.setAttribute('style', 'position: relative; float: right; bottom: 20px; color: rgb(128, 128, 128)');
      this.el.nativeElement.setAttribute('type', 'password');
    }
  }
  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.setAttribute('class', 'icon-App-eye');
    span.setAttribute('style', 'position: relative; float: right; bottom: 20px; color: rgb(128, 128, 128)');
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
