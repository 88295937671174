import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, NgModel, FormBuilder } from '@angular/forms';
import { LabourWeekoffChangesService } from 'src/app/attendance/labour-weekoff-changes/labour-weekoff-changes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BulkWeekoffChangeComponent } from 'src/app/attendance/labour-weekoff-changes/bulk-weekoff-change/bulk-weekoff-change.component';
import { GridApi, GridOptions } from 'ag-grid-community';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
// tslint:disable-next-line:max-line-length
import { AgNumberCountComponent } from 'src/app/core/components/grid-wrapper/helper/ag-number-count/ag-number-count.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellDeleteRecordRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-delete-record-renderer/ag-action-cell-delete-record-renderer.component';
// tslint:disable-next-line:max-line-length
import { ReportViewListExceptionalAttendanceComponent } from '../exceptional-attendance/report-view-list-exceptional-attendance/report-view-list-exceptional-attendance.component';
// tslint:disable-next-line:max-line-length
@Component({
  selector: 'app-exceptional-attendance',
  templateUrl: './exceptional-attendance.component.html',
  styleUrls: ['./exceptional-attendance.component.css']

})
export class ExceptionalAttendanceComponent implements OnInit {

  public weekOffDetailsForm: FormGroup;
  public steps: any[];
  selectedCount = 0;
  private defaultColDef;
  private defaultColDef1;
  private editType;
  selectedRows: any[] = [];
  rowDataAll = [];
  rowClassRules;
  requiredFlag = false;
  private isRowSelectable;
  getRowNodeId;
  gridColumnApi;
  gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs;
  rowData = [];
  hr;
  contractor;
  hrlogin = false;
  contractorlogin = false;
  roleCode;
  rowSelection;
  groupingMeta = ['d'];
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }
  onGridReady(params) {

    this.gridApi = params.api;
  }
  constructor(private labourWeekoffService: LabourWeekoffChangesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private logHelperService: LogHelperService
  ) {
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return rowNode.data ? rowNode.data.Status === 'Submitted' : false;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        // this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: true,
      headerHeight: 40,
      // isFullWidthCell: (rowNode) => {
      //     return rowNode.data.fullWidth;
      // },
    };
    // this.getRowNodeId = function (data) {
    //   return data;
    // };
  }
  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
    this.columnDefs = [
      {
        headerName: '#',
        width: 30,
        pinned: 'left',
        suppressMenu: true,
        cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: 'Name', field: 'FullName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'ULC', field: 'ULC',
        width: 100, headerComponentParams: { enableSorting: false }, suppressMenu: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Code', field: 'GatepassNo', width: 70, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Contractor', field: 'Company',
        width: 100, headerComponentParams: { enableSorting: false }, suppressMenu: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Plant', field: 'Plant', width: 70,
        headerComponentParams: { enableSorting: false }, suppressMenu: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Shift', field: 'ShiftGroup', width: 70,
        headerComponentParams: { enableSorting: false }, suppressMenu: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'ExceptionalDate', field: 'ExceptionalDate', width: 70,
        headerComponentParams: { enableSorting: false }, suppressMenu: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      }

      // {
      //   headerName: 'Action', field: '', width: 30,
      //   sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellDeleteRecordRendererComponent
      // }
    ];

  }


  onRowSelected(event) {
    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    }
  }


  ngOnInit() {
    this.rowSelection = 'multiple';
    // this.getRoleCodeByUserID();
    // this.getLabourWeekOffs();
    this.getExceptionalAttendance();
  }
  getRoleCodeByUserID() {

    this.labourWeekoffService.getRoleByUserID().subscribe((res: any) => {

      if (res.Status) {
        this.roleCode = res.Data;
        this.hr = this.roleCode.filter(list => list.Role_Code === 118);
        this.contractor = this.roleCode.filter(list => list.Role_Code === 119);
        if (this.hr.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = true;
          this.contractorlogin = false;
        }
        // tslint:disable-next-line: brace-style
        else if (this.contractor.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = true;
          this.contractorlogin = false;
        }

        // tslint:disable-next-line: brace-style
        else
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = true;
          this.contractorlogin = false;
        }
        // tslint:disable-next-line: brace-style
      }
    });
  }
  public openModalAction(): void {
    const modalRef = this.modalService.open(ReportViewListExceptionalAttendanceComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' }
    );
    modalRef.componentInstance.save1.subscribe(() => {
      modalRef.close('');
      this.getExceptionalAttendance();
    });
  }

  getLabourWeekOffs() {

    this.labourWeekoffService.getAllLabourWeekOffs().subscribe((res: any) => {
      console.log(res.Data.Table);
      // this.rowData = res.Data.Table;
      if (res.Data.Table.length > 0) {
        // this.rowData = res.Data.Table;
        this.rowDataAll = res.Data.Table;
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res.Data.Table);
        this.rowData = tempRowData;
      } else {
        this.rowData = [];
      }
    });
  }

  getExceptionalAttendance() {

    this.labourWeekoffService.getAllExceptionalAttendanceData().subscribe((res: any) => {
      console.log(res.Data.Table);
      // this.rowData = res.Data.Table;
      if (res.Data.Table.length > 0) {
        // this.rowData = res.Data.Table;
        this.rowDataAll = res.Data.Table;
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res.Data.Table);
        this.rowData = tempRowData;
      } else {
        this.rowData = [];
      }
    });
  }

  onApprove() {

    this.selectedRows = this.gridApi.getSelectedRows();
    if (this.selectedRows.length > 0) {
      // tslint:disable-next-line: ter-indent
      this.labourWeekoffService.approveLabourweekoffchangerequest(this.selectedRows).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Labour Week Off change Request Approved successfully'
        });
        this.getLabourWeekOffs();
      });
    } else {
      this.logHelperService.logError({
        message: 'Please Select Alteast One Record'
      });
    }
  }

  deleterecord(item) {

    const data = {
      WOChange_Code: item.WOChange_Code,
    };
    this.labourWeekoffService.deleterecord(data).subscribe((dt: any[]) => {
      console.log('after approve:', dt);
      this.logHelperService.logSuccess({
        message: 'Data  Succesfully Deleted.'
      });
      this.getLabourWeekOffs();
    });
  }

}
