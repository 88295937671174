import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-week-off-checkbox-render',
  templateUrl: './week-off-checkbox-render.component.html',
})
export class WeekOffCheckboxRenderComponent implements ICellRendererAngularComp {

  public params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }

  public onChange(event) {
    if (this.params.data) {
      this.params.data[this.params.colDef.field] = event.currentTarget.checked;
      this.params.context.componentParent.onCheckboxClick(event, this.params.data);
    } else {
      // header checkbox
      this.params.context.componentParent.onCheckboxClick(event, null);
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}
