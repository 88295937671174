import { Component, OnInit } from '@angular/core';
import { TableService } from '../service/table.service';

@Component({
  selector: 'app-table3',
  templateUrl: './table3.component.html',
  styleUrls: ['./table3.component.css']
})
export class Table3Component implements OnInit {

  public tableData : any;
  public tableHeader : any;

  constructor(private tableService : TableService) { }

  ngOnInit() {
    this.tableService.getTableData(3).subscribe((res : any) => {
      console.log(res);
      this.tableData = res;
      this.tableHeader = this.tableData.Table1.map((header) => {
        if(header.Value == false){
          header.child = header.RPTGroup;
        }
        return header;
      })

      this.tableHeader.forEach(element => {
        if(element.Value == false) {
          element.children = [];
          this.tableHeader.find((child, index) => {
            if(child.RPTGroup == element.RPTGroup  && child.RPTGroupChildL1 != 0 && child.RPTGroupChildL2 == 0){
              
              if(!element.children[child.RPTGroupChildL1 - 1]){
                element.children[child.RPTGroupChildL1 - 1] = [child];
                if(child.Value == false){
                  child.children = [];
                  this.tableHeader.find(secondChild => {
                    
                    if(secondChild.RPTGroupChildL1 == child.RPTGroupChildL1 && secondChild.Value && secondChild.RPTGroupChildL2 != 0){
                      child.children.push(secondChild);
                    }
                  })
                }
              } else {
                // element.children[child.RPTGroupChildL1 - 1].push(child);
              } 
            }
          })
        }
      });
      console.log(this.tableHeader);
      console.log(this.tableData);
    });
  }

}
