import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LabourImportHrComponent } from './labour-import-hr/labour-import-hr.component';
import { ImportManualPunchComponent } from './import-manual-punch/import-manual-punch.component';
import { SubcontractorLabourImportHrComponent } from './subcont-labour-import-hr/subcont-labour-import-hr.component';
import { UploadBulkEnrollmentDeleteComponent } from './upload-bulk-enrollment-delete/upload-bulk-enrollment-delete.component';
import { UploadExcelComponent } from "./upload-excel/upload-excel.component";
import { PayrollHandUploadComponent } from './payroll-excel-upload/payroll-excel-upload.component';
import { LaboursService } from '../labour/labours/labours.service';

@Component({
  selector: 'app-import-excel',
  templateUrl: './import-excel.component.html',
  styleUrls: ['./import-excel.component.css']
})
export class ImportExcelComponent implements OnInit {
  isShowSingleBtn: boolean = false;
  isShowAllBtn: boolean = false;
  constructor(
    private modalService: NgbModal,
    private labourService: LaboursService
  ) { }

  ngOnInit() {
    this.labourService.globalSettingCode('').subscribe((res:any) => {
      if(localStorage.RoleCode == '119'){
        let isShowSinglebutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELCONSIN');
        this.isShowSingleBtn = isShowSinglebutton[0].Value == "1" ? true : false;
        console.log(this.isShowSingleBtn)

        let isShowAllbutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELCONAll');
        this.isShowAllBtn = isShowAllbutton[0].Value == "1" ? true : false;
      }else{
        let isShowSinglebutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELHRSIN');
        this.isShowSingleBtn = isShowSinglebutton[0].Value == "1" ? true : false;

        let isShowAllbutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELHRAll');
        this.isShowAllBtn = isShowAllbutton[0].Value == "1" ? true : false;
      }

    },(err) => {
      console.log("global setting err --->",err)
    });
  }
  importLabourForHR(): void {
    const modalRef = this.modalService.open(LabourImportHrComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
      modalRef.componentInstance.isShowSingleButton = this.isShowSingleBtn;
      modalRef.componentInstance.isShowAllButton = this.isShowAllBtn;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  importSubcontactorLabourForHR(): void {
    const modalRef = this.modalService.open(SubcontractorLabourImportHrComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
      modalRef.componentInstance.isShowSingleButton = this.isShowSingleBtn;
      modalRef.componentInstance.isShowAllButton = this.isShowAllBtn;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }

  public importUploadBulkEnrollmentDelete(): void {
    const modalRef = this.modalService.open(UploadBulkEnrollmentDeleteComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  importExcelData(){
    const modalRef = this.modalService.open(PayrollHandUploadComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
}
