import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from '../app.globals';
import { map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class VisitorManagementService {

  public getAllVisitor(): Observable<any[]> {
    const queryString = $.param({
    });
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_VISITOR_DATA}?${queryString}`;
    return this.httpClient.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  // Get Contractor code
  getDocumentTypes() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES);
  }

  public getCompanyMasterData(): Observable<any> {
    const model = {
      Search: '',
      StatusOfIndustry: '',
      EntityTypeCode: '',
      LegalstatusCode: '',
      StateCode: '',
      CityCode: '',
      CountryCode: '',
    };
    //return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA}`, model);

      const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
      &State=&City=&Country=`;
      return this.httpClient.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
    
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA
  // + '?search=&StatusOfIndustry=&EntityTypeCode=&LegalStatusCode=&StateCode=&CityCode=&Co');
  // }
  public getPlantMasterData(): Observable<any> {

    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA}`, model);
  }


  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA
  //   + '?search=&CompanyCode=&StateCode=&CityCode=&CountryCode=&UnitType=&Ownership=&LocatedAt=&ISESICApplicable=');
  // }
  getUnitMasterData(plant) {
    const queryString = $.param({
      Search: '',
      PlantCode: JSON.stringify(plant)
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?${queryString}`,
      httpOptions
    );
  }

  getDepartmentData(unit) {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}`, model);
  }
  //  const queryString = $.param({
  //    UnitCode: JSON.stringify(unit)
  // });
  //  return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //    `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_BY_UNIT}?${queryString}`,
  //   httpOptions
  //  );
  // }
  getEmployeeDetail(): Observable<any[]> {
    const model = {
    };
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_EMPLOYEE_DEATIL}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  getCountry() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}`, model);
  }
  //  return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //    `${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_DATA}?Search=`,
  //    httpOptions
  //  );
  // }
  getStatesByCountryId(country) {
    const model = {
      Search: '',
      Country_Code: country,
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`, model);
  }
  //  const queryString = $.param({
  //  Search: '',
  // CountryCode: JSON.stringify(country)
  //  });
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}?${queryString}`,
  // httpOptions
  // );
  // }
  getDistrictsByState(state) {
    const model = {
      Search: '',
      State_Code: state,
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}`, model);
  }
  // const queryString = $.param({
  //   Search: '',
  //   StateCode: JSON.stringify(state)
  // });
  // return this.httpClient
  //   .request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}?${queryString}`,
  //     httpOptions
  //   );
  // }

  getVillageByPostOffice(village) {
    const queryString = $.param({
      Search: '',
      PostOfficeCode: JSON.stringify(village)
    });
   // return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`, model);
  
    return this.httpClient.request(GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_VILLAGE_BY_POSTOFFICE}?${queryString}`,
      httpOptions);
  }
  //   const queryString = $.param({
  //     Search: '',
  //     PostOfficeCode: JSON.stringify(village)
  //   });
  //   return this.httpClient.request(GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_VILLAGE_BY_POSTOFFICE}?${queryString}`,
  //     httpOptions);
  // }
  getPOsByCity(city) {
    // const queryString = $.param({
    //   Search: '',
    //   CityCode: JSON.stringify(city)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}?${queryString}`,
    //   httpOptions
    // );
    const model = {
      Search: '',
      City_Code: city,
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`, model);
  }


  getCitiesByDistrict(district) {
    const model = {
      Search: '',
      District_Code: district,
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}`, model);
  }
  //   const queryString = $.param({
  //     Search: '',
  //     DistrictCode: JSON.stringify(district)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}?${queryString}`,
  //     httpOptions
  //   );
  // }
  constructor(private httpClient: HttpClient) {
  }

  // tslint:disable-next-line: function-name
  SendRequest(data): Observable<Object> {
    const body = JSON.stringify(data);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.VISITOR_MANAGEMENT.SEND_REQUEST}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  cheout(data): Observable<any[]> {
    const model = {
      Visitor_Code: data
    };
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.INSERT_OUTTIME_DASHBOARD}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  ApproveRejectRequest(data): Observable<Object> {
    const body = JSON.stringify(data);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.VISITOR_MANAGEMENT.APPROVEDREJECTREQUEST}`,
      httpOptions
    );
  }
  // tslint:disable-next-line: function-name
  VisitorTimeLineDashboard(): Observable<any[]> {
    const model = {
    };
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_VISITORTIMELINE_DASHBOARD}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  gettotalcount(): Observable<any[]> {
    const model = {
    };
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_VISITORTOTALCOUNT_DASHBOARD}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  VisitorPreAuthorizedDashboard(): Observable<any[]> {
    const model = {
    };
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_VISITORPREAUTHORIZED_DASHBOARD}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }
  // tslint:disable-next-line: function-name
  VisitorActivityDashboard(data: any): Observable<any[]> {
    const model = {
      visitorCode: data
    };
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_VISITORACTIVITY_DASHBOARD}`;
    return this.httpClient.post(url, model).
      pipe(
        map((response: any) => response));
  }

  getDocumentForLabourVerify(visitorCode) {
    const queryString = $.param({
      visitorCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_DOC_DATA}?${queryString}`,
      httpOptions
    );
  }
  public getDataSet(): Observable<any[]> {
    const url = `${GLOBAL.APIS.VISITOR_MANAGEMENT.GET_ALL_VISITOR_DATASET}`;
    return this.httpClient.post(url, {}).
      pipe(
        map((response: any) => response));
  }

  public getIndiaJson() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ASSETS.GET_INDIA_JSON}`
    );
  }
}
