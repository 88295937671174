import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-reports-grid-wrapper',
  templateUrl: './reports-grid-wrapper.component.html',
  styleUrls: ['./reports-grid-wrapper.component.css']
})
export class ReportsGridWrapperComponent implements OnInit {

  rowClassRules;
  // @Input() gridOptions;
  @Input() columnDefs = [];
  @Input() rowData = [];
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  numberOfRowsPerPage = 10;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  gridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: node => node.data.isVisible,
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    headerHeight: 50,
  };

  constructor() { }

  ngOnInit() {
    console.log('ngOnInit ====== 4');
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };
  }
}
