import { Injectable } from '@angular/core';
import { FileDoc } from './file-upload.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/app.globals';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  uploaded = 0;
  progress = 0;
  files: FileDoc[] = [];
  photo: File;
  constructor(
    private httpClient: HttpClient
  ) { }

  getUploadedItems() {
    return this.files.filter((item: FileDoc) => item.isUploaded);
  }

  getNotUploadedItems() {
    return this.files.filter((item: FileDoc) => !item.isUploaded);
  }

  getTotalProgress() {
    const notUploaded = this.getNotUploadedItems().length;
    const uploaded = notUploaded ? this.files.length - notUploaded : this.files.length;
    const ratio = 100 / this.files.length;
    // const current = value * ratio / 100;
    this.progress = Math.round(uploaded * ratio);
  }

  uploadFiles(files: FileDoc[], params, url) {
    this.files = files;

    const fd = new FormData();
    const imfType = [] ;
    const filesToUpload: any = this.getNotUploadedItems();
    filesToUpload.forEach((fileDoc, index) => {
      fd.append(String(index), fileDoc['file'], fileDoc['file']['name']);
      //fd.append('Image_Type', `${String(fileDoc.documentTypeCode)},`);
      imfType.push(fileDoc.documentTypeCode);
    });
    fd.append('Image_Type', imfType.toString());
    fd.append('Ischeck', `undefined,`);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.httpClient.request(GLOBAL.HTTP_POST, url, requestOptions);

  }

  uploadPhoto(files: any, params, url) {
    this.photo = files;

    const fd = new FormData();
    const filesToUpload: any = this.photo;
    fd.append(String(), filesToUpload, filesToUpload['name']);
    // const filesToUpload: any = this.getNotUploadedItems();
    // filesToUpload.forEach((fileDoc, index) => {
    //   fd.append(String(index), fileDoc['file'], fileDoc['file']['name']);
    // });
    fd.append('Ischeck', `undefined,`);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.httpClient.request(GLOBAL.HTTP_POST, url, requestOptions);

  }

}
