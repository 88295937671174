import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-work-order-cell-renderer',
  templateUrl: './work-order-cell-renderer.component.html',
  styleUrls: ['./work-order-cell-renderer.component.css']
})
export class WorkOrderCellRendererComponent implements ICellRendererAngularComp {
  statusText = {
    Pending: { text: 'Pending', bgColor: '#EF9460', color: '#FFF' },
    Generated: { text: 'Generated', bgColor: '#F2F1F1' },
    Submitted: { text: 'Submitted', bgColor: '#31EAE8' },
    InReview: { text: 'In Review', bgColor: '#FFC300' },
    Verified: { text: 'Verified', bgColor: '#a8be37', color: '#FFF' },
    Approved: { text: 'Approved', bgColor: '#228B22', color: '#FFF' },
    Overdue: { text: 'Overdue', bgColor: '#FF5733', color: '#FFF' },
    Rejected: { text: 'Rejected', bgColor: '#FF3030', color: '#FFF' }
  };
  statusArray = Object.keys(this.statusText);

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getStatusPercentage(task, totalTasks) {
    return `${((task * 100) / totalTasks).toFixed(2)}%`;
  }

}
