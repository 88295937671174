import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { UserManagementService } from '../user-management.service';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import { AccessCheckboxCellComponent } from '../access-checkbox-cell/checkbox-cell.component';


@Component({
  selector: 'app-pagewise-action',
  templateUrl: './pagewise-action.component.html',
  styleUrls: ['./pagewise-action.component.css']
})
export class PagewiseActionComponent implements OnInit {

  gridAPI: GridApi;
  gridOptions: GridOptions;
  dataList = [];
  columnDefs = [];
  rowData: any;
  orignalData = [];

  constructor(
    private userManagementService: UserManagementService, private loadingService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.returndata();
  }

  returndata() {
    const columnDefs = [];
    const rowData = [];

    this.userManagementService.getPageActionList().subscribe((res: any) => {
      if (res.Status) {
        const actionData = res.Data.Table1;
        const pageData = res.Data.Table;
        this.orignalData = res.Data.Table1;
        if (actionData.length > 0) {
          actionData.filter((data) => {
            columnDefs.push({
              headerName: data.actionName, field: `${data.moduleId}_${data.actionId}`,
              cellRendererFramework: AccessCheckboxCellComponent, headerClass: 'text-center',
              width: 100, colId: data.actionId
            });
          });
        }

        if (pageData.length > 0) {
          // pageData.filter((pages) => {
          //   // tslint:disable-next-line:prefer-const
          //   let selectedActionIds = pages.selectedActionIds ? pages.selectedActionIds.split(',') : [];
          //   firstRow[pages.pageName] = selectedActionIds.length;
          // });
          let tmpModuleName = '';
          pageData.filter((data) => {

            let isNewName = false;
            if (tmpModuleName !== data.moduleName) {
              isNewName = true;
              tmpModuleName = data.moduleName;
              // const obj = {
              //   'Module List': isNewName ? data.moduleName : '',
              //   'Page List': '',
              // };

              // actionData.filter((action) => {
              //   obj[`${action.moduleId}_${action.actionName}`] = {
              //     actionId: action.actionId,
              //     moduleId: data.moduleId,
              //     value: false
              //   };
              // });
              // rowData.push(obj);
            }

            const obj = {
              'Module List': data.moduleName,
              'Page List': data.pageName,
            };

            // tslint:disable-next-line:prefer-const
            const selectedActionIds = data.selectedActionIds ? data.selectedActionIds.split(',').map(Number) : [];
            actionData.filter((action) => {
              if (selectedActionIds.indexOf(action.actionId) < 0) {
                obj[`${action.moduleId}_${action.actionId}`] = {
                  actionId: action.actionId,
                  pageId: data.pageId,
                  moduleId: data.moduleId,
                  value: false,
                  disabled: true,
                };
              } else {
                // tslint:disable-next-line:prefer-const
                let selectedPageIds = action.selectedPageIds ? action.selectedPageIds.split(',').map(Number) : [];
                obj[`${action.moduleId}_${action.actionId}`] = {
                  actionId: action.actionId,
                  pageId: data.pageId,
                  moduleId: data.moduleId,
                  value: selectedPageIds.indexOf(data.pageId) >= 0 ? true : false
                };
              }
            });
            rowData.push(obj);
          });
        }
        setTimeout(() => {
          this.dataList = rowData;
          const dataCol = [
            {
              headerName: 'Module List', field: 'Module List',
              sortable: false, filter: false, width: 200, pinned: true,
              headerClass: 'text-center',
            },
            {
              headerName: 'Page List', field: 'Page List',
              sortable: false, filter: false, width: 200, pinned: true,
              headerClass: 'text-center',
            },
            // {
            //   headerName: '', field: 'selectAllActions',
            //   cellRendererFramework: CheckboxCellComponent, headerClass: 'text-center',
            //   width: 100, colId: 'selectAllActions'
            // },
            {
              headerName: 'Action List', field: 'Action List',
              headerClass: 'text-center',
              children: []
            }
          ];
          dataCol[2].children = columnDefs;
          this.columnDefs = dataCol;
          this.setGridOption();
        }, 500);
      }
    }, (error) => {
      this.loadingService.hide();
    });
  }

  setGridOption() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowData: this.dataList,
      rowHeight: 32,
      headerHeight: 34,
      defaultColDef: {
        resizable: true
      }, context: {
        componentParent: this
      }
    };
  }

  onSave() {
    const data = [];
    this.gridOptions.api.forEachNode((rowNode) => {
      if (rowNode.data['Action List'] !== 'Total Selected Action') {
        const selectedId = [];
        let selectedpage = '';
        Object.entries(rowNode.data).forEach(
          (valuePair: any) => {
            if (typeof (valuePair[1]) === 'object') {
              if (selectedpage === '') {
                selectedpage = valuePair[1].actionId;
              }
              if (valuePair[1].value) {
                selectedId.push(valuePair[1].pageId);
              }
            }
          }
        );
        const objActionWise = {
          actionId: selectedpage,
          selectedPageId: selectedId
        };
        data.push(objActionWise);
      }
    });

    setTimeout(() => {
      this.userManagementService.setPagewiseActionReqParameter(this.orignalData, this.columnDefs, data);
    }, 500);
  }

  countSelectedPages(cell) {
    this.gridOptions.api.forEachNode((rowNode) => {
      if (rowNode.data['Action List'] === 'Total Selected Action') {
        if (cell.checked) {
          rowNode.data[cell.Col] += 1;
        } else {
          rowNode.data[cell.Col] -= 1;
        }
      }
    });
    this.gridOptions.api.refreshCells();
  }

}
