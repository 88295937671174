import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../../core/components/select-steps/select-steps-model/select-steps.model';
import { CompanyCommonsMasterService } from '../../../company-commons-master/company-commons-master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { SelectStepsComponent, AgActionCellRendererComponent, AgNumberCountComponent } from '../../../core/components';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'app-halfday-policy',
  templateUrl: './halfday-policy.component.html',
  styleUrls: ['./halfday-policy.component.css']
})
export class HalfdayPolicyComponent implements OnInit {
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('agGrid') agGrid: any;
  rowData: any;
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public modalRef: any;
  public gridAPI: GridApi;
  public numberOfRowsPerPage = 10;
  private selectedFilterData: any = { JSONText: {} };
  /// public shiftMasterForm: FormGroup;
  halfdayPolicyForm = new FormGroup({
    // LateInPolicy_Code: new FormControl(''),
    // Sys_Code: new FormControl(''),
    Company_Code: new FormControl(''),
    Plant_Code: new FormControl(''),
    Name: new FormControl(''),
    ShortCode: new FormControl(''),
    // Version: new FormControl(''),
    // IsLabour: new FormControl(''),
    // ApplicableDate: new FormControl(''),
    // ValiduptoDate: new FormControl(''),
    IsDefault: new FormControl(''),
    // IsCompany: new FormControl(''),
    MinHalfDayDuration: new FormControl(''),
    MinTimeForAbsent: new FormControl(''),
    // WeekOffSufixPrefixAbsent: new FormControl(''),
    // HolidaySufixPrefixAbsent: new FormControl(''),
  });
  masterData = [];
  // public weekOfList: any[] = [
  //   { JoiningOrResigning: 0, name: 'SUN' },
  //   { JoiningOrResigning: 1, name: 'MON' },
  //   { JoiningOrResigning: 1, name: 'TUE' },
  //   { JoiningOrResigning: 1, name: 'WED' },
  //   { JoiningOrResigning: 1, name: 'THU' },
  //   { JoiningOrResigning: 1, name: 'FRI' },
  //   { JoiningOrResigning: 1, name: 'SAT' },
  // ];
  statementType: any;
  // public siteMasterData: any[] = [];
  // public unitetypeList: any[] = [];
  // public ownershiplist: any[] = [];
  // public locationlist: any[] = [];
  public companyData: any[] = [];
  public plantData: any[] = [];
  // editplantCode1: string;
  editHalfDayPolicyCode1: string;
  // editPlantCode: string;
  // editPlantCode: string;
  // public countries: any[] = [];
  // public states: any[] = [];
  // public districts: any[] = [];
  // public cities: any[] = [];
  // public pPos: any[] = [];
  show = false;
  editMode = false;
  // plantName: any;
  halfdaypolicycode: any;
  // Company_Code: any;
  syscode: any;
  // shortname: any;
  // Name: any;
  plantcode: any;
  version: any;
  save = false;
  update = false;



  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };

  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  constructor(private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private logHelperService: LogHelperService,
    private activatedRoute: ActivatedRoute,
    public sharedDataService: SharedDataService,
    private modalService: NgbModal, private service: CompanyCommonsMasterService) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

  }
  // tslint:disable-next-line: function-name
  IfCheck($event) {
    if ($event.target.checked === true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }
  ngOnInit() {
    // this.plantMaster();
    this.getHalfDayPolicy();
    this.getPlantData();
    this.getCompanyData();
    // this.getCountry();
    // this.sharedDataService.getMasterData().subscribe((masterList: any) => {
    //
    //   console.log('master data', masterList);
    //   this.masterData = masterList;

    //   const unit = this.masterData.filter(list => list.Parent_Code === 150);
    //   const owner = this.masterData.filter(list => list.Parent_Code === 162);
    //   const location = this.masterData.filter(list => list.Parent_Code === 165);
    //   const status = this.masterData.filter(list => list.Parent_Code === 949);
    //   this.siteMasterData = this.bindDataforMasterItem(status);
    //   this.unitetypeList = this.bindDataforMasterItem(unit);
    //   this.ownershiplist = this.bindDataforMasterItem(owner);
    //   this.locationlist = this.bindDataforMasterItem(location);
    //   if (masterList.length) {
    //     this.plantMasterForm.reset();
    //   }
    // });
  }

  bindDataforMasterItem(filteredData) {
    const convertedData = [];
    filteredData.forEach((element: any) => {
      const subData = [];
      const subDataFilter = this.masterData.filter(list => list.Parent_Code === element.MasterItem_Code);
      if (subDataFilter.length > 0) {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name, children: this.bindDataforMasterItem(subDataFilter) });
      } else {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name });
      }
    });
    return convertedData;
  }


  getCompanyData() {
    return this.service.getCompanyMasterData().subscribe((res: any) => {
      this.companyData = res.Data.Table;
      // if (this.companyData.length === 1) {
      //   // this.labourForm.control.value.Company;
      //   this.earlyGoingPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
      // } else {
      //   this.companyData = res.Data.Table;
      // }
    });
  }

  getPlantData() {
    return this.service.getPlantMasterData().subscribe((res: any) => {
      // this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
      this.plantData = res.Data.Table;
      // if (this.plantData.length === 1) {
      //   // this.labourForm.control.value.Company;
      //   this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
      // } else {
      //   this.plantData = res.Data.Table;
      // }
    });
  }

  // plantMaster() {
  //   return this.service.getPlant().subscribe((value: any) => {
  //     this.rowData = value;
  //   });
  // }

  getHalfDayPolicy() {
    const model = {
      UserID: localStorage.getItem('UserID'),
      // LateInPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MinHalfDayDuration: '',
      MinTimeForAbsent: '',
      WeekOffSufixPrefixAbsent: '',
      HolidaySufixPrefixAbsent: '',
      StatementType: 'Select'
    };

    this.service.getHalfDayPolicy(model).subscribe((response: any[]) => {

      // return this.service.getUnitwiseDetails().subscribe((response: any) => {
      this.rowData = response;

      console.log('xyz', (response));

    });

  }

  public openModal(): void {
    this.clearData();
    this.modalRef = this.modalService.open(this.tempRef,
      { size: 'lg', backdrop: 'static' });
    if (this.editHalfDayPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;
    }

  }
  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
    this.save = false;
    this.update = false;
  }
  private clearData() {
    this.halfdayPolicyForm.reset();
  }
  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
    this.save = false;
    this.update = false;
  }


  public saveAndNew3(): void {

    console.log('form data open modal', this.halfdayPolicyForm.getRawValue());
    const value = this.halfdayPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editHalfDayPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (value.Name != null && value.ShortCode != null) {
      const data = {
        HalfDayPolicy_Code: this.editHalfDayPolicyCode1,
        Name: value.Name,
        Sys_Code: value.Sys_Code,
        Company_Code: value.Company_Code,
        ShortCode: value.ShortCode,
        Plant_Code: value.Plant_Code,
        // Version: value.Version,
        // IsLabour: value.IsLabour,
        // ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
        // ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
        IsDefault: value.IsDefault,
        // IsCompany: value.IsCompany,
        MinHalfDayDuration: value.MinHalfDayDuration,
        MinTimeForAbsent: value.MinTimeForAbsent,
        // WeekOffSufixPrefixAbsent: value.WeekOffSufixPrefixAbsent,
        // HolidaySufixPrefixAbsent: value.HolidaySufixPrefixAbsent,
        StatementType: this.statementType,
        UserID: localStorage.getItem('UserID'),
      };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateHalfDayPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'HalfDayPolicy Updated successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getHalfDayPolicy();
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddHalfDayPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'HalfDayPolicy added successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getHalfDayPolicy();
          }
        });
      }
    }
  }

  public editHalfdayPolicyCode($event): void {
    this.openModal();
    this.editHalfDayPolicyCode1 = $event.HalfDayPolicy_Code;
    if (this.editHalfDayPolicyCode1) {

      this.openModal();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getHalfdayPolicyProfile(this.editHalfDayPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        this.halfdayPolicyForm.controls['HalfDayPolicy_Code'].setValue(shift[0].HalfDayPolicy_Code);
        this.halfdayPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.halfdayPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.halfdayPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.halfdayPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.halfdayPolicyForm.controls['Name'].setValue(shift[0].Name);
        // this.halfdayPolicyForm.controls['Version'].setValue(shift[0].Version);
        // this.halfdayPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        // this.halfdayPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        // this.halfdayPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.halfdayPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        // this.halfdayPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.halfdayPolicyForm.controls['MinHalfDayDuration'].setValue(shift[0].MinHalfDayDuration);
        this.halfdayPolicyForm.controls['MinTimeForAbsent'].setValue(shift[0].MinTimeForAbsent);
        // this.halfdayPolicyForm.controls['WeekOffSufixPrefixAbsent'].setValue(shift[0].WeekOffSufixPrefixAbsent);
        // this.halfdayPolicyForm.controls['HolidaySufixPrefixAbsent'].setValue(shift[0].HolidaySufixPrefixAbsent);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }


  // tslint:disable-next-line: function-name
  CheckShortCode() {
    // tslint:disable-next-line: ter-indent
    this.service.checkHalfdayPolicy(this.halfdayPolicyForm.controls.Plant_Code.value, this.editHalfDayPolicyCode1).subscribe((res: any) => {
      this.plantcode = res.Data;
      if (this.plantcode > 0)
      // tslint:disable-next-line: brace-style
      {
        this.logHelperService.logError({
          message: 'This field requires a unique entry. Please enter different ShortCode.'
        });
        this.halfdayPolicyForm.controls['Plant_Code'].reset();
        // this.halfdayPolicyForm.controls['Name'].reset();
      }
    });
    return true;
  }
}
