import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LaboursComponent } from './labours/labours.component';
import { GatePassRequestsComponent } from './gate-pass-requests/gate-pass-requests.component';
import { BulkCancellationComponent } from './bulk-cancellation/bulk-cancellation.component';
import { CreateSubLabourComponent } from './labours/create-sub-labour/create-sub-labour.component';
import { DepartmentTrainingComponent } from './department-training/department-training.component';
import { LabourCgmpTrainingComponent } from './labour-cgmp-training/labour-cgmp-training.component';
import { AssociateAccidentDetailsComponent } from './associate-accident-details/associate-accident-details.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {
    path: 'labours',

    component: LaboursComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gate-pass-requests',
    component: GatePassRequestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bulk-cancellation',
    component: BulkCancellationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-sub-labour',
    component: CreateSubLabourComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-sub-labour/:id',
    component: CreateSubLabourComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'department-training',
    component: DepartmentTrainingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'labour-cgmp-training',
    component: LabourCgmpTrainingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'associate-accident-details',
    component: AssociateAccidentDetailsComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LabourRoutingModule { }
