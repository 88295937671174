import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'labour-family-detail',
  templateUrl: 'labour-family-detail.component.html'
})

export class LabourFamilyDetailComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}