import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastUiImageEditorComponent } from 'ngx-tui-image-editor';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
  selector: 'app-labour-profile-image',
  templateUrl: './labour-profile-image.component.html',
  styleUrls: ['./labour-profile-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabourProfileImageComponent implements OnInit {

  @ViewChild(ToastUiImageEditorComponent) editorComponent: ToastUiImageEditorComponent;

  @Input() public set image(value: string) {
    this.imageData = value;
    this.tuiImageEditorOptions = {
      ...this.tuiImageEditorOptions,
      theme: this.myTheme,
      includeUI: {
        loadImage: {
          path: value,
          name: 'labour-profile'
        }
      }
    };
  }

  public get image(): string {
    return this.imageData;
  }

  @Input() public set isShowbutton(value: boolean) {
    if (value) {
      this._isShowbutton = value;
    }
  }

  public get isShowbutton(): boolean {
    return this._isShowbutton;
  }

  @Output() public croppedImage: EventEmitter<string>;
  @Output() public closeModal: EventEmitter<void>;

  public activeModal: NgbModalRef;
  public tuiImageEditorOptions: any = {
    usageStatistics: false
  };
  public trigger$: Observable<void>;

  // private trigger: Subject<void>;
  private myTheme = {
    'downloadButton.fontSize': '10px'
  };

  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  private imageData: string;
  private _isShowbutton: boolean;

  constructor() {
    this.croppedImage = new EventEmitter<string>();
    this.closeModal = new EventEmitter<void>();
    this.trigger = new Subject<void>();
    this.trigger$ = this.trigger.asObservable();
  }

  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public saveCroppedImage(): void {
    if(this.editorComponent.editorInstance.ui.initializeImgUrl){
      const image = this.editorComponent.editorInstance.toDataURL();
      this.croppedImage.emit(image);
    } else {
      this.croppedImage.emit('');
    }
  }

  public close(): void {
    this.closeModal.emit();
  }

  public captureImage(): void {
    // this.trigger.next();
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotFoundError') {
      console.warn('Requested device not found!');
      return;
    }
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      console.warn('Camera access was not allowed by user!');
    }
  }

  public handleImage(webcamImage: WebcamImage): void {
    // this.webcamImage = webcamImage;
    this.image = webcamImage.imageAsDataUrl;
    this.captureImage();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  // public handleInitError(error: WebcamInitError): void {
  //   this.errors.push(error);
  // }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  // public handleImage(webcamImage: WebcamImage): void {
  //   console.info('received webcam image', webcamImage);
  //   this.webcamImage = webcamImage;
  // }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
