import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-ag-actions',
  templateUrl: './ag-actions.component.html',
  styleUrls: ['./ag-actions.component.css']
})
export class AgActionsComponent implements OnInit, ICellRendererAngularComp {

  public params: any;
  constructor() {
  }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onEdit() {
    this.params.context.componentParent.onEdit(this.params.data);
  }

  onDelete() {
    this.params.context.componentParent.onDelete(this.params.data);
  }

}
