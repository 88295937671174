import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartUtilService {

  constructor() { }

  getLabelValue(d, labelValueFormatted) {
    if (labelValueFormatted) {
      let newd = d;
      let sign = '';
      let posD = d;
      if (d < 0) {
        sign = '-';
        posD = d * -1;
      }

      if ((posD / 10000000) >= 1) {
        newd = `${sign}${Math.round(posD / 10000000)}Cr`;
      } else if ((posD / 100000) >= 1) {
        newd = `${sign}${Math.round(posD / 100000)}L`;
      } else if ((posD / 1000) >= 1) {
        newd = `${sign}${Math.round(posD / 1000)}K`;
      }
      return newd;
    }

    return d;
  }

  tickValueFormat = (d) => {
    let newd = d;
    let sign = '';
    let posD = d;
    if (d < 0) {
      sign = '-';
      posD = d * -1;
    }
    if ((posD / 10000000) >= 1) {
      newd = `${sign}${posD / 10000000}Cr`;
    } else if ((posD / 100000) >= 1) {
      newd = `${sign}${posD / 100000}L`;
    } else if ((posD / 1000) >= 1) {
      newd = `${sign}${posD / 1000}K`;
    }
    return newd;
  }

  roundPercentage(value) {
    if (value) {
      return Math.round(value * 100) / 100;
    }

    return value;
  }
}
