import {
  Component, OnInit, ViewChild, ViewContainerRef, Input, ComponentFactoryResolver, ComponentRef, TemplateRef,
  AfterViewInit, ChangeDetectorRef, ElementRef, Renderer2
} from '@angular/core';
import { ReportFilterData, FilterTypeEnum } from './report-filter.model';
import { ContractorFilterComponent } from '../contractor-filter/contractor-filter.component';
import { SubContractorFilterComponent } from '../sub-contractor-filter/sub-contractor-filter.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ReportFilterModel, Contractor, WorkOrder } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { EntryfordayService } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.service';

@Component({
  selector: 'app-report-filter-test',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css'],

})
export class ReportFilterTestComponent implements OnInit, AfterViewInit {

  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  public savedFilters: ReportFilterModel[] = [];

  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;

  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;

  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;

  // private variables
  private selectedFilterData: ReportFilterModel;
  private filterType: FilterTypeEnum;

  // it will store the filter data
  private reportFilterData: ReportFilterModel;
  private modalRef: NgbModalRef;
  private contractors: Contractor[] = [];
  private subContractors: Contractor[] = [];
  private workOrders: WorkOrder[] = [];
  private subContractorWorkOrders: WorkOrder[] = [];

  private contractorCode: number;
  private workOrderNo: number;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private entryfordayService: EntryfordayService,
    private modalService: NgbModal,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
  }

  getContractors() {
    this.entryfordayService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
    });
  }
  ngAfterViewInit() {

  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onContractorChangeEmitter(contractorCode: number) {
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, 1);
    } else {
      this.reportFilterData.workOrderNo = null;
    }
    if (isNullOrUndefined(this.reportFilterData)) {
      this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
  }

  getWorkOrderListEmitter(value) {
    this.getWorkOrdersByContractorCode(value, 1);

  }

  public onWorkOrderChangeEmitter(value: any) {
    if (isNullOrUndefined(this.reportFilterData)) {
      this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.workOrderNo = value.workOrderNo;
    if (isNullOrUndefined(value.workOrderNo)) {
      this.reportFilterData.workOrderNo = null;
      this.reportFilterData.subContractorCode = null;
      this.reportFilterData.subContractorWorkOrderNo = null;

      this.subContractors = [];
    } else {
      // this.getSubContractors(value.contractorCode, value.workOrderNo);
    }
  }

  public onContractorSaveEmitter(data: ReportFilterModel) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubContractorChangeEmitter(contractorCode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.subContractorCode = contractorCode;
    if (isNullOrUndefined(contractorCode)) {
      this.reportFilterData.subContractorWorkOrderNo = null;
    } else {
      this.getWorkOrdersByContractorCode(contractorCode, 2);
    }
  }

  public getSubContractorListEmiiter(value) {
    this.getSubContractors(value.contractorCode, value.workOrderNo);
  }

  public onSubContractorSaveEmitter(data: ReportFilterModel) {
    console.log('saved data', data);
    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  getSubWorkOrderListEmitter(contractorCode: number, plantcode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
    }
  }

  public onSaveFilter(): void {
    // if (this.filterType === FilterTypeEnum.ContractorFilter) {
    //   console.log('save filter data', this.selectedFilterData);
    //   // this.contractorComponentRef.instance.isNext = true;
    //   this.isContractorSave = !this.isContractorSave;
    // } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
    //   console.log('save filter data', this.selectedFilterData);
    //   this.isSubContractorSave = !this.isSubContractorSave;
    //   // this.subContractorComponentRef.instance.isNext = true;
    // } else {
    //   console.log('invalid tab');
    // }
    this.reportFilterData.filterType = this.filterType;
    console.log('saved data', this.reportFilterData);
    this.openModal();
    // this.isContractorSave = false;
    // this.cdr.detectChanges();
  }

  public onSubmit(): void {

  }

  public onFilterTab(filterType: number, reportFilterData?: ReportFilterModel): void {
    if (reportFilterData) {
      this.reportFilterData = { ...reportFilterData };
    }
    setTimeout(() => {
      this.contractors = [...this.contractors];
    }, 100);
    if (this.filterType !== filterType) {
      this.filterType = filterType;
      if (filterType === FilterTypeEnum.ContractorFilter) {
        setTimeout(() => {
          this.showContractorTab = true;
        }, 10);
      } else if (filterType === FilterTypeEnum.SubContractorFilter) {
        setTimeout(() => {
          this.showContractorTab = false;
        }, 10);
      } else {
        console.error('invalid type', filterType);
      }
    }
    return;

    // if (this.contentRef && this.filterType !== filterType) {
    //   this.contentRef.clear();
    //   this.filterType = filterType;
    //   if (reportFilterData) {
    //     this.reportFilterData = reportFilterData;
    //     this.selectedFilterData = reportFilterData;
    //   } else {
    //     // this.reportFilterData = null;
    //     this.selectedFilterData = null;
    //   }
    //   if (filterType === FilterTypeEnum.ContractorFilter) {
    //     // this.reportFilterData = null;
    //     this.createContractorFilter(this.contentRef);
    //   } else if (filterType === FilterTypeEnum.SubContractorFilter) {
    //     this.createSubContractorFilter(this.contentRef);
    //   } else {
    //     console.error('invalid type');
    //   }
    // }
  }

  public onAddfilter(): void {
    if (this.filterNameFormGroup.valid) {
      const value = this.filterNameFormGroup.get('filterTypeName').value;
      if (this.selectedFilterData) {
        const index = this.savedFilters.indexOf(this.selectedFilterData);
        if (index >= 0) {
          this.selectedFilterData = { ...this.reportFilterData };
          this.selectedFilterData.filterName = value;
          this.savedFilters[index] = this.selectedFilterData;
          this.modalRef.close();
          this.clearData();
        }
      } else {
        const filterTypeObj = this.savedFilters.find(item => item.filterName === value);
        if (!filterTypeObj) {
          const newFilterObj = { ... this.reportFilterData };
          newFilterObj.filterName = value;
          this.savedFilters.push(newFilterObj);
          console.log('saved filters', this.savedFilters);

          this.modalRef.close();
          this.clearData();
        } else {
          alert('This filter is already exist.');
        }
      }
    }
  }

  private clearData() {
    this.reportFilterData = null;
    this.selectedFilterData = null;

    this.contractorCode = null;
    this.workOrderNo = null;
    this.subContractors = [];
    this.workOrders = [];
    // this.contractors=[];
    setTimeout(() => {
      this.contractors = [...this.contractors];
    }, 100);

    this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: ReportFilterModel) {
    this.filterType = null;
    this.isSubContractorSave = false;
    this.selectedFilterData = filterObj;
    this.onFilterTab(filterObj.filterType, filterObj);
  }

  // private createContractorFilter(contentRef: ViewContainerRef): void {
  //   this.contentRef.clear();
  // tslint:disable-next-line: max-line-length
  //   this.contractorComponentRef = this.entryfordayService.createComponent<ContractorFilterComponent>(contentRef, ContractorFilterComponent);

  //   this.contractorComponentRef.instance.onStartDateChange.subscribe((value: string) => {
  //     if (isNullOrUndefined(this.reportFilterData)) {
  //       this.reportFilterData = new ReportFilterModel();
  //     }
  //     this.reportFilterData.startDate = new Date(value);
  //   });

  //   this.contractorComponentRef.instance.onContractorCodeChange.subscribe((value: number) => {
  //     // this.contractorCode = value;
  //     if (isNullOrUndefined(this.reportFilterData)) {
  //       this.reportFilterData = new ReportFilterModel();
  //     }
  //     this.reportFilterData.contractorCode = value;
  //     this.getWorkOrdersByContractorCode(value);
  //   });

  //   this.contractorComponentRef.instance.getWorkOrderList.subscribe((value: number) => {
  //     // this.contractorCode = value;
  //     if (isNullOrUndefined(this.reportFilterData)) {
  //       this.reportFilterData = new ReportFilterModel();
  //     }
  //     this.reportFilterData.contractorCode = value;
  //     if (value) {
  //       this.getWorkOrdersByContractorCode(value);
  //     }
  //   });

  //   this.contractorComponentRef.instance.onWorkOrderNoChange.subscribe((value: any) => {
  //     // this.workOrderNo = value.workOrderNo;
  //     if (isNullOrUndefined(this.reportFilterData)) {
  //       this.reportFilterData = new ReportFilterModel();
  //     }
  //     this.reportFilterData.workOrderNo = value.workOrderNo;
  //     if (isNullOrUndefined(value.workOrderNo)) {
  //       this.reportFilterData.workOrderNo = null;
  //       this.subContractors = [];
  //     } else {
  //       this.getSubContractors(value.contractorCode, value.workOrderNo);
  //     }
  //   });

  //   this.contractorComponentRef.instance.saveReportData.subscribe((value: ReportFilterModel) => {
  //     this.reportFilterData = value;
  //     // this.openModal();
  //   });

  //   this.contractorComponentRef.instance.reportFilterData = this.reportFilterData;
  //   this.contractorComponentRef.instance.contractors = this.contractors;
  // }

  // private createSubContractorFilter(contentRef: ViewContainerRef): void {
  //   this.subContractorComponentRef = this.entryfordayService.createComponent<SubContractorFilterComponent>(contentRef,
  //     SubContractorFilterComponent);
  //         // when user selects the contractor, make api call for workorder
  //   this.subContractorComponentRef.instance.getSubContractorList.subscribe((value: any) => {
  //     this.getSubContractors(value.contractorCode, value.workOrderNo);
  //   });
  //   this.subContractorComponentRef.instance.reportFilterData = this.reportFilterData;
  //   this.subContractorComponentRef.instance.subContractors = [...this.subContractors];

  //   // when user selects the contractor, make api call for workorder
  //   this.subContractorComponentRef.instance.onContractorCodeChange.subscribe((value: number) => {
  //     if (isNullOrUndefined(this.reportFilterData)) {
  //       this.reportFilterData = new ReportFilterModel();
  //     }
  //     this.reportFilterData.subContractorCode = value;
  //     this.getWorkOrdersByContractorCode(value);
  //   });

  //   this.subContractorComponentRef.instance.saveReportData.subscribe((value: ReportFilterModel) => {
  //     this.reportFilterData = value;
  //     this.reportFilterData.contractorCode = value.contractorCode || this.contractorCode;
  //     this.reportFilterData.workOrderNo = value.workOrderNo || this.workOrderNo;
  //     this.reportFilterData.workOrderNo = value.workOrderNo || this.workOrderNo;
  //     // this.openModal();
  //   });
  // }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.filterNameFormGroup.get('filterTypeName').setValue(this.selectedFilterData ? this.selectedFilterData.filterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  private getWorkOrdersByContractorCode(contractorCode: number, plantcode: number, filterType?: number): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: WorkOrder[]) => {
      if (filterType === 1) {
        this.workOrders = response;
      } else if (filterType === 2) {
        this.subContractorWorkOrders = response;
      }
    });
  }

  private getSubContractors(contractorCode: number, workOrderNo: number): void {
    this.entryfordayService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: Contractor[]) => {
      this.subContractors = [...response];
    });
  }

  private setWorkOrderList(response: WorkOrder[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }
}
