import { Component, OnInit } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core/src/render3';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/core/services/shared.service';
import { AttendanceService } from '../../attendance.service';

@Component({
  selector: 'app-ag-exceptional-drop-down-cell-render',
  templateUrl: './ag-exceptional-drop-down-cell-render.component.html',
  styleUrls: ['./ag-exceptional-drop-down-cell-render.component.css'],

})
export class AgExceptionalDropDownCellRenderComponent implements OnInit, ICellRendererAngularComp {
  params;
  gridApi: any;
  rowData: any[];
  shifts = [];
  status = [];
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  private fb: FormBuilder;
  public statusList: any[];
  // entryfordayService: any;
  private entryfordayService: AttendanceService;
  itemList = [];
  width: 90;
  isValid = true;
  invalidMsg = '';
  inputClass = 'cellInput';

  ngOnInit() {
  }

  agInit(params: any): void {
    // console.log('AgExceptionalDropDownCellRenderComponent', params.isSelected);
    this.params = params;
    // if (typeof params.dataList === 'function') {
    //   this.itemList = this.params.dataList();
    // } else {
    //   this.itemList = this.params.dataListByIndex(this.params.rowIndex, this.params.column.colId);
    // }

    // this.width = this.params.width ? this.params.width : 90;
    // this.validateColumn();
    this.shifts = params.shifts;
    this.status = params.status;
  }

  refresh(): boolean {
    return true;
  }

  selectionChange(newValue) {
    this.params.data[this.params.column.colId] = Number(newValue);
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection(Number(newValue));
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.Status;
      }
    }
  }

  // onValueChange($event) {
  //   if (this.params.data[this.params.column.colId] === $event.target.value) {
  //     return;
  //   }

  //   this.params.data[this.params.column.colId] = $event.target.value;
  //   if (this.params.forceSelection) {
  //     const forceSelection = this.params.forceSelection($event.target.value);
  //     if (forceSelection) {
  //       this.params.data[forceSelection.field] = forceSelection.value;
  //     }
  //   }

  //   this.params.onInputTextChange(this.params.rowIndex, this.params.column.colId);
  // }

  // validateColumn() {
  //   const obj = this.params.isColValid(this.params.rowIndex, this.params.column.colId);
  //   this.isValid = obj.isValid;
  //   this.inputClass = 'cellInput';
  //   this.invalidMsg = '';
  //   if (obj.requireValidation) {
  //     this.inputClass += ' require-validation-cell-input';
  //     this.invalidMsg = obj.msg;
  //   } else if (!obj.isValid) {
  //     this.inputClass += ' invalid-cell-input';
  //     this.invalidMsg = obj.msg;
  //   }
  // }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable;
    }
    return false;
  }

}
