import { Component, OnInit } from '@angular/core';
import { AdvanceFilters, DataFilter } from '../select-steps-filter.model';
import { GridApi } from 'ag-grid-community';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-advance-filter-dynamic',
  templateUrl: './advance-filter-dynamic.component.html',
  styleUrls: ['./advance-filter-dynamic.component.css']
})
export class AdvanceFilterDynamicComponent implements OnInit {
  public filterData: Subject<{}>;
  public opened = true;
  public stepsTitleName: string;
  public labourDataSource: any;
  public advanceFilterForm: FormGroup;
  public subject: Subject<any>;
  public contractorStatusList: any[];
  public selectedAdvanceFilters: AdvanceFilters[] = [];
  public filter: DataFilter = new DataFilter();
  public gridAPI: GridApi;
  constructor(private formBuilder: FormBuilder) {
    this.stepsTitleName = null;
    this.filterData = new Subject<{}>();
    this.contractorStatusList = [
      { id: 1, name: 'Review' },
      { id: 2, name: 'Active' },
      { id: 3, name: 'Deactive' },
      { id: 4, name: 'Debarred' },
      { id: 5, name: 'Cancel' }
    ];
  }

  ngOnInit() {
    this.advanceFilterForm = this.formBuilder.group({
      filter: ['', Validators.required],
    });
  }

  public isFilterSelected() {
    return JSON.stringify(this.filter) !== JSON.stringify(new DataFilter());
  }

  public filtergrid() {
    this.opened = false;
    this.setSelectedFilters();
    this.filterData.next(this.filter);
    // this.gridAPI.setDatasource(this.labourDataSource);
    // this.gridAPI.sizeColumnsToFit();
  }

  public clearFilter() {
    this.opened = false;
    this.filter = new DataFilter();
    this.filtergrid();
  }

  public filterRemoved(code: string) {
    const newFilter = new DataFilter();
    this.filter[code] = newFilter[code];
    this.filtergrid();
  }

  public setSelectedFilters() {
    this.selectedAdvanceFilters = [];
    for (const key in this.filter) {
      if (this.filter.hasOwnProperty(key)) {
        const element = this.filter[key];
        if (element !== -1 && element !== '' && element !== null && element !== false) {
          let name = '';
          switch (key) {
            case 'contractorCompanyName':
              name = 'Company Name';
              break;
            case 'contractorsEmailId':
              name = 'Email Id';
              break;
            case 'skillTypeCode':
              name = 'Skill';
              break;
            case 'worksiteCategoryCode':
              name = 'Work Site Category';
              break;
            case 'statusCode':
              name = 'Status';
              break;
            case 'roleCodeForApproval':
              name = 'Role';
              break;
            case 'roleActionCode':
              name = 'Status of Role Action';
              break;
            case 'labourDemandType':
              name = 'Labour Type';
              break;
            case 'gatePassStatus':
              name = 'Gatepass Status';
              break;
            case 'filterStartDate':
              name = 'Start Date';
              break;
            case 'filterEndDate':
              name = 'End Date';
              break;
            case 'contractorVendorCode':
              name = 'Contractor';
              break;
            case 'workorderCode':
              name = 'Workorder';
              break;
            case 'licenseCode':
              name = 'License';
              break;
            case 'mainEsic':
              name = 'Esic';
              break;
            case 'ecpolicyCode':
              name = 'EC Policy';
              break;


            case 'subContractorCode':
              name = 'Sub Contractor';
              break;
            case 'subWorkorderCode':
              name = 'Sub Workorder';
              break;
            case 'subLicenseCode':
              name = 'Sub License';
              break;
            case 'subEsic':
              name = 'Sub Esic';
              break;
            case 'subeEcPolicyCode':
              name = 'Sub EC Policy';
              break;
            case 'search':
              name = 'Search';
              break;
            default:
              break;
          }
          if (name) {
            this.selectedAdvanceFilters.push({
              name,
              code: key
            });
          }
        }
      }
    }
  }

}
