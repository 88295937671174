import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { AttendanceService } from '../attendance.service';
import { UserOTApprovalListModalComponent } from './approval-list-modal/approval-list-modal.component';
import * as moment from 'moment';
import { SectionHeadOTApprovalListModalComponent } from './section-head-approval-list-modal/section-head-approval-list-modal.component';
import { convertToHourAndMinutes, findTotalMinutes, getMinDateFromCode } from 'src/app/common/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-ot-approval',
  templateUrl: './user-ot-approval.component.html',
  styleUrls: ['./user-ot-approval.component.css']
})
export class UserOTApprovalComponent implements OnInit {
  
  public get minDate$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res && res.Data && res.Data.Table, 'MOD115PG386FV_1', 'MOD115PG386DV_2')));
  }

  public otApprovalListForSectionHead: any[] = [];
  public otApprovalListForDepartmentHead: any[] = [];
  public formGroup: FormGroup;
  public isShowSectionHeadTab: boolean;
  public isShowDepartmentHeadTab: boolean;

  public activeTab = 0;

  constructor(
    private attendanceService: AttendanceService,
    private logService: LogHelperService,
    private modalService: NgbModal,
    public sharedService: SharedService,
    private fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      dateRange: [null]
    })
  }

  ngOnInit() {
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    if (userRoles) {
      this.isShowSectionHeadTab = userRoles.some(role => role.Role_Code === 107);
      this.isShowDepartmentHeadTab = userRoles.some(role => role.Role_Code === 106);
      if (this.isShowSectionHeadTab) {
        this.activeTab = 0;
      } else {
        this.activeTab = 1;
      }
    }

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.formGroup.get('dateRange').setValue([firstDay, lastDay]);
    const requestObj = {
      UserID: localStorage.getItem('UserID'),
      StartDate: moment(firstDay).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      EndDate: moment(lastDay).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      Plant_Code: null
    };

    this.getUserOTApprovalList(requestObj);
  }

  public onDateRangeChange(): void {
    const formValue = this.formGroup.getRawValue();
    if (formValue.dateRange && formValue.dateRange.length) {
      const requestObj = {
        UserID: localStorage.getItem('UserID'),
        StartDate: moment(formValue.dateRange[0]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        EndDate: moment(formValue.dateRange[1]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        Plant_Code: null
      };

      this.getUserOTApprovalList(requestObj);
    }
  }

  private getRequestObj(): any {
    const formValue = this.formGroup.getRawValue();
    if (formValue.dateRange && formValue.dateRange.length) {
      return {
        UserID: localStorage.getItem('UserID'),
        StartDate: moment(formValue.dateRange[0]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        EndDate: moment(formValue.dateRange[1]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        Plant_Code: null
      };
    }
    return null;
  }

  getUserOTApprovalList(requestObj: any) {
    this.attendanceService.getUserOTApprovalList(requestObj).subscribe(response => {
      console.log('response', response);
      this.otApprovalListForSectionHead = _.cloneDeep(this.getGroupByContractorDataForSectionHead(response));
      this.otApprovalListForDepartmentHead = _.cloneDeep(this.getGroupBySectionForDepartmentHead(response));
    })
  }

  onTabChange(event) {
    this.activeTab = +event.nextId;
    // this.getUserOTApprovalList();
  }

  public checkAllForSectionHead(event: any, dateObj: any) {
    event.stopPropagation();
    dateObj.isCheck = !dateObj.isCheck;
    if (dateObj.contractors) {
      dateObj.contractors = dateObj.contractors.map(item => {
        item.isCheck = !dateObj.isCheck;
        return this.checkSection(null, item);
      });
    }
  }

  public checkContractor(event: any, sectionObj: any) {
    if (event) {
      event.stopPropagation();
    }

    sectionObj.isCheck = !sectionObj.isCheck;
    if (sectionObj.shifts) {
      sectionObj.shifts = sectionObj.shifts.map(item => {
        return { ...item, isCheck: sectionObj.isCheck }
      });
    }
    return sectionObj;
  }

  public checkShift(event: any, shiftObj: any) {
    if (event) {
      event.stopPropagation();
    }

    shiftObj.isCheck = !shiftObj.isCheck;
  }

  public checkAllForDepartmentHead(event: any, dateObj: any) {
    event.stopPropagation();
    dateObj.isCheck = !dateObj.isCheck;
    if (dateObj.sections) {
      dateObj.sections = dateObj.sections.map(item => {
        item.isCheck = !dateObj.isCheck;
        return this.checkSection(null, item);
      });
    }
  }

  public checkSection(event: any, sectionObj: any) {
    if (event) {
      event.stopPropagation();
    }

    sectionObj.isCheck = !sectionObj.isCheck;
    if (sectionObj.shifts) {
      sectionObj.shifts = sectionObj.shifts.map(item => {
        return { ...item, isCheck: sectionObj.isCheck }
      });
    }
    return sectionObj;
  }

  public onAccept(): void {
    const selectedList = +this.activeTab === 0 ? this.getSelectedItemsForSectionHead(this.otApprovalListForSectionHead) : this.getSelectedItemsForDepartmentHead(this.otApprovalListForDepartmentHead);
    this.submitOTApprovalData(selectedList, true);
  }

  public onReject(): void {
    const selectedList = +this.activeTab === 0 ? this.getSelectedItemsForSectionHead(this.otApprovalListForSectionHead) : this.getSelectedItemsForDepartmentHead(this.otApprovalListForDepartmentHead);
    this.submitOTApprovalData(selectedList);
  }

  private submitOTApprovalData(selectedList: any[], isAccept?: boolean): void {
    if (!selectedList.length) {
      this.logService.logError({ message: 'Please select at least on item.' });
      return;
    }

    const list = this.convertToRequestList(selectedList, isAccept
    );
    this.attendanceService.saveUserOTApprovalList(list).subscribe(response => {
      if (response) {
        this.logService.logSuccess({ message: 'Requested data is updated successfully' });
        this.getUserOTApprovalList(this.getRequestObj());
      }
    });
  }

  public onDateRowSelectioForSectionHead(dateWiseData: any): void {
    this.openReportViewModal([{ ...dateWiseData, isCheck: false }]);
  }

  public onContractorRowSelection(dateWiseData: any, contractorWiseData: any): void {
    const dateList = [
      {
        ...dateWiseData,
        isHide: true,
        isCheck: false,
        contractors: [{ ...contractorWiseData, isCheck: false }]
      }
    ];

    this.openReportViewModal(dateList);
  }

  public onShiftRowSelection(dateWiseData: any, contractorWiseData: any, shift: any): void {
    const dateList = [
      {
        date: dateWiseData.date,
        isHide: true,
        isCheck: false,
        contractors: [
          {
            ...contractorWiseData,
            isHide: true,
            isCheck: false,
            shifts: [{ ...shift, isCheck: false }]
          }
        ]
      }
    ];

    this.openReportViewModal(dateList);
  }

  public onDateRowSelectionForDepartmentHead(dateWiseData: any): void {
    // const finalList = this.otApprovalListForDepartmentHead.filter(item => item.date === dateWiseData.date);
    this.openReportViewModalForDepartmentHead([{ ...dateWiseData, isCheck: false }]);
  }

  public onSectionRowSelection(dateWiseData: any, sectionData: any): void {
    const dateList = [
      {
        ...dateWiseData,
        isHide: true,
        isCheck: false,
        sections: [{ ...sectionData, isCheck: false }]
      }
    ];

    this.openReportViewModalForDepartmentHead(dateList);
  }

  public onShiftRowSelectionForDepartmentHead(dateWiseData: any, contractorWiseData: any, shift: any): void {
    const dateList = [
      {
        date: dateWiseData.date,
        isHide: true,
        isCheck: false,
        sections: [
          {
            ...contractorWiseData,
            isHide: true,
            isCheck: false,
            shifts: [{ ...shift, isCheck: false }]
          }
        ]
      }
    ];

    this.openReportViewModalForDepartmentHead(dateList);
  }

  private openReportViewModal(response: any[]): void {
    const modalRef = this.modalService.open(SectionHeadOTApprovalListModalComponent,
      // { size: 'lg', backdrop: 'static', windowClass: 'modal-body-scroll' });
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel modal-body-scroll' });
    modalRef.componentInstance.reportData = _.cloneDeep(response);
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.showAlert.subscribe((message: string) => {
      this.logService.logError({ message });
    });

    modalRef.componentInstance.selectedItems.subscribe(({ selectedItems, isAccept }: any) => {
      console.log('selectedItems', selectedItems);
      const list = this.getSelectedItemsForSectionHead(selectedItems);
      this.submitOTApprovalData(list, isAccept);
    });
  }
  private openReportViewModalForDepartmentHead(response: any[]): void {
    const modalRef = this.modalService.open(UserOTApprovalListModalComponent,
      // { size: 'lg', backdrop: 'static', windowClass: 'modal-body-scroll' });
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel modal-body-scroll' });
    modalRef.componentInstance.reportData = _.cloneDeep(response);
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.showAlert.subscribe((message: string) => {
      this.logService.logError({ message });
    });

    modalRef.componentInstance.selectedItems.subscribe(({ selectedItems, isAccept }: any) => {
      console.log('selectedItems', selectedItems);
      const list = this.getSelectedItemsForDepartmentHead(selectedItems);
      this.submitOTApprovalData(list, isAccept);
    });
  }

  private convertToRequestList(data: any[], isAccept?: boolean): any[] {
    const list: any[] = []
    data.forEach(({ Request_Code, Priority, Plant_Code, RequestLevel_Code, RequestType_Code, LabourMovement_Code, RequestDate, SectionPlant_ID, LabourAttResult_Code }) => {
      const dataObj = {
        RequestNo: Request_Code,
        Plant_Code,
        RequestType_Code,
        RequestDate,
        LabourMovement_Code,
        SectionPlant_ID,
        LabourAttResult_Code,
        RequestLevel_Code,
        CreateBy: localStorage.getItem('UserID'),
        ModifiedBy: localStorage.getItem('UserID'),
        IsFinalApproval: +this.activeTab === 1 && isAccept ? true : false,
        IsRejected: !isAccept,
        StatusMasterList_Code: isAccept ? 256 : 608,
        Comment: null,
        Priority: Priority + 1
      };

      list.push(dataObj);
    })
    return list;
  }

  private getSelectedItemsForSectionHead(list: any[]): any[] {
    let finalDataList: any[] = [];
    list.forEach(dateObj => {
      if (dateObj.isCheck) {
        dateObj.contractors.forEach(contractor => {
          contractor.shifts.forEach(shift => {
            finalDataList = finalDataList.concat(shift.assoicates);
          });
        });
      } else {
        dateObj.contractors.forEach(contractor => {
          if (contractor.isCheck) {
            contractor.shifts.forEach(shift => {
              finalDataList = finalDataList.concat(shift.assoicates);
            });
          } else {
            contractor.shifts.forEach(shift => {
              if (shift.isCheck) {
                finalDataList = finalDataList.concat(shift.assoicates);
              } else {
                shift.assoicates.forEach(assoicate => {
                  if (assoicate.isCheck) {
                    finalDataList.push(assoicate);
                  }
                });
              }
            });
          }
        });
      }
    });

    return finalDataList;
  }

  private getSelectedItemsForDepartmentHead(list: any[]): any[] {
    let finalDataList: any[] = [];
    list.forEach(dateObj => {
      if (dateObj.isCheck) {
        dateObj.sections.forEach(section => {
          section.shifts.forEach(shift => {
            finalDataList = finalDataList.concat(shift.assoicates);
          });
        });
      } else {
        dateObj.sections.forEach(section => {
          if (section.isCheck) {
            section.shifts.forEach(shift => {
              finalDataList = finalDataList.concat(shift.assoicates);
            });
          } else {
            section.shifts.forEach(shift => {
              if (shift.isCheck) {
                finalDataList = finalDataList.concat(shift.assoicates);
              } else {
                shift.assoicates.forEach(assoicate => {
                  if (assoicate.isCheck) {
                    finalDataList.push(assoicate);
                  }
                });
              }
            });
          }
        });
      }
    });

    return finalDataList;
  }

  private getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    const dateWiseData = new Set(reportData.map(item => item.Date));
    dateWiseData.forEach((dateWiseItem: string) => {
      const items = reportData.filter(item => item.Date === dateWiseItem);
      const dateObj = {
        date: dateWiseItem,
        sections: []
      };
      const sectionWiseData = new Set(items.map(item => item.Section));
      sectionWiseData.forEach(sectionItem => {
        const sectionItems = items.filter(item => item.Section === sectionItem);
        const sectionObj = {
          sectionName: sectionItem,
          shifts: sectionItems
        };

        dateObj.sections.push(sectionObj);
      });

      tableData.push(dateObj);
    });
    return tableData;
  }

  private getGroupByContractorDataForSectionHead(reportData: any[]): any[] {
    const sectionHeadDataList: any[] = reportData.filter(item => item.Contractor && !item.SH);

    const tableData = [];
    let dateWiseData = new Set(sectionHeadDataList.map(item => item.Date));
    dateWiseData.forEach((dateWiseItem: string) => {
      let totalDateObjMinutes = 0;

      const items = sectionHeadDataList.filter(item => item.Date === dateWiseItem).sort((a, b) => {
        if (a.Contractor < b.Contractor) {
          return -1;
        }
        if (a.Contractor > b.Contractor) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      const dateObj = {
        date: dateWiseItem,
        contractors: [],
        TotalAssociate: 0,
        TotalEWH: ''
      };

      const contractorWiseData = new Set(items.map(item => item.Contractor));
      contractorWiseData.forEach(contractorItem => {
        let totalContractoreObjMinutes = 0;

        const contractorItems = items.filter(item => item.Contractor === contractorItem).sort((a, b) => {
          if (a.Shift < b.Shift) {
            return -1;
          }
          if (a.Shift > b.Shift) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        const contractorObj = {
          contractorName: contractorItem,
          shifts: [],
          TotalAssociate: 0,
          TotalEWH: ''
        };

        const shiftWiseData = new Set(contractorItems.map(item => item.Shift));
        shiftWiseData.forEach((shiftWiseItem: string) => {
          let totalShiftMinutes = 0;

          const shiftWiseItems = contractorItems.filter(item => item.Shift === shiftWiseItem).sort((a, b) => {
            if (a.Shift < b.Shift) {
              return -1;
            }
            if (a.Shift < b.Shift) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          shiftWiseItems.forEach(shiftItemObj => {
            totalShiftMinutes += findTotalMinutes(shiftItemObj.OT);
          });

          const shiftObj = {
            Shift: shiftWiseItem,
            assoicates: shiftWiseItems,
            TotalAssociate: shiftWiseItems.length,
            // TotalEWH: shiftWiseItems.length > 0 ? shiftWiseItems.map(res => res.OT || 0).reduce((a, b) => a + b, 0) : 0,
            TotalEWH: convertToHourAndMinutes(totalShiftMinutes)
          }
          contractorObj.shifts.push(shiftObj);

          totalContractoreObjMinutes += totalShiftMinutes;

          contractorObj.TotalAssociate += shiftObj.TotalAssociate;
        });

        contractorObj.TotalEWH = convertToHourAndMinutes(totalContractoreObjMinutes);

        dateObj.TotalAssociate += contractorObj.TotalAssociate;
        totalDateObjMinutes += totalContractoreObjMinutes;
        dateObj.contractors.push(contractorObj);
      });

      dateObj.TotalEWH = convertToHourAndMinutes(totalDateObjMinutes);

      tableData.push(dateObj);
    });
    return tableData;
  }

  private getGroupBySectionForDepartmentHead(reportData: any[]): any[] {
    const departmentHeadList: any[] = reportData.filter(item => item.Contractor && item.SH && !item.DH);
    const tableData = [];
    const dateWiseData = new Set(departmentHeadList.map(item => item.Date));
    dateWiseData.forEach((dateWiseItem: string) => {
      let totalDateObjMinutes = 0;

      const items = departmentHeadList.filter(item => item.Date === dateWiseItem).sort((a, b) => {
        if (a.Section < b.Section) {
          return -1;
        }
        if (a.Section > b.Section) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      const dateObj = {
        date: dateWiseItem,
        sections: [],
        TotalAssociate: 0,
        TotalEWH: ''
      };
      const sectionWiseData = new Set(items.map(item => item.Section));
      sectionWiseData.forEach(sectionItem => {
        let totalSectionObjMinutes = 0;

        const sectionItems = items.filter(item => item.Section === sectionItem).sort((a, b) => {
          if (a.Shift < b.Shift) {
            return -1;
          }
          if (a.Shift > b.Shift) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        const sectionObj = {
          sectionName: sectionItem,
          shifts: [],
          TotalAssociate: 0,
          TotalEWH: ''
        };

        const shiftWiseData = new Set(sectionItems.map(item => item.Shift));
        shiftWiseData.forEach((shiftWiseItem: string) => {
          let totalShiftMinutes = 0;

          const shiftWiseItems = sectionItems.filter(item => item.Shift === shiftWiseItem).sort((a, b) => {
            if (a.Shift < b.Shift) {
              return -1;
            }
            if (a.Shift > b.Shift) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          shiftWiseItems.forEach(shiftItemObj => {
            totalShiftMinutes += findTotalMinutes(shiftItemObj.OT);
          });

          const shiftObj = {
            Shift: shiftWiseItem,
            assoicates: _.clone(shiftWiseItems),
            TotalAssociate: shiftWiseItems.length,
            // TotalEWH: shiftWiseItems.length > 0 ? shiftWiseItems.map(res => res.OT || 0).reduce((a, b) => a + b, 0) : 0
            TotalEWH: convertToHourAndMinutes(totalShiftMinutes)
          }
          sectionObj.shifts.push(shiftObj);
          sectionObj.TotalAssociate += shiftObj.TotalAssociate;
          sectionObj.TotalEWH += shiftObj.TotalEWH;

          totalSectionObjMinutes += totalShiftMinutes;
        });
        sectionObj.TotalEWH = convertToHourAndMinutes(totalSectionObjMinutes);


        dateObj.sections.push(sectionObj);

        dateObj.TotalAssociate += sectionObj.TotalAssociate;
        // dateObj.TotalEWH += sectionObj.TotalEWH;
        totalDateObjMinutes += totalSectionObjMinutes;
      });
      dateObj.TotalEWH = convertToHourAndMinutes(totalDateObjMinutes);

      tableData.push(dateObj);
    });
    return tableData;
  }
}
