import { Component, OnInit, EventEmitter, ViewChild, Output } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import { GridOptions } from 'ag-grid-community';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL } from 'src/app/app.globals';
import { AttendanceManualPunchService } from '../attendance-manual-punch.service';
import { AgInputEditCellRendererComponent } from '../../ag-helper/ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { Router, RouterPreloader } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { AttendanceService } from '../../attendance.service';
// tslint:disable-next-line:max-line-length
import { AgDatePickerEditCellRendererComponent } from 'src/app/labour/labour-import/ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component';
// tslint:disable-next-line:max-line-length
import { AgSelectEditCellRendererComponent } from 'src/app/labour/labour-import/ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component';
// tslint:disable-next-line:max-line-length
import { AgTimePickerEditCellRendererComponent } from 'src/app/import-excel/ag-timepicker-edit-cell-renderer/ag-timepicker-edit-cell-renderer.component';
// tslint:disable-next-line:max-line-length
import { AgIconActionCellRendererComponent } from 'src/app/labour/labour-import/ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { UserRoles } from '../attendance-manual-punch.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getMinDateFromCode } from 'src/app/common/utils';
import { SharedService } from 'src/app/core/services';
import * as _ from 'lodash';

declare const $: any;

@Component({
  selector: 'app-manual-punch',
  templateUrl: './manual-punch.component.html',
  styleUrls: ['./manual-punch.component.css']
})
export class ManualPunchComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  fileError = '';

  public closeModal: EventEmitter<any>;

  @Output() public save: EventEmitter<void>;
  cValue = new Date();
  myDate = formatDate(this.cValue, 'yyyy-MM-dd', 'en-US');
  maindivclass = 'content Overlapclass';
  userEmail = '';
  loginUserId = ''; // GetCokkiesValue(3);
  loading = false;
  maxGridHeight = window.innerHeight - 210;
  fullHeight = `${(window.innerHeight - 110)}px`;
  fileObj = null;
  attendanceData = [];
  erlogin: any;
  hrlogin: any;
  uhrbplogin: any;
  phrbplogin: any;
  roleCode;
  gatePassMap = {};
  gatePassNotFoundSet = new Set();
  dateOptions = {
    showWeeks: false
  };

  plantUnitDepartmentTree = [];
  plantin = [];
  plantout = [];
  unitin = [];
  unitout = [];
  loginUserRoleCode = Number(localStorage.getItem('RoleCode'));
  downloadSampleList = [
    {
      Name: 'Goa',
      Url: 'assets/sample/import/manual-punch/Import-manual-punch_V1_Goa.xlsx',
    }
  ];

  userplantDetail = [];
  globalDataSampleDownload = [];
  globalSettingData = [];
  DownloadSampleExelShortCode = 'PNCIMPEXL001';
  allPlantsUser = [];

  onInputTextChange = (rowIndex, field) => {
    $('div[uib-tooltip-popup]').remove();

    if (field === 'Plant_Code') {
      const row = this.attendanceData[rowIndex];
      row.Unit_Code = null;
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    } else if (field === 'Unit_Code') {
      const row = this.attendanceData[rowIndex];
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    } else if (field === 'PInTime') {
      if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode)) {
        this.attendanceData[rowIndex]['POutTime'] = '';
      }
    } else if (field === 'POutTime') {
      if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode)) {
        this.attendanceData[rowIndex]['PInTime'] = '';
      }
    } else if (field === 'UInTime') {
      if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode)) {
        this.attendanceData[rowIndex]['UOutTime'] = '';
      }
    } else if (field === 'UOutTime') {
      if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode)) {
        this.attendanceData[rowIndex]['UInTime'] = '';
      }
    }

    // if (field === 'Department_Code') {
    //   const row = this.attendanceData[rowIndex];
    //   row.SectionPlantRelationId1 = null;
    //   row.SectionPlantRelationId2 = null;
    //   row.SectionPlantRelationId3 = null;
    // }

    // if (field === 'SectionPlantRelationId1') {
    //   const row = this.attendanceData[rowIndex];
    //   row.SectionPlantRelationId2 = null;
    //   row.SectionPlantRelationId3 = null;
    // }

    // if (field === 'SectionPlantRelationId2') {
    //   const row = this.attendanceData[rowIndex];
    //   row.SectionPlantRelationId3 = null;
    // }

    // if (field === 'SectionPlantRelationId3') {
    // }

    const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    const rowNodes = [row]; // params needs an array
    // const params = {
    //   force: true,
    //   rowNodes: rowNodes,
    //   columns: [field]
    // };
    // this.gridOptions.api.refreshCells(params);
    // tslint:disable-next-line
    this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    // this.gridOptions.api.refreshCells({ rowNodes: rowNodes, columns: [field], force: true });
  }

  isColValid = (rowIndex, field) => {
    const returnObj = {
      isValid: true,
      requireValidation: false,
      msg: ''
    };
    let val = '';
    const requireValidationMessage = 'Validation required';

    const code = this.attendanceData[rowIndex]['Code'];
    const date = this.attendanceData[rowIndex]['Date'];
    const pInTime = this.attendanceData[rowIndex]['PInTime'];
    const pOutTime = this.attendanceData[rowIndex]['POutTime'];
    const uInTime = this.attendanceData[rowIndex]['UInTime'];
    const uOutTime = this.attendanceData[rowIndex]['UOutTime'];

    switch (field) {
      case 'Code':
        val = this.attendanceData[rowIndex][field] || '';
        const ulc = this.gatePassMap[val];
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please enter ULC.`;
        } else if (!ulc) {
          returnObj.isValid = false;
          if (this.gatePassNotFoundSet.has(val)) {
            returnObj.msg = `ULC not found in master data. Please contract administrator or change ULC.`;
            returnObj.isValid = false;
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
        } else {
          this.attendanceData[rowIndex]['ULC'] = ulc ? ulc : null;
        }
        break;
      case 'Plant_Code':
      case 'Unit_Code':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select ${field}.`;
        }
        break;
      case 'Date':
        val = this.attendanceData[rowIndex][field] || '';
        // const ulc = this.gatePassMap[val];
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select Date.`;
        } else if (val > this.myDate) {
          returnObj.isValid = false;
          returnObj.msg = `You cannot enter future date.`;
        } else if (val  < this.minDate) {
          returnObj.isValid = false;
          const date = this.datepipe.transform(this.minDate, 'dd/MM/yyyy');
          returnObj.msg = `You cannot enter date less than ${date} date.`;
        }
        break;
      case 'PInTime':
        val = this.attendanceData[rowIndex][field] || '';
        if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode) && val && pOutTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = 'You can either enter in or out time.';
        } else if (!val && !pOutTime && !uInTime && !uOutTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = requireValidationMessage;
        } else if (val === '') {
          returnObj.isValid = true;
        } else {
          if (val && !this.plantin.length) {
            returnObj.isValid = true;
          } else {
            const pin = this.plantin.find(tmp => tmp.Code === code && tmp.Date === date && tmp.PInTime === pInTime);
            if (!pin) {
              returnObj.isValid = false;
              returnObj.requireValidation = true;
              returnObj.msg = requireValidationMessage;
            } else if (!pin.IsValid) {
              returnObj.isValid = false;
              returnObj.msg = `Punch Already Exist.`;
            }
          }
        }
        break;
      case 'POutTime':
        val = this.attendanceData[rowIndex][field] || '';
        if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode) && val && pInTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = 'You can either enter in or out time.';
        } else if (!val && !pInTime && !uInTime && !uOutTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = requireValidationMessage;
        } else if (val === '') {
          returnObj.isValid = true;
        } else {
          if (val && !this.plantout.length) {
            returnObj.isValid = true;
          } else {
            const pout = this.plantout.find(tmp => tmp.Code === code && tmp.Date === date && tmp.POutTime === pOutTime);
            if (!pout) {
              returnObj.isValid = false;
              returnObj.requireValidation = true;
              returnObj.msg = requireValidationMessage;
            } else if (!pout.IsValid) {
              returnObj.isValid = false;
              returnObj.msg = `Punch Already Exist.`;
            }
          }
        }
        break;
      case 'UInTime':
        val = this.attendanceData[rowIndex][field] || '';
        if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode) && val && uOutTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = 'You can either enter in or out time.';
        } else if (!val && !pInTime && !pOutTime && !uOutTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = requireValidationMessage;
        } else if (val === '') {
          returnObj.isValid = true;
        } else {
          if (val && !this.unitin.length) {
            returnObj.isValid = true;
          } else {
            const uin = this.unitin.find(tmp => tmp.Code === code && tmp.Date === date && tmp.UInTime === uInTime);
            if (!uin) {
              returnObj.isValid = false;
              returnObj.requireValidation = true;
              returnObj.msg = requireValidationMessage;
            } else if (!uin.IsValid) {
              returnObj.isValid = false;
              returnObj.msg = `Punch Already Exist.`;
            }
          }
        }
        break;
      case 'UOutTime':
        val = this.attendanceData[rowIndex][field] || '';
        if ([UserRoles.Contractor, UserRoles.ER].includes(this.loginUserRoleCode) && val && uInTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = 'You can either enter in or out time.';
        } else if (!val && !pInTime && !uInTime && !pOutTime) {
          returnObj.isValid = false;
          returnObj.requireValidation = true;
          returnObj.msg = requireValidationMessage;
        } else if (val === '') {
          returnObj.isValid = true;
        } else {
          if (val && !this.unitout.length) {
            returnObj.isValid = true;
          } else {
            const uout = this.unitout.find(tmp => tmp.Code === code && tmp.Date === date && tmp.UOutTime === uOutTime);
            if (!uout) {
              returnObj.isValid = false;
              returnObj.requireValidation = true;
              returnObj.msg = requireValidationMessage;
            } else if (!uout.IsValid) {
              returnObj.isValid = false;
              returnObj.msg = `Punch Already Exist.`;
            }
          }
        }
        break;
      default:
        break;
    }

    return returnObj;
  }

  deleteRow = (rowIndex) => {
    this.attendanceData.splice(rowIndex, 1);
    this.gridOptions.api.setRowData(this.attendanceData);
  }

  getPlantList = (rowIndex, field) => {
    return this.plantUnitDepartmentTree.map((item) => {
      return { id: item.Plantcode0, text: item.Plant0 };
    });
  }
  getUnitList = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .map((item) => {
        return { id: item.Unitcode0, text: item.Unit0 };
      });
  }

  getDepartmentList = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .map((item) => {
        return { id: item.UnitDeptID0, text: item.Department0 };
      });
  }

  getSection1List = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .find(item => item.UnitDeptID0 === row.Department_Code).Values
      .map((item) => {
        return { id: item.Sectioncode0, text: item.SectionName0 };
      });
  }

  getSection2List = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code || !row.SectionPlantRelationId1) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .find(item => item.UnitDeptID0 === row.Department_Code).Values
      .find(item => item.Sectioncode0 === row.SectionPlantRelationId1).Values
      .map((item) => {
        return { id: item.Sectioncode1, text: item.SectionName1 };
      });
  }

  getSection3List = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code || !row.SectionPlantRelationId1 || !row.SectionPlantRelationId2) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);
    row.SectionPlantRelationId2 = parseInt(row.SectionPlantRelationId2, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .find(item => item.UnitDeptID0 === row.Department_Code).Values
      .find(item => item.Sectioncode0 === row.SectionPlantRelationId1).Values
      .find(item => item.Sectioncode1 === row.SectionPlantRelationId2).Values
      .map((item) => {
        return { id: item.LevelID2, text: item.SectionName2 };
      });
  }
  // tslint:disable-next-line
  columnDefs = [
    {
      headerName: '', field: 'act', width: 30, minWidth: 30, maxWidth: 30,
      cellRendererFramework: AgIconActionCellRendererComponent,
      cellRendererParams: {
        onClick: this.deleteRow,
        title: 'Delete',
        width: 30,
        icon: 'fa fa-times',
        cssClass: 'row-delete-icon',
        fontSize: 24,
      }, pinned: 'left'
    },
    {
      headerName: 'Code', field: 'Code', width: 100, minWidth: 120, maxWidth: 120,
      cellRendererFramework: AgInputEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Date', field: 'Date', width: 120, minWidth: 120, maxWidth: 120,
      cellRendererFramework: AgDatePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Plant', field: 'Plant_Code', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgSelectEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
        dataListByIndex: this.getPlantList,
      }
    },
    {
      headerName: 'In-Time', field: 'PInTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Out-Time', field: 'POutTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Unit', field: 'Unit_Code', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgSelectEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
        dataListByIndex: this.getUnitList,
      }
    },
    {
      headerName: 'In-Time', field: 'UInTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Out-Time', field: 'UOutTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
  ];

  // tslint:disable-next-line
  gridOptions: GridOptions = {
    context: {
      componentParent: this
    },
    columnDefs: this.columnDefs,
    rowData: this.attendanceData,
    headerHeight: 39,
    rowHeight: 39,
    onGridReady: (params) => {
      // this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    onCellFocused: (params) => {
      const rowIndex = params.rowIndex;
      if (params.column) {
        const field = params.column.getColId();
        this.setFocusInput(rowIndex, field);
      }
    },
    getRowStyle: (params) => {
      let isValid = true;
      const keys = Object.keys(params.data);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(params.node.rowIndex, keys[j]);
        if (!validObj.isValid) {
          isValid = false;
          break;
        }
      }

      if (isValid) {
        this.attendanceData[params.node.rowIndex]['rowValid'] = true;
        return { background: '#33CC00 !important' };
      }

      this.attendanceData[params.node.rowIndex]['rowValid'] = false;
      return { background: '#FFFF95 !important' };
    },
    pagination: false,
    paginationPageSize: 10,
    suppressMovableColumns: true,
    // angularCompileRows: true
  };

  setFocusInput = (rowIndex, field) => {
  }

  public minDate: any;

  constructor(
    private loadingService: Ng4LoadingSpinnerService,
    private cookieService: CookieService,
    private logHelperService: LogHelperService,
    private attendancemanualpunchService: AttendanceManualPunchService,
    private router: Router,
    private attendanceService: AttendanceService,
    public datepipe: DatePipe,
    private sharedService: SharedService
  ) {
    this.closeModal = new EventEmitter();
    this.save = new EventEmitter();

    this.userEmail = String(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserEmail'));
    this.loginUserId = localStorage.getItem('UserID');
    this.checkMinDate();
  }

  ngOnInit() {
    this.getGlobalSettings();
    this.getUserplantDetail();
    this.loadGlobalSettings();
    this.getPlantUnitDepartmentTree();
    setTimeout(() => {
      // this.bindExcelData(null);
      this.gridOptions.api.sizeColumnsToFit();
    }, 2000);
  }

  private checkMinDate() {
    this.sharedService.globalSettingsDataSource$.subscribe((res: any) => {
      this.minDate = getMinDateFromCode(res.Data.Table, 'MOD115PG348FV_1', 'MOD115PG348DV_2')
    });
  }

  public getPlantUnitDepartmentTree() {

    this.attendancemanualpunchService.getDepartmentPlantUnitSectionData()
      .subscribe((res) => {
        this.plantUnitDepartmentTree = res;
      });
  }
  public onCloseAction(): void {
    this.closeModal.next();
  }

  onFileChange($event) {
    this.validateFile($event.target);
  }

  onUploadFile() {
    // this.bindExcelData(null);
    if (this.validateFile(this.fileInput.nativeElement)) {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        if (e.target) {
          /* read workbook */
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* save data */
          const fileContent = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null, raw: false });

          if (!fileContent) {
            this.fileError = 'Please select a file to upload.';
            return;
          }
          const keys: any = fileContent.shift();
          const data = fileContent.map((e) => {
            const obj = {};
            for (let i = 0; i < keys.length; i++) {
              obj[keys[i]] = e[i];
            }
            return obj;
          });

          if (data.length === 0) {
            this.loadingService.hide();
            this.fileError = 'AtLeast one record should be availabe in file to upload a file.';
            return;
          }
          if (data.length > Number(this.globalSettingData[0].Value)) {
            this.loadingService.hide();
            this.fileError = `Maximum ${this.globalSettingData[0].Value} Records is allowed to import.`;
            return;
          }

          // this.loadingService.hide();
          localStorage.setItem('xlsx_data', JSON.stringify(data));
          this.bindExcelData(this.removeSortCharFromKeys(data));
        }
      };
      this.loadingService.show();
      setTimeout(() => {
        reader.readAsBinaryString(this.fileInput.nativeElement.files[0]);
      }, 1000);
    }
  }

  validateFile(target) {
    this.fileError = '';
    if (target.files.length === 0) {
      this.fileError = 'Please select Excel file';
      target.value = '';
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = 'Cannot use multiple files';
      target.value = '';
      return false;
    }

    if (!(target.files[0].name.endsWith('.xlsx') || target.files[0].name.endsWith('.xls'))) {
      this.fileError = 'Invalid File format, please select Excel file only.';
      target.value = '';
      return false;
    }

    return true;
  }

  removeSortCharFromKeys(data) {

    const newData = [];
    data.forEach((obj) => {
      const newObj = Object.keys(obj).reduce((result, key) => {
        const newKey = key.replace('↓', '').trim();
        result[newKey] = obj[key];
        return result;
      }, Array.isArray(obj) ? [] : {});
      newData.push(newObj);
    });

    return newData;
  }

  bindExcelData(data) {

    const data1 = [{
      Plant: '1111',
    }];
    const data2 = [
      {
        Code: '11200225',
        Date: '1/1/20',
        Plant: 'Goa 1',
        'P-In-Time': '',
        'P-Out-Time': '15:00',
        Unit: 'Unit-1',
        'U-In-Time': '8:30',
        'U-Out-Time': '15:00',
        // Department: 'Accounts',
        // Section: 'Packing',
        // 'Sub-Section 1': 'FFS',
        // 'Sub-Section 2': 'Line 2',
      }
    ];

    const importData = [];
    for (let index = 0; index < data.length; index++) {

      const element = data[index];
      if (element && Object.keys(element).length > 0) {
        let date = (element['Date'] || '').toString().trim();
        //date = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        date = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
        if (date === 'Invalid date') {
          date = '';
        }

        const plant = (element['Plant'] || '').toString().trim();
        const unit = (element['UNIT'] || '').toString().trim();
        // const department = (element['Department'] || '').toString().trim();
        // const section1 = (element['Section'] || '').toString().trim();
        // const section2 = (element['Sub-Section 1'] || '').toString().trim();
        // const section3 = (element['Sub-Section 2'] || '').toString().trim();

        let plantCode = null;
        let unitCode = null;
        // let departmentCode = null;
        // let sectionPlantRelationId1 = null;
        // let sectionPlantRelationId2 = null;
        // let sectionPlantRelationId3 = null;
        const plantFound = this.plantUnitDepartmentTree.find(item => item.Plant0.toLowerCase() === plant.toLowerCase());
        if (plantFound) {
          plantCode = plantFound.Plantcode0;

          const unitFound = plantFound.Values.find(item => item.Unit0.toLowerCase() === unit.toLowerCase());
          if (unitFound) {
            unitCode = unitFound.Unitcode0;
            // const departmentFound = unitFound.DepartmentTree.find(item => item.Name.toLowerCase() === department.toLowerCase());
            // if (departmentFound) {
            //   departmentCode = departmentFound.UnitDeptRelID;

            //   const section1Found = departmentFound.SectionTree.find(item => item.Name.toLowerCase() === section1.toLowerCase());
            //   if (section1Found) {
            //     sectionPlantRelationId1 = section1Found.SectionPlantRelationId;

            //     const section2Found = section1Found.SectionList.find(item => item.Name.toLowerCase() === section2.toLowerCase());
            //     if (section2Found) {
            //       sectionPlantRelationId2 = section2Found.SectionPlantRelationId;

            //       const section3Found = section2Found.SectionList.find(item => item.Name.toLowerCase() === section3.toLowerCase());
            //       if (section3Found) {
            //         sectionPlantRelationId3 = section3Found.SectionPlantRelationId;
            //       }
            //     }
            //   }
            // }
          }
        }

        importData.push({
          Code: (element['Code'] || '').toString().trim(),
          Date: date,
          Plant_Code: plantCode,
          PInTime: (element['P-In-Time'] || '').toString().trim(),
          POutTime: (element['P-Out-Time'] || '').toString().trim(),
          Unit_Code: unitCode,
          UInTime: (element['U-In-Time'] || '').toString().trim(),
          UOutTime: (element['U-Out-Time'] || '').toString().trim(),
          // Department_Code: departmentCode,
          // SectionPlantRelationId1: sectionPlantRelationId1,
          // SectionPlantRelationId2: sectionPlantRelationId2,
          // SectionPlantRelationId3: sectionPlantRelationId3,
        });

        console.log('importData -------> ', importData);
      }
    }
    this.attendanceData = importData;
    this.gridOptions.api.setRowData(this.attendanceData);
    this.loadingService.hide();
    this.fileInput.nativeElement.value = null;
    this.validateDataByService();
  }

  validateDataByService() {
    const gatePassSet = new Set();
    const inout = new Set();
    const plantin = [];
    const plantout = [];
    const unitin = [];
    const unitout = [];
    this.attendanceData.forEach((item) => {

      if (item.Code) {
        gatePassSet.add(item.Code);
      }
    });

    this.validateGatePass(Array.from(gatePassSet), null, null);
    // this.validateinoutpunch(Array.from(inout), null, null);

    this.attendanceData.forEach((item) => {
      if (item.Code && item.Date && item.PInTime && item.PInTime !== 'Invalid date'
        // tslint:disable-next-line
        && !plantin.find(tmp => tmp.Code === item.Code && tmp.Date === item.Date && tmp.PInTime === item.PInTime)) {
        plantin.push({
          Code: item.Code,
          Date: item.Date,
          PInTime: item.PInTime,
        });
      }

    });
    this.attendanceData.forEach((item) => {
      if (item.Code && item.Date && item.POutTime && item.POutTime !== 'Invalid date'
        // tslint:disable-next-line
        && !plantout.find(tmp => tmp.Code === item.Code && tmp.Date === item.Date && tmp.POutTime === item.POutTime)) {
        plantout.push({
          Code: item.Code,
          Date: item.Date,
          POutTime: item.POutTime,
        });
      }

    });
    this.attendanceData.forEach((item) => {
      if (item.Code && item.Date && item.UInTime && item.UInTime !== 'Invalid date'
        // tslint:disable-next-line
        && !unitin.find(tmp => tmp.Code === item.Code && tmp.Date === item.Date && tmp.UInTime === item.UInTime)) {
        unitin.push({
          Code: item.Code,
          Date: item.Date,
          UInTime: item.UInTime,
        });
      }

    });
    this.attendanceData.forEach((item) => {
      if (item.Code && item.Date && item.UOutTime && item.UOutTime !== 'Invalid date'
        // tslint:disable-next-line
        && !unitout.find(tmp => tmp.Code === item.Code && tmp.Date === item.Date && tmp.UOutTime === item.UOutTime)) {
        unitout.push({
          Code: item.Code,
          Date: item.Date,
          UOutTime: item.UOutTime,
        });
      }

    });

    this.checkInOutTimeValidation();

    this.validateDateAndPinTime(plantin, null, null);
    this.validateDateAndPoutTime(plantout, null, null);
    this.validateDateAndUinTime(unitin, null, null);
    this.validateDateAndUoutTime(unitout, null, null);

  }

  private checkInOutTimeValidation() {
    // this.attendanceData.forEach
    // const isValid = this.attendanceData.find()
  }


  validateAttandanceData() {
    this.attendanceData.forEach(element => {
      element.PInTime = element.PInTime !== 'Invalid date' ? element.PInTime : '';
      element.POutTime = element.POutTime !== 'Invalid date' ? element.POutTime : '';
      element.UInTime = element.UInTime !== 'Invalid date' ? element.UInTime : '';
      element.UOutTime = element.UOutTime !== 'Invalid date' ? element.UOutTime : '';
    });
    this.gridOptions.api.setRowData(this.attendanceData);
    this.loadingService.hide();
    this.fileInput.nativeElement.value = null;
    this.validateDataByService();
  }


  validateData() {
    const returnObj = {
      isValid: true,
      rowIndex: -1,
      field: '',
      msg: ''
    };

    const isValid = true;
    for (let i = 0; i < this.attendanceData.length; i++) {
      const keys = Object.keys(this.attendanceData[i]);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(i, keys[j]);
        if (!validObj.isValid) {
          returnObj.isValid = false;
          returnObj.rowIndex = i;
          returnObj.field = keys[j];
          returnObj.msg = '';
          break;
        }
      }
      if (!returnObj.isValid) {
        break;
      }
    }
    return returnObj;
  }

  getApiRequestData() {

    const data = [];
    this.attendanceData.forEach((row) => {
      const tmpData = {
        UserEmail: this.userEmail,
        UserId: localStorage.getItem('UserID'),
        Code: row.Code,
        ULC: row.ULC,
        ContractorCode: null,
        Date: row.Date,
        Plant_Code: row.Plant_Code ? parseInt(row.Plant_Code, 10) : null,
        InTime: row.PInTime,
        OutTime: row.POutTime,
        Unit_Code: row.Plant_Code ? parseInt(row.Unit_Code, 10) : null,
        InTime1: row.UInTime,
        OutTime1: row.UOutTime,
        // SectionPlantRelId: row.SectionPlantRelationId3 ? parseInt(row.SectionPlantRelationId3, 10) : null, // ?
        isApprove: [UserRoles.PlantHR, UserRoles.HRBP, UserRoles.ER].includes(this.loginUserRoleCode)
      };



      data.push(tmpData);
    });

    return data;
  }

  public formatToServerDate(date) {
    if (date) {
      let a = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (a === 'Invalid date') {
        a = null;
      }
      return a;
    }

    return null;
  }

  saveData() {
    // tslint:disable-next-line: ter-indent

    const valid = this.validateData();
    if (valid.isValid) {
      this.loading = true;
      this.attendanceService.getRoleByUserID().subscribe((res: any) => {
        if (res.Status) {
          this.roleCode = res.Data;
          this.erlogin = this.roleCode.filter(list => list.Role_Code === 120);
          this.hrlogin = this.roleCode.filter(list => list.Role_Code === 118);
          this.uhrbplogin = this.roleCode.filter(list => list.Role_Code === 121);
          this.phrbplogin = this.roleCode.filter(list => list.Role_Code === 122);
          if (this.erlogin.length > 0 || this.hrlogin.length > 0 || this.uhrbplogin.length > 0
            || this.phrbplogin.length > 0) {
            this.attendancemanualpunchService.importManualAttendanceAndApprove(this.getApiRequestData()).subscribe((response) => {
              this.loading = false;
              if (response.Status) {
                this.attendanceData = [];
                this.gridOptions.api.setRowData(this.attendanceData);
                this.closeModal.next(this.attendanceData);
                this.logHelperService.logSuccess({
                  message: 'Manual Punch Approved successfull'
                });
                // this.router.navigate(['attendance/manual-punch']);
                this.save.emit();
                // alert('Attendance data imported successfully.');
              }
              // tslint:disable-next-line: brace-style
              else {
                this.logHelperService.logError({
                  message: 'Error in importing data. Please try later.'
                });
              }
              // else {
              //   alert('Error in importing data. Please try later.');
              // }
            }, (err) => {
              this.loading = false;
              alert('Error in importing data. Please try later.');
            });
          } else {
            this.attendancemanualpunchService.importManualAttendance(this.getApiRequestData()).subscribe((response) => {
              this.loading = false;
              if (response.Status) {
                this.attendanceData = [];
                this.gridOptions.api.setRowData(this.attendanceData);
                this.closeModal.next(this.attendanceData);
                this.logHelperService.logSuccess({
                  message: 'Manual Punch imported successfully'
                });
                // this.router.navigate(['attendance/manual-punch']);
                this.save.emit();
                // alert('Attendance data imported successfully.');
              }
              // tslint:disable-next-line: brace-style
              else {
                this.logHelperService.logError({
                  message: 'Error in importing data. Please try later.'
                });
              }
              // else {
              //   alert('Error in importing data. Please try later.');
              // }
            }, (err) => {
              this.loading = false;
              alert('Error in importing data. Please try later.');
            });

          }
        }
      });
    } else {
      if (valid.msg === '') {
        alert('Please correct data.');
      } else {
        alert(valid.msg);
      }
      setTimeout(() => {
        this.setFocusInput(valid.rowIndex, valid.field);
      }, 2000);
    }
  }

  loadGlobalSettings() {
    // this.importExcelService.getGlobalSettings().then((response) => {
    //   console.log(response.data);
    //   this.isAdhaarMandatory = response.data.ADHAAR01 === 1 ? true : false;
    //   this.isBankMandatory = response.data.BANK01 === 1 ? true : false;
    // }, (err) => {
    //   alert(err);
    // });
  }

  setFormat(value: any) {
    if (value) {
      let rtnValue = value;
      const splitedValue = value.split(':');
      if (splitedValue[0].length !== 2) {
        // tslint:disable-next-line:prefer-template
        rtnValue = '0' + rtnValue;
      }
      return rtnValue;
    }
  }

  revalidateRow(rowIndex, fieldName) {
    if (fieldName) {
      const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
      const rowNodes = [row]; // params needs an array
      // tslint:disable-next-line
      this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    } else {
      this.gridOptions.api.redrawRows();
    }
  }

  validateGatePass(list, rowIndex, fieldName) {
    let newList = list.filter(item => !this.gatePassMap[item]);
    newList = newList.filter(item => !this.gatePassNotFoundSet.has(item));
    if (newList.length === 0) {
      this.revalidateRow(rowIndex, fieldName);
      return;
    }

    this.attendancemanualpunchService.validateGatePass(newList)
      .subscribe((res) => {
        if (res.Data) {
          res.Data.forEach((item) => {
            if (item.IsExist) {
              this.gatePassMap[item.GatePassNo] = item.ULC ? item.ULC : 1;
            } else {
              this.gatePassNotFoundSet.add(item.GatePassNo);
            }
          });
        }

        newList.forEach((item) => {
          if (!this.gatePassMap[item]) {
            this.gatePassNotFoundSet.add(item);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }


  validateDateAndPinTime(list, rowIndex, fieldName) {

    // tslint:disable-next-line: ter-indent
    const newList = list.filter(item => !this.plantin.find(tmp => tmp.Code === item.Code &&
      tmp.Date === item.Date && tmp.PInTime === item.PInTime))
      .map((item) => {
        return {
          Code: item.Code,
          Date: item.Date,
          PInTime: item.PInTime
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.attendancemanualpunchService.validateDateAndPinTime(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const pincode = item.Code.toString();
          const pindate = this.datepipe.transform(item.Date, 'yyyy-MM-dd');
          const pin = item.PInTime ? item.PInTime.replace(/(:\d{2}| [AP]M)$/, '') : null;
          const pinnn = pin ? pin.replace(/^0+/, '') : null;
          // tslint:disable-next-line: max-line-length
          // && (tmp.PInTime === pinnn || tmp.PInTime === pin)
          const listItem = list.find(tmp => tmp.Code === pincode && tmp.Date === pindate && (tmp.PInTime === pinnn || tmp.PInTime === pin));
          if (listItem) {
            listItem['IsValid'] = item.IsValid;
            this.plantin.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateDateAndPoutTime(list, rowIndex, fieldName) {
    // tslint:disable-next-line: ter-indent

    const newList = list.filter(item => !this.plantout.find(tmp => tmp.Code === item.Code &&
      tmp.Date === item.Date && tmp.POutTime === item.POutTime))
      .map((item) => {
        return {
          Code: item.Code,
          Date: item.Date,
          POutTime: item.POutTime
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.attendancemanualpunchService.validateDateAndPoutTime(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const poutcode = item.Code.toString();
          const poutdate = this.datepipe.transform(item.Date, 'yyyy-MM-dd');
          const pout = item.POutTime ? item.POutTime.replace(/(:\d{2}| [AP]M)$/, '') : null;
          const pouttt = pout ? pout.replace(/^0+/, '') : null;
          // tslint:disable-next-line: max-line-length
          // && (tmp.POutTime === pouttt || tmp.POutTime === pout)
          const listItem = list.find(tmp => tmp.Code === poutcode
            && tmp.Date === poutdate && (tmp.POutTime === pouttt || tmp.POutTime === pout));
          if (listItem) {
            listItem['IsValid'] = item.IsValid;
            this.plantout.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }


  validateDateAndUinTime(list, rowIndex, fieldName) {
    // tslint:disable-next-line: max-line-length
    const newwe = list;
    // tslint:disable-next-line: max-line-length
    const newList = list.filter(item => !this.unitin.find(tmp => tmp.Code === item.Code && tmp.Date === item.Date && tmp.UInTime === item.UInTime))
      .map((item) => {
        return {
          Code: item.Code,
          Date: item.Date,
          UInTime: item.UInTime
        };
      });

    if (newList.length === 0) {
      return;
    }
    const a = newList;
    this.attendancemanualpunchService.validateDateAndUinTime(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const uincode = item.Code.toString();
          const uindate = this.datepipe.transform(item.Date, 'yyyy-MM-dd');
          const uin = item.UInTime ? item.UInTime.replace(/(:\d{2}| [AP]M)$/, '') : null;
          const uinn = uin ? uin.replace(/^0+/, '') : null;
          // tslint:disable-next-line: max-line-length
          const listItem = list.find(tmp => tmp.Code === uincode && tmp.Date === uindate && (this.setFormat(tmp.UInTime) === this.setFormat(uinn) || this.setFormat(tmp.UInTime) === this.setFormat(uin)));
          if (listItem) {
            listItem['IsValid'] = item.IsValid;
            this.unitin.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateDateAndUoutTime(list, rowIndex, fieldName) {
    // tslint:disable-next-line: max-line-length
    const newList = list.filter(item => !this.unitout.find(tmp => tmp.Code === item.Code && tmp.Date === item.Date && tmp.UOutTime === item.UOutTime))
      .map((item) => {
        return {
          Code: item.Code,
          Date: item.Date,
          UOutTime: item.UOutTime
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.attendancemanualpunchService.validateDateAndUoutTime(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const uoutcode = item.Code.toString();
          const uoutdate = this.datepipe.transform(item.Date, 'yyyy-MM-dd');
          const uout = item.UOutTime ? item.UOutTime.replace(/(:\d{2}| [AP]M)$/, '') : null;
          const uouttt = uout ? uout.replace(/^0+/, '') : null;
          // tslint:disable-next-line: max-line-length
          const listItem = list.find(tmp => tmp.Code === uoutcode && tmp.Date === uoutdate && (this.setFormat(tmp.UOutTime) === this.setFormat(uouttt) || this.setFormat(tmp.UOutTime) === this.setFormat(uout)));
          // tslint:disable-next-line:max-line-length
          // const listItem = list.find(tmp => tmp.Code === uoutcode && tmp.Date === uoutdate && (this.setFormat(tmp.UOutTime) === datetime || this.setFormat(tmp.UOutTime) === this.setFormat(uout)));
          if (listItem) {
            listItem['IsValid'] = item.IsValid;
            this.unitout.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  getUserplantDetail(){
    this.attendanceService.getCommonSpUserPlantAcces().subscribe((res) => {
      // console.log("user plant res ----->",res)
      this.userplantDetail = _.uniqBy(res, 'State')
      this.allPlantsUser = res;
      // console.log("userplantDetail ----->",this.userplantDetail)
    },(err) => {
      console.log("user plant err --->",err)
    })
  }

  getGlobalSettings(){
      this.attendanceService.globalSettingCode('').subscribe((res:any) => {
        // console.log("global setting res ---->",res)
        this.globalSettingData = res.Data.Table.filter((i) => i.Code == 'MOD115PG352EXCELPUNCH')
        this.globalDataSampleDownload = res.Data.Table.filter((i) => i.Code == 'MOD101IMPEXCL')
        console.log("globalDataSampleDownload --->",this.globalDataSampleDownload)
      },(err) => {
        console.log("global setting err --->",err)
      })
  }
}
