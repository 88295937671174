import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-work-order-grid-wrapper',
  templateUrl: './work-order-grid-wrapper.component.html',
  styleUrls: ['./work-order-grid-wrapper.component.css']
})
export class WorkOrderGridWrapperComponent implements OnInit {

  rowClassRules;
  // @Input() gridOptions;
  @Input() columnDefs = [];
  @Input() rowData = [];
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() printClick: EventEmitter<any> = new EventEmitter();
  @Output() emailClick: EventEmitter<any> = new EventEmitter();
  @Output() openClick: EventEmitter<any> = new EventEmitter();
  numberOfRowsPerPage = 10;
  public isVisible = true
  @Input() showSearchBox: boolean = false;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  gridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: node => node.data.isVisible,
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
      this.gridReady.next(params);
    },
    onGridSizeChanged: (params) => {
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
    },
    onColumnResized: (params) => {
      params.api.resetRowHeights();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 50,
    quickFilterText: '',
  };

  onFilterTextBoxChanged() {
    let searchtext = (document.getElementById("filter-text-box") as HTMLInputElement).value;
    if (searchtext) {
      this.gridOptions.quickFilterText = searchtext;
      this.isVisible = false
      setTimeout(() => {
        this.isVisible = true
      }, 1);
    }else{
      this.isVisible = false
      setTimeout(() => {
        this.isVisible = true
      }, 1);
    }
  }
  updateNumberOfRowsPerPage() {
    this.gridAPI.paginationSetPageSize(this.numberOfRowsPerPage);
    this.gridAPI.sizeColumnsToFit();
  }

  constructor() { }

  ngOnInit() {
    this.gridOptions.quickFilterText = '';
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };
  }
  onEdit(params) {
    this.editClick.emit(params);
  }

  onPrint(params) {
    this.printClick.emit(params);
  }

  onDelete(params) {
    this.deleteClick.emit(params);
  }

  onEmail(params) {
    this.emailClick.emit(params);
  }

  modelOpen(params) {
    this.openClick.emit(params);
  }
}
