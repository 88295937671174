import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { VisitorManagementService } from '../visitor-management.service';
import { FileDoc, FileUploadService } from 'src/app/core/components';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { PrintRequestComponent } from '../print-request/print-request.component';

let fileToReturn: File;
@Component({
  selector: 'app-visitor-form-modal',
  templateUrl: './visitor-form-modal.component.html',
  styleUrls: ['./visitor-form-modal.component.css']
})
export class VisitorFormModalComponent implements OnInit{
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('tempRef1') public tempRef1: any;
  @ViewChild('tempRefApproval') public tempRefApproval: any;
  @ViewChild('uploadDocumentTemplateRef') public uploadDocumentTemplateRef: any;
  @ViewChild('takephoto') public takephoto: any;
  @ViewChild('registerface') public registerface: any;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('signature') public signature: ElementRef<any>;
  @Input() public genderTypes: any;

  otp: any;

  visitorForm = new FormGroup({
    Company_Code: new FormControl('', Validators.required),
    Plant_Code: new FormControl('', Validators.required),
    Unit_Code: new FormControl(''),
    Department_Code: new FormControl(''),
    yourcompany: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    jobtitle: new FormControl('', Validators.required),
    mobileno: new FormControl('', Validators.required),
    pgender: new FormControl(''),
    emailid: new FormControl('', Validators.required),
    otp: new FormControl('', Validators.required),
    emergencycontactname: new FormControl('', Validators.required),
    emergencycontactno: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    address1: new FormControl('', Validators.required),
    zipcode: new FormControl('', Validators.required),
    PCountry_Code: new FormControl('', Validators.required),
    PState_Code: new FormControl('', Validators.required),
    PDistrict_Code: new FormControl('', Validators.required),
    PCity_Code: new FormControl('', Validators.required),
    PPostOffice_Code: new FormControl('', Validators.required),
    LVillage_Code: new FormControl('', Validators.required),
    visitorcategoryasterList_Code: new FormControl(''),
    TypeOfIdentityMasterItem_Code: new FormControl(''),
    addharNo: new FormControl(''),
    drvingLicense: new FormControl(''),
    electionCard: new FormControl(''),
    breanginglaptop1: new FormControl(''),
    date: new FormControl('', Validators.required),
    starttime: new FormControl('', Validators.required),
    Enddate: new FormControl('', Validators.required),
    endtime: new FormControl('', Validators.required),
    actualdate: new FormControl('', Validators.required),
    actualtime: new FormControl('', Validators.required),
    temp: new FormControl('', Validators.required),
    totannumber: new FormControl(''),
    lockernumber: new FormControl(''),
    visitorpurposeMasterList_Code: new FormControl('', Validators.required),
    purposeofvisit1: new FormControl(''),
    mobileallowed: new FormControl(''),
    havematchbox: new FormControl(''),
    laptopallowed: new FormControl(''),
    havetoolkit: new FormControl(''),
    name1: new FormControl(''),
    identitytype: new FormControl(''),
    mobilenumber: new FormControl(''),
    pemailid: new FormControl(''),
    pjobtitle: new FormControl(''),
    Gender: new FormControl(''),
    ptemp: new FormControl(''),
    iteamtype: new FormControl(''),
    iteamdescription: new FormControl(''),
    serialno: new FormControl(''),
    isreturnable: new FormControl(''),
  });
  employeeDetailForm = new FormGroup({
    searchedKeyword: new FormControl(''),
  });
  approvalForm = new FormGroup({
    statusMasterItem_Code: new FormControl(''),
    acceptingorrejectingremark: new FormControl(''),
    instructiontoreception: new FormControl(''),
    allwedlaptop: new FormControl(''),
    allwedmobile: new FormControl(''),
  });
  docForm = new FormGroup({
  });

  public datePickerPickerMode: string;
  public datePickerPlaceHolder: string;
  public companyData: any[] = [];
  public plants: any[] = [];
  public units: any[] = [];
  public departmentData: any[] = [];
  pcountries = [];
  pStates = [];
  pDistricts = [];
  pCities = [];
  pPos = [];
  pVillages = [];
  lVillages = [];
  editipersonIndex = -1;
  public activeref: any;
  hideUpdateOtherPerson = true;
  OtherPersonDetailViews = [];
  teashow = false;
  show = false;
  editshiftCode1: string;
  optionsChecked = [];
  iteamsViews = [];
  photopath: any;
  uploadedPhoto: FileDoc[] = [];
  uploadedFiles: FileDoc[] = [];
  public modalRef: any;
  allData: any;
  croppedImage: any = '';
  savedCroppedImage: any = '';
  imageChangedEvent: any = '';
  photoname: any;
  showCropper = false;
  savedSignatueImage: any = '';
  signaturePadConfigration:any= {
    png:true,
    svg:true,
    jpg:true,
    undo:true
  }
  printBtn = false
  documentTypes: any;
  files: any;

  constructor(private sharedService: SharedService,
    public sharedDataService: SharedDataService,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private service: VisitorManagementService,
    private fileUploadService: FileUploadService,
    private logHelperService: LogHelperService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
    ) {
      this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
      this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
      this.getDocumentTypes()
     }

  ngOnInit() {
    this.getCompanyData();
    this.getPlants();
    this.getUnits();
    this.getDepartmentData();
    this.getCountry();
  }

  getDocumentTypes() {
    this.service.getDocumentTypes().subscribe((res: any) => {
      this.documentTypes = res.Data;
    });
  }

  closeModal() {
    this.activeModal.close(); // This will close the modal when called.
  }

  getCompanyData() {
    return this.service.getCompanyMasterData().subscribe((response: any) => {

      this.companyData = response;
      if (this.companyData.length === 1) {
        this.visitorForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);

      } else {
        this.companyData = response;
      }
    });
  }

  getPlants() {
    return this.service.getPlantMasterData().subscribe((response: any) => {
      this.plants = response;
      if (this.plants.length === 1) {
        this.visitorForm.controls['Plant_Code'].setValue(this.plants[0].Plant_Code);
      } else {
        this.plants = response;
      }
    });
  }

  getUnits() {

    return this.service.getUnitMasterData(this.visitorForm.controls['Plant_Code'].value || 100).subscribe((res: any) => {
      this.units = res.Data.Table;
      if (this.units.length === 1) {
        this.visitorForm.controls['Unit_Code'].setValue(this.units[0].Unit_Code);
      } else {
        this.units = res.Data.Table;
      }
    });
  }
  getCountry() {
    return this.service.getCountry().subscribe((response: any) => {
      this.pcountries = response;
    });
  }
  getDepartmentData() {

    // tslint:disable-next-line: max-line-length
    return this.service.getDepartmentData(this.visitorForm.controls['Unit_Code'].value || 100).subscribe((res: any) => {
      this.departmentData = res.Data.Table;
    });
  }

  getStatesByCountryIdP() {

    return this.service.getStatesByCountryId(this.visitorForm.controls['PCountry_Code'].value || 100)
      .subscribe((response: any) => {
        this.pStates = response;
      });
  }
  getDistrictsByStateP() {
    this.service.getDistrictsByState(this.visitorForm.controls['PState_Code'].value || 100).subscribe((response: any) => {
      this.pDistricts = response;
    });
  }

  getCitiesByDistrictP() {
    this.service.getCitiesByDistrict(this.visitorForm.controls['PDistrict_Code'].value || 100).subscribe((response: any) => {
      this.pCities = response;
    });
  }
  getPostOfficeByCityP() {
    this.service.getPOsByCity(this.visitorForm.controls['PCity_Code'].value || 100).subscribe((res: any) => {
      this.pPos = res;
    });
  }
  getVillageP() {
    this.service.getVillageByPostOffice(this.visitorForm.controls['PPostOffice_Code'].value || 100).subscribe((response: any) => {
      this.lVillages = response.Data.Table;
    });
  }
  public onvisitordetail(): void {
    this.clearData();
    this.closeModal();
  }

  generateOTP() {
    const digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    this.otp = OTP;
    setTimeout(() => {
      this.otp = '';
    }, 60000);
  }

  // tslint:disable-next-line: function-name
  AddOtherPersonDetail(index?: number) {

    // tslint:disable-next-line: max-line-length
    if (this.visitorForm.controls.name1.value != null && this.visitorForm.controls.Gender.value != null && this.visitorForm.controls.pjobtitle.value != null) {
      if (typeof index !== 'undefined') {
        this.OtherPersonDetailViews[index] = {
          Name: this.visitorForm.controls.name1.value,
          pjobtitle: this.visitorForm.controls.pjobtitle.value,
          mobilenumber: this.visitorForm.controls.mobilenumber.value,
          Gender: this.visitorForm.controls.Gender.value,
          ptemp: this.visitorForm.controls.ptemp.value,
        };
        this.visitorForm.controls['name1'].reset();
        this.visitorForm.controls['pjobtitle'].reset();
        this.visitorForm.controls['mobilenumber'].reset();
        this.visitorForm.controls['Gender'].reset();
        this.visitorForm.controls['ptemp'].reset();

      } else {
        this.OtherPersonDetailViews.push({
          Name: this.visitorForm.controls.name1.value,
          JobTitle: this.visitorForm.controls.pjobtitle.value,
          MobileNo: this.visitorForm.controls.mobilenumber.value,
          GenderMasterItem_Code: this.visitorForm.controls.Gender.value,
          Temperature: this.visitorForm.controls.ptemp.value,
          EmailID: this.visitorForm.controls.pemailid.value,
          IdentityType_Code: this.visitorForm.controls.identitytype.value,

        });
        this.visitorForm.controls['name1'].reset();
        this.visitorForm.controls['pjobtitle'].reset();
        this.visitorForm.controls['mobilenumber'].reset();
        this.visitorForm.controls['Gender'].reset();
        this.visitorForm.controls['ptemp'].reset();
        this.visitorForm.controls['pemailid'].reset();
        this.visitorForm.controls['identitytype'].reset();
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Name , JobTitle , Gender First'
      });
    }
    return;
  }
  editiperson(index) {
    this.editipersonIndex = index;
    this.hideUpdateOtherPerson = false;
    this.visitorForm.controls['name1'].setValue(this.OtherPersonDetailViews[index].Name);
    this.visitorForm.controls['pjobtitle'].setValue(this.OtherPersonDetailViews[index].JobTitle);
    this.visitorForm.controls['mobilenumber'].setValue(this.OtherPersonDetailViews[index].MobileNo);
    this.visitorForm.controls['Gender'].setValue(this.OtherPersonDetailViews[index].GenderMasterItem_Code);
    this.visitorForm.controls['ptemp'].setValue(this.OtherPersonDetailViews[index].Temperature);
    this.visitorForm.controls['pemailid'].setValue(this.OtherPersonDetailViews[index].EmailID);
    this.visitorForm.controls['identitytype'].setValue(this.OtherPersonDetailViews[index].IdentityType_Code);
  }
  updateiPersonDetail() {
    this.AddOtherPersonDetail(this.editipersonIndex);
  }
  deleteperson(index) {
    this.OtherPersonDetailViews.splice(index, 1);
    this.hideUpdateOtherPerson = true;
  }

  private clearData() {
    this.visitorForm.reset();
    this.employeeDetailForm.reset();
    this.approvalForm.reset();

    this.teashow = false;
    this.show = false;
  }
  public SubmitRequest(): void {
    Object.keys(this.visitorForm.controls).forEach(key => {
      this.visitorForm.get(key).markAsTouched();
    });
    if(!this.imageChangedEvent) {
      return alert("Please upload image")
    }
    if(!this.files) {
      alert("Please upload document");
      return
    } else {
      let validDoc = false
      for(let i=0; i<this.files.length; i++) {
        if(this.files[i].documentTypeCode == 108 || this.files[i].documentTypeCode == 117) {
          validDoc = true
        }
      }
      if(!validDoc) {
        alert("Please upload valid document")
        return
      }
    }
    if(this.visitorForm.value.otp != this.otp || this.visitorForm.invalid) return;
    console.log('form data open modal', this.visitorForm.getRawValue());
    const value = this.visitorForm.getRawValue();
    const value1 = this.employeeDetailForm.getRawValue();
    //if (this.optionsChecked.length > 0 && this.optionsChecked.length <= 1) {
      const data: any = {
        Shift_Code: this.editshiftCode1,
        Company_Code: value.Company_Code,
        Plant_Code: value.Plant_Code,
        Unit_Code: value.Unit_Code,
        Department_Code: value.Department_Code,
        VisitorCompany: value.yourcompany,
        FullName: value.name,
        JobTitle: value.jobtitle,
        MobileNo: value.mobileno,
        GenderMAsterItem_Code: value.pgender,
        EmailID: value.emailid,
        OTP: value.otp,
        EmergancyContactName: value.emergencycontactname,
        EmergancyContactNo: value.emergencycontactno,
        Address1: value.address,
        Address2: value.address1,
        ZipCode: value.zipcode,
        Country_Code: value.PCountry_Code,
        State_Code: value.PState_Code,
        District_Code: value.PDistrict_Code,
        City_Code: value.PCity_Code,
        PostOffice_Code: value.PPostOffice_Code,
        Village_Code: value.LVillage_Code,
        VisitorCategory_Code: value.visitorcategoryasterList_Code,
        IDProofType_Code: value.TypeOfIdentityMasterItem_Code,
        AddharNo: value.addharNo,
        DrivingLicenceNo: value.drvingLicense,
        ElectionCardNo: value.electionCard,
        BringLaptop: value.breanginglaptop1,
        StartTime: value.starttime,
        StartDate: value.date,
        EndDate: value.Enddate,
        EndTime: value.endtime,
        ActualDate: value.actualdate,
        ActualTime: value.actualtime,
        Temperature: value.temp,
        TokenNumber: value.totannumber,
        LockerNumber: value.lockernumber,
        VisitorPurposeType_Code: value.visitorpurposeMasterList_Code,
        Visitorpurpose: value.purposeofvisit1,
        HaveMobile: value.mobileallowed,
        HaveToolkit: value.havetoolkit,
        HaveLaptop: value.laptopallowed,
        HaveMatchBox: value.havematchbox,
        CreatedBy: localStorage.getItem('UserID'),
        RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
        RequestRefCode: this.optionsChecked,
        OtherPersonDetailViews: this.OtherPersonDetailViews,
        iteamsViews: this.iteamsViews,
      };
      const uploadParams = {
        Visitor_Code: ''
      };
      if (this.photopath) {
        this.uploadedPhoto = this.photopath;
      }
      // if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.SendRequest(data).subscribe((response: any) => {
          uploadParams.Visitor_Code = response.Data;
          if (uploadParams.Visitor_Code) {
            this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.VISITOR_MANAGEMENT.UPLOAD_DOC)
              .subscribe((res) => {
                this.logHelperService.logSuccess({
                  message: 'Request Submitted successfully'
                });
                // this.getAllVisitor();
                this.docForm.reset();
                this.clearData();
                this.modalRef.close();
                // this.closeModal();
                // this.activeref.close();
                this.docForm.reset();
              });
          }
          if (uploadParams.Visitor_Code) {
            this.fileUploadService.uploadPhoto(this.uploadedPhoto, uploadParams, GLOBAL.APIS.VISITOR_MANAGEMENT.UPLOAD_VISITOR_PHOTO)
              .subscribe((res) => {
                this.logHelperService.logSuccess({
                  message: 'Request Submitted successfully'
                });
                // this.getAllVisitor();
                this.docForm.reset();
                this.clearData();
                // this.modalRef.close();
                // this.closeModal()
                // this.activeref.close();
                this.docForm.reset();
              });
          }
          this.logHelperService.logSuccess({
            message: 'Request Submitted successfully'
          });
          // this.getAllVisitor();
          this.clearData();
          // this.modalRef.close();
          // this.closeModal();
          // this.activeref.close();
          this.printBtn = true
          this.cdRef.detectChanges()
          this.docForm.reset();

        });
      }
    // } else {
    //   this.logHelperService.logError({
    //     message: 'Please Select Only One Employee '
    //   });
    // }
  }
  employeeDetail() {

    return this.service.getEmployeeDetail().subscribe((res: any) => {
      this.allData = res;
    });
  }
   // tslint:disable-next-line: function-name
   UploadDoc(): void {

    this.modalRef = this.modalService.open(this.uploadDocumentTemplateRef,
      { size: 'lg', backdrop: 'static', keyboard: false });
  }

  TakePhoto(): void {

    this.modalRef = this.modalService.open(this.takephoto);
  }
  RegisterFace(): void {
    this.modalRef = this.modalService.open(this.registerface);
  }
  Host() {
    console.log('form data open modal', this.visitorForm.getRawValue());
    const value = this.visitorForm.getRawValue();
    if (value.Company_Code != null && value.Plant_Code != null && value.yourcompany != null
      && value.name != null && value.jobtitle != null && value.emailid != null
      && value.otp != null && value.emergencycontactname != null && value.emergencycontactno != null && value.address != null
      && value.zipcode != null && value.PCountry_Code != null && value.PState_Code != null && value.PDistrict_Code != null
      && value.date != null && value.starttime != null && value.Enddate != null && value.endtime != null
      && value.temp != null) {
      this.modalRef = this.modalService.open(this.tempRef1,
        { size: 'lg', backdrop: 'static' });
      this.employeeDetail();
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotory Fields First'
      });
    }
  }
  saveCroppedImage(type=null): void {
    this.savedCroppedImage = this.croppedImage;
    if(type == 'uploadDoc') {
      if(!this.files) {
        alert("Please upload document")
        return
      }
      let validDoc = false
      for(let i=0; i<this.files.length; i++) {
        if(this.files[i].documentTypeCode == 108 || this.files[i].documentTypeCode == 117) {
          validDoc = true
        }
      }
      if(!validDoc) {
        alert("Please upload valid document")
        return
      }
    } else if(type == 'capturePhoto') {
      if(!this.imageChangedEvent) {
        return alert("Please upload image")
      }
    }
    this.modalRef.close();
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    );
    this.photopath = fileToReturn;
    this.photoname = this.imageChangedEvent.target.files[0].name;
    return fileToReturn;
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
    this.showCropper = true;
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  rotateLeft(imageCropper: ImageCropperComponent) {
    imageCropper.rotateLeft();
  }
  rotateRight(imageCropper: ImageCropperComponent) {
    imageCropper.rotateRight();
  }
  flipHorizontal(imageCropper: ImageCropperComponent) {
    imageCropper.flipHorizontal();
  }
  flipVertical(imageCropper: ImageCropperComponent) {
    imageCropper.flipVertical();
  }

  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed() {
    console.log('Load failed');
  }

  TakeSignature(): void {
    this.modalRef = this.modalService.open(this.signature,{
      backdrop: 'static',
      keyboard: false
    });
  }

  saveSignature(dataURL: string) {
    this.savedSignatueImage = dataURL;
    this.modalRef.close();
  }

  printRequest() {
    window.print();
    // this.activeref = this.modalService.open(PrintRequestComponent, { size: 'lg', backdrop: 'static' });
  }

  onFilesChanged(event) {
    this.files = event;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      description: '',
      price: ''
    });
  }
}
