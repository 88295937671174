import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WageRegisterFromBComponent } from "./wage-register-from-b/wage-register-from-b.component";
const routes: Routes = [
  { path: "", redirectTo: "wage-register-from-B", pathMatch: "full" },
  { path: "wage-register-from-B", component: WageRegisterFromBComponent },
  {
    path: 'loan-n-recoveries-register-from-c',
    loadChildren: './loan-n-recoveries-register-from-c/loan-n-recoveries-register-from-c.module#LoanNRecoveriesRegisterFromCModule',
  },
  {
    path: 'pay-slip',
    loadChildren: './pay-slip/pay-slip.module#PaySlipModule',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CentralReportsRoutingModule {}
