import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
declare let $;

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {

  @Input() title: string;
  @Input() open = true;
  @ViewChild('accordionBody') accordionBody: ElementRef;
  constructor() { }

  ngOnInit() {
  }

  toggleAccordion() {
    $(this.accordionBody.nativeElement).slideToggle();
  }

}
