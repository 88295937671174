import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from 'src/app/app.globals';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EntryfordayAdapter } from './adapter/entryforday.adapter';

@Injectable()
export class DailyAttendanceService {

  constructor(
    private http: HttpClient
  ) { }

  public saveFreezeData(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.HRMS_SP_ATT_Freeze_N_UnFreezDailyAttendance}`;
    return this.http.post<any[]>(url, data);
  }
}
