import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../app.globals';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class FaceRecognitionUIService {

  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
  ) {


  }
  getAllLabourWeekOffs() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_All_LABOURWEEKOFF_DATA}?company_Code=&plant_Code=&labour_Code=`,
      // 'http://localhost:59557/api/Master/GetShiftGroup?Search=&PlantCode=',
      httpOptions
    );
  }
  getAllTrainedData() {
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.FACE_RECOGNITION_API.GET_ALL_TRAINED_DATA}`,
      // 'http://localhost:59557/api/Master/GetShiftGroup?Search=&PlantCode=',
      httpOptions
    );
  }

  addFaceIdentityDetails(data) {

    const paylaod = {
      "Reference_Code":data.Reference_Code,
      "Page_Code":data.Page_Code,
      "Name":data.Name,
      "Descriptors":data.Descriptors,
      "System":data.System,
      "ISCommon" :1,
      "LogedInID" :GLOBAL.USER_ID,
      "ISActiveStatus" : 1, // 0: Deactivate / Desable , 1: Active / Enable , 2: Don't Need to Check status
      "RequireType" :  0,
      "ActionType ": 0, // 0: Insert | 1: Update 
      "UserID" :GLOBAL.USER_ID
    }
    const url = `${GLOBAL.APIS.FACE_RECOGNITION_API.ADD_UPDATE_FACEIDENTITY_DETAIL}`;
    return this.httpClient.post<any>(url, paylaod, httpOptions).pipe(
      map((response: any) => response.Data.Table));
  }

}

