import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import * as faceApi from '../../../assets/Scripts/face-api.min.js';

@Component({
  selector: 'app-face-file',
  templateUrl: './face-train-file.component.html',
  styleUrls: ['./face-train-file.component.css']
})

export class FaceTrainImageFileComponent implements OnInit, OnDestroy {
  @ViewChild('file') file: ElementRef;
  private labels: string;
  private systems: string;
  detections: any;
  @Input() set label(label: string) {
    if (label) {
      this.labels = label;
    }
  }
  get label(): string {
    return this.labels;
  }

  @Input() set system(system: string) {
    if (system) {
      this.systems = system;
    }
  }
  get system(): string {
    return this.systems;
  }

  @Output() getTrainData = new EventEmitter();

  isModuleLoaded: boolean;
  isTrainData: boolean;

  constructor() { }

  async ngOnInit() {

    this.isTrainData = false;
    try {
      this.isModuleLoaded = false;
      await this.loadModule();
      this.isModuleLoaded = true;
    } catch (error) {
      alert('fail to load model');
    }
  }

  emitTrainData(value) {
    this.getTrainData.emit(value);
  }

  async uploadImage(e) {
    this.isTrainData = false;
    if (e.target.files[0]) {
      const image = await faceApi.bufferToImage(e.target.files[0]);
      try {
        this.detections = await faceApi.detectSingleFace(image).withFaceLandmarks().withFaceDescriptor();
        console.log('label', this.labels, this.label);
        if (this.detections) {
          if (this.labels) {
            const labeledFaceDescriptorsObj = new faceApi.LabeledFaceDescriptors(this.labels, [this.detections.descriptor]);
            this.emitTrainData({ ...labeledFaceDescriptorsObj, system: this.systems });
            this.isTrainData = true;
          } else {
            alert('plz add label');
          }
        } else {
          alert('Photo is not clear so plz try again!!');
        }
      } catch (error) {
        alert('fail to train image');
      }
    } else {
      alert('please select file');
    }
  }
  ngOnDestroy() {
  }
  async loadModule() {
    await faceApi.nets.faceRecognitionNet.loadFromUri('assets/models');
    await faceApi.nets.faceLandmark68Net.loadFromUri('assets/models');
    await faceApi.nets.ssdMobilenetv1.loadFromUri('assets/models');
    await faceApi.nets.tinyFaceDetector.loadFromUri('assets/models');
  }
}
