import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'week'
})
export class WeekPipe implements PipeTransform {

  days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

  transform(value: any): any {

    return this.days[new Date(value).getDay()];
  }

}
