import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular/dist/interfaces";

@Component({
  selector: "total-value-component",
  template: `
      <button (click)="buttonClicked()" type="button" class="btn btn-info btn-sm">Edit</button>
  `,
})
export class editButtonRenderer implements ICellRendererAngularComp {
  public cellValue!: string;
  public params;


  agInit(params): void {
    this.params=params;
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }


  getValueToDisplay(params) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
