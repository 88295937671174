import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-ag-datepicker-edit-cell-renderer',
  templateUrl: './ag-datepicker-edit-cell-renderer.component.html',
  styleUrls: ['./ag-datepicker-edit-cell-renderer.component.css'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }
  ]
})
export class AgDatePickerEditCellRendererComponent implements OnInit, ICellRendererAngularComp {
  params;
  isValid = true;
  invalidMsg = '';
  inputClass = 'cellInput';
  width = 110;
  constructor(
    public sharedService: SharedService,
  ) { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
    this.width = this.params.width ? (this.params.width - 10) : 110;
    this.validateColumn();
  }

  refresh() {
    return true;
  }

  onValueChange($event) {
    this.params.data[this.params.column.colId] = $event.target.value;
    if (this.params.forceSelection) {
      const forceSelection = this.params.forceSelection($event.target.value);
      if (forceSelection) {
        this.params.data[forceSelection.field] = forceSelection.value;
      }
    }

    this.params.onInputTextChange(this.params.rowIndex, this.params.column.colId);
  }

  onDateChange(newValue) {
    this.params.data[this.params.column.colId] = moment(newValue).format('YYYY-MM-DD');
    this.params.onInputTextChange(this.params.rowIndex, this.params.column.colId);
  }

  validateColumn() {
    const obj = this.params.isColValid(this.params.rowIndex, this.params.column.colId);
    this.isValid = obj.isValid;
    this.inputClass = 'cellInput';
    this.invalidMsg = '';
    if (!obj.isValid) {
      this.inputClass += ' invalid-cell-input';
      this.invalidMsg = obj.msg;
    }
  }

  isDisable() {
    if (this.params.isDisable) {
      return this.params.isDisable(this.params);
    }
    return false;
  }
}
