export class GatePassAccociateInformation {
  contractorType: ContractorType;
}

export class GatepassRequestProcess {
  requestType?: number;
  Plant?: string;
  SubPlant?: string;
  WorkOrderNo?: number;
  ContractorVendor_Code?: number;
  SubContractor_Code?: number;
  Plant_Code?: number;
  contractorType?: ContractorType;
  WorkOrder_Code?: number;
  SubWorkOrder_Code?: number;
  SubWorkOrderNo?: string;
  License_Code?: number;
  NewLicense_Code?: number;
  SubLicense_Code?: number;
  SubNewLicense_Code?: number;
  ECPolicy_Code?: number;
  NewECPolicy_Code?: number;
  Company?: string;
  jobType?: string;
  labourCoverType?: string;
  actions?: string;
  labourCodes?: any[];
  SubCompany?: string;
  Role_Code?: string;
  isSubContractorFlowStarted?: boolean;
  isSkipWCPolicy?: boolean;
  isSkipLicense?: boolean;
  Remaining?: any;
}

export enum ContractorType {
  Contractor = 'CONTRACTORS_MAN',
  SubContractor = 'SUB_CONTRACTORS_MAN'
}

export enum GatePassRequestType {
  New = 103,
  Renew = 104,
  Cancel = 105
}