import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-man-power-filter-listing',
  templateUrl: './man-power-filter-listing.component.html',
  styleUrls: ['./man-power-filter-listing.component.css']
})
export class ManPowerFilterListingComponent implements OnInit {
  public text: any;
  public subject: Subject<any>;
  public manPowerFilterList: any;
  constructor() {
    this.subject = new Subject();
  }

  public ngOnInit(): void {
    this.rowDataList();
  }

  public setFilterItem(filterItem: any): void {
    this.subject.next(filterItem);
  }

  private rowDataList(): void {
    this.manPowerFilterList = [
      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'todo',
        make: 'sfhagdsh',
        Unit_Code: 'teoeow',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'reveng',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'goers',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'jyource',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'furnies',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'jackes',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'selvins',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'sufiest',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'cogoes',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'tedies',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      },

      {
        Labour_Code: 'asgdhsgd',
        Plant_Code: 'jellty',
        Unit_Code: 'teoeow',
        make: 'sfhagdsh',
        Department_Code: 'departmentcode',
        Shift_Code: 'assdadsda',
        OutDateTime_Code: 'wewqeqwewqe'
      }
    ];
  }

}
