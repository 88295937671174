import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, ComponentRef, Renderer2, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectStepsComponent } from '../select-steps/select-steps.component';
import { ModalConfig, BackdropColor, ModelDialogClass } from './select-steps-sequence-filter.model';
import { AgNumberCountComponent } from '../grid-wrapper/helper/ag-number-count/ag-number-count.component';
import { GridApi, GridOptions } from 'ag-grid-community';
import { SelectStepsFilterService } from '../../services/select-steps-filter.service';
import {
  AgSelectActionCellRendererComponent
} from '../grid-wrapper/helper/ag-select-action-cell-renderer/ag-select-action-cell-renderer.component';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SelectStepsService } from '../select-steps/select-step.service';
import { AdvanceFilterDynamicComponent } from '../select-steps-filter-wrapper/advance-filter-dynamic/advance-filter-dynamic.component';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AdvanceFilterTypeConditionComponent
} from '../select-steps-filter-wrapper/advance-filter-type-condition/advance-filter-type-condition.component';
import { OverlayRef, OverlayPositionBuilder, Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-select-steps-sequence-filter-wrapper',
  templateUrl: './select-steps-sequence-filter-wrapper.component.html',
  styleUrls: ['./select-steps-sequence-filter-wrapper.component.css']
})

export class SelectStepsSequenceFilterWrapperComponent implements OnInit {
  @ViewChild('advanceFilter') public elementRef: ElementRef;
  @ViewChild('reviewStyle') public reviewStyleRef: ElementRef;
  @ViewChild('review') public reviewRef: ElementRef;
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  @ViewChild('filterTemplateRef') public filterTemplateRef: TemplateRef<HTMLElement>;
  @ViewChild('advanceTypeConditionFilter') public advanceTypeConditionElementRef: ElementRef;
  @ViewChild('active') public activeRef: ElementRef;
  @ViewChild('activeStyle') public activeStyleRef: ElementRef;
  @ViewChild('deactivate') public deactivateRef: ElementRef;
  @ViewChild('deactivateStyle') public deactivateStyleRef: ElementRef;
  @ViewChild('debarred') public debarredRef: ElementRef;
  @ViewChild('debarredStyle') public debarredStyleRef: ElementRef;
  // @ViewChild('selectedContractorRef') public contractorRef: TemplateRef<HTMLElement>;
  private overlayRef: OverlayRef;
  private advanceTypeConditionOverlayRef: OverlayRef;
  public fontPixel = '30px';
  private isShowFilter: boolean;
  public isActive: boolean;
  public stepsTitleName: string;
  public isDeactivate: boolean;
  public isDebarred: boolean;
  public isReview: boolean;
  private isShowTypeConditionFilter: boolean;
  public stepsData: any[];
  public stepsForm: FormGroup;
  public formGroup: FormGroup;
  public role: any;
  public rowFilterData: any[];
  public requestType: number;
  private licenceData: any;
  private isLicenceData: boolean;
  private isShiftData: boolean;
  private licenseCode: any;
  private workOrderData: any;
  private isWorkOrderData: boolean;
  /** Hold form group of new get pass */
  public contactorForm: FormGroup;
  /** Hold boolean value */
  public isSelectedContractor: boolean;
  /** Hold boolean value isSelectLicencePrevCallOnce */
  public isSelectLicencePrevCallOnce: boolean;
  /** Hold boolean value */
  public isSelectedContractorAndWorkOrder: boolean;
  /** Hold boolean value of isSelectedContractorWorkOrderAndLicense */
  public isSelectedContractorWorkOrderAndLicense: boolean;
  /** Here this variable hold the value of `selected contractor` */
  public selectedContractor: string;
  /** Here this variable hold the value of `selected company` */
  public selectedCompanyName: string;
  /** Here this variable hold the value of `selectedWorkerOrderNo` */
  public selectedWorkOrderNo: string;
  /** Here this variable hold the value of `selectedWorkerOrderCode` */
  public selectedWorkOrderCode: string;
  /** Here this variable hold the value of `selectedWorkerOrderNo` */
  public selectedWorkerOrderNo: string;
  /** Here this variable hold the value of `selectedLicense` */
  public selectedLicense: string;
  public shiftData: any;
  constructor(
    private formBuilder: FormBuilder,
    private service: SelectStepsFilterService,
    private selectStepService: SelectStepsService,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef) {
    this.stepsData = [];
    this.role = 111;
    this.isActive = false;
    this.isDeactivate = false;
    this.stepsTitleName = null;
    this.isDebarred = false;
    this.isReview = false;
    this.isShowFilter = false;
    this.requestType = 104;
    this.isShiftData = false;
    this.isLicenceData = false;
    this.isSelectedContractor = false;
    this.isSelectedContractorAndWorkOrder = false;
    this.isSelectedContractorWorkOrderAndLicense = false;
  }

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };

  public gridColumnDefs = [
    {
      headerName: '#', field: '',
      width: 50, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Vendor', field: 'ShortCode',
      width: 150, filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Contractor', field: 'Company', width: 450, filterParams:
        { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true
    },
    {
      headerName: 'Email Id', field: 'EmailID', width: 450, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 70, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: true, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public workOrderColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Work Order Number', field: 'WorkOrderNO',
      width: 100, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Allow Person', field: 'allowPerson', width: 80, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Remaining Person', field: 'RemainingPerson', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'EIC User', field: 'EICUser', width: 100, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validity', field: 'ValidityDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 70, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public licenceColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Licence', field: 'LicenseNo',
      width: 100, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Maximum Associates', field: 'allowPerson', width: 100, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Remaining Person', field: 'RemainingPerson', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'State', field: 'State', width: 100, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validity', field: 'ValidityDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 70, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public gridRowData: any[] = [];
  public numberOfRowsPerPage = 10;
  public gridAPI: GridApi;
  public alertOption: SweetAlertOptions = {};
  public stepsGridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  public ngOnInit(): void {
    this.stepsTitleName = 'CONTRACTOR';
    this.formGroup = this.formBuilder.group({
      contractor: ['', Validators.required],
      workerOrder: ['', Validators.required],
      licence: ['', Validators.required],
      shift: ['', Validators.required],
      product: ['', Validators.required]
    });
    this.formGroup.controls.workerOrder.disable();
    this.formGroup.controls.licence.disable();
    this.formGroup.controls.shift.disable();
    this.formGroup.controls.product.disable();
    this.stepsForm = this.formBuilder.group({});
    this.getAllcontractorData();
  }

  public openModel(e: any, stepValue: any): void {
    const input: HTMLInputElement = <HTMLInputElement>e.target;
    input.blur();
    this.stepsComp.openModel();
    let stepData: number;
    stepData = 0;
    // if (stepValue === 'contractor' && this.isWorkOrderData ) {
    //   this.stepsData.pop();
    //   this.isWorkOrderData = false;
    // } else
    if (stepValue === 'workOrder') {
      stepData = 1;
      if (this.isLicenceData) {
        this.stepsData.pop();
        this.formGroup.controls.licence.reset();
        this.formGroup.controls.licence.disable();
        this.formGroup.controls.shift.disable();
        this.isLicenceData = false;
      }

      if (this.isShiftData) {
        this.stepsData.pop();
        this.formGroup.controls.shift.reset();
        this.formGroup.controls.shift.disable();
        this.isShiftData = false;
      }
      console.log('stepData', this.stepsData);
      const itemOnNext = { value: stepData, rowData: this.workOrderData, isTemplateRef: true };
      this.selectStepService.subject.next(itemOnNext);
    } else if (stepValue === 'licence') {
      stepData = 2;
      if (this.isShiftData) {
        this.stepsData.pop();
        this.isShiftData = false;
      }
      console.log('stepData', this.stepsData);
      const itemOnNext = { value: stepData, rowData: this.licenceData, isTemplateRef: true };
      this.selectStepService.subject.next(itemOnNext);
    } else if (stepValue === 'shift') {
      stepData = 3;
      if (this.isShiftData) {
        this.stepsData.pop();
        this.isShiftData = false;
      }
      console.log('stepData', this.stepsData);
      const itemOnNext = { value: stepData, rowData: this.shiftData };
      this.selectStepService.subject.next(itemOnNext);
    }

    setTimeout(() => {
      this.setAdvanceFilterOverlayConfig();
      this.setAdvanceFilterTypeConditionOverlayConfig();
      this.renderer.listen(this.elementRef.nativeElement, 'click', (event: any) => {
        console.log('simple', event);
        if (event.target.className !== 'd-flex flex-wrap align-items-center mr-3') { return; }
        this.openAdvanceFilter();
      });
      this.renderer.listen(this.advanceTypeConditionElementRef.nativeElement, 'click', (event) => {
        console.log('simpleTypeCondition', event);
        this.openAdvanceTypeConditionFilter();
      });
      this.overlayRef.backdropClick().subscribe(_ => this.openAdvanceFilter());
      this.advanceTypeConditionOverlayRef.backdropClick().subscribe(_ => this.openAdvanceTypeConditionFilter());
    }, 1000);
    this.addClickEventListner();
    this.setActiveStyle();
    this.setDeactivateStyle();
    this.setDebarredStyle();
    this.setReviewStyle();
  }

  public openAdvanceFilter(): void {
    this.isShowFilter = !this.isShowFilter;
    if (this.isShowFilter) {
      this.showFilter();
    } else {
      this.hideFilter();
    }
  }

  public openAdvanceTypeConditionFilter(): void {
    this.isShowTypeConditionFilter = !this.isShowTypeConditionFilter;
    if (this.isShowTypeConditionFilter) {
      this.showTypeConditionFilter();
    } else {
      this.hideTypeConditionFilter();
    }
  }

  public addClickEventListner(): void {
    setTimeout(() => {
      this.renderer.listen(this.activeRef.nativeElement, 'click', (event) => {
        this.isActive = !this.isActive;
        this.isDeactivate = false;
        this.isDebarred = false;
        this.isReview = false;
        this.setActiveStyle();
        this.setDeactivateStyle();
        this.setDebarredStyle();
        this.setReviewStyle();
      });

      this.renderer.listen(this.deactivateRef.nativeElement, 'click', (event) => {
        this.isDeactivate = !this.isDeactivate;
        this.isActive = false;
        this.isDebarred = false;
        this.isReview = false;
        this.setActiveStyle();
        this.setDeactivateStyle();
        this.setDebarredStyle();
        this.setReviewStyle();
      });

      this.renderer.listen(this.debarredRef.nativeElement, 'click', (event) => {
        this.isDebarred = !this.isDebarred;
        this.isActive = false;
        this.isDeactivate = false;
        this.isReview = false;
        this.setActiveStyle();
        this.setDeactivateStyle();
        this.setDebarredStyle();
        this.setReviewStyle();
      });

      this.renderer.listen(this.reviewRef.nativeElement, 'click', (event) => {
        this.isReview = !this.isReview;
        this.isActive = false;
        this.isDeactivate = false;
        this.isDebarred = false;
        this.setActiveStyle();
        this.setDeactivateStyle();
        this.setDebarredStyle();
        this.setReviewStyle();
      });
    }, 500);
  }

  public setActiveStyle(): void {
    setTimeout(() => {
      this.renderer.removeStyle(this.activeStyleRef.nativeElement, 'font-size');
      this.renderer.removeStyle(this.activeStyleRef.nativeElement, 'color');
      this.renderer.setStyle(this.activeStyleRef.nativeElement, 'font-size', '30px');
      const color = this.isActive ? '#50C876' : 'lightgray';
      this.renderer.setStyle(this.activeStyleRef.nativeElement, 'color', color);
    }, 200);
  }

  public setDeactivateStyle(): void {
    setTimeout(() => {
      this.renderer.removeStyle(this.deactivateStyleRef.nativeElement, 'font-size');
      this.renderer.removeStyle(this.deactivateStyleRef.nativeElement, 'color');
      this.renderer.setStyle(this.deactivateStyleRef.nativeElement, 'font-size', '30px');
      const color = this.isDeactivate ? 'darkgray' : 'lightgray';
      this.renderer.setStyle(this.deactivateStyleRef.nativeElement, 'color', color);
    }, 200);
  }

  public setDebarredStyle(): void {
    setTimeout(() => {
      this.renderer.removeStyle(this.debarredStyleRef.nativeElement, 'font-size');
      this.renderer.removeStyle(this.debarredStyleRef.nativeElement, 'color');
      this.renderer.setStyle(this.debarredStyleRef.nativeElement, 'font-size', '30px');
      const color = this.isDebarred ? '#FF2626' : 'lightgray';
      this.renderer.setStyle(this.debarredStyleRef.nativeElement, 'color', color);
    }, 200);
  }

  public setReviewStyle(): void {
    setTimeout(() => {
      this.renderer.removeStyle(this.reviewStyleRef.nativeElement, 'font-size');
      this.renderer.removeStyle(this.reviewStyleRef.nativeElement, 'color');
      this.renderer.setStyle(this.reviewStyleRef.nativeElement, 'font-size', '30px');
      const color = this.isReview ? '#FF7A4D' : 'lightgray';
      this.renderer.setStyle(this.reviewStyleRef.nativeElement, 'color', color);
    }, 200);
  }

  public rowSelectedData(value: any): void {

  }

  public onNextEvent(value: any): void {

  }

  public onPrevEvent(item: any): void {
    if (item.data[0].title === 'Contractor') {
      this.stepsTitleName = 'CONTRACTOR';
      this.formGroup.controls.workerOrder.reset();
      this.formGroup.controls.workerOrder.disable();
    } else if (item.data[0].title === 'Work Order') {
      this.stepsData.pop();
      this.formGroup.controls.licence.reset();
      this.formGroup.controls.licence.disable();
      this.stepsTitleName = 'WORKORDER';
    } else if (item.data[0].title === 'License') {
      this.stepsData.pop();
    }
    this.setFilterConfig();
  }

  public onCancelAction(value: any): void {

  }

  public onSubmitAction(value: any): void {

  }

  public onCloseAction(value: any): void {
    if (this.stepsData.length > 1) {
      for (let i = 0; i < this.stepsData.length; i++) {
        //  this.stepsData.pop();
      }
    }
    console.log('stepsDataLength', this.stepsData);
    this.isActive = false;
    this.isDeactivate = false;
    this.isDebarred = false;
    this.isReview = false;
    this.stepsTitleName = null;
    this.hideFilter();
    this.hideTypeConditionFilter();
  }

  public getContractorFilterData(filterData: any): void {
    const item = {};
    this.service.getContractorFilterData(item).subscribe((response: any[]) => {
      this.rowFilterData = response;
    });
  }

  public getWorkOrderFilterData(filterData: {}): void {
    const item = {};
    this.service.getWorkOrderFilterData(item).subscribe((response: any[]) => {
      this.rowFilterData = response;
    });
  }

  public getSelectedRowData(item: any, value: any): void {
    if (this.requestType !== value) { return; }
    if (item.arrayItem[0].title === 'Contractor') {
      this.setFormConfigOnRowSelectedOfContr(item);
      this.stepsTitleName = 'WORKORDER';
    } else if (item.arrayItem[0].title === 'Work Order') {
      this.selectWorkOrder(item);
    } else if (item.arrayItem[0].title === 'Licence') {
      this.selectLicense(item);
    } else if (item.arrayItem[0].title === 'Select WC Policy') {
      // this.selectWcPolicy(item);
    } else if (item.arrayItem[0].title === 'Sub-Contractor Selection') {
      //  this.selectSubContractor();
    } else if (item.arrayItem[0].title === 'Select Sub Work Order') {
      //  this.selectSubWorkOrder(item);
    } else if (item.arrayItem[0].title === 'Select Sub License') {
      //  this.selectSubLicense(item);
    } else {
      //  this.getWorkOrderData(item);
    }
  }

  public setFilterConfig(): void {
    this.addClickEventListner();
    this.isActive = false;
    this.isDeactivate = false;
    this.isDebarred = false;
    this.isReview = false;
    this.setActiveStyle();
    this.setDeactivateStyle();
    this.setDebarredStyle();
    this.setReviewStyle();
  }

  public setAdvanceFilterOverlayConfig(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: -4,
        offsetX: -3
      }]);
    const config = {
      positionStrategy,
      hasBackdrop: false,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    };
    this.overlayRef = this.overlay.create(config);
  }

  public setAdvanceFilterTypeConditionOverlayConfig(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.advanceTypeConditionElementRef)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: 1,
        offsetX: -3
      }]);
    const config = {
      positionStrategy,
      hasBackdrop: false,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    };
    this.advanceTypeConditionOverlayRef = this.overlay.create(config);
  }

  private showFilter(): void {
    const filterRef: ComponentRef<AdvanceFilterDynamicComponent>
      = this.overlayRef.attach(new ComponentPortal(AdvanceFilterDynamicComponent));
    filterRef.instance.filterData.subscribe((filterData: {}) => {
      if (this.stepsTitleName === 'CONTRACTOR') {
        this.getContractorFilterData(filterData);
      } else if (this.stepsTitleName === 'WORKORDER') {
        this.getWorkOrderFilterData(filterData);
      } else if (!this.stepsTitleName) {
        this.getContractorFilterData(filterData);
      }
    });
    console.log('filterRef', filterRef);
  }

  private hideFilter(): void {
    this.overlayRef.detach();
  }

  private showTypeConditionFilter(): void {
    const filterRef: ComponentRef<AdvanceFilterTypeConditionComponent>
      = this.advanceTypeConditionOverlayRef.attach(new ComponentPortal(AdvanceFilterTypeConditionComponent));
    console.log('filterRefTypeCondition', filterRef);
  }

  private hideTypeConditionFilter(): void {
    this.advanceTypeConditionOverlayRef.detach();
  }


  public onSelectQuestion(value: any): void {

  }

  private setFormConfigOnRowSelectedOfContr(item: any): void {
    if (this.isLicenceData) {
      this.stepsData.pop();
      this.isLicenceData = false;
      this.formGroup.controls.licence.reset();
      this.formGroup.controls.licence.disable();
    }
    if (this.isWorkOrderData) {
      this.stepsData.pop();
      this.isWorkOrderData = false;
      this.formGroup.controls.workerOrder.reset();
      // this.formGroup.controls.workerOrder.disable();
    }
    this.selectContractor(item);
  }

  /**
   * @method getAllcontractorData
   * @description Get All contractor data.
   */
  private getAllcontractorData(): void {
    this.service.getAllContractorData().subscribe((contractorResponse) => {
      this.gridRowData = contractorResponse;
      this.setStepArray();
    });
  }

  /**
   * @method getAllcontractorData
   * @description Get All contractor data.
   */
  private selectLicense(item: any): void {
    this.service.getAllContractorData().subscribe((contractorResponse) => {
      this.isShiftData = true;
      this.gridRowData = contractorResponse;
      this.shiftData = contractorResponse;
      const firstSteps = {
        id: 4,
        type: 'grid',
        title: 'Shift',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        header: true,
        footer: true,
        templateRef: this.filterTemplateRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
        columnDef: this.gridColumnDefs,
        rowData: 'rowSelectedData',
        gridOptions: this.stepsGridOptions
      };
      this.stepsData.push(firstSteps);
      this.rowSelectedData = contractorResponse;
      this.formGroup.controls.shift.enable();
      this.formGroup.patchValue({
        licence: item.data.LicenseNo
      });
    });
  }

  /**
   * @method selectContractor
   * @description Get All contractor data.
   */
  private selectContractor(item: any): void {
    const data = { contractorVendorcode: item.data.ContractorVendor_Code, requestType: this.requestType };
    // this.selectedContractor = item.data.ContractorVendor_Code;
    this.service.getWorkOrderByContractr(data).subscribe((rowSelectedData: any) => {
      this.workOrderData = rowSelectedData;
      this.isWorkOrderData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 112) {
        //  this.workOrderCode = rowSelectedData.length === 0 ? rowSelectedData : rowSelectedData[0].License_Code;
        this.setTemplateConfig(item);
        /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsDataWorkOrder(rowSelectedData);
        this.formGroup.patchValue({
          contractor: item.data.Company
        });
      }
    });
  }

  /**
   * Invoke this method and it is set array list data of `AllContractor` for `rowData`.
   */
  private setStepArray(): void {
    const firstSteps = {
      id: 1,
      type: 'grid',
      title: 'Contractor',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.gridColumnDefs,
      rowData: this.gridRowData,
      gridOptions: this.stepsGridOptions
    };
    this.stepsData = [firstSteps];
  }

  /**
  * @description When invoke this method it is set selectWorkOrder data.
  * @param item this parameter contain selected data.
  */
  private selectWorkOrder(item: any): void {
    if (item.data.Status === 'Expired') {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Work Order already expired.<br>Please extend validity date for select other work order.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    } else if (item.data.Status === 'Active') {
      this.selectedWorkOrderNo = item.data.WorkOrderNO;
      if (this.role !== 112) {
        this.selectedContractor = this.selectedCompanyName;
        this.selectedWorkerOrderNo = this.selectedWorkOrderNo;
        // this.formGroup.controls.workerOrder.enable();
        this.formGroup.patchValue({
          workerOrder: item.data.WorkOrderNO
        });
      } else {
        // this.selectedContractor = 'SPIKYARC';
        // this.selectedWorkerOrderNo = this.selectedWorkOrderNo;
      }
      this.getLicenseByContractor(item);
    }
  }

  /**
   * @descriptions Invoke this method when click on expire list.
   */
  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

  /**
   * @method getLicenseByContractor
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getLicenseByContractor(item: any): void {
    const parentContractorVendorCd = this.role === 112 ? 317 : item.data.ParentContractorVendor_Code;
    const workOrderData = {
      parentContractorVendorCode: parentContractorVendorCd,
      parentWorkOrderCode: item.data.ParentWorkOrder_Code, requestType: this.requestType
    };
    this.selectedWorkOrderCode = item.data.ParentWorkOrder_Code;
    this.service.getLicenseByContractor(workOrderData).subscribe((rowSelectedData: any) => {
      this.licenceData = rowSelectedData;
      this.isLicenceData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 112) {
        this.licenseCode = rowSelectedData.length === 0 ? rowSelectedData : rowSelectedData[0].License_Code;
        this.setTemplateConfig(item);
        /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsDataLicence(rowSelectedData);
      }
    });
  }

  /**
   * When invoke this method it is set template config.
   */
  private setTemplateConfig(item: any): void {
    if (item.arrayItem[0].title === 'Contractor') {
      this.isSelectedContractor = true;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Work Order') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select License') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = true;
    } else if (item.arrayItem[0].title === 'Sub-Contractor Selection') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select WC Policy') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select Labours') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }
  }

  /**
   * @method setStepsDataWorkOrder
   * @description When invoke this method it is set array steps based on licence data.
   */
  private setStepsDataWorkOrder(rowSelectedData: any): void {
    const workOrderSteps = {
      id: 2,
      type: 'grid',
      title: 'Work Order',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.workOrderColumnDefs,
      rowData: 'rowSelectedData',
      gridOptions: this.stepsGridOptions,
    };
    this.stepsData.push(workOrderSteps);
    this.rowSelectedData = rowSelectedData;
    this.formGroup.controls.workerOrder.enable();
    this.setFilterConfig();
  }

  /**
  * @method setStepsDataLicense
  * @description When invoke this method it is set array steps based on licence data.
  */
  private setStepsDataLicence(rowSelectedData: any): void {
    const licenceSteps = {
      id: 3,
      type: 'grid',
      title: 'Licence',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.filterTemplateRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.licenceColumnDefs,
      rowData: 'rowSelectedData',
      gridOptions: this.stepsGridOptions,
    };
    this.stepsData.push(licenceSteps);
    this.rowSelectedData = rowSelectedData;
    this.formGroup.controls.licence.enable();
  }
}
