import {Component, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {Ng4LoadingSpinnerService} from 'src/app/core/components';
import * as XLSX from 'xlsx';

@Component({
             selector: 'app-upload-excel',
             templateUrl: './upload-excel.component.html',
             styleUrls: ['./upload-excel.component.css']
           })
export class UploadExcelComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  fileError = '';

  public closeModal: EventEmitter<any>;

  constructor(private loadingService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
  }

  validateFile(target) {
    this.fileError = '';
    if (target.files.length === 0) {
      this.fileError = 'Please select Excel file';
      target.value = '';
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = 'Cannot use multiple files';
      target.value = '';
      return false;
    }

    if (!(target.files[0].name.endsWith('.xlsx') || target.files[0].name.endsWith('.xls'))) {
      this.fileError = 'Invalid File format, please select Excel file only.';
      target.value = '';
      return false;
    }

    return true;
  }

  onFileChange($event) {
    this.validateFile($event.target);
  }

  onUploadFile() {
    // this.bindExcelData(null);
    if (this.validateFile(this.fileInput.nativeElement)) {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        if (e.target) {
          /* read workbook */
          const bstr = reader.result;
          const wb = XLSX.read(bstr, {type: 'binary'});

          /* grab first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* save data */
          const fileContent = XLSX.utils.sheet_to_json(ws, {header: 1, blankrows: false, defval: null, raw: false});

          if (!fileContent) {
            this.fileError = 'Please select a file to upload.';
            return;
          }
          const labourData = [];
          const colgrps = fileContent.shift();
          const keys: any = fileContent.shift();
          const firstTabId = '';
          const data = fileContent.map((e) => {
            const obj = {};
            for (let i = 0; i < keys.length; i++) {
              obj[keys[i]] = e[i];
            }
            return obj;
          });

          if (data.length === 0) {
            this.loadingService.hide();
            this.fileError = 'AtLeast one record should be availabe in file to upload a file.';
            return;
          }
          if (data.length > 50) {
            this.loadingService.hide();
            this.fileError = 'Maximum 50 Records is allowed to import.';
            return;
          }

          // this.loadingService.hide();
          localStorage.setItem('xlsx_data', JSON.stringify(data));
          this.bindExcelData(this.removeSortCharFromKeys(data));
        }
      };
      this.loadingService.show();
      setTimeout(() => {
        reader.readAsBinaryString(this.fileInput.nativeElement.files[0]);
      }, 1000);
    }
  }

  bindExcelData(fn) {
  }

  removeSortCharFromKeys(data) {
  }

  saveData() {
  }
}
