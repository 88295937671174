import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-access-checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.css']
})
export class AccessCheckboxCellComponent implements OnInit, ICellRendererAngularComp {

  public params: any;
  @ViewChild('.checkbox') checkbox: ElementRef;
  isCountCell = false;
  isCheckAllCell = false;
  isNormalCell = false;
  isDisabledCell = false;
  constructor() { }

  ngOnInit() {
  }

  agInit(params): void {
    this.isCountCell = params.data.isCountCell ? true : false;
    this.isCheckAllCell = params.data.isCheckAllCell ? true : false;
    const data = params.data[params.colDef.field];
    if (data) {
      this.isDisabledCell = data.disabled ? true : false;
    }
    this.isNormalCell = !this.isCountCell && !this.isCheckAllCell && !this.isDisabledCell;
    params.data['Action List'] === 'Total Selected Action' ? true : false;
    this.params = params;
  }

  public onChangeSelectAll(event) {
    this.params.onCheckAllChange(this.params, this.params.rowIndex, this.params.column.colId, event);
  }

  public onChange(event) {
    const data = this.params.data[this.params.colDef.field];
    data.value = event.target.checked;
    this.params.context.componentParent.onCheckChange(data, this.params);
  }

  refresh(): boolean {
    return false;
  }

}
