import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;
declare var WebDataRocks: any;
import 'jquery-ui';
import 'pivottable';

export class PivotConfig {
  'derivedAttributes': any;
  'hiddenAttributes': [];
  'hiddenFromAggregators': [];
  'hiddenFromDragDrop': [];
  'menuLimit': number;
  'cols': string[];
  'rows': string[];
  'vals': string[];
  'rowOrder': string;
  'colOrder': string;
  'exclusions': any;
  'inclusions': any;
  'unusedAttrsVertical': number;
  'autoSortUnusedAttrs': boolean;
  'showUI': boolean;
  'sorters': any;
  'inclusionsInfo': any;
  'aggregatorName': string;
  'rendererName': string;
}

@Component({
  selector: 'app-pivot-table',
  templateUrl: './pivot-table.component.html',
  styleUrls: ['./pivot-table.component.css']
})
export class PivotTableComponent implements OnInit {

  @Input() data;
  @Output() updateReport: EventEmitter<PivotConfig> = new EventEmitter();

  pivot;

  constructor() { }

  inputFunction = (callback) => {
    // const data = [];
    // const cols = [];
    // cols.push('row');
    // Object.keys(this.data[0]).forEach((element) => {
    //   cols.push(element);
    // });
    // data.push(cols);
    // this.data.forEach((element, index) => {
    //   const row = [];
    //   row.push(String(index));
    //   cols.forEach((col) => {
    //     row.push(element[col]);
    //   });
    //   data.push(row);
    // });
    callback(this.data);
  }

  ngOnInit() {
    const renderers = $.extend(
      $.pivotUtilities.renderers,
      $.pivotUtilities.plotly_renderers,
      $.pivotUtilities.d3_renderers,
      $.pivotUtilities.export_renderers
    );

    $('#pivot-table').pivotUI(
      this.data, {
      // rows: ['Company'],
      // cols: ['ShortCode']
        renderers,
        onRefresh: (config) => {
          const configCopy = JSON.parse(JSON.stringify(config));
          // delete some values which are functions
          delete configCopy['aggregators'];
          delete configCopy['renderers'];
          // delete some bulky default values
          delete configCopy['rendererOptions'];
          delete configCopy['localeStrings'];
          // console.log(JSON.stringify(configCopy, undefined, 2));
          this.updateReport.emit(configCopy);
        }
      }, true);

    this.pivot = new WebDataRocks({
      container: '#pivot-table2',
      beforetoolbarcreated: this.customizeToolbar,
      toolbar: true,
      report: {
        dataSource: {
          data: this.data
        }
      }
    });
  }

  customizeToolbar = (toolbar) => {
    const tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      // delete tabs[0]; // delete the first tab
      // delete tabs[1];
      // delete tabs[4];
      // delete tabs[5];
      return tabs;
    };
  }

  saveReport() {
    const options = {
      withDefaults: true,
      withGlobals: true
    };
    const report = this.pivot.getReport(options);
    report.dataSource.data = [];
    console.log('Pivot Report', report);
  }

}
