import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
  selector: 'app-task-list-view',
  templateUrl: './task-list-view.component.html',
  styleUrls: ['./task-list-view.component.css'],
  host: {
    style: 'height: calc(100vh - 180px); display: flex; flex-direction: column'
  }
})
export class WOTaskListViewComponent {

  @Input() public taskList: any[];
  @Output() public taskDetailEmit: EventEmitter<any>;

  statusText = {
    GM608: { text: 'Rejected', bgColor: '#FF3030', color: '#FFF' },
    GM256: { text: 'Approved', bgColor: '#228B22', color: '#FFF' },
    GM832: { text: 'Verified', bgColor: '#a8be37', color: '#FFF' },
    GM248: { text: 'On Hold', bgColor: '#FFC300' },
    GM423: { text: 'Overdue', bgColor: '#FF5733', color: '#FFF' },
    GM555: { text: 'Pending', bgColor: '#EF9460', color: '#FFF' },
    GM637: { text: 'Submitted', bgColor: '#31EAE8' },
    GM264: { text: 'Generated', bgColor: '#c9c9c9' },
  };
  statusArray = Object.keys(this.statusText);

  constructor(
  ) {
    this.taskDetailEmit = new EventEmitter();
  }

  public onGetTaskDetail(task: any): void {
    this.taskDetailEmit.emit(task);
  }
}