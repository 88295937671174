import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, TemplateRef } from '@angular/core';
import { ShiftgroupService } from './shiftgroup.service';
import { AgNumberCountComponent, SelectStepsComponent, AgActionCellRendererComponent } from '../../core/components';
import {
  ModalConfig,
  BackdropColor,
  ModelDialogClass,
  ModalSize
} from '../../core/components/select-steps/select-steps-model/select-steps.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shiftgroup',
  templateUrl: './shiftgroup.component.html',
  styleUrls: ['./shiftgroup.component.css']
})
export class ShiftgroupComponent implements OnInit, AfterViewInit {
  /** form group */
  public shiftgroupDetailsForm: FormGroup;
  /** to-do */
  public steps: any[];
  public gridOptions: any[];
  @ViewChild(SelectStepsComponent) public templateRef: SelectStepsComponent;
  @ViewChild('templateRef') public templateContent: TemplateRef<any>;

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    size: ModalSize.Large,
    closeOnOutSideClick: false
  };
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private shiftgroupService: ShiftgroupService) {
    this.gridOptions = [];
  }
  responseType = {
    RoleCode: '101',
    UserId: '653',
    endRow: 20,
    searchText: '',
    startRow: 0
  };
  rowData = [];
  columnDefs = [
    {
      headerName: '#', field: '',
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Name', field: 'ShiftGroupName',
      width: 150, filterParams: { applyButton: true, clearButton: true }, sort: 'asc'
    },
    {
      headerName: 'Short Name', field: 'ShortName',
      width: 100, headerComponentParams: { enableSorting: false }, suppressMenu: true,
    },
    {
      headerName: 'Start Date', field: 'StartDate', width: 100, filterParams:
        { applyButton: true, clearButton: true }
    },
    {
      headerName: 'End Date', field: 'EndDate', width: 100, filterParams:
        { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Action', field: '', width: 100,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
    }
  ];
  ngOnInit() {
    this.getShiftGroups(this.responseType);


    // this.shiftgroupDetailsForm = this.formBuilder.group({
    //   applicability: ['', Validators.required],
    //   labour: ['', Validators.required],
    //   contractManType: ['', Validators.required],
    //   nationality: ['', Validators.required],
    // });
  }
  ngAfterViewInit() {
  }

  public openModalAction(): void {
    this.templateRef.openModel();
  }

  public onNextEvent(event): void {
    console.log(event, this.shiftgroupDetailsForm);
  }

  public onPrevEvent(event): void {
    console.log(event, 'event');

  }

  public onEmitData(data: any): void {
    console.log('emitData', data);
  }

  public onButton(): void {
    console.log('button');
  }
  /**
   * used to get all the labour data.
   */
  getShiftGroups(response: any) {
    this.shiftgroupService.getAllShiftGroups().subscribe((response: any) => {
      console.log(response);
      this.rowData = response;
    });
  }

  editShift(rowData) {
    this.router.navigate([`/edit-shift-group/${rowData.ShiftGroup_Code}`]);
    console.log('edit', rowData);
  }

  deleteShift(rowData) {

  }
}
