import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { checkValidFileSize, getAlertMessageByShortCode } from "src/app/core/utility/utility";
import { WOHRCRequestFlowDetail } from "../wo-task-model";


@Component({
  selector: 'app-task-detail-view',
  templateUrl: './task-detail-view.component.html',
  styleUrls: ['./task-detail-view.component.css'],
  //   host: {
  //     style: 'height: calc(100vh - 200px); display: flex'
  //  }
})
export class WOTaskDetailViewComponent {

  @Input() public alertMessages: any[];
  @Input() public taskDocuments: any[];
  @Input() public taskItem: any;
  @Input() public hrcRequestFlowDetails: WOHRCRequestFlowDetail;
  @Input() public set taskDetail(value: any) {
    if (value) {
      this._taskDetail = value;
      this.fileList.controls = [];
      this.formGroup.reset();
    }
  }
  public get taskDetail(): any {
    return this._taskDetail;
  }

  @Input() public statusList: any[] = [];
  @Input() public documentTypes: any[] = [];

  @Output() public handleSave: EventEmitter<any>;
  @Output() public handleBack: EventEmitter<void>;
  @ViewChild('file') public fileElementRef: ElementRef<HTMLElement>;

  public rowData: any[] = [
    {
      Subs: 'Test-subs',
      Total: 20,
      USW: 20,
      SSW: 20,
      SW: 20,
      HSW: 20,
      Role: 20,
      InputDate: '2022-01-02',
      Comment: 'Test'
    },
    {
      Subs: 'Test-subs',
      Total: 20,
      USW: 20,
      SSW: 20,
      SW: 20,
      HSW: 20,
      Role: 20,
      InputDate: '2022-01-02',
      Comment: 'Test'
    }
  ]

  public columnDefs: any[] = [];

  public formGroup: FormGroup;
  public fileFormGroup: FormGroup;
  public fileError: string;

  public get fileList(): FormArray {
    return this.fileFormGroup.get('fileList') as FormArray;
  }

  private _taskDetail: any;
  private maxSizeAllowed: number;


  constructor(
    private fb: FormBuilder,
    private logHelperService: LogHelperService
  ) {
    this.handleSave = new EventEmitter();
    this.handleBack = new EventEmitter();
    this.maxSizeAllowed = 3 * 1024;

    this.columnDefs = this.createColumnDefs();
    this.formGroup = this.buildForm();
    this.fileFormGroup = this.fb.group({
      fileList: this.fb.array([])
    })
  }

  public ngOnInit(): void {
    this.formGroup.get('IsComplied').setValue(true);
    this.formGroup.get('isLabourInformation').setValue(true);
  }

  public onBack(): void {
    this.handleBack.emit();
  }

  public onFileChange(event: any): void {
    this.fileError = '';
    if (event.target.files) {
      const files: File[] = Array.from(event.target.files)
      if (!checkValidFileSize(files[0].size, this.maxSizeAllowed)) {
        this.fileError = `Max file size allowed is 3MB.`;
        event.target.value = null;
        return;
      }
      this.setControlValues(files);
      event.target.value = null;
    }
  }

  public onDocDelete(index: any): void {
    // this.files = this.files.filter((_, currentIndex) => currentIndex !== index);
    this.fileList.removeAt(index);
  }

  public onIsCompliedChange(): void {
    const isComplied = this.formGroup.get('IsComplied').value;
    this.setControlState('Amount', isComplied);
    this.setControlState('NoOfSubscription', isComplied);
    this.setControlState('StaffTotal', isComplied);
    this.setControlState('SemiSkillTotal', isComplied);
    this.setControlState('SkillTotal', isComplied);
    this.setControlState('HighSkillTotal', isComplied);
    this.setControlState('UnskillTotal', isComplied);
    this.setControlState('ActualDate', isComplied);
    this.setControlState('isLabourInformation', isComplied);

    this.formGroup.get('isLabourInformation').setValue(isComplied);
  }

  public onIsLabourInformationChange(): void {
    const value = this.formGroup.get('isLabourInformation').value;
    this.setControlState('Amount', value);
    this.setControlState('NoOfSubscription', value);
    this.setControlState('StaffTotal', value);
    this.setControlState('SemiSkillTotal', value);
    this.setControlState('SkillTotal', value);
    this.setControlState('HighSkillTotal', value);
    this.setControlState('UnskillTotal', value);
  }

  public onSave(): void {
    const fileList = this.fileList.getRawValue();
    const formValue = this.formGroup.getRawValue()
    if (this.taskItem.IsDocMandatory && !fileList.length && formValue.IsComplied) {
      const message = getAlertMessageByShortCode(this.alertMessages, 'MSG205_7');
      this.logHelperService.logError({ message: message || 'Task documents are required' });
      return;
    }
    
    if (!this.formGroup.valid) {
      this.logHelperService.logError({
        message: 'Please enter the required fields.'
      });

      return;
    }

    const files = fileList.map(item => item.file);
    const documentTypes = fileList.map(item => item.FileType);
    if (files.length !== documentTypes.length) {
      this.logHelperService.logError({
        message: 'Please select document types.'
      });

      return;
    }

    this.handleSave.emit({ ...formValue, Task_Code: this.taskItem.Task_Code, files, documentTypes: documentTypes.join(',') });
  }

  public handleClear(): void {
    this.formGroup.reset();
  }

  private setControlValues(files: File[]): void {
    const control = this.fileFormGroup.get('fileList') as FormArray;
    // control.controls = [];
    for (const file of files) {
      const formGroup: any = this.buildFileForm(file);
      control.push(formGroup);
    }
  }

  private buildFileForm(file: any): FormGroup {
    return this.fb.group({
      FileName: [file.name],
      Size: [file.size],
      FileType: [this.taskItem.DocumentType_Code || null],
      file: [file]
    });
  }

  private setControlState(controlName: string, state: boolean): void {
    if (state) {
      this.formGroup.get(controlName).enable();
      this.formGroup.get(controlName).setValidators([Validators.required]);
    } else {
      this.formGroup.get(controlName).disable();
      this.formGroup.get(controlName).setValue(null);
      this.formGroup.get(controlName).clearValidators();
    }
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      ActualDate: [null, [Validators.required]],
      Status: [null, [Validators.required]],
      Remark: [null, [Validators.required]],
      UnskillTotal: [null, [Validators.required]],
      HighSkillTotal: [null, [Validators.required]],
      SkillTotal: [null, [Validators.required]],
      SemiSkillTotal: [null, [Validators.required]],
      StaffTotal: [null, [Validators.required]],
      Amount: [null, [Validators.required]],
      NoOfSubscription: [null, [Validators.required]],
      IsComplied: [true, []],
      isLabourInformation: [true]
    });
  }

  private createColumnDefs(): any[] {
    return [
      {
        headerName: 'Subs',
        field: 'Subs', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'Total',
        field: 'Total', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'USW',
        field: 'USW', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'SSW',
        field: 'SSW', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'SW',
        field: 'SW', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'HSW',
        field: 'HSW', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'Role',
        field: 'Role', width: 70, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'InputDate',
        field: 'NameSurName', width: 100, visible: false, filter: true, sortable: false,
      },
      {
        headerName: 'Comment',
        field: 'Comment', width: 100, visible: false, filter: true, sortable: false,
      }
    ]
  }
}