import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UserManagementService } from '../user-management.service';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';

@Component({
  selector: 'app-checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.css']
})
export class CheckboxCellComponent implements OnInit, ICellRendererAngularComp {

  public params: any;
  @ViewChild('.checkbox') checkbox: ElementRef;
  isCountCell = false;
  isCheckAllCell = false;
  isNormalCell = false;
  isDisabledCell = false;
  constructor(private userManagementService: UserManagementService,
    private loading: Ng4LoadingSpinnerService) { }

  ngOnInit() {
  }

  agInit(params): void {
    this.isCountCell = params.data.isCountCell ? true : false;
    this.isCheckAllCell = params.data.isCheckAllCell ? true : false;
    const data = params.data[params.colDef.field];
    if (data) {
      this.isDisabledCell = data.disabled ? true : false;
    }
    this.isNormalCell = !this.isCountCell && !this.isCheckAllCell && !this.isDisabledCell;
    params.data['Action List'] === 'Total Selected Action' ? true : false;
    this.params = params;
  }

  public onChangeSelectAll(event) {
    this.params.onCheckAllChange(this.params, this.params.rowIndex, this.params.column.colId, event);
  }

  public onChange(event) {
    // this.params.data[this.params.colDef.field].value = event.currentTarget.checked;
    this.params.data[this.params.colDef.field] = event.currentTarget.checked;
    // tslint:disable-next-line: max-line-length
    this.params.context.componentParent.countSelectedPages({ Col: this.params.colDef.headerName, col1: this.params.value, checked: event.currentTarget.checked });
    const data = this.params.data[this.params.colDef.field];
    console.log('data', data);
    if (data.actionId) {
      data['relation'] = 'PageActionRelation';
    } else if (data.pageId) {
      data['relation'] = 'PageModuleRelation';
    } else if (data.roleId) {
      data['relation'] = 'RoleModuleRelation';
    } else {
      data['relation'] = 'PlantwiseErrorMsg';
    }

    this.userManagementService.updateAccessInformation(data).subscribe((res: any) => {
      if (res.Status) {
        console.log(res.Data.Message);
      }
      if (!res.Status) {
        this.params.data[this.params.colDef.field].value = !this.params.data[this.params.colDef.field].value;
        // tslint:disable-next-line:max-line-length
        this.params.context.componentParent.countSelectedPages({ Col: this.params.colDef.headerName, checked: this.params.data[this.params.colDef.field].value });
      }
      this.loading.hide();
    }, (err) => {
      this.loading.hide();
      console.log('error');
    });
  }

  refresh(): boolean {
    return false;
  }

}
