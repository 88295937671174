import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ManPowerService } from '../../man-power.service';

@Component({
  selector: 'app-man-power-expand-row',
  templateUrl: './expand-row.component.html',
  styleUrls: ['./expand-row.component.css'],
  providers: [ManPowerService]
})
export class ManPowerExpandRowComponent implements OnInit, ICellRendererAngularComp {
  params: any;
  count = 0;
  isExpandable = true;
  constructor(private manPowerService: ManPowerService) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.count = params.rowIndex + 1;
    // this.isExpandable = this.manPowerService.isEditAllow();
  }

  refresh(): boolean {
    return false;
  }

  expandRow(newStatus: boolean) {
    this.params.data.expanded = newStatus;
    this.params.context.componentParent.getManPowerInfo(this.params.data, this.params.rowIndex);
  }

}
