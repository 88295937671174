// tslint:disable-next-line:max-line-length
import { Component, OnInit, ViewContainerRef, ComponentRef, TemplateRef, ViewChild, Input, ComponentFactoryResolver, EventEmitter } from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';
import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { Contractor, MasterItemCode } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { EntryForThePrsenter } from 'src/app/reports/daily-attendance/entryfortheday/entryfortheday-presenter/entryfortheday.presenter';
import { AttendanceService } from '../../attendance.service';
// tslint:disable-next-line:max-line-length
import { AttendanceManualPunchChangeComponent } from '../../attendance-manual-punch-change/attendance-manual-punch-change.component';
import { castArray } from 'lodash';

declare var $;
const dateFormat = 'DD-MM-YYYY';
const date1: Date = new Date();
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-punchtransfer-process',
  templateUrl: './punchtransfer-process.component.html',
  styleUrls: ['./punchtransfer-process.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class PunchtransferProcessComponent implements OnInit {
  public closeModal: EventEmitter<any>;
  public activeFilter: string;
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  public roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];
  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  employees: any[];

  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  public alertText: string;
  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  public pagecode: number;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;
  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;
  private filterType: FilterTypeEnum;
  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  private contractorCode: number;
  private workOrderNo: number;
  rowData: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private entryfordayService: AttendanceService,
    private attendanceService: AttendanceService,
    private logHelperService: LogHelperService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private entryForThePrsenter: EntryForThePrsenter,
    public sharedService: SharedService) {
    this.formGroup = this.entryForThePrsenter.buildForm();
    this.formGroup.get('JSONText').get('contAndSubCont').setValue(3);
    this.closeModal = new EventEmitter();
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getLabourName();
    this.pagecode = 335;
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();
    const userId = localStorage.getItem('UserID');
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  }

  public getLabourName() {
    this.attendanceService.getLabourName().subscribe((response: any) => {
      this.employees = response;
      // this.employees[0].IsWO = 1;
    });
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  // public onSubmit(): void {
  //
  //   const formValue = this.formGroup.getRawValue();
  //   console.log('response', formValue);
  //
  //   this.entryfordayService.getDailyPresentDetails(formValue).subscribe((response: any[]) => {
  //     console.log(response);
  //     this.openReportViewModal(response);
  //    });
  //  }
  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();
    if (formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat)) {
      // formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      // formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
      formValue.JSONText.contractors = castArray(formValue.JSONText.Contractors);
      console.log('xyz', this.formGroup.getRawValue());
      console.log('Date response', formValue);
      // this.getErrorid();
      this.getLabourFilterdata();
      let contCode = '';
      let subconcode = '';
      const statementtype = 'Insert';

      if (formValue.JSONText.Contractors) {
        if (this.roleCode = 119) {
          const cc = formValue.JSONText.Contractors;
          contCode = cc.toString();
        } else {
          contCode = formValue.JSONText.Contractors.join(',');
        }
      } else {
        contCode = null;
      }
      if (formValue.JSONText.SubContractors) {
        subconcode = formValue.JSONText.SubContractors.join(',');
      } else {
        subconcode = null;
      }
      const uid = localStorage.getItem('UserID');
      formValue.filterText = {
        // Date: formValue.JSONText.Date,
        Logdate: formValue.JSONText.dateRang,
        Empid: formValue.Empid,
        // ApplicableDate: moment(formValue.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
        // ValiduptoDate: moment(formValue.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
        // StatementType: statementtype,
        // Page: formValue.JSONText.Page,
      };
      console.log('filter', formValue.filterText);
      this.attendanceService.submitbioservertoclpms(formValue.filterText).subscribe((response: any[]) => {
        if (response) {
          this.logHelperService.logSuccess({
            message: 'Add Labourwisedeviationallow successfully'
          });
          this.rowData = response;
        }
      });
    } else {
      this.logHelperService.logError({
        message: 'Please Select Date'
      });
    }
  }

  getLabourFilterdata() {

    const formValue = this.formGroup.getRawValue();
    this.attendanceService.getActiveLabour(formValue.JSONText).subscribe((res: any) => {
      console.log(res.Data.Table);
      this.rowData = res.Data.Table;
      for (let index = 0; index < this.rowData.length; index++) {
        const element = this.rowData[index];
        element['selected'] = false;
      }
    });
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }
  public onCloseAction(): void {
    this.closeModal.next();
  }
  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }
}
