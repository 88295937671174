import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-number-count',
  templateUrl: './ag-number-count.component.html',
  styleUrls: ['./ag-number-count.component.css']
})
export class AgNumberCountComponent implements OnInit, ICellRendererAngularComp {

  count = 0;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.count = params.rowIndex + 1;
  }

  refresh(): boolean {
    return false;
  }
}
