﻿import { Component, EventEmitter, Input, Output, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { TreeviewI18n } from './treeview-i18n';
import { TreeviewItem } from './treeview-item';
import { TreeviewConfig } from './treeview-config';
import { TreeviewComponent } from './treeview.component';
import { DropdownDirective } from './dropdown.directive';
import { TreeviewHeaderTemplateContext } from './treeview-header-template-context';
import { TreeviewItemTemplateContext } from './treeview-item-template-context';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngx-dropdown-treeview',
  templateUrl: './dropdown-treeview.component.html',
  styleUrls: ['./dropdown-treeview.component.scss']
})
export class DropdownTreeviewComponent {
  @Input() isDropDownDisplay = true;
  @Input() buttonClass = 'btn-outline-secondary';
  @Input() headerTemplate: TemplateRef<TreeviewHeaderTemplateContext>;
  @Input() itemTemplate: TemplateRef<TreeviewItemTemplateContext>;
  @Input() items: TreeviewItem[];
  @Input() config: TreeviewConfig;
  @Output() selectedChange = new EventEmitter<any[]>(true);
  @Output() filterChange = new EventEmitter<string>();
  @Output() dropDownChange = new EventEmitter<boolean>();
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
  @ViewChild(DropdownDirective) dropdownDirective: DropdownDirective;

  constructor(
    public i18n: TreeviewI18n,
    private defaultConfig: TreeviewConfig
  ) {
    this.config = this.defaultConfig;
  }

  getText(): string {
    if (this.isDropDownDisplay) {
      if (this.treeviewComponent && this.treeviewComponent.selection.checkedItems.length) {
        return this.i18n.getText(this.treeviewComponent.selection);
        // tslint:disable-next-line:no-else-after-return
      } else {
        return this.config.placeHolder;
      }
    }
  }

  onSelectedChange(values: any[]) {
    this.selectedChange.emit(values);
  }

  onFilterChange(text: string) {
    this.filterChange.emit(text);
  }

  onDropDownChange(event) {
    this.dropDownChange.emit(event);
  }
}
