import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-cell-permission-renderer',
  templateUrl: './ag-action-cell-permission-renderer.component.html',
  styleUrls: ['./ag-action-cell-permission-renderer.component.css']
})
export class AgActionCellPermissionRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  constructor() { }

  ngOnInit() {

  }

  agInit(params: any): void {
    if (!params.data.parent) {
      this.params = params;
    }
  }

  refresh(): boolean {
    return false;
  }

  departmentPermission() {
    this.params.context.componentParent.departmentPermission(this.params.data);
  }

  accessPermission() {
    this.params.context.componentParent.accessPermission(this.params.data);
  }

}
