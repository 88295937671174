import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';

import * as faceApi from '../../../assets/Scripts/face-api.min.js';



@Component({
  selector: 'app-face-recognization',
  templateUrl: './face-recognization.component.html',
  styleUrls: ['./face-recognization.component.css']
})

export class FaceRecognizatonComponent implements OnInit, OnDestroy {



  //
  @ViewChild('canvas')
  public canvas: ElementRef;
  @ViewChild('video')
  public video: ElementRef;
  @ViewChild('file')
  file: ElementRef;
  private trainedDatas: any[];
  foundEmpId: any;
  foundEmpName: any;
  @Input() set trainedData(trainedData: any[]) {
    if (trainedData) {
      this.trainedDatas = trainedData;
    }
  }
  get trainedData(): any[] {
    return this.trainedDatas;
  }
  @Output() getRecognisePerson = new EventEmitter();

  showStop = false;
  isModuleLoaded: boolean;

  isUserDetected = false;

  localDescripterList: any[];
  constructor(private cdr: ChangeDetectorRef) {
  }

  async ngOnInit() {

    try {
      this.isModuleLoaded = false;
      await this.loadModule();
      console.log('loaded');
      this.isModuleLoaded = true;
    } catch (error) {
      alert('fail to load model');
    }
    this.loadDiscripterData();
    console.log('triandata', this.trainedData);
  }

  emitRecognisePerson(value: string) {
    this.getRecognisePerson.emit(value);
  }

  // video
  loadDiscripterData() {
    this.localDescripterList = [];
    if (this.trainedData.length > 0) {
      this.localDescripterList = this.trainedData.map((data: any) =>
        new faceApi.LabeledFaceDescriptors(data.Name, [new Float32Array(data.Descriptors)]));
    }
  }

  onPlayVideo() {

    const accurecy = 0.5;
    const faceMatcher = new faceApi.FaceMatcher(this.localDescripterList, 0.6);
    const displaySize = { width: this.video.nativeElement.width, height: this.video.nativeElement.height };
    faceApi.matchDimensions(this.canvas, displaySize);
    const timeout = setInterval(async () => {
      const detections = await faceApi.detectSingleFace(this.video.nativeElement,
        new faceApi.TinyFaceDetectorOptions({ minConfidence: 0.8, inputSize: 192 })).withFaceLandmarks().withFaceDescriptor();
      const detections1 = await faceApi.detectSingleFace(this.video.nativeElement,
        new faceApi.SsdMobilenetv1Options({ minConfidence: 0.8 })).withFaceLandmarks().withFaceDescriptor();
      if (detections !== undefined && detections1 !== undefined) {
        clearTimeout(timeout);

        this.video.nativeElement.pause();

        const bestMatch = faceMatcher.findBestMatch(detections.descriptor);
        const bestMatch1 = faceMatcher.findBestMatch(detections1.descriptor);
        const results = bestMatch.toString();
        const results1 = bestMatch1.toString();
        console.log(results, 'result');
        if (results.split(' ')[0] !== 'unknown' && results1.split(' ')[0] !== 'unknown') {
          if (results.split('-')[1].substr(0, 6) === results1.split('-')[1].substr(0, 6) &&
            (parseFloat(results.substr(results.indexOf('(') + 1, results.length - 2)) <= accurecy
              || parseFloat(results1.substr(results1.indexOf('(') + 1, results1.length - 2)) <= accurecy)) {
            console.log(results);
            const index = results.lastIndexOf('-');
            this.emitRecognisePerson(results);
            this.foundEmpId = results.split('-')[0];
            this.foundEmpName = results.split('-')[1];

            // alert('User Detected ' + this.foundEmpName.split('(')[0].trim());
            this.isUserDetected = true;
            this.cdr.markForCheck();
            this.stopRecognization();
          } else {
            this.video.nativeElement.play();
          }
        } else {
          this.video.nativeElement.play();
        }

      } else {
        this.video.nativeElement.play();

      }
    }, 1000);

  }

  startRecognization() {

    this.loadDiscripterData();
    this.startVideo();
    this.showStop = true;
    this.isUserDetected = false;

  }
  stopRecognization() {
    this.stopVideo();
    this.showStop = false;

  }
  stopVideo() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.video.nativeElement.pause();
        this.video.nativeElement.srcObject = null;
        stream.getTracks().forEach(track => track.stop());
      });
  }
  startVideo() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.video.nativeElement.srcObject = stream;
        return this.video.nativeElement.play();
      });
  }
  ngOnDestroy() {

  }
  async loadModule() {
    await faceApi.nets.faceRecognitionNet.loadFromUri('assets/models');
    await faceApi.nets.faceLandmark68Net.loadFromUri('assets/models');
    await faceApi.nets.ssdMobilenetv1.loadFromUri('assets/models');
    await faceApi.nets.tinyFaceDetector.loadFromUri('assets/models');

  }


}
