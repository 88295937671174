import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WageRegisterFromBComponent } from "./wage-register-from-b/wage-register-from-b.component";
import { CentralReportsRoutingModule } from "./central-routing.module";
import { CoreModule } from "src/app/core/core.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RowWageRegisterFormBComponent } from "./wage-register-from-b/row-wage-register-form-b/row-wage-register-form-b.component";
import { AgGridModule } from "ag-grid-angular";
import { LoanNRecoveriesRegisterFromCModule } from "./loan-n-recoveries-register-from-c/loan-n-recoveries-register-from-c.module";
import { PaySlipModule } from "./pay-slip/pay-slip.module";

@NgModule({
  declarations: [
    WageRegisterFromBComponent,
    RowWageRegisterFormBComponent,
  ],
  imports: [
    CommonModule,
    CentralReportsRoutingModule,
    CoreModule.forRoot(),
    NgbModule,
    AgGridModule.withComponents([]),
    LoanNRecoveriesRegisterFromCModule,
    PaySlipModule
  ],
  entryComponents: [
    RowWageRegisterFormBComponent,
  ],
})
export class CentralModule {}
