/**
 * This class is use to set `configuration` of modal.
 */
export class ModalConfig {
  public backDropColor?: BackdropColor;
  public modalWindowClass?: ModelWindowColor | ModelDialogClass;
  public isCenter?: boolean;
  public size?: ModalSize | null | string;
  public closeOnOutSideClick?: boolean;
  constructor(isCenter: false, backDropColor: null, modalWindowClass: null, size: '', closeOnOutsideClick: true) {
    this.isCenter = isCenter;
    this.backDropColor = backDropColor;
    this.modalWindowClass = modalWindowClass;
    this.size = size;
    this.closeOnOutSideClick = closeOnOutsideClick;
  }
}

/**
 * This enum is use to hold color name.
 * which is hold the different key of `color`.
 */
export enum ModalSize {
    Small = 'sm',
    Large = 'lg',
    Medium = 'md'
}

/**
 * This enum is use to hold color name.
 * which is hold the different key of `color`.
 */
export enum BackdropColor {
    LightBlue = 'lightskyblue',
    DarkGrey = 'darkgray',
}

/**
 * This enum is use to hold backdrop class name.
 * which is use to declare class name.
 */
export enum BackdropClass {
    LightBlueBackdrop = 'light-blue-backdrop',
    DarkGrayBackdrop = 'dark-gray-backdrop'
}

/**
 * This enum is use to hold color name of model window.
 * which is hold the different key of `color`.
 */
export enum ModelWindowColor {
    DarkGrey = 'darkgrey'
}

/**
 * This enum is use to hold model window class name.
 * which is use to declare class name.
 */
export enum ModelWindowClass {
    DarkGreyContent = 'dark-gray-content',
    MyCustomModalClass = 'myCustomModalClass'
}

/**
 * This enum is use to hold model window class name.
 * which is use to declare class name.
 */
export enum ModelDialogClass {
    CustomClass = 'customClass'
}



export class DataFilter {
  labourDemandType = -1;
  contractorCompanyName = '';
  contractorsEmailId = '';
  gatePassStatus = false;
  roleActionCode = '';
  contractorVendorCode = null;
  workorderCode = null;
  licenseCode = null;
  mainEsic = '';
  ecpolicyCode = null;
  ecpolicyChecked = false;
  subContractorCode = null;
  subWorkorderCode = null;
  subLicenseCode = null;
  subEsic = false;
  subeEcPolicyCode = null;
  subEcpolicyChecked = false;
  departmentCode = null;
  worksiteCategoryCode = null;
  plantCode = null;
  skillTypeCode = null;
  search = '';
  filterStartDate = '';
  filterEndDate = '';
}

export class StatusFilter {
  approved = false;
  onHold = false;
  pending = false;
  rejected = false;
  debarred = false;
}

export class AdvanceFilters {
  name: string;
  code: string;
}

export class FormInstance {
  contractor: any;
  workOrder: any;
  license: any;
  ecPolicy: any;
  userList: any;
}
