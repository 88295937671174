import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WeekOffFilterModel } from '../weekoff-change';
import { Checklist } from 'src/app/core/components';

@Component({
  selector: 'app-weekoff-filter',
  templateUrl: './weekoff-filter.component.html',
  styleUrls: ['./weekoff-filter.component.css']
})

export class WeekoffFilterComponent implements OnInit {

  @ViewChild('workOffForm') workOffForm: NgForm;

  weekOffFilterModdal = new WeekOffFilterModel();
  weekoffDays: any;
  weekOffChangeType: any = 'defaultWeekChange';
  checkList: Checklist[] = [];
  @Output() submitFilter: EventEmitter<WeekOffFilterModel> = new EventEmitter();
  @Output() openContracctModal: EventEmitter<WeekOffFilterModel> = new EventEmitter();
  @Input() selectedLabourLength = 0;
  @Input() set selectedContractor(value) {
    this.weekOffFilterModdal.contractorName = value;
  }
  get selectedContractor() {
    return this.weekOffFilterModdal.contractorName;
  }

  weekDays = [{
    label: 'Sunday',
    value: 'sunday'
  },
  {
    label: 'Monday',
    value: 'monday'
  },
  {
    label: 'Tuesday',
    value: 'tuesday'
  },
  {
    label: 'Wednesday',
    value: 'wednesday'
  },
  {
    label: 'Thursday',
    value: 'thursday'
  },
  {
    label: 'Friday',
    value: 'friday'
  },
  {
    label: 'Saturday',
    value: 'saturday'
  }
  ];
  constructor() { }

  ngOnInit() {
    this.checkList = [
      {
        id: 1,
        text: 'Week Of Change',
        open: true,
        childrens: [
          {
            text: 'Contractor Name',
            title: 'Contractor Name',
            desc: 'Contractor Name required.',
            isValid: () => {
              return (this.workOffForm.controls.contractorName && this.workOffForm.controls.contractorName.valid);
            },
            hasError: () => {
              // tslint:disable-next-line:max-line-length
              return (this.workOffForm.controls.contractorName && (this.workOffForm.controls.contractorName.dirty || this.workOffForm.controls.contractorName.touched)
                && this.workOffForm.controls.contractorName.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Week Off',
            title: 'Week Off',
            desc: 'Week Off required.',
            isValid: () => {
              return (this.workOffForm.controls.optradio && this.workOffForm.controls.optradio.valid);
            },
            hasError: () => {
              // tslint:disable-next-line:max-line-length
              return (this.workOffForm.controls.optradio && (this.workOffForm.controls.optradio.dirty || this.workOffForm.controls.optradio.touched)
                && this.workOffForm.controls.optradio.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
        ],
      },
      {
        id: 2,
        text: 'Labour',
        open: true,
        childrens: [
          {
            text: 'Select Labour',
            title: 'Select Labour',
            desc: 'Select atleast 1 Labour',
            isValid: () => {
              return this.selectedLabourLength > 0;
            },
            hasError: () => {
              // tslint:disable-next-line:max-line-length
              return this.selectedLabourLength <= 0;
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
        ]
      }
    ];
  }

  onSubmit() {
    console.log(this.weekOffFilterModdal);
    this.submitFilter.emit(this.weekOffFilterModdal);
  }

  onWeekOffTypeChange(value) {
    const contractorName = this.selectedContractor;
    this.weekOffFilterModdal = new WeekOffFilterModel();
    this.weekOffFilterModdal.contractorName = contractorName;

  }
  onContractClick() {
    this.openContracctModal.emit();
  }
}
