import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-attendance-report',
  templateUrl: './monthly-attendance-report.component.html',
  styleUrls: ['./monthly-attendance-report.component.css']
})
export class MonthlyAttendanceReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
