export enum MasterItemCode {
  ORDER_TYPE = 'GP226',
  ORDER_CATEGORY = 'GP230',
  NATURE_OF_WORK = '225',
  WORK_SITE_AREA = 'GM612',
  WORK_TYPE = 'GP230',
  NATURE_OF_LICENSE = 'GM623',
  STATUS_CODE = 'GM245'
}

export enum NatureOfLicenceCode {
  Acknowledgment = 624
}
