import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportExcelComponent } from './import-excel.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {
    path: ' import-excel',
    component: ImportExcelComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportExcelRoutingModule { }
