import { Injectable } from '@angular/core';


declare var d3: any;
declare var dc: any;
declare var crossfilter: any;

interface Config {
  crossfilter: any;
  filterGroup: string;
  chartId: string;
  groupByProperty: string;
  reduceProperty: string;
  width: number;
  height: number;

  // Optional
  yAxisLabel?: string;
  xAxisLabel?: string;
  reduceProperty2?: string;
  reduceProperty3?: string;
  legendText?: string;
  legendText2?: string;
  legendText3?: string;
  margins?: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  };
}

@Injectable({
  providedIn: 'root'
})
export class LineChartService {

  monthMap = {
    JAN: 1,
    FEB: 2,
    MAR: 3,
    APR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AUG: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DEC: 12,
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
  };

  constructor() { }

  getLineChartMonthWise(config: Config) {

    config.legendText = config.legendText ? config.legendText : config.reduceProperty;
    config.legendText2 = config.legendText2 ? config.legendText2 : config.reduceProperty2;
    config.legendText3 = config.legendText3 ? config.legendText3 : config.reduceProperty3;
    const monthMap = this.monthMap;
    const bisectDate = d3.bisector((d) => {
      return d.key;
    });

    const dimension = config.crossfilter.dimension((d) => {
      return this.monthMap[d[config.groupByProperty]];
    });
    // console.log('Line dimension ', dimension.top(Infinity));
    const grp1 = dimension.group().reduceSum((d) => {
      return +d[config.reduceProperty];
      // if (d.Month === 'JAN') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'FEB') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'MAR') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'APR') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'MAY') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'JUN') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'JUL') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'AUG') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'SEP') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'OCT') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'NOV') {
      //   return +d[config.reduceProperty];
      // }
      // if (d.Month === 'DEC') {
      //   return +d[config.reduceProperty];
      // }
    });

    const chart = dc.compositeChart(config.chartId, config.filterGroup);
    const margins = { left: 70, top: 50, right: 50, bottom: 50 };

    const lineCharts = [
      dc.lineChart(chart)
        .group(grp1, config.legendText)
        .colors('red')
        .renderArea(true)
        .renderDataPoints(true)
        .yAxisLabel(config.yAxisLabel)
        .xAxisLabel(config.xAxisLabel)
      // .dashStyle([2, 2]),
    ];

    let grp2 = null;
    if (config.reduceProperty2) {
      grp2 = dimension.group().reduceSum((d) => {
        return +d[config.reduceProperty2];
        // if (d.Month === 'JAN') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'FEB') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'MAR') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'APR') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'MAY') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'JUN') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'JUL') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'AUG') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'SEP') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'OCT') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'NOV') {
        //   return +d[config.reduceProperty2];
        // }
        // if (d.Month === 'DEC') {
        //   return +d[config.reduceProperty2];
        // }
      });
      lineCharts.push(
        dc.lineChart(chart)
          .margins(margins)
          .group(grp2, config.legendText2)
          .colors('blue')
          .renderArea(true)
          .renderDataPoints(true)
          .yAxisLabel(config.yAxisLabel)
          .xAxisLabel(config.xAxisLabel)
        // .dashStyle([2, 2])
      );
    }

    let grp3 = null;
    if (config.reduceProperty3) {
      grp3 = dimension.group().reduceSum((d) => {
        return d[config.reduceProperty3];
      });
      lineCharts.push(
        dc.lineChart(chart)
          .margins(margins)
          .group(grp3, config.legendText3)
          .colors('green')
          .renderArea(true)
          .renderDataPoints(true)
        // .dashStyle([1, 1])
      );
    }

    chart.width(config.width)
      .height(config.height)
      .margins(margins)
      .dimension(dimension)
      .yAxisLabel(config.yAxisLabel)
      .xAxisLabel(config.xAxisLabel)
      .transitionDuration(500)
      .elasticY(true)
      .brushOn(false)
      // .valueAccessor(function (d) {
      //   return d.value;
      // })
      .title((d) => {
        return `${this.monthMap[d.key]}: ${d.value}`;
      })
      .legend(dc.legend().x(80).y(20).itemWidth(120).horizontal(true).gap(5))
      .x(d3.scale.linear().domain([1, 12]).range([1, 12]))
      // .xUnits(dc.units.ordinal)
      ._rangeBandPadding(1)
      .compose(lineCharts)
      // tslint:disable-next-line
      .on('postRender', function (chart) {
        const svgG = chart.svg().append('g');
        const g = chart.chartBodyG().append('g');

        const xLine = g.append('line').attr('class', 'tracking x')
          .attr('x1', 0).attr('x2', 0)
          .attr('y1', 0).attr('y2', config.height)
          .attr('transform', 'translate(0,0)');

        const xText = svgG.append('text').attr('class', 'texttip x')
          .attr('x', margins.left)
          .attr('y', margins.top - 5)
          .attr('transform', 'translate(0,0)');

        function getData(groupAll, bisectDate, mouseMonth) {
          // find index of the nearest point
          let i = bisectDate.left(groupAll, mouseMonth);
          if (i <= 0) i = 1;
          if (i >= groupAll.length) i = groupAll.length - 1;

          const d0 = groupAll[i - 1];
          const d1 = groupAll[i];
          const d = mouseMonth - d0.key > d1.key - mouseMonth ? d1 : d0;

          return d;
        }

        chart.svg()
          .on('mousemove', function () {
            const mouseX = d3.mouse(this)[0];
            const mouseMonth = chart.x().invert(mouseX - margins.left);
            let d = getData(grp1.all(), bisectDate, mouseMonth);
            const x = chart.x()(d.key);
            const y = chart.y()(d.value);

            let displayData = `
						<tspan class='title' x='10' dx='10' dy='15' >${monthMap[d.key]}</tspan>
            <tspan class='author' x='10' dx='10' dy='15'>${config.legendText}: ${d.value}</tspan></div>`;
            // ${monthMap[d.key]}\n${config.legendText}: ${d.value}`;

            if (grp2) {
              d = getData(grp2.all(), bisectDate, mouseMonth);
              displayData = `${displayData}<tspan class='author' x='10' dx='10' dy='15'>${config.legendText2}: ${d.value}</tspan>`;
              // displayData = `${displayData}\n${config.legendText2}: ${d.value}`;
            }

            if (grp3) {
              d = getData(grp3.all(), bisectDate, mouseMonth);
              displayData = `${displayData}<tspan class='author' x='10' dx='10' dy='15'>${config.legendText3}: ${d.value}</tspan>`;
              // displayData = `${displayData}\n${config.legendText3}: ${d.value}`;
            }

            xLine.attr('transform', `translate(${x},0)`);
            xText.attr('transform', `translate(${x},0)`)
              .html(`${displayData}`);
          });
      });

    if (config.margins) {
      chart.margins(config.margins);
    }

    chart.yAxis().tickFormat(this.tickValueFormat);
    chart.xAxis().tickFormat(this.tickValueMonthFormat);
    dc.renderAll(config.filterGroup);
    return chart;
  }

  tickValueMonthFormat = (d) => {
    return this.monthMap[d];
  }

  tickValueFormat = (d) => {
    let newd = d;
    if ((d / 10000000) >= 1) {
      newd = `${d / 10000000}Cr`;
    } else if ((d / 100000) >= 1) {
      newd = `${d / 100000}L`;
    } else if ((d / 1000) >= 1) {
      newd = `${d / 1000}K`;
    }
    return newd;
  }
}
